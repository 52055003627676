import {
  HomeOutlined,
  LogoutOutlined,
  FileSearchOutlined,
  FileProtectOutlined,
  FileTextOutlined 

} from '@ant-design/icons';
import { Col, Layout, Menu, Modal, Row } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import logo from '../assets/img/logo-side-bar.png';
import userLogo from '../assets/img/user-icon.png';
import userServices from '../services/userService';
import {
  refreshToken,
  sessionClose,
  setExpirationTimeout,
} from '../store/actions/authActions';
import { setCompany } from '../store/actions/pagador/companyActions';
import '../styles/proveedorLayout.scss';
import '../styles/standard.scss';
import { capitalize } from '../utilities/textTools';
import Dashboard from '../pages/facturacion/dashboard/dashboard';
import GenerateBilling from '../pages/facturacion/generateBilling/generateBilling';
import CheckBilling from '../pages/facturacion/checkBilling/checkBilling';
import InvoiceManual from '../pages/facturacion/invoiceManual/invoiceManual';
const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

class FacturacionLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSelectedKey: '1',
      selected_company: '',
      notificationDrawer: false,
      notifications: [],
      unreadedNotifications: 0,
      showAuthExpModal: false,
      setTimeoutCloseSession: null,
      menu: [],
    };
  }

  fecha = moment().format('dddd, LL');

  unfilteredMenu = [
    {
      title: 'Inicio',
      icon: HomeOutlined,
      selected: true,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/dashboard`,
      key: 1,
    },
    {
      title: 'Generar Facturacion',
      icon: FileProtectOutlined,
      selected: true,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/facturacion/generar`,
      key: 2,
    },
    {
      title: 'Consultar Facturas',
      icon: FileSearchOutlined,
      selected: true,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/facturacion/consultar`,
      key: 3,
    },
    {
      title: 'Factura Manual',
      icon: FileTextOutlined ,
      selected: true,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/facturacion/factura_manual`,
      key: 4,
    },
  ];

  componentDidMount() {
    this.renderMenu();
    if (window.configuredInterval) clearInterval(window.configuredInterval);
    window.configuredInterval = setInterval(() => {
      this.props.setExpirationTimeout(this.props.authExpiration);
    }, 30000);
  }

  componentDidUpdate(props, state) {
    if (this.props.reachExpiration !== props.reachExpiration) {
      if (this.props.reachExpiration) {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
          setTimeoutCloseSession: setTimeout(this.logout, 110000),
        });
      } else {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
        });
        clearTimeout(this.state.setTimeoutCloseSession);
      }
    }
  }

  updateSelectedCompany = (value, e) => {
    this.setState({
      ...this.state,
      selected_company: value,
    });
    this.props.setCompany(value);
  };

  renderMenu() {
    userServices
      .getAdminUserModules(
        this.props.authtoken,
        process.env.REACT_APP_ADMIN_NAME
      )
      .then((response) => {
        this.setState({
          ...this.state,
          menu: this.filterMenu(
            Array.isArray(response.data) ? response.data : [],
            this.unfilteredMenu
          ),
        });
      });
  }

  filterMenu(userModules, menuItems) {
    return menuItems
      .filter((filterItem) => {
        if (filterItem.protected) {
          let found = userModules.find(
            (x) => x.cdRecursos == filterItem.protectedKey
          );
          if (found) {
            filterItem.title = found.dsNombre;
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .map((item) => {
        if (item.protected) {
          if (Array.isArray(item.children)) {
            return {
              ...item,
              children: this.filterMenu(userModules, item.children),
            };
          } else {
            return item;
          }
        } else {
          return item;
        }
      });
  }

  logout = () => {
    this.props.sessionClose(this.props.authtoken);
  };

  selectedKeyHandler = (key) => {
    this.setState({
      ...this.state,
      menuSelectedKey: key,
    });
  };

  render() {
    return (
      <Layout>
        <Sider className='realasside'>
          <div className='user-container'>
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img className='brand-logo' src={logo} alt='' />
            </Link>
            {this.props.children}
            <img className='user-avatar' src={userLogo} alt='' />
            <p className='user-name'>{this.props.username}</p>
            <br />
            <br />
          </div>
          <Menu
            className='slider-list'
            mode='inline'
            selectedKeys={[this.state.menuSelectedKey]}
          >
            {this.state.menu.map((menu, index) =>
              menu.isSubMenu ? (
                <SubMenu
                  key={`sub${index}`}
                  title={
                    <span>
                      {menu.icon ? React.createElement(menu.icon) : ''}
                      <span>{menu.title}</span>
                    </span>
                  }
                >
                  {menu.children.map((submenu) => (
                    <Menu.Item key={submenu.key}>
                      <Link to={submenu.href}>
                        {submenu.icon ? React.createElement(submenu.icon) : ''}
                        {submenu.title}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.key}>
                  <Link to={menu.href}>
                    {menu.icon ? React.createElement(menu.icon) : ''}
                    {menu.title}
                  </Link>
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout className='content'>
          <Header className='header'>
            <Row className='menu'>
              <Col span={23}>
                {this.fecha.charAt(0).toUpperCase() + this.fecha.slice(1)}
              </Col>

              <Col span={1} onClick={this.logout} className='logout'>
                {' '}
                <LogoutOutlined className='notification-icon' />
              </Col>
            </Row>
          </Header>
          <Content>
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/dashboard`}>
              <Dashboard
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              
              <Route exact path={`${process.env.PUBLIC_URL}/facturacion/generar`}>
                <GenerateBilling menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/facturacion/consultar`}>
                <CheckBilling menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/facturacion/factura_manual`}>
                <InvoiceManual menuHandler={this.selectedKeyHandler} />
              </Route>
            </Switch>
          </Content>
        </Layout>
        <Modal
          title='Sessión a punto de finalizar'
          visible={this.state.showAuthExpModal}
          onOk={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
            clearTimeout(this.state.setTimeoutCloseSession);
            this.props.refreshToken(this.props.authRefreshToken);
          }}
          onCancel={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
          }}
        >
          <p>
            Tu sesión esta a punto de finalizar, si deseas continuar operando en
            la plataforma presiona "Aceptar"
          </p>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  authtoken: state.auth.auth.access_token,
  username: state.auth.auth.name,
  authExpiration: state.auth.expiressTimestamp,
  authRefreshToken: state.auth.auth.refresh_token,
  reachExpiration: state.auth.reachExpiration,
});

export default connect(mapStateToProps, {
  setCompany,
  setExpirationTimeout,
  refreshToken,
  sessionClose,
})(FacturacionLayout);
