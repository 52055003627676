import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Card, Tabs, Spin, Col, Button, notification } from 'antd';
import { listaTipoDiaPago } from '../../../../../services/admin/payersService';
import history from '../../../../../services/history';
import '../../common/client.scss';
import BasicDataForm, {
  initialFundProviderValuesForm,
} from '../basicDataForm/basicDataForm';
import {
  editarFondeador,
  getFondeador,
} from '../../../../../services/admin/fundProvidersService';
import Documents from '../../common/documents/documents';
import Users from '../../common/users/users';

const MESSAGE_ENTIDAD_PROVEEDOR_NO_EXIST =
  'Esta Persona o Entidad no es un Fondeador';
const MESSAGE_ENTIDAD_PROVEEDOR_EXIST =
  'Esta Persona o Entidad es un Fondeador';
const FUND_PROVIDER_TITLE = 'Proveedor';

const TIPO_ASOCIADO = 'F';

const initialState = {
  activeTab: '1',
  loading: false,
  propietarioSelect: {
    options: [],
    fetching: false,
  },
  tipoDiaPago: [],
  data: { ...initialFundProviderValuesForm },
};

class FundProviderEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  redirectToFundProvider = () => {
    history.push(`${process.env.PUBLIC_URL}/fundProviders`);
  };

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await editarFondeador(this.props.authToken, formValue);

      notification.success({
        message: FUND_PROVIDER_TITLE,
        description: response.message,
        duration: 6,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getPagadorData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    const { authToken, scPersona } = this.props;
    try {
      const fundReponse = await getFondeador(authToken, scPersona);

      const response = await listaTipoDiaPago(authToken, {
        tipoAsociado: TIPO_ASOCIADO,
      });

      this.setState({
        ...this.state,
        tipoDiaPago: response.data,
        data: fundReponse.data,
        loading: false,
      });
    } catch {
      this.redirectToFundProvider();
    }
  };

  handleTabOnChange = (e) => {
    this.setState({
      ...this.state,
      activeTab: e,
    });
  };

  componentDidMount() {
    this.props.menuHandler('21');
    this.getPagadorData();
  }

  componentDidUpdate(props, state) {
    if (props.scPersona !== this.props.scPersona) {
      this.getPagadorData();
    }
  }

  render() {
    const { loading, data, tipoDiaPago, activeTab } = this.state;
    const { authToken, scPersona } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Tabs activeKey={activeTab} onChange={this.handleTabOnChange}>
              <Tabs.TabPane key={'1'} tab='Datos básicos'>
                <BasicDataForm
                  visibleSearchButton={false}
                  tipoDiaPago={tipoDiaPago}
                  data={data}
                  onFinish={this.handleOnFinish}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToFundProvider}
                      >
                        Lista de Fondeadores
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>
              </Tabs.TabPane>
              <Tabs.TabPane key={'2'} tab='Documentos'>
                <Documents
                  authToken={authToken}
                  scPersona={scPersona}
                  tipoAsociado={TIPO_ASOCIADO}
                >
                  <Col span={6}>
                    <Button
                      style={{ width: '90%' }}
                      className='btn btn-blue'
                      type='primary'
                      onClick={this.redirectToFundProvider}
                    >
                      Lista de Fondeadores
                    </Button>
                  </Col>
                </Documents>
              </Tabs.TabPane>
              <Tabs.TabPane key={'4'} tab='Usuarios'>
                <Users
                  authToken={authToken}
                  scPersona={scPersona}
                  tipoAsociado={TIPO_ASOCIADO}
                >
                  <Col span={6}>
                    <Button
                      style={{ width: '90%' }}
                      className='btn btn-blue'
                      type='primary'
                      onClick={this.redirectToFundProvider}
                    >
                      Lista de Fondeadores
                    </Button>
                  </Col>
                </Users>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scPersona = atob(props.match.params.scPersona);
  return {
    scPersona: scPersona,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(FundProviderEditForm));
