import { Button, Col, DatePicker, Form, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../utilities/messages';
import moment from 'moment';

const initialValue = {
  fechaRango:[moment(), moment()],
  scCliente: undefined,
  tipoDetalleFactura:''
};

const GenerateFiltreForm = ({
  handleSubmit,
  companiaSelect,
  handleOnSearchCompania,
  tipoFacturacionSelect
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item 
              name='fechaRango' 
              label='Rango de fechas de ingreso'
              rules={[
                  {
                      required: true,
                      message: 'Por favor seleccione un rango de fechas',
                    },
                  ]}
              >
              <DatePicker.RangePicker name='fechaRango' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              name='scCliente' 
              label='CLIENTE'>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchCompania(value)}
                notFoundContent={
                  companiaSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {companiaSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item 
              name='tipoDetalleFactura' 
              label='Tipo Facturacion'
              rules={[
                  {
                      required: true,
                      message: 'Por favor seleccione un tipo de facturacion',
                    },
                  ]}
              >
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={
                  tipoFacturacionSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {tipoFacturacionSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GenerateFiltreForm;
