import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  emailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import { tipoIds } from '../../../../../utilities/constants';

const SELECT_PLACE_HOLDER = 'Seleccione...';

const estadoPersonas = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
  { key: 'P', description: 'Prospecto' },
];

const tipoPersonas = [
  {
    key: 'N',
    description: 'Natural',
  },
  {
    key: 'J',
    description: 'Jurídica',
  },
];

const dias = [
  { label: 'LUNES', value: 'Lunes' },
  { label: 'MARTES', value: 'Martes' },
  { label: 'MIÉRCOLES', value: 'Miercoles' },
  { label: 'JUEVES', value: 'Jueves' },
  { label: 'VIERNES', value: 'Viernes' },
];

const rules = {
  cdTipoPersona: [{ required: true, message: requiredValueMessage() }],
  cdEstadoPersona: [{ required: true, message: requiredValueMessage() }],
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
    {
      pattern: /^[0-9a-zA-Z-]*$/,
      message: formatValueMessage(),
    },
  ],
  razonSocial: [
    { required: true, message: requiredValueMessage() },
    { max: 300, message: maxValueMessage(300) },
  ],
  prNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  prApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  dsEmailFacturacion: [
    {
      pattern: emailPattern,
      message: formatValueMessage(),
    },
    { max: 100, message: maxValueMessage(100) },
  ],
  cdTipoDiaPago: [{ required: true, message: requiredValueMessage() }],
diasConciliacion: [
    { required: true, message: requiredValueMessage() },
    { max: 1, message: maxValueMessage(1) },
    {
      pattern: /^[0-9]*$/,
      message: formatValueMessage(),
    },
  ]
};

export const initialFundProviderValuesForm = {
  cdTipoPersona: undefined,
  cdEstadoPersona: 'P',
  dsEstadoPersona: estadoPersonas.find((value) => value.key === 'P')
    .description,
  cdTipoId: undefined,
  id: '',
  razonSocial: '',
  prNombre: '',
  segNombre: '',
  prApellido: '',
  segApellido: '',
  dsEmailFacturacion: '',
  cdTipoDiaPago: undefined,
  diasPago: [],
  diasConciliacion:undefined
};

const BasicDataForm = ({
  onFinish,
  data,
  tipoDiaPago,
  visibleSearchButton = true,
  onFundProviderSearch,
  children,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [cdTipoPersona, setCdTipoPersona] = useState('');
  const [cdEstadoPersona, setCdEstadoPersona] = useState('');
  const [cdTipoDiaPago, setCdTipoDiaPago] = useState('');

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    setCdTipoPersona(data.cdTipoPersona);
    setCdEstadoPersona(data.cdEstadoPersona);
    setCdTipoDiaPago(data.cdTipoDiaPago);

    form.setFieldsValue({
      ...data,
    });
  }, [form, data]);

  const handleCdTipoPersonaChange = (cdTipoPersona) => {
    setCdTipoPersona(cdTipoPersona);
  };

  const handleCdTipoDiaPagoChange = (cdTipoDiaPago) => {
    setCdTipoDiaPago(cdTipoDiaPago);
  };

  const handleOnFinish = (formValue) => {
    onFinish({ ...formValue });
  };

  return (
    <Form
      name='content_form'
      layout='vertical'
      form={form}
      onFinish={handleOnFinish}
      initialValues={initialFundProviderValuesForm}
    >
      <Form.Item name='scPersona' hidden='true'>
        <Input />
      </Form.Item>
      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Datos personales
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoPersona'
            label='TIPO DE PERSONA'
            rules={rules['cdTipoPersona']}
          >
            <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
              onChange={handleCdTipoPersonaChange}
            >
              {tipoPersonas.map((tipoPersona) => (
                <Select.Option key={tipoPersona.key} value={tipoPersona.key}>
                  {tipoPersona.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='cdEstadoPersona'
            label='ESTADO'
            rules={rules['cdEstadoPersona']}
          >
            <Select allowClear placeholder={SELECT_PLACE_HOLDER}>
              {estadoPersonas.map((estadoPersona) => (
                <Select.Option
                  key={estadoPersona.key}
                  value={estadoPersona.key}
                >
                  {estadoPersona.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoId'
            label='TIPO DE IDENTIFICACIÓN'
            rules={rules['cdTipoId']}
          >
            <Select allowClear placeholder={SELECT_PLACE_HOLDER}>
              {tipoIds.map((tipoId) => (
                <Select.Option key={tipoId.key} value={tipoId.key}>
                  {tipoId.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='IDENTIFICACIÓN' required>
            <Row gutter={[8, 0]}>
              <Col flex='auto'>
                <Form.Item name='id' rules={rules['id']} noStyle>
                  <Input />
                </Form.Item>
              </Col>
              {visibleSearchButton && (
                <Col flex='none' style={{ marginTop: '0.2rem' }}>
                  <Button
                    type='dashed'
                    size='small'
                    shape='circle'
                    icon={<SearchOutlined />}
                    onClick={() =>
                      onFundProviderSearch({ id: form.getFieldValue('id') })
                    }
                  />
                </Col>
              )}
            </Row>
          </Form.Item>
        </Col>
      </Row>

      {cdTipoPersona === 'J' && (
        <Row gutter={16}>
          <Col flex={'auto'}>
            <Form.Item
              name='razonSocial'
              label='RAZÓN SOCIAL'
              rules={rules['razonSocial']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}

      {cdTipoPersona === 'N' && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prNombre'
                label='PRIMER NOMBRE'
                rules={rules['prNombre']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segNombre'
                label='SEGUNDO NOMBRE'
                rules={rules['segNombre']}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prApellido'
                label='PRIMER APELLIDO'
                rules={rules['prApellido']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segApellido'
                label='SEGUNDO APELLIDO'
                rules={rules['segApellido']}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={16}>
        <Col flex='auto'>
          <Form.Item
            name='dsEmailFacturacion'
            label='CORREO ELECTRÓNICO FACTURACIÓN'
            rules={rules['dsEmailFacturacion']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Dias de pago semana
          </Space>
        </Col>
      </Row>
      <Divider />

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoDiaPago'
            label='TIPO DE PAGO'
            rules={rules['cdTipoId']}
          >
            <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
              onChange={handleCdTipoDiaPagoChange}
            >
              {tipoDiaPago.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {cdTipoDiaPago === 'S' && (
        <>
          <Row gutter={16}>
            <Col>
              <Space
                align='baseline'
                className='sub-title'
                style={{ marginBottom: '1.3rem' }}
              >
                Dias de pago semana
              </Space>
            </Col>
          </Row>

          <Row gutter={16} align='middle' justify='center'>
            <Col flex='auto'>
              <div className='check'>
                <Form.Item name={'diasPago'}>
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row align='middle' justify='center'>
                      {dias.map((dia, index) => (
                        <Col span={4} key={index}>
                          <Form.Item label={dia.label}>
                            <Checkbox value={dia.value} />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </>
      )}


      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Dias de Conciliación
          </Space>
        </Col>
      </Row>
      <Divider />

      <Row gutter={6}>
          <Col span={6}>
            <Form.Item
              name='diasConciliacion'
              label='DÍAS DE CONCILIACIÓN'
              rules={rules['diasConciliacion']}
            >
              <Input type='number' />
            </Form.Item>
          </Col>
        </Row>

      <Row gutter={16}>
        {children}
        <Col span={6}>
          <Button
            style={{ width: '90%' }}
            className='btn btn-green'
            type='primary'
            htmlType='submit'
          >
            Grabar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicDataForm;
