import { InboxOutlined,CheckCircleOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Typography, 
  Upload,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import Dragger from 'antd/lib/upload/Dragger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { descargarDocumentos, grabarDocumentosAnexos, handleDescargarDocumentos, listarDocumentosAnexos } from '../../../services/vinculacion/documentosAnexosService';
import { setExportZip } from '../../../utilities/downloadTools';
import { sizeFileValidation, typeFileValidation } from '../../../utilities/validate/fileValidate';
import { mensajesEstadoVinculacion } from '../mensajesEstadoVin';
import { actualizarEstadoFichaJuridicaPU } from '../../../services/vinculacion/fichaJuridicaPUservice';
import { actualizarEstadoFichaNaturalPU } from '../../../services/vinculacion/fichaNaturalPUservice';
const initialState = {
  fileComposicionAccionaria: [],
  fileCuentaBancaria: [],
  fileCamaraComercio: [],
  fileRut: [],
  fileDocumentosRepLeg: [],
  fileDeclaracionRenta:[],
  fileEstadosFinancieros:[],
  fileMessageErrors: [],
  files:[],
  ListDocument:[],
  loading:false
};

const { Title } = Typography;
const mensajecargado = " ya has cargado este archivo anteriormente.";
class DocumentosAnexos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.form = React.createRef();
  }

  handleOnChangeUploadAccionario = (info) => {
    const fileList = info.fileList.length > 1 ? info.fileList : [info.file];

    this.isValidFile(fileList);
    this.setState({
      ...this.state,
      fileComposicionAccionaria:fileList,
    });
  };
  handleOnChangeUploadDecRent = (info) => {
    const fileList = info.fileList.length > 1 ? info.fileList : [info.file];

    this.isValidFile(fileList);
    this.setState({
      ...this.state,
      fileDeclaracionRenta:fileList,
    });
  };
  handleOnChangeUploadEstadosFin = (info) => {
    const fileList = info.fileList.length > 1 ? info.fileList : [info.file];

    this.isValidFile(fileList);
    this.setState({
      ...this.state,
      fileEstadosFinancieros:fileList,
    });
  };
  handleOnChangeUploadBancario = (info) => {
    const fileList = info.fileList.length > 1 ? info.fileList : [info.file] ;
    this.isValidFile(fileList);

    this.setState({
      ...this.state,
      fileCuentaBancaria:fileList,
    });
  };
  handleOnChangeUploadCamaraComercio = (info) => {
  const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
  this.isValidFile(fileList);

  this.setState({
    ...this.state,
    fileCamaraComercio:fileList,
  });
  };
  handleOnChangeUploadRut = (info) => {
    const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
    this.isValidFile(fileList);
  
    this.setState({
      ...this.state,
      fileRut:fileList,
    });
  };
  handleOnChangeUploadRepLg = (info) => {
    const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
    this.isValidFile(fileList);
  
    this.setState({
      ...this.state,
      fileDocumentosRepLeg:fileList,
    });
  };

  handleTerminar = async () => {

    const {tipoProducto,ListDocument,estadoFicha,tipoPersona,estadoVinculacion} = this.state;
    const {authtoken} = this.props
    const scCompania = JSON.parse(localStorage.getItem('personData')).scCompania;
    const idUser = JSON.parse(localStorage.getItem('personData')).idUser;
    let cdEstadoVinGeneral;
    let cdTipoProducto = tipoProducto;

    if(estadoFicha === "Completa"){
      if(tipoProducto === 'F' && estadoVinculacion === 'P'){
        cdEstadoVinGeneral = 'E';
        if(!(ListDocument.length >= 6)){

          notification.warn({
            message: 'Alerta',
            description: "por favor termine de cargar todos los documentos y guarde",
            duration: 4,
          })

          return;

        }

      }else if (tipoProducto === 'F' && estadoVinculacion === 'AP'){
        cdEstadoVinGeneral = 'CE';
        if(!(ListDocument.length >= 6)){

          notification.warn({
            message: 'Alerta',
            description: "porfavor termine de cargar todos los documentos y guarde",
            duration: 4,
          })

          return;

        }
  
      }else if (tipoProducto === 'F' && estadoVinculacion === 'DI'){

        cdEstadoVinGeneral = 'E';
        if(!(ListDocument.length >= 6)){

          notification.warn({
            message: 'Alerta',
            description: "porfavor termine de cargar todos los documentos y guarde",
            duration: 4,
          })

          return;

        }

      }else if (tipoProducto === 'C'){
        cdEstadoVinGeneral = 'E';
        if(!(ListDocument.length >= 5)){

          notification.warn({
            message: 'Alerta',
            description: "porfavor termine de cargar todos los documentos y guarde",
            duration: 4,
          })

          return;

        }

      }
  }else{  // ficha incompleta

    notification.warn({
      message: 'Alerta',
      description: "por favor termine de diligenciar la ficha en el paso 1",
      duration: 4,
    })

    return;
  }
  //peticion cambiar estado vinculacion
  let data = {
    cdEstadoVinGeneral,
    idUser,
    cdTipoProducto
  }
  try {
    this.setState({
      loading: true,
    });
    
    if(tipoPersona === 'J'){
      await actualizarEstadoFichaJuridicaPU(authtoken,scCompania,data);
    }else if (tipoPersona === 'N'){
      await actualizarEstadoFichaNaturalPU(authtoken,scCompania,data);
    }

    let jsonPersona = JSON.parse(localStorage.getItem('personData') || '');
      jsonPersona = {
        ...jsonPersona,
        estadoVinculacion : cdEstadoVinGeneral
      }
      localStorage.setItem('personData',JSON.stringify(jsonPersona));

    this.handleListDocumentAnexo();

    notification.success({
      message: 'Guardado',
      description: "Su registro ha sido guardado con éxito",
      duration: 5,
    })
    
  } catch (error) {

    notification.error({
      message: 'Error',
      description: "Error guardando ficha:" + error,
      duration: 4,
    })
    
  }finally{

    this.setState({
      loading: false,
    });

  }

  }

  validateSizeExtfile =  (files,name) => {
    let totalSize = 0;
    const validFileFormats = ['zip','rar','xlsx','xls','pdf','jpeg','png','7zip','doc','docx','pptx','ppt'];
    files && files.forEach(file =>{
      totalSize += file.size;
      if(!validFileFormats.includes(file.name.split('.').pop())){
        throw new Error("El documento: " + name + " no tiene el formato valido('zip','rar','xlsx','xls','pdf','jpeg','png','7zip','doc','docx','pptx','ppt')");
      }
    })
    if(totalSize > 10000000){
      throw new Error("Maximo de tamaño excedido en " + name + "(10MB)");
    }  
  }

  validateFormDataSize = (files) => {
    let totalSize = 0;
    Array.from(files.entries(), (file) => {
      file.forEach(input =>{
        if(input.size){totalSize += input.size} 
      })
    });
    if(totalSize > 20000000){
      throw new Error("El peso máximo permitido para todos los archivos es 20MB");
    } 
  }

  handleOnFinish = async () => {

    const {authtoken} = this.props
    const scCompania = JSON.parse(localStorage.getItem('personData')).scCompania;

    this.setState({
      loading: true,
    });

    const { 
      fileComposicionAccionaria,
      fileCuentaBancaria,
      fileCamaraComercio,
      fileEstadosFinancieros,
      fileDeclaracionRenta,
      fileRut,
      fileDocumentosRepLeg,
    } = this.state;

    
    if(fileCamaraComercio.length > 0 || fileComposicionAccionaria.length > 0 || fileCuentaBancaria.length > 0 
      || fileEstadosFinancieros.length > 0 || fileDeclaracionRenta.length > 0 || fileRut.length > 0 
      || fileDocumentosRepLeg.length > 0){

      try {

        const formData = new FormData();
        this.validateSizeExtfile(fileComposicionAccionaria,"Composición Accionaria");
        if(fileComposicionAccionaria.length > 1){
          fileComposicionAccionaria.forEach(file=>{
            formData.append('CA', file.originFileObj);
          });
        } else if(fileComposicionAccionaria.length === 1){
          formData.append('CA', fileComposicionAccionaria[0]);
        }

        this.validateSizeExtfile(fileCuentaBancaria,"Cuenta Bancaria");
        if(fileCuentaBancaria.length > 1){
          fileCuentaBancaria.forEach(file=>{
            formData.append('CCB', file.originFileObj);
          });
        } else if(fileCuentaBancaria.length === 1){
          formData.append('CCB', fileCuentaBancaria[0]);
        }

        this.validateSizeExtfile(fileCamaraComercio,"Cámara Comercio");
        if(fileCamaraComercio.length > 1){
          fileCamaraComercio.forEach(file=>{
            formData.append('CC', file.originFileObj);
          });
        } else if(fileCamaraComercio.length === 1){
          formData.append('CC', fileCamaraComercio[0]);
        }

        this.validateSizeExtfile(fileEstadosFinancieros,"Estados Financieros");
        if(fileEstadosFinancieros.length > 1){
          fileEstadosFinancieros.forEach(file=>{
            formData.append('EF', file.originFileObj);
          });
        } else if(fileEstadosFinancieros.length === 1){
          formData.append('EF', fileEstadosFinancieros[0]);
        }

        this.validateSizeExtfile(fileDeclaracionRenta,"Declaración de Renta");
        if(fileDeclaracionRenta.length > 1){
          fileDeclaracionRenta.forEach(file=>{
            formData.append('DR', file.originFileObj);
          });
        } else if(fileDeclaracionRenta.length === 1){
          formData.append('DR', fileDeclaracionRenta[0]);
        }

        this.validateSizeExtfile(fileRut,"RUT");
        if(fileRut.length > 1){
          fileRut.forEach(file=>{
            formData.append('RUT', file.originFileObj);
          });
        } else if(fileRut.length === 1){
          formData.append('RUT', fileRut[0]);
        }

        this.validateSizeExtfile(fileDocumentosRepLeg,"Cedula Rep Legal");
        if(fileDocumentosRepLeg.length > 1){
          fileDocumentosRepLeg.forEach(file=>{
            formData.append('CCRL', file.originFileObj);
          });
        } else if(fileDocumentosRepLeg.length === 1){
          formData.append('CCRL', fileDocumentosRepLeg[0]);
        }

        
        this.validateFormDataSize(formData);   
        const response = await grabarDocumentosAnexos(authtoken, formData, scCompania);
        notification.success({
          message: 'Documentos Guardados Con Éxito',
          description: response.message,
          duration: 5,
        });

        this.setState({
          ...this.state,
          fileComposicionAccionaria: [],
          fileCuentaBancaria: [],
          fileCamaraComercio: [],
          fileRut: [],
          fileDocumentosRepLeg: [],
          fileDeclaracionRenta:[],
          fileEstadosFinancieros:[],
        });

        this.handleListDocumentAnexo();
      }
      catch(error){
          notification.error({
            message: 'Error',
            description: error.message,
            duration: 5,
          })
      }
      finally {
          this.setState({
            ...this.state,
            loading: false,
          });
      }
            
  }else{
    this.setState({
      loading: false,
    });
    notification.warn({
      message: 'Acción no permitida',
      description: "No hay archivos precargados para guardar",
      duration: 4,
    
    })
  }
  };

  isValidFile = async (fileList) => {
    let fileMessageErrors = [];
    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }
    this.setState({ ...this.state, fileMessageErrors });
    return fileMessageErrors.length === 0;
  };

  handleListDocumentAnexo = async () =>{
      this.setState({
        ...this.state,
        loading:true
      });
      const {authtoken} = this.props
      const ls = localStorage.getItem('personData');
      if (ls != null){
        const scCompania = JSON.parse(ls).scCompania;
        const tipoProducto = JSON.parse(ls).tipoProducto;
        const estadoFicha = JSON.parse(ls).estadoFicha;
        const estadoVinculacion = JSON.parse(ls).estadoVinculacion;
        const tipoPersona = JSON.parse(ls).tipoPersona;
        try {
          const response = await listarDocumentosAnexos(authtoken,scCompania);
          this.setState({
            ...this.state,
            ListDocument: response.data,
            loading:false,
            tipoProducto: tipoProducto,
            estadoFicha,
            estadoVinculacion,
            tipoPersona
          });
        }
        catch{
          notification.error({
            message: 'Error',
            description: "Error en el servidor al buscar documentos Anexos",
            duration: 4,
          });}
        finally {
          this.setState({
            ...this.state,
            loading: false,
          });
        }
      }else{
        notification.error({
          message: 'Error',
          description: "Error listando Documentos Anexos",
          duration: 4,
        });
      }

      this.setState({
        ...this.state,
        loading: false,
      });
   
  }

  handleDescargarDocumentos = async () =>{
    this.setState({
      ...this.state,
      loading:true
    });
    const {authtoken} = this.props

    const scCompania = JSON.parse(localStorage.getItem('personData')).scCompania;
    try {
      const response = await descargarDocumentos(authtoken,scCompania);
      setExportZip(response,"Documentos Anexos");
    }
    catch{
      notification.error({
        message: 'Ocurrio Un Error',
        description: "No se logró descargar los archivos",
        duration: 4,
      });}
    finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  }

  componentDidMount() { 
    this.props.menuHandler('3');
    this.handleListDocumentAnexo();
  }

  render() {

    const { fileComposicionAccionaria,
      fileCuentaBancaria,
      fileCamaraComercio,
      fileRut,
      fileEstadosFinancieros,
      fileDeclaracionRenta,
      fileDocumentosRepLeg,
      fileMessageErrors,
      ListDocument,
      loading,
      tipoProducto,
      estadoVinculacion
    } = this.state;


    return (
        <div className='users-style'>
        <Spin tip='Cargando...' spinning={loading}>
            <h3><b>Estado Solicitud: </b>{mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.description}</h3>
            {estadoVinculacion === "E" ? 
              <b style={{fontSize: '16px'}}>
                {mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.message}
              </b>
            : estadoVinculacion === "CE" ?
              <b style={{fontSize: '16px'}}>
              {mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.message}
              </b>
            : estadoVinculacion === "RE" ?
              <b style={{fontSize: '16px'}}>
              {mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.message}
              </b>
            :
            <>
              <b style={{fontSize: '16px'}}>
              {mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.message}
              </b>
            <Card className='card-shadow card-radius default-border welcome-message'>
                <Form
                    name='content_form'
                    layout='vertical'
                >
                {/* Boton descargar documentos anexos dinamico */}
                 {ListDocument.length > 0 ?
                   <Row gutter={16} justify={'end'}>
                        <Col span={6}>
                            <Button
                                style={{ width: '70%' }}
                                className='btn btn-blue'
                                type='primary'
                                onClick={this.handleDescargarDocumentos}
                            >
                                Descargar
                            </Button>
                        </Col>
                    </Row>:''} 
                  <Divider />
                    {/* Camara de comercio*/}
                    <Row gutter={[16, 32]}>
                        <Col flex='auto'>
                            <Title level={4}>CÁMARA DE COMERCIO</Title>
                            <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}}>Este no debe ser mayor a 30 días</b></h5></h3>
                            {ListDocument.map((value) => (
                              value.tipodoc === 'CC'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                            ))}
                            <Form.Item 
                              noStyle
                              name='CC'>
                              <br/>
                            <Upload
                                beforeUpload={() => {
                                return false;
                                }}
                                fileList={fileCamaraComercio}
                                onChange={this.handleOnChangeUploadCamaraComercio}
                                maxCount={5}
                                multiple
                            >
                              <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
                            </Upload>
                            {fileMessageErrors.map((value) => (
                                <Text type='danger'>{value}</Text>
                            ))}
                            </Form.Item>
                        </Col>
                        {/* RUT*/}
                        <Col span={12}>
                            <Title level={4}>RUT</Title>
                            <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}} >RUT Actualizado</b></h5></h3>
                            {ListDocument.map((value) => (
                              value.tipodoc === 'RUT'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                            ))}
                            <Form.Item 
                              noStyle
                              name='RUT'>
                                <br/>
                            <Upload
                                beforeUpload={() => {
                                return false;
                                }}
                                fileList={fileRut}
                                onChange={this.handleOnChangeUploadRut}
                                maxCount={5}
                                multiple
                            >
                              <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
                            </Upload>
                            {fileMessageErrors.map((value) => (
                                <Text type='danger'>{value}</Text>
                            ))}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />

                    {/*COPIA DEL DOCUMENTO DE INDENTIDAD DEL REPRESENTANTE LEGAL*/}
                    <Row gutter={[16, 32]}>
                        <Col flex='auto'>
                            <Title level={4}>COPIA DE LA CÉDULA</Title>
                            <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}} >Copia de la cédula del representante legal</b></h5></h3>
                            {ListDocument.map((value) => (
                              value.tipodoc === 'CCRL'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                            ))}
                            <Form.Item 
                              noStyle
                              name='CCRL'>
                                <br/>
                            <Upload
                                beforeUpload={() => {
                                return false;
                                }}
                                fileList={fileDocumentosRepLeg}
                                onChange={this.handleOnChangeUploadRepLg}
                                maxCount={5}
                                multiple
                            >
                              <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
                            </Upload>
                            {fileMessageErrors.map((value) => (
                                <Text type='danger'>{value}</Text>
                            ))}
                            </Form.Item>
                        </Col>
                    {/* Certificado Cuenta Bancaria*/}
                          <Col span={12}>
                            <Title level={4}>CERTIFICADO CUENTA BANCARIA</Title>
                            <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}}>Este no debe ser mayor a 30 días</b></h5></h3>
                            {ListDocument.map((value) => (
                              value.tipodoc === 'CCB'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                            ))}
                            <Form.Item 
                              noStyle
                              name='CCB'>
                              <br/>
                            <Upload
                                beforeUpload={() => {
                                return false;
                                }}
                                fileList={fileCuentaBancaria}
                                onChange={this.handleOnChangeUploadBancario}
                                maxCount={5}
                                multiple
                            >
                              <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
                            </Upload>
                            {fileMessageErrors.map((value) => (
                                <Text type='danger'>{value}</Text>
                            ))}
                            </Form.Item>
                        </Col>
                    <Divider />
                    </Row>
                    <Row gutter={[16, 32]}>
                    {tipoProducto === "F" ?
                      <>
                      <Col flex='auto'>
                          <Title level={4}>DECLARACIÓN DE RENTA</Title>
                          <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}} >Último periodo disponible</b></h5></h3>
                          {ListDocument.map((value) => (
                            value.tipodoc === 'DR'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                          ))}
                          <Form.Item 
                            noStyle
                            name='DR'>
                              <br/>
                          <Upload
                              beforeUpload={() => {
                              return false;
                              }}
                              fileList={fileDeclaracionRenta}
                              onChange={this.handleOnChangeUploadDecRent}
                              maxCount={5}
                              multiple
                          >
                            <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
                          </Upload>
                          {fileMessageErrors.map((value) => (
                              <Text type='danger'>{value}</Text>
                          ))}
                          </Form.Item>
                      </Col>                      
                      <Col span={12}>
                          <Title level={4}>ESTADOS FINANCIEROS</Title>
                          <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}} >Estados financieros del cierre del año anterior y último corte de año en curso</b></h5></h3>
                          {ListDocument.map((value) => (
                            value.tipodoc === 'EF'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                          ))}
                          <Form.Item 
                            noStyle
                            name='EF'>
                              <br/>
                          <Upload
                              beforeUpload={() => {
                              return false;
                              }}
                              fileList={fileEstadosFinancieros}
                              onChange={this.handleOnChangeUploadEstadosFin}
                              maxCount={5}
                              multiple
                          >
                            <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
                          </Upload>
                          {fileMessageErrors.map((value) => (
                              <Text type='danger'>{value}</Text>
                          ))}
                          </Form.Item>
                      </Col>            
                      </>
                    :
                    <>
                      {/* Composicion Accionaria */}
                      <Col flex='auto'>
                            <Title level={4}>COMPOSICIÓN ACCIONARIA</Title>
                            <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}}>La sumatoria de los accionistas debe ser del 100%</b></h5></h3>
                            {ListDocument.map((value) => (
                              value.tipodoc === 'CA'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
                            ))}
                            <Form.Item 
                              noStyle
                              name='CA'>
                              <br/>
                              <Upload
                                  beforeUpload={() => {
                                  return false;
                                  }}
                                  fileList={fileComposicionAccionaria}
                                  onChange={this.handleOnChangeUploadAccionario}
                                  maxCount={5}
                                  multiple
                              >
                                  <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
                              </Upload>
                              {fileMessageErrors.map((value) => (
                                  <Text type='danger'>{value}</Text>
                              ))}
                            </Form.Item>
                        </Col>
                      </>
                    }
                  </Row>
                  <Divider />
                    {/* Boton Guardar */}
                    <Row align='middle' justify='center' gutter={16}>
                        <Col span={6}>
                            <Button
                                style={{ width: '90%' }}
                                className='btn btn-green'
                                type='primary'
                                onClick={this.handleOnFinish}
                            >
                                GUARDAR DOCUMENTOS
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button
                                style={{ width: '100%' }}
                                className='btn btn-blue'
                                type='primary'
                                onClick={this.handleTerminar}
                            >
                                TERMINAR REGISTRO
                            </Button>
                        </Col>
                    </Row>
                    
                </Form>
            </Card>
            </> }
        </Spin>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      authtoken: state.auth.auth.access_token,
      company: state.auth.auth.scPersona,
  };
};

export default connect(mapStateToProps, {})(DocumentosAnexos);

