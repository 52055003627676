import { DownloadOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React from 'react';
import { descargarDocumento } from '../../../../../services/pagador/supplierServices';
import { setExport } from '../../../../../utilities/downloadTools';

const Documents = ({ data, scProveedor, company, authToken }) => {
  const descargar = async (record) => {
    const response = await descargarDocumento(
      authToken,
      company,
      scProveedor,
      record.cdDocumento
    );

    setExport(response, record.nombre);
  };

  const documentsColumns = [
    {
      title: 'DOCUMENTACIÓN',
      dataIndex: 'nombre',
      showSorterTooltip: false,
    },
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'descripcion',
      showSorterTooltip: false,
    },
    {
      title: 'FECHA DE CARGA',
      dataIndex: 'feCarga',
      showSorterTooltip: false,
    },
    {
      title: 'FECHA DE VENCIMIENTO',
      dataIndex: 'feVencimiento',
      showSorterTooltip: false,
    },
    {
      title: 'DESCARGAR',
      render: (text, record) => (
        <DownloadOutlined
          title='Descargar'
          className='info'
          onClick={() => {
            descargar(record);
          }}
        />
      ),
      showSorterTooltip: false,
    },
  ];

  return (
    <div className='table-container'>
      <Table columns={documentsColumns} dataSource={data}></Table>
    </div>
  );
};

export default Documents;
