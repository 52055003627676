import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import React from 'react';
import { requiredValueMessage } from '../../../../../../utilities/admin_validation_messages';
import { DATE_FORMAT } from '../../../../../../utilities/messages';
import './personEntity.scss';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  cdTipoEmpresa: [{ required: true, message: requiredValueMessage() }],
  cdSociedadComercial: [{ required: true, message: requiredValueMessage() }],
  cdActividad: [{ required: true, message: requiredValueMessage() }],
  cdRegimen: [{ required: true, message: requiredValueMessage() }],
  cdSnAutorretenedor: [{ required: true, message: requiredValueMessage() }],
  cdTipoRetencion: [{ required: true, message: requiredValueMessage() }],
  otraEntEstatal: [{ required: true, message: requiredValueMessage() }],
  otraEntEstDescentralizada: [
    { required: true, message: requiredValueMessage() },
  ],
};

const tipoEmpresas = [
  { key: 'PRIVADA', description: 'Privada' },
  { key: 'PUBLICA', description: 'Pública' },
  { key: 'MIXTA', description: 'Mixta' },
];

const sociedadComercial = [
  { key: 'LIMITADA', description: 'Limitada' },
  { key: 'COLECTIVA', description: 'Colectiva' },
  { key: 'ANONIMA', description: 'Anónima' },
  { key: 'ACCIONES', description: 'En comandita por acciones' },
  { key: 'SIMPLE', description: 'En comandita simple' },
  { key: 'SUCURSAL', description: 'Sucursal de sociedad extranjera' },
  { key: 'UNIPER', description: 'Empresa unipersonal' },
  { key: 'LUCRO', description: 'Entidad sin ánimo de lucro' },
  { key: 'SAS', description: 'SAS' },
  { key: 'OTRO', description: 'Otro' },
];

const entidadesEstatal = [
  { key: 'NACION', description: 'Nación' },
  { key: 'DEP', description: 'Departamento' },
  { key: 'MUNICIPIO', description: 'Municipio' },
  { key: 'OTRA', description: 'Otra' },
];

const entidadesDesentralizada = [
  { key: 'ON', description: 'Orden nacional' },
  { key: 'OD', description: 'Orden departamental' },
  { key: 'OM', description: 'Orden municipal' },
  { key: 'EP', description: 'Establecimiento público' },
  { key: 'EICE', description: 'Empresa industrial y comercial del estado' },
  { key: 'SEM', description: 'Sociedad de economía mixta' },
  { key: 'ESE', description: 'Empresa social del estado' },
  { key: 'ESPD', description: 'Empresa de servicios públicos domiciliarios' },
  { key: 'OTRA', description: 'Otra' },
];

const regimen = [
  { key: 'C', description: 'Común' },
  { key: 'S', description: 'Simplificado' },
  { key: 'G', description: 'Gran contribuyente' },
];

const tipoRetencion = [
  { key: 'EXENTO', description: 'Exento' },
  { key: 'AUTO', description: 'Autorretenedor' },
  { key: 'SUJETO', description: 'Sujeto de retención' },
];

class PersonEntity extends React.Component {
  render() {
    const {
      actividadSelect,
      onActividadSearch,
      cdEntidadEstatal,
      cdEntidadDescentralizada,
      onChangeCdEntididadDescentralizada,
      onChangeCdEntidadEstatal,
    } = this.props;

    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoEmpresa'
                label='Tipo de empresa'
                rules={rules['cdTipoEmpresa'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {tipoEmpresas.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Operación con moneda extranjera:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdSnOpMontExt'
                      valuePropName='checked'
                      noStyle
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdSociedadComercial'
                label='Sociedad comercial o civil'
                rules={rules['cdSociedadComercial'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {sociedadComercial.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdEntidadEstatal'
                label='Entidades estatales'
                rules={rules['cdEntidadEstatal'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  onChange={onChangeCdEntidadEstatal}
                  allowClear
                >
                  {entidadesEstatal.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {cdEntidadEstatal === 'OTRA' && (
              <Col span={12}>
                <Form.Item
                  name='otraEntEstatal'
                  label='Otro ¿cuál?'
                  rules={rules['otraEntEstatal'] || ''}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdEntidadDescentralizada'
                label='Entidades estatales descentralizadas'
                rules={rules['cdEntidadDescentralizada'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  onChange={onChangeCdEntididadDescentralizada}
                  allowClear
                >
                  {entidadesDesentralizada.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {cdEntidadDescentralizada === 'OTRA' && (
              <Col span={12}>
                <Form.Item
                  name='otraEntEstDescentralizada'
                  label='Otro ¿cuál?'
                  rules={rules['otraEntEstDescentralizada'] || ''}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdActividad'
                label='Tipo de actividad'
                rules={rules['cdActividad'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  className='module-selector-select'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={onActividadSearch}
                  notFoundContent={
                    actividadSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {actividadSelect.options.map((ciudad, i) => (
                    <Select.Option key={i} value={ciudad.codigo}>
                      {ciudad.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='feConsAct'
                label='Fecha de constitución'
                rules={rules['feConsAct'] || ''}
              >
                <DatePicker
                  className='radius'
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='lugarAct'
                label='Lugar de constitución'
                rules={rules['lugarAct'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='feVigenciaAct'
                label='Fecha de vigencia'
                rules={rules['feVigenciaAct'] || ''}
              >
                <DatePicker
                  className='radius'
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='cdRegimen'
                label='Regimen tributario'
                rules={rules['cdRegimen'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {regimen.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='nmEmpleados'
                label='Número de empleados'
                rules={rules['nmEmpleados'] || ''}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmSucursales'
                label='Número de sucursales'
                rules={rules['nmSucursales'] || ''}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>Autoretenedor:</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdSnAutorretenedor'
                      valuePropName='checked'
                      noStyle
                      rules={rules['cdSnAutorretenedor']}
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdTipoRetencion'
                label='Tipo de retención'
                rules={rules['cdTipoRetencion'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {tipoRetencion.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <p>Posee sarlaft:</p>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdSnSarlaft'
                      valuePropName='checked'
                      noStyle
                      rules={rules['cdSnSarlaft']}
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <p>Exento de GMF:</p>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdSnGmf'
                      valuePropName='checked'
                      noStyle
                      rules={rules['cdSnGmf']}
                    >
                      <Switch checkedChildren='Si' unCheckedChildren='No' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default PersonEntity;
