import React, { Component } from 'react';
import { Row, Col, Card, Divider, Table, Spin } from 'antd';
import './quotesagreements.scss';
import { connect } from 'react-redux';
import { currencyFormat } from '../../../../utilities/textTools';
import {
  cupoActual,
  getLiquidez,
  listarUltimosCupos,
} from '../../../../services/admin/quotesagreementsServices';
import {
  listarFondeadores,
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../services/admin/reports/quotasService';
import QuotasAgreementsFilterFrom from './quotasAgreementsFilterFrom/quotasAgreementsFilterFrom';
import CounterpartQuotaForm from './counterpartQuotaForm/counterpartQuotaForm';
import NewLiquidityForm from './newLiquidityForm/newLiquidityForm';
import { listarEstadoCupo, listarTipoCupo, listarTipoProducto, listarTiposConvenios } from '../../../../services/admin/operation';

const intitalState = {
  visibleLiquidez: true,
  loading: false,
  scFondeador: '',
  scEntidad: '',
  tipoEntidad: 'PAG',
  tipoCupo:'',
  tipoProducto:'',
  estadoCupo:'',
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
  entidadSelect: {
    fetching: false,
    options: [],
  },
  cupoActual: {
    scEntidad: '',
    scFondeador: '',
    feDesde: '',
    feHasta: '',
    nmCupoContraparte: 0,
    nmCupoContraparteVig: 0,
    consCupoOferta: 0,
    liquidez: 0,
    descripcion: '',
    tipoCupo:'',
    tipoProducto:'',
    estadoCupo:''
  },
  programarLiquidez: {},
  liquidezActual: {
    consLiquidez: 0,
    fechaIngreso: '',
    fechaModifica: '',
    liquidez: 0,
    liquidezOfertada: 0,
    scFondeador: '',
    scPersonaIngreso: '',
    snOfertaFull: 'N',
    descripcion: '',
  },
  resultado: [],
  tipoProductoData: [],
  tipoCupoData: [],
  estadoCupoData:[]
};

class Quotasagreements extends Component {
  constructor(props) {
    super(props);
    this.state = { ...intitalState };

    this.columnas = [
      {
        title: 'FECHA CUPO',
        dataIndex: 'feHasta',
        showSorterTooltip: false,
      },
      {
        title: 'CUPO CONTRAPARTE ASIGNADO',
        dataIndex: 'nmCupoContraparte',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'SALDO DE CONTRAPARTE VIGENTE',
        dataIndex: 'nmCupoContraparteVig',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL PAGOS RECIBIDOS',
        dataIndex: 'nmPagRecibidos',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO CUPO',
        dataIndex: 'tipoCupo',
        render: (value) => (
          <span>{(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO PRODUCTO',
        dataIndex: 'tipoProducto',
        render: (value) => (
          <span>{(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO CUPO',
        dataIndex: 'estadoCupo',
        render: (value) => (
          <span>{value === 'A' ? 'Activo' : 'Inactivo'}</span>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  componentDidMount() {
    this.props.menuHandler('40');
    this.handleOnChangeTipoEntidad(intitalState.tipoEntidad);
    this.getInitFondeadorData();
    this.getInitTipoProducto();
    this.getInitTipoCupo();
    this.getInitEstadoCupo();
  }

  handleOnSearchEntidad = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { entidadSelect, tipoEntidad } = this.state;

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          fetching: true,
        },
      });

      try {
        let response = null;
        if (tipoEntidad === 'PAG') {
          response = await listarPagadores(this.props.authToken, {
            nombre: nombre,
          });
        } else if (tipoEntidad === 'PRO') {
          response = await listarProveedores(this.props.authToken, {
            nombre: nombre,
          });
        } else {
          response = await listarGruposEmpresariales(this.props.authToken, {
            nombre: nombre,
          });
        }

        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  getInitFondeadorData = async () => {
    const { fondeadorSelect, scEntidad } = this.state;

    this.setState({
      ...this.state,
      fondeadorSelect: {
        ...fondeadorSelect,
        fetching: true,
      },
    });

    try {
      const response = await listarFondeadores(this.props.authToken, {
        scEntidad: scEntidad,
        limit: 100,
      });
      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  getInitTipoProducto = async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoProductoRes = await listarTipoProducto(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoProductoData: tipoProductoRes.data,
    });
  };

  
  getInitTipoCupo= async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoCupoRes = await listarTipoCupo(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoCupoData: tipoCupoRes.data,
    });
  };

  getInitEstadoCupo= async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const estadoCupoRes = await listarEstadoCupo(authToken);
    this.setState({
      ...this.state,
      loading: false,
      estadoCupoData: estadoCupoRes.data,
    });
  };

  handleOnChangeEntidad = async (e) => {
    this.state.scEntidad = e;
    this.state.cupoActual = intitalState.cupoActual;
    this.state.resultado = intitalState.resultado;

    const { entidadSelect, tipoEntidad } = this.state;

    this.setState({
      ...this.state,
      entidadSelect: {
        ...entidadSelect,
        fetching: true,
      },
    });

    try {
      let response = null;
      if (tipoEntidad === 'PAG') {
        response = await listarPagadores(this.props.authToken, {
          limit: 50,
        });
      } else if (tipoEntidad === 'PRO') {
        response = await listarProveedores(this.props.authToken, {
          limit: 50,
        });
      } else {
        response = await listarGruposEmpresariales(this.props.authToken, {
          limit: 50,
        });
      }

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleOnChangeFondeador = async (e) => {
    this.state.scFondeador = e;
    this.state.cupoActual = intitalState.cupoActual;
    this.state.resultado = intitalState.resultado;

    const { scFondeador } = this.state;

    try {
      const liquidez = await getLiquidez(this.props.authToken, scFondeador);

      this.setState({
        ...this.state,
        liquidezActual: liquidez,
        loading: false,
        visibleLiquidez: true,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        visibleLiquidez: false,
        liquidezActual: intitalState.liquidezActual,
      });
    }
  };

  getData = async (values) => {
    const { scEntidad, scFondeador, tipoEntidad} = this.state;
    const data = {
      tipoEntidad,
      tipoCupo: values.tipoCupo,
      tipoProducto: values.tipoProducto,
      estadoCupo: values.estadoCupo
    }

    this.setState({
      ...this.state,
      loading: true,
    });

    if (!scFondeador) {
      this.setState({
        ...this.state,
        loading: false,
      });
      return;
    }

    let cupoActualData = {
      data: intitalState.cupoActual,
    };

    let cuposResponse = {
      data: intitalState.resultado,
    };
    try {
      if (
        scFondeador !== '' &&
        scEntidad !== '' &&
        scFondeador !== undefined &&
        scEntidad !== undefined
      ) {
        cupoActualData = await cupoActual(
          this.props.authToken,
          scFondeador,
          scEntidad,
          data
        );

        cuposResponse = await listarUltimosCupos(
          this.props.authToken,
          scFondeador,
          scEntidad,
          data
        );
      }
      
      this.setState({
        ...this.state,
        loading: false,
        visibleLiquidez: false,
        cupoActual: cupoActualData.data,
        resultado: cuposResponse.data,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        visibleLiquidez: false,
        cupoActual: intitalState.cupoActual,
      });
    }
  };

  getDataLiquidez = async () => {
    const { scFondeador } = this.state;

    this.setState({
      ...this.state,
      loading: true,
    });

    if (!scFondeador) {
      this.setState({
        ...this.state,
        loading: false,
      });
      return;
    }

    this.handleOnChangeFondeador(scFondeador);
  };

  handleOnChangeTipoEntidad = async (e) => {
    const { entidadSelect } = this.state;

    // SI quiero que cargue mientras busque
    // this.setState({
    //   ...this.state,
    //   loading: true,
    //   entidadSelect: {
    //     ...entidadSelect,
    //     fetching: true,
    //   },
    // });

    let response = null;
    if (e === 'PAG') {
      response = await listarPagadores(this.props.authToken, {
        limit: 50,
      });
    } else if (e === 'PRO') {
      response = await listarProveedores(this.props.authToken, {
        limit: 50,
      });
    } else {
      response = await listarGruposEmpresariales(this.props.authToken, {
        limit: 50,
      });
    }
    this.setState({
      ...this.state,
      loading: false,
      tipoEntidad: e,
      entidadSelect: {
        ...entidadSelect,
        options: response.data,
        fetching: false,
      },
    });
  };

  handleOnSearchCupos = async (values) => {
    this.getData(values);
  };

  handleOnSearchLiquidez = async (e) => {
    const { liquidezActual } = this.state;

    if (
      liquidezActual === null ||
      liquidezActual === undefined ||
      liquidezActual === ''
    ) {
      this.setState({
        ...this.state,
        liquidezActual: intitalState.liquidezActual,
      });
    }
  };

  render() {
    const {
      loading,
      fondeadorSelect,
      entidadSelect,
      scFondeador,
      tipoEntidad,
      scEntidad,
      cupoActual,
      resultado,
      liquidezActual,
      tipoCupoData,
      tipoProductoData,
      estadoCupoData
    } = this.state;

    const { nmCupoContraparte, consCupoOferta, feHasta } = cupoActual;

    const { liquidez, consLiquidez, liquidezOfertada } = liquidezActual;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='operation-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <QuotasAgreementsFilterFrom
              fondeadorSelect={fondeadorSelect}
              entidadSelect={entidadSelect}
              handleOnSearchFondeador={this.handleOnSearchFondeador}
              handleOnSearchEntidad={this.handleOnSearchEntidad}
              handleOnChangeFondeador={this.handleOnChangeFondeador}
              handleOnChangeEntidad={this.handleOnChangeEntidad}
              handleOnChangeTipoEntidad={this.handleOnChangeTipoEntidad}
              handleOnSearchCupos={this.handleOnSearchCupos}
              scEntidad={scEntidad}
              nmCupoContraparte={nmCupoContraparte}
              consCupoOferta={consCupoOferta}
              liquidez={liquidez}
              liquidezOfertada={liquidezOfertada}
              liquidezNegociada={consLiquidez}
              feHasta={feHasta}
              tipoProducto={tipoProductoData}
              tipoCupo={tipoCupoData}
            />

            <div className='table-container'>
              <Row gutter={30} className='bill-selection'>
                <Col span={12}>
                  <p className='title'>Lista de cupos</p>
                </Col>
                <Col span={12} className='export'>
                  <Row gutter={16}>
                    <Col span={12}>
                      <span className='button'>
                        <CounterpartQuotaForm
                          scFondeador={scFondeador}
                          authToken={this.props.authToken}
                          tipoEntidad={tipoEntidad}
                          scEntidad={scEntidad}
                          cupoActual={cupoActual}
                          onFinish={() => this.getData()}
                          handleOnSearchCupos={this.handleOnSearchCupos}
                          liquidezActual={liquidezActual}
                          tipoProducto={tipoProductoData}
                          tipoCupo={tipoCupoData}
                          estadoCupo={estadoCupoData}
                        />
                      </span>
                    </Col>
                    <Col span={12}>
                      <span className='button'>
                        <NewLiquidityForm
                          liquidez={liquidezActual}
                          scFondeador={scFondeador}
                          authToken={this.props.authToken}
                          handleOnSearchLiquidez={this.handleOnSearchLiquidez}
                          onFinish={() => this.getDataLiquidez()}
                        />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
              <Table
                columns={this.columnas}
                dataSource={resultado}
                pagination={false}
              ></Table>
            </div>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Quotasagreements);
