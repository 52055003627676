import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_PAGADOR;

export const listarFondeadores = (authtoken, scPagador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scPagador}/listar-fondeadores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarProveedores = (authtoken, scPagador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scPagador}/listar-proveedores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarConvenios = (authtoken, scPagador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/listar-convenios?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};


export const listarTipoBeneficio = (authtoken, scPagador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/listar-tipoBeneficio`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const transaccion = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas/facturas-tramites?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const transaccionExcel = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas/facturas-tramites/exportar/xlsx?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const nopago = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas/pagador-pagos-pendientes?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const nopagoExcel = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas/pagador-pagos-pendientes/exportar/xlsx?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const tasa = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas/pagador-tasa?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const tasaExcel = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas/pagador-tasa/exportar/xlsx?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const oferta = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/ofertas/ofertas-dia-proveedores?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const ofertaExcel = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/ofertas/ofertas-dia-proveedores/exportar/xlsx?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const error = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/errores-erp/listar-errores-erp?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const errorExcel = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/errores-erp/listar-errores-erp/exportar/xlsx?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const soporte = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas-exp/pagos-comisiones?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const soporteExcel = (authtoken, scPagador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas-exp/pagos-comisiones/exportar/xlsx?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const soporteFactura = (authtoken, scPagador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas-exp/pagos-comisiones/descargar-factura?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const soporteComision = (authtoken, scPagador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scPagador}/facturas-exp/pagos-comisiones/descargar-comision?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') ||
          nombre.includes('Hasta') ||
          nombre.includes('fechaPago')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        if (filtros[nombre] && nombre !== 'currentPage') {
          empty[nombre] = filtros[nombre];
        }
      }
    }
  });
  return empty;
};
