import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  emailPattern,
  multiEmailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import { tipoIds } from '../../../../../utilities/constants';

const PAYERS_TITLE = 'Pagadores';
const SELECT_PLACE_HOLDER = 'Seleccione...';

const estadoPersonas = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
  { key: 'P', description: 'Prospecto' },
];

const tipoPersonas = [
  {
    key: 'N',
    description: 'Natural',
  },
  {
    key: 'J',
    description: 'Jurídica',
  },
];

const dias = [
  { label: 'LUNES', value: 'Lunes' },
  { label: 'MARTES', value: 'Martes' },
  { label: 'MIÉRCOLES', value: 'Miercoles' },
  { label: 'JUEVES', value: 'Jueves' },
  { label: 'VIERNES', value: 'Viernes' },
];

const tipoPagosFondeador = [
  { key: 'A', description: 'Anterior' },
  { key: 'P', description: 'Posterior' },
];

const tipoPagador = [
  { key: 'C', description: 'Confirming' },
  { key: 'F', description: 'Factoring' },
];

const rules = {
  cdTipoPersona: [{ required: true, message: requiredValueMessage() }],
  cdEstado: [{ required: true, message: requiredValueMessage() }],
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
    {
      pattern: /^[0-9a-zA-Z-]*$/,
      message: formatValueMessage(),
    },
  ],
  razonSocial: [
    { required: true, message: requiredValueMessage() },
    { max: 300, message: maxValueMessage(300) },
  ],
  prNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  prApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  dsEmailFacturacion: [
    {
      pattern: emailPattern,
      message: formatValueMessage(),
    },
    { max: 100, message: maxValueMessage(100) },
  ],
  cdDivision: [{ max: 10, message: maxValueMessage(10) }],
  nmPagosPagador: [{ required: true, message: requiredValueMessage() }],
  cdTipoPagoFon: [{ required: true, message: requiredValueMessage() }],
  cdTipoPagador: [{ required: true, message: requiredValueMessage() }],
  dsEmailNotif: [
    {
      pattern: multiEmailPattern,
      message: formatValueMessage(),
    },
    { max: 200, message: maxValueMessage(100) },
  ],
};

export const initialPayerValuesForm = {
  cdTipoPersona: undefined,
  cdEstado: 'P',
  dsEstado: estadoPersonas.find((value) => value.key === 'P').description,
  cdTipoId: undefined,
  id: '',
  razonSocial: '',
  prNombre: '',
  segNombre: '',
  prApellido: '',
  segApellido: '',
  dsEmailFacturacion: '',
  dsEmailNotif: '',
  cdDivision: '',
  nmPagosPagador: '1',
  cdTipoPagoFon: undefined,
  cdTipoPagador: undefined,
  diasPagosFond: [],
};

const BasicDataForm = ({
  onFinish,
  data,
  visibleCalendar = true,
  visibleSearchButton = true,
  onPayerSearch,
  onConfigCalendar,
  children,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [cdTipoPersona, setCdTipoPersona] = useState('');
  const [cdEstado, setCdEstado] = useState('');

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    setCdTipoPersona(data.cdTipoPersona);
    setCdEstado(data.cdEstado);

    form.setFieldsValue({
      ...data,
    });
  }, [form, data]);

  const handleCdTipoPersonaChange = (cdTipoPersona) => {
    setCdTipoPersona(cdTipoPersona);
  };

  const handleOnFinish = (formValue) => {
    onFinish({ ...formValue });
  };

  const handleOnConfigCalendar = () => {
    if (form.getFieldError('nmPagosPagador').length > 0) {
      notification.success({
        message: PAYERS_TITLE,
        description: 'debe asigna al menos 1 Calendario de pagos',
        duration: 6,
      });
    }
    onConfigCalendar(form.getFieldValue('nmPagosPagador'));
  };

  return (
    <Form
      name='content_form'
      layout='vertical'
      form={form}
      onFinish={handleOnFinish}
      initialValues={initialPayerValuesForm}
    >
      <Form.Item name='scPersona' hidden='true'>
        <Input />
      </Form.Item>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Datos personales
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoPersona'
            label='TIPO DE PERSONA'
            rules={rules['cdTipoPersona']}
          >
            <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
              onChange={handleCdTipoPersonaChange}
            >
              {tipoPersonas.map((tipoPersona) => (
                <Select.Option key={tipoPersona.key} value={tipoPersona.key}>
                  {tipoPersona.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='cdEstado' label='ESTADO' rules={rules['cdEstado']}>
            <Select allowClear placeholder={SELECT_PLACE_HOLDER}>
              {estadoPersonas.map((estadoPersona) => (
                <Select.Option
                  key={estadoPersona.key}
                  value={estadoPersona.key}
                >
                  {estadoPersona.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoId'
            label='TIPO DE IDENTIFICACIÓN'
            rules={rules['cdTipoId']}
          >
            <Select allowClear placeholder={SELECT_PLACE_HOLDER}>
              {tipoIds.map((tipoId) => (
                <Select.Option key={tipoId.key} value={tipoId.key}>
                  {tipoId.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='IDENTIFICACIÓN' required>
            <Row gutter={[8, 0]}>
              <Col flex='auto'>
                <Form.Item name='id' rules={rules['id']} noStyle>
                  <Input />
                </Form.Item>
              </Col>
              {visibleSearchButton && (
                <Col flex='none' style={{ marginTop: '0.2rem' }}>
                  <Button
                    type='dashed'
                    size='small'
                    shape='circle'
                    icon={<SearchOutlined />}
                    onClick={() =>
                      onPayerSearch({ id: form.getFieldValue('id') })
                    }
                  />
                </Col>
              )}
            </Row>
          </Form.Item>
        </Col>
      </Row>

      {cdTipoPersona === 'J' && (
        <Row gutter={16}>
          <Col flex={'auto'}>
            <Form.Item
              name='razonSocial'
              label='RAZÓN SOCIAL'
              rules={rules['razonSocial']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}

      {cdTipoPersona === 'N' && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prNombre'
                label='PRIMER NOMBRE'
                rules={rules['prNombre']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segNombre'
                label='SEGUNDO NOMBRE'
                rules={rules['segNombre']}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prApellido'
                label='PRIMER APELLIDO'
                rules={rules['prApellido']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segApellido'
                label='SEGUNDO APELLIDO'
                rules={rules['segApellido']}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='dsEmailFacturacion'
            label='CORREO ELECTRÓNICO FACTURACIÓN'
            rules={rules['dsEmailFacturacion']}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='cdDivision'
            label='DIVISIÓN'
            rules={rules['cdDivision']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='dsEmailNotif'
            label='CORREO ELECTRÓNICO NOTIFICACIONES LEGALES'
            rules={rules['dsEmailNotif']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información de fechas para pago
          </Space>
        </Col>
      </Row>
      <Divider />
      {visibleCalendar && (
        <Row gutter={16} justify='space-around' align='middle'>
          <Col span={12}>
            <Form.Item
              name='nmPagosPagador'
              label='NÚMERO DE CALENDARIOS DE PAGO'
              rules={rules['nmPagosPagador'] || ''}
            >
              <InputNumber style={{ width: '100%' }} min={1} precision={0} />
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: 'center' }}>
            <Button
              className='btn btn-blue'
              type='primary'
              onClick={handleOnConfigCalendar}
            >
              Configurar
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col>
          <Space
            align='baseline'
            className='sub-title'
            style={{ marginBottom: '1.3rem' }}
          >
            Días de pago fondeador
          </Space>
        </Col>
      </Row>

      <Row gutter={16} align='middle' justify='center'>
        <Col flex='auto'>
          <div className='check'>
            <Form.Item name={'diasPagosFond'}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row align='middle' justify='center'>
                  {dias.map((dia, index) => (
                    <Col span={4} key={index}>
                      <Form.Item label={dia.label}>
                        <Checkbox value={dia.value} />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoPagoFon'
            label='TIPO DE PAGO'
            rules={rules['cdTipoPagoFon']}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {tipoPagosFondeador.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoPagador'
            label='TIPO DE PAGADOR'
            rules={rules['cdTipoPagador']}
          >
            <Select
              placeholder={SELECT_PLACE_HOLDER}
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              filterOption={(input, option) => {
                return (
                  option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >=
                  0
                );
              }}
            >
            {tipoPagador && 
            tipoPagador.map((opcion) => (
              <Select.Option key={opcion.key} value={opcion.key}>
                {opcion.description}
            </Select.Option>
            ))}  
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {children}
        <Col span={6}>
          <Button
            style={{ width: '90%' }}
            className='btn btn-green'
            type='primary'
            htmlType='submit'
          >
            Grabar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicDataForm;
