import axios from 'axios'
import { listarCuentasProveedor } from './billServices'
import { middleWare } from '../middleware'

var baseUrl = process.env.REACT_APP_SERVICES_URL


const listAgreements = (authToken, scProveedor) => {

    return middleWare(axios({
        url: `${baseUrl}/api/descAuto/listarConveniosProveedorDescAuto/${scProveedor}`,
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`
        },
        data: null,
    }))
}

const listAgreementsNew = (authToken, scProveedor) => {

    return middleWare(axios({
        url: `${baseUrl}/api/descAuto/listarConveniosProveedorSinDescAuto/${scProveedor}`,
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`
        },
        data: null,
    }))
}

const updateAgreement = (authToken, scProveedor, data, affitoken) => {
    return middleWare(axios({
        url: `${baseUrl}/api/descAuto/actualizarConvenioProveedorDescAuto/${scProveedor}`,
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`
        },
        data: { affitoken, data },
    }))
}
const insertAgreement = (authToken, scProveedor, data, affitoken) => {
    return middleWare(axios({
        url: `${baseUrl}/api/descAuto/insertarConvenioProveedorDescAuto/${scProveedor}`,
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`
        },
        data: { affitoken, data },
    }))
}

export default {
    listAgreements,
    listarCuentasProveedor,
    updateAgreement,
    listAgreementsNew,
    insertAgreement
}
