import React from 'react';
import './error.scss';
import '../../../styles/standard.scss';
import { Row, Col, Button } from 'antd';
import Woman from '../../../assets/img/error-woman.png'
import { connect } from 'react-redux';
import history from '../../../services/history'

const error1 = (
    <Col span={12} className="text">
        <div className="title">
            ¡El tiempo vuela!
        </div>
        <div className="description">
            Nuestro horario para negociar facturas ha finalizado. Da clic en el siguiente botón para programara facturas.
        </div>
        <div className="button">
            <Button> Ir a programar</Button>
        </div>
    </Col>);

const error2 = (
    <Col span={12} className="text">
        <div className="title">
            Ups! Parece que no tienes facturas disponibles
        </div>
        <div className="description">
            Intenta más tarde o contáctanos si crees que se trata de un error.
        </div>
    </Col>
);

class Error extends React.Component {

    state = {
        activeError: error2
    }

    componentDidUpdate(props, state) {
        if (props.company != this.props.company) {
            history.push(`${process.env.PUBLIC_URL}/dashboard`)
        }
    }


    render() {
        return (
            <Row className="error-style content-margin container">
                <Col span={12} className="woman">
                    <img src={Woman} alt="" />
                </Col>
                {this.state.activeError}
            </Row>
        )
    }
}
function mapStateToProps(state) {
    return {
        company: state.billing.company
    }
}

export default connect(mapStateToProps, {})(Error);