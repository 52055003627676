import React, { Component } from 'react';
import  Rates from './rates/rates';
class BulkRatesUpdate extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.props.menuHandler('45')
  }

  render() {
    return (
      <div className="content-margin bulkload-style">
        <div className="card-background-light card-shadow radius">
          <div className="align-center">
            <Rates type="tasas" />
          </div>
        </div>
      </div>
    )
  }
}

export default BulkRatesUpdate