import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL;

const upload = (authtoken, company, files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file, file.filename);
  });

  return middleWare(
    axios({
      url: `${baseUrl}/api/${company}/cargar-archivo`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

const confirm = (authtoken, company, filename, extensionArchivo) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${company}/cargar-archivo/aceptar/${filename}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: extensionArchivo,
    })
  );
};

const guardar = (authtoken, company, values, nombreArchivo) => {
  if (values.tipoCalendarioPago === 'M') {
    values.calendarioPagoM = Object.values(values.calendarioPagoM);
  }

  if (values.tipoCalendarioPago === 'C') {
    values.calendarioPagoC = values.calendarioPagoC.format('YYYY-MM-DD');
  }

  return middleWare(
    axios({
      url: `${baseUrl}/api/${company}/guardarFactura/${nombreArchivo}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: values,
    })
  );
};

const downloadTemplate = (authtoken, company) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${company}/plantilla`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

const guardarAgile = (authtoken, company, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${company}/guardarFacturaAgile`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

export default {
  upload,
  confirm,
  guardar,
  downloadTemplate,
  guardarAgile,
};
