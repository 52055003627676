import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarConvenios = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios/listar-convenios?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const getConvenio = (authtoken, scConvenio) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios/${scConvenio}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTiposConvenios = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-convenios`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTipoProducto= (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-producto`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTipoCupo = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-cupo`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarEstadoCupo = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/estado-registro`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTiposCobros = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-cobros`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const crearConvenio = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const editarConvenio = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios/grabar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const listarConveniosFondeador = (authtoken, scConvenio) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-fondeador/convenios/${scConvenio}/listar-fondeadores`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarConveniosProveedores = (authtoken, scConvenio, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-proveedor/convenios/${scConvenio}/listar-proveedores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const exportarExcelConvenioProveedores = (
  authtoken,
  scConvenio,
  filtros
) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-proveedor/convenios/${scConvenio}/listar-proveedores/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFondeadores = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-fondeadores`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarProveedores = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-proveedores?${queryString.stringify(
        filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarPagadores = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-pagadores?${queryString.stringify(
        filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaCategorias = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/categorias`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaCategoriasProveedor = (authtoken, tipoConvenio) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${tipoConvenio}/categorias`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaTipoBaseDias = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-base-dias`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaFormatoACH = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/formato-ach`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaTipoTasaProveedor = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-tasa-proveedor`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaBancos = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-bancos`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const crearConvenioFondeadorConfirming = (
  authtoken,
  scConvenio,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-fondeador/convenios/${scConvenio}/confirming/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const editarConvenioFondeadorConfirming = (
  authtoken,
  scConvenio,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-fondeador/convenios/${scConvenio}/confirming/grabar/${data.scFondeador}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const crearConvenioFondeadorProntoPago = (
  authtoken,
  scConvenio,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-fondeador/convenios/${scConvenio}/pronto-pago/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const editarConvenioFondeadorProntoPago = (
  authtoken,
  scConvenio,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-fondeador/convenios/${scConvenio}/pronto-pago/grabar/${data.scFondeador}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const crearConvenioProveedor = (authtoken, scConvenio, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-proveedor/convenios/${scConvenio}/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const editarConvenioProveedor = (
  authtoken,
  scConvenio,
  scProveedor,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-proveedor/convenios/${scConvenio}/grabar/${scProveedor}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const calcularRangos = (
  authtoken,
  scConvenio,
  cdTipoCalculoVolumen,
  filtros
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-fondeador/convenios/${scConvenio}/calcular-rango/${cdTipoCalculoVolumen}?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTiposPago = (authtoken, scPagador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${scPagador}/calendario-pagos/listar-pagos`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCompanias = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-companias?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFondeadoresAprobados = (authtoken, scConvenio, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-proveedor/convenios/${scConvenio}/proveedores/listar-fondeadores-aprobados?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD');
      }
    }
  });
  return filtros;
};

export const duplicarConvenio = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios/duplicar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};
