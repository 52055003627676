import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarProveedores = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-proveedores`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarPagadores = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-pagadores`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaGruposEmpresariales = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/grupos-empresariales/listar-grupos`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarConvenios = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios/listar-convenios`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTiposConvenios = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-convenios`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFondeadores = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-fondeadores`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

/* #endregion */

export const listarPagosPendientes = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/pagadores/listar-pagos-pendientes?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const editarMarcarPagoAll = (
  authtoken,
  facturas,
  filtros,
  seleccionarTodo
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/marcar-pago/all`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: {
        ...filtros,
        scFacturasSeleccionadas: facturas,
        seleccionarTodo,
      },
    })
  );
};

export const editarMarcarPago = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/marcar-pago`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: {
        ...data,
      },
    })
  );
};
