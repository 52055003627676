import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Input, DatePicker, Button, Select, Spin } from 'antd';

import moment from 'moment';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';

const initialValue = {
  fechaRango: [],
  scLoteAch: '',
};

const AchHistoryFilterForm = ({
  handleSubmit,
  fondeadorSelect,
  onSearchFondeador,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='scFondeador'
              label='FONDEADOR'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un fondeador',
                },
              ]}
            >
              <Select
                name='scFondeador'
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => onSearchFondeador(value)}
                notFoundContent={
                  fondeadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {fondeadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='scLoteAch' label='No. LOTE'>
              <Input name='scLoteAch' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name='fechaRango' label='RANGO DE FECHAS DE CREACIÓN'>
              <DatePicker.RangePicker name='fechaRango' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AchHistoryFilterForm;
