import axios from 'axios';
import { middleWare } from '../../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;


export const resumenCompraProgramacion = (
  authToken,
  data
) => {
    let filters = formatoFechas(data)
  return middleWare(
  axios({
    url: `${baseUrl}/api/programar/listarPreOfertaPorProveedor?fechaOferta=${filters.fechaDesde}&scProveedor=${filters.scProveedor}`,

    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  })
  );
};

export const listarOfertaCompraProgramacion = (authToken, data) => {
  let filters = formatoFechas(data) 
  return middleWare(
  axios({
    url: `${baseUrl}/api/programar/listarOfertaCompra?${queryString.stringify(
        filters
      )}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  })
  );
};

export const listarDiasHabiles = (authToken) => {
  return middleWare(
  axios({
    url: `${baseUrl}/api/programar/listarDiasHabiles`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  })
  );
};

export const ofertasProgramacionExcel = (authToken, data) => {
  let filters = formatoFechas(data) 
  return middleWare(
  axios({
    url: `${baseUrl}/api/programar/listarOfertaCompra/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) || nombre.includes('Oferta') &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        if (filtros[nombre]) {
          empty[nombre] = filtros[nombre];
        }
      }
    }
  });
  return empty;
};