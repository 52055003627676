import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import {
  currencyFormat,
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import {
  listarConvenios,
  listarCuposPagadores,
  listarCuposPagadoresExcel,
  listarFacturas,
  listarFacturasExcel,
  listarFacturasPagadasExcel,
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../services/fondeador/reportServices';
import TableReport from '../../../../components/tableReport/tableReport';
import { setExcel } from '../../../../utilities/downloadTools';
import PayerQuotaFilterForm from './payerQuotaFilterForm/payerQuotaFilterForm';

const initialState = {
  form: {
    scConvenio: '',
    scPagador: '',
    scGrupo: '',
    fechaHasta: '',
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 7,
    },
    {
      id: 'sumaNmCupoContraparte',
      value: currencyFormat(0),
      index: 'nmCupoContraparte',
      colSpan: 1,
    },
    {
      id: 'sumaNmCupoContraparteVig',
      value: currencyFormat(0),
      index: 'nmCupoContraparteVig',
      colSpan: 1,
    },
    {
      id: 'sumaNmLiquidez',
      value: currencyFormat(0),
      index: 'liquidez',
      colSpan: 1,
    },
    {
      id: 'sumaNmLiquidezReal',
      value: currencyFormat(0),
      index: 'liquidezReal',
      colSpan: 2,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class PayerQuota extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'TIPO CONVENIO',
        dataIndex: 'tipoConvenio',
        sorter: (a, b) => sorterText(a.tipoConvenio, b.tipoConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => sorterText(a.nombreConvenio, b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO ID',
        dataIndex: 'tipoIdPagador',
        sorter: (a, b) => sorterText(a.tipoIdPagador, b.tipoIdPagador),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'idPagador',
        sorter: (a, b) => sorterText(a.idPagador, b.idPagador),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA HASTA',
        dataIndex: 'fechaHasta',
        sorter: (a, b) => sorterDate(a.fechaHasta, b.fechaHasta),
        showSorterTooltip: false,
      },
      {
        title: 'CUPO CONTRAPARTE ASIGNADO',
        dataIndex: 'nmCupoContraparte',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.nmCupoContraparte - b.nmCupoContraparte,
        showSorterTooltip: false,
      },
      {
        title: 'CUPO CONTRAPARTE VIGENTE',
        dataIndex: 'nmCupoContraparteVig',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.nmCupoContraparteVig - b.nmCupoContraparteVig,
        showSorterTooltip: false,
      },
      {
        title: 'LIQUIDEZ ASIGNADA',
        dataIndex: 'liquidez',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.liquidez - b.liquidez,
        showSorterTooltip: false,
      },
      {
        title: 'LIQUIDEZ REAL',
        dataIndex: 'liquidezReal',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.liquidezReal - b.liquidezReal,
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'estadoRegistro',
        sorter: (a, b) => sorterText(a.estadoRegistro, b.estadoRegistro),
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        grupoSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });
      try {
        const response = await listarConvenios(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarCuposPagadoresExcel(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );
      setExcel(response, `reporte_cupo_pagador`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  searchData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      const response = await listarCuposPagadores(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );

      const resumen = this.state.resumen.map((res) => {
        if (response.metadata.sumatorias[res.id] !== undefined) {
          res.value = (
            <span className='format-number'>
              {currencyFormat(response.metadata.sumatorias[res.id])}
            </span>
          );
        }

        return res;
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        resumen: resumen,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...initialState,

        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'tipoConvenio',
            colSpan: 7,
          },
          {
            id: 'sumaNmCupoContraparte',
            value: currencyFormat(0),
            index: 'nmCupoContraparte',
            colSpan: 1,
          },
          {
            id: 'sumaNmCupoContraparteVig',
            value: currencyFormat(0),
            index: 'nmCupoContraparteVig',
            colSpan: 1,
          },
          {
            id: 'sumaNmLiquidez',
            value: currencyFormat(0),
            index: 'liquidez',
            colSpan: 1,
          },
          {
            id: 'sumaNmLiquidezReal',
            value: currencyFormat(0),
            index: 'liquidezReal',
            colSpan: 2,
          },
        ],
      });
    }
  }

  render() {
    const {
      pagadorSelect,
      grupoSelect,
      convenioSelect,
      loading,

      currentPage,
      limit,
      total,
      resumen,
      resultado,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <PayerQuotaFilterForm
            companyId={this.props.company.company}
            grupoSelect={grupoSelect}
            pagadorSelect={pagadorSelect}
            convenioSelect={convenioSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchGrupo={this.handleOnSearchGrupo}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default PayerQuota;
