import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_CLOSE, LOGIN_SET_EXPIRATION, LOGIN_ACCEPT_TERMS } from '../actions/types'
import jwt from 'jsonwebtoken'
import moment from 'moment'

let auth = JSON.parse(localStorage.getItem('auth-token'));
const { snAceptaCondiciones, exp, authorities } = auth ? jwt.decode(auth.access_token) : { snAceptaCondiciones: undefined, exp: undefined, authorities: undefined }
const initialState = auth ?
  {
    isAuthenticated: true,
    auth,
    loading: false,
    error: null,
    expiressTimestamp: exp * 1000,
    reachExpiration: false,
    snAceptaCondiciones,
    authorities
  }
  :
  {
    isAuthenticated: false,
    auth: null,
    loading: false,
    error: null,
    snAceptaCondiciones: snAceptaCondiciones || 'N'
  };

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case LOGIN_SUCCESS:
      return {
        isAuthenticated: true,
        loading: false,
        auth: action.payload,
        expiressTimestamp: (jwt.decode(action.payload.access_token).exp * 1000),
        reachExpiration: false,
        snAceptaCondiciones: jwt.decode(action.payload.access_token).snAceptaCondiciones,
        authorities: jwt.decode(action.payload.access_token).authorities
      }
    case LOGIN_ERROR:
      return {
        isAuthenticated: false,
        loading: false,
        error: action.payload
      }
    case LOGIN_CLOSE:
      localStorage.removeItem('auth-token');
      localStorage.clear();
      return { isAuthenticated: false, auth: { name: '' }, loading: false, error: null };
    case LOGIN_SET_EXPIRATION: {
      return { ...state, reachExpiration: action.payload <= 0 };
    }
    case LOGIN_ACCEPT_TERMS: {
      return { ...state, snAceptaCondiciones: 'S' };
    }
    case 'LOGIN_TEST': {
      return { ...state, reachExpiration: action.payload.status };
    }
    default: {

      return {
        ...state,
        error: "",
      }
    }
  }
}
