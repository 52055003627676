import axios from 'axios';
import queryString from 'query-string';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const buscarFichaJuridica = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridica/buscarFichaJuridica/${scCompania}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const consultaTiposActividades = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-actividades-economicas?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const actualizarFichaJuridica = (authToken, company, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridica/actualizarFichaJuridica/${company}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const actualizarFichaNatural = (authToken, company, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaNatural/actualizarFichaNatural/${company}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};
export const buscarFichaNatural = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaNatural/buscarFichaNatural/${scCompania}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};
