import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SET_EXPIRATION,
  LOGIN_ACCEPT_TERMS,
} from '../actions/types';
import userService from '../../services/userService';
import { notification } from 'antd';
import moment from 'moment';
import { LOGIN_SERVICE_ERROR } from '../../utilities/messages';

export const login = (username, password) => (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  userService
    .login({ username, password })
    .then((authToken) => {
      localStorage.setItem('auth-token', JSON.stringify(authToken));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: authToken,
      });
    })
    .catch((error) => {
      if (error.response.status === 400)
        if (Array.isArray(error.response.data.errors)) {
          error.response.data.errors.forEach((element) => {
            notification.error({
              message: 'Error',
              description: `${element.message}`,
              duration: 5,
            });
          });
        } else {
          notification.error({
            message: 'Error',
            description:
              error.response.data.error_description === 'Bad credentials'
                ? LOGIN_SERVICE_ERROR
                : 'Ha ocurrido un error, por favor intenta mas tarde',
            duration: 5,
          });
        }
      else
        notification.error({
          message: 'Error',
          description: error.response.data.message,
          duration: 5,
        });

      dispatch({ type: LOGIN_ERROR, payload: error.response.data.message });
    });
};

export const sessionClose = (authToken) => (dispatch) => {
  userService.revokeToken(authToken);
};

export const setExpirationTimeout = (stateExpiration) => (dispatch) => {
  let current = moment();
  let expiration = moment(stateExpiration);
  let diff = expiration.diff(current, 'millisecond') - 120000;

  dispatch({ type: LOGIN_SET_EXPIRATION, payload: diff });
};
export const refreshToken = (refreshToken) => (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  userService
    .refreshToken(refreshToken)
    .then((authToken) => {
      if (authToken)
        localStorage.setItem('auth-token', JSON.stringify(authToken));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: authToken,
      });
    })
    .catch((error) => {
      if (error.response.status === 400)
        if (Array.isArray(error.response.data.errors)) {
          error.response.data.errors.forEach((element) => {
            notification.error({
              message: 'Error',
              description: `${element.message}`,
              duration: 5,
            });
          });
        } else {
          notification.error({
            message: 'Error',
            description:
              error.response.data.error_description === 'Bad credentials'
                ? LOGIN_SERVICE_ERROR
                : 'Ha ocurrido un error, por favor intenta mas tarde',
            duration: 5,
          });
        }
      else
        notification.error({
          message: 'Error',
          description: error.response.data.message,
          duration: 5,
        });

      dispatch({ type: LOGIN_ERROR, payload: error.response.data.message });
    });
};

export const acceptTermsAction = (authToken) => (dispatch) => {
  userService.acceptTerms(authToken).then((response) => {
    if (response.status) {
      dispatch({ type: LOGIN_ACCEPT_TERMS, payload: response });
    }
  });
};
