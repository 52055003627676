import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Spin,
  Select,
  Button,
  Checkbox,
  notification,
  Upload,
  Divider,
  InputNumber,
  Space,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import {
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import {
  sizeFileValidation,
  typeFileValidation,
} from '../../../../../utilities/validate/fileValidate';
import { setExcel } from '../../../../../utilities/downloadTools';
import moment from 'moment';
import {
  crearCalendarioPagos,
  crearCalendarioPagosDiasCalendario,
  editarCalendarioPagos,
  editarCalendarioPagosDiasCalendario,
  exportarDiaCalendario,
} from '../../../../../services/admin/payersService';
import { isEqualsToObject } from '../../../../../utilities/compare';

const { Dragger } = Upload;

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const dias = [
  { label: 'LUNES', value: 'Lunes' },
  { label: 'MARTES', value: 'Martes' },
  { label: 'MIÉRCOLES', value: 'Miercoles' },
  { label: 'JUEVES', value: 'Jueves' },
  { label: 'VIERNES', value: 'Viernes' },
];

const tiposDiaPago = [
  {
    key: 'S',
    description: 'Días semana',
    form: ({ index }) => <DiasSemanaForm key={index} index={index} />,
  },
  {
    key: 'M',
    description: 'Días mes',
    form: ({ index }) => <DiasMesForm key={index} index={index} />,
  },
  {
    key: 'C',
    description: 'Dias calendario',
    form: ({ index, onDescargaDiasCalendario, handleOnChange, reemplazar}) => (
      <DiasCalendarioForm
        key={index}
        index={index}
        onDescargaDiasCalendario={onDescargaDiasCalendario}
        handleOnChange={handleOnChange}
        reemplazar={reemplazar}
      />
    ),
  },
  {
    key: 'V',
    description: 'Diario - Vencido',
    form: ({ index }) => <DiasVencidosFrom key={index} index={index} />,
  },
  {
    key: 'D',
    description: 'Día único semana',
    form: ({ index }) => <DiaUnicoDePagoFrom key={index} index={index} />,
  },
  {
    key: 'I',
    description: 'Ciclo de pagos',
    form: ({ index }) => <CicloPagosFrom key={index} index={index} />,
  },
];

const initialState = {
  loading: false,
  cdTipoDiaPago: [],
  reemplazar: false,
};

const initialCalendarioValues = {
  scPago: '',
  cdTipoDiaPago: undefined,
};

const rules = {
  nombrePago: [
    {
      required: true,
      message: requiredValueMessage(),
    },
    { max: 20, message: maxValueMessage(20) },
  ],
  cdTipoDiaPago: [{ required: true, message: requiredValueMessage() }],
  diasPagosMes: [{ required: true, message: requiredValueMessage() }],
  file: [
    {
      type: 'array',
      required: true,
      message: 'debe seleccionar un archivo',
    },
    {
      validator: (_, value) => sizeFileValidation(value, 10),
      message: 'El archivo debe tener un tamaño máximo de 10MB',
    },
    {
      validator: (_, value) =>
        typeFileValidation(value, [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'application/xml',
          'text/xml',
        ]),
      message:
        'El tipo de archivo es el incorrecto, debe ser de tipo xls, xlsx',
    },
  ],
  diasPagoVencida: [{ required: true, message: requiredValueMessage() }],
  diasPagoUnico: [{ required: true, message: requiredValueMessage() }],
  diaPagoCicloFinal: [{ required: true, message: requiredValueMessage() }],
  diaPagoCicloInicial: [{ required: true, message: requiredValueMessage() }],
};

class CalendarioPago extends Component {
  formRefs = React.createRef();

  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  onChangeCdTipoDiaPago = (index, e) => {
    console.log('onChangeCdTipoDiaPago', index, e);

    const { cdTipoDiaPago } = this.state;

    cdTipoDiaPago[index] = e;

    this.setState({
      ...this.state,
      cdTipoDiaPago: cdTipoDiaPago,
    });
    console.log('onChangeCdTipoDiaPago 1', this.state);
  };

  handleSubmit = async (formValue) => {
    const { onFinish } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    let pagosCalendarios = Object.entries(formValue).map((value) => value[1]);
    console.log('handleSubmit', pagosCalendarios);
    try {
      for (let i = 0; i < pagosCalendarios.length; i++) {
        const pagos = pagosCalendarios[i];
        console.log('handleSubmit', pagos);
        let response;
        switch (pagos.cdTipoDiaPago) {
          case 'C':
            response = await this.grabarDiasCalendario(pagos);
            break;
          case 'M':
            const diasPagosMes = Object.entries(pagos.diasPagosMes).map(
              (value) => value[1]
            );
            response = await this.grabarCalendarioPago({
              ...pagos,
              diasPagosMes,
            });
            break;
          default:
            response = await this.grabarCalendarioPago(pagos);
            break;
        }

        pagosCalendarios[i] = {
          ...pagos,
          scPago: response.location ? response.location : pagos.scPago,
        };
      }
      notification.success({
        message: 'CALENDARIO DE PAGO',
        description: 'Calendario de pagos actualizado exitosamente',
        duration: 6,
      });

      onFinish(pagosCalendarios);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  grabarCalendarioPago = (pagos) => {
    const { authToken, scPersona } = this.props;

    if (pagos.scPago) {
      return editarCalendarioPagos(authToken, scPersona, pagos);
    } else {
      return crearCalendarioPagos(authToken, scPersona, pagos);
    }
  };

  grabarDiasCalendario =  (pagos) => {
    const { authToken, scPersona } = this.props;
    if (pagos.scPago) {
      return editarCalendarioPagosDiasCalendario(
        authToken,
        scPersona,
        pagos.scPago,
        pagos,
        pagos.reemplazar,
      );
    } else {
      return crearCalendarioPagosDiasCalendario(authToken, scPersona, pagos, pagos.reemplazar);
    }
  };

  handleOnDescargaDiasCalendario = async () => {
    console.log('handleOnDescargaDiasCalendario');
    const { authToken, scPersona } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await exportarDiaCalendario(authToken, scPersona);

      const date = moment().format('YYYY_MM_DD');

      setExcel(response, date);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    if (this.props.data && Array.isArray(this.props.data)) {
      const formData = this.props.data.reduce((obj, item, index, arr) => {
        return index === arr.length - 1
          ? { ...obj, [index]: { ...item, inicio: 0, fin: 0 } }
          : { ...obj, [index]: { ...item } };
      }, {});

      const cdTipoDiaPago = this.props.data.map((item) => item.cdTipoDiaPago);

      this.formRefs.current.setFieldsValue({
        ...formData,
      });
      this.setState({
        ...this.state,
        cdTipoDiaPago: cdTipoDiaPago,
      });
    }
  }

  handleOnChange = (e) =>  {
    this.setState({ ...this.state, reemplazar : e.target.checked });
  }

  render() {
    const { cdTipoDiaPago, loading} = this.state;
    const { data = [] } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='header-container'>
          <Form
            name='calendario_form'
            layout='vertical'
            ref={this.formRefs}
            onFinish={this.handleSubmit}
          >
            {data.map((_, index) => (
              <div key={index}>
                <Form.Item
                  name={[index, 'scPago']}
                  rules={rules['scPago'] || ''}
                  hidden={true}
                >
                  <Input />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={[index, 'nombrePago']}
                      dependencies={[[index, 'fin']]}
                      label='NOMBRE'
                      rules={rules['nombrePago'] || ''}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[index, 'cdTipoDiaPago']}
                      label='TIPO DÍA PAGO'
                      rules={rules['cdTipoDiaPago'] || ''}
                    >
                      <Select
                        placeholder={SELECT_PLACE_HOLDER}
                        onChange={(e) => this.onChangeCdTipoDiaPago(index, e)}
                        allowClear
                      >
                        {tiposDiaPago.map((item) => (
                          <Select.Option key={item.key} value={item.key}>
                            {item.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {cdTipoDiaPago[index] &&
                  tiposDiaPago
                    .filter((tipoDia) => tipoDia.key === cdTipoDiaPago[index])
                    .map((tipoDia) =>
                      tipoDia.form({
                        index,
                        onDescargaDiasCalendario:
                          this.handleOnDescargaDiasCalendario,
                        handleOnChange: 
                          this.handleOnChange, 
                      })
                    )}
                <Divider />
              </div>
            ))}

            <Row justify='center'>
              <Col span={8}>
                <Form.Item>
                  <Button
                    className='btn btn-green'
                    type='primary'
                    htmlType='submit'
                  >
                    Grabar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    );
  }
}

const DiasSemanaForm = ({ index }) => (
  <>
    <Row gutter={16}>
      <Col flex='auto'>
        <Space
          align='baseline'
          className='sub-title'
          style={{ marginBottom: '1.3rem' }}
        >
          DÍAS DE PAGO SEMANA
        </Space>
      </Col>
    </Row>
    <Row>
      <Col flex='auto'>
        <Form.Item
          name={[index, 'diasPagoSemana']}
          style={{ marginBottom: '0' }}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row align='middle' justify='center'>
              {dias.map((dia, index) => (
                <Col span={4} key={index}>
                  <Form.Item label={dia.label}>
                    <Checkbox value={dia.value} />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Col>
    </Row>
  </>
);

const DiasMesForm = ({ index }) => (
  <>
    <Row gutter={16}>
      <Col flex='auto'>
        <Space
          align='baseline'
          className='sub-title'
          style={{ marginBottom: '1.3rem' }}
        >
          DÍAS DE PAGO MES
        </Space>
      </Col>
    </Row>
    <Row gutter={8}>
      <Col span={8}>
        <Form.Item
          name={[index, 'diasPagosMes', '0']}
          rules={rules['diasPagosMes']}
        >
          <InputNumber min={0} max={31} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={[index, 'diasPagosMes', '1']}
          rules={rules['diasPagosMes']}
        >
          <InputNumber min={0} max={31} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={[index, 'diasPagosMes', '2']}
          rules={rules['diasPagosMes']}
        >
          <InputNumber min={0} max={31} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const DiasCalendarioForm = ({ index, onDescargaDiasCalendario, handleOnChange, reemplazar }) => (
  <>
    <Row gutter={16}>
      <Col flex='auto'>
        <Space
          align='baseline'
          className='sub-title'
          style={{ marginBottom: '1.3rem' }}
        >
          CARGAR DÍAS PAGO
        </Space>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col flex='auto'>
        <Form.Item label='ARCHIVO XLS' required rules={rules['file']}>
          <Form.Item
            name={[index, 'file']}
            valuePropName='fileList'
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList.length > 0 ? [e.file] : [];
            }}
            rules={rules['file']}
            noStyle
          >
            <Dragger
              action='/upload.do'
              maxCount={0}
              multiple={false}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml, text/xml, .xls, .xlsx'
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>
                Suelta el archivo aquí o selecciona alguno.
              </p>
            </Dragger>
          </Form.Item>
        </Form.Item>
      </Col>
    </Row>
    <Row justify='center' align='middle'>
    <Col span={10}>
      <Form.Item
          defaultValue = {reemplazar} 
          name={[index, 'reemplazar']}       
          valuePropName='checked'
          style={{ marginBottom: '0' }}
      >
        <Checkbox 
           onChange={(e) => handleOnChange(e)}
          > 
          Reemplazar 
        </Checkbox> 
      </Form.Item>      
    </Col>
      <Col span={10}>
        <Space style={{ textAlign: 'left' }}>
          <Button
            type='link'
            htmlType='button'
            onClick={onDescargaDiasCalendario}
          >
            Exportar a Excel
          </Button>
        </Space>
      </Col>
    </Row>
  </>
);

const DiasVencidosFrom = ({ index }) => (
  <>
    <Row gutter={8}>
      <Col span={12}>
        <Form.Item
          label='DÍAS VENCIDOS'
          name={[index, 'diasPagoVencida']}
          rules={rules['diasPagoVencida']}
        >
          <InputNumber min={0} max={999} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const DiaUnicoDePagoFrom = ({ index }) => (
  <>
    <Row gutter={8}>
      <Col span={12}>
        <Form.Item
          label='DÍA DE PAGO'
          name={[index, 'diasPagoUnico']}
          rules={rules['diasPagoUnico']}
        >
          <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
            {dias.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </>
);

const CicloPagosFrom = ({ index }) => (
  <>
    <Row gutter={8}>
      <Col span={12}>
        <Form.Item
          label='FIN CICLO'
          name={[index, 'diaPagoCicloFinal']}
          rules={rules['diaPagoCicloFinal']}
        >
          <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
            {dias.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label='DÍA DE PAGO'
          name={[index, 'diaPagoCicloInicial']}
          rules={rules['diaPagoCicloInicial']}
        >
          <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
            {dias.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default CalendarioPago;
