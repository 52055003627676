import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';


const baseUrl = process.env.REACT_APP_SERVICES_URL_VINCULACION;

export const guardar = (data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/previnculacion/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
  );
};

export const solicitartoken = (data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/previnculacion/token`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
  );
};






