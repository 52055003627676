import { Button, Col, Form, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { BUSQUEDA_TITLE } from '../../../../../utilities/messages';

const initialValue = {
  scProveedor: undefined,
  scGrupo: undefined,
};
const OffersForm = ({
  resetForms,
  handleSubmit,
  proveedorSelect,
  handleOnSearchProveedor,
  grupoSelect,
  handleOnSearchGrupo,
  handleOnReset,
  data,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  if (resetForms) {
    form.setFieldsValue({ ...initialValue });
    handleOnReset();
  }

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      fechaRango: [data.fechaDesdeCompra, data.fechaHastaCompra],
    });
    forceUpdate({});
  }, [form, data]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scGrupo' label='Grupo Empresarial'>
              <Select
                name='scGrupo'
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchGrupo(value)}
                notFoundContent={
                  grupoSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {grupoSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='scProveedor' label='Proveedor'>
              <Select
                name='scProveedor'
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchProveedor(value)}
                notFoundContent={
                  proveedorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {proveedorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OffersForm;
