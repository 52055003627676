import React from 'react';
import { Table } from 'antd';

const Accounts = ({ data }) => {
  const accountsColumns = [
    {
      title: 'BANCO',
      dataIndex: 'nombreBanco',
      showSorterTooltip: false,
    },
    {
      title: 'TIPO DE CUENTA',
      dataIndex: 'tipoCuenta',
      showSorterTooltip: false,
    },
    {
      title: 'NÚMERO DE CUENTA',
      dataIndex: 'nroCuenta',
      showSorterTooltip: false,
    },
    {
      title: 'ESTADO',
      dataIndex: 'dsEstadoRegistro',
      showSorterTooltip: false,
    },
  ];

  return (
    <div className='table-container'>
      <Table columns={accountsColumns} dataSource={data}></Table>
    </div>
  );
};

export default Accounts;
