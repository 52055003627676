import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import history from '../../../services/history';
import { Card, Tabs } from 'antd';
import './operation.scss';
import Agreements from './agreements/agreements';
import QuotasAgreements from './quotasAgreements/quotasAgreements';
import AchHistory from './ach/achHistory/achHistory';
import AchPayment from './ach/achPayments/achPayments';

class Reports extends Component {
  componentDidMount() {
    this.props.menuHandler('5');
  }

  changeTab = (activeKey) => {
    history.push(`${process.env.PUBLIC_URL}/operation/${activeKey}`);
  };

  render() {
    const { activeTab } = this.props;

    return (
      <div className='operation-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <Tabs activeKey={activeTab || '0'} onChange={this.changeTab}>
              <Tabs.TabPane tab='Convenios' key={0}>
                <Agreements
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Cupos' key={1}>
                <QuotasAgreements
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Pago ACH' key={2}>
                <AchPayment
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Histórico Pago ACH' key={3}>
                <AchHistory
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const activeTab = props.match.params.activeTab;
  return {
    activeTab: activeTab,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(Reports));
