import React from 'react';
import { Form, Input, Row, Col, Button, DatePicker, Select, Spin } from 'antd';
import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_POST_FORMAT,
} from '../../../../../utilities/messages';
import { requiredValueMessage } from '../../../../../utilities/admin_validation_messages';
import equal from 'fast-deep-equal';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  cdMunElaboracion: [{ required: true, message: requiredValueMessage() }],
  feElaboracion: [{ required: true, message: requiredValueMessage() }],
};

class PersonNatural extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleOnFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish('2', {
      ...formValue,
      feElaboracion: formValue.feElaboracion.format(DATE_POST_FORMAT),
      feExpedicion: formValue.feExpedicion
        ? formValue.feExpedicion.format(DATE_POST_FORMAT)
        : '',
    });
  };

  componentDidUpdate(props, state) {
    if (!equal(this.props.person, props.person)) {
      const { person } = this.props;
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        ...person,
        feElaboracion: person.feElaboracion
          ? moment(person.feElaboracion, DATE_FORMAT)
          : moment(),
        feExpedicion: person.feExpedicion
          ? moment(person.feExpedicion, DATE_FORMAT)
          : '',
      });
    }
  }

  render() {
    const { ciudadesSelect, onCiudadesSearch } = this.props;

    return (
      <div className='content-container general-style'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Form
            ref={this.formRef}
            name='person_data'
            layout='vertical'
            onFinish={this.handleOnFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdMunElaboracion'
                  label='Ciudad de elaboración'
                  rules={rules['cdMunElaboracion'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    className='module-selector-select'
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => onCiudadesSearch(value)}
                    notFoundContent={
                      ciudadesSelect.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {ciudadesSelect.options.map((ciudad, i) => (
                      <Select.Option key={i} value={ciudad.codigo}>
                        {ciudad.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='feElaboracion'
                  label='Fecha de elaboración'
                  rules={rules['feElaboracion'] || ''}
                >
                  <DatePicker
                    className='radius'
                    format={DATE_FORMAT}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='prNombre'
                  label='Primer nombre'
                  rules={rules['prNombre'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='segNombre'
                  label='Segundo nombre'
                  rules={rules['segNombre'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='prApellido'
                  label='Primer apellido'
                  rules={rules['prApellido'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='segApellido'
                  label='Segundo apellido'
                  rules={rules['segApellido'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='dsTipoId'
                  label='Tipo de documento'
                  rules={rules['dsTipoId'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='id'
                  label='Número de identificación'
                  rules={rules['id'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='feExpedicion'
                  label='Fecha de expedición'
                  rules={rules['feExpedicion'] || ''}
                >
                  <DatePicker
                    className='radius'
                    allowClear={false}
                    inputReadOnly
                    open={false}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='lugarExpedicion'
                  label='Lugar de expedición'
                  rules={rules['lugarExpedicion'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row align='middle' justify='center'>
              <Col span={6}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Siguiente
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

export default PersonNatural;
