import {
  BILL_REPORT_SUMMARY_INIT,
  BILL_REPORT_SUMMARY_LOADED
} from '../types'

import { resumenFacturasProveedor } from '../../../services/proveedor/reportServices'

export const initBillSummaryAction = () => dispatch => {
  dispatch({ type: BILL_REPORT_SUMMARY_INIT })
};

export const loadReportSummaryAction = (authtoken, company) => dispatch => {
  resumenFacturasProveedor(authtoken, company)
    .then(response => {
      dispatch({ type: BILL_REPORT_SUMMARY_LOADED, payload: response })
    })
};