import { Col, Form, Row, Button, notification, Spin } from 'antd'
import Dragger from 'antd/lib/upload/Dragger';
import React, { useState,useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { listarDocumentosAnexos } from '../../../../../services/vinculacion/documentosAnexosAdminService';

export const ModalDocFirmados = ({handleUpload,handleOnFinish,fileList,token,scCompania,toggleLoading}) => {

  const [existFiles, setExistFiles] = useState(false);
  const [loading, setLoading] = useState(false);

  const listDocuments = async () =>{
    setLoading(true);
    try {
        const res = await listarDocumentosAnexos(token,scCompania);
        if(res.metadata.count > 0){
            let df = res.data.filter((registro) => registro.tipodoc === 'DF');
            if(df.length > 0){
                setExistFiles(true);
            }else {
                setExistFiles(false);
            }
        }
    }catch (error) {

        notification.error({
          title: 'Error',
          message: "Error listando documentos",
          duration: 5,
        });
    } finally {
        setLoading(false);
    }

  }

  useEffect(() => {

    setExistFiles(false);
    listDocuments();
    
  }, [scCompania])

  useEffect(() => {

    setLoading(toggleLoading);
    
  }, [toggleLoading])
 
  return (
    <div>
    <Spin tip='Cargando...' spinning={loading}>
    <Form
        name='content_form'
        layout='vertical'
    >
        <Row gutter={[16, 32]}>
            <Col flex='auto'>
                <h3><p style={{color:'green'}}>Puedes seleccionar múltiples archivos</p></h3>
                <h3><p style={{color:'red'}}>No adjuntar comprimidos</p></h3>
                <h3 style={{fontWeight:'bold'}}>{existFiles ? <b>Ya se han cargado documentos firmados</b> : null }</h3>
                <Form.Item name='docs'>
                <Dragger
                    beforeUpload={() => {
                    return false;
                    }}
                    fileList={fileList}
                    onChange={handleUpload}
                    multiple={true}
                >
                    <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                        Suelta los archivos aquí o seleccionalos.
                    </p>
                </Dragger>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={16} justify={'center'}>
            <Col span={6}>
                <Button
                    style={{ width: '90%' }}
                    className='btn btn-green'
                    type='primary'
                    onClick={() => {
                        setLoading(true);
                        handleOnFinish();
                      }
                    }
                >
                    CARGAR
                </Button>
            </Col>
        </Row>
    </Form>
    </Spin>
    </div>
  )
}
