import React, { useEffect, useState } from 'react';
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button } from 'antd';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';
import moment from 'moment';

const initialValue = {
  scPagador: '',
  scProveedor: '',
  cdFactura: '',
  scConvenio: '',
};

const AgreementsFilterFrom = ({
  companyId,
  handleSubmit,
  proveedorSelect,
  pagadorSelect,
  handleOnSearchProveedor,
  handleOnSearchPagador,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [companyId, form]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scPagador' label='Pagador'>
              <Select
                name='scPagador'
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchPagador(value)}
                notFoundContent={
                  pagadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {pagadorSelect.options.map((d) => (
                  <Select.Option key={d.scPersona}>{d.nombre}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scProveedor' label='Proveedor'>
              <Select
                name='scProveedor'
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchProveedor(value)}
                notFoundContent={
                  proveedorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {proveedorSelect.options.map((d) => (
                  <Select.Option key={d.scPersona}>{d.nombre}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='nombreConvenio' label='Nombre Convenio'>
              <Input name='nombreConvenio' />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AgreementsFilterFrom;
