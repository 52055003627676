import { Button, Card, Col, notification, Spin } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '../../../../..//services/history';
import {
  actualizarMandato,
  buscarMandato,
  enviarMandato,
  exportMandatoPDF,
  grabarMandato,
  listarFacultadesMandatos,
} from '../../../../../services/admin/radian/radianService';
import { setExportPDF } from '../../../../../utilities/downloadTools';
import { DATE_POST_FORMAT } from '../../../../../utilities/messages';
import '../mandateForm.scss';
import MandateForm, { initialValuesForm } from '../mandateForm/mandateForm';

const initialState = {
  loading: false,
  facultadadSelect: [],
  data: { ...initialValuesForm },
};

class MadateEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnFinish = async (formValue) => {
    const { data } = this.state;
    const { authToken } = this.props;
    const { scMandato } = data;

    if (data.cdEstadoMandato === 'EE') {
      try {
        const response = await grabarMandato(authToken, formValue);

        notification.success({
          message: 'Exito',
          description: response.message,
          duration: 6,
        });

        this.redirectToEditMandate(response.data);
      } catch (error) {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    } else {
      try {
        const response = await actualizarMandato(
          authToken,
          formValue,
          scMandato
        );

        notification.success({
          message: 'Exito',
          description: response.message,
          duration: 6,
        });

        this.setState({
          ...this.state,
          loading: false,
          data: {
            ...response.data,
            feInicio: response.data.feInicio
              ? moment(response.data.feInicio, DATE_POST_FORMAT)
              : '',
            feVigencia: response.data.feVigencia
              ? moment(response.data.feVigencia, DATE_POST_FORMAT)
              : '',
            feEscritura: response.data.feEscritura
              ? moment(response.data.feEscritura, DATE_POST_FORMAT)
              : '',
          },
        });
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  redirectToEditMandate = (mandato) => {
    history.push(
      `${process.env.PUBLIC_URL}/radian/mandate/edit/${Buffer.from(
        mandato.scMandato
      ).toString('base64')}}`
    );
  };

  redirectToMandate = () => {
    history.push(`${process.env.PUBLIC_URL}/radian/mandate`);
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(props, state) {
    if (this.props.scMandato !== props.scMandato) {
      this.init();
    }
  }

  init = async () => {
    const { authToken, scMandato } = this.props;

    this.setState({ ...this.state, loading: true });

    try {
      const response = await buscarMandato(authToken, scMandato);

      const mandatoRes = await listarFacultadesMandatos(this.props.authToken, {
        limit: 100,
      });

      this.setState({
        ...this.state,
        data: {
          ...response.data,
          feInicio: response.data.feInicio
            ? moment(response.data.feInicio, DATE_POST_FORMAT)
            : '',
          feVigencia: response.data.feVigencia
            ? moment(response.data.feVigencia, DATE_POST_FORMAT)
            : '',
          feEscritura: response.data.feEscritura
            ? moment(response.data.feEscritura, DATE_POST_FORMAT)
            : '',
        },
        facultadads: mandatoRes.data,
        loading: false,
      });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  };

  handleEnviarMandato = async () => {
    const { data } = this.state;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await enviarMandato(
        this.props.authToken,
        this.props.scMandato
      );

      notification.success({
        message: 'Enviado con Exito',
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        loading: false,
        data: {
          ...data,
          cdEstadoMandato: response.data.cdEstadoMandato,
          dsEstadoMandato: response.data.dsEstadoMandato,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportPDF = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await exportMandatoPDF(
        this.props.authToken,
        this.props.scMandato
      );

      setExportPDF(response, `Documento Mandato ${this.props.scMandato}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  render() {
    const { loading, data, facultadads } = this.state;
    const fileRequired = data.cdEstadoMandato === 'EE';

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <MandateForm
              companiaEdit={false}
              data={data}
              onFinish={this.handleOnFinish}
              handleEnviarMandato={this.handleEnviarMandato}
              facultadads={facultadads}
              uploadHidden={
                data.cdEstadoMandato !== 'P' && data.cdEstadoMandato !== 'E'
              }
              guardarHidden={
                data.cdEstadoMandato === 'I' || data.cdEstadoMandato === 'EE'
              }
              fileRequired={fileRequired}
            >
              <Col span={6}>
                <Button
                  style={{ width: '90%' }}
                  className='btn btn-blue'
                  type='primary'
                  onClick={this.redirectToMandate}
                >
                  Lista de Mandatos
                </Button>
              </Col>

              <Col span={6}>
                <Button
                  style={{ width: '90%' }}
                  className='btn btn-blue'
                  type='primary'
                  onClick={this.exportPDF}
                >
                  Descargar Mandato
                </Button>
              </Col>

              {data.cdEstadoMandato === 'I' && (
                <Col span={6}>
                  <Button
                    style={{ width: '90%' }}
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.handleEnviarMandato}
                  >
                    Enviar
                  </Button>
                </Col>
              )}
            </MandateForm>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scMandato = Buffer.from(
    props.match.params.scMandato,
    'base64'
  ).toString('utf8');

  return {
    scMandato: scMandato,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(MadateEditForm));
