import React, { useState } from 'react';
import { Button, Popover, List, Divider, Row, Col } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { currencyFormat, percentageFormat } from '../../utilities/textTools';

const TaxPopover = ({ record, children }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (e) => {
    setVisible(e);
  };

  const hide = () => {
    setVisible(false);
  };

  const title = <span>Impuesto</span>;

  const data = [
    `Iva: ${currencyFormat(record.vrIva)}`,
    `ReteIva: ${currencyFormat(record.vrReteIva)}`,
    `Retefuente: ${currencyFormat(record.vrRetefuente)}`,
    `Porcentaje reteIca: ${percentageFormat(record.poReteIca)}`,
  ];

  const content = (
    <>
      <List
        size='small'
        dataSource={data}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      ></List>
      <Divider style={{ 'margin-top': '10px', 'margin-bottom': '10px' }} />

      <Row justify='center'>
        <Col span={12}>
          <Button type='link' onClick={hide}>
            Cerrar
          </Button>
        </Col>
      </Row>
    </>
  );
  return (
    <>
      <Popover
        placement='right'
        title={title}
        content={content}
        trigger='click'
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Button type='text'>
          {children} <QuestionCircleOutlined />
        </Button>
      </Popover>
    </>
  );
};

export default TaxPopover;
