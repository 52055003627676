import qs from 'qs';
import axios from 'axios';
import { notification } from 'antd';
import store from '../store/index';
import { LOGIN_CLOSE } from '../store/actions/types';

var baseUrl = process.env.REACT_APP_SERVICES_URL_LOGIN;

const login = (data) => {
  const formData = new FormData();
  formData.append('grant_type', 'password');
  formData.append('scope', 'read write');
  formData.append('username', data.username);
  formData.append('password', data.password);

  return axios({
    url: `${baseUrl}/oauth/token`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: formData,
  }).then((response) => {
    return response.data;
  });
};

const refreshToken = (refresh_token) => {
  let data = qs.stringify({
    grant_type: 'refresh_token',
    scope: 'read write',
    refresh_token,
  });

  return axios({
    url: `${baseUrl}/oauth/token`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      localStorage.removeItem('auth-token');
      store.dispatch({ type: LOGIN_CLOSE });
      notification.error({
        message: 'Sesión Finalizada',
        description: 'La sesión ha finalizado, por favor ingrese nuevamente',
        duration: 6,
      });

      window.location.href = `${process.env.PUBLIC_URL}/`;
    });
};

const rememberPassword = (data) => {
  return axios({
    url: `${baseUrl}/api/recuperarPassword`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: qs.stringify(data),
  }).then((response) => {
    return response.data;
  });
};

const confirmPassword = (data) => {
  return axios({
    url: `${baseUrl}/api/recuperarPassword/confirmar`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: data,
  }).then((response) => {
    return response.data;
  });
};

const changePassword = (access_token, data) => {
  return axios({
    url: `${baseUrl}/api/cambiarPassword`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${access_token}`,
    },
    data: data,
  }).then((response) => {
    return response.data;
  });
};

const acceptTerms = (authToken) => {
  return axios({
    url: `${baseUrl}/api/asociado/actualizarContactoAceptoCondiciones`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
    data: null,
  })
    .then((response) => {
      notification.success({
        message: 'Enviado',
        description: 'Aceptaste los términos y condiciones de la plataforma',
      });
      return { status: true };
    })
    .catch((error) => {
      notification.error({
        message: 'Error',
        description:
          'No fue posible aceptar los términos y condiciones, por favor intentalo de nuevo mas tarde',
      });
      return { status: false };
    });
};

const getAdminUserModules = (authToken, application) => {
  return axios({
    url: `${baseUrl}/api/recursos/menu?cdAplicacion=${application}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
    data: null,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      notification.error({
        message: 'Error',
        description: error.response.data.message,
      });
    });
};

const revokeToken = (authToken) => {
  return axios({
    url: `${baseUrl}/api/revoke/token`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => {
      store.dispatch({ type: LOGIN_CLOSE });
      window.location.href = `${process.env.PUBLIC_URL}/`;
      return response.data;
    })
    .catch((error) => {
      store.dispatch({ type: LOGIN_CLOSE });
      window.location.href = `${process.env.PUBLIC_URL}/`;
    });
};

const grabarLog = (infoError) => {
  console.log('grabarLog', infoError);
  let data = qs.stringify({
    log: infoError,
  });

  return axios({
    url: `${baseUrl}/api/log/grabar`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export default {
  login,
  rememberPassword,
  confirmPassword,
  refreshToken,
  changePassword,
  acceptTerms,
  getAdminUserModules,
  revokeToken,
  grabarLog,
};
