import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Spin,
  notification,
  Card,
  Modal,
} from 'antd';
import { connect } from 'react-redux';
import {
  exportarExcelListaErrores,
  listaErrores,
  marcarTratada,
} from '../../../../services/admin/file/erpErrorsService';
import './erpErrors.scss';
import { sorterDate, sorterText } from '../../../../utilities/textTools';
import { FileExcelOutlined } from '@ant-design/icons';
import { listarCompanias } from '../../../../services/admin/operation';
import TableReport from '../../../../components/tableReport/tableReport';

let intColumnas = 1;

const SELECT_PLACE_HOLDER = 'Seleccionar...';
const { Option } = Select;
const initialState = {
  loading: false,
  currentPage: 1,
  resultado: [],
  total: 0,
  sumatorias: {},
  filtros: {
    fechaDesde: '',
    fechaHasta: '',
    scCompania: '',
    snTratada: '',
    offset: 0,
    sort: '',
  },
  tratadaOptions: [
    { key: 'S', nombre: 'Sí' },
    { key: 'N', nombre: 'No' },
  ],
  parametrosModal: {
    detalle: '',
  },
  companiaSelect: {
    fetching: false,
    options: [],
  }
};

class ErpErrors extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

  }

  columns =  [
    {
      title: 'COMPAÑIA',
      dataIndex: 'nombreCompania',
      sorter: (a, b) => sorterText(a.nombreCompania, b.nombreCompania),
      showSorterTooltip: false,
    },
    {
      title: 'FECHA DE ERROR',
      dataIndex: 'feError',
      sorter: (a, b) => sorterDate(a.feError, b.feError),
      showSorterTooltip: false,
    },
    {
      title: 'PROCEDIMIENTO',
      dataIndex: 'cdProcedimiento',
      sorter: (a, b) => sorterText(a.cdProcedimiento, b.cdProcedimiento),
      showSorterTooltip: false,
    },
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'dsError',
      sorter: (a, b) => sorterText(a.dsError, b.dsError),
      showSorterTooltip: false,
    },
    {
      title: 'PARÁMETROS',
      dataIndex: 'dsParametros',
      render: (record) => (
        <Button
          type='primary'
          title='Editar'
          onClick={() => this.showModalDetalle(record)}
        >
          DETALLE
        </Button>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'TRATADA',
      dataIndex: '',
      render: (record) => (
        <Button
          disabled={record.snTratada == 'S'}
          type='primary'
          title='Editar'
          onClick={() => this.marcarTratada(record)}
        >
          TRATADA
        </Button>
      ),
      showSorterTooltip: false,
    },
  ];

  formulario = [
    [
      {
        nombre: 'fechaDesde',
        label: '* DESDE',
        tipo: 'date',
      },
      {
        nombre: 'fechaHasta',
        label: '* HASTA',
        tipo: 'date',
      },
    ],
    [
      {
        nombre: 'scCompania',
        label: 'COMPAÑIA',
        tipo: 'select',
      },
      {
        nombre: 'snTratada',
        label: 'TRATADA',
        tipo: 'select',
        selectType: 'tratadaOptions',
      },
    ],
  ];

  componentDidMount() {
    this.props.menuHandler('82');
  }


  showModalDetalle = (record) => {
    if (typeof record !== 'undefined' && record != null) {
      this.setState({
        ...this.state,
        editarVisible: true,
        parametrosModal: { record },
      });
    } else {
      this.setState({
        ...initialState,
        filtros: {
          fechaDesde: '',
          fechaHasta: '',
          scCompania: '',
          snTratada: '',
          offset: 0,
        },
      });
    }
  };

  marcarTratada(record) {
    marcarTratada(this.props.authToken, record.scError).then((response) => {
      if (response.message) {
        notification.success({
          message: 'Marcar tratada',
          description: response.message,
          duration: 6,
        });
      }
      this.getData();
    });
  }

  getData = () => {
    this.state.resultado = [];
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });
      listaErrores(this.props.authToken, this.state.filtros).then(
        (response) => {
          this.setResponse(response);
        }
      );
    }
  };

  setResponse = (response) => {
    this.setState({
      ...this.state,
      resultado: response.data,
      total: response.metadata.count,
      loading: false,
      sumatorias: response.metadata.sumatorias,
    });
  };

  
  handleOnSearch = () => {
    this.state.filtros.sort = '-feError';
    this.getData();
  };

  handleOnSearchCompania = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { companiaSelect } = this.state;

      this.setState({
        ...this.state,
        companiaSelect: {
          ...companiaSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarCompanias(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            fetching: false,
          },
        });
      }
    }
  };

  validateForm = () => {
    let filtros = this.state.filtros;
    let fechas = true;
    Object.keys(filtros).forEach((nombre) => {
      if (nombre !== 'resultado') {
        if (
          nombre.includes('fecha') &&
          (filtros[nombre] === '' || filtros[nombre] === null)
        ) {
          fechas = false;
        }
      }
    });
    if (!fechas) {
      notification.error({
        message: 'Las fechas son obligatorias',
        duration: 4,
      });
    }
    return fechas;
  };

  limpiarCampos() {
    this.setState({
      ...initialState,
      filtros: {
        fechaDesde: '',
        fechaHasta: '',
        scCompania: '',
        snTratada: '',
        offset: 0,
      }
    });
    document.getElementById("formulario").reset();
  }
  
  handleTextChange = (e, campo) => {
    this.state.filtros[campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo) => {
    this.state.filtros[campo] = moment;
  };

  handleSelectChange = (value, stateName) => {
    this.state.filtros[stateName] = value;
    this.setState({ ...this.state });
  };

  handleCancel = (e) => {
    this.setState({
      editarVisible: false,
    });
  };

  exportExcel = () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });
      exportarExcelListaErrores(this.props.authToken, this.state.filtros).then(
        (response) => {
          this.setExcel(response);
        }
      );
    }
  };

  setExcel = (response) => {
    const type = this.props.type;
    const url = window.URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Errores ERP.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.setState({
      ...this.state,
      loading: false,
    });
  };

  handleChange = (e) => {
    this.state.filtros.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.filtros.offset = e.offset;
    this.state.filtros.limit = e.limit;

    this.getData();
  };

  render() {
    const {
      currentPage,
      limit,
      filtros: { offset },
      total,
      resultado,
      companiaSelect,
    } = this.state;

    return (
      <div className='reports'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={this.state.loading}>
                <div className='header-container'>
                  <p className='title'>Búsqueda</p>
                  <Form
                    name='content_form'
                    layout='vertical'
                    className='filters-container'
                    id="formulario"
                  >
                    <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item name='fechaDesde' label='* DESDE'>
                            <DatePicker 
                            name='fechaDesde' 
                            onChange={(moment, string) =>
                              this.handleDateChange(moment,'fechaDesde')} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name='fechaHasta' label='* HASTA'>
                            <DatePicker 
                            name='fechaHasta'
                            onChange={(moment, string) =>
                              this.handleDateChange(moment, 'fechaHasta')}/>
                          </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                      <Form.Item name='scCompania' label='COMPAÑIA'>                         
                          <Select
                              showSearch
                              allowClear
                              placeholder={SELECT_PLACE_HOLDER}
                              defaultActiveFirstOption={false}
                              showArrow={false}
                              filterOption={false}
                              onSearch={(value) => this.handleOnSearchCompania(value)}
                              onChange={(value) => this.handleSelectChange(value,'scCompania')}
                              notFoundContent={
                                this.state.companiaSelect.fetching ? <Spin size='small' /> : null
                                }
                            >
                            {this.state.companiaSelect.options.map((d) => (
                                        <Select.Option key={d.codigo}>
                                          {d.descripcion}
                                        </Select.Option>
                            ))}
                          </Select>
                      </Form.Item>
                      </Col>
                      <Col span={12}>
                      <Form.Item name='snTratada' label='TRATADA'>                         
                          <Select
                              showSearch
                              allowClear
                              placeholder={SELECT_PLACE_HOLDER}
                              defaultActiveFirstOption={false}
                              showArrow={false}
                              filterOption={false}
                              onChange={(value) => this.handleSelectChange(value,'snTratada')}
                              
                            >
                            {this.state.tratadaOptions.map((d) => (
                                        <Select.Option key={d.key}>
                                          {d.nombre}
                                        </Select.Option>
                            ))}
                          </Select>
                          </Form.Item>
                      </Col>
                    </Row>
                    <Row justify='left'>
                      <Col>
                        <Form.Item name='submit'>
                          <Button
                            className='btn btn-green'
                            type='primary'
                            onClick={() => this.handleOnSearch()}
                          >
                            Buscar
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name='limpiarCampos'>
                          <Button
                            className='btn btn-blue'
                            type='primary'
                            onClick={() => {
                              this.limpiarCampos();
                            }}
                          >
                            Limpiar
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div>{this.state.mssg}</div>
                  </Form>
                </div>
                <div className='table-container'>
                  <Row gutter={30} className='bill-selection' justify='end'>
                    <Col xl={{ span: 24 / intColumnas }} className='export'>
                      <span className='button'>
                        <Button
                          size='medium'
                          className='btn-excel'
                          title='Exportar a excel'
                          onClick={this.exportExcel}
                          icon={<FileExcelOutlined />}
                        ></Button>
                      </span>
                    </Col>
                  </Row>
                  <Divider />
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columns}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </div>
              </Spin>
            </div>
          </div>

          <Modal
            title={`Parámetros`}
            className='modal-style'
            visible={this.state.editarVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <div className='header-container'>
              <Form name='localization_form' layout='vertical'>
                <pre>{this.state.parametrosModal.record}</pre>
              </Form>
            </div>
          </Modal>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(ErpErrors);
