import { Button, Col, Form, notification, Row, Spin, Tabs, Modal } from 'antd';
import moment, { months } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  consultaTiposActividades,
} from '../../../services/admin/personServices';
import {
  listaDepartamentos,
  listaMunicipios,
  listaPaises,
} from '../../../services/admin/suppliersService';
import {
  buscarTipoFicha
} from '../../../services/vinculacion/fichaService'
import {
  buscarFichaJuridicaPU, actualizarFichaJuridicaPU, guardarFichaJuridicaPU, descargarFichaJuridicaPU, guardarFichaJuridicaPUF4ALL
} from '../../../services/vinculacion/fichaJuridicaPUservice'
import {
  buscarFichaNaturalPU, actualizarFichaNaturalPU, guardarFichaNaturalPU, descargarFichaNaturalPU
} from '../../../services/vinculacion/fichaNaturalPUservice'
import { setExportZip } from '../../../utilities/downloadTools';
import history from '../../../services/history';
import { DATE_FORMAT, DATE_FORMAT_SECOND, DATE_POST_FORMAT } from '../../../utilities/messages';
import Address from '../../admin/clients/person/juridica/address/address';
import Financial from '../../admin/clients/person/juridica/financial/financial';
import GeneralVincular from '../FichaCliente/generalVincular';
import Legal from '../../admin/clients/person/juridica/legal/legal';
import PersonEntity from '../../admin/clients/person/juridica/personEntity/personEntity';
import Shareholders from '../../admin/clients/person/juridica/shareholders/shareholders';
import Tributary from '../../admin/clients/person/juridica/tributary/tributary';
import CommercialReferences from '../../admin/clients/person/natural/commercialReferences/commercialReferences';
import FinancialNatural from '../../admin/clients/person/natural/financial/financialNatural';
import ForeignCurrencyOperations from '../../admin/clients/person/natural/foreignCurrencyOperations/foreignCurrencyOperations';
import GeneralNatural from '../../admin/clients/person/natural/generalNatural/generalNatural';
import PersonalReferences from '../../admin/clients/person/natural/personalReferences/personalReferences';
import PersonNatural from '../../admin/clients/person/natural/personNatural/personNatural';
import TributaryNatural from '../../admin/clients/person/natural/tributaryNatural/tributaryNatural';
import Tutor from '../../admin/clients/person/natural/tutor/tutor';
import '../../admin/clients/person/person.scss';
import OtherDocuments from './otherDocuments';
import PersonNaturalVincular from './personNaturalVincular';
import { mensajesEstadoVinculacion } from '../mensajesEstadoVin';
import { formDataValidateJ, formDataValidateN } from './dataFichaClientForm';
import OperationLeyModal from '../../../components/operationLeyModal';
import { buscarOperacionesLey, grabarDatosOperacionesLey } from '../../../services/vinculacion/fichaService';


const initialState = {
  activeTab: '1',
  ciudadesEleboraSelect: {
    fetching: false,
    options: [],
  },
  ciudadesNacLeg: {
    fetching: false,
    options: [],
  },
  ciudadesNac: {
    fetching: false,
    options: [],
  },
  actividadSelect: {
    fetching: false,
    options: [],
  },
  cdEntidadEstatal: '',
  cdEntidadDescentralizada: '',
  paises: [],
  departamentosEntidad: { options: [], fetching: false },
  municipiosEntidad: { options: [], fetching: false },
  departamentosRecidenciaLeg: { options: [], fetching: false },
  municipiosRecidenciaLeg: { options: [], fetching: false },
  departamentosResidencia: { options: [], fetching: false },
  municipiosResidencia: { options: [], fetching: false },
  departamentosTutorNac: { options: [], fetching: false },
  municipiosTutorNac: { options: [], fetching: false },
  departamentosTutorRes: { options: [], fetching: false },
  municipiosTutorRes: { options: [], fetching: false },
  departamentosExt: { options: [], fetching: false },
  municipiosExt: { options: [], fetching: false },
  loading: false,
  person: undefined,
  cdReteIva: false,
  cdReteIca: false,
  cdEntidadVigila: '',
  accionistas: [],
  snMenorEdad: false,
  referenciasComerciales: [],
  referenciasPersonales: [],
  cdSnOpMonExt: false,
  fechaLimite: undefined,
  cdSnSancionado: false,
  modalCobranza: true,
  snAceptoMedios: ''
};

class FichaCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.formRef = React.createRef();
  }

  handleSwitch(formValue, key) {
    const { person } = this.state;
    let res = "";
    if (key in formValue) {
      res = formValue[key] ? 'S' : 'N';
    } else {
      res = person[key];
    }
    return res;
  }

  redirectToInsUsuarios = () => {
    history.push(`${process.env.PUBLIC_URL}/vinculacion/inscripcionusuarios`);
  };

  redirectToDocAnexos = () => {
    history.push(`${process.env.PUBLIC_URL}/vinculacion/DocumentosAnexos`);
  };

  handleOnFinish = async (formValue) => {

    if (formValue.cdSnSancionado === undefined || formValue.cdSnSancionado === false) formValue.cdSnSancionado = 'N';
    if (formValue.cdSnSancionado === true) formValue.cdSnSancionado = 'S';
    const {
      person,
      accionistas,
      referenciasComerciales,
      referenciasPersonales,
      personType,
      personId,
      tipoProducto,
      estadoVinculacion
    } = this.state;


    if (personType === 'J') {
      let sumatoria = 0;
      accionistas.map(accionista => {
        sumatoria = sumatoria + parseFloat(accionista.poParticipacion);
      });
      if (!(sumatoria === 100)) {
        notification.error({
          message: 'Error',
          description: "La sumatoria de los accionistas debe ser del 100%",
          duration: 5,
        });

        return;
      }
    }

    const { authtoken } = this.props;
    let jsonPersona = JSON.parse(localStorage.getItem('personData') || '');
    let company = jsonPersona.scCompania;

    const feElaboracion = formValue.feElaboracion
      ? formValue.feElaboracion.format(DATE_POST_FORMAT)
      : person.feElaboracion
        ? moment(person.feElaboracion, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feExpedicionLeg = formValue.feExpedicionLeg
      ? formValue.feExpedicionLeg.format(DATE_POST_FORMAT)
      : person.feExpedicionLeg
        ? moment(person.feExpedicionLeg, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feNacimientoLeg = formValue.feNacimientoLeg
      ? formValue.feNacimientoLeg.format(DATE_POST_FORMAT)
      : person.feNacimientoLeg
        ? moment(person.feNacimientoLeg, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feConsAct = formValue.feConsAct
      ? formValue.feConsAct.format(DATE_POST_FORMAT)
      : person.feConsAct
        ? moment(person.feConsAct, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feVigenciaAct = formValue.feVigenciaAct
      ? formValue.feVigenciaAct.format(DATE_POST_FORMAT)
      : person.feVigenciaAct
        ? moment(person.feVigenciaAct, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    /* ficha natural */
    const feExpedicion = formValue.feExpedicion
      ? formValue.feExpedicion.format(DATE_POST_FORMAT)
      : person.feExpedicion
        ? moment(person.feExpedicion, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feNacimiento = formValue.feNacimiento
      ? formValue.feNacimiento.format(DATE_POST_FORMAT)
      : person.feNacimiento
        ? moment(person.feNacimiento, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feExpTutor = formValue.feExpTutor
      ? formValue.feExpTutor.format(DATE_POST_FORMAT)
      : person.feExpTutor
        ? moment(person.feExpTutor, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feNacimientoTutor = formValue.feNacimientoTutor
      ? formValue.feNacimientoTutor.format(DATE_POST_FORMAT)
      : person.feNacimientoTutor
        ? moment(person.feNacimientoTutor, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    this.setState({
      loading: true,
    });

    const value = {
      idUser: personId,
      ...person,
      ...formValue,
      feElaboracion,
      feExpedicion,
      feExpedicionLeg,
      feNacimientoLeg,
      feConsAct,
      feVigenciaAct,
      cdSnOpMontExt: this.handleSwitch(formValue, "cdSnOpMontExt"),
      cdSnAutorretenedor: this.handleSwitch(formValue, "cdSnAutorretenedor"),
      cdSnSarlaft: this.handleSwitch(formValue, "cdSnSarlaft"),
      cdSnGmf: this.handleSwitch(formValue, "cdSnGmf"),
      cdSnGranContribuyente: this.handleSwitch(formValue, "cdSnGranContribuyente"),
      cdReteIva: this.handleSwitch(formValue, "cdReteIva"),
      cdReteFuente: this.handleSwitch(formValue, "cdReteFuente"),
      cdReteIca: this.handleSwitch(formValue, "cdReteIca"),
      accionistas,
      feNacimiento,
      cdSnIndependiente: this.handleSwitch(formValue, "cdSnIndependiente"),
      cdSnInfoEmail: this.handleSwitch(formValue, "cdSnInfoEmail"),
      snMenorEdad: this.handleSwitch(formValue, "snMenorEdad"),
      feExpTutor,
      feNacimientoTutor,
      referenciasComerciales,
      referenciasPersonales,
      cdSnOpMonExt: this.handleSwitch(formValue, "cdSnOpMonExt"),
      affitoken: '123',
    };

    try {
      if (personType === 'J') {
        if (tipoProducto === 'F' && estadoVinculacion === 'P') {
          await guardarFichaJuridicaPUF4ALL(authtoken, company, value);
        } else {
          await guardarFichaJuridicaPU(authtoken, company, value);
        }
      } else if (personType === 'N') {
        await guardarFichaNaturalPU(authtoken, company, value);
      }

      notification.success({
        title: 'Enviado',
        message: 'Ficha Guardada exitosamente',
        duration: 5,
      });

      this.setState({
        loading: false,
        estadoFicha: 'Completa'
      });

      let jsonPersona = JSON.parse(localStorage.getItem('personData') || '');
      jsonPersona = {
        ...jsonPersona,
        estadoFicha: "Completa"
      }
      localStorage.setItem('personData', JSON.stringify(jsonPersona));

      if (tipoProducto === "F" && estadoVinculacion === "P") {
        this.redirectToDocAnexos();
      } else {
        this.redirectToInsUsuarios();
      }

    } catch {
      this.setState({
        loading: false,
      });
    }
  };

  handleGuardarParcialmente = async () => {

    this.setState({ ...this.state, loading: true });
    const { authtoken } = this.props;
    const { person, personType, personId, referenciasComerciales, referenciasPersonales, accionistas } = this.state;
    let jsonPersona = JSON.parse(localStorage.getItem('personData') || '');
    let company = jsonPersona.scCompania;

    let data = this.formRef.current.getFieldsValue();

    //Validamos que CdSnSancionado No se guarde como Undefined
    if (data.cdSnSancionado === undefined) data.cdSnSancionado = false

    const feElaboracion = data.feElaboracion
      ? data.feElaboracion.format(DATE_POST_FORMAT)
      : person.feElaboracion
        ? moment(person.feElaboracion, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feExpedicionLeg = data.feExpedicionLeg
      ? data.feExpedicionLeg.format(DATE_POST_FORMAT)
      : person.feExpedicionLeg
        ? moment(person.feExpedicionLeg, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feNacimientoLeg = data.feNacimientoLeg
      ? data.feNacimientoLeg.format(DATE_POST_FORMAT)
      : person.feNacimientoLeg
        ? moment(person.feNacimientoLeg, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feConsAct = data.feConsAct
      ? data.feConsAct.format(DATE_POST_FORMAT)
      : person.feConsAct
        ? moment(person.feConsAct, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feVigenciaAct = data.feVigenciaAct
      ? data.feVigenciaAct.format(DATE_POST_FORMAT)
      : person.feVigenciaAct
        ? moment(person.feVigenciaAct, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    /* ficha natural */
    const feExpedicion = data.feExpedicion
      ? data.feExpedicion.format(DATE_POST_FORMAT)
      : person.feExpedicion
        ? moment(person.feExpedicion, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feNacimiento = data.feNacimiento
      ? data.feNacimiento.format(DATE_POST_FORMAT)
      : person.feNacimiento
        ? moment(person.feNacimiento, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feExpTutor = data.feExpTutor
      ? data.feExpTutor.format(DATE_POST_FORMAT)
      : person.feExpTutor
        ? moment(person.feExpTutor, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    const feNacimientoTutor = data.feNacimientoTutor
      ? data.feNacimientoTutor.format(DATE_POST_FORMAT)
      : person.feNacimientoTutor
        ? moment(person.feNacimientoTutor, DATE_FORMAT).format(DATE_POST_FORMAT)
        : undefined;

    data["idUser"] = personId;
    for (let item in data) {

      if (data[item] === false) {
        data[item] = "N";
      } else if (data[item] === true) {
        data[item] = "S";
      }
    }

    const value = {
      ...data,
      referenciasComerciales,
      referenciasPersonales,
      accionistas,
      feElaboracion,
      feExpedicion,
      feExpedicionLeg,
      feNacimientoLeg,
      feConsAct,
      feVigenciaAct,
      feNacimiento,
      feExpTutor,
      feNacimientoTutor,
    };


    try {
      if (personType === 'J') {
        await actualizarFichaJuridicaPU(authtoken, company, value);
      } else if (personType === 'N') {
        await actualizarFichaNaturalPU(authtoken, company, value);
      }

      notification.success({
        title: 'Enviado',
        message: 'Actualización de datos exitosa',
        duration: 5,
      });

      this.setState({
        loading: false,
        estadoFicha: 'Incompleta'
      });
    } catch {
      this.setState({
        loading: false,
      });
    }

  }

  handleDescargar = async () => {

    const { authtoken } = this.props;
    const { personType, personId, estadoFicha } = this.state;
    let jsonPersona = JSON.parse(localStorage.getItem('personData') || '');
    let company = jsonPersona.scCompania;

    if (!(estadoFicha === "Completa")) {
      notification.error({
        title: 'Error',
        message: 'Debes llenar todos los campos de la ficha cliente antes de descargarla',
        duration: 5,
      });
      return;
    }

    this.setState({ ...this.state, loading: true });

    try {
      if (personType === 'J') {
        const res = await descargarFichaJuridicaPU(authtoken, personId, company);
        setExportZip(res, "Documentos Juridica");
      } else if (personType === 'N') {
        const res = await descargarFichaNaturalPU(authtoken, personId, company);
        setExportZip(res, "Documentos Natural");
      }
      notification.success({
        title: 'Descargado',
        message: 'Documentos descargados con exito',
        duration: 5,
      });

      this.setState({
        loading: false,
      });
    } catch {
      this.setState({
        loading: false,
      });
    }

  }

  handleOnChangeCdSnOpMonExt = (value) => {
    this.setState({ ...this.state, cdSnOpMonExt: value });
  };

  handleOnChangeAccionista = (value) => {
    this.setState({ ...this.state, accionistas: value });
  };

  handleOnChangeCommRef = (value) => {
    this.setState({ ...this.state, referenciasComerciales: value });
  };

  handleOnChangePersonalRef = (value) => {
    this.setState({ ...this.state, referenciasPersonales: value });
  };

  handelOnChangeCdEntidadEstatal = (value) => {
    this.setState({ ...this.state, cdEntidadEstatal: value });
  };

  handleOnChangeCdEntididadDescentralizada = (value) => {
    this.setState({ ...this.state, cdEntidadDescentralizada: value });
  };

  handleOnChangeCdReteIva = (value) => {
    this.setState({ ...this.state, cdReteIva: value });
  };

  handleOnChangeCdReteIca = (value) => {
    this.setState({ ...this.state, cdReteIca: value });
  };

  handleOnChangeCdEntidadVigilada = (value) => {
    this.setState({ ...this.state, cdEntidadVigila: value });
  };

  handleOnChangeMenorEdad = (value) => {
    this.setState({ ...this.state, snMenorEdad: value });
  };

  handleOnCiudadesElaboraSearch = async (nombre) => {
    if (nombre === '' || nombre.length >= 3) {
      const { ciudadesEleboraSelect } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        ciudadesEleboraSelect: {
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listaMunicipios(authtoken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: '57',
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesEleboraSelect: {
            ...ciudadesEleboraSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesEleboraSelect: {
            ...ciudadesEleboraSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnCiudadesNacLegSearch = async (nombre = '') => {
    if (nombre === '' || nombre.length >= 3) {
      const { ciudadesNacLeg } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        ciudadesNacLeg: {
          options: [],
          fetching: true,
        },
      });

      const cdPais = this.formRef.current.getFieldValue('cdPaisNacLeg');
      if (!cdPais) {
        return;
      }

      try {
        const response = await listaMunicipios(authtoken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: cdPais,
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesNacLeg: {
            ...ciudadesNacLeg,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesNacLeg: {
            ...ciudadesNacLeg,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnCiudadesNacSearch = async (nombre = '') => {
    if (nombre === '' || nombre.length >= 3) {
      const { ciudadesNac } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        ciudadesNac: {
          options: [],
          fetching: true,
        },
      });

      const cdPais = this.formRef.current.getFieldValue('cdPaisNac');
      if (!cdPais) {
        return;
      }

      try {
        const response = await listaMunicipios(authtoken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: cdPais,
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesNac: {
            ...ciudadesNac,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesNac: {
            ...ciudadesNac,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnChangePaisEntidad = async (value) => {
    this.setState({
      ...this.state,
      departamentosEntidad: { options: [], fetching: true },
      municipiosEntidad: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepEntidad: undefined,
      cdMunEntidad: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosEntidad: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisLaboral = async (value) => {
    this.setState({
      ...this.state,
      departamentosEntidad: { options: [], fetching: true },
      municipiosEntidad: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepEmpresa: undefined,
      cdMunEmpresa: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosEntidad: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisResidencia = async (value) => {
    this.setState({
      ...this.state,
      departamentosResidencia: { options: [], fetching: true },
      municipiosResidencia: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepResidencia: undefined,
      cdMunResidencia: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosResidencia: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosResidencia: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisExt = async (value) => {
    this.setState({
      ...this.state,
      departamentosExt: { options: [], fetching: true },
      municipiosExt: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepExt: undefined,
      cdMunExt: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosExt: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosExt: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisTutorNac = async (value) => {
    this.setState({
      ...this.state,
      departamentosTutorNac: { options: [], fetching: true },
      municipiosTutorNac: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepNacTutor: undefined,
      cdMunNacTutor: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosTutorNac: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosTutorNac: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisTutorRes = async (value) => {
    this.setState({
      ...this.state,
      departamentosTutorRes: { options: [], fetching: true },
      municipiosTutorRes: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepResidenciaTutor: undefined,
      cdMunResidenciaTutor: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosTutorRes: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosTutorRes: { options: [], fetching: false },
      });
    }
  };

  handleOnChangePaisNacLeg = async (value) => {
    this.setState({
      ...this.state,
      ciudadesNacLeg: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdMunNacLeg: undefined,
    });

    this.handleOnCiudadesNacLegSearch();
  };

  handleOnChangePaisNac = async (value) => {
    this.setState({
      ...this.state,
      ciudadesNacLeg: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdMunNac: undefined,
    });

    this.handleOnCiudadesNacSearch();
  };

  handleOnChangePaisResidenciaLeg = async (value) => {
    this.setState({
      ...this.state,
      departamentosRecidenciaLeg: { options: [], fetching: true },
      municipiosRecidenciaLeg: { options: [], fetching: false },
    });

    this.formRef.current.setFieldsValue({
      cdDepResidenciaLeg: undefined,
      cdMunResidenciaLeg: undefined,
    });

    try {
      const departamentos = await this.getDepartamento(value);
      this.setState({
        ...this.state,
        departamentosRecidenciaLeg: { options: departamentos, fetching: false },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        departamentosRecidenciaLeg: { options: [], fetching: false },
      });
    }
  };

  getDepartamento = async (cdPais) => {
    let { paises } = this.state;
    const { authtoken } = this.props;

    const pais = paises.find((pais) => pais.codigo === cdPais);

    let departamentos = pais && pais.departamentos ? pais.departamentos : [];

    if (departamentos.length === 0) {
      const response = await listaDepartamentos(authtoken, {
        cdPais: cdPais,
      });
      departamentos = response.data;

      paises = paises.map((pais) => {
        return pais.codigo === cdPais ? { ...pais, departamentos } : pais;
      });
    }

    this.setState({
      ...this.state,
      paises,
    });

    return departamentos;
  };

  handleOnChangeDepartamentoEntidad = async (value) => {
    this.setState({
      ...this.state,
      municipiosEntidad: { options: [], fetching: true },
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisEntidad');

    this.formRef.current.setFieldsValue({
      cdMunEntidad: undefined,
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosEntidad: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoLaboral = async (value) => {
    this.setState({
      ...this.state,
      municipiosEntidad: { options: [], fetching: true },
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisEmpresa');

    this.formRef.current.setFieldsValue({
      cdMunEmpresa: undefined,
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosEntidad: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosEntidad: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoExt = async (value) => {
    this.setState({
      ...this.state,
      municipiosExt: { options: [], fetching: true },
    });

    this.formRef.current.setFieldsValue({
      cdMunExt: undefined,
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisExt');
    if (!cdPais) {
      return;
    }

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosExt: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosExt: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoResidenciaLeg = async (value) => {
    this.setState({
      ...this.state,
      municipiosRecidenciaLeg: { options: [], fetching: true },
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisResidenciaLeg');

    this.formRef.current.setFieldsValue({
      cdMunResidenciaLeg: undefined,
    });

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosRecidenciaLeg: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosRecidenciaLeg: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoResidencia = async (value) => {
    this.setState({
      ...this.state,
      municipiosResidencia: { options: [], fetching: true },
    });

    this.formRef.current.setFieldsValue({
      cdMunResidencia: undefined,
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisResidencia');
    if (!cdPais) {
      return;
    }

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosResidencia: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosResidencia: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepartamentoNac = async (value) => {
    this.setState({
      ...this.state,
      municipiosTutorNac: { options: [], fetching: true },
    });

    this.formRef.current.setFieldsValue({
      cdMunNacTutor: undefined,
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisNacTutor');
    if (!cdPais) {
      return;
    }

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosTutorNac: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosTutorNac: { options: [], fetching: false },
      });
    }
  };

  handleOnChangeDepTutorRes = async (value) => {
    this.setState({
      ...this.state,
      municipiosTutorRes: { options: [], fetching: true },
    });

    this.formRef.current.setFieldsValue({
      cdMunResidenciaTutor: undefined,
    });

    const cdPais = this.formRef.current.getFieldValue('cdPaisResidenciaTutor');
    if (!cdPais) {
      return;
    }

    try {
      const municipios = await this.getMunicipio(value, cdPais);

      this.setState({
        ...this.state,
        municipiosTutorRes: { options: municipios, fetching: false },
      });
    } catch {
      this.setState({
        ...this.state,
        municipiosTutorRes: { options: [], fetching: false },
      });
    }
  };

  getMunicipio = async (cdDepartamento, cdPais) => {
    let { paises } = this.state;
    const { authtoken } = this.props;

    const pais = paises.find((pais) => pais.codigo === cdPais);

    let deptos = pais ? pais.departamentos : [];

    const departamento = deptos.find(
      (departamento) => departamento.codigo === cdDepartamento
    );

    let municipios =
      departamento && departamento.municipios ? departamento.municipios : [];

    if (municipios.length === 0) {
      const response = await listaMunicipios(authtoken, {
        cdPais: cdPais,
        cdDepartamento: cdDepartamento,
      });
      municipios = response.data;
      deptos = deptos.map((departamento) =>
        departamento.codigo === cdDepartamento
          ? { ...departamento, municipios }
          : departamento
      );
      paises = paises.map((pais) => {
        return pais.codigo === cdPais
          ? { ...pais, departamentos: deptos }
          : pais;
      });
    }

    this.setState({
      ...this.state,
      paises,
    });

    return municipios;
  };

  handleOnActividadSearch = async (nombre) => {
    if (nombre === '' || nombre.length >= 3) {
      const { actividadSelect } = this.state;
      const { authtoken } = this.props;

      this.setState({
        ...this.state,
        actividadSelect: {
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await consultaTiposActividades(authtoken, {
          descripcion: nombre,
        });
        this.setState({
          ...this.state,
          actividadSelect: {
            ...actividadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          actividadSelect: {
            ...actividadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  init = () => {
    const { personType } = this.state;

    if (personType === 'J') {
      this.initJuridicaForm();
    } else if (personType === 'N') {
      this.initNaturalForm();
    }
  };

  initJuridicaForm = async () => {
    const { authtoken } = this.props;

    const {
      paises,
      actividadSelect: { options: actividades },
      personId
    } = this.state;

    let paisTemp = [...paises];
    let actividadTemp = [...actividades];
    let ciudadesElabTemp = [];
    let ciudadesNacLegTemp = [];
    let person = {};
    let estadoFicha = "";
    let estadoVinculacion = "";
    let tipoProducto = "";
    let departamentosEntidad = [];
    let municipiosEntidad = [];
    let departamentosResidenciaLeg = [];
    let municipiosResidenciaLeg = [];

    try {
      const personResponse = await buscarFichaJuridicaPU(authtoken, personId);
      estadoFicha = personResponse.cdEstado;
      estadoVinculacion = personResponse.cdEstadoVinGeneral;
      tipoProducto = personResponse.cdTipoProducto;
      person = { ...personResponse };
      this.setState({ fechaLimite: moment(person.feElaboracion.replace(/\//g, '-'), "DD-MM-YYYY").add(1, 'months').format(DATE_FORMAT) })

      if (paises.length === 0) {
        const paisesResponse = await listaPaises(authtoken);
        paisTemp = [...paisesResponse.data];
        this.setState({ ...this.state, paises: paisTemp });
      }

      // tab de generalres - ciudad elabora  - juridica
      const ciudadResponse = await listaMunicipios(authtoken, {
        conDepartamento: true,
        nombre: person.dsMunElaboracion ? person.dsMunElaboracion : '',
        cdPais: person.cdPaisNac | '57',
        limit: 30,
      });
      ciudadesElabTemp = [...ciudadResponse.data];

      // tab de representante legal - juridica
      const ciudadNacLegResponse = await listaMunicipios(authtoken, {
        conDepartamento: true,
        nombre: person.dsMunNacLeg ? person.dsMunNacLeg : '',
        cdPais: person.cdPaisNacLeg | '57',
        limit: 30,
      });
      ciudadesNacLegTemp = [...ciudadNacLegResponse.data];

      // tab de  direccion - juridica


      if (person.cdPaisEntidad) {
        departamentosEntidad = await this.getDepartamento(
          person.cdPaisEntidad
        )

        if (person.cdDepEntidad) {

          municipiosEntidad = await this.getMunicipio(
            person.cdDepEntidad,
            person.cdPaisEntidad
          );

        }
      }

      if (person.cdPaisResidenciaLeg) {

        departamentosResidenciaLeg = await this.getDepartamento(
          person.cdPaisResidenciaLeg
        );

        if (person.cdDepResidenciaLeg) {

          municipiosResidenciaLeg = await this.getMunicipio(
            person.cdDepResidenciaLeg,
            person.cdPaisResidenciaLeg
          );
        }
      }

      const actividadResponse = await consultaTiposActividades(authtoken, {
        descripcion: person.dsActividad,
      });
      actividadTemp = [...actividadResponse.data];

      this.setState({
        ...this.state,
        activeTab: initialState.activeTab,

        actividadSelect: {
          options: actividadTemp,
          fetching: false,
        },
        ciudadesEleboraSelect: {
          fetching: false,
          options: ciudadesElabTemp,
        },
        ciudadesNacLeg: {
          fetching: false,
          options: ciudadesNacLegTemp,
        },
        departamentosEntidad: {
          options: departamentosEntidad,
          fetching: false,
        },
        municipiosEntidad: {
          fetching: false,
          options: municipiosEntidad,
        },
        departamentosRecidenciaLeg: {
          fetching: false,
          options: departamentosResidenciaLeg,
        },
        municipiosRecidenciaLeg: {
          fetching: false,
          options: municipiosResidenciaLeg,
        },
        cdEntidadEstatal: person.cdEntidadEstatal,
        cdEntidadDescentralizada: person.cdEntidadDescentralizada,
        cdReteIva: person.cdReteIva === 'S',
        cdReteIca: person.cdReteIca === 'S',
        cdEntidadVigila: person.cdEntidadVigila,
        person: person,
        estadoFicha: estadoFicha,
        estadoVinculacion: estadoVinculacion,
        tipoProducto: tipoProducto,
        loading: false,
        accionistas: person.accionistas
          ? person.accionistas.map((accionista) => ({
            ...accionista,
            key: accionista.scAccionista,
          }))
          : [],
      });

      this.setFormValue(person);

    } catch (error) {
      this.setState({ ...this.state, loading: false });
    }

  };

  initNaturalForm = async () => {
    const { authtoken } = this.props;
    const { paises, personId, actividadSelect: { options: actividades } } = this.state;

    let paisTemp = [...paises];
    let ciudadesElabTemp = [];
    let actividadTemp = [...actividades];
    let ciudadesNacTemp = [];
    let person = {};
    let estadoFicha = "";
    let estadoVinculacion = "";
    let tipoProducto = "";
    let departamentosResidencia = [];
    let municipiosResidencia = [];
    let departamentosEntidad = [];
    let municipiosEntidad = [];


    try {
      const personResponse = await buscarFichaNaturalPU(authtoken, personId);
      estadoFicha = personResponse.cdEstado;
      estadoVinculacion = personResponse.cdEstadoVinGeneral;
      tipoProducto = personResponse.cdTipoProducto;
      person = { ...personResponse };
      this.setState({ fechaLimite: moment(person.feElaboracion.replace(/\//g, '-'), "DD-MM-YYYY").add(1, 'months').format(DATE_FORMAT) })


      if (paises.length === 0) {
        const paisesResponse = await listaPaises(authtoken);
        paisTemp = [...paisesResponse.data];
        this.setState({ ...this.state, paises: paisTemp });
      }

      // tab de generalres - ciudad elabora  - Natural
      const ciudadResponse = await listaMunicipios(authtoken, {
        conDepartamento: true,
        nombre: person.dsMunElaboracion ? person.dsMunElaboracion : '',
        cdPais: person.cdPaisNac | '57',
        limit: 30,
      });
      ciudadesElabTemp = [...ciudadResponse.data];

      // tab de datos generales - Natural
      const ciudadNacResponse = await listaMunicipios(authtoken, {
        conDepartamento: true,
        nombre: person.dsMunNac ? person.dsMunNac : '',
        cdPais: person.cdPaisNac | '57',
        limit: 30,
      });
      ciudadesNacTemp = [...ciudadNacResponse.data];

      if (person.cdPaisResidencia) {
        departamentosResidencia = await this.getDepartamento(
          person.cdPaisResidencia
        )

        if (person.cdDepResidencia) {

          municipiosResidencia = await this.getMunicipio(
            person.cdDepResidencia,
            person.cdPaisResidencia
          );

        }
      }

      if (person.cdPaisEmpresa) {
        departamentosEntidad = await this.getDepartamento(
          person.cdPaisEmpresa
        )

        if (person.cdDepEmpresa) {

          municipiosEntidad = await this.getMunicipio(
            person.cdDepEmpresa,
            person.cdPaisEmpresa
          );

        }
      }

      const departamentosTutorNac = person.cdPaisNacTutor
        ? await this.getDepartamento(person.cdPaisNacTutor)
        : [];

      const municipiosTutorNac =
        person.cdDepNacTutor && person.cdPaisNacTutor
          ? await this.getMunicipio(person.cdDepNacTutor, person.cdPaisNacTutor)
          : [];

      const departamentosTutorRes = person.cdPaisResidenciaTutor
        ? await this.getDepartamento(person.cdPaisResidenciaTutor)
        : [];

      const municipiosTutorRes =
        person.cdDepResidenciaTutor && person.cdPaisResidenciaTutor
          ? await this.getMunicipio(
            person.cdDepResidenciaTutor,
            person.cdPaisResidenciaTutor
          )
          : [];

      const departamentosExt = person.cdPaisExt
        ? await this.getDepartamento(person.cdPaisExt)
        : [];

      const municipiosExt =
        person.cdDepExt && person.cdPaisExt
          ? await this.getMunicipio(person.cdDepExt, person.cdPaisExt)
          : [];

      const actividadResponse = await consultaTiposActividades(authtoken, {
        descripcion: person.dsActividad,
      });
      actividadTemp = [...actividadResponse.data];

      this.setState({
        ...this.state,
        actividadSelect: {
          options: actividadTemp,
          fetching: false,
        },
        ciudadesEleboraSelect: {
          fetching: false,
          options: ciudadesElabTemp,
        },
        ciudadesNac: {
          fetching: false,
          options: ciudadesNacTemp,
        },
        departamentosResidencia: {
          fetching: false,
          options: departamentosResidencia,
        },
        departamentosEntidad: {
          fetching: false,
          options: departamentosEntidad,
        },
        departamentosTutorNac: {
          fetching: false,
          options: departamentosTutorNac,
        },
        departamentosTutorRes: {
          fetching: false,
          options: departamentosTutorRes,
        },
        departamentosExt: {
          fetching: false,
          options: departamentosExt,
        },
        municipiosResidencia: {
          fetching: false,
          options: municipiosResidencia,
        },
        municipiosEntidad: {
          fetching: false,
          options: municipiosEntidad,
        },
        municipiosTutorNac: {
          fetching: false,
          options: municipiosTutorNac,
        },
        municipiosTutorRes: {
          fetching: false,
          options: municipiosTutorRes,
        },
        municipiosExt: {
          fetching: false,
          options: municipiosExt,
        },
        activeTab: initialState.activeTab,
        loading: false,
        person: person,
        estadoFicha: estadoFicha,
        estadoVinculacion: estadoVinculacion,
        tipoProducto: tipoProducto,
        snMenorEdad: person.snMenorEdad === 'S',
        referenciasComerciales: person.referenciasComerciales
          ? person.referenciasComerciales.map((ref) => ({
            ...ref,
            key: ref.scReferencia,
          }))
          : [],
        referenciasPersonales: person.referenciasPersonales
          ? person.referenciasPersonales.map((ref) => ({
            ...ref,
            key: ref.scReferencia,
          }))
          : [],
        cdSnGranContribuyente: person.cdSnGranContribuyente === 'S',
        cdReteIva: person.cdReteIva === 'S',
        cdReteFuente: person.cdReteFuente === 'S',
        cdReteIca: person.cdReteIca === 'S',
        cdSnOpMonExt: person.cdSnOpMonExt === 'S',
      });
      this.setFormValue(person);
    } catch (error) {
      this.setState({ ...this.state, loading: false });
    }
  };

  setFormValue = (person) => {
    this.formRef.current.setFieldsValue({
      ...person,
      /* Juridica */
      feElaboracion: person.feElaboracion
        ? moment(person.feElaboracion, DATE_FORMAT)
        : moment(),
      feExpedicionLeg: person.feExpedicionLeg
        ? moment(person.feExpedicionLeg, DATE_FORMAT)
        : '',
      feNacimientoLeg: person.feNacimientoLeg
        ? moment(person.feNacimientoLeg, DATE_FORMAT)
        : '',
      feConsAct: person.feConsAct ? moment(person.feConsAct, DATE_FORMAT) : '',
      feVigenciaAct: person.feVigenciaAct
        ? moment(person.feVigenciaAct, DATE_FORMAT)
        : '',
      cdSnOpMontExt: person.cdSnOpMontExt === 'S',
      cdSnAutorretenedor: person.cdSnAutorretenedor === 'S',
      cdSnSarlaft: person.cdSnSarlaft === 'S',
      cdSnGmf: person.cdSnGmf === 'S',
      cdSnGranContribuyente: person.cdSnGranContribuyente === 'S',
      cdReteIva: person.cdReteIva === 'S',
      cdReteFuente: person.cdReteFuente === 'S',
      cdReteIca: person.cdReteIca === 'S',
      cdSnSancionado: person.cdSnSancionado === 'S',
      /* Natural */
      feExpedicion: person.feExpedicion
        ? moment(person.feExpedicion, DATE_FORMAT)
        : '',
      feNacimiento: person.feNacimiento
        ? moment(person.feNacimiento, DATE_FORMAT)
        : '',
      cdSnIndependiente: person.cdSnIndependiente === 'S',
      cdSnInfoEmail: person.cdSnInfoEmail === 'S',
      feExpTutor: person.feExpTutor
        ? moment(person.feExpTutor, DATE_FORMAT)
        : '',
      feNacimientoTutor: person.feNacimientoTutor
        ? moment(person.feNacimientoTutor, DATE_FORMAT)
        : '',
      snMenorEdad: person.snMenorEdad === 'S',
      cdSnOpMonExt: person.cdSnOpMonExt === 'S',
    });
  };

  validateItems = (errorFields) => {
    let dataToValidate = [];
    const { personType } = this.state;
    if (personType === 'N') {
      dataToValidate = formDataValidateN;
    } else if (personType === 'J') {
      dataToValidate = formDataValidateJ;
    }
    let found = [];
    dataToValidate.map(tab => {
      found = tab.inputs.filter(fieldName => fieldName === errorFields[0].name[0]);
      if (found.length > 0) {
        notification.warning({
          message: 'Ficha Cliente',
          description:
            'Por favor revise los campos del submenú: ' + tab.name,
          duration: 6,
        })
        return;
      }
    })
    notification.warning({
      message: 'Ficha Cliente',
      description:
        'Uno ó mas de los campos requeridos no está correctamente diligenciado',
      duration: 6,
    })

  }

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  waitForLocalStorageVariable = () => {
    return new Promise((resolve) => {
      const value = localStorage.getItem('personData');
      if (value) {
        resolve(value);
      } else {
        const intervalId = setInterval(() => {
          const updatedValue = localStorage.getItem('personData');
          if (updatedValue) {
            clearInterval(intervalId);
            resolve(updatedValue);
          }
        }, 100);
      }
    });
  }

  componentDidMount() {

    this.props.menuHandler('1');
    this.setState({ ...this.state, loading: true });
    this.handleOnModalCobranza();
    try {

      this.waitForLocalStorageVariable().then((result) => {

        const tipoPersona = JSON.parse(result).tipoPersona;
        const id = JSON.parse(result).idUser;

        if ((tipoPersona != undefined) && (id != undefined)) {

          this.setState({
            ...this.state,
            personType: tipoPersona,
            personId: id,
          });

          this.init();

        } else {
          notification.error({
            message: 'Error',
            description: "Error Buscando tipo ficha",
            duration: 5,
          });
        }

      }).catch((error) => {
        this.setState({ ...this.state, loading: false });

      })

    } catch (error) {

      notification.error({
        message: 'Error',
        description: "Error Buscando ficha",
        duration: 5,
      });

      this.setState({ ...this.state, loading: false });

    }

  }

  setmodalCobranza(modalCobranza) {
    this.setState({ modalCobranza });
  }

  handleOnFinishModalCobranza = (formValue) => {
    const { authtoken } = this.props;
    const dataMedio = Object.fromEntries(
      Object.entries(formValue).map(([key, value]) => [key, value ? 'S' : 'N'])
    );

    const NoSeleccionado = Object.values(dataMedio).every(value => value === 'N');
    const Seleccionado = Object.values(dataMedio).some(value => value === 'S');


    if (formValue.numeroCelular.trim() !== '') {
      dataMedio.numeroCelular = formValue.numeroCelular.trim();
    }
  
    if (NoSeleccionado && !Seleccionado) {
      notification.warning({
        message: 'Seleccione un medio',
        description: 'Por favor seleccione un medio',
      });
    } else {

      grabarDatosOperacionesLey(authtoken, dataMedio).then((response) => {
        this.setState({
          ...this.state,
          snAceptoMedios: response.data.snAceptoMedios,
        });
        notification.success({
          message: 'Enviado',
          description: 'Datos Guardados',
        });
      });
    }
  }

  handleOnModalCobranza = () => {
    const { authtoken } = this.props;
    buscarOperacionesLey(authtoken).then((response) => {
      this.setState({
        ...this.state,
        snAceptoMedios: response.data.snAceptoMedios,
      });
    });
  }

  notificacionModalCobranza = () => {
    return (
      notification.warn({
        message: 'Envia la informacion',
        description: 'Selecciona los medios de contacto para recibir información de cobro y publicitaria.',
        duration: 4,
      })
    );
  }

  render() {
    const {
      activeTab,
      ciudadesEleboraSelect,
      loading,
      paises,
      departamentosEntidad,
      municipiosEntidad,
      departamentosRecidenciaLeg,
      municipiosRecidenciaLeg,
      actividadSelect,
      departamentosResidencia,
      municipiosResidencia,
      departamentosTutorNac,
      municipiosTutorNac,
      departamentosTutorRes,
      municipiosTutorRes,
      departamentosExt,
      municipiosExt,
      ciudadesNacLeg,
      cdEntidadEstatal,
      cdEntidadDescentralizada,
      cdReteIva,
      cdReteIca,
      cdEntidadVigila,
      accionistas,
      ciudadesNac,
      snMenorEdad,
      referenciasComerciales,
      referenciasPersonales,
      cdSnOpMonExt,
      estadoVinculacion,
      tipoProducto,
      fechaLimite,
      personType
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='content-margin data-person-style'>
          {(estadoVinculacion === "P" || estadoVinculacion === "AP") ? <h3>Dispone hasta:  <b style={{ color: 'green' }}>{fechaLimite}</b> para terminar el proceso</h3> : ''}
          <h3><b>Estado Solicitud: </b>{mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.description}</h3>
          {estadoVinculacion === "E" ?
            <b style={{ fontSize: '16px' }}>
              {mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.message}
            </b>
            : estadoVinculacion === "CE" ?
              <b style={{ fontSize: '16px' }}>
                {mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.message}
              </b>
              : estadoVinculacion === "RE" ?
                <b style={{ fontSize: '16px' }}>
                  {mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.message}
                </b>
                :
                <>
                  <b style={{ fontSize: '16px' }}>
                    {mensajesEstadoVinculacion.find(mensaje => mensaje.key === estadoVinculacion)?.message}
                  </b>
                  <div className='card-background-light card-shadow radius'>
                    <div className='align-center'>
                      <Form
                        ref={this.formRef}
                        name='ficha_form'
                        layout='vertical'
                        onFinish={this.handleOnFinish}
                        onFinishFailed={({ values, errorFields, outOfDate }) => {
                          this.validateItems(errorFields);
                        }}
                      >
                        <Row gutter={16}>
                          <Col flex='auto'>
                            <Tabs activeKey={activeTab} onChange={this.changeTab}>
                              {
                                (tipoProducto === "F" && personType === "J" && estadoVinculacion === "P") ?

                                  <>
                                    {/* FICHA JURIDICA SOLO 3 TABS FACTORING */}
                                    <Tabs.TabPane key={1} tab='Dirección'>
                                      <b style={{ fontSize: '20px' }}>
                                        DATOS GENERALES DE LA ENTIDAD PRINCIPAL
                                      </b>
                                      <Address
                                        paises={paises}
                                        departamentos={departamentosEntidad}
                                        municipios={municipiosEntidad}
                                        onChangePais={this.handleOnChangePaisEntidad}
                                        onChangeDepartamento={
                                          this.handleOnChangeDepartamentoEntidad
                                        }
                                      />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key={2} tab='Representante legal'>
                                      <Legal
                                        paises={paises}
                                        departamentos={departamentosRecidenciaLeg}
                                        municipios={municipiosRecidenciaLeg}
                                        ciudadesSelect={ciudadesNacLeg}
                                        onChangePais={
                                          this.handleOnChangePaisResidenciaLeg
                                        }
                                        onChangeDepartamento={
                                          this.handleOnChangeDepartamentoResidenciaLeg
                                        }
                                        onCiudadesSearch={
                                          this.handleOnCiudadesNacLegSearch
                                        }
                                        onChangePaisNac={this.handleOnChangePaisNacLeg}
                                      />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key={3} tab='Accionistas'>
                                      <Shareholders
                                        accionistas={accionistas}
                                        onChange={this.handleOnChangeAccionista}
                                      />
                                    </Tabs.TabPane>
                                  </>

                                  : (tipoProducto === "F" && personType === "J" && estadoVinculacion === "AP") ?

                                    <>
                                      {/* FICHA JURIDICA TODOS LOS TABS */}
                                      <Tabs.TabPane key={1} tab='Datos generales'>
                                        <GeneralVincular
                                          onCiudadesSearch={
                                            this.handleOnCiudadesElaboraSearch
                                          }
                                          ciudadesSelect={ciudadesEleboraSelect}
                                        />
                                      </Tabs.TabPane>
                                      <Tabs.TabPane key={2} tab='Dirección'>
                                        <b style={{ fontSize: '20px' }}>
                                          DATOS GENERALES DE LA ENTIDAD PRINCIPAL
                                        </b>
                                        <Address
                                          paises={paises}
                                          departamentos={departamentosEntidad}
                                          municipios={municipiosEntidad}
                                          onChangePais={this.handleOnChangePaisEntidad}
                                          onChangeDepartamento={
                                            this.handleOnChangeDepartamentoEntidad
                                          }
                                        />
                                      </Tabs.TabPane>
                                      <Tabs.TabPane key={3} tab='Representante legal'>
                                        <Legal
                                          paises={paises}
                                          departamentos={departamentosRecidenciaLeg}
                                          municipios={municipiosRecidenciaLeg}
                                          ciudadesSelect={ciudadesNacLeg}
                                          onChangePais={
                                            this.handleOnChangePaisResidenciaLeg
                                          }
                                          onChangeDepartamento={
                                            this.handleOnChangeDepartamentoResidenciaLeg
                                          }
                                          onCiudadesSearch={
                                            this.handleOnCiudadesNacLegSearch
                                          }
                                          onChangePaisNac={this.handleOnChangePaisNacLeg}
                                        />
                                      </Tabs.TabPane>
                                      <Tabs.TabPane
                                        key={4}
                                        tab='Tipo de entidad y naturaleza juridica'
                                      >
                                        <PersonEntity
                                          cdEntidadEstatal={cdEntidadEstatal}
                                          cdEntidadDescentralizada={
                                            cdEntidadDescentralizada
                                          }
                                          actividadSelect={actividadSelect}
                                          onActividadSearch={this.handleOnActividadSearch}
                                          onChangeCdEntidadEstatal={
                                            this.handelOnChangeCdEntidadEstatal
                                          }
                                          onChangeCdEntididadDescentralizada={
                                            this.handleOnChangeCdEntididadDescentralizada
                                          }
                                        />
                                      </Tabs.TabPane>
                                      <Tabs.TabPane key={5} tab='Información tributaria'>
                                        <Tributary
                                          cdReteIva={cdReteIva}
                                          cdReteIca={cdReteIca}
                                          cdEntidadVigila={cdEntidadVigila}
                                          onChangeCdReteIva={this.handleOnChangeCdReteIva}
                                          onChangeCdReteIca={this.handleOnChangeCdReteIca}
                                          onChangeCdEntidadVigilada={
                                            this.handleOnChangeCdEntidadVigilada
                                          }
                                        />
                                      </Tabs.TabPane>
                                      <Tabs.TabPane key={6} tab='Accionistas'>
                                        <Shareholders
                                          accionistas={accionistas}
                                          onChange={this.handleOnChangeAccionista}
                                        />
                                      </Tabs.TabPane>
                                      <Tabs.TabPane key={7} tab='Información financiera'>
                                        <Financial />
                                      </Tabs.TabPane>
                                      <Tabs.TabPane key={8} tab='Otros'>
                                        <OtherDocuments
                                          actividadSelect={actividadSelect}
                                          onActividadSearch={this.handleOnActividadSearch}
                                          tipoFicha={personType} />
                                      </Tabs.TabPane>
                                    </>

                                    : tipoProducto === "C" && personType === "J" ?

                                      <>
                                        {/* FICHA JURIDICA TODOS LOS TABS*/}
                                        <Tabs.TabPane key={1} tab='Datos generales'>
                                          <GeneralVincular
                                            onCiudadesSearch={
                                              this.handleOnCiudadesElaboraSearch
                                            }
                                            ciudadesSelect={ciudadesEleboraSelect}
                                          />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key={2} tab='Dirección'>
                                          <b style={{ fontSize: '20px' }}>
                                            DATOS GENERALES DE LA ENTIDAD PRINCIPAL
                                          </b>
                                          <Address
                                            paises={paises}
                                            departamentos={departamentosEntidad}
                                            municipios={municipiosEntidad}
                                            onChangePais={this.handleOnChangePaisEntidad}
                                            onChangeDepartamento={
                                              this.handleOnChangeDepartamentoEntidad
                                            }
                                          />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key={3} tab='Representante legal'>
                                          <Legal
                                            paises={paises}
                                            departamentos={departamentosRecidenciaLeg}
                                            municipios={municipiosRecidenciaLeg}
                                            ciudadesSelect={ciudadesNacLeg}
                                            onChangePais={
                                              this.handleOnChangePaisResidenciaLeg
                                            }
                                            onChangeDepartamento={
                                              this.handleOnChangeDepartamentoResidenciaLeg
                                            }
                                            onCiudadesSearch={
                                              this.handleOnCiudadesNacLegSearch
                                            }
                                            onChangePaisNac={this.handleOnChangePaisNacLeg}
                                          />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                          key={4}
                                          tab='Tipo de entidad y naturaleza juridica'
                                        >
                                          <PersonEntity
                                            cdEntidadEstatal={cdEntidadEstatal}
                                            cdEntidadDescentralizada={
                                              cdEntidadDescentralizada
                                            }
                                            actividadSelect={actividadSelect}
                                            onActividadSearch={this.handleOnActividadSearch}
                                            onChangeCdEntidadEstatal={
                                              this.handelOnChangeCdEntidadEstatal
                                            }
                                            onChangeCdEntididadDescentralizada={
                                              this.handleOnChangeCdEntididadDescentralizada
                                            }
                                          />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key={5} tab='Información tributaria'>
                                          <Tributary
                                            cdReteIva={cdReteIva}
                                            cdReteIca={cdReteIca}
                                            cdEntidadVigila={cdEntidadVigila}
                                            onChangeCdReteIva={this.handleOnChangeCdReteIva}
                                            onChangeCdReteIca={this.handleOnChangeCdReteIca}
                                            onChangeCdEntidadVigilada={
                                              this.handleOnChangeCdEntidadVigilada
                                            }
                                          />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key={6} tab='Accionistas'>
                                          <Shareholders
                                            accionistas={accionistas}
                                            onChange={this.handleOnChangeAccionista}
                                          />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key={7} tab='Información financiera'>
                                          <Financial />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key={8} tab='Otros'>
                                          <OtherDocuments
                                            actividadSelect={actividadSelect}
                                            onActividadSearch={this.handleOnActividadSearch}
                                            tipoFicha={personType} />
                                        </Tabs.TabPane>
                                      </>

                                      : tipoProducto === "C" && personType === "N" ?

                                        <>
                                          {/* FICHA NATURAL TODOS LOS TABS */}
                                          <Tabs.TabPane key={1} tab='Datos personales'>
                                            <PersonNaturalVincular
                                              onCiudadesSearch={
                                                this.handleOnCiudadesElaboraSearch
                                              }
                                              ciudadesSelect={ciudadesEleboraSelect}
                                            />
                                          </Tabs.TabPane>
                                          <Tabs.TabPane key={2} tab='Datos generales'>
                                            <GeneralNatural
                                              paises={paises}
                                              departamentosResidencia={departamentosResidencia}
                                              municipiosResidencia={municipiosResidencia}
                                              departamentosEmpresa={departamentosEntidad}
                                              municipiosEmpresa={municipiosEntidad}
                                              ciudadesNac={ciudadesNac}
                                              onChangePaisResidencia={
                                                this.handleOnChangePaisResidencia
                                              }
                                              onChangeDepartamentoResidencia={
                                                this.handleOnChangeDepartamentoResidencia
                                              }
                                              onChangePaisLaboral={
                                                this.handleOnChangePaisLaboral
                                              }
                                              onChangeDepartamentoLaboral={
                                                this.handleOnChangeDepartamentoLaboral
                                              }
                                              onChangePaisNac={this.handleOnChangePaisNac}
                                              onChangeCiudadesNac={
                                                this.handleOnCiudadesNacSearch
                                              }
                                            />
                                          </Tabs.TabPane>
                                          <Tabs.TabPane
                                            key={3}
                                            tab='En caso de tratarse de un menor de edad'
                                          >
                                            <Tutor
                                              snMenorEdad={snMenorEdad}
                                              paises={paises}
                                              departamentosNac={departamentosTutorNac}
                                              municipiosNac={municipiosTutorNac}
                                              departamentosRes={departamentosTutorRes}
                                              municipiosRes={municipiosTutorRes}
                                              onChangePaisNacimiento={
                                                this.handleOnChangePaisTutorNac
                                              }
                                              onChangeDepartamentoNac={
                                                this.handleOnChangeDepartamentoNac
                                              }
                                              onChangePaisResidencia={
                                                this.handleOnChangePaisTutorRes
                                              }
                                              onChangeDepartamentoResidencia={
                                                this.handleOnChangeDepTutorRes
                                              }
                                              onChangeMenorEdad={this.handleOnChangeMenorEdad}
                                            />
                                          </Tabs.TabPane>
                                          <Tabs.TabPane key={4} tab='Referencias comerciales'>
                                            <CommercialReferences
                                              referenciasComerciales={referenciasComerciales}
                                              onChange={this.handleOnChangeCommRef}
                                            />
                                          </Tabs.TabPane>
                                          <Tabs.TabPane key={5} tab='Referencias personales'>
                                            <PersonalReferences
                                              referenciasPersonales={referenciasPersonales}
                                              onChange={this.handleOnChangePersonalRef}
                                            />
                                          </Tabs.TabPane>
                                          <Tabs.TabPane key={6} tab='Información tributaria'>
                                            <TributaryNatural
                                              cdReteIva={cdReteIva}
                                              cdReteIca={cdReteIca}
                                              onChangeCdReteIva={this.handleOnChangeCdReteIva}
                                              onChangeCdReteIca={this.handleOnChangeCdReteIca}
                                            />
                                          </Tabs.TabPane>
                                          <Tabs.TabPane key={7} tab='Información financiera'>
                                            <FinancialNatural />
                                          </Tabs.TabPane>
                                          <Tabs.TabPane
                                            key={8}
                                            tab='Operaciones en moneda extranjera'
                                          >
                                            <ForeignCurrencyOperations
                                              paises={paises}
                                              cdSnOpMonExt={cdSnOpMonExt}
                                              departamentos={departamentosExt}
                                              municipios={municipiosExt}
                                              onChangePais={this.handleOnChangePaisExt}
                                              onChangeDepartamento={
                                                this.handleOnChangeDepartamentoExt
                                              }
                                              onChangeCdSnOpMonExt={
                                                this.handleOnChangeCdSnOpMonExt
                                              }
                                            />
                                          </Tabs.TabPane>
                                          <Tabs.TabPane key={9} tab='Otros'>
                                            <OtherDocuments
                                              actividadSelect={actividadSelect}
                                              onActividadSearch={this.handleOnActividadSearch}
                                              tipoFicha={personType} />
                                          </Tabs.TabPane>
                                        </>

                                        : null
                              }
                            </Tabs>
                          </Col>
                        </Row>
                        <Row align='middle' justify='center' gutter={16}>
                          <Col span={6}>
                            <Form.Item>
                              <Button
                                className='form-btn btn-radius'
                                type='primary'
                                onClick={this.handleGuardarParcialmente}
                              >
                                Guardar Parcialmente
                              </Button>
                            </Form.Item>
                          </Col>
                          {estadoVinculacion !== "P" || tipoProducto === "C" ?
                            <Col span={6}>
                              <Form.Item>
                                <Button
                                  className='form-btn-blue btn-radius'
                                  type='default'
                                  onClick={this.handleDescargar}
                                >
                                  Descargar
                                </Button>
                              </Form.Item>
                            </Col> : null}
                          <Col span={6}>
                            <Form.Item>
                              <Button
                                className='form-btn btn-radius'
                                type='primary'
                                htmlType='submit'
                              >
                                Guardar y Enviar
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                  {this.state.snAceptoMedios === 'N' && (
                    <Modal
                      visible={this.state.modalCobranza}
                      onCancel={() => this.notificacionModalCobranza(false)}
                      footer={null}
                      width={768}
                    >
                      <Row>
                        <OperationLeyModal onFinish={this.handleOnFinishModalCobranza}></OperationLeyModal>
                      </Row>
                    </Modal>
                  )}
                </>}

        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authtoken: state.auth.auth.access_token,
    company: state.auth.auth.scPersona,
  };
};

export default connect(mapStateToProps, {})(FichaCliente);


