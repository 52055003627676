export const requiredValueMessage = () => {
  return 'Éste campo es requerido';
};

export const formatValueMessage = () => {
  return 'El valor no tiene el formato correcto';
};

export const maxValueMessage = (maxValue) => {
  return `El valor supera la cantidad máxima de caracteres (${maxValue})`;
};

export const minValueMessage = (minValue) => {
  return `El valor no supera el numero mínima de caracteres (${minValue})`;
};

export const rangeValueMessage = (minValue, maxValue) => {
  return `El valor debe estar entre ${minValue} y ${maxValue}`;
};

export const emailPattern =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const multiEmailPattern =
  /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;

export const digitsPattern = (entera, fraccion) => {
  return new RegExp(
    `^(\\d{1,${entera}}(\\.\\d{0,${fraccion}})|(\\d{1,${entera}})|(\\.?\\d{1,${fraccion}}))$`
  );
};

export const digitsMessagge = (entera, fraccion) => {
  return `valor numérico fuera de los límites (se espera: <${entera} dígitos>. <${fraccion} dígitos> )`;
};

export const passwordPattern =
  /^(?=.*[a-z]){3,}(?=.*[A-Z]){2,}(?=.*[0-9]){2,}(?=.*[!@#$%^&*()--__+.]){1,}.{8,}$/;

export const passwordMessage = () => {
  return `debe contener al menos 8 caracteres, ser alfanumérica e incluir al menos una mayúscula y un caracter especial`;
};

export const compareContraseñaMessage = (dependencies) => {
  return `debe ser igual a ${dependencies}`;
};

export const validateFechaNacimiento = (value, fechaActual) => {
  if (fechaActual.diff(value, 'years') < 18) {
    return Promise.reject('La solicitud debe ser tramitada por una persona mayor de edad');
  }
  return Promise.resolve();
};
