import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Col, Divider, notification, Row, Spin } from 'antd';
import React, { Component } from 'react';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  listarConvenios,
  listarProveedores,
  listarTipoBeneficio,
  tasa,
  tasaExcel,
} from '../../../../services/pagador/reportServices';
import { setExcel } from '../../../../utilities/downloadTools';
import {
  currencyFormat,
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import CompletedAgreementForm from './completedAgreementForm/completedAgreementForm';

const initialState = {
  resetForm: false,
  form: {
    scProveedor: '',
    cdFactura: '',
    scConvenio: '',
    fechaDesdeDesembolso: '',
    fechaHastaDesembolso: '',
    cdTipoBeneficio:''
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  tipoBeneficioSelect: {
    fetching: false,
    options: [],
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 19,
    },
    {
      id: 'totalVrPago',
      value: currencyFormat(0),
      index: 'vrPago',
      colSpan: 1,
    },
    {
      id: 'totalVrBeneficioExponencial',
      value: currencyFormat(0),
      index: 'vrBeneficioExponencialTotal',
      colSpan: 1,
    },
    {
      id: 'totalVrCostoTransaccion',
      value: currencyFormat(0),
      index: 'vrCostoTransaccionPg',
      colSpan: 1,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class CompletedAgreement extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
    this.columnas = [
      {
        title: 'TIPO CONVENIO',
        dataIndex: 'tipoConvenio',
        sorter: (a, b) => sorterText(a.tipoConvenio - b.tipoConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => sorterText(a.nombreConvenio - b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor - b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador - b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'CÓDIGO DE FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura - b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'REF. DOC. CONTABLE',
        dataIndex: 'cdDocumentoSAP',
        sorter: (a, b) => sorterText(a.cdDocumentoSAP - b.cdDocumentoSAP),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REPORTADOR',
        dataIndex: 'vrFactura',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR BASE FACTURA',
        dataIndex: 'vrFacturaBase',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REDUCIDO',
        dataIndex: 'vrFacturaChReducido',
        render: (value) => <span>{currencyFormat(value)} </span>,
        sorter: (a, b) => a.vrFacturaChReducido - b.vrFacturaChReducido,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR NOTAS CRÉDITO',
        dataIndex: 'vrTotalNotas',
        render: (value) => <span>{currencyFormat(value || 0)} </span>,
        sorter: (a, b) => a.vrTotalNotas - b.vrTotalNotas,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA EMISIÓN',
        dataIndex: 'feEmision',
        sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ESTIMADA DE PAGO',
        dataIndex: 'feEstimadaPago',
        sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'TASA DESCUENTO M.A',
        dataIndex: 'poDescuentoProveedor',
        render: (value) => (
          <span className='format-number'>{percentageFormat(value)}</span>
        ),
        sorter: (a, b) => a.poDescuentoProveedor - b.poDescuentoProveedor,
        showSorterTooltip: false,
      },
      {
        title: 'TASA BENEFICIO M.A',
        dataIndex: 'poBeneficioPagadorReal',
        render: (value) => (
          <span className='format-number'>{percentageFormat(value)}</span>
        ),
        sorter: (a, b) => a.poBeneficioPagadorReal - b.poBeneficioPagadorReal,
        showSorterTooltip: false,
      },
      {
        title: 'DÍAS BENEFICIO',
        dataIndex: 'numDiasBeneficioPg',
        render: (value) => <span className='format-number'>{value}</span>,
        sorter: (a, b) => a.numDiasBeneficioPg - b.numDiasBeneficioPg,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR BENEFICIO',
        dataIndex: 'vrBeneficioPagador',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrBeneficioPagador - b.vrBeneficioPagador,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE COMPRA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE PAGO',
        dataIndex: 'fePago',
        sorter: (a, b) => sorterDate(a.fePago, b.fePago),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PAGADO',
        dataIndex: 'vrPago',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrPago - b.vrPago,
        showSorterTooltip: false,
      },
      {
        title: 'BENEFICIO EXPONENCIAL',
        dataIndex: 'vrBeneficioExponencialTotal',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) =>
          a.vrBeneficioExponencialTotal - b.vrBeneficioExponencialTotal,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR POR TRANSACCIÓN',
        dataIndex: 'vrCostoTransaccionPg',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrCostoTransaccionPg - b.vrCostoTransaccionPg,
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchProveedor = (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      listarProveedores(this.props.authToken, this.props.company.company, {
        nombre: nombre,
      }).then((response) => {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      });
    }
  };

  handleOnSearchConvenio = (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });

      listarConvenios(this.props.authToken, this.props.company.company, {
        nombre: nombre,
      }).then((response) => {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      });
    }
  };

  getInitTipoBenedicioData = async () => {
    const { tipoBeneficioSelect } = this.state;
    this.setState({
      ...this.state,
      tipoBeneficioSelect: {
        ...tipoBeneficioSelect,
        fetching: true,
      },
    });
    try {
      const response = await listarTipoBeneficio(this.props.authToken,this.props.company.company);
      this.setState({
        ...this.state,
        tipoBeneficioSelect: {
          ...tipoBeneficioSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        tipoBeneficioSelect: {
          ...tipoBeneficioSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scConvenio: value.scConvenio,
        scProveedor: value.scProveedor,
        cdFactura: value.cdFactura,
        fechaDesdeDesembolso: value.fechaRango[0],
        fechaHastaDesembolso: value.fechaRango[1],
        cdTipoBeneficio:value.cdTipoBeneficio
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await tasa(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = currencyFormat(response.metadata.sumatorias[res.id]);
          }
          return res;
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await tasaExcel(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );
        setExcel(response, `Reporte_transacciones_finalizadas`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeDesembolso;
    const fechaHasta = this.state.form.fechaHastaDesembolso;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleOnReset = () => {
    this.setState({
      ...this.state,
      resetForm: false,
      form: {
        ...initialState.form,
      },
    });
  };

  componentDidUpdate(props, state) {
    if (this.props.company.company != props.company.company) {
      this.setState({
        ...initialState,
        resetForm: true,
        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'tipoConvenio',
            colSpan: 15,
          },
          {
            id: 'totalVrPago',
            value: currencyFormat(0),
            index: 'vrPago',
            colSpan: 1,
          },
          {
            id: 'totalVrBeneficioExponencial',
            value: currencyFormat(0),
            index: 'vrBeneficioExponencialTotal',
            colSpan: 1,
          },
          {
            id: 'totalVrCostoTransaccion',
            value: currencyFormat(0),
            index: 'vrCostoTransaccionPg',
            colSpan: 1,
          },
        ],
      });
    }
  }
  componentDidMount() {
    this.getInitTipoBenedicioData();
  }

  render() {
    const { proveedorSelect, convenioSelect, loading, resetForm,tipoBeneficioSelect } = this.state;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <CompletedAgreementForm
            resetForms={resetForm}
            proveedorSelect={proveedorSelect}
            convenioSelect={convenioSelect}
            tipoBeneficioSelect={tipoBeneficioSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnReset={this.handleOnReset}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={this.state.currentPage}
              limit={this.state.limit}
              total={this.state.total}
              resumen={this.state.resumen}
              columns={this.columnas}
              data={this.state.resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default CompletedAgreement;
