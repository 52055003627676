export const columns = {
  tramite: [
    {
      title: 'TIPO DE CONVENIO',
      dataIndex: 'tipoConvenio',
      sorter: (a, b) => a.tipoConvenio - b.tipoConvenio,
      showSorterTooltip: false
    },
    {
      title: 'FONDEADOR',
      dataIndex: 'nombreFondeador',
      sorter: (a, b) => a.nombreFondeador - b.nombreFondeador,
      showSorterTooltip: false
    },
    {
      title: 'PAGADOR',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => a.nombrePagador - b.nombrePagador,
      showSorterTooltip: false
    },
    {
      title: 'PROVEEDOR',
      dataIndex: 'nombreProveedor',
      sorter: (a, b) => a.nombreProveedor - b.nombreProveedor,
      showSorterTooltip: false
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => a.cdFactura - b.cdFactura,
      showSorterTooltip: false
    },
    {
      title: 'FECHA VENTA',
      dataIndex: 'feCompra',
      sorter: (a, b) => a.feCompra - b.feCompra,
      showSorterTooltip: false
    },
    {
      title: 'TASA PROPORCIONAL',
      dataIndex: 'tasaProporcionalInf',
      sorter: (a, b) => a.tasaProporcionalInf - b.tasaProporcionalInf,
      showSorterTooltip: false
    },
    {
      title: 'VALOR OFERTA',
      dataIndex: 'vrOferta',
      sorter: (a, b) => a.vrOferta - b.vrOferta,
      showSorterTooltip: false
    },
    {
      title: 'NÚMERO DE CUENTA',
      dataIndex: 'numeroCuenta',
      sorter: (a, b) => a.numeroCuenta - b.numeroCuenta,
      showSorterTooltip: false
    },
    {
      title: 'BANCO',
      dataIndex: 'nombreBanco',
      sorter: (a, b) => a.nombreBanco - b.nombreBanco,
      showSorterTooltip: false
    },
  ],
  proveedor: [
    {
      title: 'PAGADOR',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => a.nombrePagador - b.nombrePagador,
      showSorterTooltip: false
    },
  ],
  pagador: [
    {
      title: 'PROVEEDOR',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => a.nombrePagador - b.nombrePagador,
      showSorterTooltip: false
    },
  ],
  fondeador: [
    {
      title: 'PROVEEDOR',
      dataIndex: 'nombreProveedor',
      sorter: (a, b) => a.nombreProveedor - b.nombreProveedor,
      showSorterTooltip: false
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdDocumentoSAP',
      sorter: (a, b) => a.cdDocumentoSAP - b.cdDocumentoSAP,
      showSorterTooltip: false
    },
    {
      title: 'REF DOC CONTABLE',
      dataIndex: 'cdFactura',
      sorter: (a, b) => a.cdFactura - b.cdFactura,
      showSorterTooltip: false
    },
    {
      title: 'VALOR REPORTADO',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      showSorterTooltip: false
    },
    {
      title: 'VALOR BASE',
      dataIndex: 'vrFacturaBase',
      sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
      showSorterTooltip: false
    },
    {
      title: 'FECHA VENCIMIENTO',
      dataIndex: 'feVencimiento',
      sorter: (a, b) => a.feVencimiento - b.feVencimiento,
      showSorterTooltip: false
    },
    {
      title: 'FECHA ESPERADA DE PAGO',
      dataIndex: 'feEstimadaPago',
      sorter: (a, b) => a.feEstimadaPago - b.feEstimadaPago,
      showSorterTooltip: false
    },
    {
      title: 'DESCUENTO M.A.',
      dataIndex: 'vrBeneficioFondeadorReal',
      sorter: (a, b) => a.vrBeneficioFondeadorReal - b.vrBeneficioFondeadorReal,
      showSorterTooltip: false
    },
    {
      title: 'BENEFICIO E.A.',
      dataIndex: 'vrBeneficioExponencialTotal',
      sorter: (a, b) => a.vrBeneficioExponencialTotal - b.vrBeneficioExponencialTotal,
      showSorterTooltip: false
    },
    {
      title: 'FECHA',
      dataIndex: 'feCompra',
      sorter: (a, b) => a.feCompra - b.feCompra,
      showSorterTooltip: false
    },
  ],
  contabilidad: [
    {
      title: 'PAGADOR',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => a.nombrePagador - b.nombrePagador,
      showSorterTooltip: false
    },
    {
      title: 'FONDEADOR',
      dataIndex: 'nombreFondeador',
      sorter: (a, b) => a.nombreFondeador - b.nombreFondeador,
      showSorterTooltip: false
    },
    {
      title: 'PROVEEDOR',
      dataIndex: 'nombreProveedor',
      sorter: (a, b) => a.nombreProveedor - b.nombreProveedor,
      showSorterTooltip: false
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdDocumentoSAP',
      sorter: (a, b) => a.cdDocumentoSAP - b.cdDocumentoSAP,
      showSorterTooltip: false
    },
    {
      title: 'VALOR REPORTADO',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      showSorterTooltip: false
    },
    {
      title: 'VALOR BASE',
      dataIndex: 'vrFacturaBase',
      sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
      showSorterTooltip: false
    },
    {
      title: 'FECHA EMISION',
      dataIndex: 'feEmision',
      sorter: (a, b) => a.feEmision - b.feEmision,
      showSorterTooltip: false
    },
    {
      title: 'FECHA VENCIMIENTO',
      dataIndex: 'feVencimiento',
      sorter: (a, b) => a.feVencimiento - b.feVencimiento,
      showSorterTooltip: false
    },
    {
      title: 'FECHA VENTA',
      dataIndex: 'feEmision',
      sorter: (a, b) => a.feEmision - b.feEmision,
      showSorterTooltip: false
    },
    {
      title: 'VALOR DESEMBOLSO',
      dataIndex: 'vrDesembolso',
      sorter: (a, b) => a.vrDesembolso - b.vrDesembolso,
      showSorterTooltip: false
    },
    {
      title: 'VALOR TRANSACCIÓN',
      dataIndex: 'vrCostoTransaccionPv',
      sorter: (a, b) => a.vrCostoTransaccionPv - b.vrCostoTransaccionPv,
      showSorterTooltip: false
    },
  ],
}