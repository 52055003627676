import { Button, Modal, notification, Spin } from 'antd';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import RangosComisionModal from '../../../../../../components/rangosComisionModal/rangosComisionModal';
import TasaDescuentoModal from '../../../../../../components/tasaDescuentoModal/tasaDescuentoModal';
import { crearConvenioProveedor, listarConveniosFondeador } from '../../../../../../services/admin/operation';
import { listarProveedores } from '../../../../../../services/admin/reports/quotasService';
import { PROV_SIN_TASAS } from '../../../../../../utilities/messages';
import SupplierForm, {
  initialSupplierFormValue,
} from '../supplierForm/supplierForm';

const MESSAGE_TITLE = 'Proveedores';

const initialState = {
  visible: false,
  loading: false,
  configModalVisible: false,
  proveedorSelect: {
    options: [],
    fetching: false,
  },

  rangoComisionVisible: false,
  TasaDescuentoVisible: false,
  nmRangosCom: '',
  rangosCom: [],
  data: { ...initialSupplierFormValue, key: '0' },
  dataFondeador:[],
  rangosPoDescuentoProveedor:[]
};

class SupplierNewModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnSearchProveedor = async (nombre) => {
    if (!nombre || nombre === '' || nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  getData = async () => {
    const { authToken } = this.props;
    try {
      const proveedorRes = await listarProveedores(authToken);

      this.setState({
        ...this.state,
        loading: false,
        proveedorSelect: {
          options: proveedorRes.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({ ...this.state, loading: false, visible: false });
    }
  };
  handleBuscarConvenioFondeador = async () => {
    const { scConvenio, authToken } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarConveniosFondeador(authToken, scConvenio);

      this.setState({
        ...this.state,
        dataFondeador: response.data,
        loading: false,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnOpenModal = () => {
    const { onOpen } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    onOpen();

    const data = this.initialData();

    this.setState({
      ...this.state,
      data,
      visible: true,
      loading: false,
    });
  };

  handleOnClose = () => {
    this.handleBuscarConvenioFondeador()
    this.setState({
      ...this.state,
      visible: false,
    });
  };

  handleOnConfigRangoComision = (nmRangosComNew) => {
    const { nmRangosCom, rangosCom } = this.state;
    this.setState({
      ...this.state,
      rangoComisionVisible: true,
      nmRangosCom: nmRangosComNew,
      rangosCom: nmRangosCom !== nmRangosComNew ? [] : rangosCom,
    });
  };

  handleOnTasaDescuentoModal = () => {
    this.setState({
      ...this.state,
      TasaDescuentoVisible: true,
    });
  };

  handleTasaDescuentoModalClose = () => {
    this.setState({
      ...this.state,
      TasaDescuentoVisible: false,
    });
  };
  handleOnFinishTasaDescuentoModal = (rangosPoDescuentoProveedor) => {
    this.setState({
      ...this.state,
      rangosPoDescuentoProveedor,
      TasaDescuentoVisible: false,
      dataFondeador:[...rangosPoDescuentoProveedor]
    });
  };

  handleOnFinishRangoModal = (rangosCom) => {
    this.setState({
      ...this.state,
      rangosCom,
      rangoComisionVisible: false,
    });
  };

  handleOnRangoModalClose = () => {
    this.setState({
      ...this.state,
      rangoComisionVisible: false,
    });
  };

  handleOnFinish = async (formValue) => {
    const { authToken, onFinish, scConvenio } = this.props;
    const { rangosCom, rangosPoDescuentoProveedor } = this.state;

    formValue.poDescuentoProveedor = 0
    formValue.cdSnPuntosBasicos = 'N'
    if(this.validateForm()){
      this.setState({ ...this.state, loading: true });
    try {
      const response = await crearConvenioProveedor(authToken, scConvenio, {
        ...formValue,
        rangosCom,
        rangosPoDescuentoProveedor
      });
      notification.success({
        message: MESSAGE_TITLE,
        description: response.message,
        duration: 6,
      });
      this.setState({ ...this.state, loading: false, visible: false });

      onFinish({ ...formValue, rangosCom, scProveedor: response.location });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  }
  };
  componentDidUpdate(props, state) {
    if (this.state.visible !== state.visible && this.state.visible) {
      this.getData();
    }
  }
  componentDidMount(){
    this.handleBuscarConvenioFondeador();
  }

  initialData = () => {
    let uuids = uuidv4();
    return { ...initialSupplierFormValue, key: uuids };
  };
  validateForm = () => {
    let data = this.state.rangosPoDescuentoProveedor;
    let rangosProv = true;
    if(data.length === 0) {
      rangosProv = false
    }
    if (!rangosProv) {
      notification.warn({
        message: 'Por favor validar',
        description: PROV_SIN_TASAS,
        duration: 4,
      });
    }
    return rangosProv;
  };

  render() {
    const {
      visible,
      loading,
      proveedorSelect,
      rangoComisionVisible,
      TasaDescuentoVisible,
      nmRangosCom,
      rangosCom,
      data,
      dataFondeador
    } = this.state;

    const {
      tipoTasaProveedor,
      categorias,
      tipoPagos,
      fondeadores,
    } = this.props;

    return (
      <>
        <Button
          size='small'
          className='btn-agregar'
          onClick={this.handleOnOpenModal}
        >
          + Agregar un nuevo proveedor
        </Button>
        <Modal
          title='Nuevo Proveedor'
          visible={visible}
          className='modal-found-providers-style'
          width={800}
          footer={null}
          onCancel={this.handleOnClose}
        >
          <Spin tip='Cargando...' spinning={loading}>
            <SupplierForm
              data={data}
              onFinish={this.handleOnFinish}
              handleOnSearchProveedor={this.handleOnSearchProveedor}
              proveedorSelect={proveedorSelect}
              tipoTasaProveedor={tipoTasaProveedor}
              onConfigRangoComision={this.handleOnConfigRangoComision}
              OnTasaDescuentoModal={this.handleOnTasaDescuentoModal}
              categorias={categorias}
              tipoPagos={tipoPagos}
              fondeadores={fondeadores}
            />
          </Spin>
        </Modal>
        <RangosComisionModal
          visible={rangoComisionVisible}
          nmRangosCom={nmRangosCom}
          data={rangosCom}
          onFinish={this.handleOnFinishRangoModal}
          onClose={this.handleOnRangoModalClose}
        />
        <TasaDescuentoModal
          visible={TasaDescuentoVisible}
          dataFondeador={dataFondeador}
          onFinish={this.handleOnFinishTasaDescuentoModal}
          onClose={this.handleTasaDescuentoModalClose}
        />
      </>
    );
  }
}

export default SupplierNewModal;
