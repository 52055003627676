import React, { Component } from 'react';
import './integraction.scss';
import { Button, Card, Col, Row } from 'antd';
import {excel} from '../../../assets/img/excel.svg'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { downloadTemplateAPI } from '../../../services/pagador/Integraction';


class Integraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      tableData: [],
      filename: '',
      confirmed: false,
      loading: false,
      fechaCarga: null,
    };
  }

  componentDidMount() {
    this.props.menuHandler('6');
  }

  downloadTemplate = async () => {
    const {company,authToken} = this.props
    
    const res = await downloadTemplateAPI(authToken,company.company).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.ms-excel;charset=ANSI',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Plantilla_${this.props.type}_masiva.xlt`
        );
        document.body.appendChild(link);
        link.click();
      });

  };

  render() {
    return (
        <div className='dashboard-style'>
        <Card className='card-shadow card-radius default-border welcome-message '>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <p className='dash-welcome'>
                <span>Bienvenidos a la API de Exponencial Confiming{this.props.name}! </span>
              </p>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify='center' align='middle'>
            <Col span={9}>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="144px" height="144px"><path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"/><path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"/><path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"/><path fill="#17472a" d="M14 24.005H29V33.055H14z"/><g><path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"/><path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"/><path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"/><path fill="#129652" d="M29 24.005H44V33.055H29z"/></g><path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"/><path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"/></svg>
            <Button className='btn-secondary' onClick={this.downloadTemplate}>
                Descargar Plantilla Excel
            </Button>
            </Col>
            <Col span={15}>
              <p>
              Nos complace presentar nuestra última innovación: una potente API que simplificará la forma en que te conectas con nosotros y gestionas tus finanzas.
                <br />
                <br />
                <ul>
                  <li>
                  Nuestra API incorpora múltiples soluciones innovadoras diseñadas para mejorar la comunicación con nuestros usuarios y elevar la eficiencia en sus negocios.
                  </li>
                  <li>
                  Nuestra API hace que todo sea más rápido y fácil que nunca.
                  </li>
                  <li>
                  Gracias a la agilidad de la nueva API, el proceso de carga de facturas se vuelve mucho más rápido y eficiente.
                  </li>
                </ul>
                ¡Esperamos que disfrutes esta nueva experiencia!
                <br />
              </p>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(Integraction));
