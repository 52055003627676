import { Button, Col, DatePicker, Form, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';


const SELECT_PLACE_HOLDER = 'Seleccione...';

const initialValue = {
  scProveedor: undefined,
  fechaDesde: undefined,
};
const ProgramForm = ({
  handleSubmit,
  handleOnSearchProveedor,
  disabledDate,
  proveedorSelect,
  data,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...data
    });
    forceUpdate({});
  }, [form, data]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
            {/* Select Date Dias Habiles */}
        <Col span={12}>
            <Form.Item name='fechaDesde'               
            rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un rango de fechas',
                },
              ]}>
              <DatePicker
                format={DATE_FORMAT}
                disabledDate={disabledDate}
                className='program-calendar'
                inputReadOnly
              />
            </Form.Item>
        </Col>    
            {/* Select Proveedor */}
        <Col span={8}>    
            <Form.Item name='scProveedor'
             rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un proveedor',
                },
              ]}>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchProveedor(value)}
                notFoundContent={
                  proveedorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {proveedorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
        </Col> 
        </Row>
        {/* Boton Buscar Programacion */}
        <Row justify='center' align='middle'>
            <Col span={6}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  BUSCAR
                </Button>
              </Form.Item>
            </Col>
          </Row>
      </Form>
    </div>
  );
};

export default ProgramForm;
