import React from 'react';
import { Form, Input, Row, Col, Button, DatePicker, Select, Spin } from 'antd';
import moment from 'moment';
import equal from 'fast-deep-equal';

import { requiredValueMessage } from '../../../../../utilities/admin_validation_messages';
import { length } from '../../../../../utilities/validate/textValidate';
import { DATE_FORMAT } from '../../../../../utilities/messages';
import { Genero } from '../../../../../utilities/constants';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  cargoLeg: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 100),
    },
  ],
  dirResidenciaLeg: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisResidenciaLeg: [{ required: true, message: requiredValueMessage() }],
  cdDepResidenciaLeg: [{ required: true, message: requiredValueMessage() }],
  cdMunResidenciaLeg: [{ required: true, message: requiredValueMessage() }],
  telResidenciaLeg: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  emailLeg: [
    { required: true, message: requiredValueMessage() },
    {
      pattern:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 200),
    },
  ],
};

class PersonLegal extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  handleOnFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish('4', formValue);
  };

  handleOnChangePais = (value) => {
    const { onChangePais } = this.props;
    this.formRef.current.setFieldsValue({
      cdDepResidenciaLeg: undefined,
      cdMunResidenciaLeg: undefined,
    });
    onChangePais(value);
  };

  handleOnChangeDepartamento = (value) => {
    const { onChangeDepartamento } = this.props;

    const cdPais = this.formRef.current.getFieldValue('cdPaisResidenciaLeg');

    this.formRef.current.setFieldsValue({
      cdMunResidenciaLeg: undefined,
    });

    onChangeDepartamento(value, cdPais);
  };

  componentDidMount() {
    const { person } = this.props;
    if (person) {
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        ...person,
        feExpedicionLeg: person.feExpedicionLeg
          ? moment(person.feExpedicionLeg, DATE_FORMAT)
          : '',
        feNacimientoLeg: person.feNacimientoLeg
          ? moment(person.feNacimientoLeg, DATE_FORMAT)
          : '',
      });
    }
  }

  componentDidUpdate(props, state) {
    if (!equal(props.person, this.props.person)) {
      const { person } = this.props;
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        ...person,
        feExpedicionLeg: moment(person.feExpedicionLeg, DATE_FORMAT),
        feNacimientoLeg: moment(person.feNacimientoLeg, DATE_FORMAT),
      });
    }
  }

  render() {
    const { paises, departamentos, municipios } = this.props;

    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Form
            ref={this.formRef}
            name='legal_data'
            layout='vertical'
            onFinish={this.handleOnFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='prNombreLeg'
                  label='Primer nombre'
                  rules={rules['prNombreLeg'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='segNombreLeg'
                  label='Segundo nombre'
                  rules={rules['segNombreLeg'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='prApellidoLeg'
                  label='Primer apellido'
                  rules={rules['prApellidoLeg'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='segApellidoLeg'
                  label='Segundo apellido'
                  rules={rules['segApellidoLeg'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
                name='cdGenero'
                label='Genero'
                rules={rules['cdGenero'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {Genero.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='dsTipoIdLeg'
                  label='Tipo de documento'
                  rules={rules['dsTipoIdLeg'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='idLeg'
                  label='Número de identificación'
                  rules={rules['idLeg'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='feExpedicionLeg'
                  label='Fecha de expedición'
                  rules={rules['feExpedicionLeg'] || ''}
                >
                  <DatePicker
                    className='radius'
                    allowClear={false}
                    inputReadOnly
                    open={false}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='lugarExpedicion'
                  label='Lugar de expedición'
                  rules={rules['lugarExpedicion'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='feNacimientoLeg'
                  label='Fecha de nacimiento'
                  rules={rules['feNacimientoLeg'] || ''}
                >
                  <DatePicker
                    className='radius'
                    allowClear={false}
                    inputReadOnly
                    open={false}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='dsPaisNacLeg'
                  label='País de nacimiento'
                  rules={rules['dsPaisNacLeg'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='dsMunNacLeg'
                  label='Ciudad de nacimiento'
                  rules={rules['dsMunNacLeg'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='cargoLeg'
                  label='Cargo u oficio'
                  rules={rules['cargoLeg'] || ''}
                >
                  <Input minLength={5} maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='dirResidenciaLeg'
                  label='Dirección de contacto'
                  rules={rules['dirResidenciaLeg'] || ''}
                >
                  <Input minLength={5} maxLength={300} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='cdPaisResidenciaLeg'
                  label='País'
                  rules={rules['cdPaisResidenciaLeg'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.handleOnChangePais}
                  >
                    {paises.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdDepResidenciaLeg'
                  label='Departamento'
                  rules={rules['cdDepResidenciaLeg'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      departamentos.fetching ? <Spin size='small' /> : null
                    }
                    onChange={this.handleOnChangeDepartamento}
                  >
                    {departamentos.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='cdMunResidenciaLeg'
                  label='Ciudad/Municipio'
                  rules={rules['cdMunResidenciaLeg'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      municipios.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {municipios.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='telResidenciaLeg'
                  label='Teléfono'
                  rules={rules['telResidenciaLeg'] || ''}
                >
                  <Input minLength={7} maxLength={20} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='emailLeg'
                  label='Correo electrónico'
                  rules={rules['emailLeg'] || ''}
                >
                  <Input minLength={5} maxLength={200} />
                </Form.Item>
              </Col>
            </Row>

            <Row align='middle' justify='center'>
              <Col span={6}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Siguiente
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

export default PersonLegal;
