import React, { Component } from 'react';
import {
  Upload,
  Table,
  Button,
  notification,
  Popover,
  Spin,
  Row,
  Col,
  Form,
  Select,
} from 'antd';
import {
  InboxOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import bulkloadServices from '../../../../services/admin/bulkloadServices';
import { listaPagadores } from '../../../../services/admin/payersService';
import { listarPagadores } from '../../../../services/admin/reports/quotasService';
import { form } from '../../archive/logs/archives/form';

const { Dragger } = Upload;
const { Option } = Select;

class Bills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      tableData: [],
      filename: '',
      confirmed: false,
      loading: false,
      pagador: '',
      payers: [],
    };
  }

  columnsFacturas = [
    {
      title: 'ESTADO',
      key: 'estado',
      align: 'center',
      render: (item) =>
        Array.isArray(item.errors) && item.errors.length > 0 ? (
          <Popover
            data-html='true'
            placement='topRight'
            content={
              Array.isArray(item.errors) && (
                <div>
                  {item.errors.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              )
            }
            title='Errores'
          >
            {this.state.confirmed ? (
              <CloseOutlined className='icon-size color-alert' />
            ) : (
              <InfoCircleOutlined className='icon-size color-error' />
            )}
          </Popover>
        ) : this.state.confirmed ? (
          <CheckOutlined className='icon-size color-success' />
        ) : null,
    },
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'proveedorId',
      key: 'proveedorId',
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      key: 'cdFactura',
    },

    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      render: (value) => <span>$ {this.formatNumber(value)}</span>,
      key: 'vrFactura',
    },
    {
      title: 'FECHA EMISIÓN',
      dataIndex: 'feEmision',
      key: 'feEmision',
    },
    {
      title: 'FECHA VENCIMIENTO',
      dataIndex: 'feVencimiento',
      key: 'feVencimiento',
    },
    {
      title: 'FECHA ESTIMADA PAGO',
      dataIndex: 'feEstimadaPago',
      key: 'feEstimadaPago',
    },
  ];

  columnsPagos = [
    {
      title: 'ESTADO',
      key: 'estado',
      align: 'center',
      render: (item) =>
        Array.isArray(item.errors) && item.errors.length > 0 ? (
          <Popover
            data-html='true'
            placement='topRight'
            content={
              Array.isArray(item.errors) && (
                <div>
                  {item.errors.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              )
            }
            title='Errores'
          >
            {this.state.confirmed ? (
              <CloseOutlined className='icon-size color-alert' />
            ) : (
              <InfoCircleOutlined className='icon-size color-error' />
            )}
          </Popover>
        ) : this.state.confirmed ? (
          <CheckOutlined className='icon-size color-success' />
        ) : null,
    },
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'proveedorId',
      key: 'proveedorId',
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      key: 'cdFactura',
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      render: (value) => <span>$ {this.formatNumber(value)}</span>,
      key: 'vrFactura',
    },
    {
      title: 'FECHA EMISIÓN',
      dataIndex: 'feEmision',
      key: 'feEmision',
    },
    {
      title: 'FECHA VENCIMIENTO',
      dataIndex: 'feVencimiento',
      key: 'feVencimiento',
    },
    {
      title: 'FECHA ESTIMADA PAGO',
      dataIndex: 'feEstimadaPago',
      key: 'feEstimadaPago',
    },

    {
      title: 'VALOR PAGO',
      dataIndex: 'vrPago',
      key: 'vrPago',
      render: (value) => <span>$ {this.formatNumber(value)}</span>,
    },
  ];

  componentDidMount() {
    // this.listarPagadores();
  }

  listarPagadores = () => {
    listaPagadores(this.props.authToken, {}).then((response) => {
      if (response.data && Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          payers: response.data,
        });
      }
    });
  };

  handleUpload = () => {
    const { fileList } = this.state;
    console.log(fileList);
    const formData = new FormData();
    let mustReturn = false;
    if (!this.state.pagador) {
      notification.error({
        message: 'El pagador es requerido',
        duration: 4,
      });
      return;
    }

    if (fileList.length <= 0) {
      notification.error({
        message: 'El archivo es requerido',
        duration: 4,
      });
      return;
    }
    fileList.forEach((file) => {
      if (file.name.slice(-3) === 'xls' || file.name.slice(-4) === 'xlsx') {
        formData.append('file', file);
      } else {
        mustReturn = true;
        notification.error({
          message: 'El archivo debe tener formato de excel (xls, xlsx)',
          duration: 4,
        });
      }
    });

    if (mustReturn) {
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
    });

    bulkloadServices
      .upload(
        this.props.authToken,
        this.state.pagador.key,
        this.props.type,
        formData
      )
      .then((response) => {
        this.setState({
          ...this.state,
          tableData: response.data,
          filename: response.metadata ? response.metadata.nombreArchivo : '',
          confirmed: false,
          loading: false,
        });
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  confirmUpload = () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    bulkloadServices
      .confirm(
        this.props.authToken,
        this.state.pagador.key,
        this.props.type,
        this.state.filename
      )
      .then((response) => {
        if (response.metadata) {
          this.setState({
            ...this.state,
            confirmed: true,
            loading: false,
            fileList: [],
          });
          notification.success({
            message: 'Confirmado',
            description:
              'Archivo cargado correctamente, por favor revisar los resultados',
          });
        }
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  downloadTemplate = () => {
    if (!this.state.pagador) {
      notification.error({
        message: 'El pagador es requerido',
        duration: 4,
      });
      return;
    }

    bulkloadServices
      .downloadTemplate(
        this.props.authToken,
        this.state.pagador.key,
        this.props.type
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.ms-excel;charset=ANSI',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Plantilla_${this.props.type}.xlt`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  handleChange = (status) => {
    if (this.state.fileList.length > 1) {
      this.state.fileList.shift();
      this.setState({
        ...this.state,
      });
    }
  };

  handleOnSearch = (value) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      listarPagadores(this.props.authToken, { nombre: value }).then(
        (response) => {
          if (Array.isArray(response.data)) {
            this.setState({
              ...this.state,
              payers: response.data.map((item) => {
                return {
                  scPersona: item.codigo,
                  nombre: item.nombre || item.descripcion,
                };
              }),
              fetching: false,
            });
          }
        }
      );
    } else {
      this.setState({
        ...this.state,
        payers: [],
      });
    }
  };

  render() {
    return (
      <div className='content-container'>
        <div className='file-upload'>
          <div className='header-container'>
            <p className='title'>Búsqueda</p>
          </div>
          <div className='label'>
            <p className='label-text file-upload-message'>PAGADOR</p>
          </div>
          <div className='select-wrapper' style={{ width: '200%' }}>
            <Select
              className='module-selector-select'
              placeholder='Seleccione'
              showSearch
              allowClear
              value={this.state.pagadorName}
              onChange={(e, value) =>
                this.setState({
                  ...this.state,
                  pagadorName: e,
                  pagador: value,
                })
              }
              onSearch={(value) => this.handleOnSearch(value)}
              notFoundContent={
                this.state.fetching ? <Spin size='small' /> : null
              }
            >
              {Array.isArray(this.state.payers) &&
                this.state.payers.map((payer) => (
                  <Option
                    key={payer.scPersona}
                    value={payer.razonSocial || payer.nombre}
                  >
                    {payer.razonSocial || payer.nombre}
                  </Option>
                ))}
            </Select>
          </div>
          <p className='file-upload-message'>
            Archivo para inscribir {this.props.type} de forma masiva
          </p>
          <Dragger
            customRequest={() => {}}
            multiple={false}
            onChange={this.handleChange}
            onRemove={(file) => {
              const index = this.state.fileList.indexOf(file);
              const newFileList = this.state.fileList.slice();
              newFileList.splice(index, 1);
              this.setState({
                ...this.state,
                fileList: newFileList,
              });
            }}
            beforeUpload={(file) => {
              this.setState({
                ...this.state,
                fileList: [...this.state.fileList, file],
              });
            }}
            fileList={this.state.fileList}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Suelta el archivo aquí o selecciona alguno
            </p>
          </Dragger>
          <div className='template-download'>
            <Button
              style={{ margin: 0 }}
              className='btn btn-blue'
              onClick={this.handleUpload}
            >
              Cargar archivo
            </Button>
            <a href='#' onClick={this.downloadTemplate}>
              Descargar plantilla
            </a>
            {/* <CalendarOutlined style={{ fontSize: "2rem", margin: "1rem" }} /> */}
          </div>
        </div>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          {Array.isArray(this.state.tableData) &&
            this.state.tableData.length >= 1 && (
              <div className='table-wrapper'>
                <Table
                  columns={
                    this.props.type == 'facturas'
                      ? this.columnsFacturas
                      : this.columnsPagos
                  }
                  dataSource={this.state.tableData}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                  }}
                />
              </div>
            )}
          {Array.isArray(this.state.tableData) &&
          this.state.tableData.length > 0 ? (
            <Button className='btn btn-green' onClick={this.confirmUpload}>
              Confirmar archivo
            </Button>
          ) : (
            ''
          )}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};
export default connect(mapStateToProps)(Bills);
