import React from 'react';
import { Spin, Form, Col, Row, Select, Button } from 'antd';
import { BUSQUEDA_TITLE } from '../../../../../utilities/messages';
import { currencyFormat, dateFormat } from '../../../../../utilities/textTools';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const initialValue = {
  scGrupo: '',
  scEntidad: '',
  tipoEntidad: '',
};

const QuotasAgreementsFilterForm = ({
  entidadSelect,
  handleOnSearchEntidad,
  handleOnChangeEntidad,
  handleOnChangeTipoEntidad,
  handleOnSearchCupos,
  nmCupoContraparte,
  liquidez,
  feHasta,
  tipoCupo,
  tipoProducto
}) => {
  const [form] = Form.useForm();

  const entidades = [
    { key: 'PAG', value: 'Pagador' },
    { key: 'PRO', value: 'Proveedor' },
    { key: 'GRP', value: 'Grupo empresarial' },
  ];

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        initialValues={initialValue}
        onFinish={handleOnSearchCupos}
      >
        <Row>
          <Col>
            <p className='second-title'>LIQUIDEZ: {currencyFormat(liquidez)}</p>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={5}>
            <Form.Item 
              name='tipoEntidad' 
              label='Tipo Entidad'
              rules={[{ required: true, message: 'seleccionar una entidad' }]}>
              <Select onChange={handleOnChangeTipoEntidad}>
                {entidades.map((d) => (
                  <Select.Option key={d.key} value={d.key}>
                    {d.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              name='scEntidad'
              label='Entidad'
              rules={[{ required: true, message: 'seleccionar una entidad' }]}
            >
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchEntidad(value)}
                onChange={handleOnChangeEntidad}
                notFoundContent={
                  entidadSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {entidadSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                name='tipoProducto' 
                label='Tipo de Producto'
                rules={[
                      {
                        required: true,
                        message: 'debe seleccionar el tipo de producto',
                      }
              ]}>
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
            >
              {tipoProducto.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item 
                name='tipoCupo' 
                label='Tipo de Cupo'
                rules={[
                      {
                        required: true,
                        message: 'debe seleccionar el tipo de cupo',
                      }
                    ]}>
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
            >
              {tipoCupo.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              </Row>
        <Row>          
          <Col span={3}>
            <Form.Item name='cupos' label=' '>
              <Button
                type='primary'
                htmlType='submit'
                // onClick={(value) => handleOnSearchCupos(value)}
              >
                Buscar Cupos
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className='second-title'>
              CUPO CONTRAPARTE ASIGNADO ACTUAL:{' '}
              {currencyFormat(nmCupoContraparte)}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className='second-title'>
              FECHA DE VIGENCIA: {dateFormat(feHasta)}
            </p>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default QuotasAgreementsFilterForm;
