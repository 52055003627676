import {
  FilePdfTwoTone,
  FileTextOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Divider, Modal, Row, Space, Spin, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../components/tableReport/tableReport';
import {setExcel } from '../../../utilities/downloadTools';
import { currencyFormat, sorterDate, sorterText } from '../../../utilities/textTools';
import { listarCompanias } from '../../../services/facturacion/ParametrosService';
import {anularFactura, detalleFacturaComision, enviarACadena, exportaFacturasGeneradas, listarFacturasGeneradas } from '../../../services/facturacion/facturacionService';
import CheckBillingFiltreForm from './checkBillingFiltreForm/checkBillingFiltreForm';
import ModalDetailsCheckBilling from './modalDetailsCheckBilling/modalDetailsCheckBilling';
import SendToCadenaFiltreForm from './checkBillingFiltreForm/sendToCadenaFiltreForm';
import ModalCancelInvoice from './modalCancelInvoice/modalCancelInvoice';

const initialState = {
  form: {
    feDesde: '',
    feHasta: '',
    scCliente: undefined,
    nmFactura:undefined
  },
  loading: false,
  resultado: [],
  dataModal:[],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  ModalDataTotal:0,
  companiaSelect: {
    fetching: false,
    options: [],
  },
  visible:false,
  visibleConfirm:false,
  dataCancelInvoice:[],
  selectedKeys:[],
  unselectedKeys: [],
  selectedData:[]
};

class CheckBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };
    this.columnas = [
      {
        title: 'CLIENTE',
        dataIndex: 'nombreCliente',
        sorter: (a, b) => sorterText(a.nombreCliente, b.nombreCliente),
        showSorterTooltip: false,
      },
      {
        title: 'CONTACTO',
        dataIndex: 'nombreContacto',
        sorter: (a, b) => sorterText(a.nombreContacto, b.nombreContacto),
        showSorterTooltip: false,
      },
      {
        title: 'TELEFONO',
        dataIndex: 'telefonoContacto',
        sorter: (a, b) => sorterText(a.telefonoContacto, b.telefonoContacto),
        showSorterTooltip: false,
      },
      {
        title: 'CÓDIGO FACTURA',
        dataIndex: 'nmFactura',
        sorter: (a, b) => sorterText(a.nmFactura, b.nmFactura),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR FACTURA',
        dataIndex: 'vrFactura',
        sorter: (a, b) => sorterText(a.vrFactura, b.vrFactura),
        render: (vrFactura) => <span>{currencyFormat(vrFactura)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'estadoFacturaExp',
        sorter: (a, b) => sorterText(a.estadoFacturaExp, b.estadoFacturaExp),
        showSorterTooltip: false,
      },
      {
        title: 'FACTURA ELECTRONICA',
        dataIndex: 'snElectronica',
        sorter: (a, b) => sorterText(a.snElectronica, b.snElectronica),
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            onClick={() => this.showModalDetalle(record)}
          >
            DETALLE
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ANULAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='secondary'
            onClick={() => this.showModalCancelInvoice(record)}
          >
            ANULAR FACTURA
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.formSearch = React.createRef();
  }

  componentDidMount() {
    this.props.menuHandler('3');
  }

  handleOnSearchCompania = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { companiaSelect } = this.state;

      this.setState({
        ...this.state,
        companiaSelect: {
          ...companiaSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarCompanias(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      loading: false,
      form: {
        scCliente: value.scCliente !== undefined 
        ? value.scCliente : undefined,
        feDesde: value.fechaRango[0],
        feHasta: value.fechaRango[1],
        nmFactura: value.nmFactura !== undefined 
        ? value.nmFactura : undefined ,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });
    this.getData();
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  getData = async () => {
    this.setState({
      ...this.state,
      resultado:[],
      resumen: initialState.resumen,
      loading: true,
    });
    try {
      const response = await listarFacturasGeneradas(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        loading: false,
        resultado: response.data,
        total: response.metadata.count,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await exportaFacturasGeneradas(
        this.props.authToken,
        this.getFiltro()
      );

      setExcel(response, 'Listado_Facturas_Generadas');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  showModalDetalle = async (record) => {

    let nmFactura = record.nmFactura;

    if (typeof record !== 'undefined' && record != null) {
      this.setState({
        ...this.state,
        resumen: initialState.resumen,
        loading:true,
      });
      try {
        const response = await detalleFacturaComision(
          this.props.authToken,
          nmFactura
        );
        if(response.data.length > 0){
          this.setState({
            ...this.state,
            visible: true,
            dataModal:response.data,
            ModalDataTotal: response.metadata.count,
          });
        }else{    
          notification.info({
            message: 'Sin Datos',
            description: "No se encontraron datos para mostrar",
            duration: 4,
          });
        }
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
        notification.error({
          message: 'Error',
          description: "Error al visualizar detalles",
          duration: 4,
        });
      } finally{
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  hiddenModalDetalle = () => {
    this.setState({ ...this.state, visible: false, dataModal:[]});
  };

  sendToCadena = async () => {
    const {selectedData} = this.state;

    if(selectedData.length > 0){
    try {
      const response = await enviarACadena(
        this.props.authToken,
        selectedData,
      );
      notification.success({
        message: 'Enviado a cadena con éxito',
        description: response.message,
        duration: 4,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
      notification.error({
        message: 'Error',
        description: "Error al enviar a cadena",
        duration: 4,
      });
    } finally{
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  }else{
    notification.warn({
      message: 'Por favor selecciona un registro',
      description: 'Selecciona el registro que desea enviar a cadena',
      duration: 4,
    });
  }
  }

  showModalCancelInvoice = (record) =>{
    if (typeof record !== 'undefined' && record != null) {
      this.setState({
        ...this.state,
        visibleConfirm: true,
        dataCancelInvoice:record
      });
    }
  }

  hiddenModalCancelInvoice = () => {
    this.setState({ ...this.state, visibleConfirm: false, dataCancelInvoice:[]});
  };

  cancelInvoice = async () => {
    const {dataCancelInvoice} = this.state
    let bill = dataCancelInvoice.nmFactura;
    this.hiddenModalCancelInvoice();
    try {
      const response = await anularFactura(
        this.props.authToken,
        bill,
      );
      notification.success({
        message: 'Anulación exitosa',
        description: response.message,
        duration: 4,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
      notification.error({
        message: 'Error',
        description: "Error al enviar a cadena",
        duration: 4,
      });
    } finally{
      this.getData()
    }
  }

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      const updatedSelectedKeys = [
        ...this.state.selectedKeys,
        ...changeRows.map((row) => row.key),
      ];
  
      this.setState({
        selectedKeys: updatedSelectedKeys,
        unselectedKeys: [],
        selectAll: selected,
        selectedData: [...this.state.selectedData, ...changeRows],
      });
    } else {
      this.setState({
        selectedKeys: [],
        unselectedKeys: [],
        selectAll: selected,
        selectedData: [],
      });
    }
  };

  onSelect = (record, selected) => {
    const { selectAll, selectedKeys, unselectedKeys, selectedData } = this.state;
  
    if (selected) {
      const updatedSelectedKeys = [...selectedKeys, record.key];
      const updatedSelectedData = [...selectedData, record];
  
      this.setState({
        selectedKeys: updatedSelectedKeys,
        selectedData: updatedSelectedData,
        unselectedKeys: unselectedKeys.filter((key) => key !== record.key),
        selectAll: selectAll && updatedSelectedKeys.length === 0,
      });
    } else {
      const updatedUnselectedKeys = [...unselectedKeys, record.key];
      const updatedSelectedData = selectedData.filter((data) => data.key !== record.key);
  
      this.setState({
        selectedKeys: selectedKeys.filter((key) => key !== record.key),
        unselectedKeys: updatedUnselectedKeys,
        selectedData: updatedSelectedData,
        selectAll: false,
      });
    }
  };


  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      companiaSelect,
      selectedKeys,
      visible,
      visibleConfirm,
      dataModal
    } = this.state;

    this.rowSelection = {
      type: 'checkbox',
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      selectedRowKeys: selectedKeys,
    };

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='mandate-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <CheckBillingFiltreForm
              handleSubmit={this.handleSubmit}
              companiaSelect={companiaSelect}
              handleOnSearchCompania={this.handleOnSearchCompania}
            />

            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de facturas
                  </Space>
                </Col>
                {resultado.length > 0 ? (
                  <Col flex='none'>
                    <Space align='baseline'>
                      <Button className='form-btn' type='primary' onClick={this.exportExcel}>
                        Exportar a excel
                      </Button>
                    </Space>
                  </Col>
                ) : null}
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    keyIndex={'nombreCliente'}
                    rowSelection={this.rowSelection}
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>

              {resultado.length > 0 ? (
                <SendToCadenaFiltreForm
                sendToCadena={this.sendToCadena}
                />
                ) : null}
            </div>
          <ModalDetailsCheckBilling 
          visible={visible} 
          hiddenModalDetalle={this.hiddenModalDetalle}
          dataModal={dataModal}
          currentPage={currentPage}
          limit={limit}
          total={total}
          resumen={resumen}
          handleChange={this.handleChange}
          />
          <ModalCancelInvoice
          visibleConfirm={visibleConfirm} 
          hiddenModalCancelInvoice={this.hiddenModalCancelInvoice}
          cancelInvoice={this.cancelInvoice}
          />
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(CheckBilling);
