import axios from 'axios'
import { middleWare } from '../middleware'
import queryString from 'query-string'

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN

/* #region Listar Opciones */

export const listaGruposEmpresariales = (authtoken, filtros) => {
  return middleWare(axios({
    url: `${baseUrl}/api/grupos-empresariales/listar-grupos?${queryString.stringify(filtros)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listaClusters = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  return middleWare(axios({
    url: `${baseUrl}/api/neuroscore/listar-clusters?${queryString.stringify(filters)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const grabarCluster = (authtoken, data) => {
  let datos = formatoFechas(data)
  return middleWare(axios({
    url: `${baseUrl}/api/neuroscore/clusters/grabar/${datos.clusNeuroRiesgo}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: datos
  }))
}

const formatoFechas = (filtros) => {
  let empty = {}
  Object.keys(filtros).forEach(nombre => {
    if (nombre !== 'resultado') {
      if ((nombre.includes("Desde") || nombre.includes("Hasta")) && (typeof filtros[nombre] === 'object')) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD')
      } else if (filtros[nombre] && nombre != "currentPage" && nombre != "total") {
        empty[nombre] = filtros[nombre]
      }
    }
  });
  return empty
}