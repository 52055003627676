import { Button, Col, DatePicker, notification, Row, Select, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { listaGruposEmpresariales } from '../../../services/admin/businessGroupsService';
import { listaPagadores } from '../../../services/admin/payersService';
import {
  listarPagadores,
  listarProveedores,
} from '../../../services/admin/reports/quotasService';
import { listaProveedores } from '../../../services/admin/suppliersService';
import { ejecutarTareaHubspot } from '../../../services/admin/taskService';
import { DATE_FORMAT } from '../../../utilities/messages';
import './hubspotTask.scss';

const { Option } = Select;

class BulkLoadTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagador: '',
      proveedor: '',
      grupo: '',
      feDesde: '',
      feHasta: '',
      pagadores: [],
      proveedores: [],
      grupos: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.props.menuHandler('96');
  }

  listarPagadores = () => {
    listaPagadores(this.props.authToken, {}).then((response) => {
      if (response.data && Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          pagadores: response.data,
        });
      }
    });
  };
  listarProveedores = () => {
    listaProveedores(this.props.authToken, {}).then((response) => {
      if (response.data && Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          proveedores: response.data,
        });
      }
    });
  };
  listarGruposEmpresariales = () => {
    listaGruposEmpresariales(this.props.authToken, {}).then((response) => {
      if (response.data && Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          grupos: response.data,
        });
      }
    });
  };

  ejecutarTarea = (tarea) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    let data = {
      feDesde: this.state.feDesde,
      feHasta: this.state.feHasta,
      scGrupo: this.state.grupo.key,
      scPagador: this.state.pagador.key,
      scProveedor: this.state.proveedor.key,
      tarea: tarea,
    };

    ejecutarTareaHubspot(this.props.authToken, data)
      .then((res) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        notification.success({
          message: 'Confirmado',
          description: 'La tarea se ejecutó correctamente',
        });
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      switch (nombre) {
        case 'proveedor':
          listarProveedores(this.props.authToken, { nombre: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  proveedores: response.data.map((item) => {
                    return {
                      scPersona: item.codigo,
                      nombre: item.nombre || item.descripcion,
                    };
                  }),
                  fetching: false,
                });
              }
            }
          );
          break;
        case 'pagador':
          listarPagadores(this.props.authToken, { nombre: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  pagadores: response.data.map((item) => {
                    return {
                      scPersona: item.codigo,
                      nombre: item.nombre || item.descripcion,
                    };
                  }),
                  fetching: false,
                });
              }
            }
          );
          break;
        case 'grupo':
          listaGruposEmpresariales(this.props.authToken, {
            nombre: value,
          }).then((response) => {
            if (Array.isArray(response.data)) {
              this.setState({
                ...this.state,
                grupos: response.data.map((item) => {
                  return {
                    scGrupo: item.scGrupo,
                    nombre: item.nombre || item.dsNombres,
                  };
                }),
                fetching: false,
              });
            }
          });
          break;
      }
    } else {
      switch (nombre) {
        case 'proveedor':
          this.setState({
            ...this.state,
            proveedores: [],
          });
          break;
        case 'pagador':
          this.setState({
            ...this.state,
            pagadores: [],
          });
          break;
        case 'grupo':
          this.setState({
            ...this.state,
            grupos: [],
          });
          break;
      }
    }
  };

  render() {
    return (
      <div className='content-margin task-style'>
        <div className='card-background-light card-shadow radius'>
          <div className='align-center'>
            <Spin tip='Cargando...' spinning={this.state.loading}>
              <div className='content-container'>
                <div className='header-container'>
                  <p className='title'>Tareas masivas desde Hubspot</p>
                </div>
                <div className='detail-container'>
                  <Row gutter={16}>
                    <Col span={12}>
                      <div className='form-label'>
                        <p className='form-label-text'>FECHA INICIAL</p>
                      </div>
                      <div className='form-input'>
                        <DatePicker
                          className='form-datepicker radius module-selector-select'
                          placeholder='Seleccione la fecha'
                          value={this.state.feDesde}
                          onChange={(e) =>
                            this.setState({ ...this.state, feDesde: e })
                          }
                          format={DATE_FORMAT}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className='form-label'>
                        <p className='form-label-text'>FECHA FINAL</p>
                      </div>
                      <div className='form-input'>
                        <DatePicker
                          className='form-datepicker radius module-selector-select'
                          placeholder='Seleccione la fecha'
                          value={this.state.feHasta}
                          onChange={(e) =>
                            this.setState({ ...this.state, feHasta: e })
                          }
                          format={DATE_FORMAT}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <div className='form-label'>
                        <p className='form-label-text'>PAGADOR</p>
                      </div>
                      <div className='form-input'>
                        <Select
                          className='module-selector-select'
                          placeholder='Seleccione'
                          showSearch
                          allowClear
                          value={this.state.pagadorName}
                          onChange={(e, value) =>
                            this.setState({
                              ...this.state,
                              pagadorName: e,
                              pagador: value,
                            })
                          }
                          onSearch={(value) =>
                            this.handleOnSearch(value, 'pagador')
                          }
                          notFoundContent={
                            this.state.fetching ? <Spin size='small' /> : null
                          }
                        >
                          {Array.isArray(this.state.pagadores) &&
                            this.state.pagadores.map((pagador) => (
                              <Option
                                key={pagador.scPersona}
                                value={pagador.razonSocial || pagador.nombre}
                              >
                                {pagador.razonSocial || pagador.nombre}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className='form-label'>
                        <p className='form-label-text'>PROVEEDOR</p>
                      </div>
                      <div className='form-input'>
                        <Select
                          className='module-selector-select'
                          placeholder='Seleccione'
                          showSearch
                          allowClear
                          value={this.state.proveedorName}
                          onChange={(e, value) =>
                            this.setState({
                              ...this.state,
                              proveedorName: e,
                              proveedor: value,
                            })
                          }
                          onSearch={(value) =>
                            this.handleOnSearch(value, 'proveedor')
                          }
                          notFoundContent={
                            this.state.fetching ? <Spin size='small' /> : null
                          }
                        >
                          {Array.isArray(this.state.proveedores) &&
                            this.state.proveedores.map((proveedor) => (
                              <Option
                                key={proveedor.scPersona}
                                value={
                                  proveedor.nombre || proveedor.razonSocial
                                }
                              >
                                {proveedor.nombre || proveedor.razonSocial}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <div className='form-label'>
                        <p className='form-label-text'>GRUPO EMPRESARIAL</p>
                      </div>
                      <div className='form-input'>
                        <Select
                          className='module-selector-select'
                          placeholder='Seleccione'
                          showSearch
                          value={this.state.grupoName}
                          onChange={(e, value) =>
                            this.setState({
                              ...this.state,
                              grupoName: e,
                              grupo: value,
                            })
                          }
                          onSearch={(value) =>
                            this.handleOnSearch(value, 'grupo')
                          }
                          notFoundContent={
                            this.state.fetching ? <Spin size='small' /> : null
                          }
                        >
                          {Array.isArray(this.state.grupos) &&
                            this.state.grupos.map((grupo) => (
                              <Option
                                key={grupo.scGrupo}
                                value={grupo.dsNombres || grupo.nombre}
                              >
                                {grupo.dsNombres || grupo.nombre}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </Col>
                  </Row>
                  <div className='task-list'>
                    <div className='task'>
                      <div className='task-label'>
                        <p className='task-label-text'>
                          ¿Desea que se ejecute la tarea, traer proveedores
                          desde Hubspot?
                        </p>
                      </div>
                      <div className='task-button'>
                        <Button
                          className='task-button-component'
                          onClick={() => {
                            this.ejecutarTarea('crearProveedoresDesdeHubspot');
                          }}
                        >
                          Botón mágico
                        </Button>
                      </div>
                    </div>
                    <div className='task'>
                      <div className='task-label'>
                        <p className='task-label-text'>
                          ¿Desea que se ejecute la tarea, carga inicial del mes
                          de hubspot?
                        </p>
                      </div>
                      <div className='task-button'>
                        <Button
                          className='task-button-component'
                          onClick={() => {
                            this.ejecutarTarea('cargaInicialDelMes');
                          }}
                        >
                          Carga inicial del mes
                        </Button>
                      </div>
                    </div>
                    <div className='task'>
                      <div className='task-label'>
                        <p className='task-label-text'>
                          ¿Desea que se ejecute la tarea, carga inicial del dia
                          de hubspot?
                        </p>
                      </div>
                      <div className='task-button'>
                        <Button
                          className='task-button-component'
                          onClick={() => {
                            this.ejecutarTarea('cargaInicialDelDia');
                          }}
                        >
                          Carga inicial del dia
                        </Button>
                      </div>
                    </div>
                    <div className='task'>
                      <div className='task-label'>
                        <p className='task-label-text'>
                          ¿Desea que se ejecute la tarea, completar tareas del
                          mes hubspot?
                        </p>
                      </div>
                      <div className='task-button'>
                        <Button
                          className='task-button-component'
                          onClick={() => {
                            this.ejecutarTarea('completarTareasDelMes');
                          }}
                        >
                          Completar tareas del mes
                        </Button>
                      </div>
                    </div>
                    <div className='task'>
                      <div className='task-label'>
                        <p className='task-label-text'>
                          ¿Desea que se ejecute la tarea, completar tareas
                          semanal hubspot?
                        </p>
                      </div>
                      <div className='task-button'>
                        <Button
                          className='task-button-component'
                          onClick={() => {
                            this.ejecutarTarea('completarTareasDeLaSemana');
                          }}
                        >
                          Completar tareas semanal
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};
export default connect(mapStateToProps)(BulkLoadTask);
