import {
  COMPANY_SELECTED,
  SELECTED_BILLS,
  NEXT_STEP,
  SET_TRACKING,
  SET_NEEDED_AMOUNT,
  CHARGEALL_SELECTED_BILLS,
  CHARGEALL_NEXT_STEP,
  PROGRAM_NEXT_STEP,
  PROGRAM_SELECTED_BILLS,
  UPDATE_PROGRAM_DATE,
  SET_PERSON_TYPE,
  BILLING_RESET_STEPS,
  REALOAD_NOTIFICATIONS
} from '../../actions/types'

const initialState = {
  company: '',
  selectedBills: [],
  programSelectedBills: [],
  chargeallSelectedBills: [],
  neededAmount: 0,
  chargeallTotalSelectedValue: 0,
  totalSelectedValue: 0,
  programTotalSelectedValue: 0,
  currentStep: 0,
  chargeallcurrentStep: 0,
  programCurrentStep: 0,
  selectedKeys: [],
  programSelectedKeys: [],
  programDate: '',
  reloadNotifications: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPANY_SELECTED:
      return {
        ...initialState,
        company: action.payload,
        personType: state.personType
      }
    case REALOAD_NOTIFICATIONS:
      return {
        ...state,
        reloadNotifications: action.payload,
      }
    case SET_PERSON_TYPE:
      return {
        ...state,
        personType: action.payload
      }
    case SELECTED_BILLS:
      return {
        ...state,
        ...action.payload
      }
    case CHARGEALL_SELECTED_BILLS:
      return {
        ...state,
        ...action.payload
      }
    case NEXT_STEP:
      return {
        ...state,
        currentStep: action.payload
      }
    case CHARGEALL_NEXT_STEP:
      return {
        ...state,
        chargeallcurrentStep: action.payload
      }
    case UPDATE_PROGRAM_DATE:
      return {
        ...state,
        ...action.payload
      }
    case PROGRAM_NEXT_STEP:
      return {
        ...state,
        programCurrentStep: action.payload
      }
    case PROGRAM_SELECTED_BILLS:
      return {
        ...state,
        ...action.payload
      }
    case SET_TRACKING:
      return {
        ...state,
        trackingId: action.payload
      }
    case SET_NEEDED_AMOUNT:
      return {
        ...state,
        neededAmount: action.payload
      }
    case BILLING_RESET_STEPS:
      return {
        ...initialState,
        company: state.company,
        personType: state.personType,
        neededAmount: state.neededAmount
      }
    default:
      return {
        ...state
      }
  }
}