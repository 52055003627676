import axios from 'axios';
import { middleWare } from '../../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarFondeadores = (authtoken, filters) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-fondeadores?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarConvenios = (authtoken, filters) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-convenios?${queryString.stringify(filters)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const operacionProveedores = (authtoken, scFondeador, filtros) => {
  let filters = formatoFechas(filtros);
  if (filters.resultado) {
    delete filters.resultado;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/fondeadores/${scFondeador}/proveedores-totales?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarPagadores = (authtoken, filters) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-pagadores?${queryString.stringify(filters)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarProveedores = (authtoken, filters) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-proveedores?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const operacionConvenios = (authtoken, scFondeador, filtros) => {
  let filters = formatoFechas(filtros);
  if (filters.resultado) {
    delete filters.resultado;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/fondeadores/${scFondeador}/proveedores-convenios?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const operacionProveedoresExcel = (authtoken, scFondeador, filtros) => {
  let filters = formatoFechas(filtros);
  if (filters.resultado) {
    delete filters.resultado;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/fondeadores/${scFondeador}/proveedores-totales/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const operacionConveniosExcel = (authtoken, scFondeador, filtros) => {
  let filters = formatoFechas(filtros);
  if (filters.resultado) {
    delete filters.resultado;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/fondeadores/${scFondeador}/proveedores-convenios/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        if (filtros[nombre]) {
          empty[nombre] = filtros[nombre];
        }
      }
    }
  });
  return empty;
};
