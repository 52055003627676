import React from 'react'
import './chargeall.scss'
import '../../../styles/standard.scss'
import { Row, Col, Card, Steps } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import Assisted from './assisted/assisted';
import Summary from '../billing/summary/summary';
import Finish from '../billing/finish/finish';
import store from "../../../store/index";
import { setNeededAmount } from '../../../store/actions/proveedor/billingActions'
import { connect } from 'react-redux'

const { Step } = Steps;

class Chargeall extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
    }
  }

  componentDidMount = () => {
    this.props.menuHandler('3')
    this.updateStep()
  }

  componentWillUnmount() {
    const { chargeallcurrentStep } = store.getState().billing;
    if (chargeallcurrentStep <= 0) {
      this.props.setNeededAmount(0);
    }
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  updateStep = () => {
    const { chargeallcurrentStep } = store.getState().billing;
    this.setState({ currentStep: chargeallcurrentStep });
  }

  render() {
    const step = this.state.currentStep;
    var { chargeallTotalSelectedValue, chargeallSelectedBills } = store.getState().billing
    var facturasProcesadas = []
    if (chargeallSelectedBills !== []) {
      chargeallSelectedBills = Object.values(chargeallSelectedBills);
      chargeallSelectedBills.forEach(element => {
        element.forEach(datum => {
          facturasProcesadas.push(datum);
        })
      });
    }
    var investors = []
    facturasProcesadas.map((bill, key) => {
      investors[key] = bill.scNombreFondeador
    })
    let unique = [...new Set(investors)];
    return (<div className="assisted-style chargeall-style">
      <Card className="card-shadow radius-3 default-border">
        <Row gutter={16}>
          <Col span={14}>
            <div className="standard-steps steps-container">
              <Steps progressDot current={this.state.currentStep} onChange={this.changeStep}>
                <Step title="1" />
                {/* <Step title="1 Asistido" /> */}
                <Step title="2" />
                <Step title="3" />
              </Steps>
            </div>
          </Col>
          <Col span={6} className="shop-info-container">
            <div className="shop-card radius-3">
              <p className="icon">
                <ShoppingCartOutlined className="primary-green-font" />
              </p>
              <p className="money-text primary-green-font">
                $ {this.formatNumber(chargeallTotalSelectedValue)}
              </p>
            </div>
          </Col>
          <Col span={4} className="summary primary-green-font">
            <div><strong>Facturas:</strong> {facturasProcesadas.length}</div>
            <div><strong>Fondeadores:</strong></div>
            <div>{unique.map((investor) => (
              investor + "\n"
            ))}</div>
          </Col>
        </Row>
      </Card>
      {step === 0 ? <Assisted stepHandler={this.updateStep} /> : ''}
      {/* {step === 1 ? <Assisted /> : ''} */}
      {step === 1 ? <Summary module={'assisted'} stepHandler={this.updateStep} /> : ''}
      {step === 2 ? <Finish module={'assisted'} stepHandler={this.updateStep} /> : ''}
    </div>)
  }
}
const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, { setNeededAmount })(Chargeall);