import React from 'react';
import 'antd/dist/antd.css';
import './App.scss';
import MainLayout from './layouts/mainlayout';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import LoginLayout from './layouts/loginlayout';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from './services/history';

import ProtectedRoute from './utilities/protectedRoute';
import LoginRoute from './utilities/loginRoute';
import ErrorBoundary from './components/ErrorBoundary';
import { FormTipoPersona } from './pages/vinculacion/FormTipoPersona/FormTipoPersona';
import userService from './services/userService';
import moment from 'moment';
import jwt from 'jsonwebtoken';

class App extends React.Component {
  
  componentDidMount(): void {

    let token = JSON.parse(localStorage.getItem('auth-token'));
    if (token) {
      const auth = jwt.decode(token.access_token);
      const stateExpiration = auth.exp * 1000;
      let current = moment();
      let expiration = moment(stateExpiration);
      let diff = expiration.diff(current, 'millisecond');
      if (diff <= 0) {
          userService.revokeToken(auth);
        }
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <ConfigProvider locale={esES}>
          <Router history={history} basename={'/affirmatum2'}>
            <Switch>
              <LoginRoute
                path={`${process.env.PUBLIC_URL}/login`}
                component={LoginLayout}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/previnculacion`}
                component={FormTipoPersona}
              />
              <ProtectedRoute
                path={`${process.env.PUBLIC_URL}/`}
                component={MainLayout}
              />
              <Route path={`${process.env.PUBLIC_URL}/*`}>
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              </Route>
            </Switch>
          </Router>
        </ConfigProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
