export const initialState = {
  tipoModeloOptions: [
    {
      key: 'ORIGINACION',
      nombre: 'ORIGINACION'
    },
    {
      key: 'SEGMENTACION',
      nombre: 'SEGMENTACION'
    },

  ],
  grupoEmpresarialOptions: [],
  fondeadorOptions: [],
  loading: false,
  archive: {
    cdTipoModelo: '',
    fechaDesde: '',
    fechaHasta: '',
    scGrupo: '',
    currentPage: 1,
    total: 0,
    resultado: [],
  },

  opcionesDocumentos: [],
  fetching: false,
  sumatorias: {}
}