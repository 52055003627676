import { Col, Form, Row, Button, Select, Input } from 'antd'
import Title from 'antd/lib/typography/Title';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useState, useEffect} from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { requiredValueMessage } from '../../../../../utilities/admin_validation_messages';

const rules = {
    estadoVinculacion: [
      { required: true, message: requiredValueMessage() },
    ]
}

const initialValue = {
  descripcionDI:'',
  estadoVinculacion:'',
};

export const ModalCambiarEstado = ({handleOnFinishCambiarEstado,tipoProducto}) => {
  const [estadoVinculacion, setEstadoVinculacion] = useState('');
  const [descripcionDI, setDescripcionDI] = useState('');
  return (
    <div>
    <Form
        name='content_form'
        layout='vertical'
        onFinish={handleOnFinishCambiarEstado}
    >
        <Row gutter={[16, 32]}>
            <Col span={12}>
                <Form.Item 
                    name='estadoVinculacion'
                    label="Seleccione el estado"
                    rules={rules['estadoVinculacion'] || ''}

                >
                    <Select
                        placeholder="Seleccione"
                        allowClear
                        defaultActiveFirstOption={false}
                        onChange={(value) => setEstadoVinculacion(value)}
                    >
                        
                        <Select.Option value={"CE"}>Registro completo y enviado</Select.Option>
                        {tipoProducto !== "F" ? 
                          <Select.Option value={"DI"}>Documentación incompleta</Select.Option> : null
                         }
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        {estadoVinculacion === 'DI' && (
              <Row gutter={16}>
                <Col flex='auto'>
                  <Form.Item
                    name='descripcionDI'
                    label='Descripción: '
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
            )}
        <Row gutter={16} justify={'center'}>
            <Col span={6}>
                <Button
                    htmlType='submit'
                    className='form-btn btn-radius'
                    type='primary'
                >
                    Guardar
                </Button>
            </Col>
        </Row>
    </Form>
    </div>
  )
}
