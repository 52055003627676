import {
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Spin,
    Switch,
  } from 'antd';
  import React from 'react';
  import { length } from '../../../utilities/validate/textValidate';
  import * as numberValidate from '../../../utilities/validate/numberValidate';
import { digitsMessagge, digitsPattern, requiredValueMessage } from '../../../utilities/admin_validation_messages';
import Title from 'antd/lib/typography/Title';
import TextArea from 'antd/lib/input/TextArea';
  
  const SELECT_PLACE_HOLDER = 'Seleccionar...';
  
  const rules = {
    docConstDeLaSociedad: [{ required: true, message: requiredValueMessage() }],
    camaraDeComercio: [{ required: true, message: requiredValueMessage() }],
    cdActividad: [{ required: true, message: requiredValueMessage() }]
  };
  
  const situacionTributaria = [
    { key: 'RE', description: 'Regimen especial' },
    { key: 'NC', description: 'No contribuyente' },
    { key: 'C', description: 'Contribuyente' },
  ];
  
  const entidadVigila = [
    { key: 'SF', description: 'Superintendencia financiera' },
    { key: 'SIC', description: 'Superintendencia de industria y comercio' },
    { key: 'SS', description: 'Superintendencia de sociedades' },
    { key: 'SES', description: 'Superintendencia de economía solidaria' },
    { key: 'OTRA', description: 'Otra' },
  ];
  
  class OtherDocuments extends React.Component {
    render() {
      const {
        actividadSelect,
        onActividadSearch,
        tipoFicha
      } = this.props;
      return (
        <div className='content-container'>
          <div className='form-container card-background-gray card-shadow card-radius'>
            <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='camaraDeComercio'
                label='Cámara de comercio donde se encuentra registrada'
                rules={rules['camaraDeComercio'] || ''}
              >
                <Input minLength={4} placeholder="Nombre"/>
              </Form.Item>
            </Col>
            {tipoFicha === 'N' && ( <Col span={12}>
              <Form.Item
                name='cdActividad'
                label='Tipo de actividad'
                rules={rules['cdActividad'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  className='module-selector-select'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={onActividadSearch}
                  notFoundContent={
                    actividadSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {actividadSelect.options.map((actividad, i) => (
                    <Select.Option key={i} value={actividad.codigo}>
                      {actividad.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>)}
            
            </Row>
            <Row gutter={24}>
              <Col>
                <Form.Item>
                  <Row justify='start' style={{ paddingTop: '0.5rem' }}>
                    <Col>
                      <Space>¿Has sido sancionado o investigado por procesos de lavado de activos o de financiación al terrorismo?</Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdSnSancionado'
                        valuePropName='checked'
                        noStyle
                      >
                        <Switch checkedChildren='Si' unCheckedChildren='No' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name='docConstDeLaSociedad'
                label='Documento de constitución de la sociedad'
                rules={rules['docConstDeLaSociedad'] || ''}
              >
               <TextArea rows={5} minLength={1} maxLength={4000} style={{ resize: 'none' }}/>
              </Form.Item>
            </Col>
            </Row>
          </div>
        </div>
      );
    }
  }
  
  export default OtherDocuments;
  