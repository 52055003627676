import { Button, Col, Form, Input, Row, Select, DatePicker, Checkbox, InputNumber } from 'antd';
import React, { useEffect , useState } from 'react'
import {
  emailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../utilities/admin_validation_messages';
import { isEqualsToObject } from '../../../../utilities/compare';
import { tipoIds } from '../../../../utilities/tipoIds';
import { estadoSolicitudMsj, estadoSolicitudMsj2 } from '../../../../utilities/estadoSolicitudMsj';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utilities/messages';
import Text from 'antd/lib/typography/Text'
import TextArea from 'antd/lib/input/TextArea';
import './requestReports.scss';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser,
} from '../../../../utilities/textTools';



const rules = {
  cdEstado: [{ required: true, message: requiredValueMessage() }],
  instanciaAprobacion: [{ required: true, message: requiredValueMessage() }],
  cupoAprobado: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9a-zA-Z-]*$'),
      message: 'El valor no tiene el formato correcto',
    }
  ],
  fechaAprobacionCupo: [{ required: true, message: requiredValueMessage() }],
  fechaVencimientoCupo: [{ required: true, message: requiredValueMessage() }],
  riesgo: [{ required: true, message: requiredValueMessage() }],
  tasa: [{ required: true, message: requiredValueMessage() }],
  causalRechazo: [{ required: true, message: requiredValueMessage() }],
  observacionesDI: [{ required: true, message: requiredValueMessage() }]
};

export const instancia_aprobacion = [
  { key: 'FT', name:"Fast Track"  },
  { key: 'CC', name:"Cómite Credito"  },
  { key: 'JD', name:"Junta Directiva" }
];

export const riesgo = [
  { key: 'BA', name:"Bajo"  },
  { key: 'ME', name:"Medio"  },
  { key: 'MEAL', name:"Medio Alto" },
  { key: 'AL', name:"Alto" },
];

export const causal_rechazo = [
  { key: 'CE', name:"Capacidad de endeudamiento"  },
  { key: 'CR', name:"Centrales de riesgo"  },
  { key: 'TC', name:"Tiempo de constitución" },
  { key: 'PC', name:"Procesos concursales" },
  { key: 'MMC', name:"Matricula mercantil vencida" },
  { key: 'PN', name:"Patrimonio negativo" },
  { key: 'TS', name:"Tipo de sociedad" },
  { key: 'AD', name:"Alteración de documentos" },
  { key: 'SR', name:"SARLAFT" },
  { key: 'IN', name:"Inactividad" },
];


export const FormActualizarSolicitud = (props) => {

    const [form] = Form.useForm();
    const [estado, setEstado] = useState(false);
    const [disableEdit,setDisableEdit] = useState(false);
    

    const handleSelectChange = (value) =>{
      setEstado(value);
    }

    const calcularFechasPorDefecto = () => {
      let date = new Date();
      date.setMonth(date.getMonth() + 6);   
      form.setFieldsValue({
        "feVenCupo":moment(date),
        "feAprobacion":moment(new Date())
      });
    }

    useEffect(() => {
      if (props.data) {
        setEstado(props.data.cdEstado);
        if (props.data.cdEstado === 'A'){
            form.setFieldsValue({
              "cdEstado":props.data.cdEstado,
              "cdInstaciaAprobacion":props.data.cdInstaciaAprobacion,
              "nmCupoAprobado":props.data.nmCupoAprobado,
              "feAprobacion":moment(new Date()),
              "feVenCupo":moment(props.data.feVenCupo),
              "cdRiesgo":props.data.cdRiesgo,
              "nmTasaAprobada":props.data.nmTasaAprobada,
              "solExcepcion":props.data.solExcepcion === 'S' ? true : false,
              "dsObservacion":props.data.dsObservacion
            });
            setDisableEdit(true);
        }else{
          form.setFieldsValue(props.data);
          calcularFechasPorDefecto();
        }
      }else{
        calcularFechasPorDefecto();
      }
    }, []);

  return (
    <div className='header-container'>
        <Form layout='vertical' form={form} onFinish={props.onFinish}>  
          <Row gutter={16} justify='center'>
            <Col span={12}>
              <Form.Item
                name='cdEstado'
                label='ESTADO'
                rules={rules['cdEstado'] || ''}
              >
                <Select
                  onChange={(value) => handleSelectChange(value)}
                  disabled={disableEdit}
                >
                  {estadoSolicitudMsj2.map(({ key, name }) => (
                    <Select.Option key={key} value={key}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            </Row>
            
          {estado === 'A' && (props.tipoSolicitud === 'FA' || props.tipoSolicitud === 'CCR' || props.tipoSolicitud === 'RC')   ? 
          <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdInstaciaAprobacion'
                label='Instancia Aprobación'
                rules={rules['instanciaAprobacion'] || ''}
              >
                <Select>
                  {instancia_aprobacion.map(({ key, name }) => (
                    <Select.Option key={key} value={key}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmCupoAprobado'
                label='Cupo Aprobado'
                rules={rules['cupoAprobado'] || ''}
              >
                <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      stringMode={true}
                  />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='feAprobacion'
              label='Fecha Aprobación Cupo'
              rules={rules['fechaAprobacionCupo'] || ''}
            >
              <DatePicker
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='feVenCupo'
              label='Fecha Vencimiento Cupo'
              rules={rules['fechaVencimientoCupo'] || ''}
            >
              <DatePicker
                format={DATE_FORMAT}
                disabled={disableEdit}
              />
            </Form.Item>
          </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdRiesgo'
                label='Riesgo'
                rules={rules['riesgo'] || ''}
              >
                <Select>
                  {riesgo.map(({ key, name }) => (
                    <Select.Option key={key} value={key}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmTasaAprobada'
                label='Tasa'
                rules={rules['tasa'] || ''}
              >
                <InputNumber
                  min={0}
                  max={100}
                  precision={2}
                  style={{ width: '100%' }}
                  formatter={percentageInputFormat}
                  parser={percentageInputParser}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify='center'>
          <Col span={12}>
              <div className='check'>
              <Form.Item
                name='solExcepcion'
                rules={rules['excepcion'] || ''}
                valuePropName="checked"
              >
                <Checkbox
                  className='tax-info-check'
                >
                <Text level={2}>Solicitud con excepción</Text>  
                </Checkbox>
              </Form.Item>
              </div>
          </Col> 
          </Row>
          <Row gutter={16}>
          <Col flex="auto">
            <Form.Item
              name='dsObservacion'
              label='Observaciónes'
              rules={rules['observaciones'] || ''}
            >
              <TextArea
                maxLength={300}
                style={{ height: 100, width : '100%', resize: 'none' }}
              />
            </Form.Item>
          </Col>
          </Row>
          </>
          : estado === 'R' && (props.tipoSolicitud === 'FA' || props.tipoSolicitud === 'CCR' || props.tipoSolicitud === 'RC') ?
          <>
            <Row gutter={16} justify='center'>
              <Col span={12}>
                <Form.Item
                  name='cdCausalRechazo'
                  label='Causal Rechazo'
                  rules={rules['causalRechazo'] || ''}
                >
                  <Select>
                    {causal_rechazo.map(({ key, name }) => (
                      <Select.Option key={key} value={key}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
          : estado === 'DI' ?
          <>
            <Row gutter={16}>
              <Col flex="auto">
                <Form.Item
                  name='dsObservacionDI'
                  label='Observaciónes de documentación incompleta'
                  rules={rules['observacionesDI'] || ''}
                >
                  <TextArea
                    maxLength={300}
                    style={{ height: 100, width : '100%', resize: 'none' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </> : null
          }

          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
  )
}
