import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { sorterDate, sorterText } from '../../../../../utilities/textTools';
import TableReport from '../../../../../components/tableReport/tableReport';

import { listarProveedores } from '../../../../../services/admin/reports/quotasService';
import moment from 'moment';
import {
  fichaProximaVencerExcel,
  fichaVigenteExcel,
  listarFichaProximaVencer,
  listarFichaVigente,
} from '../../../../../services/admin/reports/customer_profile';
import CustumerFilterForm from '../custumerFilterForm/custumerFilterForm';
import { setExcel } from '../../../../../utilities/downloadTools';

const initialState = {
  form: {
    fechaCargaDesde: moment(),
    fechaCargaHasta: moment(),
    scProveedor: '',
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  proveedorSelect: {
    fetching: false,
    options: [],
  },
};

class NextToBeat extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreCompania',
        sorter: (a, b) => sorterText(a.nombreCompania, b.nombreCompania),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACION',
        dataIndex: 'idCompania',
        sorter: (a, b) => sorterText(a.idCompania, b.idCompania),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE CARGA',
        dataIndex: 'feCarga',
        sorter: (a, b) => sorterDate(a.feCarga, b.feCarga),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'CLASIFICACION',
        dataIndex: 'dsClasificacionProveedor',
        sorter: (a, b) =>
          sorterText(a.dsClasificacionProveedor, b.dsClasificacionProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'NO. OFERTAS',
        dataIndex: 'nmOfertas',
        sorter: (a, b) => this.sorter(a.nmOfertas, b.nmOfertas),
        showSorterTooltip: false,
      },
    ];
  }

  sorter = (a, b) =>
    isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b;

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scProveedor: value.scProveedor,
        fechaVencimientoDesde: value.fechaRango[0],
        fechaVencimientoHasta: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listarFichaProximaVencer(
          this.props.authToken,
          this.getFiltro()
        );

        console.log(response);

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await fichaProximaVencerExcel(
          this.props.authToken,
          this.getFiltro()
        );
        setExcel(response, `Ficha_proxima_vencer`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      proveedorSelect,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <CustumerFilterForm
            proveedorSelect={proveedorSelect}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default NextToBeat;
