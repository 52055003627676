import {
  AlertOutlined,
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BarsOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  ContactsOutlined,
  CopyOutlined,
  DownloadOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  FormOutlined,
  HomeOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MonitorOutlined,
  ProfileOutlined,
  ReadOutlined,
  RobotOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShareAltOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  TableOutlined,
  TeamOutlined,
  ToolOutlined,
  UserAddOutlined,
  WalletOutlined,
  UploadOutlined,
  ZoomInOutlined,
  FundProjectionScreenOutlined,
} from '@ant-design/icons';
import { Col, Layout, Menu, Modal, Row } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import logo from '../assets/img/logo-side-bar.png';
import userLogo from '../assets/img/user-icon.png';
import Banks from '../pages/admin/administration/banks/banks';
import Documentos from '../pages/admin/administration/documentos/documentos';
import EconomicActivity from '../pages/admin/administration/economicActivity/economicActivity';
import ErpConection from '../pages/admin/administration/erpConection/erpConection';
import NonBusinessDays from '../pages/admin/administration/nonBusinessDays/nonBusinessDays';
import Parameterization from '../pages/admin/administration/parameterization/parameterization';
import Users from '../pages/admin/administration/users/users';
import ErpErrors from '../pages/admin/archive/erpErrors/erpErrors';
import NotificationsReport from '../pages/admin/archive/notificationsReport/notificationsReport';
import FileDownloads from '../pages/admin/archive/fileDownloads/fileDownloads';
import ArchivoLogs from '../pages/admin/archive/logs/archives';
import BulkLoad from '../pages/admin/bills/bulkload';
import BulkLoadTask from '../pages/admin/bills/task/task';
import BulkLoadTaskQuota from '../pages/admin/bills/task/taskquota';
import BulkLoadF4ll from '../pages/admin/bills/bulkloadFall/bulkloadFall';
import BusinessGroups from '../pages/admin/clients/businessGroups/businessGroups';
import BusinessGroupsEditForm from '../pages/admin/clients/businessGroups/businessGroupsEditForm/businessGroupsEditForm';
import BusinessGroupsNewForm from '../pages/admin/clients/businessGroups/businessGroupsNewForm/businessGroupsNewForm';
import FundProviderEditForm from '../pages/admin/clients/fundProviders/fundProviderEditForm/fundProviderEditForm';
import FundProviderNewForm from '../pages/admin/clients/fundProviders/fundProviderNewForm/fundProviderNewForm';
import FundProviders from '../pages/admin/clients/fundProviders/fundProviders';
import Payers from '../pages/admin/clients/payers/payers';
import PayersEditForm from '../pages/admin/clients/payers/payersEditForm/payersEditForm';
import PayersNewForm from '../pages/admin/clients/payers/payersNewForm/payersNewForm';
import Person from '../pages/admin/clients/person/person';
import SupplierEditForm from '../pages/admin/clients/suppliers/supplierEditForm/supplierEditForm';
import Suppliers from '../pages/admin/clients/suppliers/suppliers';
import SuppliersNewForm from '../pages/admin/clients/suppliers/suppliersNewForm/suppliersNewForm';
import HubspotTask from '../pages/admin/crm/hubspotTask';
import Vinculacion from '../pages/admin/crm/vinculacion/vinculacion';
/**Pages */
import Dashboard from '../pages/admin/dashboard/dashboard';
import Bills from '../pages/admin/historics/bills/bills';
import DownloadList from '../pages/admin/historics/downloadList/downloadList';
import ExecutedTasksList from '../pages/admin/historics/executedTasksList/executedTasksList';
import TraceabilityReport from '../pages/admin/historics/traceabilityReport/traceabilityReport';
import TransactionalErrors from '../pages/admin/historics/transactionalErrors/transactionalErrors';
import Agreements from '../pages/admin/history/agreements/reports';
import NeuroscoreArchive from '../pages/admin/neuroscore/archive/reports';
import NeuroscoreConfig from '../pages/admin/neuroscore/config/reports';
import NeuroscoreReports from '../pages/admin/neuroscore/reports/reports';
import ACHoperation from '../pages/admin/operations/ACHoperation/ACHoperation';
import AgreementEditForm from '../pages/admin/operations/agreementOperation/agreementEditForm/agreementEditForm';
import AgreementNewForm from '../pages/admin/operations/agreementOperation/agreementNewForm/agreementNewForm';
import AgreementOperation from '../pages/admin/operations/agreementOperation/agreementOperation';
import Discounts from '../pages/admin/operations/discounts/discounts';
import PaymentReports from '../pages/admin/operations/paymentReports/paymentReports';
import Quotesagreements from '../pages/admin/operations/quotesagreements/quotesagreements';
import ReceiptedInvoices from '../pages/admin/operations/receiptedInvoices/receiptedInvoices';
import BillsEvents from '../pages/admin/radian/billsEvent/billsEvents';
import BillsEventsDetails from '../pages/admin/radian/billsEvent/billsEventsDetails/billsEventsDetails';
import MadateEditForm from '../pages/admin/radian/mandates/mandateEditForm/madateEditForm';
import MandateNewForm from '../pages/admin/radian/mandates/mandateNewForm/mandateNewForm';
import Mandate from '../pages/admin/radian/mandates/mandates';
import CustomerProfile from '../pages/admin/reports/customer_profile/reports';
import FundingReport from '../pages/admin/reports/fundingReport/fundingReport';
import DiscountedBills from '../pages/admin/reports/discounted_bills/reports';
import LoadedBills from '../pages/admin/reports/loaded_bills/reports';
import Offers from '../pages/admin/reports/offers/offers';
import OffersBills from '../pages/admin/reports/offers/offersBills/offersBills';
import OperationSuppliers from '../pages/admin/reports/operations/reports';
import Quotas from '../pages/admin/reports/quotas/quotas';
import FAQ from '../pages/proveedor/content/faq';
import HelpCenter from '../pages/proveedor/content/helpcenter';
import TYC from '../pages/proveedor/content/TYC';
import userServices from '../services/userService';
import {
  refreshToken,
  sessionClose,
  setExpirationTimeout,
} from '../store/actions/authActions';
import { setCompany } from '../store/actions/pagador/companyActions';
import '../styles/proveedorLayout.scss';
import '../styles/standard.scss';
import { EMAIL_INIT_PAGADOR } from '../utilities/messages';
import { capitalize } from '../utilities/textTools';
import FichaClienteEdit from '../pages/admin/reports/customer_profile/vinculacionEditModal/fichaClienteEdit/fichaClienteEdit';
import InscripcionUsuariosForm from '../pages/vinculacion/InscripcionUsuarios/inscripcionUsuariosForm';
import InscripcionUsuariosAdminForm from '../pages/admin/reports/customer_profile/InscripcionUsuariosAdmin/inscripcionUsuariosAdminForm';
import Program from '../pages/admin/reports/program/program';
import Consultation from '../pages/admin/credits/consultation/consultation';
import BulkRatesUpdate from '../pages/admin/operations/bulkRatesUpdate/bulkRatesUpdate';
import { RequestReports } from '../pages/admin/operations/requests/requestReports';
import { RequestDetails } from '../pages/admin/operations/requests/requestDetails';
const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSelectedKey: '1',
      selected_company: '',
      notificationDrawer: false,
      notifications: [],
      unreadedNotifications: 0,
      showAuthExpModal: false,
      setTimeoutCloseSession: null,
      menu: [],
    };
  }

  fecha = moment().format('dddd, LL');

  unfilteredMenu = [
    {
      title: 'Inicio',
      icon: HomeOutlined,
      selected: true,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/dashboard`,
      key: 1,
    },
    {
      title: 'Administración',
      icon: ToolOutlined,
      selected: false,
      disabled: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'MAESTROS',
      key: 100,
      children: [
        {
          title: 'Parametrización',
          icon: SettingOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'MAESTROSPARAMETRIZACION',
          href: `${process.env.PUBLIC_URL}/parameterization`,
          key: 2,
        },
        {
          title: 'Conexión ERP',
          icon: ApiOutlined,
          selected: true,
          disabled: false,
          protected: true,
          protectedKey: 'MAESTROSERP',
          href: `${process.env.PUBLIC_URL}/erpConection`,
          key: 3,
        },
        {
          title: 'Documentos',
          icon: CopyOutlined,
          selected: true,
          disabled: false,
          protected: true,
          protectedKey: 'MAESTROSDOCUMENTOS',
          href: `${process.env.PUBLIC_URL}/documentos`,
          key: 4,
        },
        {
          title: 'Bancos',
          icon: BankOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'MAESTROSBANCO',
          href: `${process.env.PUBLIC_URL}/banks`,
          key: 5,
        },
        {
          title: 'Actividades economicas',
          icon: BarsOutlined,
          selected: true,
          disabled: false,
          protected: true,
          protectedKey: 'MAESTROSACTECO',
          href: `${process.env.PUBLIC_URL}/economicActivity`,
          key: 6,
        },
        {
          title: 'Días no habiles',
          icon: CalendarOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'MAESTROSDNH',
          href: `${process.env.PUBLIC_URL}/nonBusinessDays`,
          key: 7,
        },
        {
          title: 'Usuarios',
          icon: ContactsOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'MAESTROSUADM',
          href: `${process.env.PUBLIC_URL}/users`,
          key: 8,
        },
      ],
    },
    {
      title: 'Clientes',
      icon: SolutionOutlined,
      selected: false,
      disabled: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'CLIENTES',
      key: 200,
      children: [
        {
          title: 'Proveedores',
          icon: ShoppingCartOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CLIENTESPROVEEDOR',
          href: `${process.env.PUBLIC_URL}/suppliers`,
          key: 20,
        },
        {
          title: 'Fondeadores',
          icon: BankOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CLIENTESFONDEADOR',
          href: `${process.env.PUBLIC_URL}/fundProviders`,
          key: 21,
        },
        {
          title: 'Pagadores',
          icon: ShopOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CLIENTESPAGADOR',
          href: `${process.env.PUBLIC_URL}/payers`,
          key: 22,
        },
        {
          title: 'Grupos empresariales',
          icon: ClusterOutlined,
          selected: false,
          disabled: false,
          isSubMenu: false,
          protected: true,
          protectedKey: 'GRUPOSEMPRESARIALES',
          href: `${process.env.PUBLIC_URL}/businessGroups`,
          key: 300,
        },
      ],
    },

    {
      title: 'Operaciones',
      icon: CalculatorOutlined,
      selected: false,
      disabled: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'OPERACIONES',
      key: 400,
      children: [
        {
          title: 'Cupo Contraparte',
          icon: WalletOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CUPOSPAG',
          href: `${process.env.PUBLIC_URL}/quotesagreements`,
          key: 40,
        },
        {
          title: 'Convenios',
          icon: ScheduleOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'OPERACIONESNEGOCIACION',
          href: `${process.env.PUBLIC_URL}/operation`,
          key: 41,
        },
        {
          title: 'Descuento Automático',
          icon: RobotOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CONFCONVPROV',
          href: `${process.env.PUBLIC_URL}/discounts`,
          key: 42,
        },
        {
          title: 'Reporte de Pago',
          icon: AuditOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'OPERACIONESREPORTEPAGO',
          href: `${process.env.PUBLIC_URL}/paymentReports`,
          key: 43,
        },
        {
          title: 'Operacion ACH',
          icon: FileDoneOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'OPACH',
          href: `${process.env.PUBLIC_URL}/ACHoperation`,
          key: 44,
        },
        {
          title: 'Tasas Masivas',
          icon: FileDoneOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'ACTUALIZACIONMASIVATASAS',
          href: `${process.env.PUBLIC_URL}/bulkRatesUpdate`,
          key: 45,
        },
        {
          title: 'Solicitudes',
          icon: FormOutlined,
          selected: false,
          disabled: false,
          protected: false,
          protectedKey: 'SOLICITUDESPRODUCTONUEVO',
          href: `${process.env.PUBLIC_URL}/requests`,
          key: 46,
        },
      ],
    },
    {
      title: 'Facturas',
      icon: ReadOutlined,
      selected: false,
      disabled: false,
      isSubMenu: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'FACTURA',
      key: 500,
      children: [
        {
          title: 'Factura Masiva',
          icon: CloudUploadOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'FACTURAMASIVA',
          href: `${process.env.PUBLIC_URL}/billsbulkload`,
          key: 50,
        },
        {
          title: 'Carga Masiva ERP',
          icon: CarryOutOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'FACTURAMASIVASAP',
          href: `${process.env.PUBLIC_URL}/billsbulkloadtask`,
          key: 51,
        },
        {
          title: 'Carga Masiva Cupos',
          icon: WalletOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'TAREASMASIVASCUPO',
          href: `${process.env.PUBLIC_URL}/billsbulkLoadtaskquota`,
          key: 52,
        },
        {
          title: 'Carga Facturas F4ll',
          icon: UploadOutlined,
          selected: false,
          disabled: false,
          href: `${process.env.PUBLIC_URL}/bulkloadF4ll`,
          key: 53,
          visible: true,
        },
      ],
    },
    {
      title: 'Reportes',
      icon: ZoomInOutlined,
      selected: false,
      disabled: false,
      isSubMenu: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'REP',
      key: 600,
      children: [
        {
          title: 'Facturas descontadas',
          icon: ProfileOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPFACTURAS',
          href: `${process.env.PUBLIC_URL}/reports/discountedbills`,
          key: 60,
        },
        {
          title: 'Facturas cargadas',
          icon: CloudUploadOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPFACTURASCARGADAS',
          href: `${process.env.PUBLIC_URL}/reports/loadedbills`,
          key: 61,
        },
        {
          title: 'Operaciones',
          icon: TableOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPOPFOND',
          href: `${process.env.PUBLIC_URL}/reports/operationSuppliers`,
          key: 63,
        },
        {
          title: 'Cupos',
          icon: WalletOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPCUPOCONV',
          href: `${process.env.PUBLIC_URL}/reports/quotas`,
          key: 64,
        },
        {
          title: 'Ofertas',
          icon: ShoppingOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPOFEDIA',
          href: `${process.env.PUBLIC_URL}/reports/offers`,
          key: 65,
        },
        {
          title: 'Ficha cliente',
          icon: FormOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPFICHA',
          href: `${process.env.PUBLIC_URL}/reports/customerProfile`,
          key: 66,
        },
        {
          title: 'Programar',
          icon: CalendarOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPROGRAM',
          href: `${process.env.PUBLIC_URL}/reports/program`,
          key: 67,
        },
        {
          title: 'Fondeo',
          icon: FundProjectionScreenOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPFONDEO',
          href: `${process.env.PUBLIC_URL}/reports/fundingReport`,
          key: 68,
        },
      ],
    },
    {
      title: 'Historico',
      icon: FieldTimeOutlined,
      selected: false,
      disabled: false,
      isSubMenu: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'HISTORICOS',
      key: 700,
      children: [
        {
          title: 'Facturas',
          icon: ProfileOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'HISTORICOFACTURAS',
          href: `${process.env.PUBLIC_URL}/history/bills`,
          key: 74,
        },
        {
          title: 'Errores transaccionales',
          icon: AlertOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'HISTORICOERRTRANS',
          href: `${process.env.PUBLIC_URL}/history/transactionalErrors`,
          key: 75,
        },
        {
          title: 'Lista de tareas ejecutadas',
          icon: CarryOutOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'HISTORICOETAREA',
          href: `${process.env.PUBLIC_URL}/history/executedTasksList`,
          key: 76,
        },
        {
          title: 'Reportes de trazabilidad',
          icon: MonitorOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPTRAZ',
          href: `${process.env.PUBLIC_URL}/history/traceabilityReport`,
          key: 77,
        },
        {
          title: 'Lista de descargar',
          icon: DownloadOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPDOWN',
          href: `${process.env.PUBLIC_URL}/history/downloadList`,
          key: 78,
        },
        {
          title: 'Facturas descontadas',
          icon: ScheduleOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'HISTORICOSNEG',
          href: `${process.env.PUBLIC_URL}/history/agreements`,
          key: 70,
        },
      ],
    },
    {
      title: 'Archivo',
      icon: FolderOpenOutlined,
      selected: false,
      disabled: false,
      isSubMenu: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'ARCHIVOS',
      key: 800,
      children: [
        {
          title: 'Logs',
          icon: MonitorOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'ARCHIVOSLOGS',
          href: `${process.env.PUBLIC_URL}/archives/archivologs`,
          key: 80,
        },
        {
          title: 'Descarga de archivos',
          icon: DownloadOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'DESCARGARARCHIVOS',
          href: `${process.env.PUBLIC_URL}/archives/fileDownloads`,
          key: 81,
        },
        {
          title: 'Errores erp',
          icon: AlertOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'ERRERP',
          href: `${process.env.PUBLIC_URL}/archives/erpErrors`,
          key: 82,
        },
        {
          title: 'Reporte de Notificaciones',
          icon: AlertOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPNOTIFICACIONES',
          href: `${process.env.PUBLIC_URL}/archives/notificationsReport`,
          key: 83,
        },
      ],
    },
    {
      title: 'Neuroscore',
      icon: LineChartOutlined,
      selected: false,
      disabled: false,
      isSubMenu: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'NEUROSCORE',
      key: 900,
      children: [
        {
          title: 'Archivo',
          icon: FileTextOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'ARCHIVONEURO',
          href: `${process.env.PUBLIC_URL}/neuroscore/archive`,
          key: 90,
        },
        {
          title: 'Config',
          icon: SettingOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CONFIGNEURO',
          href: `${process.env.PUBLIC_URL}/neuroscore/config`,
          key: 91,
        },
        {
          title: 'Reporte',
          icon: MonitorOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'REPORTESNEURO',
          href: `${process.env.PUBLIC_URL}/neuroscore/reports`,
          key: 92,
        },
      ],
    },
    {
      title: 'Crm',
      icon: TeamOutlined,
      selected: false,
      disabled: false,
      isSubMenu: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'CRM',
      key: 1000,
      children: [
        {
          title: 'Vinculacion',
          icon: UserAddOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CRMVINCULAR',
          href: `${process.env.PUBLIC_URL}/crm/vinculacion`,
          key: 95,
        },
        {
          title: 'Tareas Hubspot',
          icon: ShareAltOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CRMHUBSPOT',
          href: `${process.env.PUBLIC_URL}/crm/hubspot`,
          key: 96,
        },
      ],
    },
    {
      title: 'Radian',
      icon: TeamOutlined,
      selected: false,
      disabled: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'RADIAN',
      key: 1100,
      children: [
        {
          title: 'Mandatos',
          icon: FormOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'RADIANMANDATOS',
          href: `${process.env.PUBLIC_URL}/radian/mandate`,
          key: 110,
        },
        {
          title: 'Eventos de Facturas',
          icon: FormOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'RADIANEVENTOSFACTURAS',
          href: `${process.env.PUBLIC_URL}/radian/billsEvents`,
          key: 111,
        },
      ],
    },
    {
      title: 'Creditos',
      icon: TeamOutlined,
      selected: false,
      disabled: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'CREDITOS',
      key: 1200,
      children: [
        {
          title: 'Consultas',
          icon: FormOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'CREDITOSCONSULTAS',
          href: `${process.env.PUBLIC_URL}/credits/consultation`,
          key: 120,
        },
      ],
    },
  ];

  componentDidMount() {
    this.renderMenu();
    if (window.configuredInterval) clearInterval(window.configuredInterval);
    window.configuredInterval = setInterval(() => {
      this.props.setExpirationTimeout(this.props.authExpiration);
    }, 30000);
  }

  componentDidUpdate(props, state) {
    if (this.props.reachExpiration !== props.reachExpiration) {
      if (this.props.reachExpiration) {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
          setTimeoutCloseSession: setTimeout(this.logout, 110000),
        });
      } else {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
        });
        clearTimeout(this.state.setTimeoutCloseSession);
      }
    }
  }

  updateSelectedCompany = (value, e) => {
    this.setState({
      ...this.state,
      selected_company: value,
    });
    this.props.setCompany(value);
  };

  renderMenu() {
    userServices
      .getAdminUserModules(
        this.props.authtoken,
        process.env.REACT_APP_ADMIN_NAME
      )
      .then((response) => {
        this.setState({
          ...this.state,
          menu: this.filterMenu(
            Array.isArray(response.data) ? response.data : [],
            this.unfilteredMenu
          ),
        });
      });
  }

  filterMenu(userModules, menuItems) {
    return menuItems
      .filter((filterItem) => {
        if (filterItem.protected) {
          let found = userModules.find(
            (x) => x.cdRecursos == filterItem.protectedKey
          );
          if (found) {
            filterItem.title = found.dsNombre;
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .map((item) => {
        if (item.protected) {
          if (Array.isArray(item.children)) {
            return {
              ...item,
              children: this.filterMenu(userModules, item.children),
            };
          } else {
            return item;
          }
        } else {
          return item;
        }
      });
  }

  logout = () => {
    this.props.sessionClose(this.props.authtoken);
  };

  selectedKeyHandler = (key) => {
    this.setState({
      ...this.state,
      menuSelectedKey: key,
    });
  };

  render() {
    return (
      <Layout>
        <Sider className='realasside'>
          <div className='user-container'>
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img className='brand-logo' src={logo} alt='' />
            </Link>
            {this.props.children}
            <img className='user-avatar' src={userLogo} alt='' />
            <p className='user-name'>{this.props.username}</p>
            <br />
            <br />
          </div>
          <Menu
            className='slider-list'
            mode='inline'
            selectedKeys={[this.state.menuSelectedKey]}
          >
            {this.state.menu.map((menu, index) =>
              menu.isSubMenu ? (
                <SubMenu
                  key={`sub${index}`}
                  title={
                    <span>
                      {menu.icon ? React.createElement(menu.icon) : ''}
                      <span>{menu.title}</span>
                    </span>
                  }
                >
                  {menu.children.map((submenu) => (
                    <Menu.Item key={submenu.key}>
                      <Link to={submenu.href}>
                        {submenu.icon ? React.createElement(submenu.icon) : ''}
                        {submenu.title}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.key}>
                  <Link to={menu.href}>
                    {menu.icon ? React.createElement(menu.icon) : ''}
                    {menu.title}
                  </Link>
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout className='content'>
          <Header className='header'>
            <Row className='menu'>
              <Col span={23}>
                {this.fecha.charAt(0).toUpperCase() + this.fecha.slice(1)}
              </Col>

              <Col span={1} onClick={this.logout} className='logout'>
                {' '}
                <LogoutOutlined className='notification-icon' />
              </Col>
            </Row>
          </Header>
          <Content>
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/dashboard`}>
                <Dashboard
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/parameterization`}>
                <Parameterization menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/erpConection`}>
                <ErpConection menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/documentos`}>
                <Documentos menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/banks`}>
                <Banks menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/economicActivity`}>
                <EconomicActivity menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/nonBusinessDays`}>
                <NonBusinessDays menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/users`}>
                <Users menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/suppliers`}>
                <Suppliers menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/suppliers/new`}>
                <SuppliersNewForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/suppliers/edit/:scPersona`}
              >
                <SupplierEditForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/fundProviders`}>
                <FundProviders menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/fundProviders/new`}>
                <FundProviderNewForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/fundProviders/edit/:scPersona`}
              >
                <FundProviderEditForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/payers`}>
                <Payers menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/payers/new`}>
                <PayersNewForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/payers/edit/:scPersona`}
              >
                <PayersEditForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/person/:cdTypePerson/:scPersona`}
              >
                <Person menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/businessGroups`}>
                <BusinessGroups menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/businessGroups/new`}
              >
                <BusinessGroupsNewForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/businessGroups/edit/:scGrupo`}
              >
                <BusinessGroupsEditForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/operation`}>
                <AgreementOperation menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/operation/new`}>
                <AgreementNewForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/operation/edit/:scConvenio`}
              >
                <AgreementEditForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/bulkRatesUpdate`}>
                <BulkRatesUpdate menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/discounts`}>
                <Discounts menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/paymentReports`}>
                <PaymentReports menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/ACHoperation`}>
                <ACHoperation menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/receiptedInvoices`}>
                <ReceiptedInvoices menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/billsbulkload`}>
                <BulkLoad menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/billsbulkloadtask`}>
                <BulkLoadTask menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/billsbulkloadtaskquota`}
              >
                <BulkLoadTaskQuota menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/bulkloadF4ll`}>
                <BulkLoadF4ll menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/quotesagreements`}>
                <Quotesagreements menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/operationSuppliers`}
              >
                <OperationSuppliers menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/discountedbills`}
              >
                <DiscountedBills menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/loadedbills`}
              >
                <LoadedBills menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/archives/archivologs`}
              >
                <ArchivoLogs menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/neuroscore/archive`}
              >
                <NeuroscoreArchive menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/neuroscore/config`}>
                <NeuroscoreConfig menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/neuroscore/reports`}
              >
                <NeuroscoreReports menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/crm/vinculacion`}>
                <Vinculacion menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/crm/hubspot`}>
                <HubspotTask menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/customerProfile`}
              >
                <CustomerProfile menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/customerProfile/:currentTab`}
              >
                <CustomerProfile menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/reports/program`}>
                <Program menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/customer_profile/fichaClienteEdit/:idUser/:cdFicha`}
              >
                <FichaClienteEdit menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/customer_profile/inscripcionUsuarios/:scCompania/:idUser`}
              >
                <InscripcionUsuariosAdminForm />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/fundingReport`}
              >
                <FundingReport menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/radian/mandate`}>
                <Mandate menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/radian/mandate/new`}
              >
                <MandateNewForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/radian/mandate/edit/:scMandato`}
              >
                <MadateEditForm menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/radian/billsEvents`}
              >
                <BillsEvents menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/credits/consultation`}
              >
                <Consultation menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/billEvent/details/:scFactura`}
              >
                <BillsEventsDetails menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/history/agreements`}
              >
                <Agreements menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/reports/quotas`}>
                <Quotas menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/reports/offers`}>
                <Offers menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reports/offers/bills/:scProveedor`}
              >
                <OffersBills />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/history/bills`}>
                <Bills menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/history/transactionalErrors`}
              >
                <TransactionalErrors menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/history/executedTasksList`}
              >
                <ExecutedTasksList menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/history/traceabilityReport`}
              >
                <TraceabilityReport menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/history/downloadList`}
              >
                <DownloadList menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/archives/fileDownloads`}
              >
                <FileDownloads menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/archives/erpErrors`}
              >
                <ErpErrors menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/archives/notificationsReport`}
              >
                <NotificationsReport menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/helpcenter`}>
                <HelpCenter
                  menuHandler={this.selectedKeyHandler}
                  email={EMAIL_INIT_PAGADOR}
                  module='fondeador'
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/requests`}>
                <RequestReports
                  menuHandler={this.selectedKeyHandler}
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/request/:scSolicitud`}>
                <RequestDetails
                  menuHandler={this.selectedKeyHandler}
                />
              </Route>

              <Route path={`${process.env.PUBLIC_URL}/faq`}>
                <FAQ menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route path={`${process.env.PUBLIC_URL}/tyc`}>
                <TYC menuHandler={this.selectedKeyHandler} hadAccepted='true' />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/*`}>
                <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
              </Route>
            </Switch>
          </Content>
        </Layout>
        <Modal
          title='Sessión a punto de finalizar'
          visible={this.state.showAuthExpModal}
          onOk={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
            clearTimeout(this.state.setTimeoutCloseSession);
            this.props.refreshToken(this.props.authRefreshToken);
          }}
          onCancel={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
          }}
        >
          <p>
            Tu sesión esta a punto de finalizar, si deseas continuar operando en
            la plataforma presiona "Aceptar"
          </p>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  authtoken: state.auth.auth.access_token,
  username: state.auth.auth.name,
  authExpiration: state.auth.expiressTimestamp,
  authRefreshToken: state.auth.auth.refresh_token,
  reachExpiration: state.auth.reachExpiration,
});

export default connect(mapStateToProps, {
  setCompany,
  setExpirationTimeout,
  refreshToken,
  sessionClose,
})(AdminLayout);
