import React, { Component } from 'react'
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Card,
  Checkbox,
  Modal,
} from 'antd'
import {
  FileExcelOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileOutlined,
} from '@ant-design/icons'
import { initialState } from './initialState'
import { columns } from './columns'
import { form } from './form'
import {
  listaGruposEmpresariales,
  listaClusters,
  grabarCluster,
} from '../../../../../services/admin/configNeuroscoreService'
import '../reports.scss'
import { connect } from 'react-redux'
import { REPORTS_DATES_REQUIRED_ERROR } from '../../../../../utilities/messages'
import Total from '../../../../proveedor/reports/total'
import equals from 'fast-deep-equal'

const { Option } = Select

let intColumnas = 1

class Content extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.formRef = React.createRef()
  }

  columnas = {
    [this.props.type]: [
      ...columns[this.props.type],
      {
        title: 'OPCIONES',
        dataIndex: '',
        render: (record) => (
          <Button
            type="primary"
            title="Editar"
            onClick={() => this.showModal(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ],
  }

  formulario = form
  isBackButtonClicked = false

  showModal(record) {
    this.setState({
      parametrosModal: {
        ...this.state.parametrosModal,
        clusNeuroRiesgo: record.clusNeuroRiesgo,
        percentilCorrespondiente: record.percentilCorrespondiente,
        limiteInferior: record.limiteInferior,
        limiteSuperior: record.limiteSuperior,
      },
      modalVisible: true,
    })
  }

  handleCancelModal = (e) => {
    this.setState({
      modalVisible: false,
    })
  }

  validations = (parametrosModal) => {
    let validationResult = true

    if (!parametrosModal.clusNeuroRiesgo) {
      validationResult = false
      notification.error({
        message: 'Error de validaciónn',
        description: 'CLUSTER NEURONAL DE RIESGO es obligatorio',
        duration: 4,
      })
    }
    if (!parametrosModal.percentilCorrespondiente || `${parametrosModal.percentilCorrespondiente}`.length > 2 ) {
      validationResult = false
      notification.error({
        message: 'Error de validaciónn',
        description:
          'PERCENTIL CORRESPONDIENTE es obligatorio y de máximo 2 cifras',
        duration: 4,
      })
    }
    if (!parametrosModal.limiteInferior
      ||
      parametrosModal.limiteInferior <= 0
      ||
      !`${parametrosModal.limiteInferior}`.includes('.')
      ||
      `${parametrosModal.limiteInferior}`.split('.')[0].length > 4
      ||
      `${parametrosModal.limiteInferior}`.split('.')[1].length > 3
      ) {
      validationResult = false
      notification.error({
        message: 'Error de validaciónn',
        description:
          'LÍMITE INFERIOR es obligatorio, valor minimo 0, 4 dígitos enteros y 3 fracción',
        duration: 4,
      })
    }
    if (!parametrosModal.limiteSuperior
      ||
      parametrosModal.limiteSuperior <= 0
      ||
      !`${parametrosModal.limiteSuperior}`.includes('.')
      ||
      `${parametrosModal.limiteSuperior}`.split('.')[0].length > 4
      ||
      `${parametrosModal.limiteSuperior}`.split('.')[1].length > 3
      ) {
      validationResult = false
      notification.error({
        message: 'Error de validaciónn',
        description:
          'LÍMITE SUPERIOR es obligatorio, valor minimo 0, 4 dígitos enteros y 3 fracción',
        duration: 4,
      })
    }

    return validationResult
  }

  grabar = () => {
    this.setState({ ...this.state, loading: true })
    setTimeout(() => {
      this.setState({ ...this.state, loading: false })
    }, 3000)

    if (!this.validations(this.state.parametrosModal)) {
      return
    }

    grabarCluster(this.props.authToken, this.state.parametrosModal).then(
      (response) => {
        console.log(response)
        if (response.data) {
          notification.success({
            message: 'Actualizado',
            description: response.message,
            duration: 4,
          })
        }
        this.setState({
          ...this.state,
          modalVisible: false,
          loading: false,
        })
        this.getData()
      },
    )
  }

  formRender = () => {
    const { type } = this.props
    let formulario = ''
    return (formulario = (
      <Form name="content_form" layout="vertical" className="filters-container">
        {this.formulario[type].map((fila, key) => {
          if (fila.length === 1) {
            return (
              <Row key={key} gutter={16}>
                <Col span={24}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
              </Row>
            )
          } else {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={fila[1].nombre} label={fila[1].label}>
                    {this.inputType(fila[1])}
                  </Form.Item>
                </Col>
              </Row>
            )
          }
        })}
        <Row justify="left">
          <Col span={8}>
            <Form.Item name="submit">
              <Button
                className="form-btn btn-radius"
                type="primary"
                onClick={() => this.getData()}
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ))
  }

  inputType = (campo) => {
    const { type } = this.props
    let input
    let stateName = campo.nombre
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(e) => this.handleTextChange(e, stateName, type)}
          />
        )
        break
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName, type)
            }
          />
        )
        break
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        )
        break
      case 'select_nosearch':
        input = (
          <Select
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onClick={() => {
              if (this.state[campo.selectType].length == 0)
                this[campo.loadFunction]()
            }}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        )
        break
    }
    return input
  }

  handleTextChange = (e, campo, type) => {
    this.state[type][campo] = e.target.value
    this.setState({
      ...this.state,
    })
  }

  handleDateChange = (moment, campo, type) => {
    this.state[type][campo] = moment
  }

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      })
      switch (nombre) {
        case 'scGrupo':
          listaGruposEmpresariales(this.props.authToken, { name: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  grupoEmpresarialOptions: response.data
                    .filter((x) =>
                      x.dsNombres.toLowerCase().includes(value.toLowerCase()),
                    )
                    .map((item) => {
                      return {
                        key: item.scGrupo,
                        nombre: item.dsNombres,
                      }
                    }),
                  fetching: false,
                })
              }
            },
          )
      }
    } else {
      switch (nombre) {
        case 'scPagador':
          this.setState({
            ...this.state,
            pagadorOptions: [],
          })
          break
        case 'scConvenio':
          this.setState({
            ...this.state,
            convenioOptions: [],
          })
          break
        case 'scGrupoEmpresarial':
          this.setState({
            ...this.state,
            grupoEmpresarialOptions: [],
          })
          break
      }
    }
  }

  handleSelectChange = (value, stateName) => {
    this.state[this.props.type][stateName] = value
    this.setState({ ...this.state })
  }

  getData = () => {
    this.state.resultado = []
    switch (this.props.type) {
      case 'config':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          })
          listaClusters(this.props.authToken, this.state[this.props.type]).then(
            (response) => {
              this.setResponse(response)
            },
          )
        }
        break
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(props, state) {
    if (!equals(this.state.parametrosModal, state.parametrosModal)) {
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue(this.state.parametrosModal)
      }
    }
  }

  setResponse = (response) => {
    if (response && response.status !== false) {
      this.setState({
        ...this.state,
        [this.props.type]: {
          ...this.state[this.props.type],
          resultado: response.data,
          total: response.metadata.count,
        },
        loading: false,
        sumatorias: response.metadata.sumatorias,
      })
    } else {
      this.setState({
        ...this.state,
        loading: false,
      })
    }
  }

  validateForm = () => {
    let filtros = this.state[this.props.type]
    let fechas = true
    Object.keys(filtros).forEach((nombre) => {
      if (nombre !== 'resultado') {
        if (
          nombre.includes('fecha') &&
          (filtros[nombre] === '' || filtros[nombre] === null)
        ) {
          fechas = false
        }
      }
    })
    if (!fechas) {
      notification.error({
        message: 'Las fechas son obligatorias',
        description: REPORTS_DATES_REQUIRED_ERROR,
        duration: 4,
      })
    }
    return fechas
  }

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2)
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else if (num === 0) {
      return 0
    }
  }

  render() {
    const { type } = this.props
    return (
      <div className="reports-container">
        <Spin tip="Cargando..." spinning={this.state.loading}>
          <div className="header-container">
            <p className="title">Lista de clusters</p>
            {/* {this.formRender()} */}
          </div>
          <div className="table-container">
            <Divider />
            <Table
              pagination={false}
              columns={this.columnas[type]}
              dataSource={this.state[type].resultado}
            ></Table>
          </div>
          <Modal
            title="Grabar cluster"
            className="operation-modal-style"
            visible={this.state.modalVisible}
            footer={null}
            onCancel={this.handleCancelModal}
          >
            <div className="header-container">
              <Form
                name="localization_form"
                layout="vertical"
                ref={this.formRef}
                initialValues={{
                  ...this.state.parametrosModal,
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="clusNeuroRiesgo"
                      label="CLUSTER NEURONAL DE RIESGO"
                    >
                      <Input
                        value={this.state.parametrosModal.clusNeuroRiesgo}
                        placeholder={this.state.parametrosModal.clusNeuroRiesgo}
                        disabled
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            parametrosModal: {
                              ...this.state.parametrosModal,
                              clusNeuroRiesgo: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="percentilCorrespondiente"
                      label="PERCENTIL CORRESPONDIENTE"
                    >
                      <Input
                        value={
                          this.state.parametrosModal.percentilCorrespondiente
                        }
                        placeholder={
                          this.state.parametrosModal.percentilCorrespondiente
                        }
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            parametrosModal: {
                              ...this.state.parametrosModal,
                              percentilCorrespondiente: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="limiteInferior" label="LIMITE INFERIOR">
                      <Input
                        value={this.state.parametrosModal.limiteInferior}
                        placeholder={this.state.parametrosModal.limiteInferior}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            parametrosModal: {
                              ...this.state.parametrosModal,
                              limiteInferior: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="limiteSuperior" label="LIMITE SUPERIOR">
                      <Input
                        value={this.state.parametrosModal.limiteSuperior}
                        placeholder={this.state.parametrosModal.limiteSuperior}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            parametrosModal: {
                              ...this.state.parametrosModal,
                              limiteSuperior: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={8}>
                    <Form.Item name="submit">
                      <Button className="btn btn-green" onClick={this.grabar}>
                        Grabar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  }
}

export default connect(mapStateToProps)(Content)
