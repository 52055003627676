import React, { Component } from 'react';
import { Modal } from 'antd';
import Title from 'antd/lib/skeleton/Title';


class DescuentoNotaModal extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleOnOk = () => {
    const { handleOk } = this.props;
    handleOk();
  }
  render() {
    const { visible, data } = this.props;

    return (
      <Modal
        title={<h3>Aviso</h3>}
        visible={visible}
        onCancel={this.handleOnClose}
        onOk={this.handleOnOk}
      >
      <h4>
      En caso de tener notas crédito estas van a ser descontadas al momento de la negociación.
      </h4>
      </Modal>
    );
  }
}

export default DescuentoNotaModal;
