import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Card,
  Checkbox,
} from 'antd';
import {
  FileExcelOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { initialState } from './initialState';
import { columns } from './columns';
import { form } from './form';
import {
  listaGruposEmpresariales,
  ejecutar,
} from '../../../../../services/admin/archivoNeuroscoreService';
import '../reports.scss';
import { connect } from 'react-redux';
import { REPORTS_DATES_REQUIRED_ERROR } from '../../../../../utilities/messages';
import Total from '../../../../proveedor/reports/total';

const { Option } = Select;

let intColumnas = 1;

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  columnas = columns;

  formulario = form;
  isBackButtonClicked = false;

  formRender = () => {
    const { type } = this.props;
    let formulario = '';
    return (formulario = (
      <Form name='content_form' layout='vertical' className='filters-container'>
        {this.formulario[type].map((fila, key) => {
          if (fila.length === 1) {
            return (
              <Row key={key} gutter={16}>
                <Col span={24}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={fila[1].nombre} label={fila[1].label}>
                    {this.inputType(fila[1])}
                  </Form.Item>
                </Col>
              </Row>
            );
          }
        })}
        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                className='form-btn btn-radius'
                type='primary'
                onClick={() => this.getData()}
              >
                Ejecutar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ));
  };

  inputType = (campo) => {
    const { type } = this.props;
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(e) => this.handleTextChange(e, stateName, type)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName, type)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
      case 'select_nosearch':
        input = (
          <Select
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onClick={() => {
              if (this.state[campo.selectType].length == 0)
                this[campo.loadFunction]();
            }}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  };

  handleTextChange = (e, campo, type) => {
    this.state[type][campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo, type) => {
    this.state[type][campo] = moment;
  };

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      switch (nombre) {
        case 'scGrupo':
          listaGruposEmpresariales(this.props.authToken, { name: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  grupoEmpresarialOptions: response.data
                    .filter((x) =>
                      x.dsNombres.toLowerCase().includes(value.toLowerCase())
                    )
                    .map((item) => {
                      return {
                        key: item.scGrupo,
                        nombre: item.dsNombres,
                      };
                    }),
                  fetching: false,
                });
              }
            }
          );
      }
    } else {
      switch (nombre) {
        case 'scPagador':
          this.setState({
            ...this.state,
            pagadorOptions: [],
          });
          break;
        case 'scConvenio':
          this.setState({
            ...this.state,
            convenioOptions: [],
          });
          break;
        case 'scGrupoEmpresarial':
          this.setState({
            ...this.state,
            grupoEmpresarialOptions: [],
          });
          break;
      }
    }
  };

  handleSelectChange = (value, stateName) => {
    this.state[this.props.type][stateName] = value;
    this.setState({ ...this.state });
  };

  getData = () => {
    this.state.resultado = [];
    switch (this.props.type) {
      case 'archive':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          ejecutar(this.props.authToken, this.state[this.props.type]).then(
            (response) => {
              this.setResponse(response);
            }
          );
        }
        break;
    }
  };

  componentDidMount() {}

  componentDidUpdate(props, state) {}

  setResponse = (response) => {
    this.setState({
      ...this.state,
      loading: false,
    });
    if (response.status != false && response.message) {
      notification.success({
        message: 'Ejecutando',
        description: response.message,
        duration: 4,
      });
    }
  };

  validateForm = () => {
    let filtros = this.state[this.props.type];
    let validationResult = true;
    let formulario = this.formulario;
    let nombres = [];
    let errores = [];
    Object.keys(filtros).forEach((nombre) => {
      if (
        formulario[this.props.type].find((x) =>
          x.find((y) => y.nombre === nombre && y.required === true)
        )
      ) {
        if (filtros[nombre] === '' || filtros[nombre] === null) {
          nombres.push(nombre);
          validationResult = false;
        }
      }
    });
    if (!validationResult) {
      formulario[this.props.type].forEach((formItem) => {
        formItem.forEach((key) => {
          if (nombres.includes(key.nombre)) {
            errores.push(key.label.replace('* ', ''));
          }
        });
      });
      if (errores.length > 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar los valores de los campos ${errores.reduce(
            (error, acum) => `${acum}, ${error}`
          )} para consultar este reporte`,
          duration: 4,
        });
      }
      if (errores.length == 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar el valor del campo ${errores[0]} para consultar`,
          duration: 4,
        });
      }
    }
    return validationResult;
  };

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  render() {
    const { type } = this.props;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <div className='header-container'>
            <p className='title'>Búsqueda</p>
            {this.formRender()}
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Content);
