import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import Dragger from 'antd/lib/upload/Dragger';
import React, { Component } from 'react';
import {
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import { tipoIds } from '../../../../../utilities/constants';
import { DATE_FORMAT } from '../../../../../utilities/messages';
import {
  sizeFileValidation,
  typeFileValidation,
} from '../../../../../utilities/validate/fileValidate';

const SELECT_PLACE_HOLDER = 'Seleccione...';

const { Option } = Select;

const initialState = {
  fileList: [],
  fileMessageErrors: [],
};

const rules = {
  scCompania: [{ required: true, message: requiredValueMessage() }],
  feInicio: [{ required: true, message: requiredValueMessage() }],
  feEscritura: [{ required: true, message: requiredValueMessage() }],
  dsNombresNotario: [{ max: 300, message: maxValueMessage(300) }],
  dsNombresRL: [
    { required: true, message: requiredValueMessage() },
    { max: 300, message: maxValueMessage(300) },
  ],
  dsApellidosRL: [
    { required: true, message: requiredValueMessage() },
    { max: 300, message: maxValueMessage(300) },
  ],
  dsCargoRL: [
    { required: true, message: requiredValueMessage() },
    { max: 300, message: maxValueMessage(300) },
  ],
  dsAreaRL: [
    { required: true, message: requiredValueMessage() },
    { max: 300, message: maxValueMessage(300) },
  ],
  dsApellidosNotario: [{ max: 300, message: maxValueMessage(300) }],
  dsIdNotario: [
    { max: 20, message: maxValueMessage(20) },
    {
      pattern: /^[0-9]*$/,
      message: formatValueMessage(),
    },
  ],
  idRL: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
    {
      pattern: /^[0-9]*$/,
      message: formatValueMessage(),
    },
  ],
  nmNotaria: [
    {
      pattern: /^[0-9]*$/,
      message: formatValueMessage(),
    },
  ],
  cdTipoIdRL: [{ required: true, message: requiredValueMessage() }],
  cdFacultadMandatoDE: [{ required: true, message: requiredValueMessage() }],
};

export const initialValuesForm = {
  feInicio: '',
  feVigencia: '',
  feEscritura: '',
  dsNombresRL: '',
  dsApellidosRL: '',
  cdTipoIdRL: '',
  idRL: '',
  dsCargoRL: '',
  dsAreaRL: '',
  dsNombresNotario: '',
  dsApellidosNotario: '',
  dsIdNotario: '',
  nmNotaria: '',
  cdFacultadMandatoDE: undefined,
  cdTipoMandanteDE: null,
  file: [],
};

class MandateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.form = React.createRef();
  }

  handleOnChangeUpload = (info) => {
    const fileList = info.fileList.length > 1 ? [info.file] : info.fileList;

    this.isValidFile(fileList);

    this.setState({
      ...this.state,
      fileList,
    });
  };

  handleOnFinish = async (formValue) => {
    const { onFinish } = this.props;
    const { fileList } = this.state;

    if (await this.isValidFile(fileList)) {
      onFinish({ ...formValue, file: fileList });
    }
  };

  isValidFile = async (fileList) => {
    const { fileRequired = true } = this.props;
    let fileMessageErrors = [];
    if (fileList.length === 0 && fileRequired) {
      fileMessageErrors.push('debe seleccionar un archivo');
    }

    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    try {
      await typeFileValidation(fileList, ['application/pdf']);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    this.setState({ ...this.state, fileMessageErrors });
    return fileMessageErrors.length === 0;
  };

  componentDidMount() {
    if (this.props.data) {
      const bankAccount = this.props.data;
      this.form.current.setFieldsValue({ ...bankAccount });
      this.setState({ ...this.state, fileList: [] });
    }
  }

  componentDidUpdate(props, state) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(props.data)) {
      this.form.current.resetFields();
      this.setState({ ...this.state, fileList: [] });

      this.form.current.setFieldsValue({
        ...this.props.data,
      });
    }
  }

  handleOnChangeCompania = (value) => {
    const { handleSearchReprLeg, companiaSelect } = this.props;

    const data = companiaSelect.options.find((el) => el.codigo === value);

    handleSearchReprLeg(data);
  };

  render() {
    const {
      handleOnSearchCompania,
      companiaSelect,
      facultadads,
      companiaEdit = true,
      children,
      uploadHidden = true,
      guardarHidden = true,
      estadoHidden = true,
    } = this.props;

    const { fileList, fileMessageErrors } = this.state;

    return (
      <Form
        name='content_form'
        layout='vertical'
        ref={this.form}
        onFinish={this.handleOnFinish}
        initialValues={initialValuesForm}
      >
        <Row gutter={[16, 32]}>
          <Col>
            <Space align='baseline' className='sub-title'>
              DATOS REPRESENTANTE LEGAL
            </Space>
          </Col>
        </Row>
        <Divider />

        <Row gutter={[16, 32]}>
          <Col span={9}>
            {companiaEdit && (
              <Form.Item
                name='scCompania'
                label='COMPAÑIA'
                rules={rules['scCompania']}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleOnSearchCompania}
                  onChange={this.handleOnChangeCompania}
                  notFoundContent={
                    companiaSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {companiaSelect.options.map((d) => (
                    <Option key={d.codigo}>{d.descripcion}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {!companiaEdit && (
              <>
                <Form.Item
                  name='nombreCompania'
                  label='COMPAÑIA'
                  rules={rules['scCompania']}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item name='scCompania' hidden={true}>
                  <Input />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>

        <Row gutter={[16, 32]}>
          <Col span={9}>
            <Form.Item
              name='dsNombresRL'
              label='NOMBRE'
              rules={rules['dsNombresRL']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name='dsApellidosRL'
              label='APELLIDO'
              rules={rules['dsApellidosRL']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 32]}>
          <Col span={9}>
            <Form.Item name='idRL' label='IDENTIFICACIÓN' rules={rules['idRL']}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name='cdTipoIdRL'
              label='TIPO IDENTIFICACIÓN'
              rules={rules['cdTipoIdRL']}
            >
              <Select name='cdTipoIdRL' allowClear>
                {tipoIds.map((el) => (
                  <Option value={el.key}>{el.description}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 32]}>
          <Col span={9}>
            <Form.Item
              name='dsCargoRL'
              label='CARGO'
              rules={rules['dsCargoRL']}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item
              name='dsAreaRL'
              label='ÁREA O DEPARTAMENTO'
              rules={rules['dsAreaRL']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 32]}>
          <Col>
            <Space align='baseline' className='sub-title'>
              DATOS NOTARIO
            </Space>
          </Col>
        </Row>
        <Divider />

        <Row gutter={[16, 32]}>
          <Col span={9}>
            <Form.Item
              name='dsNombresNotario'
              label='NOMBRE'
              rules={rules['dsNombresNotario']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name='dsApellidosNotario'
              label='APELLIDO'
              rules={rules['dsApellidosNotario']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 32]}>
          <Col span={9}>
            <Form.Item
              name='dsIdNotario'
              label='IDENTIFICACIÓN'
              rules={rules['dsIdNotario']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name='nmNotaria'
              label='NÚMERO DE NOTARÍA'
              rules={rules['nmNotaria']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 32]}>
          <Col span={9}>
            <Form.Item
              name='feInicio'
              label='FECHA DE INICIO'
              rules={rules['feInicio']}
            >
              <DatePicker
                className='form-datepicker radius module-selector-select'
                placeholder='Seleccione la fecha'
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name='feEscritura'
              label='FECHA DE ESCRITURA'
              rules={rules['feEscritura']}
            >
              <DatePicker
                className='form-datepicker radius module-selector-select'
                placeholder='Seleccione la fecha'
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 32]}>
          <Col span={9}>
            <Form.Item name='feVigencia' label='FECHA DE VIGENCIA'>
              <DatePicker
                className='form-datepicker radius module-selector-select'
                placeholder='Seleccione la fecha'
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name='cdFacultadMandatoDE'
              label='FALCULTAD DE MANDATO'
              rules={rules['cdFacultadMandatoDE']}
            >
              <Select allowClear>
                {facultadads &&
                  facultadads.map((d) => (
                    <Select.Option key={d.codigo} value={d.codigo}>
                      {d.descripcion}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {estadoHidden && (
          <Row gutter={[16, 32]}>
            <Col span={9}>
              <Form.Item name='dsEstadoMandato' label='Estado Mandato'>
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
        )}

        {uploadHidden && (
          <Row gutter={[16, 32]}>
            <Col flex='auto'>
              <Form.Item name='file' label='CARGAR MANDATO'>
                <Form.Item noStyle>
                  <Dragger
                    beforeUpload={() => {
                      return false;
                    }}
                    fileList={fileList}
                    onChange={this.handleOnChangeUpload}
                    maxCount={0}
                    multiple={false}
                    accept='application/pdf'
                  >
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Suelta el archivo aquí o selecciona alguno.
                    </p>
                  </Dragger>
                  {fileMessageErrors.map((value) => (
                    <Text type='danger'>{value}</Text>
                  ))}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          {children}
          {guardarHidden && (
            <Col span={6}>
              <Button
                style={{ width: '90%' }}
                className='btn btn-green'
                type='primary'
                htmlType='submit'
              >
                GUARDAR
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

export default MandateForm;
