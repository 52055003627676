import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Select,
  Input,
  Button,
  Checkbox,
  Divider,
  Spin,
  Table,
  notification,
} from 'antd';
import './discounts.scss';
import { SettingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  listarDescuentosAutomaticos,
  listarProveedores,
} from '../../../../services/admin/discountsService';
import { EMPTY_SELECT } from '../../../../utilities/messages';
import DiscountsDetail from './discountsDetail';
import Title from 'antd/lib/typography/Title';
import { sorterText } from '../../../../utilities/textTools';
import TableReport from '../../../../components/tableReport/tableReport';

const { Option } = Select;
const initialState = {
  filtros: {
    nombre: '',
    offset: 0,
    limit: 10,
    total: 0,
    sort: '',

  },
  resultado: [],
  nombre: '',
  offset: 0,
  total: 0,
  currentPage: 1,
  loading: false,
  isNew: true,
  scProveedor: '',
  snProveedor: '',
  antpost: [
    { codigo: 'A', descripcion: 'Anterior' },
    { codigo: 'P', descripcion: 'Posterior' },
  ],
};
class Discounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      resultado: [],
      showDetail: false,
      isNew: false,
      agreement: {},
      update: false,
    };
    this.formRef = React.createRef();
    this.update = this.update.bind(this);
  }

  sorter = (a, b) =>
    isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b;

  columns = [
    {
      title: 'NOMBRE CONVENIO',
      dataIndex: 'nombreConvenio',
      sorter: (a, b) => sorterText(a.nombreConvenio, b.nombreConvenio),
      showSorterTooltip: false,
    },
    {
      title: 'PAGADOR',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
      showSorterTooltip: false,
    },
    {
      title: 'ESTADO',
      dataIndex: 'cdSnDescAuto',
      sorter: (a, b) => sorterText(a.cdSnDescAuto, b.cdSnDescAuto),
      showSorterTooltip: false,
      key: 'cdSnDescAuto',
      render: (key, item) => (
        <label
          className={
            item.snDescAuto === 'S'
              ? 'aggrement-row aggrement-row-active'
              : 'aggrement-row aggrement-row-inactive'
          }
        >
          {' '}
          {item.snDescAuto === 'S' ? 'Activo' : 'Inactivo'}{' '}
        </label>
      ),
      align: 'center',
    },
    {
      title: 'CONFIGURACIÓN',
      dataIndex: 'configuration',
      key: 'configuration',
      render: (key, item) => (
        <SettingOutlined
          className='agree-icon-size'
          onClick={() => this.mostrarDetalle(item)}
        />
      ),
      align: 'center',
    },
  ];

  mostrarDetalle(e) {
    this.setState({
      ...this.state,
      showDetail: true,
      agreement: this.state.resultado.find((x) => x.scConvenio == e.scConvenio),
    });
    this._scrollToView();
  }

  _scrollToView() {
    setTimeout(() => {
      var section = document.getElementById('agreements-selection-section');
      if (section) {
        section.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }, 500);
  }

  update() {
    this.setState({
      ...this.state,
      update: true,
    });
  }

  // componentDidUpdate(props, state) {
  //   if (this.state.update) {
  //     this.setState({
  //       ...this.state,
  //       update: false,
  //     });
  //   }
  // }

  // columns = [
  //   {
  //     title: 'NOMBRE CONVENIO',
  //     dataIndex: 'nombreConvenio',
  //     sorter: (a, b) => a.nombreConvenio - b.nombreConvenio,
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: 'PAGADOR',
  //     dataIndex: 'nombrePagador',
  //     sorter: (a, b) => a.nombrePagador - b.nombrePagador,
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: 'CUENTA',
  //     dataIndex: '',
  //     sorter: (a, b) => this.sorter(a.dsSnDescAuto, b.dsSnDescAuto),
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: 'DESC. AUTOMATICO',
  //     dataIndex: 'snDescAuto',
  //     render: (object) => (
  //       <Checkbox
  //         className='check'
  //         checked={object == 'S' ? true : false}
  //       ></Checkbox>
  //     ),
  //     sorter: (a, b) => a.snDescAuto - b.snDescAuto,
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: 'DIAS DESC. AUTOMATICO',
  //     dataIndex: 'nmDiasDescAuto',
  //     sorter: (a, b) => this.sorter(a.nmDiasDescAuto, b.nmDiasDescAuto),
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: 'ANTERIOR/POSTERIOR',
  //     dataIndex: 'cdDiaDescAuto',
  //     render: (object) => (
  //       <Select value={object} disabled>
  //         {this.state.antpost.map((item) => (
  //           <Select.Option key={item.codigo} value={item.codigo}>
  //             {item.descripcion}
  //           </Select.Option>
  //         ))}
  //       </Select>
  //     ),
  //     sorter: (a, b) => this.sorter(a.cdDiaDescAuto, b.cdDiaDescAuto),
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: 'TASA DESC. AUTOMATICO',
  //     dataIndex: 'poDescAuto',
  //     sorter: (a, b) => this.sorter(a.poDescAuto, b.poDescAuto),
  //     showSorterTooltip: false,
  //   },
  // ];

  componentDidMount() {
    this.props.menuHandler('42');
  }

  getData = () => {
    if (
      this.state.scProveedor == '' ||
      this.state.scProveedor == null ||
      this.state.scProveedor == 'undefined'
    ) {
      notification.info({
        message: 'Atención',
        description: 'No hay proveedor seleccionado',
        duration: 6,
      });
    } else {
      this.setState({
        ...this.state,
        loading: true,
      });
      listarDescuentosAutomaticos(
        this.props.authToken,
        this.state.scProveedor,
        this.getFiltro(),
      )
        .then((response) => {
          if (response.data) {
            this.state.resultado = response.data;
            this.state.filtros.total = response.metadata.count;
          }
          this.setState({
            ...this.state,
            loading: false,
          });
        })
        .catch(() =>
          this.setState({
            ...this.state,
            loading: false,
          })
        );
    }
  };
  getFiltro = () => {
    const { filtros, limit, offset, sort } = this.state;
    return {
      ...filtros,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  handleOnSearchProveedor = (value) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      listarProveedores(this.props.authToken, { nombre: value }).then(
        (response) => {
          if (Array.isArray(response.data)) {
            this.setState({
              ...this.state,
              proveedores: response.data.map((item) => {
                return {
                  codigo: item.codigo,
                  descripcion: item.descripcion || item.nombre,
                };
              }),
              fetching: false,
            });
          }
        }
      );
    } else {
      this.setState({
        ...this.state,
        proveedores: [],
      });
    }
  };

  render() {
    const { loading, currentPage, limit, total, resumen, resultado } =
      this.state;
    return (
      <Spin tip='Cargando...' spinning={this.state.loading}>
        <div className='discounts-style agreements'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <p className='title'>Búsqueda</p>
              <Form
                name='operation_form'
                layout='vertical'
                initialValues={{ snProveedor: undefined }}
                ref={this.formRef}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name='snProveedor' label='PROVEEDOR'>
                      <Select
                        placeholder={EMPTY_SELECT}
                        showSearch
                        allowClear
                        value={this.state.snProveedor}
                        onChange={(e, option) => {
                          if (option) {
                            this.setState({
                              ...this.state,
                              scProveedor: option.key,
                              snProveedor: e,
                            });
                          } else {
                            this.setState({
                              ...this.state,
                              scProveedor: null,
                              snProveedor: e,
                            });
                          }
                        }}
                        onSearch={(value) =>
                          this.handleOnSearchProveedor(value)
                        }
                        notFoundContent={
                          this.state.fetching ? <Spin size='small' /> : null
                        }
                      >
                        {Array.isArray(this.state.proveedores) &&
                          this.state.proveedores.map((item) => (
                            <Select.Option
                              key={item.codigo}
                              value={item.descripcion}
                            >
                              {item.descripcion}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item>
                      <Button
                        className='btn btn-green'
                        type='primary'
                        htmlType='submit'
                        onClick={() => {
                          this.getData();
                        }}
                      >
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div>
              <p className='ptable'>Lista de convenios</p>
            </div>
            <div className='table-container'>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={this.state.filtros.total}
                    resumen={resumen}
                    columns={this.columns}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
          <div id='agreements-selection-section'></div>
          <Row>
            {this.state.showDetail ? (
              <Title level={4} className='agree-marginbot'>
                Detalles del convenio de descuento automático
              </Title>
            ) : null}
          </Row>
          {this.state.showDetail ? (
            <DiscountsDetail
              scConvenio={this.state.agreement.scConvenio}
              name={this.state.agreement.nombreConvenio}
              snDescAuto={this.state.agreement.snDescAuto}
              checked={this.state.agreement.snDescAuto == 'S'}
              daysAuto={this.state.agreement.nmDiasDescAuto}
              daysBusiness={this.state.agreement.cdDiaDescAuto}
              preference={this.state.agreement.descAutoPreferencia} //ver que hacer con esta
              selectedAccount={this.state.agreement.scCuenta} //ver que hacer con esta
              typeAgreement={this.state.agreement.tipoConvenio}
              accounts={this.state.agreement.fondeadorCuentaDto}
              company={this.state.scProveedor}
              handler={this.update}
            />
          ) : null}
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Discounts);
