import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Select, Modal, Spin, Input } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { maxLength } from '../../../../../utilities/validate/textValidate';

const { Option } = Select;

const AchPaymentsModal = ({
  loteSeleccionado,
  handleModalOk,
  handleOnSearch,
  bancoSelect,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    forceUpdate({});
  }, []);

  const handleOk = async () => {
    const values = await form.validateFields();
    form.resetFields();
    setVisible(false);
    handleModalOk(values);
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <>
      <Button
        size='small'
        className='btn-info'
        title='Exportar archivo plano'
        onClick={() => {
          setVisible(true);
        }}
        icon={<FileOutlined />}
      ></Button>
      <Modal
        title={`Exportar a archivo plano lote #${loteSeleccionado}`}
        visible={visible}
        className='modal-style'
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ type: 'default', className: 'cancel-btn btn-radius' }}
        okButtonProps={{ type: 'primary', className: 'ok-btn btn-radius' }}
      >
        <div className='header-container'>
          <Form
            name='content_form'
            layout='vertical'
            className='filters-container'
            form={form}
          >
            <div className='table-container'>
              <div className='header-container'>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='cdBanco'
                      label='Banco'
                      rules={[
                        {
                          required: true,
                          message: 'por favor seleccione un banco',
                        },
                      ]}
                    >
                      <Select
                        onSearch={handleOnSearch}
                        showSearch
                        allowClear
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        notFoundContent={
                          bancoSelect.fetching ? <Spin size='small' /> : null
                        }
                      >
                        {bancoSelect.options.map((d) => (
                          <Option key={d.codigo} value={d.codigo}>
                            {d.descripcion}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdTipoCuenta'
                      label='Tipo de cuenta'
                      rules={[
                        {
                          required: true,
                          message: 'por favor seleccione un tipo de cuenta',
                        },
                      ]}
                    >
                      <Select>
                        <Option key={0} value={'A'}>
                          Ahorros
                        </Option>
                        <Option key={1} value={'C'}>
                          Corriente
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name='numeroCuenta'
                      label='Número de cuenta'
                      rules={[
                        {
                          required: true,
                          message: 'por favor ingrese un número de cuenta',
                        },
                        {
                          validator: (_, value) =>
                            maxLength(value, 100, 'numero de cuenta'),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AchPaymentsModal;
