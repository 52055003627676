import { BorderOutlined, CheckOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Switch,
} from 'antd';
import React, { Component } from 'react';
import TableReport from '../../../../../components/tableReport/tableReport';
import {
  exportarExcelConvenioProveedores,
  listaCategoriasProveedor,
  listarConveniosProveedores,
  listarFondeadoresAprobados,
  listarTiposPago,
  listaTipoTasaProveedor,
} from '../../../../../services/admin/operation';
import { setExcel } from '../../../../../utilities/downloadTools';
import { BUSQUEDA_TITLE } from '../../../../../utilities/messages';
import {
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';
import SupplierEditModal from './supplierEditModal/supplierEditModal';
import SupplierNewModal from './supplierNewModal/supplierNewModal';

const IDENTIFICATION_FILTRO = 'Identificación';
const NAME_FILTRO = 'Nombre/Razón social';

const initialState = {
  loading: false,
  visibleEditModal: false,
  form: {
    nombre: '',
    id: '',
  },
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  sort: '',
  proveedor: {},
  filtroSeleccionado: NAME_FILTRO,
  tipoTasaProveedor: [],
  categorias: [],
  tipoPagos: [],
  fondeadores: [],
};

class Suppliers extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
    this.columnas = [
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'proveedorId',
        sorter: (a, b) => sorterText(a.proveedorId, b.proveedorId),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'proveedorNombre',
        sorter: (a, b) => sorterText(a.proveedorNombre, b.proveedorNombre),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoRegistro',
        sorter: (a, b) => sorterText(a.dsEstadoRegistro, b.dsEstadoRegistro),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA INGRESO',
        dataIndex: 'feModifica',
        sorter: (a, b) => sorterDate(a.feModifica, b.feModifica),
        showSorterTooltip: false,
      },
      {
        title: 'D.A.',
        dataIndex: 'cdSnDescAuto',
        sorter: (a, b) => sorterText(a.cdSnDescAuto, b.cdSnDescAuto),
        showSorterTooltip: false,
        render: (record) =>
          record === 'S' ? (
            <CheckOutlined
              style={{ fontSize: '24px', color: '#09d96d' }}
              twoToneColor='#09d96d'
            />
          ) : (
            <BorderOutlined style={{ fontSize: '24px' }} />
          ),
      },
      {
        title: 'DIA D.A.',
        dataIndex: 'nmDiasDescAuto',
        sorter: (a, b) => a.nmDiasDescAuto - b.nmDiasDescAuto,
        showSorterTooltip: false,
      },
      {
        title: 'TASA',
        dataIndex: 'poDescuentoProveedor',
        sorter: (a, b) => a.poDescuentoProveedor - b.poDescuentoProveedor,
        render: (text) => <span>{percentageFormat(text)}</span>,
        showSorterTooltip: false,
      },
      {
        title: '',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showModal(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
    this.formSearch = React.createRef();
  }

  showModal = async (proveedor) => {
    await this.handleOnOpenModal();
    this.setState({
      ...this.state,
      visibleEditModal: true,
      proveedor,
    });
  };

  getData = async () => {
    const { scConvenio, authToken } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarConveniosProveedores(
        authToken,
        scConvenio,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    const { scConvenio, authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await exportarExcelConvenioProveedores(
        authToken,
        scConvenio,
        this.getFiltro()
      );

      setExcel(response, 'lista_convenios_proveedores');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;

    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleSwitchChange = (check) => {
    let nombre = NAME_FILTRO;
    if (check) {
      nombre = IDENTIFICATION_FILTRO;
    }

    this.formSearch.current.setFieldsValue({
      tipoSearch: check,
      nombre: '',
      id: '',
    });

    this.setState({
      ...this.state,
      filtroSeleccionado: nombre,
      filtros: {
        ...this.state.filtros,
        nombre: '',
        id: '',
      },
    });
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      resultado: [],
      total: initialState.total,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.getData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  handleModalOnFinish = () => {
    this.setState({
      ...this.state,
      visibleEditModal: false,
    });
    this.getData();
  };

  handleEditModalOnClose = () => {
    this.setState({ ...this.state, visibleEditModal: false });
  };

  loadDataModal = async () => {
    const { authToken, scConvenio } = this.props;

    this.setState({ ...this.state, loading: true });
    try {
      const response = await listarConveniosProveedores(
        authToken,
        scConvenio,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        loading: false,
        resultado: response.data,
        total: response.metadata.count,
      });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  };

  handleOnOpenModal = async () => {
    const { authToken, scConvenio, cdTipoConvenio, scPagador } = this.props;

    const { tipoTasaProveedor, categorias, tipoPagos, fondeadores } =
      this.state;

    this.setState({ ...this.state, loading: true });

    let tipoTasaProveedorTemp = [...tipoTasaProveedor];
    let categoriasTemp = [...categorias];
    let tipoPagosTemp = [...tipoPagos];
    let fondeadoresTemp = fondeadores ? [...fondeadores] : [];

    try {
      if (!tipoTasaProveedorTemp || tipoTasaProveedorTemp.length === 0) {
        const tipoTasaProveedorRes = await listaTipoTasaProveedor(authToken);
        tipoTasaProveedorTemp = [...tipoTasaProveedorRes.data];
      }

      if (!categoriasTemp || categoriasTemp.length === 0) {
        const categoriasRes = await listaCategoriasProveedor(
          authToken,
          cdTipoConvenio
        );
        categoriasTemp = [...categoriasRes.data];
      }

      if (!tipoPagosTemp || tipoPagosTemp.length === 0) {
        const tipoPagosRes = await listarTiposPago(authToken, scPagador);
        tipoPagosTemp = [...tipoPagosRes.data];
      }

      if (!fondeadoresTemp || fondeadoresTemp.length === 0) {
        const fondeadoresRes = await listarFondeadoresAprobados(
          authToken,
          scConvenio
        );
        fondeadoresTemp = [...fondeadoresRes.data];
      }

      this.setState({
        ...this.state,
        loading: false,
        tipoTasaProveedor: tipoTasaProveedorTemp,
        categorias: categoriasTemp,
        tipoPagos: tipoPagosTemp,
        fondeadores: fondeadoresTemp,
      });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  };

  componentDidMount() {
    this.loadDataModal();
  }

  componentDidUpdate(props, state) {
    if (props.cdTipoConvenio !== this.props.cdTipoConvenio) {
      this.loadDataModal();
    }
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resultado,
      visibleEditModal,
      proveedor,
      filtroSeleccionado,
      tipoTasaProveedor,
      categorias,
      tipoPagos,
      fondeadores,
    } = this.state;
    const {
      authToken,
      scConvenio,
      scPagador,
      cdTipoConvenio,
      children,
    } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='header-container'>
          <Row gutter={[16, 32]}>
            <Col span={24}>
              <Space align='baseline' className='title'>
                {BUSQUEDA_TITLE}
              </Space>
            </Col>
          </Row>
          <Form
            layout='vertical'
            ref={this.formSearch}
            onFinish={this.handleSubmit}
          >
            <Row gutter={[16, 32]}>
              <Col span={6}>
                <Form.Item name='tipoSearch' label='Filtro'>
                  <Switch
                    checkedChildren={IDENTIFICATION_FILTRO}
                    unCheckedChildren={NAME_FILTRO}
                    onChange={this.handleSwitchChange}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                {filtroSeleccionado === NAME_FILTRO && (
                  <Form.Item name='nombre' label={NAME_FILTRO}>
                    <Input />
                  </Form.Item>
                )}
                {filtroSeleccionado === IDENTIFICATION_FILTRO && (
                  <Form.Item
                    name='id'
                    label={IDENTIFICATION_FILTRO}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value !== undefined &&
                            value !== '' &&
                            isNaN(value)
                          ) {
                            return Promise.reject(
                              new Error(
                                `El campo Identificación debe tener un valor numérico`
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </Col>

              <Col span={6}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Buscar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Row gutter={[16, 32]} align='middle'>
          <Col flex='auto'>
            <Space align='baseline' className='sub-title'>
              Lista de Proveedores
            </Space>
          </Col>
          <Col flex='none'>
            <Space align='baseline'>
              <SupplierNewModal
                onOpen={this.handleOnOpenModal}
                authToken={authToken}
                scConvenio={scConvenio}
                cdTipoConvenio={cdTipoConvenio}
                scPagador={scPagador}
                tipoTasaProveedor={tipoTasaProveedor}
                categorias={categorias}
                tipoPagos={tipoPagos}
                fondeadores={fondeadores}
                onFinish={this.handleModalOnFinish}
              />
            </Space>
          </Col>
          <Col flex='none'>
            <Space align='baseline'>
              <Button type='link' onClick={this.exportExcel}>
                Exportar a excel
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col flex='auto'>
            <div className='table-container'>
              <TableReport
                currentPage={currentPage}
                limit={limit}
                total={total}
                columns={this.columnas}
                data={resultado}
                handleChange={this.handleChange}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>{children}</Row>

     {visibleEditModal &&   <SupplierEditModal
          data={proveedor}
          visible={visibleEditModal}
          authToken={authToken}
          scConvenio={scConvenio}
          scPagador={scPagador}
          cdTipoConvenio={cdTipoConvenio}
          tipoTasaProveedor={tipoTasaProveedor}
          categorias={categorias}
          tipoPagos={tipoPagos}
          fondeadores={fondeadores}
          onFinish={this.handleModalOnFinish}
          onClose={this.handleEditModalOnClose}
        />}
      </Spin>
    );
  }
}

export default Suppliers;
