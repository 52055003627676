import { Col, Form, Row, Select } from 'antd';
import React from 'react'
import { requiredValueMessage } from '../../../../utilities/admin_validation_messages';


const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
    scClientes: [{ required: true, message: requiredValueMessage() }] 
  };

export const SelectComponet = ({pagadorSelect}) => {
  return (
    <Row gutter={16}>
     <Col flex={'auto'}>     
        <Form.Item
            name='scClientes'
            label='Selecciona tus clientes (Selecciona: Otros, si tu cliente no está en la lista)'
            rules={rules['scClientes']}
        >
                <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    mode='multiple'
                    allowClear
                    style={{ width: '100%' }}
                    filterOption={(input, option) => {
                    return (
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                    }}
                >
                    {pagadorSelect &&
                    pagadorSelect.map((grupo) => (
                        <Select.Option key={grupo.codigo} value={grupo.codigo}>
                        {grupo.descripcion}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>
    </Row>
  )
}
