import axios from 'axios'
import { middleWare } from '../middleware'

const baseUrl = process.env.REACT_APP_SERVICES_URL_PAGADOR



export const downloadTemplateAPI = (authtoken, company) => {
    return middleWare(axios({
      url: `${baseUrl}/api/affi/${company}/plantillaPy`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      },
      data: null
    }))
  }