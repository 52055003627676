import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { Component } from 'react';
import {
  emailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import { isEqualsToObject } from '../../../../../utilities/compare';
import { tipoIds } from '../../../../../utilities/constants';

export const initialFormValue = {
  scPersona: undefined,
  cdTipoId: '',
  id: '',
  prNombre: '',
  segNombre: '',
  prApellido: '',
  segApellido: '',
  email: '',
  telefono1: '',
  cdPerfil: '',
};

const rules = {
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: /^[0-9a-zA-Z-]*$/,
      message: formatValueMessage(),
    },
  ],
  prNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segNombre: [{ max: 20, message: maxValueMessage(20) }],
  prApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  email: [
    { required: true, message: requiredValueMessage() },
    { max: 100, message: maxValueMessage(100) },
    { pattern: emailPattern, message: formatValueMessage() },
  ],
  telefono1: [
    { required: true, message: requiredValueMessage() },
    { max: 50, message: maxValueMessage(50) },
  ],
  cdPerfil: [{ required: true, message: requiredValueMessage() }],
};

class UsersForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish({
      ...formValue,
    });
  };

  componentDidMount() {
    if (this.props.data) {
      const user = this.props.data;
      this.form.current.setFieldsValue({
        ...user,
      });
    }
  }

  componentDidUpdate(props, state) {
    if (props.reset !== this.props.reset && this.props.reset) {
      this.form.current.resetFields();
    }

    if (
      !isEqualsToObject(props.data, this.props.data) ||
      props.cdPerfil !== this.props.cdPerfil
    ) {
      this.form.current.setFieldsValue({
        ...this.props.data,
        cdPerfil: this.props.cdPerfil,
      });
    }
  }

  render() {
    const { buscarContacto, perfiles = [] } = this.props;

    return (
      <div className='header-container'>
        <Form layout='vertical' ref={this.form} onFinish={this.onFinish}>
          <Form.Item name='scPersona' hidden='true'>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoId'
                label='TIPO DE IDENTIFICACIÓN'
                rules={rules['cdTipoId'] || ''}
              >
                <Select>
                  {tipoIds.map(({ key, description }) => (
                    <Select.Option key={key} value={key}>
                      {description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='id'
                label='IDENTIFICACIÓN'
                rules={rules['id'] || ''}
              >
                <Input onBlur={(e) => buscarContacto(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prNombre'
                label='PRIMER NOMBRE'
                rules={rules['prNombre'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segNombre'
                label='SEGUNDO NOMBRE'
                rules={rules['segNombre'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prApellido'
                label='PRIMER APELLIDO'
                rules={rules['prApellido'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segApellido'
                label='SEGUNDO APELLIDO'
                rules={rules['segApellido'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col flex='auto'>
              <Form.Item
                name='email'
                label='CORREO ELECTRÓNICO'
                rules={rules['email'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='telefono1'
                label='TELÉFONO'
                rules={rules['telefono1'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdPerfil'
                label='Perfil de Usuario'
                rules={rules['cdPerfil'] || ''}
              >
                <Select>
                  {perfiles.map(({ codigo, descripcion }) => (
                    <Select.Option key={codigo} value={codigo}>
                      {descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default UsersForm;
