import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
  Upload,
} from 'antd';
import React, { Component } from 'react';

import billServices from '../../../services/proveedor/billServices';
import {
  formatValueMessage,
  maxValueMessage,
  minValueMessage,
  requiredValueMessage,
} from '../../../utilities/admin_validation_messages';
import {
  sizeFileValidation,
  typeFileValidation,
} from '../../../utilities/validate/fileValidate';

const { Dragger } = Upload;
const { Text } = Typography;

const initialValues = {
  fileList: [],
  fileMessageErrors: [],
  tipoCarga: 'TA',
  loading: false,
};

const rules = {
  cufe: [
    { required: true, message: requiredValueMessage() },
    { max: 96, message: maxValueMessage(96) },
    { min: 96, message: minValueMessage(96) },
    {
      pattern: /^[a-z0-9]*$/,
      message: formatValueMessage(),
    },
  ],
};

const tiposCarga = [
  {
    codigo: 'TA',
    descripcion: 'Código Cufe',
  },
  {
    codigo: 'XML',
    descripcion: 'Archivo XML',
  },
];

class CufeModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialValues;

    this.formRef = React.createRef();
  }
  componentDidUpdate(props, state) {
    if (props.visible !== this.props.visible) {
      this.formRef.current.setFieldsValue({
        cufe: this.props.oferta.cufe,
        tipoCarga: 'TA',
      });
      this.setState({
        ...this.state,
        fileList: [],
        fileMessageErrors: [],
        tipoCarga: 'TA',
      });
    }
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleOnFinish = async (form) => {
    const { onClose, oferta, token, company } = this.props;
    const { fileList, tipoCarga } = this.state;

    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      if (tipoCarga === 'XML') {
        const valid = await this.isValidFile(fileList);
        if (!valid) {
          this.setState({
            ...this.state,
            loading: false,
          });
          return;
        }
      }
      const response = await billServices.addCufe(token, company, {
        ...form,
        scFactura: oferta.scFactura,
        file: fileList,
      });

      onClose({
        ...oferta,
        cufe: response.location,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleSelectOnChage = (e) => {
    this.setState({
      ...this.state,
      tipoCarga: e,
    });
  };

  handleOnChangeUpload = (info) => {
    const fileList = info.fileList.length > 1 ? [info.file] : info.fileList;

    this.isValidFile(fileList);

    this.setState({
      ...this.state,
      fileList,
    });
  };

  isValidFile = async (fileList) => {
    const { edit = false } = this.props;
    let fileMessageErrors = [];

    if (fileList.length === 0 && !edit) {
      fileMessageErrors.push('debe seleccionar un archivo');
    }

    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    try {
      await typeFileValidation(fileList, ['application/xml', 'text/xml']);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    this.setState({ ...this.state, fileMessageErrors });
    return fileMessageErrors.length === 0;
  };

  render() {
    const { visible, oferta } = this.props;
    const { fileList, fileMessageErrors, tipoCarga, loading } = this.state;

    return (
      <Modal
        title={`Factura # ${oferta.cdFactura}`}
        visible={visible}
        onCancel={this.handleOnClose}
        width={500}
        footer={false}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <Form
            layout='vertical'
            ref={this.formRef}
            onFinish={this.handleOnFinish}
          >
            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item
                  name='tipoCarga'
                  label='Tipo de registro'
                  rules={rules['tipoCarga'] || ''}
                >
                  <Select
                    className='module-selector-select'
                    placeholder='Seleccione'
                    onChange={this.handleSelectOnChage}
                  >
                    {tiposCarga.map((tipo, i) => (
                      <Select.Option key={i} value={tipo.codigo}>
                        {tipo.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {tipoCarga === 'TA' && (
              <Row gutter={16}>
                <Col flex='auto'>
                  <Form.Item
                    name='cufe'
                    label='CUFE #'
                    rules={rules['cufe'] || ''}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {tipoCarga === 'XML' && (
              <Row gutter={16}>
                <Col flex='auto'>
                  <Form.Item name='file' label='ARCHIVO XML' required>
                    <Form.Item noStyle>
                      <Dragger
                        beforeUpload={() => {
                          return false;
                        }}
                        fileList={fileList}
                        onChange={this.handleOnChangeUpload}
                        maxCount={0}
                        multiple={false}
                        accept='application/xml,text/xml'
                      >
                        <p className='ant-upload-drag-icon'>
                          <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>
                          Suelta el archivo aquí o selecciona alguno.
                        </p>
                      </Dragger>
                      {fileMessageErrors.map((value) => (
                        <Text type='danger'>{value}</Text>
                      ))}
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row justify='center'>
              <Col span={8}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Grabar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default CufeModal;
