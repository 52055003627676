import { Modal, notification, Spin } from 'antd';
import Title from 'antd/lib/skeleton/Title';
import React, { Component } from 'react';
import {
  getPerfil,
  listaPerfilesAdmin,
  updateUserProfile,
} from '../../../../../services/admin/profileService';
import { editarUsuario } from '../../../../../services/admin/usersService';
import { isEqualsToObject } from '../../../../../utilities/compare';
import FichaCliente from '../../../../vinculacion/FichaCliente/fichaCliente';

const USERS_TITLE = 'Usuarios';

const initialState = {
  loading: false,
  perfiles: [],
  cdPerfil: undefined,
};

class VinculacionEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnFinish = async (formValue) => {
    const { onFinish, authToken } = this.props;
    this.setState({ ...this.state, loading: true });

    try {
      const response = await editarUsuario(authToken, formValue);
      await updateUserProfile(authToken, {
        ...formValue,
        userSeguridad: formValue.id,
      });

      notification.success({
        message: USERS_TITLE,
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        visible: false,
        loading: false,
      });

      onFinish({ ...formValue, scPersona: response.location });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getData = async () => {
    const { authToken, data } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const perfilesResponse = await listaPerfilesAdmin(authToken);
      const userProfileResponse = await getPerfil(
        authToken,
        data.userSeguridad
      );

      this.setState({
        ...this.state,
        perfiles: perfilesResponse.data,
        loading: false,
        cdPerfil: userProfileResponse.data
          ? userProfileResponse.data.cdPerfil
          : undefined,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };
  render() {
    const { loading, perfiles, cdPerfil } = this.state;

    const { visible, onClose, data } = this.props;

    return (
      <Modal
        title='Editar usuario'
        visible={visible}
        className='modal-style'
        footer={null}
        onCancel={onClose}
      >
        <h2>Vinculacion</h2>

      <FichaCliente/>

      </Modal>
    );
  }
}

export default VinculacionEditModal;
