import React, { Component } from 'react';
import {
  Button,
  Spin,
  Modal,
  Form,
  Row,
  Col,
  Upload,
  Typography,
  Space,
  notification,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  sizeFileValidation,
  typeFileValidation,
} from '../../../../../utilities/validate/fileValidate';
import { cargarArchivo } from '../../../../../services/admin/nonBusinessDaysService';

const { Dragger } = Upload;
const { Text } = Typography;
const MESSAGE_TITLE = 'Días no habiles';

const initialState = {
  loading: false,
  fileList: [],
  fileMessageErrors: [],
};

class NonBusinessDaysModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnFinish = async (tipoCarga) => {
    const { onFinish, authToken } = this.props;
    const { fileList } = this.state;

    if (await this.isValidFile(fileList)) {
      this.setState({ ...this.state, loading: true });

      try {
        const response = await cargarArchivo(authToken, tipoCarga, fileList);

        notification.success({
          message: MESSAGE_TITLE,
          description: response.message,
          duration: 6,
        });

        onFinish();
      } finally {
        this.setState({ ...this.state, loading: false });
      }
    }
  };

  handleOnChangeUpload = (info) => {
    const fileList =
      info.fileList.length > 1 ? info.fileList.shift() : info.fileList;

    this.isValidFile(fileList);

    this.setState({
      ...this.state,
      fileList,
    });
  };

  isValidFile = async (fileList) => {
    const { edit = false } = this.props;
    let fileMessageErrors = [];

    if (fileList.length === 0 && !edit) {
      fileMessageErrors.push('debe seleccionar un archivo');
    }

    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    try {
      await typeFileValidation(fileList, [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ]);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    this.setState({ ...this.state, fileMessageErrors });
    return fileMessageErrors.length === 0;
  };

  cleanForm = () => {
    this.setState({ ...initialState });
  };

  componentDidUpdate(props, state) {
    if (this.props.visible !== props.visible && this.props.visible) {
      this.cleanForm();
    }
  }

  render() {
    const { visible, onClose } = this.props;
    const { loading, fileList, fileMessageErrors } = this.state;
    return (
      <Modal
        title='Días no habiles'
        visible={visible}
        className='non-business-days-modal-style'
        footer={null}
        onCancel={onClose}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <Form layout='vertical'>
            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item name='file' label='ARCHIVO XLSX' required>
                  <Form.Item noStyle>
                    <Dragger
                      beforeUpload={() => {
                        return false;
                      }}
                      fileList={fileList}
                      onChange={this.handleOnChangeUpload}
                      maxCount={0}
                      multiple={false}
                      accept={
                        '.xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                      }
                    >
                      <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                      </p>
                      <p className='ant-upload-text'>
                        Suelta el archivo aquí o selecciona alguno.
                      </p>
                    </Dragger>
                    {fileMessageErrors.map((value, index) => (
                      <Text type='danger' key={index}>
                        {value}
                      </Text>
                    ))}
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} justify='center'>
              <Col span={10}>
                <Space align={'center'}>
                  <Button
                    className='btn btn-blue'
                    onClick={() => this.handleOnFinish('reemplazar')}
                  >
                    Reemplazar
                  </Button>
                </Space>
              </Col>
              <Col span={10}>
                <Space align={'center'}>
                  <Button
                    className='btn btn-green'
                    onClick={() => this.handleOnFinish('adicionar')}
                  >
                    Adicionar
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default NonBusinessDaysModal;
