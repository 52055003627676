import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs } from 'antd';
import './reports.scss';
import BillsInProcess from './billsInProcess/billsInProcess';
import BillsPendingOfPayment from './billsPendingOfPayment/billsPendingOfPayment';
import CompletedAgreement from './completedAgreement/completedAgreement';
import ErrorsErp from './errorsErp/errorsErp';
import Offers from './offers/offers';
import { withRouter } from 'react-router';
import history from '../../../services/history';

class Reports extends Component {
  componentDidMount() {
    this.props.menuHandler('5');
  }

  changeTab = (activeKey) => {
    history.push(`${process.env.PUBLIC_URL}/reports/${activeKey}`);
  };

  render() {
    const { activeTab } = this.props;
    return (
      <div className='reports-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <Tabs activeKey={activeTab || '0'} onChange={this.changeTab}>
              <Tabs.TabPane tab='Facturas en trámite' key={0}>
                <BillsInProcess
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Facturas pendientes de pago' key={1}>
                <BillsPendingOfPayment
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Transacciones finalizadas' key={2}>
                <CompletedAgreement
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Ofertas' key={3}>
                <Offers
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Erorres ERP' key={4}>
                <ErrorsErp
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const activeTab = props.match.params.activeTab;
  return {
    activeTab: activeTab,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(Reports));
