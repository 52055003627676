import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Spin } from 'antd';
import React, { Component } from 'react';
import {
  listaPerfilesAdmin,
  updateUserProfile,
} from '../../../../../services/admin/profileService';
import {
  buscarUsuarioAdmin,
  crearUsuario,
} from '../../../../../services/admin/usersService';
import UsersForm, { initialFormValue } from '../usersForm/usersForm';

const USERS_TITLE = 'Usuarios';

const initialState = {
  visible: false,
  loading: false,
  data: initialFormValue,
  perfiles: [],
};

class UsersNewModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnOpenModal = () => {
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  handleOnClose = () => {
    this.setState({
      ...this.state,
      visible: false,
    });
  };

  handleOnUserSearch = async (id) => {
    if (!id || id === '') {
      return;
    }

    const { authToken, onEditModalChange } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await buscarUsuarioAdmin(authToken, id);

      const data = response.data;
      if (data.companyUser) {
        Modal.confirm({
          title: USERS_TITLE,
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              El usuario o contacto ya se encuentra asociado con está compañía,
              <br /> ¿desea actualizar la información del contacto?
            </div>
          ),
          onOk: () => {
            this.setState({
              ...this.state,
              visible: false,
              loading: false,
            });
            onEditModalChange(data);
          },
          onCancel: () => {
            this.setState({
              ...this.state,
              loading: false,
            });
          },
        });
      } else {
        Modal.confirm({
          title: USERS_TITLE,
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              Ya existe un contacto con este mismo correo, <br /> ¿desea
              actualizar la información del usuario y posteriormente asociarlo a
              esta compañía?
            </div>
          ),
          onOk: () => {
            this.setState({
              ...this.state,
              loading: false,
              data,
            });
          },
          onCancel: () => {
            this.setState({
              ...this.state,
              loading: false,
            });
          },
        });
      }
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnFinish = async (formValue) => {
    const { onFinish, authToken } = this.props;
    this.setState({ ...this.state, loading: true });

    try {
      const response = await crearUsuario(authToken, formValue);
      await updateUserProfile(authToken, {
        ...formValue,
        userSeguridad: response.data.userSeguridad,
      });

      notification.success({
        message: USERS_TITLE,
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        visible: false,
        loading: false,
      });

      onFinish({ ...formValue, scPersona: response.data.scPersona });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getData = async () => {
    const { authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const perfilesResponse = await listaPerfilesAdmin(authToken);

      this.setState({
        ...this.state,
        perfiles: perfilesResponse.data,
        loading: false,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(props, state) {
    if (state.visible !== this.state.visible) {
      this.setState({
        ...this.state,
        data: { ...initialFormValue },
      });
    }
  }

  render() {
    const { visible, loading, data, perfiles } = this.state;

    return (
      <>
        <Button
          size='small'
          className='btn-agregar'
          onClick={this.handleOnOpenModal}
        >
          + Agregar nuevo usuario
        </Button>
        {visible && (
          <Modal
            title='Nuevo Usuario'
            visible={visible}
            className='modal-style'
            footer={null}
            onCancel={this.handleOnClose}
          >
            <Spin tip='Cargando...' spinning={loading}>
              <UsersForm
                data={data}
                reset={visible}
                perfiles={perfiles}
                buscarContacto={this.handleOnUserSearch}
                onFinish={this.handleOnFinish}
              />
            </Spin>
          </Modal>
        )}
      </>
    );
  }
}

export default UsersNewModal;
