import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Divider, Popover, Row, Space, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TableReport from '../../../../../components/tableReport/tableReport';
import {
  exportEventFacturaExcel,
  exportMandatoXML,
  getFacturaEventos,
  listarEventosDeFacturas,
} from '../../../../../services/admin/radian/billsEventsService';
import history from '../../../../../services/history';
import { setDownload, setExcel } from '../../../../../utilities/downloadTools';
import { sorterDate, sorterText } from '../../../../../utilities/textTools';
import EventNewModal from '../eventNewModal/eventNewModal';
import './billsEventsDetails.scss';

const initialState = {
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  visible: false,
  factura: { cdFactura: '' },
};

class BillsEventsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NOMBRE',
        dataIndex: 'dsTipoEventoDE',
        sorter: (a, b) => sorterText(a.dsTipoEventoDE, b.dsTipoEventoDE),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE REGISTRO',
        dataIndex: 'feIngreso',
        sorter: (a, b) => sorterDate(a.feIngreso, b.feIngreso),
        showSorterTooltip: false,
      },
      {
        title: 'NUMERO DEL EVENTO',
        dataIndex: 'scEvento',
        sorter: (a, b) => sorterText(a.scEvento, b.scEvento),
        showSorterTooltip: false,
      },
      {
        title: 'SENDER PARTY',
        dataIndex: 'nombreSenderParty',
        sorter: (a, b) => sorterText(a.nombreSenderParty, b.nombreSenderParty),
        showSorterTooltip: false,
      },
      {
        title: 'RECEIVER PARTY',
        dataIndex: 'nombreReceiverParty',
        sorter: (a, b) =>
          sorterText(a.nombreReceiverParty, b.nombreReceiverParty),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoEventoDE',
        sorter: (a, b) => sorterText(a.dsEstadoEventoDE, b.dsEstadoEventoDE),
        showSorterTooltip: false,
      },
      {
        title: 'XML DE ENVIO',
        dataIndex: '',
        render: (record) =>
          record.cdEstadoEventoDE !== 'P' ? (
            <Button
              type='primary'
              title='Descargar XML'
              onClick={() => this.exportXML(record)}
            >
              <FileTextOutlined />
            </Button>
          ) : null,
        showSorterTooltip: false,
      },
      {
        title: 'RESPUESTA DIAN',
        dataIndex: '',
        render: (record) =>
          record.dsRespDian ? (
            <Popover
              placement='topRight'
              content={
                <ul style={{ listStyleType: 'none' }}>
                  <li>
                    <b>Código:</b>
                    {`${record.dsRespDian.statusCode}`}
                  </li>
                  <li>
                    <b>Descripción:</b>
                    {` ${
                      record.dsRespDian.statusDescription
                        ? record.dsRespDian.statusDescription
                        : ''
                    }`}
                  </li>
                  <li>
                    <b>Mensaje:</b>
                    {` ${
                      record.dsRespDian.statusMessage
                        ? record.dsRespDian.statusMessage
                        : ''
                    }`}
                  </li>
                  <li>
                    <b>Detalle:</b>
                    {` ${
                      record.dsRespDian.warnings &&
                      record.dsRespDian.warnings.length > 0
                        ? record.dsRespDian.warnings.join('; ')
                        : ''
                    } `}
                  </li>
                  <li>
                    <b>UUID:</b>
                    {` ${record.dsRespDian.uuid ? record.dsRespDian.uuid : ''}`}
                  </li>
                </ul>
              }
            >
              {record.dsRespDian.statusCode === '00' ? (
                <CheckCircleOutlined className='icon-size color-success' />
              ) : (
                <CloseCircleOutlined className='icon-size color-error' />
              )}
            </Popover>
          ) : !record.dsRespDian ? (
            <Popover
              placement='topRight'
              content={
                <ul style={{ listStyleType: 'none' }}>
                  <li>
                    <b>Mensaje: </b>
                    {record.cdEstadoEventoDE === 'P'
                      ? 'Evento Pendiente De Respuesta'
                      : 'Evento Iniciado Correctamente'}
                  </li>
                </ul>
              }
            >
              {record.cdEstadoEventoDE === 'P' ? (
                <ClockCircleOutlined className='icon-size color-alert' />
              ) : record.cdEstadoEventoDE === 'I' ? (
                <IssuesCloseOutlined className='icon-size color-info' />
              ) : null}
            </Popover>
          ) : null,
      },
    ];
  }

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.handleSearchEvents();
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const { authToken, scFactura } = this.props;
    this.setState({
      ...this.state,
      offset: 0,
      loading: true,
    });

    try {
      const respEventos = await listarEventosDeFacturas(
        authToken,
        this.getFiltro()
      );

      const respFactura = await getFacturaEventos(authToken, scFactura);

      this.setState({
        ...this.state,
        resultado: respEventos.data,
        total: respEventos.metadata.count,
        factura: respFactura.data,
        loading: false,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleSearchEvents = async () => {
    const { authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarEventosDeFacturas(
        authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        loading: false,
      });
    } catch (error) {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { limit, offset, sort } = this.state;
    const { scFactura } = this.props;
    return {
      scFactura,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  exportExcel = async () => {
    const { scFactura } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await exportEventFacturaExcel(
        this.props.authToken,
        scFactura
      );

      setExcel(response, `Eventos_Factura_${scFactura}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportXML = async (record) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await exportMandatoXML(
        this.props.authToken,
        record.scEvento
      );

      setDownload(response, `${record.nombreEvento}_${record.cdFactura}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  showBillsEvents = () => {
    history.goBack();
  };

  handleOnShowNewModal = () => {
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  handleNewModalOnClose = (value) => {
    debugger;

    if (value) {
      this.setState({
        ...this.state,
        currentPage: 1,
        offset: 0,
        visible: false,
      });
      this.handleSearchEvents();
    } else {
      this.setState({
        ...this.state,
        visible: false,
      });
    }
  };

  render() {
    const { visible, factura } = this.state;
    const { authToken } = this.props;

    return (
      <Spin tip='Cargando...' spinning={this.state.loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Row gutter={[16, 32]} align='middle'>
              <Col flex='auto'>
                <Space align='baseline' className='sub-title'>
                  EVENTOS DE FACTURA NRO: {factura.cdFactura}
                </Space>
              </Col>
              <Col flex='none'>
                <Space align='baseline'>
                  <Button
                    size='small'
                    onClick={this.handleOnShowNewModal}
                    className='btn-agregar'
                  >
                    + Agregar nuevo evento
                  </Button>
                </Space>
              </Col>
              <Col flex='none'>
                <Space align='baseline'>
                  <Button type='link' onClick={this.exportExcel}>
                    Exportar a excel
                  </Button>
                </Space>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col flex='auto'>
                <div className='table-container'>
                  <TableReport
                    currentPage={this.state.currentPage}
                    limit={this.state.limit}
                    total={this.state.total}
                    resumen={this.state.resumen}
                    columns={this.columnas}
                    data={this.state.resultado}
                    handleChange={this.handleChange}
                  />
                </div>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col span={6}>
                <Button
                  style={{ width: '90%' }}
                  className='btn btn-blue'
                  type='primary'
                  onClick={this.showBillsEvents}
                >
                  Regresar
                </Button>
              </Col>
            </Row>
          </Card>
          {visible && (
            <EventNewModal
              authToken={authToken}
              visible={visible}
              factura={factura}
              onClose={this.handleNewModalOnClose}
            />
          )}
        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state, props) => {
  const scFactura = Buffer.from(
    props.match.params.scFactura,
    'base64'
  ).toString('utf8');

  return {
    scFactura: scFactura,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};
export default withRouter(connect(mapStateToProps, {})(BillsEventsDetails));
