import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs, Spin, Col, Button, notification, Modal } from 'antd';
import '../../common/client.scss';
import history from '../../../../../services/history';
import {
  buscarFondeador,
  crearFondeador,
} from '../../../../../services/admin/fundProvidersService';
import BasicDataForm, {
  initialFundProviderValuesForm,
} from '../basicDataForm/basicDataForm';
import { listaTipoDiaPago } from '../../../../../services/admin/payersService';

const MESSAGE_ENTIDAD_PROVEEDOR_NO_EXIST =
  'Esta Persona o Entidad no es un Fondeador';
const MESSAGE_ENTIDAD_PROVEEDOR_EXIST =
  'Esta Persona o Entidad es un Fondeador';
const FUND_PROVIDER_TITLE = 'Proveedor';

const TIPO_ASOCIADO = 'F';

const initialState = {
  loading: false,
  propietarioSelect: {
    options: [],
    fetching: false,
  },
  tipoDiaPago: [],
  data: { ...initialFundProviderValuesForm },
};

class FundProviderNewForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  redirectToFundProvider = () => {
    history.push(`${process.env.PUBLIC_URL}/fundProviders`);
  };

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await crearFondeador(this.props.authToken, formValue);

      notification.success({
        message: FUND_PROVIDER_TITLE,
        description: response.message,
        duration: 6,
      });
      this.redirectToEditFundProvider(response.location);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnFundProviderSearch = async (filtros) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await buscarFondeador(this.props.authToken, filtros);

      if (response.data) {
        if (response.data.snFondeador) {
          Modal.success({
            title: FUND_PROVIDER_TITLE,
            content: MESSAGE_ENTIDAD_PROVEEDOR_EXIST,
          });
          this.redirectToEditFundProvider(response.data.scPersona);
        } else {
          Modal.success({
            title: FUND_PROVIDER_TITLE,
            content: MESSAGE_ENTIDAD_PROVEEDOR_NO_EXIST,
          });

          this.setState({
            ...this.state,
            data: response.data,
          });
        }
      }
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  redirectToEditFundProvider = (scPersona) => {
    history.push(
      `${process.env.PUBLIC_URL}/fundProviders/edit/${btoa(scPersona)}`
    );
  };

  getTipoDiaPago = async () => {
    const { authToken } = this.props;
    try {
      const response = await listaTipoDiaPago(authToken, {
        tipoAsociado: TIPO_ASOCIADO,
      });
      this.setState({
        ...this.state,
        tipoDiaPago: response.data,
      });
    } catch {
      this.setState({
        ...this.state,
        tipoDiaPago: [],
      });
    }
  };

  componentDidMount() {
    this.props.menuHandler('21');
    this.getTipoDiaPago();
  }

  render() {
    const { loading, data, tipoDiaPago } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Tabs activeKey={'1'}>
              <Tabs.TabPane key={'1'} tab='Datos básicos'>
                <BasicDataForm
                  tipoDiaPago={tipoDiaPago}
                  data={data}
                  onFundProviderSearch={this.handleOnFundProviderSearch}
                  onFinish={this.handleOnFinish}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToFundProvider}
                      >
                        Lista de Fondeadores
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(FundProviderNewForm);
