export const columns = {
  config: [
    {
      title: 'CLUSTER NEURONAL DE RIESGO',
      dataIndex: 'clusNeuroRiesgo',
      sorter: (a, b) => b.clusNeuroRiesgo.localeCompare(a.clusNeuroRiesgo),
      showSorterTooltip: false
    },
    {
      title: 'PERCENTIL CORRESPONDIENTE',
      dataIndex: 'percentilCorrespondiente',
      sorter: (a, b) => a.percentilCorrespondiente - b.percentilCorrespondiente,
      showSorterTooltip: false
    },
    {
      title: 'LIMITE INFERIOR',
      dataIndex: 'limiteInferior',
      sorter: (a, b) => a.limiteInferior - b.limiteInferior,
      showSorterTooltip: false
    },
    {
      title: 'LIMITE SUPERIOR',
      dataIndex: 'limiteSuperior',
      sorter: (a, b) => a.limiteSuperior - b.limiteSuperior,
      showSorterTooltip: false
    },
    

  ],

}