import axios from 'axios'
import { middleWare } from '../middleware'
import queryString from 'query-string'

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN

/* #region Listar Opciones */

export const listarCompañias = (authtoken, filtros) => {
  return middleWare(axios({
    url: `${baseUrl}/api/listar-companias?${queryString.stringify(filtros)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}
export const listarClusteres = (authtoken) => {
  return middleWare(axios({
    url: `${baseUrl}/api/neuroscore/listar-clusters`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarCalificaciones = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  return middleWare(axios({
    url: `${baseUrl}/api/neuroscore/listar-calificaciones?${queryString.stringify(filters)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}


const formatoFechas = (filtros) => {
  let empty = {}
  Object.keys(filtros).forEach(nombre => {
    if (nombre !== 'resultado') {
      if ((nombre.includes("Desde") || nombre.includes("Hasta")) && (typeof filtros[nombre] === 'object')) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD')
      } else if (filtros[nombre] && nombre != "currentPage" && nombre != "total") {
        empty[nombre] = filtros[nombre]
      }
    }
  });
  return empty
}