//

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../../utilities/textTools';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

export const initialGroupValuesForm = {
  scGrupo: '',
  dsNombres: '',
  scGrupoZoho: '',
  dsEmail: '',
  dsPagina: '',
  dsTelefono: '',
  cdTipoCliente: 'PAG',
  cdEstadoGrupo: 'A',
  nmMetaPiso: '',
  nmMetaTecho: '',
  nmRangosMeta: '',
  cdHoraCarga: undefined,
  cdHoraDesbloqueo: undefined,
  nmRangosCom: '',
};

const tiposCliente = [
  { key: 'PAG', description: 'Pagador' },
  { key: 'PRO', description: 'Proveedor' },
  { key: 'FON', description: 'Fondeador' },
];
const estadosRegistro = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
];

const rules = {
  dsNombres: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  scGrupoZoho: [{ max: 20, message: maxValueMessage(20) }],
  dsEmail: [{ max: 100, message: maxValueMessage(100) }],
  dsPagina: [{ max: 100, message: maxValueMessage(100) }],
  dsTelefono: [
    {
      pattern: new RegExp('^[0-9\\s]*$'),
      message: formatValueMessage(),
    },
    { max: 20, message: maxValueMessage(20) },
  ],
  cdTipoCliente: [{ required: true, message: requiredValueMessage() }],
  cdEstadoGrupo: [{ required: true, message: requiredValueMessage() }],
  nmMetaTecho: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('nmMetaPiso') < value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('el valor debe ser mayor al valor de Meta Piso Volumen')
        );
      },
    }),
  ],
  nmMetaPiso: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('nmMetaTecho') > value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('el valor debe ser menor al valor de Meta Techo Volumen')
        );
      },
    }),
  ],
  nmRangosCom: [
    { required: true, message: requiredValueMessage() },
  ],
};

const getFieldsConfigVolumen = (form) => {
  const nmMetaPiso = form.getFieldValue('nmMetaPiso');
  const nmMetaTecho = form.getFieldValue('nmMetaTecho');
  const nmRangosMeta = form.getFieldValue('nmRangosMeta');

  if (!nmMetaPiso || nmMetaPiso === '') {
    return {};
  }

  if (!nmMetaTecho || nmMetaTecho === '') {
    return {};
  }

  if (!nmRangosMeta || nmRangosMeta === '' || nmRangosMeta <= 0) {
    return {};
  }

  if (form.getFieldError('nmMetaPiso').length > 0) {
    return {};
  }

  if (form.getFieldError('nmMetaTecho').length > 0) {
    return {};
  }

  return { nmMetaPiso, nmMetaTecho, nmRangosMeta };
};

const BasicDataForm = ({
  onFinish,
  horasDesbloqueo,
  horasCarga,
  children,
  data,
  onConfigVolumen,
  onConfigRangoComision,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
    });
  }, [form, data]);

  const handleOnConfigVolumen = () => {
    const rangoMeta = getFieldsConfigVolumen(form);

    if (JSON.stringify(rangoMeta) !== '{}') {
      onConfigVolumen(rangoMeta);
    } else {
      Modal.warning({
        title: 'CALCULAR VOLUMEN',
        content:
          'para calcular debe seleccionar rango de volumen valido  [Meta Piso Volumen, Meta Tacho Volumen] y un Número de rangos para Volumen mayor a 0',
      });
    }
  };

  const handleOnConfigRangoComision = () => {
    let nmRangosCom = form.getFieldValue('nmRangosCom');

    onConfigRangoComision(nmRangosCom);
  };

  return (
    <Form
      name='content_form'
      layout='vertical'
      form={form}
      onFinish={onFinish}
      initialValues={initialGroupValuesForm}
    >
      <Form.Item name='scGrupo' hidden='true'>
        <Input />
      </Form.Item>
      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Datos personales
          </Space>
        </Col>
      </Row>
      <Divider />

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='dsNombres'
            label='NOMBRE'
            rules={rules['dsNombres'] || ''}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='scGrupoZoho'
            label='CÓDIGO ZOHO'
            rules={rules['scGrupoZoho'] || ''}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='dsEmail'
            label='CORREO ELECTRÓNICO'
            rules={rules['dsEmail'] || ''}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='dsPagina'
            label='SITIO WEB'
            rules={rules['dsPagina'] || ''}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='dsTelefono'
            label='TELÉFONO'
            rules={rules['dsTelefono'] || ''}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='cdTipoCliente'
            label='TIPO DE GRUPO'
            rules={rules['cdTipoCliente'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {tiposCliente.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdEstadoGrupo'
            label='ESTADO GRUPO'
            rules={rules['cdEstadoGrupo'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {estadosRegistro.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información para calcular voLumen
          </Space>
        </Col>
      </Row>
      <Divider />

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='nmMetaPiso'
            dependencies={['nmMetaTecho']}
            label='META PISO VOLUMEN'
            rules={rules['nmMetaPiso'] || ''}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              formatter={currencyInputFormat}
              parser={currencyInputParser}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='nmMetaTecho'
            dependencies={['nmMetaPiso']}
            label='META TECHO VOLUMEN'
            rules={rules['nmMetaTecho'] || ''}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              formatter={currencyInputFormat}
              parser={currencyInputParser}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify='space-around' align='middle'>
        <Col span={12}>
          <Form.Item
            name='nmRangosMeta'
            label='NÚMERO DE RANGOS PARA VOLUMEN'
            rules={rules['nmRangosMeta'] || ''}
          >
            <InputNumber style={{ width: '100%' }} min={0} precision={0} />
          </Form.Item>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <Button
            className='btn btn-blue'
            type='primary'
            onClick={handleOnConfigVolumen}
          >
            Configurar
          </Button>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información para la carga de facturas
          </Space>
        </Col>
      </Row>
      <Divider />

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdHoraCarga'
            label='HORA DE CARGA (HH:MM)'
            rules={rules['cdHoraCarga'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {horasCarga.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='cdHoraDesbloqueo'
            label='HORA DE DESBLOQUEO (HH:MM)'
            rules={rules['cdHoraDesbloqueo'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {horasDesbloqueo.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información para los rangos de comisión
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} justify='space-around' align='middle'>
        <Col span={12}>
          <Form.Item
            name='nmRangosCom'
            label='NÚMERO DE RANGOS COMISIÓN'
            rules={rules['nmRangosCom'] || ''}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              precision={0}
              max={99}
            />
          </Form.Item>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <Button
            className='btn btn-blue'
            type='primary'
            onClick={handleOnConfigRangoComision}
          >
            Configurar
          </Button>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '1.5rem' }}>
        {children}
        <Col span={6}>
          <Button
            style={{ width: '90%' }}
            className='btn btn-green'
            type='primary'
            htmlType='submit'
          >
            Grabar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicDataForm;
