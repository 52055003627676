import axios from 'axios';
import { middleWare } from '../../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarEventosFacturas = (authtoken, filtros) => {
  console.log(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/eventos/listar-facturas?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarEventosFacturasExcel = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/eventos/listar-facturas/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
export const exportEventFacturaExcel = (authtoken, scFactura) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/${scFactura}/eventos/exportar/xlsx`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarEventosDE = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/eventos-de?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const getFacturaEventos = (authtoken, scFactura) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/eventos/factura/${scFactura}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarEventosDeFacturas = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/${filtros.scFactura
        }/eventos?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const grabarEventosDE = (authtoken, scFactura, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/${scFactura}/eventos/registrar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const exportMandatoXML = (authtoken, scEvento) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/eventos/${scEvento}/application-response/descargar`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const executeTaskAccionesEventos = (authtoken, keys, task, scPersona) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/eventos/accionesEventosRadian`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: {
        evento: task,
        facturas: keys,
        scPersonaModifica: scPersona
      },
    })
  );
};

export const executeTaskAccionesConsultaEventos = (authtoken, keys, task, scPersona) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/eventos/accionesConsultaEventos`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: {
        evento: task,
        facturas: keys,
        scPersonaModifica: scPersona
      },
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else if (
        filtros[nombre] &&
        nombre != 'currentPage' &&
        nombre != 'total'
      ) {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
