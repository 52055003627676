import React, { Component } from 'react';
import { Card, Tabs } from 'antd';
import './reports.scss';
import { connect } from 'react-redux';
import Bills from './bills/bills';
import PendingPayments from './pendingPayments/pendingPayments';
import TransactionInProgress from './transactionInProgress/transactionInProgress';
import PaidBills from './paidBills/paidBills';
import PayerQuota from './payerQuota/payerQuota';
import AgreementQuota from './agreementQuota/agreementQuota';
import BillsRadian from './BillsRadian/billsRadian';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '0',
    };
  }

  componentDidMount() {
    this.props.menuHandler('6');
  }

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  render() {
    return (
      <div className='reports-style-f'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>
              <Tabs.TabPane tab='Facturas' key={0}>
                <Bills
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Facturas en trámite' key={1}>
                <TransactionInProgress
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Pagos pendientes' key={2}>
                <PendingPayments
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Facturas Pagadas' key={3}>
                <PaidBills
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Cupo pagadores' key={4}>
                <PayerQuota
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Cupos' key={5}>
                <AgreementQuota
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab='Facturas Radian' key={8}>
                <BillsRadian
                  company={this.props.company}
                  authToken={this.props.authToken}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Reports);
