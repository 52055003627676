import React from 'react'
import './f4allModalProvider.scss'
import { Modal, Carousel, Row } from 'antd'
import f4allProveedorPng from '../assets/img/factoring_Proveedores.png'


function F4allModalProvider({showModalCupos,onClose}) {
  return (
    <Modal
      className="content-modal"
      visible={showModalCupos}
      footer={false}
      closable
      maskClosable
      onCancel={() => onClose(false)}
      width={400}
    >
    <Row>
          <a href="https://web.whatsapp.com/send?phone=573243209544" target="_blank" rel="noopener noreferrer">
            <img src={f4allProveedorPng} alt="f4allProveedorPng" className='img-modal-size'/>
          </a>
    </Row>

    </Modal>
  )
}

export default F4allModalProvider