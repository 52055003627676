export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_CLOSE = 'LOGIN_CLOSE'
export const LOGIN_SET_EXPIRATION = 'LOGIN_SET_EXPIRATION'
export const LOGIN_ACCEPT_TERMS = 'LOGIN_ACCEPT_TERMS'


export const COMPANY_SELECTED = 'COMPANY_SELECTED'
export const SELECTED_BILLS = 'SELECTED_BILLS'
export const PROGRAM_NEXT_STEP = 'PROGRAM_NEXT_STEP'
export const PROGRAM_SELECTED_BILLS = 'PROGRAM_SELECTED_BILLS'
export const CHARGEALL_SELECTED_BILLS = 'CHARGEALL_SELECTED_BILLS'
export const NEXT_STEP = 'NEXT_STEP'
export const CHARGEALL_NEXT_STEP = 'CHARGEALL_NEXT_STEP'
export const SET_NEEDED_AMOUNT = 'SET_NEEDED_AMOUNT'
export const SET_TRACKING = 'SET_TRACKING'
export const UPDATE_PROGRAM_DATE = 'UPDATE_PROGRAM_DATE'
export const SET_PERSON_TYPE = 'SET_PERSON_TYPE'
export const BILLING_RESET_STEPS = 'BILLING_RESET_STEPS'
export const REALOAD_NOTIFICATIONS = 'REALOAD_NOTIFICATIONS'

/**Affitoken */
export const AFFITOKEN_USER_STATUS = 'AFFITOKEN_USER_STATUS'
export const AFFITOKEN_STEP_STATUS = 'AFFITOKEN_STEP_STATUS'
export const AFFITOKEN_SEND_MEDIA = 'AFFITOKEN_SEND_MEDIA'
export const AFFITOKEN_TOKEN_TEMP_GENERATED = 'AFFITOKEN_TOKEN_TEMP_GENERATED'
export const AFFITOKEN_TOKEN_TEMP_VALIDATED = 'AFFITOKEN_TOKEN_TEMP_VALIDATED'
export const AFFITOKEN_TOKEN_GENERATED = 'AFFITOKEN_TOKEN_GENERATED'
export const AFFITOKEN_TOKEN_VALIDATED = 'AFFITOKEN_TOKEN_VALIDATED'
export const AFFITOKEN_REGISTER_USER = 'AFFITOKEN_REGISTER_USER'
export const AFFITOKEN_UPDATE_USER = 'AFFITOKEN_UPDATE_USER'
export const AFFITOKEN_TOKEN_VALIDATED_RESTART = 'AFFITOKEN_TOKEN_VALIDATED_RESTART'
export const AFFITOKEN_TOKEN_SET = 'AFFITOKEN_TOKEN_SET'
export const AFFITOKEN_RESET_STEPS = 'AFFITOKEN_RESET_STEPS'
/**end Affitoken */

/**Resumen Facturas */
export const BILL_REPORT_SUMMARY_INIT = 'BILL_REPORT_SUMMARY_INIT'
export const BILL_REPORT_SUMMARY_LOADED = 'BILL_REPORT_SUMMARY_LOADED'
/**end Resumen Facturas */

/**Ficha Cliente admin*/

export const ADMIN_PERSON_TEMPORAL_DATA = 'ADMIN_PERSON_TEMPORAL_DATA'
export const ADMIN_PERSON_REQUEST = 'ADMIN_PERSON_REQUEST'
export const ADMIN_PERSON_J_LOADED = 'ADMIN_PERSON_J_LOADED'
export const ADMIN_PERSON_N_LOADED = 'ADMIN_PERSON_N_LOADED'
export const ADMIN_PERSON_UPDATE_STATE = 'ADMIN_PERSON_UPDATE_STATE'
export const ADMIN_PERSON_SAVE_REQUEST = 'ADMIN_PERSON_SAVE_REQUEST'
export const ADMIN_PERSON_SAVE_SUCCESS = 'ADMIN_PERSON_SAVE_SUCCESS'


/**Ficha Cliente */
export const PERSON_REQUEST = 'PERSON_REQUEST'
export const PERSON_J_LOADED = 'PERSON_J_LOADED'
export const PERSON_N_LOADED = 'PERSON_N_LOADED'
export const PERSON_SAVE_REQUEST = 'PERSON_SAVE_REQUEST'
export const PERSON_SAVE_SUCCESS = 'PERSON_SAVE_SUCCESS'
export const PERSON_UPDATE_STATE = 'PERSON_UPDATE_STATE'

/**end Ficha Cliente */


/**Pagador Compañia */
export const COMPANY_SELECTED_PAGADOR = 'COMPANY_SELECTED_PAGADOR'
/**end Pagador Compañia */


/**Fondeador Compañia */
export const COMPANY_SELECTED_FONDEADOR = 'COMPANY_SELECTED_FONDEADOR'
/**end Fondeador Compañia */
