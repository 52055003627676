export const columns = {
  reporte_logs: [
    {
      title: 'FECHA',
      dataIndex: 'fecha',
      sorter: (a, b) => a.fecha - b.fecha,
      showSorterTooltip: false
    },
    {
      title: 'NIVEL',
      dataIndex: 'nivel',
      sorter: (a, b) => a.nivel - b.nivel,
      showSorterTooltip: false
    },
    {
      title: 'HILO',
      dataIndex: 'hilo',
      sorter: (a, b) => a.hilo - b.hilo,
      showSorterTooltip: false
    },
    {
      title: 'LOGGER',
      dataIndex: 'logger',
      sorter: (a, b) => a.logger - b.logger,
      showSorterTooltip: false
    },
    {
      title: 'MENSAJE',
      dataIndex: 'mensaje',
      sorter: (a, b) => a.mensaje - b.mensaje,
      showSorterTooltip: false
    },
  ],
  descarga_logs: [
    {
      title: 'FONDEADOR',
      dataIndex: 'nombreFondeador',
      sorter: (a, b) => a.nombreFondeador - b.nombreFondeador,
      showSorterTooltip: false
    },
  ],
  
}