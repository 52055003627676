import { Button, Card, Col, notification, Spin, Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  editarConvenio,
  getConvenio,
  listarTiposCobros,
  listarTiposConvenios,
} from '../../../../../services/admin/operation';
import { listarPagadores } from '../../../../../services/admin/reports/quotasService';
import history from '../../../../../services/history';
import { initialSupplierValuesForm } from '../../../clients/suppliers/BasicDataForm/BasicDataForm';
import Suppliers from '../../agreementOperation/suppliers/suppliers';
import '../agreementOperation.scss';
import BasicDataForm from '../basicDataForm/basicDataForm';
import FoundProviders from '../foundProviders/foundProviders';

const AGREEMENTS_TITLE = 'Proveedor';

const initialState = {
  loading: false,
  pagadorSelect: {
    options: [],
    fetching: false,
  },
  data: {
    ...initialSupplierValuesForm,
  },
  tipoConvenios: [],
  tipoCobros: [],
  activeTab: '0',
  poDescuentoMinimoProveedor: '',
};

class AgreementEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  redirectToAgreements = () => {
    history.push(`${process.env.PUBLIC_URL}/operation`);
  };

  getData = async () => {
    const { scConvenio, authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await getConvenio(authToken, scConvenio);

      const tipoConvenioRes = await listarTiposConvenios(authToken);
      const tipoCobroRes = await listarTiposCobros(authToken);

      this.setState({
        ...this.state,
        loading: false,
        data: { ...response.data },
        tipoConvenios: tipoConvenioRes.data,
        tipoCobros: tipoCobroRes.data,
        poDescuentoMinimoProveedor:
          response.metadata.sumatorias.poDescuentoMinimoProveedor,
      });
    } catch (error) {
      this.redirectToAgreements();
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await editarConvenio(this.props.authToken, formValue);

      notification.success({
        message: AGREEMENTS_TITLE,
        description: response.message,
        duration: 6,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleTabOnChange = (e) => {
    this.setState({
      ...this.state,
      activeTab: e,
    });
  };

  componentDidMount() {
    this.props.menuHandler('41');
    this.getData();
  }

  render() {
    const {
      loading,
      pagadorSelect,
      data,
      tipoConvenios,
      tipoCobros,
      activeTab,
      poDescuentoMinimoProveedor,
    } = this.state;

    const { authToken, scConvenio } = this.props;

    const { cdTipoConvenio, scPagador, nombrePagador } = data;

    return (
      <div className='suppliers-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <Tabs activeKey={activeTab} onChange={this.handleTabOnChange}>
            <Tabs.TabPane key={'0'} tab='Convenios'>
              <Spin tip='Cargando...' spinning={loading}>
                <BasicDataForm
                  data={data}
                  edit={true}
                  tipoConvenios={tipoConvenios}
                  tipoCobros={tipoCobros}
                  pagadorSelect={pagadorSelect}
                  handleOnSearchPagador={this.handleOnSearchPagador}
                  onFinish={this.handleOnFinish}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToAgreements}
                      >
                        Lista de Convenios
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane key={'1'} tab='Fondeadores'>
              <FoundProviders
                authToken={authToken}
                scConvenio={scConvenio}
                cdTipoConvenio={cdTipoConvenio}
                poDescuentoMinimoProveedor={poDescuentoMinimoProveedor}
                scPagador={scPagador}
                nombrePagador={nombrePagador}
              >
                <Col span={6}>
                  <Button
                    style={{ width: '90%' }}
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.redirectToAgreements}
                  >
                    Listar de Convenio
                  </Button>
                </Col>
              </FoundProviders>
            </Tabs.TabPane>

            <Tabs.TabPane key={'2'} tab='Proveedores'>
              <Suppliers
                authToken={authToken}
                scConvenio={scConvenio}
                scPagador={scPagador}
                poDescuentoMinimoProveedor={poDescuentoMinimoProveedor}
                cdTipoConvenio={cdTipoConvenio}
              >
                <Col span={6}>
                  <Button
                    style={{ width: '90%' }}
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.redirectToAgreements}
                  >
                    Listar de Convenio
                  </Button>
                </Col>
              </Suppliers>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scConvenio = atob(props.match.params.scConvenio);
  return {
    scConvenio: scConvenio,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(AgreementEditForm));
