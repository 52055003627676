import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  emailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import { tipoIds } from '../../../../../utilities/constants';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const tipoPersonas = [
  {
    key: 'N',
    description: 'Natural',
  },
  {
    key: 'J',
    description: 'Jurídica',
  },
];

const estadoPersonas = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
  { key: 'P', description: 'Prospecto' },
];

const tipoProveedor = [
  { key: 'C', description: 'Confirming' },
  { key: 'F', description: 'Factoring' },
];

export const initialSupplierValuesForm = {
  cdTipoPersona: undefined,
  cdEstadoPersona: 'P',
  dsEstadoPersona: estadoPersonas.find((value) => value.key === 'P')
    .description,
  cdTipoId: undefined,
  id: '',
  razonSocial: '',
  prNombre: '',
  segNombre: '',
  prApellido: '',
  segApellido: '',
  scPropietario: undefined,
  dsEmailFacturacion: '',
  scGrupoAsociado: [],
};

const rules = {
  cdTipoPersona: [{ required: true, message: requiredValueMessage() }],
  cdEstadoPersona: [{ required: true, message: requiredValueMessage() }],
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
    {
      pattern: /^[0-9a-zA-Z-]*$/,
      message: formatValueMessage(),
    },
  ],
  razonSocial: [
    { required: true, message: requiredValueMessage() },
    { max: 300, message: maxValueMessage(300) },
  ],
  prNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  prApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  scPropietario: [{ required: true, message: requiredValueMessage() }],
  dsEmailFacturacion: [
    {
      pattern: emailPattern,
      message: formatValueMessage(),
    },
    { max: 100, message: maxValueMessage(100) },
  ],
  cdTipoProveedor: [{ required: true, message: requiredValueMessage() }],
};

const BasicDataForm = ({
  onFinish,
  propietarioSelect,
  grupoSelect,
  onPropietarioSearch,
  onSupplierSearch,
  data,
  visibleSearchButton = true,
  children,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [cdTipoPersona, setCdTipoPersona] = useState('');
  const [cdEstadoPersona, setCdEstadoPersona] = useState('');

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    setCdTipoPersona(data.cdTipoPersona);
    setCdEstadoPersona(data.cdEstadoPersona);
    form.setFieldsValue({
      ...data,
    });
  }, [form, data]);

  const handleCdTipoPersonaChange = (cdTipoPersona) => {
    setCdTipoPersona(cdTipoPersona);
  };

  return (
    <Form
      name='content_form'
      layout='vertical'
      form={form}
      onFinish={onFinish}
      initialValues={initialSupplierValuesForm}
    >
      <Form.Item name='scPersona' hidden='true'>
        <Input />
      </Form.Item>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Datos personales
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoPersona'
            label='TIPO DE PERSONA'
            rules={rules['cdTipoPersona']}
          >
            <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
              onChange={handleCdTipoPersonaChange}
            >
              {tipoPersonas.map((tipoPersona) => (
                <Select.Option key={tipoPersona.key} value={tipoPersona.key}>
                  {tipoPersona.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='cdEstadoPersona'
            label='ESTADO'
            rules={rules['cdEstadoPersona']}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {estadoPersonas.map((estadoPersona) => (
                <Select.Option
                  key={estadoPersona.key}
                  value={estadoPersona.key}
                >
                  {estadoPersona.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoId'
            label='TIPO DE IDENTIFICACIÓN'
            rules={rules['cdTipoId']}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {tipoIds.map((tipoId) => (
                <Select.Option key={tipoId.key} value={tipoId.key}>
                  {tipoId.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='IDENTIFICACIÓN' required>
            <Row gutter={[8, 0]}>
              <Col flex='auto'>
                <Form.Item name='id' rules={rules['id']} noStyle>
                  <Input />
                </Form.Item>
              </Col>
              {visibleSearchButton && (
                <Col flex='none' style={{ marginTop: '0.2rem' }}>
                  <Button
                    type='dashed'
                    size='small'
                    shape='circle'
                    icon={<SearchOutlined />}
                    onClick={() =>
                      onSupplierSearch({ id: form.getFieldValue('id') })
                    }
                  />
                </Col>
              )}
            </Row>
          </Form.Item>
        </Col>
      </Row>

      {cdTipoPersona === 'J' && (
        <Row gutter={16}>
          <Col flex={'auto'}>
            <Form.Item
              name='razonSocial'
              label='RAZÓN SOCIAL'
              rules={rules['razonSocial']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}

      {cdTipoPersona === 'N' && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prNombre'
                label='PRIMER NOMBRE'
                rules={rules['prNombre']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segNombre'
                label='SEGUNDO NOMBRE'
                rules={rules['segNombre']}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prApellido'
                label='PRIMER APELLIDO'
                rules={rules['prApellido']}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segApellido'
                label='SEGUNDO APELLIDO'
                rules={rules['segApellido']}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='scPropietario'
            label='PROPIETARIO'
            rules={rules['scPropietario']}
          >
            <Select
              className='module-selector-select'
              placeholder={SELECT_PLACE_HOLDER}
              showSearch
              allowClear
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => onPropietarioSearch(value)}
              notFoundContent={
                propietarioSelect.fetching ? <Spin size='small' /> : null
              }
            >
              {propietarioSelect.options.map((propietario) => (
                <Select.Option
                  key={propietario.codigo}
                  value={propietario.codigo}
                >
                  {propietario.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='dsEmailFacturacion'
            label='CORREO ELECTRÓNICO FACTURACIÓN'
            rules={rules['dsEmailFacturacion']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col flex={'auto'}>
          <Form.Item
            name='scGrupoAsociado'
            label='GRUPOS EMPRESARIALES'
            rules={rules['scGrupoAsociado']}
          >
            <Select
              placeholder={SELECT_PLACE_HOLDER}
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
            >
              {grupoSelect &&
                grupoSelect.map((grupo) => (
                  <Select.Option key={grupo.codigo} value={grupo.codigo}>
                    {grupo.descripcion}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoProveedor'
            label='TIPO DE PROVEEDOR'
            rules={rules['cdTipoProveedor']}
          >
            <Select
              placeholder={SELECT_PLACE_HOLDER}
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              filterOption={(input, option) => {
                return (
                  option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >=
                  0
                );
              }}
            >
            {tipoProveedor && 
            tipoProveedor.map((opcion) => (
              <Select.Option key={opcion.key} value={opcion.key}>
                {opcion.description}
            </Select.Option>
            ))}  
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        {children}
        <Col span={6}>
          <Button
            style={{ width: '90%' }}
            className='btn btn-green'
            type='primary'
            htmlType='submit'
          >
            Grabar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicDataForm;
