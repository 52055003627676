import { Button, Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';
import moment from 'moment';


const initialValue = {
  fechaRango: [moment().subtract(1, 'days'), moment()],
  scGrupo: '',
  scPagador:'',
  scProveedor:'',
  cdFactura:'',
  cufe:'',
};
const BillEventsFilterForm = ({
  handleSubmit,
  pagadorSelect,
  proveedorSelect,
  grupoEmpresarialSelect,
  onSearchPagador,
  onSearchProveedor,
  onSearchGrupoEmpresarial,
  data,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        {/*Rango de Fecha*/}
        <Row gutter={16}>
          <Col flex='auto'>
            <Form.Item
              name='fechaRango'
              label='Rango de fechas de compra'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un rango de fecha',
                },
              ]}
            >
              <DatePicker.RangePicker format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col flex='auto'>
            <Form.Item name='cufe' label='CUFE'>
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scGrupo' label='Grupo Empresarial'>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => onSearchGrupoEmpresarial(value)}
                notFoundContent={
                  grupoEmpresarialSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {grupoEmpresarialSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scProveedor' label='Proveedor'>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => onSearchProveedor(value)}
                notFoundContent={
                  proveedorSelect.fetching ? <Spin size='small' /> : null
                }
                required
              >
                {proveedorSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scPagador' label='Pagador'>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => onSearchPagador(value)}
                notFoundContent={
                  pagadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {pagadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='cdFactura' label='Nro Factura'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BillEventsFilterForm;
