import axios from 'axios'
import { middleWare } from '../middleware'

const baseUrl = process.env.REACT_APP_SERVICES_URL_PAGADOR

const upload = (authtoken, company, loadtype, files) => {
  console.log(loadtype);
  console.log(files);
  return middleWare(axios({
    url: `${baseUrl}/api/${company}/${loadtype}/cargar-archivo`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      "Authorization": `Bearer ${authtoken}`
    },
    data: files
  }))
}
const confirm = (authtoken, company, loadtype, filename, fechaCarga) => {
  console.log(fechaCarga)
  if (fechaCarga != null) {
    return middleWare(axios({
      url: `${baseUrl}/api/${company}/${loadtype}/cargar-archivo/aceptar/${filename}?fechaCarga=${fechaCarga}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      },
      data: null
    }))
  } else {
    return middleWare(axios({
      url: `${baseUrl}/api/${company}/${loadtype}/cargar-archivo/aceptar/${filename}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      },
      data: null
    }))
  }
}
const downloadTemplate = (authtoken, company, loadtype) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${company}/${loadtype}/plantilla`,
    responseType: 'blob',
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export default {
  upload,
  confirm,
  downloadTemplate
}