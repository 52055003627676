import React from 'react';
import { Row, Col, Divider, Form, Button, Radio, Alert, Input } from 'antd';
import './send.scss';
import '../affitoken.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  setStepAction,
  selectSendMethodAction,
  requestTempTokenAction,
  updateUserAction,
} from '../../../../store/actions/proveedor/affitokenActtions';
import history from '../../../../services/history';

class Send extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPhoneInput: this.props.user.tipoEnvioOtp == 'SMS',
      user: {
        ...this.props.user,
        tipoEnvioOtp: this.props.user.tipoEnvioOtp || 'EMAIL',

        telefonoEnvioOtp:
          this.props.user.telefonoEnvioOtp || this.props.user.telefono1,
      },
    };
    this.props.selectSendMethodAction(this.props.user.tipoEnvioOtp || 'EMAIL');
  }

  changeRadio(value) {
    this.props.selectSendMethodAction(value);
    this.setState({
      ...this.state,
      showPhoneInput: value == 'SMS',
      user: {
        ...this.state.user,
        tipoEnvioOtp: value,
      },
    });
  }
  nextStep() {
    if (this.props.affitoken.user.estado === 'Activo') {
      this.props.updateUserAction(this.props.authentication, this.state.user);
    } else {
      let { tipoEnvioOtp, telefonoEnvioOtp } = this.state.user;
      this.props.requestTempTokenAction(this.props.authentication);
      this.props.selectSendMethodAction(tipoEnvioOtp, telefonoEnvioOtp);
      this.props.setStepAction(2);
    }
  }

  back() {
    this.props.setStepAction(0);
    history.push(`${process.env.PUBLIC_URL}/dashboard`);
  }
  componentDidUpdate(props, state) {
    if (props.affitoken.updateStatus !== this.props.affitoken.updateStatus) {
      let { updateStatus } = this.props.affitoken;
      if (updateStatus) {
        this.setState({
          ...this.state,
          updated: {
            updateStatus: updateStatus.status,
            updateMessage: updateStatus.message,
          },
        });
      }
    }

    if (state.user.telefonoEnvioOtp !== this.state.user.telefonoEnvioOtp) {
    }
  }

  render() {
    return (
      <div className='configure-style'>
        <Row className='title-container'>
          <Col span={24} className='title'>
            Configurar medio de envío
          </Col>
        </Row>
        <Divider />
        <Row className='description-container'>
          <Col span={24} className='description'>
            A continuación puedes seleccionar el medio de envio de tu
            preferencia para recibir el Affitoken. Recuerda que puedes
            modificarlo en cualquier momento.
            <br />
          </Col>
        </Row>
        <Row className='configure-form default-border card-shadow radius-2'>
          <Col span={24}>
            <Form name='configure_affitoken' layout='vertical'>
              <Row gutter={16}>
                <Radio.Group
                  defaultValue={'EMAIL'}
                  onChange={(e) => {
                    this.changeRadio(e.target.value);
                  }}
                  value={this.state.user.tipoEnvioOtp}
                >
                  <Col span={12}>
                    <Radio value={'EMAIL'}>EMAIL</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={'SMS'}>SMS</Radio>
                  </Col>
                </Radio.Group>
                {this.state.showPhoneInput && (
                  <Col span={12} className='form-update-send'>
                    <span>Numero de celular</span>
                    <Input
                      value={this.state.user.telefonoEnvioOtp}
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          user: {
                            ...this.state.user,
                            telefonoEnvioOtp: e.target.value,
                          },
                        })
                      }
                      style={{ width: '50%' }}
                    />
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className='submit-container'>
          {this.state.updated ? (
            this.state.updated.updateStatus ? (
              <Col span={24}>
                <Alert
                  className='affitoken-alert'
                  message='Exitoso'
                  description={this.state.updated.updateMessage}
                  type='info'
                />
                <Button
                  className='form-btn btn-radius'
                  htmlType='submit'
                  onClick={() => {
                    this.back();
                  }}
                >
                  Volver
                </Button>
              </Col>
            ) : (
              <Col span={24}>
                <Alert
                  className='affitoken-alert'
                  message='Error'
                  description={this.state.updated.updateMessage}
                  type='error'
                />
                <Button
                  className='form-btn btn-radius'
                  htmlType='submit'
                  onClick={() => {
                    this.back();
                  }}
                >
                  Volver
                </Button>
              </Col>
            )
          ) : (
            <Col span={24}>
              <Button
                className='form-btn btn-radius'
                htmlType='submit'
                onClick={() => {
                  this.nextStep();
                }}
              >
                Enviar
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

Send.propTypes = {
  authentication: PropTypes.object.isRequired,
  affitoken: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    authentication: {
      authtoken: state.auth.auth.access_token,
      company: state.billing.company,
    },
    affitoken: state.affitoken,
  };
};

export default connect(mapStateToProps, {
  setStepAction,
  selectSendMethodAction,
  updateUserAction,
  requestTempTokenAction,
})(Send);
