import { Col, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';
import {
  digitsMessagge,
  digitsPattern,
  requiredValueMessage,
} from '../../../../../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../../../utilities/textTools';
import * as numberValidate from '../../../../../../utilities/validate/numberValidate';
import { length } from '../../../../../../utilities/validate/textValidate';

const { TextArea } = Input;

const rules = {
  nmTotalAct: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  nmTotalPas: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  nmTotalPatrimonio: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  nmIngresos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  nmOtrosIng: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
  ],
  nmEgresos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(25, 8),
      message: digitsMessagge(25, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  otrosIngresos: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 1, 4000),
    },
  ],
};

class Financial extends React.Component {
  render() {
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='nmTotalAct'
                label='Total activos'
                rules={rules['nmTotalAct'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmTotalPas'
                label='Total pasivos'
                rules={rules['nmTotalPas'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='nmTotalPatrimonio'
                label='Total patrimonio'
                rules={rules['nmTotalPatrimonio'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmIngresos'
                label='Total ingresos (mensuales)'
                rules={rules['nmIngresos'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='nmOtrosIng'
                label='Otros ingresos (mensuales)'
                rules={rules['nmOtrosIng'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nmEgresos'
                label='Total egresos (mensuales)'
                rules={rules['nmEgresos'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                  stringMode={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex='auto'>
              <Form.Item
                name='otrosIngresos'
                label='Detalle de otros ingresos no operacionales u originados en actividades diferentes a la principal'
                rules={rules['otrosIngresos'] || ''}
              >
                <TextArea rows={5} minLength={1} maxLength={4000} style={{ resize: 'none' }}/>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Financial;
