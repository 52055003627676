import React, { Component } from 'react';
import {
  Row,
  Col,
  Spin,
  Button,
  Modal,
  Divider,
  Table,
  notification,
  Space,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  listaUsuarios,
  eliminarUsuario,
} from '../../../../../services/admin/payersService';
import { sorterDate, sorterText } from '../../../../../utilities/textTools';
import UserNewModal from './userNewModal/userNewModal';
import UserEditModal from './userEditModal/userEditModal';

const USERS_TITLE = 'Usuarios';
const initilState = {
  loading: false,
  resultado: [],
  total: 0,
  userEdit: {},
  visibleEditModal: false,
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initilState };
    this.columnas = [
      {
        title: 'ID.',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.id, b.id),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        showSorterTooltip: false,
      },
      {
        title: 'CORREO ELECTRÓNICO',
        dataIndex: 'email',
        sorter: (a, b) => sorterText(a.email, b.email),
        showSorterTooltip: false,
      },
      {
        title: 'POSEE AFFITOKEN',
        dataIndex: 'affitokeStatus',
        sorter: (a, b) => sorterText(a.affitokeStatus, b.affitokeStatus),
        showSorterTooltip: false,
      },
      {
        title: 'MÉTODO AFFITOKEN',
        dataIndex: 'affitokeDsDeliveryType',
        sorter: (a, b) =>
          sorterText(a.affitokeDsDeliveryType, b.affitokeDsDeliveryType),
        showSorterTooltip: false,
      },
      {
        title: 'NÚMERO CELULAR',
        dataIndex: 'telefono2',
        sorter: (a, b) => sorterText(a.telefono2, b.telefono2),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA MOD. AFFITOKEN',
        dataIndex: 'affitokeUpdataAt',
        sorter: (a, b) => sorterDate(a.affitokeUpdataAt, b.affitokeUpdataAt),
        showSorterTooltip: false,
      },
      {
        title: 'CANALES DE COMUNICACION',
        dataIndex: 'dsMedio',
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='EDITAR'
            onClick={() => this.showModalEditar(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='ELIMINAR'
            onClick={() => this.showDeleteConfirm(record)}
          >
            ELIMINAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  showDeleteConfirm = (record) => {
    Modal.confirm({
      title: USERS_TITLE,
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro de eliminar este usuario?',
      onOk: () => this.eliminarUsuario(record),
    });
  };

  eliminarUsuario = async (record) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await eliminarUsuario(
        this.props.authToken,
        this.props.scPersona,
        record.scPersona
      );

      notification.success({
        message: USERS_TITLE,
        description: response.message,
        duration: 6,
      });

      this.getData();
    } catch {
      this.setState({
        ...this.state,
        loading: true,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    const { authToken, scPersona } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaUsuarios(authToken, scPersona);
      console.log(response, 'Usuarios');
      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  showModalEditar = (user) => {
    this.setState({
      ...this.state,
      userEdit: user,
      visibleEditModal: true,
    });
  };

  handleEditModalOnClose = () => {
    this.setState({
      ...this.state,
      cuentaEdit: {},
      visibleEditModal: false,
    });
  };

  handleModalOnFinish = (e) => {
    this.setState({
      ...this.state,
      cuentaEdit: {},
      visibleEditModal: false,
    });
    this.getData();
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { loading, resultado, userEdit, visibleEditModal } = this.state;
    const { authToken, scPersona, tipoAsociado, children } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <Row gutter={[16, 32]} align='middle'>
          <Col flex='auto'>
            <Space align='baseline' className='sub-title'>
              Lista de Usuarios
            </Space>
          </Col>
          <Col flex='none'>
            <span className='button'>
              <UserNewModal
                tipoAsociado={tipoAsociado}
                authToken={authToken}
                scPersona={scPersona}
                onEditModalChange={this.showModalEditar}
                onFinish={this.handleModalOnFinish}
              />
            </span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col flex='auto'>
            <div className='table-container'>
              <Table
                rowKey={(record) => record.scPersona}
                columns={this.columnas}
                dataSource={resultado}
                pagination={{
                  defaultPageSize: 10,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} de ${total} registros`,
                  pageSizeOptions: ['10', '20', '30'],
                  position: ['none', 'bottomRight'],
                }}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>{children}</Row>
        {visibleEditModal && (
          <UserEditModal
            tipoAsociado={tipoAsociado}
            scPersona={scPersona}
            authToken={authToken}
            data={userEdit}
            visible={visibleEditModal}
            onClose={this.handleEditModalOnClose}
            onFinish={this.handleModalOnFinish}
          />
        )}
      </Spin>
    );
  }
}

export default Users;
