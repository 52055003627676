import {
  FilePdfTwoTone,
  FileTextOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Space, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import { listarCompanias } from '../../../../services/admin/operation';
import {
  exportMandatoPDF,
  exportMandatoXML,
  listarMandatos,
  listarMandatosExcel,
} from '../../../../services/admin/radian/radianService';
import history from '../../../../services/history';
import {
  setDownload,
  setExcel,
  setExportPDF,
} from '../../../../utilities/downloadTools';
import { sorterDate, sorterText } from '../../../../utilities/textTools';
import MandateFilterForm from './mandateFiltreForm/mandateFiltreForm';
import './mandates.scss';

const initialState = {
  form: {
    scCompania: '',
    fechaIngresoDesde: '',
    fechaIngresoHasta: '',
  },
  loading: false,
  resultado: [],
  sort: 'feIngreso',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  companiaSelect: {
    fetching: false,
    options: [],
  },
};

class Mandate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'CONSECUTIVO',
        dataIndex: 'scMandato',
        sorter: (a, b) => sorterText(a.scMandato, b.scMandato),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'nombreCompania',
        sorter: (a, b) => sorterText(a.nombreCompania, b.nombreCompania),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoMandato',
        sorter: (a, b) => sorterText(a.dsEstadoMandato, b.dsEstadoMandato),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE INGRESO',
        dataIndex: 'feIngreso',
        sorter: (a, b) => sorterDate(a.feIngreso, b.feIngreso),
        defaultSortOrder: 'descend',
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE INICIO',
        dataIndex: 'feInicio',
        sorter: (a, b) => sorterDate(a.feInicio, b.feInicio),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <FormOutlined
            title='Editar Mandato'
            onClick={() => this.showEditMandato(record)}
            className='info'
          />
        ),
        showSorterTooltip: false,
      },
      {
        title: 'DESCARGAR MANDATO',
        dataIndex: '',
        render: (record) => (
          <FilePdfTwoTone
            title='Descargar Mandato'
            onClick={() => this.exportPDF(record.scMandato)}
            className='info'
          />
        ),
        showSorterTooltip: false,
      },
      {
        title: 'DESCARGAR XML',
        dataIndex: '',
        render: (record) => (
          <FileTextOutlined
            title='Descargar XML'
            onClick={() => this.exportXML(record.scMandato)}
            className='info'
          />
        ),
        showSorterTooltip: false,
      },
    ];

    this.formSearch = React.createRef();
  }

  componentDidMount() {
    this.props.menuHandler('110');
    this.getData();
  }

  showEditMandato = (record) => {
    history.push(
      `${process.env.PUBLIC_URL}/radian/mandate/edit/${Buffer.from(
        record.scMandato
      ).toString('base64')}}`
    );
  };

  handleOnSearchCompania = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { companiaSelect } = this.state;

      this.setState({
        ...this.state,
        companiaSelect: {
          ...companiaSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarCompanias(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      loading: false,
      form: {
        scCompania: value.scCompania,
        fechaIngresoDesde: value.fechaRango
          ? value.fechaRango[0]
          : initialState.form.fechaIngresoDesde,
        fechaIngresoHasta: value.fechaRango
          ? value.fechaRango[1]
          : initialState.form.fechaIngresoHasta,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });
    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });
    try {
      const response = await listarMandatos(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        loading: false,
        resultado: response.data,
        total: response.metadata.count,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarMandatosExcel(
        this.props.authToken,
        this.getFiltro()
      );

      setExcel(response, 'Listado_de_Mandato');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportPDF = async (scMandato) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await exportMandatoPDF(this.props.authToken, scMandato);

      setExportPDF(response, `Documento Mandato ${scMandato}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportXML = async (scMandato) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await exportMandatoXML(this.props.authToken, scMandato);

      setDownload(response, `Documento Mandato ${scMandato}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      companiaSelect,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='mandate-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <MandateFilterForm
              handleSubmit={this.handleSubmit}
              companiaSelect={companiaSelect}
              handleOnSearchCompania={this.handleOnSearchCompania}
            />

            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de Mandatos
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                    <Button
                      size='small'
                      onClick={() =>
                        history.push(
                          `${process.env.PUBLIC_URL}/radian/mandate/new`
                        )
                      }
                      className='btn-agregar'
                    >
                      + Agregar nuevo mandato
                    </Button>
                  </Space>
                </Col>
                {resultado.length > 0 ? (
                  <Col flex='none'>
                    <Space align='baseline'>
                      <Button type='link' onClick={this.exportExcel}>
                        Exportar a excel
                      </Button>
                    </Space>
                  </Col>
                ) : null}
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Mandate);
