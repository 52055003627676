import axios from 'axios'
import { middleWare } from '../middleware'
import queryString from 'query-string'

const baseUrl = process.env.REACT_APP_SERVICES_URL_PAGADOR

export const listarFondeadores = (authtoken, scPagador, scProveedor) => {

  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/ofertas/listarPreOferta/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}
export const listarOferta = (authtoken, scPagador, scProveedor, scFondeador) => {

  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/ofertas/listarOfertaCompra/${scProveedor}/${scFondeador}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarOfertaExcel = (authtoken, scPagador, scProveedor, scFondeador) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/ofertas/listarOfertaCompra/exportar/xlsx/${scProveedor}/${scFondeador}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}