import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const grabarDocumentosAnexos = (authtoken, data, scCompania) => {
  const formData = new FormData();
  formData.append('CA', data[0].originFileObj);
  formData.append('CCB', data[1].originFileObj);
  formData.append('CC', data[2].originFileObj);
  formData.append('RUT', data[3].originFileObj);
  formData.append('CCRL', data[4].originFileObj);


  return middleWare(
    axios({
      url: `${baseUrl}/api/admin/docAnexos/cargar-archivos/${scCompania}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export const listarDocumentosAnexos = (authToken,scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/admin/docAnexos/listar-archivos/${scCompania}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authToken}`
      },
    })
  );
};

export const descargarDocumentos = (authToken,scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/admin/docAnexos/descargar-archivos/${scCompania}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authToken}`
      },
    })
  );
};