import { Modal, Spin } from 'antd';
import React, { Component } from 'react';
import { listarCompanias } from '../../../../../services/admin/operation';
import {
  grabarEventosDE,
  listarEventosDE,
} from '../../../../../services/admin/radian/billsEventsService';
import EventsForm from '../eventsForm/eventsForm';

const initialState = {
  loading: false,
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  tiposEventos: [],
  data: {
    scSenderParty: undefined,
    scReceiverParty: undefined,
  },
  senderPartySelect: {
    fetching: false,
    options: [],
  },
  receiverPartySelect: {
    fetching: false,
    options: [],
  },
  visible: false,
};

class EventNewModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const { authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const respEventos = await listarEventosDE(authToken);

      this.setState({
        ...this.state,
        loading: false,
        tiposEventos: respEventos.data,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        tiposEventos: [],
      });
    }
  };

  handleOnSenderPartySearch = async (nombre) => {
    const { senderPartySelect } = this.state;
    if (nombre && nombre.length >= 3) {
      this.setState({
        ...this.state,
        senderPartySelect: {
          ...senderPartySelect,
          fetching: true,
        },
      });

      try {
        const response = await listarCompanias(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          senderPartySelect: {
            ...senderPartySelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          senderPartySelect: {
            ...senderPartySelect,
            options: [],
            fetching: false,
          },
        });
      }
    } else {
      this.setState({
        ...this.state,
        senderPartySelect: {
          ...senderPartySelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleOnReceiverPartySearch = async (nombre) => {
    const { receiverPartySelect } = this.state;
    if (nombre && nombre.length >= 3) {
      this.setState({
        ...this.state,
        receiverPartySelect: {
          ...receiverPartySelect,
          fetching: true,
        },
      });

      try {
        const response = await listarCompanias(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          receiverPartySelect: {
            ...receiverPartySelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          receiverPartySelect: {
            ...receiverPartySelect,
            options: [],
            fetching: false,
          },
        });
      }
    } else {
      this.setState({
        ...this.state,
        receiverPartySelect: {
          ...receiverPartySelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleOnFinish = async (form) => {
    const {
      factura: { scFactura },
      authToken,
      onClose,
    } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await grabarEventosDE(authToken, scFactura, form);
      onClose(response.data);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnOpenModal = () => {
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  handleOnChangeTipoEvento = async (e) => {
    const { factura } = this.props;
    const { senderPartySelect, receiverPartySelect } = this.state;
    let senderParty = {
      scPersona: factura.scProveedor,
      nombre: factura.nombreProveedor,
    };
    let receiverParty = {
      scPersona: factura.scPagador,
      nombre: factura.nombrePagador,
    };

    this.setState({
      ...this.state,
      senderPartySelect: {
        ...senderPartySelect,
        options: [],
        fetching: true,
      },
      receiverPartySelect: {
        ...receiverPartySelect,
        options: [],
        fetching: true,
      },
      data: {
        scSenderParty: undefined,
        scReceiverParty: undefined,
      },
    });

    try {
      if (e === 'PTDE' || e === 'PG') {
        senderParty = {
          scPersona: factura.scPagador,
          nombre: factura.nombrePagador,
        };
      }

      if (e === 'EPP' || e === 'TDE') {
        receiverParty = {
          scPersona: factura.scFondeador,
          nombre: factura.nombreFondeador,
        };
      }

      const responseSender = await listarCompanias(this.props.authToken, {
        nombre: senderParty.nombre,
      });

      const responseReceiver = await listarCompanias(this.props.authToken, {
        nombre: receiverParty.nombre,
      });

      this.setState({
        ...this.state,
        senderPartySelect: {
          ...senderPartySelect,
          options: responseSender.data,
          fetching: false,
        },
        receiverPartySelect: {
          ...receiverPartySelect,
          options: responseReceiver.data,
          fetching: true,
        },
        data: {
          scSenderParty: senderParty.scPersona,
          scReceiverParty: receiverParty.scPersona,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        senderPartySelect: {
          ...senderPartySelect,
          options: [],
          fetching: false,
        },
        receiverPartySelect: {
          ...receiverPartySelect,
          options: [],
          fetching: false,
        },
        data: {
          scSenderParty: undefined,
          scReceiverParty: undefined,
        },
      });
    }
  };

  render() {
    const {
      loading,
      tiposEventos,
      senderPartySelect,
      receiverPartySelect,
      data,
    } = this.state;

    const { visible, onClose } = this.props;

    return (
      <Modal
        title='Crear Nuevo Evento'
        visible={visible}
        className='modal-style'
        footer={null}
        onCancel={() => onClose()}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <EventsForm
            data={data}
            tiposEventos={tiposEventos}
            onSenderPartySearch={this.handleOnSenderPartySearch}
            senderPartySelect={senderPartySelect}
            onReceiverPartySearch={this.handleOnReceiverPartySearch}
            receiverPartySelect={receiverPartySelect}
            onChangeTipoEvento={this.handleOnChangeTipoEvento}
            onFinish={this.handleOnFinish}
          />
        </Spin>
      </Modal>
    );
  }
}

export default EventNewModal;
