import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

const upload = (authtoken, scProveedor, files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file, file.filename);
  });

  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cargar-archivoFall`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

const confirm = (authtoken, scProveedor, filename, extensionArchivo, scPersona) => {
  let scPersonaModifica = scPersona;
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cargar-archivoFall/aceptar/${filename}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: {extensionArchivo, scPersonaModifica}
    })
  );
};

const guardar = (authtoken, scProveedor, values, nombreArchivo, scPersona) => {
  if (values.tipoCalendarioPago === 'M') {
    values.calendarioPagoM = Object.values(values.calendarioPagoM);
  }

  if (values.tipoCalendarioPago === 'C') {
    values.calendarioPagoC = values.calendarioPagoC.format('YYYY-MM-DD');
  }
  values.scPersonaModifica = scPersona
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/guardarFacturaFall/${nombreArchivo}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: values,
    })
  );
};

const guardarAgile = (authtoken, scProveedor, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/guardarFacturaAgileFall`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

const downloadTemplate = (authtoken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/plantillaFall`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export default {
  guardarAgile,
  upload,
  confirm,
  guardar,
  downloadTemplate,
};
