import React, { Component } from 'react';
import CondicionesInfo from './condicionesInfo';
import { Modal } from 'antd';


class CondicionesModal extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };
  render() {
    const { visible, data } = this.props;

    return (
      <Modal
        title={<h3>Condiciones de {data.nombre}</h3>}
        visible={visible}
        onCancel={this.handleOnClose}
        width={800}
        footer={false}
      >

      <CondicionesInfo dataFondedor={data}/>

      </Modal>
    );
  }
}

export default CondicionesModal;
