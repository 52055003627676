import { Button, Col, Form, Input, Modal, Row } from 'antd';
import equal from 'fast-deep-equal';
import React, { Component } from 'react';
import { requiredValueMessage } from '../../utilities/admin_validation_messages';
import { length } from '../../utilities/validate/textValidate';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

export const initialValuesPersonaRef = {
  nombre: '',
  id: '',
  telefono: '',
  parentesco: '',
  scReferencia: '',
  key: '',
};

const rules = {
  nombre: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  id: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 20),
    },
    {
      pattern: new RegExp('^[0-9a-zA-Z-]*$'),
      message: 'El valor no tiene el formato correcto',
    },
  ],
  telefono: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  parentesco: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
};

class PersonalReferenceModal extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleOnFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish({ ...formValue });
  };

  componentDidUpdate(props, state) {
    if (this.props.visible !== props.visible && this.props.visible) {
      this.formRef.current.setFieldsValue({ ...initialValuesPersonaRef });
      if (!equal(this.props.data, props.data)) {
        this.formRef.current.setFieldsValue({ ...this.props.data });
      }
    }
  }

  render() {
    const { onClose, visible } = this.props;
    return (
      <Modal
        title='Referencias comerciales'
        visible={visible}
        onCancel={onClose}
        width={500}
        footer={false}
      >
        <Form
          ref={this.formRef}
          name='shareholder_data'
          layout='vertical'
          initialValues={initialValuesPersonaRef}
          onFinish={this.handleOnFinish}
        >
          <Form.Item
            name='nombre'
            label='Nombre y apellidos'
            rules={rules['nombre'] || ''}
          >
            <Input maxLength={100} />
          </Form.Item>

          <Form.Item name='scReferencia' hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item name='key' hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item
            name='id'
            label='Nro. identificación'
            rules={rules['id'] || ''}
          >
            <Input maxLength={20} />
          </Form.Item>

          <Form.Item
            name='telefono'
            label='Teléfono'
            rules={rules['telefono'] || ''}
          >
            <Input maxLength={20} />
          </Form.Item>

          <Form.Item
            name='parentesco'
            label='Parentesco'
            rules={rules['parentesco'] || ''}
          >
            <Input maxLength={100} />
          </Form.Item>

          <Row justify='center' align='middle'>
            <Col span={6}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
export default PersonalReferenceModal;
