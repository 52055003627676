import React from 'react';
import { Form, Input, Row, Col, Button, Select, Spin } from 'antd';
import equal from 'fast-deep-equal';

import { requiredValueMessage } from '../../../../../utilities/admin_validation_messages';
import { length } from '../../../../../utilities/validate/textValidate';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  dirEntidad: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisEntidad: [{ required: true, message: requiredValueMessage() }],
  cdDepEntidad: [{ required: true, message: requiredValueMessage() }],
  cdMunEntidad: [{ required: true, message: requiredValueMessage() }],
  tel1Entidad: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  ext1Entidad: [
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 10),
    },
  ],
  tel2Entidad: [
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  ext2Entidad: [
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 10),
    },
  ],
  emailEntidad: [
    { required: true, message: requiredValueMessage() },
    {
      pattern:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 200),
    },
  ],
  pagWebEntidad: [
    {
      validator: (_, value) => length(value, 5, 255),
    },
  ],
};
class PersonAddress extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleOnChangePais = (value) => {
    const { onChangePais } = this.props;
    this.formRef.current.setFieldsValue({
      cdDepEntidad: undefined,
      cdMunEntidad: undefined,
    });
    onChangePais(value);
  };

  handleOnChangeDepartamento = (value) => {
    const { onChangeDepartamento } = this.props;

    const cdPais = this.formRef.current.getFieldValue('cdPaisEntidad');

    this.formRef.current.setFieldsValue({
      cdMunEntidad: undefined,
    });

    onChangeDepartamento(value, cdPais);
  };

  handleOnFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish('3', formValue);
  };

  componentDidUpdate(props, state) {
    if (!equal(props.person, this.props.person)) {
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        ...this.props.person,
      });
    }
  }

  render() {
    const { paises, departamentos, municipios } = this.props;

    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Form
            ref={this.formRef}
            name='address_data'
            layout='vertical'
            onFinish={this.handleOnFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='dirEntidad'
                  label='Dirección'
                  rules={rules['dirEntidad'] || ''}
                >
                  <Input minLength={5} maxLength={300} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='cdPaisEntidad'
                  label='País'
                  rules={rules['cdPaisEntidad'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.handleOnChangePais}
                  >
                    {paises.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdDepEntidad'
                  label='Departamento'
                  rules={rules['cdDepEntidad'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      departamentos.fetching ? <Spin size='small' /> : null
                    }
                    onChange={this.handleOnChangeDepartamento}
                  >
                    {departamentos.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='cdMunEntidad'
                  label='Ciudad/Municipio'
                  rules={rules['cdMunEntidad'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      municipios.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {municipios.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='tel1Entidad'
                  label='Teléfono'
                  rules={rules['tel1Entidad'] || ''}
                >
                  <Input minLength={7} maxLength={20} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='ext1Entidad'
                  label='Extensión'
                  rules={rules['ext1Entidad'] || ''}
                >
                  <Input minLength={1} maxLength={10} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='tel2Entidad'
                  label='Teléfono 2'
                  rules={rules['tel2Entidad'] || ''}
                >
                  <Input minLength={7} maxLength={20} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='ext2Entidad'
                  label='Extensión'
                  rules={rules['ext2Entidad'] || ''}
                >
                  <Input minLength={1} maxLength={10} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='emailEntidad'
                  label='Correo electrónico contacto'
                  rules={rules['emailEntidad'] || ''}
                >
                  <Input minLength={5} maxLength={200} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='pagWebEntidad'
                  label='Página web'
                  rules={rules['pagWebEntidad'] || ''}
                >
                  <Input minLength={5} maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
            <Row align='middle' justify='center'>
              <Col span={6}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Siguiente
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

export default PersonAddress;
