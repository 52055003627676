import React from 'react';
import equal from 'fast-deep-equal'
import { connect } from 'react-redux'
import { Row, Col, Input, Button, notification } from 'antd';
import { requestTokenAction, setToken } from '../../store/actions/proveedor/affitokenActtions'
import './affitokenValidator.scss'
import { AFFITOKEN_SEND_SUCCESS } from '../../utilities/messages'

let interval = null
const prependZero = (number) => {
  if (number <= 9)
    return "0" + number;
  else
    return number;
}

class AffitokenValidator extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      affitoken: '',
      readOnly: false,
      timer: 0,
      interval: null
    }
    this.noPasteInputRef = React.createRef();
  }
  componentDidMount() {

    this.noPasteInputRef.current.input.onpaste = (e) => {
      e.preventDefault()
      return false;
    }
    if (!this.props.disableAutoGenerate) {
      this.requestNewToken()
    }
  }

  componentDidUpdate(props, state) {
    if (this.state.timer === 0) {
      clearInterval(interval)
    }
  }
  componentWillUnmount() {
    clearInterval(interval)
  }

  requestNewToken() {
    if (this.state.timer === 0) {
      this.props.requestTokenAction(this.props.authentication)
      let date = new Date()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      this.setState({
        ...this.state,
        timer: 180 - (((minutes % 3) * 60) + seconds)
      })

      notification.success({
        message: 'Enviado',
        description: AFFITOKEN_SEND_SUCCESS,
        duration: 6
      })
      interval = setInterval(() => {
        this.setState({
          ...this.state,
          timer: this.state.timer - 1
        })
      }, 1000)
    }
  }

  tokenChange = (e) => {
    if (e.target.value.length > 5) {
      this.props.setToken(e.target.value)
    }
  }

  render() {
    return (
      <div>
        <Row className="account-container" justify="center" align="middle">
          <Col span={7}>
            <div className="token-label">
              Ingresar Affitoken
            </div>
          </Col>
          <Col span={6}>
            <div className="token-input">
              <Input ref={this.noPasteInputRef} readOnly={this.state.readOnly} allowClear onChange={this.tokenChange} size="small" />
            </div>
          </Col>
          <Col span={6}>
            <div className="token-label">
              <div className="time">
                <p>{`${prependZero(Math.floor(this.state.timer / 60))}:${prependZero(this.state.timer % 60)}`}</p>
              </div>
            </div>
          </Col>
          <Col span={5}>
            <div className="token-label">
              <Button className={this.state.timer === 0 ? 'btn-next active' : 'btn-next disabled'} onClick={() => { this.requestNewToken() }}>Solicitar nuevo Affitoken</Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: {
      authtoken: state.auth.auth.access_token,
      company: state.billing.company
    },
    affitoken: state.affitoken
  }
}


export default connect(mapStateToProps, { requestTokenAction, setToken })(AffitokenValidator);
