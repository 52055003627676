import Axios from "axios";
import { middleWare } from "../middleware";
import QueryString from "qs";


const baseUrl = process.env.REACT_APP_SERVICES_URL_FACTURACION;




export const listarTipoFacturacion = (authtoken) =>{
    return middleWare(
        Axios({
            url:`${baseUrl}/api/listar-tipo-factura`,
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data:null
        })
    );
};

export const listarCompanias = (authtoken,nombre) =>{
    return middleWare(
        Axios({
            url:`${baseUrl}/api/listar-companias?${QueryString.stringify(nombre)}`,
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data:null
        })
    );
};