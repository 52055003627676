import React from 'react';
import { Modal } from 'antd';
import Text from 'antd/lib/typography/Text';


const ModalCancelInvoice = (props) => {
  return (
    <div className='header-container'>   
    
     <Modal
      title={<h4>AVISO</h4>}
      visible={props.visibleConfirm}
      onCancel={props.hiddenModalCancelInvoice}
      onOk={props.cancelInvoice}
    > 
    <Text type="strong" level={4}>¿DESEAS CONTINUAR CON LA OPERACION?</Text>
    
    </Modal>
    </div>
  );
};
export default ModalCancelInvoice;
