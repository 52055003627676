import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL;

export const actualizarFichaJuridica = (authToken, scCompania, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridica/actualizarFichaJuridica/${scCompania}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};
export const buscarFichaJuridica = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaJuridica/buscarFichaJuridica/${scCompania}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};
export const actualizarFichaNatural = (authToken, scCompania, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaNatural/actualizarFichaNatural/${scCompania}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};
export const buscarFichaNatural = (authToken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaNatural/buscarFichaNatural/${scCompania}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};
export const consultaTiposActividades = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/actividadEconomica/buscarActividadEconomica?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const buscarOperacionesLey = (authToken,scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/medios/buscarOperacionesLey/${scProveedor}`,
      method:'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      data:null
    })
  )
};

export const grabarDatosOperacionesLey = (authToken,scProveedor,dataMedio) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/medios/actualizarOperacionesLey/${scProveedor}`,
      method:'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}` 
    },
    data:dataMedio
    })
  )
}

export const grabarSnTutorial = (authToken,scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/medios/actualizarSnTutorial/${scProveedor}`,
      method:'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}` 
    },
    data:null
    })
  )
}
