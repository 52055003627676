import { combineReducers } from 'redux'
import authReducer from './authReducer'
import billingReducer from "./proveedor/billingReducer";
import affitokenReducer from './proveedor/affitokenReducer'
import reportsReducer from './proveedor/reportsReducer'
import personReducer from './proveedor/personReducer'
import companyReducer from './pagador/companyReducer'
import adminPersonReducer from './admin/personReducer'

export default combineReducers({
    auth: authReducer,
    billing: billingReducer,
    affitoken: affitokenReducer,
    reports: reportsReducer,
    person: personReducer,
    company: companyReducer,
    adminPerson: adminPersonReducer
})