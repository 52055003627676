import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import {
  currencyFormat,
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';
import TableReport from '../../../../../components/tableReport/tableReport';

import { listaFacturasDescontadasExcel } from '../../../../../services/admin/reportsDiscountedBills';
import { setExcel } from '../../../../../utilities/downloadTools';
import {
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../../services/admin/reports/quotasService';
import moment from 'moment';
import {
  listarFacturasAnuladas,
  listarFacturasAnuladasExcel,
} from '../../../../../services/admin/reportsLoadedBills';
import VoidedBillsFilterForm from './voidedBillsFilterForm/voidedBillsFilterForm';

const initialState = {
  form: {
    fechaDesde: moment(),
    fechaHasta: moment(),
    scGrupo: '',
    scPagador: '',
    scProveedor: '',
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 6,
    },
    {
      id: 'totalVrFactura',
      value: currencyFormat(0),
      index: 'vrFactura',
      colSpan: 4,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
};

class VoidedBills extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NOMBRE DEL CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => sorterText(a.nombreConvenio, b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: '# FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENTA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },

      {
        title: 'TIPO DE DOCUMENTO',
        dataIndex: 'dsDebeHaber',
        sorter: (a, b) => sorterText(a.dsDebeHaber, b.dsDebeHaber),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR FACTURA',
        dataIndex: 'vrFactura',
        render: (value) => <span>{currencyFormat(value)} </span>,
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: 'JUSTIFICACIÓN',
        dataIndex: 'dsJustificacion',
        sorter: (a, b) => sorterText(a.dsJustificacion, b.dsJustificacion),
        showSorterTooltip: false,
      },
      {
        title: 'ANULADO POR',
        dataIndex: 'nombrePersonaModifica',
        sorter: (a, b) =>
          sorterText(a.nombrePersonaModifica, b.nombrePersonaModifica),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE ANULACIÓN',
        dataIndex: 'feModifica',
        sorter: (a, b) => sorterText(a.feModifica, b.feModifica),
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scGrupo: value.scGrupo,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        fechaDesde: value.fechaRango[0],
        fechaHasta: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listarFacturasAnuladas(
          this.props.authToken,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = (
              <span className='format-number'>
                {currencyFormat(response.metadata.sumatorias[res.id])}
              </span>
            );
          }

          return res;
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await listarFacturasAnuladasExcel(
          this.props.authToken,
          this.getFiltro()
        );
        setExcel(response, `${moment().format('YYYY_MM_DD')}`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      grupoSelect,
      pagadorSelect,
      proveedorSelect,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <VoidedBillsFilterForm
            grupoSelect={grupoSelect}
            pagadorSelect={pagadorSelect}
            proveedorSelect={proveedorSelect}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnSearchGrupo={this.handleOnSearchGrupo}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default VoidedBills;
