import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';
import TableReport from '../../../../../components/tableReport/tableReport';
import {
  listarFacturasF4All,
  listarFacturasF4AllExcel,
} from '../../../../../services/admin/reportsDiscountedBills';
import { setExcel } from '../../../../../utilities/downloadTools';
import {
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
  listarConvenios,
  listarFondeadores,
} from '../../../../../services/admin/reports/quotasService';
import moment from 'moment';
import F4AllFilterForm from './f4AllFilterForm/f4AllFilterForm';

const initialState = {
  form: {
    fechaDesdeCompra: moment(),
    fechaHastaCompra: moment(),
    scGrupo: '',
    scPagador: '',
    scProveedor: '',
    cdFactura: '',
    scConvenio: '',
    scFondeador: '',
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 3,
    },
    {
      id: 'totalVrFactura',
      value: currencyFormat(0),
      index: 'vrFactura',
      colSpan: 5,
    },
    {
      id: 'totalVrOferta',
      value: currencyFormat(0),
      index: 'vrOferta',
      colSpan: 1,
    },
    {
      id: 'totalVrColchonFd',
      value: currencyFormat(0),
      index: 'vrColchonFd',
      colSpan: 2,
    },
    {
      id: 'totalVrPago',
      value: currencyFormat(0),
      index: 'vrPago',
      colSpan: 1,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  buttonOptions: [],
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
};

class F4All extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'NÚMERO FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REPORTADO',
        dataIndex: 'vrFactura',
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        render: (value) => <span>{currencyFormat(value)} </span>,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA EMISIÓN',
        dataIndex: 'feEmision',
        sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ESPERADA DE PAGO',
        dataIndex: 'feEstimadaPago',
        sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENTA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR OFERTA',
        dataIndex: 'vrOferta',
        sorter: (a, b) => a.vrOferta - b.vrOferta,
        render: (value) => <span>{currencyFormat(value)} </span>,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR RETENIDO',
        dataIndex: 'vrColchonFd',
        sorter: (a, b) => a.vrColchonFd - b.vrColchonFd,
        render: (value) => <span>{currencyFormat(value)} </span>,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA PAGO',
        dataIndex: 'fePago',
        sorter: (a, b) => sorterDate(a.fePago, b.fePago),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PAGADO',
        dataIndex: 'vrPago',
        sorter: (a, b) => a.vrPago - b.vrPago,
        render: (value) => <span>{currencyFormat(value)} </span>,
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarConvenios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scGrupo: value.scGrupo,
        scConvenio: value.scConvenio,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        scFondeador: value.scFondeador,
        cdFactura: value.cdFactura,
        fechaDesdeCompra: value.fechaRango[0],
        fechaHastaCompra: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listarFacturasF4All(
          this.props.authToken,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = (
              <span className='format-number'>
                {currencyFormat(response.metadata.sumatorias[res.id])}
              </span>
            );
          }

          return res;
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await listarFacturasF4AllExcel(
          this.props.authToken,
          this.getFiltro()
        );
        setExcel(response, `Facturas_F4All`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'Los campos fecha inicial y fecha final son obligatorios',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  componentDidMount() {
    this.searchData();
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      grupoSelect,
      pagadorSelect,
      proveedorSelect,
      convenioSelect,
      fondeadorSelect,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <F4AllFilterForm
            grupoSelect={grupoSelect}
            pagadorSelect={pagadorSelect}
            proveedorSelect={proveedorSelect}
            convenioSelect={convenioSelect}
            fondeadorSelect={fondeadorSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnSearchGrupo={this.handleOnSearchGrupo}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleOnSearchFondeador={this.handleOnSearchFondeador}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default F4All;
