import { Button, Card, Col, Divider, Row, Space, Spin, Popover, } from 'antd';
import {
  InfoCircleTwoTone,
} from '@ant-design/icons';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  listarProveedores,
  listarPagadores
} from '../../../../services/admin/operation';
import {
  listarGruposEmpresariales,
} from '../../../../services/admin/reports/quotasService';
import {
  listarNotificaciones,
  listarNotificacionesExcel,
} from '../../../../services/admin/file/notificationService';
import {
  setExcel,
} from '../../../../utilities/downloadTools';
import {
  sorterDate,
  sorterText,
  currencyFormat
} from '../../../../utilities/textTools';
import NotificationsFilterForm from './notificationsFilterForm/notificationsFilterForm';
import './notificationsReport.scss';

const initialState = {
  form: {
    scProveedor: '',
    scPagador: '',
    scGrupo: '',
    fechaIngresoDesde: moment(),
    fechaIngresoHasta: moment(),
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  grupoSelect: {
    fetching: false,
    options: [],
  },
};

class notificationsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'NIT',
        dataIndex: 'idProveedor',
        sorter: (a, b) => sorterText(a.idProveedor, b.idProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'NRO FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR',
        dataIndex: 'vrFactura',
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA',
        dataIndex: 'feCreacion',
        sorter: (a, b) => sorterDate(a.feCreacion, b.feCreacion),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO',
        dataIndex: 'tipo',
        sorter: (a, b) => sorterText(a.tipo, b.tipo),
        showSorterTooltip: false,
      },
      {
        title: 'ORIGEN',
        dataIndex: 'origen',
        sorter: (a, b) => sorterText(a.origen, b.origen),
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: 'mensaje',
        sorter: (a, b) => sorterDate(a.mensaje, b.mensaje),
        showSorterTooltip: false,
        render: (text) => (
          <Popover placement='topRight' content={text} title='Mensaje'>
            <InfoCircleTwoTone className='info' />
          </Popover>
        ),
      },
    ];
    this.formSearch = React.createRef();
  }

  componentDidMount() {
    this.props.menuHandler('83');
    this.getData();
  }

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        grupoSelect: {
          ...grupoSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      loading: false,
      form: {
        scProveedor: value.scProveedor,
        scPagador: value.scPagador,
        scGrupo: value.scGrupo,
        fechaIngresoDesde: value.fechaRango
          ? value.fechaRango[0]
          : initialState.form.fechaIngresoDesde,
        fechaIngresoHasta: value.fechaRango
          ? value.fechaRango[1]
          : initialState.form.fechaIngresoHasta,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });
    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });
    try {
      const response = await listarNotificaciones(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        loading: false,
        resultado: response.data,
        total: response.metadata.count,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarNotificacionesExcel(
        this.props.authToken,
        this.getFiltro()
      );

      setExcel(response, 'Reporte_de_Notificaciones');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      proveedorSelect,
      pagadorSelect,
      grupoSelect,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='mandate-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <NotificationsFilterForm
              handleSubmit={this.handleSubmit}
              proveedorSelect={proveedorSelect}
              pagadorSelect={pagadorSelect}
              grupoSelect={grupoSelect}
              handleOnSearchProveedor={this.handleOnSearchProveedor}
              handleOnSearchPagador={this.handleOnSearchPagador}
              handleOnSearchGrupo={this.handleOnSearchGrupo}
            />

            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de Notificaciones
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                  </Space>
                </Col>
                {resultado.length > 0 ? (
                  <Col flex='none'>
                    <Space align='baseline'>
                      <Button type='link' onClick={this.exportExcel}>
                        Exportar a excel
                      </Button>
                    </Space>
                  </Col>
                ) : null}
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(notificationsReport);
