import { notification } from 'antd';
import store from '../store';
import { LOGIN_CLOSE } from '../store/actions/types';

export const middleWare = (axios) => {
  return axios
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202
      ) {
        return response.data;
      } else {
        notification.error({
          message: 'Error',
          description: response.data.message,
          duration: 5,
        });
      }
    })
    .catch((error) => {
      try {
        if (error.response.status === 401) {
          localStorage.removeItem('auth-token');
          store.dispatch({ type: LOGIN_CLOSE });
          notification.error({
            message: 'Sesión Finalizada',
            description:
              'La sesión ha finalizado, por favor ingrese nuevamente',
            duration: 6,
          });

          window.location.href = `${process.env.PUBLIC_URL}/`;
        }

        if (error.response.status === 400) {
          if (error.response.data.type == 'application/json') {
            let reader = new FileReader();
            reader.onload = function () {
              let message = JSON.parse(this.result).message;
              notification.info({
                message: message,
                duration: 5,
              });
            };
            reader.readAsText(error.response.data);
          } else {
            if (Array.isArray(error.response.data.errors)) {
              error.response.data.errors.forEach((element) => {
                notification.warning({
                  message: 'Cuidado',
                  description: `El campo ${element.field} ${element.message}`,
                  duration: 5,
                });
              });
            } else {
              if (
                error.response.data.message ==
                "Required request part 'file' is not present"
              ) {
                notification.error({
                  message: 'Error',
                  description:
                    'El archivo requerido no está presente en la solicitud',
                  duration: 5,
                });
              } else {
                notification.info({
                  message: error.response.data.message,
                  duration: 5,
                });
              }
            }
          }
        }
        if (error.response.status === 500) {
          if (error.response.data.type == 'application/json') {
            let reader = new FileReader();
            reader.onload = function () {
              let message = JSON.parse(this.result).message;
              notification.error({
                message: 'Error',
                description:
                  'se presento un error inesperado. Si vuelve a pasar favor contactar con el administrador',
                duration: 5,
              });
            };
            reader.readAsText(error.response.data);
          } else {
            notification.error({
              message: 'Error',
              description:
                'se presento un error inesperado. Si vuelve a pasar favor contactar con el administrador',
              duration: 5,
            });
          }
        }
        if (error.response.status === 404) {
          if (error.response.data instanceof Blob) {
            const fr = new FileReader();
            fr.addEventListener('load', (e) => {
              notification.error({
                message: 'Error',
                description: JSON.parse(fr.result).message,
                duration: 5,
              });
            });
            fr.readAsText(error.response.data);
          } else {
            notification.error({
              message: 'Error',
              description: error.response.data.message,
              duration: 5,
            });
          }
        }

        if (error.response.status === 403) {
          if (error.response.data instanceof Blob) {
            const fr = new FileReader();
            fr.addEventListener('load', (e) => {
              notification.error({
                message: 'Error',
                description: JSON.parse(fr.result).message,
                duration: 5,
              });
            });
            fr.readAsText(error.response.data);
          } else {
            notification.error({
              message: 'Error',
              description: error.response.data.error_description,
              duration: 5,
            });
          }
        }

        return Promise.reject(
          new Error(`se presento un error en el servidor: ${error.message}`)
        );
      } catch (err) {
        notification.error({
          message: 'Error',
          description: 'se presento un error en la conexion con el servidor',
          duration: 5,
        });

        return Promise.reject(
          new Error(
            `se presento un error en la conexion con el servidor: ${error.message}`
          )
        );
      }
    });
};
