import React, { Component } from 'react';

import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Divider,
  Spin,
  Switch,
  Space,
} from 'antd';
import { listaConexiones } from '../../../../services/admin/erpConectionService';
import './erpConection.scss';
import { connect } from 'react-redux';
import { BUSQUEDA_TITLE } from '../../../../utilities/messages';
import TableReport from '../../../../components/tableReport/tableReport';
import { sorterText } from '../../../../utilities/textTools';
import ErpConectionNewModal from './erpConectionNewModal/erpConectionNewModal';
import ErpConectionEditModal from './erpConectionEditModal/erpConectionEditModal';

const IDENTIFICATION_FILTRO = 'Identificación';
const NAME_FILTRO = 'Nombre/Razón social';

const initialState = {
  loading: false,
  conexionEdit: {},
  visibleEditModal: false,
  form: {
    nombreCompania: '',
    idCompania: '',
  },
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  filtroSeleccionado: NAME_FILTRO,
};

class ErpConection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'TIPO IDENTIFICACIÓN',
        dataIndex: 'dsTipoIdCompania',
        sorter: (a, b) => sorterText(a.dsTipoIdCompania, b.dsTipoIdCompania),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'idCompania',
        sorter: (a, b) => sorterText(a.idCompania, b.idCompania),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE/RAZÓN SOCIAL',
        dataIndex: 'nombreCompania',
        sorter: (a, b) => sorterText(a.nombreCompania, b.nombreCompania),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO CONEXIÓN',
        dataIndex: 'dsTipoConexionErp',
        sorter: (a, b) => sorterText(a.dsTipoConexionErp, b.dsTipoConexionErp),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoRegistro',
        sorter: (a, b) => sorterText(a.dsEstadoRegistro, b.dsEstadoRegistro),
        showSorterTooltip: false,
      },
      {
        title: 'Editar',
        dataIndex: '',
        render: (record) => (
          <Button type='primary' onClick={() => this.showModalEditar(record)}>
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.formSearch = React.createRef();
  }

  showModalEditar = (erpConexion) => {
    this.setState({
      ...this.state,
      conexionEdit: erpConexion,
      visibleEditModal: true,
    });
  };

  handleSwitchChange = (check) => {
    let nombre = NAME_FILTRO;
    if (check) {
      nombre = IDENTIFICATION_FILTRO;
    }

    this.formSearch.current.setFieldsValue({
      tipoSearch: check,
      nombreCompania: '',
      idCompania: '',
    });

    this.setState({
      ...this.state,
      filtroSeleccionado: nombre,
      filtros: {
        ...this.state.filtros,
        nombreCompania: '',
        idCompania: '',
      },
    });
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      resultado: [],
      total: initialState.total,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.getData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaConexiones(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;

    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleModalOnFinish = (conexion) => {
    this.setState({
      ...this.state,
      conexionEdit: {},
      visibleEditModal: false,
    });
    this.getData();
  };

  handleEditModalOnClose = () => {
    this.setState({
      ...this.state,
      conexionEdit: {},
      visibleEditModal: false,
    });
  };

  componentDidMount() {
    this.props.menuHandler('3');
    this.getData();
  }

  render() {
    const {
      filtroSeleccionado,
      loading,
      currentPage,
      limit,
      total,
      resultado,
      conexionEdit,
      visibleEditModal,
    } = this.state;

    const { authToken } = this.props;
    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='erpConection-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <Row gutter={[16, 32]}>
                <Col span={24}>
                  <Space align='baseline' className='title'>
                    {BUSQUEDA_TITLE}
                  </Space>
                </Col>
              </Row>
              <Form
                ref={this.formSearch}
                name='erp_search'
                layout='vertical'
                onFinish={this.handleSubmit}
              >
                <Row gutter={[16, 32]}>
                  <Col span={6}>
                    <Form.Item name='tipoSearch' label='Filtro'>
                      <Switch
                        checkedChildren={IDENTIFICATION_FILTRO}
                        unCheckedChildren={NAME_FILTRO}
                        onChange={this.handleSwitchChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    {filtroSeleccionado === NAME_FILTRO && (
                      <Form.Item name='nombreCompania' label={NAME_FILTRO}>
                        <Input />
                      </Form.Item>
                    )}
                    {filtroSeleccionado === IDENTIFICATION_FILTRO && (
                      <Form.Item
                        name='idCompania'
                        label={IDENTIFICATION_FILTRO}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (
                                value !== undefined &&
                                value !== '' &&
                                isNaN(value)
                              ) {
                                return Promise.reject(
                                  new Error(
                                    `El campo Identificación debe tener un valor numérico`
                                  )
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                  </Col>

                  <Col span={6}>
                    <Form.Item>
                      <Button
                        className='form-btn btn-radius'
                        type='primary'
                        htmlType='submit'
                      >
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Conexión ERP
                  </Space>
                </Col>
                <Col flex='none'>
                  <span className='button'>
                    <ErpConectionNewModal
                      authToken={authToken}
                      onFinish={this.handleModalOnFinish}
                    />
                  </span>
                </Col>
              </Row>

              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        <ErpConectionEditModal
          authToken={authToken}
          data={conexionEdit}
          visible={visibleEditModal}
          onClose={this.handleEditModalOnClose}
          onFinish={this.handleModalOnFinish}
        />
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(ErpConection);
