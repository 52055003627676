import React from 'react';
import './tutorial.scss';
import { Modal, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const renderTutorial = (index) => {
  switch (index) {
    case 1: {
      return (
        <div
          style={{ width: '100%', minHeight: '250px' }}
          dangerouslySetInnerHTML={{
            __html: `<iframe
          title='tutorial 1'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src='${process.env.PUBLIC_URL}/tutorial1.pdf'
          frameborder='0'
        />`,
          }}
        ></div>
      );
    }
    case 2: {
      return (
        <iframe
          title='tutorial 2'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial2.pdf`}
          frameBorder='0'
        ></iframe>
      );
    }
    case 3: {
      return (
        <iframe
          title='tutorial 3'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial3.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    case 4: {
      return (
        <iframe
          title='tutorial 4'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial4.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    case 5: {
      return (
        <iframe
          title='tutorial 5'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial5.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    case 6: {
      return (
        <iframe
          title='tutorial 6'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial6.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    case 7: {
      return (
        <iframe
          title='tutorial 7'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial7.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    case 8: {
      return (
        <iframe
          title='tutorial 8'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial8.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    case 9: {
      return (
        <iframe
          title='tutorial 9'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial9.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    case 10: {
      return (
        <iframe
          title='tutorial 10'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial10.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    case 11: {
      return (
        <iframe
          title='tutorial 11'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial11.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
    default: {
      return (
        <iframe
          title='tutorial 1'
          sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
          className='tutorialIframe'
          src={`${process.env.PUBLIC_URL}/tutorial1.pdf`}
          frameborder='0'
        ></iframe>
      );
    }
  }
};

function Tutorial(props) {
  return (
    <Modal
      className='content-modal'
      visible={props.showModal}
      title='Tutoriales'
      footer={<div></div>}
      closable
      maskClosable
      onCancel={() => props.onClose()}
      onClose
    >
      {renderTutorial(props.tutorial)}
    </Modal>
  );
}

export default Tutorial;
