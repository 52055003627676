import {
  BookOutlined,
  CopyOutlined,
  CreditCardOutlined,
  HomeOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  ScheduleOutlined,
  ShopOutlined,
  SnippetsOutlined,
  SyncOutlined,
  WalletOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import { Col, Layout, Menu, Modal, Row, Select } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import '../styles/proveedorLayout.scss';
import '../styles/standard.scss';

import logo from '../assets/img/logo-side-bar.png';
import userLogo from '../assets/img/user-icon.png';

import masterServices from '../services/fondeador/masterServices';
import history from '../services/history';
import {
  refreshToken,
  sessionClose,
  setExpirationTimeout,
} from '../store/actions/authActions';
import { setCompany } from '../store/actions/pagador/companyActions';

import { connect } from 'react-redux';
import ListWallet from '../pages/fondeador/adminWallet/listWallet/listWallet';
import PaymentsManaged from '../pages/fondeador/adminWallet/paymentsManaged/paymentsManaged';
import PaymentsWallet from '../pages/fondeador/adminWallet/paymentsWallet/paymentsWallet';
import Refunds from '../pages/fondeador/adminWallet/refunds/refunds';
import Dashboard from '../pages/fondeador/dashboard/dashboard';
import Disbursement from '../pages/fondeador/disbursement/disbursement';
import Operation from '../pages/fondeador/operation/operation';
import Reports from '../pages/fondeador/reports/reports';
import Suppliers from '../pages/fondeador/suppliers/suppliers';
import Support from '../pages/fondeador/support/support';
import TYC from '../pages/proveedor/content/TYC';
import FAQ from '../pages/proveedor/content/faq';
import HelpCenter from '../pages/proveedor/content/helpcenter';
import { EMAIL_INIT_PAGADOR } from '../utilities/messages';
import { capitalize } from '../utilities/textTools';
import userServices from '../services/userService';

/**Pages */

const { Option } = Select;

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

class FondeadorLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSelectedKey: '1',
      selected_company: '',
      notificationDrawer: false,
      notifications: [],
      unreadedNotifications: 0,
      showAuthExpModal: false,
      setTimeoutCloseSession: null,
      menu: [],
    };
  }

  fecha = moment().format('dddd, LL');

  unfilteredMenu = [
    {
      title: 'Inicio',
      icon: HomeOutlined,
      selected: true,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/dashboard`,
      key: 1,
    },
    {
      title: 'Proveedores',
      icon: SnippetsOutlined,
      selected: false,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/suppliers`,
      key: 2,
    },
    {
      title: 'Desembolso',
      icon: BookOutlined,
      selected: true,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/disbursement`,
      key: 3,
    },
    {
      title: 'Soporte',
      icon: ShopOutlined,
      selected: false,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/support`,
      key: 4,
    },
    {
      title: 'Operaciones',
      icon: CopyOutlined,
      selected: true,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/operation`,
      key: 5,
    },
    {
      title: 'Reportes',
      icon: ZoomInOutlined,
      selected: false,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/reports`,
      key: 6,
    },
    {
      title: 'Administración de cartera',
      icon: WalletOutlined,
      selected: false,
      disabled: false,
      isSubMenu: true,
      protected: true,
      protectedKey: 'ADMINCARTERA',
      key: 7,
      children: [
        {
          title: ' Listar cartera',
          icon: ScheduleOutlined,
          selected: false,
          disabled: false,
          protected: true,
          protectedKey: 'LISTARCARTERA',
          href: `${process.env.PUBLIC_URL}/adminWallet/listWallet`,
          key: 71,
        },
        {
          title: ' Pagos automáticos',
          icon: CreditCardOutlined,
          selected: true,
          disabled: false,
          protected: true,
          protectedKey: 'PAGOSAUTOMATICOS',
          href: `${process.env.PUBLIC_URL}/adminWallet/paymentWallet`,
          key: 72,
        },
        {
          title: ' Pagos dirigidos',
          icon: CreditCardOutlined,
          selected: true,
          disabled: false,
          protected: true,
          protectedKey: 'PAGOSDIRIGIDOS',
          href: `${process.env.PUBLIC_URL}/adminWallet/paymentManaged`,
          key: 73,
        },
        {
          title: ' Reintegros',
          icon: SyncOutlined,
          selected: true,
          disabled: false,
          protected: true,
          protectedKey: 'REINTEGROS',
          href: `${process.env.PUBLIC_URL}/adminWallet/refunds`,
          key: 74,
        },
      ],
    },
    {
      title: 'Ayuda',
      icon: QuestionCircleOutlined,
      selected: false,
      disabled: false,
      protected: false,
      href: `${process.env.PUBLIC_URL}/helpcenter`,
      key: 12,
    },
  ];

  componentDidMount() {
    if (window.configuredInterval) {
      clearInterval(window.configuredInterval);
    }

    window.configuredInterval = setInterval(() => {
      this.props.setExpirationTimeout(this.props.authExpiration);
    }, 30000);

    const token = this.props.authtoken;

    masterServices.consultarCompanias(token).then((response) => {
      var defaultCompany;
      if (Array.isArray(response.data) && response.data.length > 0) {
        defaultCompany = response.data[0];
      } else {
        defaultCompany = {};
      }

      this.renderMenu();

      this.setState({
        companies: response.data,
        username: this.props.username,
        selected_company: defaultCompany.scPersona,
      });

      if (defaultCompany.scPersona && defaultCompany.scPersona !== '') {
        this.props.setCompany(defaultCompany.scPersona);
      }
    });
  }

  componentDidUpdate(props, state) {
    if (this.props.reachExpiration !== props.reachExpiration) {
      if (this.props.reachExpiration) {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
          setTimeoutCloseSession: setTimeout(this.logout, 110000),
        });
      } else {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
        });
        clearTimeout(this.state.setTimeoutCloseSession);
      }
    }
  }

  updateSelectedCompany = (value, e) => {
    this.setState({
      ...this.state,
      selected_company: value,
    });
    this.props.setCompany(value);
  };

  logout = () => {
    this.props.sessionClose(this.props.authtoken);
  };

  selectedKeyHandler = (key) => {
    this.setState({
      ...this.state,
      menuSelectedKey: key,
    });
  };
  
  renderMenu() {
    userServices
      .getAdminUserModules(
        this.props.authtoken,
        process.env.REACT_APP_FONDEADOR_NAME
      )
      .then((response) => {
         this.setState({
           ...this.state,
           menu: this.filterMenu(
             Array.isArray(response.data) ? response.data : [],
             this.unfilteredMenu
           ),
         });
      });
  }

  filterMenu(userModules, menuItems) {
    return menuItems
      .filter((filterItem) => {
        if (filterItem.protected) {
          let found = userModules.find(
            (x) => x.cdRecursos === filterItem.protectedKey
          );
          if (found) {
            filterItem.title = found.dsNombre;
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .map((item) => {
        if (item.protected) {
          if (Array.isArray(item.children)) {
            return {
              ...item,
              children: this.filterMenu(userModules, item.children),
            };
          } else {
            return item;
          }
        } else {
          return item;
        }
      });
  }

  render() {
    return (
      <Layout>
        <Sider className='realasside'>
          <div className='user-container'>
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img className='brand-logo' src={logo} alt='' />
            </Link>
            {this.props.children}
            <img className='user-avatar' src={userLogo} alt='' />
            <p className='user-name'>{this.props.username}</p>
            <span>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Seleccione una compañia'
                optionFilterProp='children'
                onChange={this.updateSelectedCompany}
                value={this.state.selected_company}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.companies !== undefined
                  ? this.state.companies.map((company) => (
                      <Option
                        value={company.scPersona}
                        key={company.scPersona}
                        persontype={company.tipoPersona}
                      >
                        {company.nombre}
                      </Option>
                    ))
                  : ''}
              </Select>
            </span>
          </div>
          <Menu
            className='slider-list'
            mode='inline'
            selectedKeys={[this.state.menuSelectedKey]}
          >
            {this.state.menu.map((menu) =>
              menu.isSubMenu ? (
                <SubMenu
                  key='sub1'
                  title={
                    <span>
                      {menu.icon ? React.createElement(menu.icon) : ''}
                      <span>{menu.title}</span>
                    </span>
                  }
                >
                  {menu.children.map((submenu) => (
                    <Menu.Item key={submenu.key}>
                      <Link to={submenu.href}>
                        {submenu.icon ? React.createElement(submenu.icon) : ''}
                        {submenu.title}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.key}>
                  <Link to={menu.href}>
                    {menu.icon ? React.createElement(menu.icon) : ''}
                    {menu.title}
                  </Link>
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout className='content'>
          <Header className='header'>
            <Row className='menu'>
              <Col span={22}>
                {this.fecha.charAt(0).toUpperCase() + this.fecha.slice(1)}
              </Col>
              <Col span={1}>
                <QuestionCircleOutlined
                  className='notification-icon'
                  onClick={() => {
                    history.push(`${process.env.PUBLIC_URL}/helpcenter`);
                  }}
                />
              </Col>
              <Col span={1} onClick={this.logout} className='logout'>
                {' '}
                <LogoutOutlined className='notification-icon' />
              </Col>
            </Row>
          </Header>
          <Content>
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/dashboard`}>
                <Dashboard
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/suppliers`}>
                <Suppliers
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/disbursement`}>
                <Disbursement
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/support`}>
                <Support
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/operation`}>
                <Operation
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/operation/:activeTab`}
              >
                <Operation
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/reports`}>
                <Reports
                  menuHandler={this.selectedKeyHandler}
                  name={capitalize(
                    this.props.username.split(' ')[0].toLowerCase()
                  ).trim()}
                />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/helpcenter`}>
                <HelpCenter
                  menuHandler={this.selectedKeyHandler}
                  email={EMAIL_INIT_PAGADOR}
                  module='fondeador'
                />
              </Route>

              <Route path={`${process.env.PUBLIC_URL}/faq`}>
                <FAQ menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route path={`${process.env.PUBLIC_URL}/tyc`}>
                <TYC menuHandler={this.selectedKeyHandler} hadAccepted='true' />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/adminWallet/listWallet`}
              >
                <ListWallet menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/adminWallet/paymentWallet`}
              >
                <PaymentsWallet menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/adminWallet/paymentManaged`}
              >
                <PaymentsManaged menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/adminWallet/refunds`}
              >
                <Refunds menuHandler={this.selectedKeyHandler} />
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/*`}>
                <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
              </Route>
            </Switch>
          </Content>
        </Layout>

        <Modal
          title='Sessión a punto de finalizar'
          visible={this.state.showAuthExpModal}
          onOk={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
            clearTimeout(this.state.setTimeoutCloseSession);
            this.props.refreshToken(this.props.authRefreshToken);
          }}
          onCancel={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
          }}
        >
          <p>
            Tu sesión esta a punto de finalizar, si deseas continuar operando en
            la plataforma presiona "Aceptar"
          </p>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  authtoken: state.auth.auth.access_token,
  username: state.auth.auth.name,
  authExpiration: state.auth.expiressTimestamp,
  authRefreshToken: state.auth.auth.refresh_token,
  reachExpiration: state.auth.reachExpiration,
});

export default connect(mapStateToProps, {
  setCompany,
  setExpirationTimeout,
  refreshToken,
  sessionClose,
})(FondeadorLayout);
