import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import {
  currencyFormat,
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';
import TableReport from '../../../../../components/tableReport/tableReport';

import {
  listaFacturasDescontadas,
  listaFacturasDescontadasExcel,
  listarFacturasProveedor,
  listarFacturasProveedorExcel,
} from '../../../../../services/admin/reportsDiscountedBills';
import { setExcel } from '../../../../../utilities/downloadTools';
import {
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
  listarConvenios,
} from '../../../../../services/admin/reports/quotasService';
import moment from 'moment';
import SupplierForm from './supplierForm/supplierForm';

const initialState = {
  form: {
    feDesde: moment(),
    feHasta: moment(),
    scGrupo: '',
    scPagador: '',
    scProveedor: '',
    cdFactura: '',
    scConvenio: '',
    cdTipoConvenio: '',
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 13,
    },
    {
      id: 'totalVrOferta',
      value: currencyFormat(0),
      index: 'vrOferta',
      colSpan: 1,
    },
    {
      id: 'totalVrTransaccion',
      value: currencyFormat(0),
      index: 'vrTransaccion',
      colSpan: 1,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  buttonOptions: [],
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
};

class Supplier extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PAGADOR',
        dataIndex: 'pagador',
        sorter: (a, b) => sorterText(a.pagador, b.pagador),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'proveedor',
        sorter: (a, b) => sorterText(a.proveedor, b.proveedor),
        showSorterTooltip: false,
      },
      {
        title: '# FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REPORTADO',
        dataIndex: 'vrReportadoFactura',
        render: (value) => <span>{currencyFormat(value)} </span>,
        sorter: (a, b) => a.vrReportadoFactura - b.vrReportadoFactura,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR BASE',
        dataIndex: 'vrBaseFactura',
        render: (value) => <span>{currencyFormat(value)} </span>,
        sorter: (a, b) => a.vrBaseFactura - b.vrBaseFactura,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REDUCIDO',
        dataIndex: 'vrFacturaChReducido',
        render: (value) => <span>{currencyFormat(value)} </span>,
        sorter: (a, b) => a.vrFacturaChReducido - b.vrFacturaChReducido,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR NOTAS CRÉDITO',
        dataIndex: 'vrTotalNotas',
        render: (value) => <span>{currencyFormat(value)} </span>,
        sorter: (a, b) => a.vrTotalNotas - b.vrTotalNotas,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE NEGOCIACIÓN',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'DESCUENTO M.A.',
        dataIndex: 'tasaMA',
        render: (value) => <span>{percentageFormat(value)}</span>,
        sorter: (a, b) => a.tasaMA - b.tasaMA,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DESEMBOLSO',
        dataIndex: 'feDesembolso',
        sorter: (a, b) => sorterText(a.feDesembolso, b.feDesembolso),
        showSorterTooltip: false,
      },
      {
        title: 'PLAZO DESCONTADO',
        dataIndex: 'plazoDescontado',
        sorter: (a, b) => a.plazoDescontado - b.plazoDescontado,
        showSorterTooltip: false,
      },
      {
        title: 'TASA PROPORCIONAL',
        dataIndex: 'tasaProporcional',
        render: (value) => <span>{percentageFormat(value)}</span>,
        sorter: (a, b) => a.tasaProporcional - b.tasaProporcional,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR OFERTA',
        dataIndex: 'vrOferta',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrOferta - b.vrOferta,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR POR TRANSACIÓN',
        dataIndex: 'vrTransaccion',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrTransaccion - b.vrTransaccion,
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarConvenios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        cdTipoConvenio: value.cdTipoConvenio,
        scGrupo: value.scGrupo,
        scConvenio: value.scConvenio,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        cdFactura: value.cdFactura,
        feDesde: value.fechaRango[0],
        feHasta: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listarFacturasProveedor(
          this.props.authToken,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = (
              <span className='format-number'>
                {currencyFormat(response.metadata.sumatorias[res.id])}
              </span>
            );
          }

          return res;
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await listarFacturasProveedorExcel(
          this.props.authToken,
          this.getFiltro()
        );
        setExcel(response, `Facturas_proveedores`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      grupoSelect,
      pagadorSelect,
      proveedorSelect,
      convenioSelect,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <SupplierForm
            grupoSelect={grupoSelect}
            pagadorSelect={pagadorSelect}
            proveedorSelect={proveedorSelect}
            convenioSelect={convenioSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnSearchGrupo={this.handleOnSearchGrupo}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default Supplier;
