import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import {
  listarFondeadores,
  nopago,
  nopagoExcel,
} from '../../../../services/pagador/reportServices';
import BillsPendingOfPaymentForm from './billsPendingOfPaymentForm/billsPendingOfPaymentForm';
import moment from 'moment';
import { setExcel } from '../../../../utilities/downloadTools';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';

const initialState = {
  resetForm: false,
  form: {
    scFondeador: '',
    fechaDesdeEstimadaPago: '',
    fechaHastaEstimadaPago: '',
  },
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'nombrePagador',
      colSpan: 7,
    },
    {
      id: 'sumaVrFactura',
      value: currencyFormat(0),
      index: 'vrFactura',
      colSpan: 1,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  loading: false,
};

class BillsPendingOfPayment extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        key: '1',
        sorter: (a, b) => sorterText(a.nombrePagador - b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        key: '2',
        sorter: (a, b) => sorterText(a.nombreProveedor - b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        key: '3',
        sorter: (a, b) => sorterText(a.nombreFondeador - b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'CÓDIGO DE FACTURA',
        dataIndex: 'cdFactura',
        key: '4',
        sorter: (a, b) => sorterText(a.cdFactura - b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'REF. DOC. CONTABLE',
        dataIndex: 'cdDocumentoSAP',
        key: '5',
        sorter: (a, b) => sorterText(a.cdDocumentoSAP - b.cdDocumentoSAP),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE PAGO',
        dataIndex: 'feCompra',
        key: '6',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ESPERADA PAGO',
        dataIndex: 'feEstimadaPago',
        key: '7',
        sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REPORTADO',
        dataIndex: 'vrFactura',
        key: '8',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchFondeador = (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      listarFondeadores(this.props.authToken, this.props.company.company, {
        nombre: nombre,
      }).then((response) => {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      });
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scFondeador: value.scFondeador,
        fechaDesdeEstimadaPago: value.fechaRango[0],
        fechaHastaEstimadaPago: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await nopagoExcel(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );
        setExcel(response, `Reporte_facturas_pendiente_de_pago`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeEstimadaPago;
    const fechaHasta = this.state.form.fechaHastaEstimadaPago;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await nopago(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = currencyFormat(response.metadata.sumatorias[res.id]);
          }
          return res;
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  handleOnReset = () => {
    this.setState({
      ...this.state,
      resetForm: false,
      form: {
        ...initialState.form,
      },
    });
  };

  componentDidUpdate(props, state) {
    if (this.props.company.company != props.company.company) {
      this.setState({
        ...initialState,
        resetForm: true,
        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'nombrePagador',
            colSpan: 7,
          },
          {
            id: 'sumaVrFactura',
            value: currencyFormat(0),
            index: 'vrFactura',
            colSpan: 1,
          },
        ],
      });
    }
  }

  render() {
    const { loading, resetForm, fondeadorSelect } = this.state;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <BillsPendingOfPaymentForm
            resetForms={resetForm}
            fondeadorSelect={fondeadorSelect}
            handleSubmit={this.handleSubmit}
            handleOnSearchFondeador={this.handleOnSearchFondeador}
            handleOnReset={this.handleOnReset}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={this.state.currentPage}
              limit={this.state.limit}
              total={this.state.total}
              resumen={this.state.resumen}
              columns={this.columnas}
              data={this.state.resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default BillsPendingOfPayment;
