export const tipoIds = [
  { key: 'NI', description: 'NIT' },
  { key: 'CC', description: 'C.C.' },
  { key: 'CE', description: 'C.E.' },
  { key: 'TI', description: 'T.I.' },
  { key: 'RC', description: 'R.C.' },
  { key: 'PA', description: 'PAS' },
  { key: 'OT', description: 'OTROS' },
];

export const Genero = [
  { key: 'M', description: 'Masculino' },
  { key: 'F', description: 'Femenino' },
  { key: 'O', description: 'Otro' },
];
