import React, { useEffect, useState } from 'react';
import {
  Spin,
  Form,
  Col,
  Row,
  Button,
  Modal,
  notification,
  InputNumber,
  DatePicker,
  Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../../utilities/textTools';
import { range } from '../../../../../utilities/validate/numberValidate';
import { maxLength } from '../../../../../utilities/validate/textValidate';
import { adicionarCupoContraparte } from '../../../../../services/admin/quotesagreementsServices';
import { DATE_FORMAT } from '../../../../../utilities/messages';
import moment from 'moment';


const SELECT_PLACE_HOLDER = 'Seleccionar...';

const initialValue = {
  nmCupoContraparteActual: '',
  nmCupoContraparte: '',
  nmCupoContraparteVig: '',
  descripcion: '',
  feDesde: '',
  feHasta: '',
  cdTipoProducto:'',
  cdTipoCupo:'',
  estadoCupo:''
};

const CounterpartQuotaForm = ({
  tipoEntidad,
  scEntidad,
  cupoActual,
  scFondeador,
  authToken,
  onFinish,
  handleOnSearchCupos,
  liquidezActual,
  tipoProducto,
  tipoCupo,
  estadoCupo
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...cupoActual,
        nmCupoContraparteActual: cupoActual.nmCupoContraparte,
        feDesde: cupoActual.feDesde
          ? moment(cupoActual.feDesde, DATE_FORMAT)
          : '',
        feHasta: cupoActual.feHasta
          ? moment(cupoActual.feHasta, DATE_FORMAT)
          : '',
        tipoCupo: cupoActual.tipoCupo,
        tipoProducto: cupoActual.tipoProducto
      });
    }
  }, [form, cupoActual, liquidezActual, visible]);

  const openModal = () => {
    handleOnSearchCupos();
    setVisible(true);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const formValue = await form.validateFields();

      if (
        scEntidad === '' ||
        scFondeador === '' ||
        scEntidad === undefined ||
        scFondeador === undefined
      ) {
        notification.warning({
          message: 'Fondeador o entidad vacío',
          description:
            'Por favor seleccione un fondeador y una entidad en la pantalla anterior antes de asignar el cupo',
          duration: 5,
        });
      } else {
        const data = {
          ...formValue,
          feDesde: formValue.feDesde.format(DATE_FORMAT),
          feHasta: formValue.feHasta
            ? formValue.feHasta.format(DATE_FORMAT)
            : undefined,
          // cdSnLiquidezToCupo: formValue.cdSnLiquidezToCupo ? 'S' : 'N',
          cdSnOfertaFull: formValue.cdSnOfertaFull ? 'S' : 'N',
          tipoEntidad: tipoEntidad,
        };

        const response = await adicionarCupoContraparte(
          authToken,
          scFondeador,
          scEntidad,
          data
        );

        notification.success({
          message: 'Cupo Contraparte agregado',
          description: response.message,
          duration: 4,
        });

        setVisible(false);
        onFinish(data);
      }
    } finally {
      setLoading(false);
    }
  };

  const closeWindow = () => {
    setVisible(false);
  };


  return (
    <>
      <Button size='small' onClick={() => openModal()} className='btn-quota'>
        + Adicionar cupo contraparte
      </Button>
      <Modal
        title='Adicionar cupo contraparte'
        className='modal-style'
        width={720}
        closable={true}
        visible={visible}
        onOk={handleOk}
        onCancel={closeWindow}
        okText='Grabar'
        cancelButtonProps={{ className: 'btn-radius' }}
        okButtonProps={{ type: 'primary', className: 'ok-btn btn-radius' }}
      >
        <div className='header-container'>
          <Spin tip='Cargando...' spinning={loading}>
            <Form
              name='content_form'
              layout='vertical'
              className='filters-container'
              form={form}
              initialValues={initialValue}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name='nmCupoContraparteActual'
                    label='CUPO CONTRAPARTE ASIGNADO ACTUAL'
                  >
                    <InputNumber
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='nmCupoContraparteVig'
                    label='SALDO CONTRAPARTE VIGENTE'
                  >
                    <InputNumber
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name='feDesde'
                    label='FECHA DESDE'
                    rules={[
                      { required: true, message: 'selecciona una fecha desde' },
                    ]}
                  >
                    <DatePicker format={DATE_FORMAT} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='feHasta' label='FECHA HASTA'>
                    <DatePicker format={DATE_FORMAT} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name='nmCupoContraparte'
                    label='CUPO CONTRAPARTE ASIGNADO'
                    rules={[
                      {
                        required: true,
                        message: 'debe ingresar un cupo contraparte asignado',
                      },
                      {
                        validator: (_, value) =>
                          range(value, 0, 999999999999999),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                <Form.Item 
                name='estadoCupo' 
                label='ESTADO CUPO'
                rules={[
                      {
                        required: true,
                        message: 'debe seleccionar el estado del cupo',
                      }
                    ]}
                >
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
            >
              {estadoCupo.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              </Row>

              <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                name='tipoProducto' 
                label='Tipo de Producto'
                rules={[
                      {
                        required: true,
                        message: 'debe seleccionar el tipo de producto',
                      }
                    ]}
                >
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
            >
              {tipoProducto.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item 
                name='tipoCupo' 
                label='Tipo de Cupo'
                rules={[
                      {
                        required: true,
                        message: 'debe seleccionar el tipo de cupo',
                      }
                    ]}
                >
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
            >
              {tipoCupo.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name='descripcion'
                    label='OBSERVACIONES'
                    rules={[
                      {
                        required: true,
                        message: 'debe ingresar una observación',
                      },
                      {
                        validator: (_, value) => maxLength(value, 400),
                      },
                    ]}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default CounterpartQuotaForm;
