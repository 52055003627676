import { Button, Col, Form, Input, notification, Row } from 'antd'
import React, { useState,useEffect,useRef } from 'react'
import '../../../components/affitokenValidator/affitokenValidator.scss'
import { solicitartoken } from '../../../services/vinculacion/registroService';
import { maxValueMessage, minValueMessage, requiredValueMessage } from '../../../utilities/admin_validation_messages';

const rules = {
  token: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'no tiene el formato correcto',
    },
    { min: 6, message: minValueMessage(6) },
    { max: 6, message: maxValueMessage(6) },
  ]
};


function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const twoDigits = (num) => String(num).padStart(2, '0')


const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}

const INITIAL_COUNT = 180
export const Token = (props) => {
    const [form] = Form.useForm();
    const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)
    const [status, setStatus] = useState(STATUS.STARTED);
    const [buttondisable, setbuttondisable] = useState(true);

    const secondsToDisplay = secondsRemaining % 60
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
    const minutesToDisplay = minutesRemaining % 60

    const getToken = async () =>{

      let data = {email:props.email}

      try {
        const response = await solicitartoken(data);
        
        notification.success({
          message: 'Token enviado',
          description: response.message,
          duration: 5,
        });
  
  
      } catch {
  
        notification.error({
          message: 'Error',
          description: "Error Comunicando con servidor",
          duration: 5,
        });
      }

    }

    useEffect(() => {
      
      console.log(props.email);
      getToken();


    },[])

    useInterval(
      () => {
        if (secondsRemaining > 0) {
          setSecondsRemaining(secondsRemaining - 1)
        } else {
          setStatus(STATUS.STOPPED);
          setbuttondisable(false);
        }
      },
      status === STATUS.STARTED ? 1000 : null,
    )
  
    

    const requestNewToken = () => {

      getToken();
      setSecondsRemaining(INITIAL_COUNT);
      setStatus(STATUS.STARTED);
      setbuttondisable(true);
        
    }

  return (
    <div>
        <Form form={form} onFinish={props.handleToken}>
        <Row justify="center" align="middle">
            <p>Al correo Electronico Ingresado, se ha enviado un Affitoken. Ten en cuenta el tiempo disponible para ingresarlo.</p>              
          <Col span={6}>
            <Form.Item
                name='token'
                label='Ingresar Affitoken'
                rules={rules['token'] || ''}
              >
                <Input 
                readOnly={false} 
                disabled={!buttondisable}
                allowClear 
                size="small"
                onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                  }}
                
                onCopy={(e)=>{
                e.preventDefault()
                return false;
                }} 
                />
            </Form.Item>
          </Col>
          <Col span={6}>
            <div className="token-label">
              <div className="time">
                <p>{twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}</p>
              </div>
            </div>
          </Col>
          <Col span={5}>
            <div className="token-label">
              <Button disabled={buttondisable} className={buttondisable ? 'btn-next disabled' : 'btn-next active' } onClick={() => { requestNewToken() }}>Solicitar nuevo Affitoken</Button>   
            </div>
          </Col>
        </Row>
        <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                  disabled={!buttondisable}
                >
                  Enviar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
  )
}
