import { Modal, notification, Spin } from 'antd';
import React, { Component } from 'react';
import CalculateVolumeModal from '../../../../../../components/calculateVolumeModal/calculateVolumeModal';
import {
  calcularRangos,
  editarConvenioFondeadorConfirming,
  editarConvenioFondeadorProntoPago,
  listaFormatoACH,
  listaTipoBaseDias,
} from '../../../../../../services/admin/operation';
import { isEqualsToObject } from '../../../../../../utilities/compare';
import FoundProviderForm from '../foundProviderForm/foundProviderForm';

const MESSAGE_TITLE = 'Fondeadores';

const initialState = {
  loading: false,
  fondeadorSelect: {
    options: [],
    fetching: false,
  },
  rangoVolumen: {},
  rangoMetas: [],
  configModalVisible: false,
  tipoBaseDias: [],
  formtatoACHs: [],
};

class FoundProvidersEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnConfigVolumen = async (rangoVolumen) => {
    const { authToken, scConvenio } = this.props;

    if (this.validChangeRangoVolumen(rangoVolumen)) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await calcularRangos(
          authToken,
          scConvenio,
          rangoVolumen.cdTipoCalculoVolumen,
          rangoVolumen
        );

        this.setState({
          ...this.state,
          configModalVisible: true,
          loading: false,
          rangoMetas: response.data,
          rangoVolumen: rangoVolumen,
        });
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    } else {
      this.setState({
        ...this.state,
        configModalVisible: true,
      });
    }
  };

  validChangeRangoVolumen = (rangoVolumenPro) => {
    const { rangoVolumen, rangoMetas } = this.state;
    return (
      (rangoMetas && rangoMetas.length === 0) ||
      !isEqualsToObject(rangoVolumen, rangoVolumenPro)
    );
  };

  handleOnVolumenModalClose = () => {
    this.setState({
      ...this.state,
      configModalVisible: false,
    });
  };

  handleOnFinishVolumenModal = (rangoMetas) => {
    this.setState({
      ...this.state,
      configModalVisible: false,
      rangoMetas: rangoMetas,
    });
  };

  handleOnFinish = async (formValue) => {
    const {
      cdTipoConvenio,
      authToken,
      scConvenio,
      onFinish,
      poDescuentoMinimoProveedor,
    } = this.props;
    const { rangoMetas } = this.state;
    this.setState({ ...this.state, loading: true });
    formValue.poProveedorDefecto =
      undefined || null
        ? poDescuentoMinimoProveedor
        : formValue.poProveedorDefecto;
    try {
      let response = null;
      if (cdTipoConvenio === 'confirming' || cdTipoConvenio === 'factoring') {
        response = await editarConvenioFondeadorConfirming(
          authToken,
          scConvenio,
          { ...formValue, rangoMetas }
        );
      } else {
        response = await editarConvenioFondeadorProntoPago(
          authToken,
          scConvenio,
          { ...formValue, rangoMetas }
        );
      }

      notification.success({
        message: MESSAGE_TITLE,
        description: response.message,
        duration: 6,
      });
      this.setState({ ...this.state, loading: false, visible: false });

      onFinish({ ...formValue, rangoMetas, scConvenio: response.location });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  };

  getData = async () => {
    const { authToken } = this.props;
    this.setState({ ...this.state, loading: true });

    try {
      const tipoBaseDiasRes = await listaTipoBaseDias(authToken);
      const formtatoACHsRes = await listaFormatoACH(authToken);
      this.setState({
        ...this.state,
        tipoBaseDias: tipoBaseDiasRes.data,
        formtatoACHs: formtatoACHsRes.data,
        loading: false,
      });
    } catch {
      this.setState({ ...this.state, loading: false, visible: false });
    }
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(props, state) {
    if (!isEqualsToObject(props.data, this.props.data)) {
      this.state.rangoMetas = this.props.data.rangoMetas;
      this.state.rangoVolumen = {
        nmMetaPiso: this.props.data.nmMetaPiso,
        nmMetaTecho: this.props.data.nmMetaTecho,
        nmRangosMeta: this.props.data.nmRangosMeta,
        cdTipoCalculoVolumen: this.props.data.cdTipoCalculoVolumen,
        poBeneficioExponencial: this.props.data.poBeneficioExponencial,
      };

      this.setState({
        ...this.state,
      });
    }
  }

  render() {
    const {
      loading,
      fondeadorSelect,
      configModalVisible,
      rangoMetas,
      tipoBaseDias,
      formtatoACHs,
    } = this.state;
    const {
      visible,
      onClose,
      data,
      cdTipoConvenio,
      poDescuentoMinimoProveedor,
    } = this.props;
    return (
      <>
        <Modal
          title='Editar fondeador'
          visible={visible}
          className='modal-found-providers-style'
          width={800}
          footer={null}
          onCancel={onClose}
        >
          <Spin tip='Cargando...' spinning={loading}>
            <FoundProviderForm
              edit={true}
              data={data}
              cdTipoConvenio={cdTipoConvenio}
              onFinish={this.handleOnFinish}
              handleOnSearchFondeador={this.handleOnSearchFondeador}
              fondeadorSelect={fondeadorSelect}
              onConfigVolumen={this.handleOnConfigVolumen}
              tipoBaseDias={tipoBaseDias}
              formtatoACHs={formtatoACHs}
              poDescuentoMinimoProveedor={poDescuentoMinimoProveedor}
            />
          </Spin>
        </Modal>
        <CalculateVolumeModal
          visible={configModalVisible}
          data={rangoMetas}
          onFinish={this.handleOnFinishVolumenModal}
          onClose={this.handleOnVolumenModalClose}
        />
      </>
    );
  }
}

export default FoundProvidersEditModal;
