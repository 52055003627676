import { Button, Col, Form, Input, InputNumber, Row, Select, Spin } from 'antd';
import equal from 'fast-deep-equal';
import React, { Component } from 'react';
import { requiredValueMessage } from '../../../../../../utilities/admin_validation_messages';
import {
  percentageInputFormat,
  percentageInputParser,
} from '../../../../../../utilities/textTools';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

export const initialSupplierFormValue = {
  cdTipoTasaProveedor: undefined,
  nmDiasBloqOferta: '',
  nmDiasTablaFactores: '',
  nmRangosCom: '',
  poParticipacionCom: '',
  idSAPProveedor: '',
  dsCatProv: undefined,
  cdEstadoRegistro: '',
  scPago: undefined,
  scFondeadores: undefined,
  proveedorNombre: '',
};

const initialState = {
  cdTipoTasaProveedor: '',
};

const estadoRegistro = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
  { key: 'C', description: 'Caducado' },
  { key: 'P', description: 'Pendiente' },
  { key: 'R', description: 'Rechazado' },
  { key: 'E', description: null },
];

const rules = {
  cdTipoTasaProveedor: [{ required: true, message: requiredValueMessage() }],
  nmDiasTablaFactores: [{ required: true, message: requiredValueMessage() }],
  poParticipacionCom: [{ required: true, message: requiredValueMessage() }],
  idSAPProveedor: [{ required: true, message: requiredValueMessage() }],
  cdEstadoRegistro: [{ required: true, message: requiredValueMessage() }],
  scPago: [{ required: true, message: requiredValueMessage() }],
};

class SupplierForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.form = React.createRef();
  }

  handleCdTipoTasaProveedor = (cdTipoTasaProveedor) => {
    this.setState({
      ...this.state,
      cdTipoTasaProveedor,
    });
  };

  handleOnConfigRangoComision = () => {
    const { onConfigRangoComision } = this.props;

    let nmRangosCom = this.form.current.getFieldValue('nmRangosCom');

    onConfigRangoComision(nmRangosCom);
  };

  handleOnTasaDescuentoModal = () => {
    const { OnTasaDescuentoModal } = this.props;
    OnTasaDescuentoModal();
  };

  componentDidMount() {
    if (this.props.data) {
      const data = this.props.data;
      this.form.current.setFieldsValue({ ...data });
      this.setState({
        ...this.state,
        cdTipoCalculoVolumen: data.cdTipoCalculoVolumen,
      });
    }
  }

  componentDidUpdate(props, state) {
    if (!equal(props.data, this.props.data)) {
      this.form.current.resetFields();
      this.form.current.setFieldsValue({
        ...initialSupplierFormValue,
        ...this.props.data,
      });
      this.setState({
        ...this.state,
        cdTipoTasaProveedor: this.props.data.cdTipoTasaProveedor,
      });
    }
  }

  render() {
    const { cdTipoTasaProveedor } = this.state;
    const {
      onFinish,
      edit = false,
      proveedorSelect,
      handleOnSearchProveedor,
      tipoTasaProveedor,
      categorias,
      tipoPagos,
      fondeadores,
    } = this.props;

    return (
      <div className='header-container'>
        <Form layout='vertical' ref={this.form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col flex='auto'>
              {edit === false && (
                <Form.Item name='scProveedor' label='PROVEEDOR'>
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => handleOnSearchProveedor(value)}
                    notFoundContent={
                      proveedorSelect.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {proveedorSelect.options.map((d) => (
                      <Select.Option key={d.codigo}>
                        {d.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {edit && (
                <>
                  <Form.Item name='proveedorNombre' label='PROVEEDOR'>
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item name='scProveedor' hidden='true'>
                    <Input readOnly />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} style={{ textAlign: 'start' }}>
              <Form.Item label='TASA DE DESCUENTO'>
                <Button
                  className='btn btn-blue'
                  type='primary'
                  onClick={this.handleOnTasaDescuentoModal}
                >
                  Configurar
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoTasaProveedor'
                label='TIPO TASA'
                rules={rules['cdTipoTasaProveedor'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  allowClear
                  onChange={this.handleCdTipoTasaProveedor}
                >
                  {tipoTasaProveedor.map((item) => (
                    <Select.Option key={item.codigo} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {cdTipoTasaProveedor === 'FIJA_FACT' && (
              <Col span={12}>
                <Form.Item
                  name='nmDiasTablaFactores'
                  label='DÍAS PARA TABLA DE FACTORES'
                  rules={rules['nmDiasTablaFactores'] || ''}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    precision={0}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='nmDiasBloqOferta'
                label='DÍAS OFERTA BLOQUEADA'
                rules={rules['nmDiasBloqOferta'] || ''}
              >
                <InputNumber style={{ width: '100%' }} min={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='poParticipacionCom'
                label='% PARTICIPACIÓN COMISIÓN'
                rules={rules['poParticipacionCom'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={percentageInputFormat}
                  parser={percentageInputParser}
                  min={0}
                  max={100}
                  precision={2}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify='space-around' align='middle'>
            <Col span={12}>
              <Form.Item
                name='nmRangosCom'
                label='NÚMERO DE RANGOS COMISIÓN'
                rules={rules['nmRangosCom'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  precision={0}
                  max={99}
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{ textAlign: 'center' }}>
              <Button
                className='btn btn-blue'
                type='primary'
                onClick={this.handleOnConfigRangoComision}
              >
                Configurar
              </Button>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='idSAPProveedor'
                label='NÚMERO DE IDENTIFICACIÓN (ERP)'
                rules={rules['idSAPProveedor'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='dsCatProv'
                label='CATEGORÍA'
                rules={rules['dsCatProv'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {categorias.map((item) => (
                    <Select.Option key={item.codigo} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdEstadoRegistro'
                label='ESTADO'
                rules={rules['cdEstadoRegistro'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {estadoRegistro.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='scPago'
                label='TIPO DE PAGO'
                rules={rules['scPago'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {tipoPagos.map((item) => (
                    <Select.Option key={item.scPago} value={item.scPago}>
                      {item.nombrePago}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col flex={'auto'}>
              <Form.Item
                name='scFondeadores'
                label='FONDEADORES'
                rules={rules['scFondeadores']}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  mode='multiple'
                  allowClear
                  style={{ width: '100%' }}
                  filterOption={(input, option) => {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {fondeadores &&
                    fondeadores.map((grupo) => (
                      <Select.Option key={grupo.codigo} value={grupo.codigo}>
                        {grupo.descripcion}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default SupplierForm;
