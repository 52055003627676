import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN


export const buscarSolicitudes = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/listar?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      }
    })
  );
};

export const actualizarSolicitud = (authToken, scSolicitud, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/actualizar/${scSolicitud}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const actualizarEstadoSolicitud = (authToken, scSolicitud, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/actualizarEstado/${scSolicitud}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const listarDocumentosAnexos = (authToken,scSolicitud) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/listar-archivos/${scSolicitud}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authToken}`
      },
    })
  );
};

export const descargarDocumentos = (authToken, scSolicitud) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/descargar-archivos/${scSolicitud}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      }
    })
  );
};

export const descargarReporte = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/descargar-reporte?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      }
    })
  );
};

export const getProveedorPU = (authToken, sccompany) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/buscarFichaPU/${sccompany}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      }
    })
  );
}
