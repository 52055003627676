import React, { useEffect, useState } from 'react';
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button } from 'antd';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';
import moment from 'moment';

const initialValue = {
  fechaRango: [moment(new Date()), moment(new Date())],
  scProveedor: '',
  cdFactura: '',
  scConvenio: '',
};
const BillsInProcessForm = ({
  resetForms,
  handleSubmit,
  proveedorSelect,
  convenioSelect,
  handleOnSearchConvenio,
  handleOnSearchProveedor,
  handleOnReset,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  if (resetForms) {
    form.setFieldsValue({ ...initialValue });
    handleOnReset();
  }

  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='fechaRango'
              label='Rango de fechas de desembolso'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un rango de fechas',
                },
              ]}
            >
              <DatePicker.RangePicker name='fechaRango' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scProveedor' label='Proveedor'>
              <Select
                name='scProveedor'
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchProveedor(value)}
                notFoundContent={
                  proveedorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {proveedorSelect.options.map((d) => (
                  <Select.Option key={d.scPersona}>{d.nombre}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scConvenio' label='Convenios'>
              <Select
                name='scConvenio'
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchConvenio(value)}
                notFoundContent={
                  convenioSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {convenioSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='cdFactura' label='Código de factura'>
              <Input name='cdFactura' />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BillsInProcessForm;
