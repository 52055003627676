import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Switch,
  Table,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import Man from '../../../../assets/img/assisted-man.png';
import AffitokenValidator from '../../../../components/affitokenValidator/affitokenValidator';
import billServices from '../../../../services/proveedor/billServices';
import {
  chargeallNextStep,
  nextStep,
  programNextStep,
  setTracking,
} from '../../../../store/actions/proveedor/billingActions';
import store from '../../../../store/index';
import '../../../../styles/standard.scss';
import '../billing.scss';
import './summary.scss';
import DescuentoNotaModal from './descuentoNotaModal/descuentoNotaModal';

const { Option } = Select;

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      total: 0,
      modal: false,
      billsSumary: [],
      summaryDetail: [],
      affitoken: [],
      changeDataType: false,
      loading: false,
      requestAccount: false,
      selectedAccount: '',
      condicionVisible:false
    };
  }

  componentDidUpdate(props, state) {
    if (
      props.billing.totalSelectedValue != this.props.billing.totalSelectedValue
    ) {
      const { totalSelectedValue } = this.props.billing;
      if (totalSelectedValue <= 0 || this.state.billsSumary == []) {
        notification.error({
          message: 'Error',
          description:
            'Ha ocurrido un error con la selección de facturas, el carrito estaba en ceros',
          duration: 6,
        });
        if (this.props.module === 'assisted') {
          this.props.chargeallNextStep(0);
        } else if (this.props.module === 'program') {
          this.props.programNextStep(0);
        } else {
          this.props.nextStep(0);
        }
        this.props.stepHandler();
      }
    }
  }

  componentDidMount() {
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    this.setState({
      ...this.state,
      loading: true,
      total: 0,
    });
    var operacion = 'O';
    if (this.props.module === 'program') {
      operacion = 'P';
    }
    billServices
      .resumenCompraOferta(token, scProveedor, operacion)
      .then((response) => {
        var summary = [];
        var total = 0;
        response.data.forEach((datum, key) => {
          datum.ofertas.forEach((offer, key) => {
            if (!summary[offer.nombrePagador + '-' + datum.nombreFondeador]) {
              if (datum.scFondeador !== 0) {
                this.setState({
                  ...this.state,
                  requestAccount: true,
                });
              }
              summary[offer.nombrePagador + '-' + datum.nombreFondeador] = [];
              summary[offer.nombrePagador + '-' + datum.nombreFondeador].key =
                key;
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].investorName = datum.nombreFondeador;
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].billsNumber = 0;
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].totalValue = 0;
              summary[offer.nombrePagador + '-' + datum.nombreFondeador].bills =
                [];
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].credits = [];
            }
            summary[offer.nombrePagador + '-' + datum.nombreFondeador].society =
              offer.nombrePagador;
            if (offer.vrOferta) {
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].bills.push(offer);
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].billsNumber += 1;
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].totalValue += offer.vrOferta;
              total += offer.vrOferta;
            } else {
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].credits.push(offer);
              summary[
                offer.nombrePagador + '-' + datum.nombreFondeador
              ].totalValue += offer.vrFactura;
              total += offer.vrFactura;
            }
          });
        });
        billServices
          .listarCuentasProveedor(token, scProveedor)
          .then((response) => {
            this.setState({
              ...this.state,
              accounts: response.data,
              billsSumary: Object.values(summary),
              total,
            });
          });
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  }

  prontoPago = 0;

  columns = [
    {
      title: 'PAGADOR',
      dataIndex: 'society',
    },
    {
      title: '#FACTURAS',
      dataIndex: 'billsNumber',
    },
    {
      title: 'FONDEADOR',
      dataIndex: 'investorName',
    },
    {
      title: 'VALOR',
      dataIndex: 'totalValue',
      render: (value) => (
        <span className='offer-value'>$ {this.formatNumber(value)}</span>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, record) => (
        <InfoCircleOutlined
          className='info'
          onClick={() => this.updateModalVisibility(true, record)}
        />
      ),
    },
  ];

  summaryDetailBillsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => a.scFactura - b.scFactura,
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmision',
      sorter: (a, b) => Date.parse(a.feEmision) - Date.parse(b.feEmision),
      render: (text) => this.formatDate(text, 0),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimiento',
      sorter: (a, b) =>
        Date.parse(a.feVencimiento) - Date.parse(b.feVencimiento),
      render: (text) => this.formatDate(text, 0),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.scFactura - b.scFactura,
      render: (text) => <span>$ {this.formatNumber(text)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'OFERTA COMPRA',
      dataIndex: 'vrOferta',
      sorter: (a, b) => a.scFactura - b.scFactura,
      render: (text) => (
        <span className='offer-value'>$ {this.formatNumber(text)}</span>
      ),
      showSorterTooltip: false,
    },
  ];

  summaryDetailCreditsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => a.scFactura - b.scFactura,
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmision',
      render: (text) => this.formatDate(text, 0),
      sorter: (a, b) =>
        Date.parse(a.feEmisionRest) - Date.parse(b.feEmisionRest),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimiento',
      render: (text) => this.formatDate(text, 0),
      sorter: (a, b) =>
        Date.parse(a.feVencimientoRest) - Date.parse(b.feVencimientoRest),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      render: (value) => '$ ' + this.formatNumber(value),
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      showSorterTooltip: false,
    },
  ];

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  updateSelectedAccount = (value) => {
    this.setState({
      ...this.state,
      selectedAccount: value,
    });
  };

  updateModalVisibility = (boolean, record) => {
    if (boolean) {
      this.setState({
        ...this.state,
        summaryDetail: {
          bills: record.bills,
          credits: record.credits,
        },
        modal: boolean,
      });
    } else {
      this.setState({
        ...this.state,
        changeDataType: false,
        summaryDetail: [],
        modal: boolean,
      });
    }
  };

  changeTable(boolean) {
    this.setState({
      ...this.state,
      changeDataType: !boolean,
    });
  }

  formatDate(date, type) {
    var arrDate = date.split('T');
    if (type === 1) {
      const arrHour = arrDate[type].split('.');
      arrDate[type] = arrHour[0];
    }
    return arrDate[type];
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  nextStep = () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    const affitoken = store.getState().affitoken.affitokenEntered;

    if (!affitoken) {
      this.setState({
        ...this.state,
        loading: false,
      });

      return notification.error({
        message: 'Error',
        description: 'Para continuar, por favor digita el Affitoken',
        duration: 6,
      });
    }
    var operacion = 'O';
    if (this.props.module === 'program') {
      operacion = 'P';
    }
    if (this.state.requestAccount && this.state.selectedAccount === '') {
      this.setState({
        ...this.state,
        loading: false,
      });

      return notification.error({
        message: 'No has seleccionado una cuenta',
        description: 'Para continuar, por favor selecciona una cuenta',
        duration: 6,
      });
    }
    billServices
      .confirmarCarrito(
        token,
        selectedCompany,
        affitoken,
        this.state.selectedAccount,
        operacion
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            ...this.state,
            loading: false,
          });
          this.props.setTracking(response.data.scTracking);
          if (this.props.module === 'assisted') {
            this.props.chargeallNextStep(2);
          } else if (this.props.module === 'program') {
            this.props.programNextStep(2);
          } else {
            this.props.nextStep(2);
          }
          this.props.stepHandler();
        } else {
        }
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  };

  prevStep = () => {
    if (this.props.module === 'assisted') {
      this.props.chargeallNextStep(0);
    } else if (this.props.module === 'program') {
      this.props.programNextStep(0);
    } else {
      this.props.nextStep(0);
    }
    this.props.stepHandler();
  };

  handleCondicionOnClose = () =>{
    this.setState({
      ...this.state,
      condicionVisible:false
    });
  }

  handleOpenCondicionModal = () =>{
    this.setState({
      ...this.state,
      condicionVisible:true
    });
  }

  handleOk = () => {
        this.nextStep()
        this.setState({
          ...this.state,
          condicionVisible:false,
          loading:true
        });
  };

  render() {
    var totalSelectedValue = 0;
    if (this.props.module === 'assisted') {
      totalSelectedValue = store.getState().billing.chargeallTotalSelectedValue;
    } else if (this.props.module === 'program') {
      totalSelectedValue = store.getState().billing.programTotalSelectedValue;
    } else {
      totalSelectedValue = store.getState().billing.totalSelectedValue;
    }
    return (
      <div className='summary-style'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <Card className='card-shadow card-radius default-border first-message'>
            <Row>
              <Col
                xl={{ span: 5, offset: 6 }}
                lg={{ span: 8, offset: 2 }}
                md={{ span: 8, offset: 3 }}
              >
                <img src={Man} alt='' />
              </Col>
              <Col
                xl={{ span: 6, offset: 1 }}
                lg={{ span: 9, offset: 2 }}
                md={{ span: 9, offset: 2 }}
              >
                <div className='description'>
                  Estás a un clic de cobrar tus facturas
                </div>
              </Col>
            </Row>
            <Row className='total'>
              <Col
                xl={{ span: 3, offset: 6 }}
                lg={{ span: 3, offset: 4 }}
                md={{ span: 3, offset: 4 }}
              >
                <span className='text'>Total</span>
              </Col>
              <Col
                xl={{ span: 9, offset: 1 }}
                lg={{ span: 10, offset: 1 }}
                md={{ span: 10, offset: 1 }}
              >
                <span className='value'>
                  $ {this.formatNumber(this.state.total)}
                </span>
              </Col>
            </Row>
            <Row className='table-container'>
              <Col
                xl={{ span: 22, offset: 1 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
              >
                <Divider />
                <Table
                  columns={this.columns}
                  dataSource={this.state.billsSumary}
                ></Table>
              </Col>
            </Row>
            {this.state.requestAccount ? (
              <Row className='account-container table-container'>
                <Col span={5} offset={2}>
                  <div className='select-label'>Elegir cuenta bancaria</div>
                </Col>
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 12 }}
                  lg={{ span: 16 }}
                  md={{ span: 16 }}
                >
                  <div className='select-container'>
                    <Select size='small' onChange={this.updateSelectedAccount}>
                      {this.state.accounts.map((account) => (
                        <Option
                          title={
                            account.nombreBanco +
                            ' ' +
                            account.nroCuenta +
                            ' - ' +
                            account.tipoCuenta
                          }
                          key={account.scCuenta}
                        >
                          {account.nombreBanco +
                            ' ' +
                            account.nroCuenta +
                            ' - ' +
                            account.tipoCuenta}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row className='table-container'>
              <Col span={18} offset={2}>
                <AffitokenValidator />
              </Col>
            </Row>
          </Card>
          <Row gutter={[16, 16]}>
            <Col span={24} className='action-btn-container'>
              <Button className='btn-prev' onClick={this.prevStep}>
                Atrás
              </Button>
              <Button className='btn-next' onClick={this.handleOpenCondicionModal}>
                Aceptar oferta compra
              </Button>
            </Col>
          </Row>
          <Modal
            className='summary-modal'
            centered
            title='Resumen de la transacción'
            destroyOnClose={true}
            visible={this.state.modal}
            onOk={() => this.updateModalVisibility(false, false)}
            onCancel={() => this.updateModalVisibility(false, false)}
            footer={null}
            width={1024}
          >
            <Row gutter={16}>
              <Col xl={{ span: 5 }} md={{ span: 6 }} className='select-label'>
                Facturas/Notas crédito
              </Col>
              <Col
                xl={{ span: 5 }}
                md={{ span: 8 }}
                className='switch-container'
              >
                <Switch
                  checkedChildren='Notas crédito'
                  checked={this.state.changeDataType}
                  unCheckedChildren='Facturas'
                  onChange={() => this.changeTable(this.state.changeDataType)}
                />
              </Col>
            </Row>
            <Row gutter={16} className='margin-top'>
              <Col span={24}>
                <Table
                  loading={this.state.loading}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} de ${total} registros`,
                  }}
                  columns={
                    this.state.changeDataType
                      ? this.summaryDetailCreditsColumns
                      : this.summaryDetailBillsColumns
                  }
                  dataSource={
                    this.state.changeDataType
                      ? this.state.summaryDetail.credits
                      : this.state.summaryDetail.bills
                  }
                ></Table>
              </Col>
            </Row>
          </Modal>
        </Spin>
        <DescuentoNotaModal
          visible={this.state.condicionVisible}
          onClose={this.handleCondicionOnClose}
          handleOk={this.handleOk}
          
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: {
      authtoken: state.auth.auth.access_token,
      company: state.billing.company,
    },
    affitoken: state.affitoken,
    billing: state.billing,
  };
};

export default connect(mapStateToProps, {
  nextStep,
  chargeallNextStep,
  setTracking,
  programNextStep,
})(Summary);
