import {
  BILL_REPORT_SUMMARY_INIT,
  BILL_REPORT_SUMMARY_LOADED
}
  from '../../actions/types'

const initialState = {
  billingSummary:
  {
    totalNmFacturas: 0,
    totalFacturasNuevas: 0,
    totalVrNotas: 0,
    totalNmNotas: 0,
    totalVrfacturas: 0
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case BILL_REPORT_SUMMARY_INIT:
      return state
    case BILL_REPORT_SUMMARY_LOADED:
      return {
        ...state,
        billingSummary: {
          ...state.billingSummary,
          ...action.payload
        }
      }
    default:
      return {
        ...initialState,
        ...state
      }
  }
}