import axios from 'axios'
import { notification } from 'antd';

var baseUrl = process.env.REACT_APP_SERVICES_URL

const middleWare = (axios) => {
  return axios.then(response => {
    if (response.status === 200) {
      return response.data
    } else {
      notification.error({
        message: 'Error',
        description: response.data.message,
        duration: 5
      });
    }
  }).catch((error) => {
    notification.error({
      message: 'Error',
      description: error.response.data.message,
      duration: 5
    });

    return {
      status: false,
      message: 'Error en El Servicio'
    }
  })
}

/**
 * 
 * @param {*} authToken 
 * @param {*} scProveedor 
 */
export const listarNotificaciones = (authToken, scProveedor) => {
  return middleWare(axios({
    url: `${baseUrl}/api/notificacion/lista-notifiaciones/${scProveedor}`,
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: null,
  }))
}

/**
 * 
 * @param {*} authToken 
 * @param {*} scProveedor
 * @param {*} scNotificacion
 */
export const actualizarEstadoLeido = (authToken, scProveedor, scNotificacion) => {
  return middleWare(axios({
    url: `${baseUrl}/api/notificacion/marcar-leida/${scProveedor}/${scNotificacion}`,
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: null,
  }))
}