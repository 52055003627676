import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';
import { DATE_POST_FORMAT } from '../../utilities/messages';

const baseUrl = process.env.REACT_APP_SERVICES_URL_FONDEADOR;

export const listarCuposConvenio = (authtoken, scFondeador, scConvenio) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupo-convenio/${scConvenio}/listar-ultimos-cupos`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarProveedoresConvenio = (
  authtoken,
  scFondeador,
  scConvenio,
  filtros
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/convenios/${scConvenio}/listar-proveedores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarProveedores = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/proveedor/listar-proveedores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarConvenios = (
  authtoken,
  scFondeador,
  filtros = undefined
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/convenios/lista-convenios${
        filtros ? `?${queryString.stringify(filtros)}` : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
export const listarGruposEmpresariales = (
  authtoken,
  scFondeador,
  filtros = undefined
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/listar-grupos-empresariales${
        filtros ? `?${queryString.stringify(filtros)}` : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarBancos = (authtoken, scFondeador, nombreBanco) => {
  if (nombreBanco) {
    nombreBanco = `?nombre=${nombreBanco}`;
  } else {
    nombreBanco = '';
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/listar-bancos${nombreBanco}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarPagadoresCupos = (authtoken, filters) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-pagadores-cupos?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarProveedoresCupos = (authtoken, filters) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-proveedores-cupos?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarGruposEmpresarialesCupos = (authtoken, filters) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-grupos-empresarial-cupos?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTipoProducto= (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-producto`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTipoCupo = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-cupo`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarEstadoCupo = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/estado-registro`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};


export const actualizarConvenio = (
  authtoken,
  scFondeador,
  scConvenio,
  convenioActualizado
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/convenios/${scConvenio}/convenio-fondeador/actualizar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: convenioActualizado,
    })
  );
};

export const baseDias = (authtoken, scFondeador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/tipo-base-dias`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const lotePago = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/ach/generar-lote-pago`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: filtros,
    })
  );
};

export const lotes = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/ach/lista-lotes-ach?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const transacciones = (authtoken, scFondeador, scLoteAch, filtros) => {
  if (filtros) {
    filtros = formatoFechas(filtros);
    filtros = `?${queryString.stringify(filtros)}`;
  } else {
    filtros = '';
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/ach/${scLoteAch}/lista-transacciones-ach${filtros}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const transaccionesExcel = (authtoken, scFondeador, scLoteAch) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/ach/${scLoteAch}/lista-transacciones-ach/exportar/xlsx`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const transaccionesPlano = (
  authtoken,
  scFondeador,
  scLoteAch,
  filtros
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/ach/${scLoteAch}/lista-transacciones-ach/exportar/arhivo-plano?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const conceptos = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/ach/lista-conceptos-ach?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const convenioFondeador = (authtoken, scFondeador, scConvenio) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/convenios/${scConvenio}/convenio-fondeador`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const cupoActual = (authtoken, scFondeador, scEntidad, tipoEntidad) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupos/${scEntidad}/listar-cupos`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: tipoEntidad,
    })
  );
};

export const listarUltimosCupos = (
  authtoken,
  scFondeador,
  scEntidad,
  tipoEntidad
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupos/${scEntidad}/listar-ultimos-cupos`,

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: tipoEntidad,
    })
  );
};

export const getLiquidez = (authtoken, scFondeador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/liquidez/${scFondeador}/listar-liquidez`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const adicionarCupoContraparte = (
  authtoken,
  scFondeador,
  scEntidad,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupos/${scEntidad}/cupo-contraparte/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

export const adicionarNuevaLiquidez = (authtoken, scFondeador, data) => {
  data = formatoFechas(data);
  return middleWare(
    axios({
      url: `${baseUrl}/api/liquidez/${scFondeador}/actualizar-liquidez/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

export const tipoConceptoACH = (authtoken, scFondeador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/formatos-ach`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const categorias = (authtoken, scFondeador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/categorias`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') ||
          nombre.includes('Hasta') ||
          nombre.includes('Corte') ||
          nombre.includes('fechaCorte')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format(DATE_POST_FORMAT);
      } else {
        if (filtros[nombre]) {
          empty[nombre] = filtros[nombre];
        }
      }
    }
  });
  return empty;
};
