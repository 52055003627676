import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_VINCULACION;

export const buscarFichaNaturalPU = (authToken,id) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaNaturalPU/buscarFichaNaturalPU/${id}`,
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${authToken}`
        },
      })
    );
  };
  
  export const listarFichasNaturalPU = (authToken,data) => {
    let filters = formatoFechas(data)
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaNaturalPU/listar?id_nit=${data.id_nit}&nombre=${data.nombre}&fechaDesde=${filters.fechaDesde}&fechaHasta=${filters.fechaHasta}`,
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json'
          "Authorization": `Bearer ${authToken}`
        },
        data: null,
      })
    );
  };

  export const actualizarFichaNaturalPU = (authToken,scCompania,data) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaNaturalPU/actualizarFichaNaturalPU/${scCompania}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        data : data
      })
    );
  };

  export const actualizarEstadoFichaNaturalPU = (authToken,scCompania,data) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaNaturalPU/actualizarEstadoFichaNaturalPU/${scCompania}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        data : data
      })
    );
  };

  export const guardarFichaNaturalPU = (authToken,scCompania,data) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaNaturalPU/guardarFichaNaturalPU/${scCompania}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        data : data
      })
    );
  };

  export const descargarFichaNaturalPU = (authToken,idUser,scCompania) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaNaturalPU/descargarFichaNaturalPU/${idUser}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          "Authorization": `Bearer ${authToken}`
        },
        params: {
          scCompania: scCompania
        }
      })
    );
  };

  const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else if (
        filtros[nombre] &&
        nombre != 'currentPage' &&
        nombre != 'total'
      ) {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};