import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification, Modal } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { dateFormat, dateFormatTwo, sorterDate, sorterText } from '../../../../../utilities/textTools';
import TableReport from '../../../../../components/tableReport/tableReport';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { listarProveedores } from '../../../../../services/admin/reports/quotasService';
import moment from 'moment';
import QueryString from 'qs';

import {
  fichaProximaVencerExcel,
  fichaVigenteExcel,
  listarFichaProximaVencer,
  listarFichaVigente,
} from '../../../../../services/admin/reports/customer_profile';
import CustumerFilterForm from '../custumerFilterForm/custumerFilterForm';
import { setExcel } from '../../../../../utilities/downloadTools';
import VinculacionEditModal from '../vinculacionEditModal/vinculacionEditModal';
import history from '../../../../../services/history';
import VinculacionFilterForm from '../vinculacionFilterForm/vinculacionFilterForm';
import { listarFichasJuridicaPU,activarFichaJuridicaPU, grabarDocumentosFirmados, grabarDocumentosFirmadosJ, actualizarEstadoFichaJuridicaPU, actualizarTipoProductoFichaJuridicaPU } from '../../../../../services/vinculacion/fichaJuridicaPUAdminservice';
import { listarFichasNaturalPU,activarFichaNaturalPU, grabarDocumentosFirmadosN, actualizarEstadoFichaNaturalPU, actualizarTipoProductoFichaNaturalPU } from '../../../../../services/vinculacion/fichaNaturalPUAdminservice';
import { ExportarFichasPU } from '../../../../../services/vinculacion/fichaService';
import { ExportarFichasNaturalPU } from '../../../../../services/vinculacion/fichaNaturalPUAdminservice';
import { ExportarFichasJuridicaPU } from '../../../../../services/vinculacion/fichaJuridicaPUAdminservice';
import { listarDocumentosAnexos,descargarDocumentos, grabarDocumentosAnexos } from '../../../../../services/vinculacion/documentosAnexosAdminService';
import { setExportZip } from '../../../../../utilities/downloadTools';
import { ModalDocFirmados } from '../modalDocsFirmados/ModalDocFirmados';
import { sizeFileValidation } from '../../../../../utilities/validate/fileValidate';
import { ModalCambiarEstado } from '../modalCambiarEstado/ModalCambiarEstado';
import { VDIGITAL_VALIDACION_FICHA } from '../../../../../utilities/messages';
import ModalTipoProducto from '../modalTipoProducto/modalTipoProducto';
import { listarTipoProducto } from '../../../../../services/admin/operation';


const IDENTIFICATION_FILTRO = 'Identificación';
const NAME_FILTRO = 'Nombre/Razón social';

const initialState = {
  form: {
    fechaDesde: moment(),
    fechaHasta: moment(),
    nombre: '',
    id_nit: '',
    tipoFicha:'Natural',
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  visibleEditModal: false,
  filtroSeleccionado: NAME_FILTRO,
  selectedKeys: [],
  selectAll: false,
  unselectedKeys: [],
  visible : false,
  visibleCambiarEstadoModal: false,
  record : {},
  visibleTipoProductoModal:false,
  tipoProductoData:[],
  FileList : []
};

class VinculacionTab extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProv',
        sorter: (a, b) => sorterText(a.nombreProv, b.nombreProv),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACION',
        dataIndex: 'id_nit',
        sorter: (a, b) => sorterText(a.id_nit, b.id_nit),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE CARGA',
        dataIndex: 'feElaboracion',
        sorter: (a,b) => sorterDate(a.feElaboracion,b.feElaboracion),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO PRODUCTO',
        dataIndex: 'dsTipoProducto',
        sorter: (a, b) => sorterText(a.dsTipoProducto, b.dsTipoProducto),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO FICHA',
        dataIndex: 'estado',
        sorter: (a, b) => sorterText(a.estado, b.estado),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO VINCULACION',
        dataIndex: 'dsEstadoVinGeneral',
        sorter: (a, b) => sorterText(a.dsEstadoVinGeneral, b.dsEstadoVinGeneral),
        showSorterTooltip: false,
      },
      {
        title: 'CANALES DE COMUNICACION',
        dataIndex: 'dsMedio',
        showSorterTooltip: false,
      },
      {
        title: 'FICHA CLIENTE',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Ver Ficha Cliente'
            onClick={() => this.redirectToFichaVinculacion(record)}
          >
            FICHA CLIENTE
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'INSCRIPCION USUARIOS',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Ver Ins Usuarios'
            onClick={() => this.redirectToInsUsuarios(record)}
          >
            INSCRIPCION USUARIOS
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'DOCUMENTOS',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Ver doc Anexos'
            onClick={() => this.downloadDocAnexos(record)}
          >
            DOCUMENTOS
          </Button>
        ),
        showSorterTooltip: false,
      }
    ];
  }


  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  onClose = () => {

    this.setState({
      ...this.state,
      visible: false,
      loading:false
    });

  }

  onCloseModalCambiarEstado = () => {

    this.setState({
      ...this.state,
      visibleCambiarEstadoModal: false,
    });

  }

  handleActivar = async () => {

    function UserException(message) {
      this.message = message;
      this.name = "UserException";
    }

    try {

      const { form,resultado,selectedKeys}= this.state;
      const { authToken } = this.props;
      let incompleta = false;
      let data = [];
      

      if (selectedKeys.length > 0){
        resultado.forEach(result => {
        
        if (result.cdEstadoVinGeneral === 'CE'){
          selectedKeys.forEach(selectedKey =>{
            if(result.idUser === selectedKey){
              data.push(result); 
            }
          })
        }else{
          incompleta = true;
        }

      })

      if(incompleta === true){
        throw new UserException(VDIGITAL_VALIDACION_FICHA);
      }

      this.setState({
        ...this.state,
        loading: true,
      });

      let res = "";
      if (form.tipoFicha === 'N'){

        res = await activarFichaNaturalPU(authToken,data);

      }else if (form.tipoFicha === 'J'){

        res = await activarFichaJuridicaPU(authToken,data);

      }


      notification.success({
        message: 'Activado',
        description: res.message,
        duration: 4,
      });

      this.searchData();

      this.setState({
        ...this.state,
        selectedKeys : []
      });
      

    }

    } catch (error) {

      notification.error({
        message: 'Error',
        description: error.message,
        duration: 4,
      });

      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleCargarDocsFirmados = async () =>{

    const { resultado,selectedKeys}= this.state;

    if (selectedKeys.length > 0 && selectedKeys.length === 1){
      resultado.forEach(result => {
        if(result.idUser === selectedKeys[0]){
          let sccompania = result.scCompania;
          this.setState({
            ...this.state,
            visible:true,
            scCompaniaSelected:sccompania
          })
        }
      })
    }else{

      notification.warning({
        message: 'Alerta',
        description: "Solo puede cargar documentos por 1 proveedor",
        duration: 4,
      });
    }
  }

  handleCambiarEstadoVinculacion = async () =>{

    const { selectedKeys}= this.state;
    if (selectedKeys.length > 0 && selectedKeys.length === 1){
        this.setState({
          ...this.state,
          visibleCambiarEstadoModal:true
        })
    }else{

      notification.warning({
        message: 'Alerta',
        description: "Solo puede cambiar el estado de 1 proveedor a la vez",
        duration: 4,
      });

    }
  }

  handleOnFinishCambiarEstado = async (value) =>{

    function UserException(message) {
      this.message = message;
      this.name = "UserException";
    }

    try {

      const { form,resultado,selectedKeys}= this.state;
      const { authToken } = this.props;
      let factoring = true;
      let confirming = true;
      let enestudio = true;
      let data = {};
      
      if (selectedKeys.length > 0){
        resultado.forEach(result => {
          selectedKeys.forEach(selectedKey =>{
            if(result.idUser === selectedKey){
              if (result.cdTipoProducto === 'F'){
                if(result.cdEstadoVinGeneral === 'E'){
                    if (value.estadoVinculacion === "AP" || value.estadoVinculacion === "RE") {
                      data = {
                        cdEstadoVinGeneral:value.estadoVinculacion,
                        cdEstado:'I',
                        idUser:selectedKey
                      }
                    } else {
                      confirming = false;
                    }
                  } else if (result.cdEstadoVinGeneral === 'AP' && value.estadoVinculacion === 'CE' ){
                    data = {
                      cdEstadoVinGeneral:value.estadoVinculacion,
                      cdEstado:'C',
                      idUser:selectedKey
                    }
                  }
                  else{
                    enestudio = false;
                  }
              } else{
                  if(result.cdEstadoVinGeneral === 'E'){
                    if (value.estadoVinculacion === "CE" || value.estadoVinculacion === "DI") {
                      data = {
                        cdEstadoVinGeneral:value.estadoVinculacion,
                        cdEstado:'C',
                        idUser:selectedKey,
                        descripcion: value.descripcionDI
                      }
                    } else {
                      factoring = false              
                    }
                  } else if (result.cdEstadoVinGeneral === 'DI') {
                    if (value.estadoVinculacion === "CE") {
                      data = {
                        cdEstadoVinGeneral:value.estadoVinculacion,
                        cdEstado:'C',
                        idUser:selectedKey
                      }
                    } else {
                      throw new UserException("Las fichas de documentación incompleta solo debe ser cambiadas a registro completo y enviado");
                    }
                  } else {
                    throw new UserException("Solo se puede cambiar de estado fichas que estén en estudio");
                  }
                } 
            } 
          })
        })
        
      if(factoring === false){
        throw new UserException("La ficha a la que quiere cambiar el estado debe ser de un cliente Factoring");
      }

      if(enestudio === false){
        throw new UserException("Solo se puede aprobar o rechazar fichas que estén en estudio");
      }

      if(confirming === false){
        throw new UserException("La ficha a la que quiere cambiar el estado debe ser de un cliente Confirming");
      }


      this.setState({
        ...this.state,
        loading: true,
      });

      let res = "";
      if (form.tipoFicha === 'N'){

        res = await actualizarEstadoFichaNaturalPU(authToken,data);

      }else if (form.tipoFicha === 'J'){

        res = await actualizarEstadoFichaJuridicaPU(authToken,data);

      }

      notification.success({
        message: 'Cambio de estado exitoso',
        description: "El cambio de estado se realizó con éxito",
        duration: 4,
      });

      this.searchData();

      this.setState({
        ...this.state,
        selectedKeys : []
      });
      
    }

    } catch (error) {

      notification.error({
        message: 'Error',
        description: error.message,
        duration: 4,
      });

      this.setState({
        ...this.state,
        loading: false,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
        visibleCambiarEstadoModal: false,
      });
    }
  }

  handleSubmit = (value) => {
    if(value.tipoFicha === "Natural") value.tipoFicha = 'N'
    if(value.tipoFicha === "Juridica" ||value.tipoFicha === undefined ) value.tipoFicha = 'J'
    if(value.id_nit === undefined) value.id_nit = ''
    if(value.nombre === undefined) value.nombre = ''
    this.setState({
      ...this.state,
      form: {
        tipoFicha: value.tipoFicha,
        id_nit: value.id_nit,
        nombre: value.nombre,
        fechaDesde: value.fechaRango[0],
        fechaHasta: value.fechaRango[1],
        tipoProducto: value.tipoProducto,
        estadoVinGeneral: value.estadoVinGeneral
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {

    const {form} = this.state

    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        if(form.tipoFicha === 'J'){
          const response = await listarFichasJuridicaPU(
            this.props.authToken,
            this.getFiltro()
          );
          this.setState({
            ...this.state,
            resultado: response.data,
            total: response.metadata.count,
            loading: false,

          });
        }
        if(form.tipoFicha === 'N'){
          const response = await listarFichasNaturalPU(
            this.props.authToken,
            this.getFiltro()
          );
          this.setState({
            ...this.state,
            resultado: response.data,
            total: response.metadata.count,
            loading: false,
          });
        }
      } finally {
        this.setState({
          ...this.state,
          loading: false,
          selectedKeys : []
        });
      }
    }
  };

  exportExcel = async () => {

    const {form} = this.state
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        if(form.tipoFicha === 'J'){
        const response = await ExportarFichasJuridicaPU(
          this.props.authToken,
          this.getFiltro()
        );
        setExcel(response, `Fichas_Juridica`);
      }
      if(form.tipoFicha === 'N'){
        const response = await ExportarFichasNaturalPU(
          this.props.authToken,
          this.getFiltro()
        );
        setExcel(response, `Fichas_Natural`);
      }
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesde;
    const fechaHasta = this.state.form.fechaHasta;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  redirectToFichaVinculacion = (record) => {
    const {form} = this.state
    const search = QueryString.stringify({
      tipoFicha: form.tipoFicha,
      feDesde: form.fechaDesde.format("YYYYMMDD"),
      feHasta: form.fechaHasta.format("YYYYMMDD"),
      id_nit: form.id_nit,
      nombre: form.nombre,
      tipoProducto: form.tipoProducto,
      estadoVinGeneral: form.estadoVinGeneral
    });
    let cdFicha = form.tipoFicha
    history.push({
      pathname: `${process.env.PUBLIC_URL}/reports/customer_profile/fichaClienteEdit/${Buffer.from(record.idUser).toString('base64')}/${Buffer.from(cdFicha).toString('base64')}`,
      search,
    });
  };

  redirectToInsUsuarios = (record) => {
    const {form} = this.state
    const search = QueryString.stringify({
      tipoFicha: form.tipoFicha,
      feDesde: form.fechaDesde.format("YYYYMMDD"),
      feHasta: form.fechaHasta.format("YYYYMMDD"),
      id_nit: form.id_nit,
      nombre: form.nombre,
      tipoProducto: form.tipoProducto,
      estadoVinGeneral: form.estadoVinGeneral
    });
    history.push({
      pathname: `${process.env.PUBLIC_URL}/reports/customer_profile/inscripcionUsuarios/${Buffer.from(record.scCompania).toString('base64')}/${Buffer.from(record.idUser).toString('base64')}`,
      search,
    });
  };

  onSelect = (record, selected) => {
    const { selectAll } = this.state;
    let unselectedKeys = [];
    if (selected) {
      if (this.state.selectAll) {
        unselectedKeys = this.state.unselectedKeys.filter(
          (key) => key !== record.key
        );
      }
      this.setState({
        ...this.state,
        selectedKeys: [...this.state.selectedKeys, record.key],
        unselectedKeys: unselectedKeys,
        record : record
      });
    } else {
      if (selectAll) {
        unselectedKeys = [...this.state.unselectedKeys, record.key];
      }
      let filterKeys = this.state.selectedKeys.filter(
        (selectedKey) => selectedKey !== record.key
      );
      this.setState({
        ...this.state,
        selectedKeys: filterKeys,
        unselectedKeys: unselectedKeys,
      });
    }
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      changeRows.forEach((row) => {
        let selected = this.state.selectedKeys.find((key) => row.key === key);
        if (!selected) {
          this.state.selectedKeys = [...this.state.selectedKeys, row.key];
        }
      });
      this.setState({
        ...this.state,
        unselectedKeys: [],
        selectAll: selected,
      });
    } else {
      this.setState({
        ...this.state,
        selectedKeys: [],
        unselectedKeys: [],
        selectAll: selected,
      });
    }
  };

  downloadDocAnexos = async (record) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const res = await listarDocumentosAnexos(this.props.authToken,record.scCompania);
      if(res.metadata.count > 0){

        try {
          const response = await descargarDocumentos(this.props.authToken,record.scCompania);
          setExportZip(response,"Documentos");
        }
        catch{
          notification.error({
            message: 'Ocurrio Un Error',
            description: "No se logró descargar los documentos",
            duration: 4,
          });}

          this.setState({
            ...this.state,
            loading: false,
          });
    
      }else{
        notification.warning({
          title: 'Aviso',
          message: "El Usuario no ha cargado documentos",
          duration: 5,
        })
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    } catch (error) {

      notification.error({
        title: 'Error',
        message: "Error Descargando Documentos",
        duration: 5,
      });
    }
  };

  handleEditModalOnClose = () => {
    this.setState({
      ...this.state,
      cuentaEdit: {},
      visibleEditModal: false,
    });
  };

  handleCambiarEstadoModalOnClose = () => {
    this.setState({
      ...this.state,
      visibleCambiarEstadoModal: false,
    });
  };

  handleModalOnFinish = (user) => {
    this.setState({
      ...this.state,
      userEdit: {},
      visibleEditModal: false,
    });
  };

  handleSwitchChange = (check) => {
    let nombre = NAME_FILTRO;
    if (check) {
      nombre = IDENTIFICATION_FILTRO;
    }

    this.formSearch.current.setFieldsValue({
      tipoSearch: check,
      nombre: '',
      id: '',
    });

    this.setState({
      ...this.state,
      filtroSeleccionado: nombre,
      filtros: {
        ...this.state.filtros,
        nombre: '',
        id: '',
      },
    });
  };

  init = async (props) => {
    const filtro = this.parseQueryString(props);
    if (
      (filtro.tipoFicha !== undefined) &&
      (filtro.feDesde !== undefined) &&
      (filtro.feHasta !== undefined)
    ) {
      await this.updateStatePage(filtro);
      await this.searchData();
    } else {
      this.setState({ loading: false });
    }
  };

  updateStatePage = async (filtro) => {
    this.setState({
      ...this.state,
      form: {
        tipoFicha: filtro.tipoFicha,
        id_nit: filtro.id_nit || '',
        nombre: filtro.nombre || '',
        fechaDesde: moment(filtro.fechaDesde,"YYYYMMDD"),
        fechaHasta: moment(filtro.fechaHasta,"YYYYMMDD"),
        tipoProducto: filtro.tipoProducto,
        estadoVinGeneral: filtro.estadoVinGeneral
      },
      currentPage: filtro.currentPage || 1,
      offset: filtro.offset || 0,
    });

  };

  handleUpload = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({
      ...this.state,
      fileList: fileList,
    });
  };

  handleOnFinish = async () => {
    this.setState({
      loading: true,
    });
    const { 
      fileList,
      selectedKeys,
      resultado,
      form
    } = this.state;

    let response;
    let scCompania

    resultado.forEach((result) =>{
      if(result.idUser === selectedKeys[0]){
        scCompania = result.scCompania
      }
    })

    if(fileList.length > 0){
      try {
        if (form.tipoFicha === 'J'){
          response = await grabarDocumentosFirmadosJ(this.props.authToken, fileList, scCompania);
        }else if (form.tipoFicha === 'N'){
          response = await grabarDocumentosFirmadosN(this.props.authToken, fileList, scCompania);
        }
        notification.success({
          message: 'Documentos Cargados Con Exito',
          description: response.message,
          duration: 5,
        });
      }
      catch{
        notification.error({
          message: 'Ocurrio Un Error',
          description: "No se logro guardar los cambios",
          duration: 4,
        })
      }
      finally {
        this.setState({
          ...this.state,
          loading: false,
          visible:false,
          selectedKeys: [],
          fileList: []
        });
      }
    }else{
      this.setState({
        loading: false,
      });
      notification.warn({
        message: 'Accion no permitida',
        description: "No hay archivos para guardar",
        duration: 4,
      })
    }
  };

  isValidFile = async (fileList) => {
    let fileMessageErrors = [];
    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }
    this.setState({ ...this.state, fileMessageErrors });
    return fileMessageErrors.length === 0;
  };

  parseQueryString = (props) => {
    const {
      location: { search },
    } = props;

    const filtro = QueryString.parse(search, { ignoreQueryPrefix: true });

    return {
      ...filtro,
      limit: parseInt(filtro.limit),
      currentPage: parseInt(filtro.currentPage),
      offset: parseInt(filtro.offset),
      tipoFicha: filtro.tipoFicha,
      proveedor: filtro.prov,
      id_nit:filtro.id_nit,
      fechaDesde:filtro.feDesde,
      fechaHasta:filtro.feHasta,
      tipoProducto:filtro.tipoProducto,
      estadoVinGeneral:filtro.estadoVinGeneral
    };
  };

  handleOnFinishTipoProducto = async (formValue) => {
    const { selectedKeys , form}= this.state;
    const {authToken} = this.props
    this.setState({
      loading: true,
      visibleTipoProductoModal:false
    });

    const data = {
      cdTipoProducto: formValue.cdtipoProducto,
      idUsers: selectedKeys
    }

    if (form.tipoFicha === 'N') await actualizarTipoProductoFichaNaturalPU(authToken,data);
    if (form.tipoFicha === 'J') await actualizarTipoProductoFichaJuridicaPU(authToken,data);
    notification.info({
      message: 'Completado',
      description: "Actualizacion completada",
      duration: 4,
    });
    this.setState({
      loading: false,
    });
    this.searchData()
  }

  showModalTipoProducto(){
    const { selectedKeys}= this.state;
    if(selectedKeys.length >= 1){
      this.setState({
        ...this.state,
        visibleTipoProductoModal:true
      })
    }else{
      notification.info({
        title: 'Info',
        message: "Por favor selecciona el registro antes de continuar",
        duration: 5,
      })
  }
}
  getInitTipoProducto = async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoProductoRes = await listarTipoProducto(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoProductoData: tipoProductoRes.data,
    });
  };

  hiddenModalTipoProducto(){
    this.setState({
      ...this.state,
      visibleTipoProductoModal:false
    })
  }

  componentDidMount() {
    this.init(this.props);
    this.getInitTipoProducto();
  }
  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      proveedorSelect,
      visibleEditModal,
      form,
      selectedKeys,
      visible,
      fileList,
      visibleCambiarEstadoModal,
      scCompaniaSelected,
      record,
      visibleTipoProductoModal,
      tipoProductoData
    } = this.state;

    const {authToken} = this.state

    this.rowSelection = {
      type: 'checkbox',
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      selectedRowKeys: selectedKeys,
    };

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <VinculacionFilterForm
            proveedorSelect={proveedorSelect}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              keyIndex={'idUser'}
              rowSelection={this.rowSelection}
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
          {resultado.length > 0 && (
            <>
            <Row gutter={[45, 30]} justify='center' align='middle'>
              <Col span={6}>
                <Button
                  className='btn btn-blue btn-radius'
                  type='primary'
                  onClick={() => this.handleActivar()}
                >
                  Activar
                </Button>
              </Col>
              <Col span={6}>
              <Button
                className='btn btn-blue btn-radius'
                type='primary'
                onClick={() => this.handleCargarDocsFirmados()}
              >
                Cargar Docs Firmados
              </Button>
              </Col>
              <Col span={6}>
              <Button
                className='btn btn-blue btn-radius'
                type='primary'
                onClick={() => this.handleCambiarEstadoVinculacion()}
                style={{ width: 'auto' }}
              >
                Cambiar Estado Solicitud
              </Button>
              </Col>
              
              <Col span={6}>
              <Button
                className='btn btn-blue btn-radius'
                type='primary'
                onClick={() => this.showModalTipoProducto()}
                style={{ width: 'auto' }}
              >
                Cambiar Producto
              </Button>
              </Col>
            </Row>
            </>
          )}
          {visibleEditModal && (
          <VinculacionEditModal
            authToken={authToken}
            data={null}
            visible={visibleEditModal}
            onClose={this.handleEditModalOnClose}
            onFinish={this.handleModalOnFinish}
          />
        )}
        <Modal
        title='Cargar Documentos Firmados'
        visible={visible}
        className='modal-style'
        footer={null}
        onCancel={this.onClose}
        >
          <ModalDocFirmados
            handleUpload={this.handleUpload}
            handleOnFinish={this.handleOnFinish}
            fileList={fileList}
            token={this.props.authToken}
            scCompania={scCompaniaSelected}
            toggleLoading={loading}
          />
        </Modal>
        <Modal
        title='Cambiar Estado Vinculacion'
        visible={visibleCambiarEstadoModal}
        footer={null}
        onCancel={this.onCloseModalCambiarEstado}
        >
          <ModalCambiarEstado
            handleOnFinishCambiarEstado={this.handleOnFinishCambiarEstado}
            tipoProducto={record.cdTipoProducto}
          />
        </Modal>
        <Modal
          visible={visibleTipoProductoModal}
          onCancel={() => this.hiddenModalTipoProducto(false)}
          footer={null}
        >
        <ModalTipoProducto handleOnFinish={this.handleOnFinishTipoProducto} tipoProductoData={tipoProductoData}/>
        </Modal>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state,props) => {
  const tab = props.match.params.currentTab; //Buffer.from(props.match.params.activeTab,'base64').toString('utf8');
  return {
    currentTab:tab
  };
};

export default withRouter(connect(mapStateToProps, {})(VinculacionTab));