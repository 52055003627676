import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Card, Tabs, Spin, Col, Button, notification, Modal } from 'antd';
import '../../common/client.scss';
import history from '../../../../../services/history';
import {
  editarPagador,
  getPagador,
  listarCalendariosPagos,
} from '../../../../../services/admin/payersService';
import BasicDataForm, {
  initialPayerValuesForm,
} from '../basicDataForm/basicDataForm';
import Documents from '../../common/documents/documents';
import Users from '../../common/users/users';
import CalendarioPago from '../calendarioPago/calendarioPago';

const TIPO_ASOCIADO = 'P';

const PAYERS_TITLE = 'Pagadores';

const initialState = {
  activeTab: '1',
  loading: false,
  propietarioSelect: {
    options: [],
    fetching: false,
  },
  data: { ...initialPayerValuesForm },
  pagosPagador: [],
  visibleCalendario: false,
};

class PayersEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  redirectToPayers = () => {
    history.push(`${process.env.PUBLIC_URL}/payers`);
  };

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await editarPagador(this.props.authToken, formValue);

      notification.success({
        message: PAYERS_TITLE,
        description: response.message,
        duration: 6,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getData = async () => {
    const { authToken, scPersona } = this.props;

    this.setState({ ...this.state, loading: true });

    try {
      const pagadorResponse = await getPagador(authToken, scPersona);

      const pagosResponse = await listarCalendariosPagos(authToken, scPersona);

      const { count } = pagosResponse.metadata;
      this.setState({
        ...this.state,
        loading: false,
        data: {
          ...pagadorResponse.data,
          nmPagosPagador: count === 0 ? 1 : count,
        },
        pagosPagador: pagosResponse.data,
      });
    } catch {
      this.redirectToPayers();
    }
  };

  handleOnConfigCalendar = (nmPagosPagador) => {
    let { pagosPagador } = this.state;

    if (pagosPagador.length > nmPagosPagador) {
      pagosPagador.splice(nmPagosPagador);
    } else if (pagosPagador.length < nmPagosPagador) {
      let diferencia = nmPagosPagador - pagosPagador.length - 1;
      for (let i = 0; i <= diferencia; i++) {
        pagosPagador.push({});
      }
    }

    this.setState({
      ...this.state,
      pagosPagador: pagosPagador,
      visibleCalendario: true,
    });
  };

  handleOnCloseModal = () => {
    this.setState({
      ...this.state,
      visibleCalendario: false,
    });
  };

  handleTabOnChange = (e) => {
    this.setState({
      ...this.state,
      activeTab: e,
    });
  };

  handleOnFinishCalendario = (calendarioPagos) => {
    console.log('handleOnFinishCalendario', calendarioPagos);
    this.setState({
      ...this.state,
      visibleCalendario: false,
      pagosPagador: calendarioPagos,
    });
  };

  componentDidMount() {
    this.props.menuHandler('22');
    this.getData();
  }

  render() {
    const { loading, activeTab, data, pagosPagador, visibleCalendario } =
      this.state;
    const { authToken, scPersona } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Tabs activeKey={activeTab} onChange={this.handleTabOnChange}>
              <Tabs.TabPane key={'1'} tab='Datos básicos'>
                <BasicDataForm
                  visibleSearchButton={false}
                  data={data}
                  onFinish={this.handleOnFinish}
                  visibleCalendar={true}
                  onConfigCalendar={this.handleOnConfigCalendar}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToPayers}
                      >
                        Lista de Pagadores
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>

                <Modal
                  title='Calendarios de pago'
                  visible={visibleCalendario}
                  className='modal-style'
                  footer={null}
                  onCancel={this.handleOnCloseModal}
                >
                  <CalendarioPago
                    data={pagosPagador}
                    authToken={authToken}
                    scPersona={scPersona}
                    onFinish={this.handleOnFinishCalendario}
                  />
                </Modal>
              </Tabs.TabPane>

              <Tabs.TabPane key={'2'} tab='Documentos'>
                <Documents
                  authToken={authToken}
                  scPersona={scPersona}
                  tipoAsociado={TIPO_ASOCIADO}
                >
                  <Col span={6}>
                    <Button
                      style={{ width: '90%' }}
                      className='btn btn-blue'
                      type='primary'
                      onClick={this.redirectToPayers}
                    >
                      Lista de Pagadores
                    </Button>
                  </Col>
                </Documents>
              </Tabs.TabPane>
              <Tabs.TabPane key={'4'} tab='Usuarios'>
                <Users
                  authToken={authToken}
                  scPersona={scPersona}
                  tipoAsociado={TIPO_ASOCIADO}
                >
                  <Col span={6}>
                    <Button
                      style={{ width: '90%' }}
                      className='btn btn-blue'
                      type='primary'
                      onClick={this.redirectToPayers}
                    >
                      Lista de Pagadores
                    </Button>
                  </Col>
                </Users>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scPersona = atob(props.match.params.scPersona);
  return {
    scPersona: scPersona,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(PayersEditForm));
