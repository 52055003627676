import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const buscarFichaJuridicaPU = (authToken,id) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/buscarFichaJuridicaPU/${id}`,
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json'
          "Authorization": `Bearer ${authToken}`
        },
      })
    );
  };

  export const listarFichasJuridicaPU = (authToken,data) => {
    let filters = formatoFechas(data)
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/listar?${queryString.stringify(
          filters
        )}`,
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json'
          "Authorization": `Bearer ${authToken}`
        },
        data: null,
      })
    );
  };

  export const actualizarFichaJuridicaPU = (authToken,scCompania,data) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/actualizarFichaJuridicaPU/${scCompania}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        data : data
      })
    );
  };

  export const guardarFichaJuridicaPU = (authToken,scCompania,data) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/guardarFichaJuridicaPU/${scCompania}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        data : data
      })
    );
  };

  export const activarFichaJuridicaPU = (authToken,data) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/activarFichaJuridicaPU`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        data : data
      })
    );
  };

  export const actualizarEstadoFichaJuridicaPU = (authToken,data) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/actualizarEstadoFichaJuridicaPU`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        data : data
      })
    );
  };

  export const actualizarTipoProductoFichaJuridicaPU = (authToken,data) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/actualizarTipoProductoFichaJuridicaPU`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        data : data
      })
    );
  };

  export const descargarFichaJuridicaPU = (authToken,idUser) => {
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/descargarFichaJuridicaPU/${idUser}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          "Authorization": `Bearer ${authToken}`
        },
      })
    );
  };

  export const grabarDocumentosFirmadosJ = (authtoken, data, scCompania) => {
    const formData = new FormData();

    for(let i = 0; i < data.length ; i++ ){
      formData.append("files", data[i].originFileObj);
    }
        
    return middleWare(
      axios({
        url: `${baseUrl}/api/fichaJuridicaPU/cargarDocsFirmados/${scCompania}`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authtoken}`,
        },
        data: formData,
      })
    );
  };

  const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else if (
        filtros[nombre] &&
        nombre != 'currentPage' &&
        nombre != 'total'
      ) {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};

export const ExportarFichasJuridicaPU = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  if (filters.resultado) {
    delete filters.resultado
  }
  return middleWare(axios({
    url: `${baseUrl}/api/fichaJuridicaPU/exportar/xlsx?${queryString.stringify(filters)}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}