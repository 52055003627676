import React from 'react'
import './reportCenter.scss'
import { Row, Col, Card, DatePicker, message, Button, Menu, Table, Select, Input, notification, Divider, Spin, Popover } from 'antd'
import { UserOutlined, FileExcelOutlined, DownloadOutlined, QuestionCircleFilled } from '@ant-design/icons';
//import BarChart from '../../components/barchart'
import LineChart from '../../../components/linechart'
import DoughnutChart from '../../../components/doughnutchart'
import {
  reporteFacturasTramite,
  reporteFacturasFinalizadas,
  listarGrupos,
  listarPagadores,
  listarFondeadores,
  flujoDeCajaEsperado,
  facturasProveedorPorCompania,
  soporteComisiones,
  soporteTransaccionesFinalizadas,
  reporteFacturasFinalizadasExcel,
  reporteFacturasEnTramiteExcel,
  soporteComisionesExcel,
  soporteComisionesDetallePDF,
  soporteComisionesDetalleAnexoPDF,
  reporteSoporteTransaccionesFinalizadasExcel
} from '../../../services/proveedor/reportServices'
import billServices from '../../../services/proveedor/billServices'
import store from '../../../store/index'
import { capitalize } from '../../../utilities/textTools'
import { connect } from "react-redux";
import moment from 'moment'
import {
  REPORTS_DATES_REQUIRED_ERROR,
  REPORTS_TITLE_INIT,
  REPORTS_SUBTITLE_INIT,
  REPORTS_DISBURSED_INIT,
  REPORTS_ON_TRAMIT_INIT,
  REPORTS_COMISION_INIT,
  REPORTS_FINISHED_INIT,
  DASHBOARD_GRAPH_INIT
} from '../../../utilities/messages'
import Total from './total';

const { RangePicker } = DatePicker;

const { Option } = Select

class ReportCenter extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      groups: [],
      investors: [],
      payers: [],
      payersOnTramit: [],
      espectedFlow: {
        data: [],
        min: 0,
        max: 0
      },
      loading: false,
      activeFilter: false,
      activeFilters: {
        disbursedBills: false,
        onTramitBills: false,
        commissionSupports: false,
        finishedTransactionsSupports: false
      },
      billsByCompany: [],
      disbursedBills: [],
      onTramitBills: [],
      commissionSupports: [],
      finishedTransactions: [],
      disbursedBillsFilters: {
        group: '',
        payer: '',
        investor: '',
        account: '',
        billNumber: '',
        feDesde: '',
        feHasta: '',
        limit: ''
      },
      onTramitBillsFilters: {
        group: '',
        payer: '',
        investor: '',
      },
      commissionSupportsFilters: {
        feDesde: '',
        feHasta: '',
        feHastaMoment: '',
        feDesdeMoment: '',
      },
      finishedTransactionsSupportsFilters: {
        feDesde: '',
        feHasta: '',
        feHastaMoment: '',
        feDesdeMoment: '',
        transactionId: ''
      },
      pagination: {
        currentPage: 1,
        total: 10
      },
      sumatorias: {}
    };
  }

  disbursedBillsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      showSorterTooltip: false,
    },
    {
      title: 'VALOR REPORTADO',
      dataIndex: 'vrReportadoFactura',
      render: value => <span>$ {(this.formatNumber(value))}</span>,
      sorter: (a, b) => a.vrReportadoFactura - b.vrReportadoFactura,
      showSorterTooltip: false
    },
    {
      title: 'VALOR BASE',
      dataIndex: 'vrBaseFactura',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrBaseFactura - b.vrBaseFactura,
      showSorterTooltip: false
    },
    {
      title: 'FECHA VENCIMIENTO',
      dataIndex: 'feVencimiento',
      render: text => (this.formatDate(text)),
      sorter: (a, b) => Date.parse(a.feVencimiento) - Date.parse(b.feVencimiento),
      showSorterTooltip: false
    },
    {
      title: 'PLAZO DESCONTADO',
      dataIndex: 'plazoDescontado',
      sorter: (a, b) => a.plazoDescontado - b.plazoDescontado,
      showSorterTooltip: false
    },
    {
      title: 'TASA DESCONTADO',
      dataIndex: 'tasaMA',
      render: value => (String(value).substring(0, 4)),
      sorter: (a, b) => a.tasaMA - b.tasaMA,
      showSorterTooltip: false
    },
    {
      title: 'TASA PROPORCIONAL',
      dataIndex: 'tasaProporcional',
      render: value => {
        if (value) {
          return String(value).substring(0, 4)
        }
      },
      sorter: (a, b) => a.tasaProporcional - b.tasaProporcional,
      showSorterTooltip: false
    },
    {
      title: 'FECHA DESEMBOLSO',
      dataIndex: 'feDesembolso',
      render: text => (this.formatDate(text)),
      sorter: (a, b) => a.feDesembolso - b.feDesembolso,
      showSorterTooltip: false
    },
    {
      title: 'VALOR PAGADO',
      dataIndex: 'vrPagado',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrPagado - b.vrPagado,
      showSorterTooltip: false
    },
    {
      title: 'VALOR TRANSACCION',
      dataIndex: 'vrTransaccion',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrTransaccion - b.vrTransaccion,
      showSorterTooltip: false
    }
  ]

  onTramitBillsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      showSorterTooltip: false,
    },
    {
      title: 'FECHA COMPRA',
      dataIndex: 'feCompra',
      render: text => (this.formatDate(text)),
      sorter: (a, b) => Date.parse(a.feCompra) - Date.parse(b.feCompra),
      showSorterTooltip: false,
    },
    {
      title: 'PAGADOR',
      dataIndex: 'pagador',
      sorter: (a, b) => a.pagador - b.pagador,
      showSorterTooltip: false,
    },
    {
      title: 'FONDEADOR',
      dataIndex: 'fondeador',
      sorter: (a, b) => a.fondeador - b.fondeador,
      showSorterTooltip: false,
    },
    {
      title: 'NUMERO CUENTA',
      dataIndex: 'nroCuenta',
      showSorterTooltip: false,
    },
    {
      title: 'TASA PROPORCIONAL',
      dataIndex: 'tasaProporcional',
      render: value => {
        if (value) {
          return String(value).substring(0, 4)
        }
      },
      sorter: (a, b) => a.tasaProporcional - b.tasaProporcional,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR OFERTA',
      dataIndex: 'vrOferta',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrOferta - b.vrOferta,
      showSorterTooltip: false,
    },
  ]

  commissionSupportsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'nmFactura',
      sorter: (a, b) => a.scFacturaExp - b.scFacturaExp,
      showSorterTooltip: false,
    },
    {
      title: 'FECHA',
      dataIndex: 'feEmision',
      render: text => (this.formatDate(text)),
      sorter: (a, b) => Date.parse(a.feEmision) - Date.parse(b.feEmision),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR BASE',
      dataIndex: 'vrFacturaBase',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      showSorterTooltip: false,
    },
    {
      title: 'ESTADO',
      dataIndex: 'estado',
      showSorterTooltip: false,
    },
    {
      title: 'IVA',
      dataIndex: 'vrIva',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrIva - b.vrIva,
      showSorterTooltip: false,
    },
    {
      title: 'RETE. FUENTE',
      dataIndex: 'vrRetefuente',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrRetefuente - b.vrRetefuente,
      showSorterTooltip: false,
    },
    {
      title: 'RETE. IVA',
      dataIndex: 'vrReteIva',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrReteIva - b.vrReteIva,
      showSorterTooltip: false,
    },
    {
      title: 'FACTURA',
      dataIndex: '',
      render: (text, record) => <DownloadOutlined title='Descargar anexo' className="info" onClick={() => this.commissionSupportDetailAddPDF(record.nmFactura)} className="info" />,
      showSorterTooltip: false,
    },
    {
      title: 'SOPORTE',
      dataIndex: '',
      render: (text, record) => <DownloadOutlined title='Descargar soporte' className="info" onClick={() => this.commissionSupportDetailPDF(record.nmFactura)} className="info" />,
      showSorterTooltip: false,
    },

  ]

  finishedTransactionsSupportsColumns = [
    {
      title: '# TRANSACCIÓN',
      dataIndex: 'scTracking',
      sorter: (a, b) => a.scTracking - b.scTracking,
      showSorterTooltip: false,
    },
    {
      title: 'MONTO TOTAL TRANSACCIÓN',
      dataIndex: 'vrOferta',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrOferta - b.vrOferta,
      showSorterTooltip: false,
    },
    {
      title: 'FACTURAS',
      dataIndex: 'cantidadFacturas',
      sorter: (a, b) => a.cantidadFacturas - b.cantidadFacturas,
      showSorterTooltip: false,
    },
    {
      title: 'MONTO TOTAL NOTAS',
      dataIndex: 'vrNotaCredito',
      render: value => <span>$ {(this.formatNumber(value))} </span>,
      sorter: (a, b) => a.vrNotaCredito - b.vrNotaCredito,
      showSorterTooltip: false,
    },
    {
      title: 'NOTAS',
      dataIndex: 'cantidadNotasCreditos',
      sorter: (a, b) => a.cantidadNotasCreditos - b.cantidadNotasCreditos,
      showSorterTooltip: false,
    },
    {
      title: 'FECHA',
      dataIndex: 'fechaIngreso',
      render: text => (this.formatDate(text)),
      sorter: (a, b) => Date.parse(a.fechaIngreso) - Date.parse(b.fechaIngreso),
      showSorterTooltip: false,
    },
    {
      title: 'USUARIO MODIFICA',
      dataIndex: 'nombrePersonaModifica',
      sorter: (a, b) => a.nombrePersonaModifica - b.nombrePersonaModifica,
      showSorterTooltip: false,
    },
  ]

  componentDidMount() {
    this.props.menuHandler('13')
    const scProveedor = store.getState().billing.company;
    if (scProveedor) {
      this.loadSelectsData(scProveedor)
    }
    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  loadSelectsData = (scProveedor) => {
    this.setState({
      ...this.state,
      loading: true
    })
    const token = store.getState().auth.auth.access_token;
    billServices.listarCuentasProveedor(token, scProveedor).then((response) => {
      this.setState({
        ...this.state,
        accounts: response.data
      })
    })

    listarGrupos(token, scProveedor).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          groups: response.data
        })
      }
    })

    listarPagadores(token, scProveedor).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          payers: response.data,
          payersOnTramit: response.data
        })
      }
    })

    listarFondeadores(token, scProveedor).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          investors: response.data
        })
      }
    })

    flujoDeCajaEsperado(token, scProveedor).then((response) => {
      if (response.data.length > 0) {
        var flow = []
        const groups = response.data.reduce((acc, date) => {
          var momentDate = moment(date.feEstimadaPago)
          const yearWeek = `${momentDate.format('MMMM')} ${momentDate.startOf("isoWeek").format('DD')}-${momentDate.endOf("isoWeek").format('DD')}`;
          if (!acc[yearWeek]) {
            acc[yearWeek] = [];
          }
          acc[yearWeek].push(date);
          return acc;
        }, []);


        let groupsArray = Object.values(groups)
        let groupsNamesArray = Object.keys(groups)


        for (let index = 0; index < groupsArray.length; index++) {
          flow.push({
            value: groupsArray[index].reduce((acc, item) => (acc += item.totalVrFacturas), 0),
            label: groupsNamesArray[index]
          })
        }
        this.setState({
          ...this.state,
          espectedFlow: {
            data: flow,
          }
        })
      }
      else {
        this.setState({
          ...this.state,
          espectedFlow: {
            data: [],
            min: 0,
            max: 0
          },
        })

      }
    })

    facturasProveedorPorCompania(token, scProveedor).then((response) => {
      if (response.data) {
        var bills = []
        response.data.forEach(datum => {
          bills.push({
            label: datum.nombrePagador,
            value: datum.totalVrfacturas - datum.totalVrNotas
          })
        });
        this.setState({
          ...this.state,
          billsByCompany: bills
        })
      }
      this.setState({
        ...this.state,
        loading: false
      })
    })

    var properties = Object.getOwnPropertyNames(this.state.activeFilters)
    properties.forEach(property => {
      if (this.state.activeFilters[property]) {
        switch (property) {
          case 'disbursedBills':
            this.disbursedBills()
            break;
          case 'onTramitBills':
            this.onTramit()
            break;
          case 'commissionSupports':
            this.commissionSupport()
            break;
          case 'finishedTransactionsSupports':
            this.finishedTransactionsSupports()
            break;
        }
        return false;
      }
    });
  }

  onBackButtonEvent = (e) => {
    const filters = Object.getOwnPropertyNames(this.state.activeFilters)
    var isActive = filters.some((filter) => (
      this.state.activeFilters[filter]
    ))
    if (isActive) {
      e.preventDefault()
      this.prevStep()
    }
  }

  componentDidUpdate(props, state) {
    if (props.billing.company !== this.props.billing.company) {
      const scProveedor = this.props.billing.company;
      this.state.disbursedBillsFilters = {
        group: '',
        payer: '',
        investor: '',
        account: '',
      }
      this.state.onTramitBillsFilters = {
        group: '',
        payer: '',
        investor: '',
      }
      this.loadSelectsData(scProveedor)
    }
  }

  formatDate(date) {
    var arrDate = date.split('T');
    if (arrDate.length > 0) {
      return arrDate[0]
    } else {
      return date;
    }
  }

  commissionSupportDetailPDF = (nmFactura) => {
    this.setState({
      ...this.state,
      loading: true
    })
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    soporteComisionesDetallePDF(token, scProveedor, nmFactura).then((response) => {
      this.setState({
        ...this.state,
        loading: false
      })
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Soporte comision factura ${nmFactura}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  }

  commissionSupportDetailAddPDF = (nmFactura) => {
    this.setState({
      ...this.state,
      loading: true
    })
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    soporteComisionesDetalleAnexoPDF(token, scProveedor, nmFactura).then((response) => {
      this.setState({
        ...this.state,
        loading: false
      })
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Anexo comision factura ${nmFactura}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  }

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }

  disbursedBills = () => {
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    if (this.state.disbursedBillsFilters.feDesde && this.state.disbursedBillsFilters.feHasta) {
      this.setState({
        ...this.state,
        loading: true
      })
      var { disbursedBillsFilters } = this.state
      const regex = /[^0-9]/g;
      var feDesde = disbursedBillsFilters.feDesde.replace(regex, '');
      var feHasta = disbursedBillsFilters.feHasta.replace(regex, '');
      reporteFacturasFinalizadas(token, scProveedor, feDesde, feHasta, disbursedBillsFilters).then(response => {
        if (response.data) {
          response.data.forEach(bill => {
            bill.key = bill.cdFactura
          })
          this.setState({
            ...this.state,
            activeFilter: true,
            activeFilters: {
              disbursedBills: true,
            },
            disbursedBills: response.data,
            pagination: {
              ...this.state.pagination,
              total: response.metadata.count
            },
            loading: false,
            sumatorias: response.metadata.sumatorias
          })
        }
        this.setState({
          ...this.state,
          loading: false
        })
        window.history.pushState(null, null, window.location.pathname);
      })
    } else {
      notification.error({
        message: 'Las fechas son obligatorias',
        description: REPORTS_DATES_REQUIRED_ERROR,
        duration: 4
      });
    }

  }

  disbursedBillsExcel = () => {
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    if (this.state.disbursedBillsFilters.feDesde && this.state.disbursedBillsFilters.feHasta) {
      this.setState({
        ...this.state,
        loading: true
      })
      var { disbursedBillsFilters } = this.state
      const regex = /[^0-9]/g;
      var feDesde = disbursedBillsFilters.feDesde.replace(regex, '');
      var feHasta = disbursedBillsFilters.feHasta.replace(regex, '');
      reporteFacturasFinalizadasExcel(token, scProveedor, feDesde, feHasta, disbursedBillsFilters).then(response => {
        const url = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.ms-excel' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Facturas desembolsadas ${feDesde} + ${feHasta}.xlsx`);
        document.body.appendChild(link);
        link.click();
        this.setState({
          ...this.state,
          loading: false
        })
      })
    } else {
      notification.error({
        message: 'Las fechas son obligatorias',
        description: REPORTS_DATES_REQUIRED_ERROR,
        duration: 4
      });
    }
  }

  onTramit = () => {
    this.setState({
      ...this.state,
      loading: true
    })
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    const { onTramitBillsFilters } = this.state
    reporteFacturasTramite(token, scProveedor, onTramitBillsFilters).then(response => {
      if (response.data) {
        response.data.forEach(bill => {
          bill.key = bill.cdFactura
        })
        this.setState({
          ...this.state,
          activeFilter: true,
          activeFilters: {
            onTramitBills: true,
          },
          onTramitBills: response.data,
          pagination: {
            ...this.state.pagination,
            total: response.metadata.count
          },
          loading: false,
          sumatorias: response.metadata.sumatorias
        })
      }
      this.setState({
        ...this.state,
        loading: false
      })
      window.history.pushState(null, null, window.location.pathname);
    });
  }

  onTramitBillsExcel = () => {
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    this.setState({
      ...this.state,
      loading: true
    })
    const { onTramitBillsFilters } = this.state
    reporteFacturasEnTramiteExcel(token, scProveedor, onTramitBillsFilters).then(response => {
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Facturas en tramite.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.setState({
        ...this.state,
        loading: false
      })
    })
  }

  commissionSupport = () => {
    if (this.state.commissionSupportsFilters.feDesde && this.state.commissionSupportsFilters.feHasta) {
      this.setState({
        ...this.state,
        loading: true
      })
      const token = store.getState().auth.auth.access_token;
      const scProveedor = store.getState().billing.company;
      const { commissionSupportsFilters } = this.state
      soporteComisiones(token, scProveedor, commissionSupportsFilters).then((response) => {
        if (response.data) {
          response.data.forEach(bill => {
            bill.key = bill.scFacturaExp
          })
          this.setState({
            ...this.state,
            commissionSupports: response.data,
            activeFilter: true,
            activeFilters: {
              commissionSupports: true,
            },
            pagination: {
              ...this.state.pagination,
              total: response.metadata.count
            },
            loading: false,
            sumatorias: response.metadata.sumatorias
          })
        }
        this.setState({
          ...this.state,
          loading: false
        })
        window.history.pushState(null, null, window.location.pathname);
      })
    } else {
      notification.error({
        message: 'Las fechas son obligatorias',
        description: REPORTS_DATES_REQUIRED_ERROR,
        duration: 4
      });
    }
  }

  commissionSupportExcel = () => {
    this.setState({
      ...this.state,
      loading: true
    })
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    const { commissionSupportsFilters } = this.state
    soporteComisionesExcel(token, scProveedor, commissionSupportsFilters).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Soporte de comisiones.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.setState({
        ...this.state,
        loading: false
      })
      this.setState({
        ...this.state,
        loading: false
      })
    })
  }

  finishedTransactionsSupports = () => {
    if (this.state.finishedTransactionsSupportsFilters.feDesde && this.state.finishedTransactionsSupportsFilters.feHasta) {
      this.setState({
        ...this.state,
        loading: true
      })
      const token = store.getState().auth.auth.access_token;
      const scProveedor = store.getState().billing.company;
      const { finishedTransactionsSupportsFilters } = this.state
      soporteTransaccionesFinalizadas(token, scProveedor, finishedTransactionsSupportsFilters).then((response) => {
        if (response.data) {
          response.data.forEach(bill => {
            bill.key = bill.scTracking
          })
          this.setState({
            ...this.state,
            finishedTransactionsSupports: response.data,
            activeFilter: true,
            activeFilters: {
              finishedTransactionsSupports: true,
            },
            pagination: {
              ...this.state.pagination,
              total: response.metadata.count
            },
            loading: false,
            sumatorias: response.metadata.sumatorias
          })
        }
        this.setState({
          ...this.state,
          loading: false
        })
        window.history.pushState(null, null, window.location.pathname);
      })
    } else {
      notification.error({
        message: 'Las fechas son obligatorias',
        description: REPORTS_DATES_REQUIRED_ERROR,
        duration: 4
      });
    }
  }

  finishedTransactionsSupportsExcel = () => {
    this.setState({
      ...this.state,
      loading: true
    })
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    const { finishedTransactionsSupportsFilters } = this.state
    reporteSoporteTransaccionesFinalizadasExcel(token, scProveedor, finishedTransactionsSupportsFilters).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Soporte de transacciones finalizadas.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.setState({
        ...this.state,
        loading: false
      })
      this.setState({
        ...this.state,
        loading: false
      })
    })
  }

  handleDisbursedBillsDateChange = (date, string) => {
    this.setState({
      ...this.state,
      disbursedBillsFilters: {
        ...this.state.disbursedBillsFilters,
        feDesde: string[0],
        feHasta: string[1]
      }
    })
    return;
  }

  handleFiltersChange = (value, fieldName, key, execute = false) => {
    if (typeof value === 'object') {
      value = value.target.value
    }
    this.state[key][fieldName] = value
    this.setState({
      ...this.state
    })

    if (fieldName === 'group') {
      if (key === 'disbursedBillsFilters' || key === 'onTramitBillsFilters') {
        this.filterPayer(value, key);
      }
    }

    if (execute) {
      switch (key) {
        case 'disbursedBillsFilters':
          this.disbursedBills()
          break;
        case 'onTramitBillsFilters':
          this.onTramit()
          break;
        case 'commissionSupportsFilters':
          this.commissionSupport()
          break;
        case 'finishedTransactionsSupportsFilters':
          this.finishedTransactionsSupports()
          break;
      }
    }
  }

  handleCommissionSupportsDateChange = (date, string, fieldName, execute = false) => {
    this.state.commissionSupportsFilters[fieldName] = string
    this.state.commissionSupportsFilters[fieldName + 'Moment'] = date
    this.setState({
      ...this.state
    })
    if (execute) {
      this.commissionSupport()
    }
  }

  handleFinishedTransactionsSupportsDateChange = (date, string, fieldname = '', execute = false) => {
    if (fieldname !== '') {
      this.state.finishedTransactionsSupportsFilters[fieldname] = string
      this.state.finishedTransactionsSupportsFilters[fieldname + 'Moment'] = date
    } else {
      if (date) {
        this.state.finishedTransactionsSupportsFilters.feDesde = string[0]
        this.state.finishedTransactionsSupportsFilters.feHasta = string[1]
        this.state.finishedTransactionsSupportsFilters.feDesdeMoment = date[0]
        this.state.finishedTransactionsSupportsFilters.feHastaMoment = date[1]
      } else {
        this.state.finishedTransactionsSupportsFilters.feDesde = ''
        this.state.finishedTransactionsSupportsFilters.feHasta = ''
        this.state.finishedTransactionsSupportsFilters.feDesdeMoment = ''
        this.state.finishedTransactionsSupportsFilters.feHastaMoment = ''
      }
      this.setState({
        ...this.state
      })
    }

    if (execute) {
      this.finishedTransactionsSupports()
    }
  }

  prevStep = () => {
    const filters = Object.getOwnPropertyNames(this.state.activeFilters)
    filters.forEach(filter => {
      this.state.activeFilters[filter] = false
    })
    this.setState({
      ...this.state,
      activeFilter: false,
      pagination: {
        ...this.state.pagination,
        currentPage: 1,
      },
      disbursedBillsFilters: {
        ...this.state.disbursedBillsFilters,
        offset: 0
      },
      onTramitBillsFilters: {
        ...this.state.onTramitBillsFilters,
        offset: 0
      }
    })
  }

  handleChange = (pagination, filters, sorter, extra, key) => {

    if (this.state.pagination.currentPage != pagination.current) {
      this.state[key].offset = pagination.pageSize * (pagination.current - 1)
      this.state.pagination.currentPage = pagination.current
    }

    if ((this.state[key].limit || 10) != pagination.pageSize) {
      this.state[key].limit = pagination.pageSize
      this.state[key].offset = 0
      this.state.pagination.currentPage = 1
    }

    if (sorter.field) {
      let { field, order } = sorter
      this.state[key].sort = order == 'descend' ? `-${field}` : field
    }
    if (key === 'disbursedBillsFilters') {
      this.disbursedBills()
    } else if (key === 'onTramitBillsFilters') {
      this.onTramit()
    } else if (key === 'commissionSupportsFilters') {
      this.commissionSupport()
    } else if (key === 'finishedTransactionsSupportsFilters') {
      this.finishedTransactionsSupports()
    }

  }

  filterPayer = (scGrupo, filter, update) => {
    var stateToChange = ''
    this.state[filter].payer = ''
    switch (filter) {
      case 'disbursedBillsFilters':
        stateToChange = 'payers'
        break;
      case 'onTramitBillsFilters':
        stateToChange = 'payersOnTramit'
        break;
    }
    const token = store.getState().auth.auth.access_token;
    const scProveedor = store.getState().billing.company;
    listarPagadores(token, scProveedor).then((response) => {
      this.state[stateToChange] = response.data;
      if (scGrupo) {
        this.state[stateToChange] = response.data.filter(payer => (
          payer.scGrupo === scGrupo
        ));
      }
      this.setState({
        ...this.state,
      })
    })
  }

  render() {
    return (
      <div className="content-margin content-margin-2">
        <div>
          <Spin tip="Cargando..." spinning={this.state.loading}>
            <Card className="card-shadow card-radius default-border welcome-message ">
              < Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="title">
                    {REPORTS_TITLE_INIT}
                  </div>
                </Col>
              </Row>
              < Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="subtitle">
                    {REPORTS_SUBTITLE_INIT}
                  </div>
                </Col>
              </Row>
            </Card>
            <Card className="card-container card-radius radius" hidden={this.state.activeFilter}>
              <Row>
                <Col span={23}>
                  <h1 className="card-title">Facturas desembolsadas</h1>
                </Col>
                <Col span={1}>
                  <Popover placement="topRight" content={REPORTS_DISBURSED_INIT}>
                    <QuestionCircleFilled className="dash-pop-icon-size" />
                  </Popover>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6} className="filters-box" >
                  <label>Filtrar Fechas</label>
                  <RangePicker size='small' onChange={this.handleDisbursedBillsDateChange} placeholder={['Desde', 'Hasta']}>
                  </RangePicker>
                </Col>
                <Col span={6} className="filters-box">
                  <label>Grupo empresarial</label>
                  <Select size='small' value={this.state.disbursedBillsFilters.group} onChange={(value) => this.handleFiltersChange(value, 'group', 'disbursedBillsFilters')}>
                    <Option value="">TODOS</Option>
                    {this.state.groups.map((group) => (
                      <Option title={group.dsNombres} value={group.scGrupo} key={group.scGrupo}>{group.dsNombres}</Option>
                    ))}
                  </Select>
                </Col>
                <Col span={6} className="filters-box">
                  <label>Pagador</label>
                  <Select size='small' value={this.state.disbursedBillsFilters.payer} onChange={(value) => this.handleFiltersChange(value, 'payer', 'disbursedBillsFilters')}>
                    <Option value="">TODOS</Option>
                    {this.state.payers.map((payer) => (
                      <Option title={payer.nombre} value={payer.scPersona} key={payer.scPersona}>{payer.nombre}</Option>
                    ))}
                  </Select>
                </Col>
                <Col span={6} className="filters-box">
                  <label>Fondeador</label>
                  <Select size='small' value={this.state.disbursedBillsFilters.investor} onChange={(value) => this.handleFiltersChange(value, 'investor', 'disbursedBillsFilters')}>
                    <Option value="">TODOS</Option>
                    {this.state.investors.map((investor) => (
                      <Option title={investor.nombre} value={investor.scPersona} key={investor.scPersona}>{investor.nombre}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6} className="filters-box">
                  <label>Cuenta Bancaria</label>
                  <Select size='small' value={this.state.disbursedBillsFilters.account} onChange={(value) => this.handleFiltersChange(value, 'account', 'disbursedBillsFilters')}>
                    <Option value=''>TODOS</Option>
                    {this.state.accounts.map((account) => (
                      <Option title={account.nombreBanco + ' ' + account.nroCuenta + ' - ' + account.tipoCuenta} value={account.scCuenta} key={account.scCuenta}>{account.nombreBanco + ' ' + account.nroCuenta + ' - ' + account.tipoCuenta}</Option>
                    ))}
                  </Select>
                </Col>
                <Col span={6} className="filters-box" >
                  <label># Factura</label>
                  <Input size='small' allowClear onChange={(value) => this.handleFiltersChange(value, 'billNumber', 'disbursedBillsFilters')} />
                </Col>
                <Col span={6} offset={6} className="filters-box query-buttons">
                  <Button size={'small'} type="primary" className="btn-radius" onClick={this.disbursedBills}>Consultar</Button>
                </Col>
              </Row>
            </Card>
            <Row className="multi-cards-row top-margin" gutter={16} hidden={this.state.activeFilter}>
              <Col span={8} >
                <Card className="card-radius radius">
                  <Row>
                    <Col span={23}>
                      <h1 className="card-title">Facturas en trámite</h1>
                    </Col>
                    <Col span={1}>
                      <Popover content={REPORTS_ON_TRAMIT_INIT}>
                        <QuestionCircleFilled className="dash-pop-icon-size" />
                      </Popover>
                    </Col>
                  </Row>
                  <Row className="filters-box">
                    <label>Grupo Empresarial</label>
                    <Select size='small' value={this.state.onTramitBillsFilters.group} onChange={(value) => this.handleFiltersChange(value, 'group', 'onTramitBillsFilters')}>
                      <Option value="">TODOS</Option>
                      {this.state.groups.map((group) => (
                        <Option title={group.dsNombres} value={group.scGrupo} key={group.scGrupo}>{group.dsNombres}</Option>
                      ))}
                    </Select>
                  </Row>
                  <Row className="filters-box top-margin-1">
                    <label>Pagador</label>
                    <Select size='small' value={this.state.onTramitBillsFilters.payer} onChange={(value) => this.handleFiltersChange(value, 'payer', 'onTramitBillsFilters')}>
                      <Option value="">TODOS</Option>
                      {this.state.payersOnTramit.map((payer) => (
                        <Option title={payer.nombre} key={payer.scPersona} value={payer.scPersona}>{payer.nombre}</Option>
                      ))}
                    </Select>
                  </Row>
                  <Row className="filters-box top-margin-1">
                    <label>Fondeador</label>
                    <Select size='small' value={this.state.onTramitBillsFilters.investor} onChange={(value) => this.handleFiltersChange(value, 'investor', 'onTramitBillsFilters')}>
                      <Option value="">TODOS</Option>
                      {this.state.investors.map((investor) => (
                        <Option title={investor.nombre} key={investor.scPersona} value={investor.scPersona}>{investor.nombre}</Option>
                      ))}
                    </Select>
                  </Row>
                  <Row className="query-buttons top-margin-1">
                    <Button size='small' type="primary" className="btn-radius" onClick={this.onTramit}>Consultar</Button>
                  </Row>
                </Card>
              </Col>
              <Col span={8}>
                <Card className="card-radius radius">
                  <Row>
                    <Col span={23}>
                      <h1 className="card-title">Soporte de comisiones</h1>
                    </Col>
                    <Col span={1}>
                      <Popover content={REPORTS_COMISION_INIT}>
                        <QuestionCircleFilled className="dash-pop-icon-size" />
                      </Popover>
                    </Col>
                  </Row>
                  <Row gutter={16} className="filters-box top-margin-1">
                    <label>Fecha Inicial</label>
                    <DatePicker size='small' value={this.state.commissionSupportsFilters.feDesdeMoment} onChange={(date, string) => this.handleCommissionSupportsDateChange(date, string, 'feDesde')}></DatePicker>
                  </Row>
                  <Row gutter={16} className="filters-box top-margin-1">
                    <label>Fecha Final</label>
                    <DatePicker size='small' value={this.state.commissionSupportsFilters.feHastaMoment} onChange={(date, string) => this.handleCommissionSupportsDateChange(date, string, 'feHasta')}></DatePicker>
                  </Row>
                  <Row className="query-buttons top-margin-1">
                    <Button size='small' type="primary" className="btn-radius" onClick={this.commissionSupport}>Consultar</Button>
                  </Row>
                </Card>
              </Col>
              <Col span={8}>
                <Card className="card-radius radius">
                  <Row>
                    <Col span={23}>
                      <h1 className="card-title">Soporte de transacciones finalizadas</h1>
                    </Col>
                    <Col span={1}>
                      <Popover placement="topRight" content={REPORTS_FINISHED_INIT}>
                        <QuestionCircleFilled className="dash-pop-icon-size" />
                      </Popover>
                    </Col>
                  </Row>
                  <Row gutter={16} className="filters-box">
                    <label>Rango de Fechas</label>
                    <RangePicker size='small' value={[this.state.finishedTransactionsSupportsFilters.feDesdeMoment, this.state.finishedTransactionsSupportsFilters.feHastaMoment]} onChange={this.handleFinishedTransactionsSupportsDateChange} placeholder={['Desde', 'Hasta']}></RangePicker>
                  </Row>
                  <Row gutter={16} className="filters-box">
                    <label># Transacción</label>
                    <Input size='small' value={this.state.finishedTransactionsSupportsFilters.transactionId} onChange={(value) => this.handleFiltersChange(value, 'transactionId', 'finishedTransactionsSupportsFilters')} allowClear />
                  </Row>
                  <Row gutter={16} className="query-buttons top-margin-1">
                    <Button size='small' type="primary" className="btn-radius" onClick={this.finishedTransactionsSupports}>Consultar</Button>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="multi-cards-row top-margin" gutter={16} hidden={this.state.activeFilter}>
              <Col span={12} >
                <Card className="card-radius radius">
                  <Row>
                    <h1 className="card-title">Tu flujo de caja esperado</h1>
                  </Row>
                  <LineChart
                    data={this.state.espectedFlow.data}
                    min={this.state.espectedFlow.min}
                    max={this.state.espectedFlow.max}
                    title={'Valores por mes'}
                    color="#09D96D"
                    fill='false'
                  />
                  <Row>
                    <p>{DASHBOARD_GRAPH_INIT}</p>
                  </Row>
                </Card>
              </Col>
              <Col span={12} >
                <Card className="card-radius radius">
                  <Row>
                    <h1 className="card-title">Tus facturas negociadas este mes</h1>
                  </Row>

                  <DoughnutChart
                    data={this.state.billsByCompany}
                    title={'Title sample'}
                    colors={['#09D96D', '#3399FF', '#176D8E', '#828282', '#38D380', '#4F4F4F']}
                  />
                  <Row>
                    <h3 className="card-text">Esta es una gráfica del resumen de tus facturas negociadas en este mes</h3>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={16} className='top-margin' hidden={!this.state.activeFilters.disbursedBills}>
              <Col lg={{ span: 8 }}>
                <h1 className="card-title">Facturas desembolsadas</h1>
              </Col>
              <Col lg={{ offset: 14, span: 1 }} >
                <span><Button size='large' className='btn-excel' onClick={this.disbursedBillsExcel} title="Exportar a excel" icon={<FileExcelOutlined />}></Button></span>
              </Col>
              <Col span={24} className="report-description">
                <p>{REPORTS_DISBURSED_INIT}</p>
              </Col>
              <Col span={24}>
                <Card className="card-container card-radius radius report-card">
                  <Row gutter={16}>
                    <Col xl={{ span: 4 }} lg={{ span: 3 }} md={{ span: 6 }} className="select-label">Filtrar por grupo: </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 4 }} >
                      <div className="select-container">
                        <Select size='small' value={this.state.disbursedBillsFilters.group} onChange={(value) => this.handleFiltersChange(value, 'group', 'disbursedBillsFilters', true)}>
                          <Option value="">TODOS</Option>
                          {this.state.groups.map((group) => (
                            <Option title={group.dsNombres} value={group.scGrupo} key={group.scGrupo}>{group.dsNombres}</Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 3 }} md={{ span: 6 }} className="select-label">Filtrar por pagador: </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 4 }} >
                      <div className="select-container">
                        <Select size="small" value={this.state.disbursedBillsFilters.payer} onChange={(value) => this.handleFiltersChange(value, 'payer', 'disbursedBillsFilters', true)}>
                          <Option value=''>TODOS</Option>
                          {this.state.payers.map((payer) => (
                            <Option title={payer.nombre} key={payer.scPersona} value={payer.scPersona}>{payer.nombre}</Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 3 }} md={{ span: 6 }} className="select-label">Filtrar por fondeador: </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 4 }} >
                      <div className="select-container">
                        <Select size="small" value={this.state.disbursedBillsFilters.investor} onChange={(value) => this.handleFiltersChange(value, 'investor', 'disbursedBillsFilters', true)}>
                          <Option value=''>TODOS</Option>
                          {this.state.investors.map((investor) => (
                            <Option title={investor.nombre} key={investor.scPersona} value={investor.scPersona}>{investor.nombre}</Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: '1.2rem' }}>
                    <Col xl={{ span: 4 }} lg={{ span: 3 }} md={{ span: 6 }} className="select-label">Filtrar por cuenta: </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 8 }} >
                      <div className="select-container">
                        <Select size="small" value={this.state.disbursedBillsFilters.account} onChange={(value) => this.handleFiltersChange(value, 'account', 'disbursedBillsFilters', true)}>
                          <Option value=''>TODOS</Option>
                          {this.state.accounts.map((account) => (
                            <Option title={account.nombreBanco + ' ' + account.nroCuenta + ' - ' + account.tipoCuenta} key={account.scCuenta} value={account.scCuenta}>{account.nombreBanco + ' ' + account.nroCuenta + ' - ' + account.tipoCuenta}</Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>

                  </Row>
                  <Row gutter={16}>
                    <Table
                      pagination={{
                        defaultPageSize: 10,
                        showTotal: (total, range) => `${range[0]} -${range[1]} de ${total} registros`,
                        current: this.state.pagination.currentPage,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30'],
                        total: this.state.pagination.total
                      }}
                      columns={this.disbursedBillsColumns}
                      dataSource={this.state.disbursedBills}
                      footer={() => <Total sumatorias={this.state.sumatorias} />}
                      onChange={(pagination, filters, sorter, extra) => this.handleChange(pagination, filters, sorter, extra, 'disbursedBillsFilters')}>
                    </Table>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={16} className='top-margin' hidden={!this.state.activeFilters.onTramitBills}>
              <Col lg={{ span: 8 }}>
                <h1 className="card-title">Facturas en trámite</h1>
              </Col>
              <Col lg={{ offset: 14, span: 1 }} >
                <span><Button size='large' className='btn-excel' onClick={this.onTramitBillsExcel} title="Exportar a excel" icon={<FileExcelOutlined />}></Button></span>
              </Col>
              <Col span={24} className="report-description">
                <p>{REPORTS_ON_TRAMIT_INIT}</p>
              </Col>
              <Col span={24}>
                <Card className="card-container card-radius radius report-card">
                  <Row gutter={16}>
                    <Col xl={{ span: 4 }} lg={{ span: 3 }} md={{ span: 6 }} className="select-label">Filtrar por grupo: </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 4 }}>
                      <div className="select-container">
                        <Select size="small" value={this.state.onTramitBillsFilters.group} onChange={(value) => this.handleFiltersChange(value, 'group', 'onTramitBillsFilters', true)}>
                          <Option value=''>TODOS</Option>
                          {this.state.groups.map((group) => (
                            <Option title={group.dsNombres} key={group.scGrupo} value={group.scGrupo}>{group.dsNombres}</Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 3 }} md={{ span: 6 }} className="select-label">Filtrar por pagador: </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 4 }}>
                      <div className="select-container">
                        <Select size="small" value={this.state.onTramitBillsFilters.payer} onChange={(value) => this.handleFiltersChange(value, 'payer', 'onTramitBillsFilters', true)}>
                          <Option value=''>TODOS</Option>
                          {this.state.payersOnTramit.map((payer) => (
                            <Option title={payer.nombre} key={payer.scPersona} value={payer.scPersona}>{payer.nombre}</Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 3 }} md={{ span: 6 }} className="select-label">Filtrar por fondeador: </Col>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 4 }}>
                      <div className="select-container">
                        <Select size="small" value={this.state.onTramitBillsFilters.investor} onChange={(value) => this.handleFiltersChange(value, 'investor', 'onTramitBillsFilters', true)}>
                          <Option value=''>TODOS</Option>
                          {this.state.investors.map((investor) => (
                            <Option title={investor.nombre} key={investor.scPersona} value={investor.scPersona}>{investor.nombre}</Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Table
                      pagination={{
                        defaultPageSize: 10,
                        showTotal: (total, range) => `${range[0]} -${range[1]} de ${total} items`,
                        current: this.state.pagination.currentPage,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30'],
                        total: this.state.pagination.total
                      }}
                      columns={this.onTramitBillsColumns}
                      dataSource={this.state.onTramitBills}
                      footer={() => <Total sumatorias={this.state.sumatorias} />}
                      onChange={(pagination, filters, sorter, extra) => this.handleChange(pagination, filters, sorter, extra, 'onTramitBillsFilters')}>

                    </Table>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={16} className='top-margin' hidden={!this.state.activeFilters.commissionSupports}>
              <Col lg={{ span: 8 }}>
                <h1 className="card-title">Soporte de comisiones</h1>
              </Col>
              <Col lg={{ offset: 14, span: 1 }} >
                <span><Button size='large' className='btn-excel' onClick={this.commissionSupportExcel} title="Exportar a excel" icon={<FileExcelOutlined />}></Button></span>
              </Col>
              <Col span={24} className="report-description">
                <p>{REPORTS_COMISION_INIT}</p>
              </Col>
              <Col span={24}>
                <Card className="card-container card-radius radius report-card">
                  <Row gutter={16}>
                    <Col xl={{ span: 2 }} md={{ span: 5 }} className="select-label">Desde: </Col>
                    <Col span={4}>
                      <div className="select-container">
                        <DatePicker value={this.state.commissionSupportsFilters.feDesdeMoment} onChange={(date, string) => this.handleCommissionSupportsDateChange(date, string, 'feDesde', true)}></DatePicker>
                      </div>
                    </Col>
                    <Col xl={{ span: 2 }} md={{ span: 5 }} className="select-label">Hasta: </Col>
                    <Col span={4}>
                      <div className="select-container">
                        <DatePicker value={this.state.commissionSupportsFilters.feHastaMoment} onChange={(date, string) => this.handleCommissionSupportsDateChange(date, string, 'feHasta', true)}></DatePicker>
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Table
                      pagination={{
                        defaultPageSize: 10,
                        showTotal: (total, range) => `${range[0]} -${range[1]} de ${total} items`,
                        current: this.state.pagination.currentPage,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30'],
                        total: this.state.pagination.total
                      }}
                      columns={this.commissionSupportsColumns}
                      dataSource={this.state.commissionSupports}
                      footer={() => <Total sumatorias={this.state.sumatorias} />}
                      onChange={(pagination, filters, sorter, extra) => this.handleChange(pagination, filters, sorter, extra, 'commissionSupportsFilters')}>
                    </Table>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={16} className='top-margin' hidden={!this.state.activeFilters.finishedTransactionsSupports}>
              <Col lg={{ span: 12 }}>
                <h1 className="card-title">Soporte de transacciones finalizadas</h1>
              </Col>
              <Col lg={{ offset: 8, span: 1 }} >
                <span><Button size='large' className='btn-excel' onClick={this.finishedTransactionsSupportsExcel} title="Exportar a excel" icon={<FileExcelOutlined />}></Button></span>
              </Col>
              <Col span={24} className="report-description">
                <p>{REPORTS_FINISHED_INIT}</p>
              </Col>
              <Col span={24}>
                <Card className="card-container card-radius radius report-card">
                  <Row gutter={16}>
                    <Col xl={{ span: 2 }} md={{ span: 5 }} className="select-label">Desde: </Col>
                    <Col span={4}>
                      <div className="select-container">
                        <DatePicker value={this.state.finishedTransactionsSupportsFilters.feDesdeMoment} onChange={(date, string) => this.handleFinishedTransactionsSupportsDateChange(date, string, 'feDesde', true)}></DatePicker>
                      </div>
                    </Col>
                    <Col xl={{ span: 2 }} md={{ span: 5 }} className="select-label">Hasta: </Col>
                    <Col span={4}>
                      <div className="select-container">
                        <DatePicker value={this.state.finishedTransactionsSupportsFilters.feHastaMoment} onChange={(date, string) => this.handleFinishedTransactionsSupportsDateChange(date, string, 'feHasta', true)}></DatePicker>
                      </div>
                    </Col>
                    <Col xl={{ span: 3 }} md={{ span: 5 }} className="select-label"># Transacción: </Col>
                    <Col span={4}>
                      <div className="select-container">
                        <Input allowClear onChange={(value) => this.handleFiltersChange(value, 'transactionId', 'finishedTransactionsSupportsFilters', true)} />
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Table
                      pagination={{
                        defaultPageSize: 10,
                        showTotal: (total, range) => `${range[0]} -${range[1]} de ${total} items`,
                        current: this.state.pagination.currentPage,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30'],
                        total: this.state.pagination.total
                      }}
                      columns={this.finishedTransactionsSupportsColumns}
                      dataSource={this.state.finishedTransactionsSupports}
                      footer={() => <Total sumatorias={this.state.sumatorias} />}
                      onChange={(pagination, filters, sorter, extra) => this.handleChange(pagination, filters, sorter, extra, 'finishedTransactionsSupportsFilters')}>
                    </Table>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 16]} hidden={!this.state.activeFilter}>
              <Col span={24} className="action-btn-container">
                <Button size='small' className="btn-prev" onClick={this.prevStep}>Atrás</Button>
              </Col>
            </Row>
          </Spin>
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  billing: state.billing
});

export default connect(mapStateToProps, {})(ReportCenter);