import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listaProveedores = (authtoken, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/listar-proveedores?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const editarProveedor = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/grabar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const crearProveedor = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const listarProveedoresExcel = (authtoken, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/listar-proveedores/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaCuentasBancarias = (authtoken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scProveedor}/cuentas/listar-cuentas`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaBancos = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-bancos?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaMunicipios = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-municipios?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaPaises = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-paises?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaDepartamentos = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-departamentos?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaPropietarios = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/propietarios?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaGruposEmpresarialesProveedores = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/grupos-empresariales/asociados?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const buscarProveedor = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/buscar-proveedor?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const getProveedor = (authtoken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/${scProveedor}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const validarSarlaftProveedor = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/validar-sarlaft?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const editarCuentaProveedor = (
  authtoken,
  scProveedor,
  scCuenta,
  filtros
) => {
  const formData = new FormData();
  formData.append('cdBanco', filtros.cdBanco);
  formData.append('nroCuenta', filtros.nroCuenta);
  formData.append('cdTipoCuenta', filtros.cdTipoCuenta);
  formData.append('cdEstadoRegistro', filtros.cdEstadoRegistro);
  formData.append('cdMunicipio', filtros.cdMunicipio);
  if (filtros.file.length != 0){
    formData.append('file', filtros.file[0].originFileObj);
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scProveedor}/cuentas/${scCuenta}/grabar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export const crearCuentaProveedor = (authtoken, scProveedor, formValue) => {
  const formData = new FormData();
  formData.append('cdBanco', formValue.cdBanco);
  formData.append('nroCuenta', formValue.nroCuenta);
  formData.append('cdTipoCuenta', formValue.cdTipoCuenta);
  formData.append('cdEstadoRegistro', formValue.cdEstadoRegistro);
  formData.append('cdMunicipio', formValue.cdMunicipio);
  formData.append('file', formValue.file[0].originFileObj);

  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scProveedor}/cuentas/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export const descargarCuentaProveedor = (authtoken, scCompania, scCuenta) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scCompania}/cuentas/${scCuenta}/descargar-soporte`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const informacionTributaria = (authtoken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scProveedor}/informacion-tributaria`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const actualizarInformacionTributaria = (
  authtoken,
  scProveedor,
  files
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scProveedor}/informacion-tributaria/grabar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: files,
    })
  );
};

export const listaConveniosProveedor = (authtoken, scProveedor, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/proveedores/${scProveedor}/convenios?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD');
      }
    }
  });
  return filtros;
};
