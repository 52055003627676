export const setExcel = (response, fileName) => {
  downloadFile(response, `${fileName}.xlsx`, 'application/vnd.ms-excel');
};

export const setExportPDF = (response, fileName) => {
  if (!response.message) {
    downloadFile(response, `${fileName}.pdf`, 'application/pdf');
  }
};
export const setExportZip = (response, fileName) => {
  if (!response.message) {
    downloadFile(response, `${fileName}.zip`, 'application/zip');
  }
};

export const setExport = (response, fileName) => {
  downloadFile(response, `Documento ${fileName}.${getType(response.type)}`);
};

export const setDownload = (response, fileName) => {
  downloadFile(response, `${fileName}`);
};

export const downloadFile = (response, fileName, contentType) => {
  const url = window.URL.createObjectURL(
    contentType ? new Blob([response], { type: contentType }) : response
  );

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const getType = (type) => {
  let ext = '';
  switch (type) {
    case 'application/json; charset=UTF-8':
      ext = 'json';
      break;
    case 'application/json':
      ext = 'json';
      break;
    case 'text/plain; charset=UTF-8':
      ext = 'txt';
      break;
    case 'text/plain':
      ext = 'txt';
      break;
    case 'application/zip':
      ext = 'zip';
      break;
    case 'application/x-rar-compressed':
      ext = 'rar';
      break;
    case 'application/x-7z-compressed':
      ext = '7zip';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      ext = 'xlxs';
      break;
    case 'application/vnd.ms-excel':
      ext = 'xls';
      break;
    case 'application/pdf':
      ext = 'pdf';
      break;
    case 'text/xml':
      ext = 'xml';
      break;
    case 'image/gif':
      ext = 'gif';
      break;
    case 'image/jpeg':
      ext = 'jpeg';
      break;
    case 'image/png':
      ext = 'png';
      break;
    case 'image/x-icon':
      ext = 'icon';
      break;
    case 'image/svg+xml':
      ext = 'svg';
      break;
    case 'image/tiff':
      ext = 'tiff';
      break;
    default:
      break;
  }

  return ext;
};
