import { Button, Modal, Spin, notification } from 'antd';
import React, { Component } from 'react';
import { crearConexion } from '../../../../../services/admin/erpConectionService';
import { listarPagadores } from '../../../../../services/admin/history/agreements';
import {
  formatValueMessage,
  maxValueMessage,
  rangeValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import { range } from '../../../../../utilities/validate/numberValidate';
import ErpConectionForm from '../erpConectionForm/erpConectionForm';

const initialState = {
  visible: false,
  loading: false,
  companiaSelect: {
    fetching: false,
    options: [],
  },
};

const initialFormValue = {
  scCompania: '',
  cdTipoConexionErp: '',
  nombreComponente: '',
  patternDate: 'yyyy-MM-dd',
  cdMoneda: '',
  patternNumber: '',
  separadorMiles: '',
  separadorDecimales: '',
  cdEstadoRegistro: 'A',
  nombreCompania: '',
  directa: {
    sapRouter: '',
    asHost: '',
    poolCapacity: '',
    peakLimit: '',
    client: '',
    sysNr: '',
    user: '',
    passwd: '',
    lang: '',
    cdSociedad: '',
  },
  webService: {
    url: '',
    userPortal: '',
    passwdPortal: '',
    asHost: '',
    client: '',
    user: '',
    passwd: '',
    sysNr: '',
    lang: '',
    cdSociedad: '',
  },
  apiRest: {
    url: '',
    userPortal: '',
    passwdPortal: '',
  },
  ftp: {
    asHost: '',
    carpEntrada: '',
    carpSalida: '',
    user: '',
    passwd: '',
  },
  jdbc: {
    driver: '',
    urlConexion: '',
    user: '',
    passwd: '',
  },
};

const rules = {
  cdTipoConexionErp: [
    {
      required: true,
      message: requiredValueMessage(),
    },
  ],
  scCompania: [
    {
      required: true,
      message: requiredValueMessage(),
    },
  ],
  nombreComponente: [
    {
      required: true,
      message: requiredValueMessage(),
    },
    { max: 50, message: maxValueMessage(50) },
  ],
  patternDate: [
    { required: true, message: requiredValueMessage() },
    { max: 30, message: maxValueMessage(30) },
  ],
  cdMoneda: [{ max: 3, message: maxValueMessage(3) }],
  patternNumber: [{ max: 20, message: maxValueMessage(20) }],
  separadorMiles: [{ max: 1, message: maxValueMessage(1) }],
  separadorDecimales: [{ max: 1, message: maxValueMessage(1) }],
  cdEstadoRegistro: [{ required: true, message: requiredValueMessage() }],

  JCO: {
    sapRouter: [
      { required: true, message: requiredValueMessage() },
      { max: 255, message: maxValueMessage(255) },
    ],
    asHost: [
      { required: true, message: requiredValueMessage() },
      { max: 15, message: maxValueMessage(15) },
    ],
    poolCapacity: [
      { required: true, message: requiredValueMessage() },
      {
        validator: async (_, value) => range(value, 0, 99),
        message: rangeValueMessage(0, 99),
      },
    ],
    peakLimit: [
      { required: true, message: requiredValueMessage() },
      {
        validator: async (_, value) => range(value, 0, 99),
        message: rangeValueMessage(0, 99),
      },
    ],
    client: [
      { required: true, message: requiredValueMessage() },
      { max: 10, message: maxValueMessage(10) },
      {
        pattern: /^[0-9a-zA-Z]*$/,
        message: formatValueMessage(),
      },
    ],
    sysNr: [
      { required: true, message: requiredValueMessage() },
      { max: 4, message: maxValueMessage(4) },
    ],
    user: [
      { required: true, message: requiredValueMessage() },
      { max: 40, message: maxValueMessage(40) },
    ],
    passwd: [
      { required: true, message: requiredValueMessage() },
      { max: 50, message: maxValueMessage(50) },
    ],
    lang: [
      { required: true, message: requiredValueMessage() },
      { max: 10, message: maxValueMessage(10) },
    ],
    cdSociedad: [
      { required: true, message: requiredValueMessage() },
      { max: 10, message: maxValueMessage(10) },
    ],
  },
  WS: {
    url: [
      { required: true, message: requiredValueMessage() },
      { max: 1000, message: maxValueMessage(1000) },
      {
        pattern: new RegExp(
          '^(https?|ftp|file)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]'
        ),
        message: formatValueMessage(),
      },
    ],
    userPortal: [
      { required: true, message: requiredValueMessage() },
      { max: 40, message: maxValueMessage(40) },
    ],
    passwdPortal: [
      { required: true, message: requiredValueMessage() },
      { max: 50, message: maxValueMessage(50) },
    ],
    asHost: [
      { required: true, message: requiredValueMessage() },
      { max: 15, message: maxValueMessage(15) },
    ],
    client: [
      { required: true, message: requiredValueMessage() },
      { max: 10, message: maxValueMessage(10) },
      {
        pattern: /^[0-9a-zA-Z]*$/,
        message: formatValueMessage(),
      },
    ],
    user: [
      { required: true, message: requiredValueMessage() },
      { max: 40, message: maxValueMessage(40) },
    ],
    passwd: [
      { required: true, message: requiredValueMessage() },
      { max: 50, message: maxValueMessage(50) },
    ],
    sysNr: [
      { required: true, message: requiredValueMessage() },
      { max: 4, message: maxValueMessage(4) },
    ],
    lang: [
      { required: true, message: requiredValueMessage() },
      { max: 10, message: maxValueMessage(10) },
    ],
    cdSociedad: [
      { required: true, message: requiredValueMessage() },
      { max: 10, message: maxValueMessage(10) },
    ],
  },
  REST: {
    url: [
      { required: true, message: requiredValueMessage() },
      { max: 1000, message: maxValueMessage(1000) },
      {
        pattern: new RegExp(
          '^(https?|ftp|file)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]'
        ),
        message: formatValueMessage(),
      },
    ],
    userPortal: [
      { required: true, message: requiredValueMessage() },
      { max: 40, message: maxValueMessage(40) },
    ],
    passwdPortal: [
      { required: true, message: requiredValueMessage() },
      { max: 50, message: maxValueMessage(50) },
    ],
  },
  JDBC: {
    driver: [
      { required: true, message: requiredValueMessage() },
      { max: 255, message: maxValueMessage(255) },
    ],
    urlConexion: [
      { required: true, message: requiredValueMessage() },
      { max: 255, message: maxValueMessage(255) },
    ],
    user: [
      { required: true, message: requiredValueMessage() },
      { max: 40, message: maxValueMessage(40) },
    ],
    passwd: [
      { required: true, message: requiredValueMessage() },
      { max: 50, message: maxValueMessage(50) },
    ],
  },
  FTP: {
    asHost: [
      { required: true, message: requiredValueMessage() },
      { max: 15, message: maxValueMessage(15) },
    ],
    carpEntrada: [
      { required: true, message: requiredValueMessage() },
      { max: 255, message: maxValueMessage(255) },
    ],
    carpSalida: [
      { required: true, message: requiredValueMessage() },
      { max: 255, message: maxValueMessage(255) },
    ],
    user: [
      { required: true, message: requiredValueMessage() },
      { max: 40, message: maxValueMessage(40) },
    ],
    passwd: [
      { required: true, message: requiredValueMessage() },
      { max: 50, message: maxValueMessage(50) },
    ],
  },
};

class ErpConectionNewModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnOpenModal = () => {
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  handleOnClose = () => {
    this.setState({
      ...this.state,
      visible: false,
    });
  };

  handleOnCompaniaSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { companiaSelect } = this.state;

      this.setState({
        ...this.state,
        companiaSelect: {
          ...companiaSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnFinish = async (formValue) => {
    const { onFinish } = this.props;
    this.setState({ ...this.state, loading: true });

    try {
      const response = await crearConexion(this.props.authToken, formValue);

      notification.success({
        message: 'Conexión ERP',
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        visible: false,
        loading: false,
      });

      onFinish({ ...formValue, scCompania: response.location });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  render() {
    const { visible, loading, companiaSelect } = this.state;
    return (
      <>
        <Button
          size='small'
          className='btn-agregar'
          onClick={this.handleOnOpenModal}
        >
          + Agregar nueva conexión
        </Button>
        <Modal
          title='Nueva conexión ERP'
          visible={visible}
          className='modal-style'
          footer={null}
          onCancel={this.handleOnClose}
        >
          <Spin tip='Cargando...' spinning={loading}>
            <ErpConectionForm
              data={initialFormValue}
              reset={visible}
              rules={rules}
              companiaSelect={companiaSelect}
              onCompaniaSearch={this.handleOnCompaniaSearch}
              onFinish={this.handleOnFinish}
            />
          </Spin>
        </Modal>
      </>
    );
  }
}

export default ErpConectionNewModal;
