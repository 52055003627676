import React, { Component } from 'react';
import moment from 'moment';
import {
  Upload,
  Table,
  Button,
  notification,
  Popover,
  Spin,
  DatePicker,
  Space,
  Row,
  Col,
} from 'antd';
import {
  InboxOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import bulkloadServices from '../../../../services/pagador/bulkloadServices';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';

const { Dragger } = Upload;

class Bills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      tableData: [],
      filename: '',
      confirmed: false,
      loading: false,
      fechaCarga: null,
    };
  }

  columnsFacturas = [
    {
      title: 'ESTADO',
      key: 'estado',
      align: 'center',
      render: (item) =>
        Array.isArray(item.errors) && item.errors.length > 0 ? (
          <Popover
            data-html='true'
            placement='topRight'
            content={
              Array.isArray(item.errors) && (
                <div>
                  {item.errors.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              )
            }
            title='Errores'
          >
            {this.state.confirmed ? (
              <CloseOutlined className='icon-size color-alert' />
            ) : (
              <InfoCircleOutlined className='icon-size color-error' />
            )}
          </Popover>
        ) : this.state.confirmed ? (
          <CheckOutlined className='icon-size color-success' />
        ) : (
          <CheckCircleTwoTone twoToneColor='#52c41a' className='icon-size' />
        ),
    },
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'proveedorId',
      sorter: (a, b) => sorterText(a.proveedorId, b.proveedorId),
      key: 'proveedorId',
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
      key: 'cdFactura',
    },

    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      render: (value) => <span>{currencyFormat(value)}</span>,
      key: 'vrFactura',
    },
    {
      title: 'FECHA EMISIÓN',
      dataIndex: 'feEmision',
      sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
      key: 'feEmision',
    },
    {
      title: 'FECHA VENCIMIENTO',
      dataIndex: 'feVencimiento',
      sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
      key: 'feVencimiento',
    },
    {
      title: 'FECHA ESTIMADA PAGO',
      dataIndex: 'feEstimadaPago',
      sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
      key: 'feEstimadaPago',
    },
  ];

  columnsPagos = [
    {
      title: 'ESTADO',
      key: 'estado',
      align: 'center',
      render: (item) =>
        Array.isArray(item.errors) && item.errors.length > 0 ? (
          <Popover
            data-html='true'
            placement='topRight'
            content={
              Array.isArray(item.errors) && (
                <div>
                  {item.errors.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              )
            }
            title='Errores'
          >
            {this.state.confirmed ? (
              <CloseOutlined className='icon-size color-alert' />
            ) : (
              <InfoCircleOutlined className='icon-size color-error' />
            )}
          </Popover>
        ) : this.state.confirmed ? (
          <CheckOutlined className='icon-size color-success' />
        ) : (
          <CheckCircleTwoTone twoToneColor='#52c41a' className='icon-size' />
        ),
    },
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'proveedorId',
      sorter: (a, b) => sorterText(a.proveedorId, b.proveedorId),
      key: 'proveedorId',
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
      key: 'cdFactura',
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      render: (value) => <span>$ {currencyFormat(value)}</span>,
      key: 'vrFactura',
    },
    {
      title: 'FECHA EMISIÓN',
      dataIndex: 'feEmision',
      sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
      key: 'feEmision',
    },
    {
      title: 'FECHA VENCIMIENTO',
      dataIndex: 'feVencimiento',
      sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
      key: 'feVencimiento',
    },
    {
      title: 'FECHA ESTIMADA PAGO',
      dataIndex: 'feEstimadaPago',
      sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
      key: 'feEstimadaPago',
    },

    {
      title: 'VALOR PAGO',
      dataIndex: 'vrPago',
      sorter: (a, b) => a.vrPago - b.vrPago,
      key: 'vrPago',
      render: (value) => <span>{currencyFormat(value)}</span>,
    },
  ];

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...this.state,
        fileList: [],
        tableData: [],
        filename: '',
      });
    }
  }

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file);
    });
    this.setState({
      ...this.state,
      loading: true,
    });

    if (this.props.company.company === '0') {
      notification.warning({
        message: 'Todas las compañias seleccionadas',
        description:
          'Por favor seleccione una compañía antes de cargar el archivo',
      });
      this.setState({
        ...this.state,
        loading: false,
      });
    } else if (fileList.length === 0) {
      notification.warning({
        message: 'Ningún archivo seleccionado',
        description: 'Por favor seleccione o arrastre un archivo',
      });
      this.setState({
        ...this.state,
        loading: false,
      });
    } else {
      bulkloadServices
        .upload(
          this.props.authToken,
          this.props.company.company,
          this.props.type,
          formData
        )
        .then((response) => {
          this.setState({
            ...this.state,
            tableData: response.data,
            filename: response.metadata ? response.metadata.nombreArchivo : '',
            confirmed: false,
            loading: false,
          });
        })
        .catch(() =>
          this.setState({
            ...this.state,
            loading: false,
          })
        );
    }
  };

  confirmUpload = () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    bulkloadServices
      .confirm(
        this.props.authToken,
        this.props.company.company,
        this.props.type,
        this.state.filename,
        this.state.fechaCarga
      )
      .then((response) => {
        if (response.metadata) {
          let numFacturasOk = 0;
          let vrFacturasOk = 0;
          let numFacturasFail = 0;
          let vrFacturasFail = 0;
          this.setState({
            ...this.state,
            confirmed: true,
            loading: false,
            fileList: [],
          });
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            if (element.errors) {
              numFacturasFail++;
              vrFacturasFail += element.vrFactura;
            } else {
              numFacturasOk++;
              vrFacturasOk += element.vrFactura;
            }
          }
          let vrTotalFacturasOk = currencyFormat(vrFacturasOk);
          let vrTotalFacturasFail = currencyFormat(vrFacturasFail);
          notification.success({
            message: 'Confirmado',
            description: `Archivo cargado correctamente, por favor revisar los resultados.
            Facturas cargadas: ${numFacturasOk} por un valor de  ${vrTotalFacturasOk}.
            Facturas fallidas: ${numFacturasFail} por un valor de  ${vrTotalFacturasFail}.`,
          });
        }
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  };

  downloadTemplate = () => {
    bulkloadServices
      .downloadTemplate(
        this.props.authToken,
        this.props.company.company,
        this.props.type
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.ms-excel;charset=ANSI',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Plantilla_${this.props.type}_masiva.xlt`
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  handleChange = (status) => {
    if (this.state.fileList.length > 1) {
      this.state.fileList.shift();
      this.setState({
        ...this.state,
        tableData: [],
      });
    }
  };

  onChangeFechaCarga = (date, dateString) => {
    this.state.fechaCarga = dateString.replace(/-/gi, '');
  };

  render() {
    return (
      <div className='content-container'>
        <div className='file-upload'>
          <p className='file-upload-message'>
            Archivo para inscribir {this.props.type} de forma masiva
          </p>
          <Dragger
            customRequest={() => {}}
            multiple={false}
            onChange={this.handleChange}
            onRemove={(file) => {
              const index = this.state.fileList.indexOf(file);
              const newFileList = this.state.fileList.slice();
              newFileList.splice(index, 1);
              this.setState({
                ...this.state,
                fileList: newFileList,
              });
            }}
            beforeUpload={(file) => {
              this.setState({
                ...this.state,
                fileList: [...this.state.fileList, file],
              });
            }}
            fileList={this.state.fileList}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Suelta el archivo aquí o selecciona alguno
            </p>
          </Dragger>
          <div className='template-download'>
            <Button className='btn btn-blue' onClick={this.handleUpload}>
              Cargar archivo
            </Button>
            <a href='#' onClick={this.downloadTemplate}>
              Descargar plantilla
            </a>
          </div>
        </div>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          {Array.isArray(this.state.tableData) &&
            this.state.tableData.length >= 1 && (
              <div>
                <div className='table-wrapper'>
                  <Table
                    columns={
                      this.props.type === 'facturas'
                        ? this.columnsFacturas
                        : this.columnsPagos
                    }
                    dataSource={this.state.tableData}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '30'],
                    }}
                  />
                </div>
                <div>
                  <Space direction='horizontal'>
                    {this.props.type === 'facturas' && (
                      <DatePicker
                        size={16}
                        style={{ marginTop: '16px', width: '160px' }}
                        onChange={this.onChangeFechaCarga}
                        placeholder='Fecha para oferta'
                      />
                    )}
                    <Button
                      className='btn btn-green'
                      onClick={this.confirmUpload}
                      disabled={this.state.fileList.length <= 0}
                    >
                      Confirmar archivo
                    </Button>
                  </Space>
                </div>
              </div>
            )}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};
export default connect(mapStateToProps)(Bills);
