import React from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Spin } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utilities/messages';
import { currencyFormat, dateFormat, sorterDate, sorterText } from '../../../../utilities/textTools';
import avatar from '../../../../assets/img/assisted-man.png';
import TableReport from '../../../../components/tableReport/tableReport';


const ModalDetailsCheckBilling = ({
  visible,
  hiddenModalDetalle,
  dataModal,
  currentPage,
  limit,
  total,
  resumen,
  handleChange
}) => {


  const columnas = [
    {
      title: 'PAGADOR',
      dataIndex: 'nombrePagador',
      render: (nombrePagador) => <span>{nombrePagador}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'CÓDIGO FACTURA',
      dataIndex: 'cdFactura',
      render: (cdFactura) => <span>{cdFactura}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR TRANSACCIONAL',
      dataIndex: 'vrCostoTransaccion',
      render: (vrCostoTransaccion) => <span>{currencyFormat(vrCostoTransaccion)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'FECHA COMPRA',
      dataIndex: 'feCompra',
      render: (feCompra) => dateFormat(feCompra),
      showSorterTooltip: false,
    },
  ];

  return (
    <div className='header-container'>   
    
     <Modal
            className='content-modal '
            visible={visible}
            title="DETALLE DE LA FACTURA"
            onCancel={hiddenModalDetalle}
            footer={null}
          >
      <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={columnas}
                    data={dataModal}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
    </Modal>
    </div>
  );
};
export default ModalDetailsCheckBilling;
