import React, { Component } from 'react';
import { Row, Col, Button, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import equal from 'fast-deep-equal';
import { v4 as uuidv4 } from 'uuid';
import PersonalReferenceModal, {
  initialValuesPersonaRef,
} from '../../../../../components/personalReferenceModal/personalReferenceModal';

const initialState = {
  referenciasPersonales: [],
  visibleModal: false,
  data: initialValuesPersonaRef,
};

class PersonalReferences extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NOMBRE Y APELLIDO',
        dataIndex: 'nombre',
      },
      {
        title: 'NRO. IDENTIFICACION',
        dataIndex: 'id',
      },
      {
        title: 'TELÉFONO',
        dataIndex: 'telefono',
      },
      {
        title: 'PARENTESCO',
        dataIndex: 'parentesco',
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <EditOutlined
            className='info'
            onClick={() => this.handleOnOpenModal(record)}
          />
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <DeleteOutlined
            className='info'
            onClick={() => this.deleteShareholder(record)}
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  deleteShareholder = (record) => {
    const { referenciasPersonales } = this.state;

    let temps = referenciasPersonales.filter(
      (value) => value.key !== record.key
    );

    this.setState({ ...this.state, referenciasPersonales: temps });
  };

  handleOnFinish = () => {
    const { onFinish } = this.props;
    const { referenciasPersonales } = this.state;

    if (referenciasPersonales.length === 0) {
      referenciasPersonales.warn({
        title: 'Alerta',
        message: 'Debe añadir las referencias personales',
        duration: 4,
      });
      return;
    }

    onFinish('6', { referenciasPersonales: referenciasPersonales });
  };

  handleOnOpenModal = (record) => {
    this.setState({ ...this.state, visibleModal: true, data: record });
  };

  handleOnFinishModal = (formValue) => {
    const { referenciasPersonales } = this.state;

    let temps = [];

    if (formValue.key && formValue.key !== '') {
      temps = referenciasPersonales.map((value) =>
        value.key === formValue.key ? formValue : value
      );
    } else {
      let uuid = uuidv4();
      temps = [{ ...formValue, key: uuid }, ...referenciasPersonales];
    }

    this.setState({
      ...this.state,
      referenciasPersonales: temps,
      visibleModal: false,
      data: initialValuesPersonaRef,
    });
  };

  handleOnCloseModal = () => {
    this.setState({
      ...this.state,
      visibleModal: false,
    });
  };

  componentDidMount() {
    if (this.props.person) {
      this.init();
    }
  }

  componentDidUpdate(props, state) {
    if (!equal(props.person, this.props.person)) {
      this.init();
    }
  }

  init = () => {
    console.log('init', this.props.person);
    const {
      person: { referenciasPersonales },
    } = this.props;

    this.setState({
      ...this.state,
      referenciasPersonales: referenciasPersonales
        ? referenciasPersonales.map((ref) => ({
            ...ref,
            key: ref.scReferencia,
          }))
        : [],
    });
  };

  render() {
    const { referenciasPersonales, visibleModal, data } = this.state;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='end'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6} style={{ textAlign: 'right' }}>
              <Button
                className='btn-radius'
                type='primary'
                onClick={() => this.handleOnOpenModal(initialValuesPersonaRef)}
              >
                Agregar referencia personal
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
            <Col flex='auto' style={{ overflow: 'auto' }}>
              <Table
                style={{ width: '100%' }}
                columns={this.columnas}
                dataSource={referenciasPersonales}
                pagination={false}
              ></Table>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='center'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6}>
              <Button
                className='form-btn btn-radius'
                type='primary'
                htmlType='submit'
                onClick={this.handleOnFinish}
              >
                Siguiente
              </Button>
            </Col>
          </Row>

          <PersonalReferenceModal
            visible={visibleModal}
            data={data}
            onFinish={this.handleOnFinishModal}
            onClose={this.handleOnCloseModal}
          />
        </div>
      </div>
    );
  }
}

export default PersonalReferences;
