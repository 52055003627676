import {
  FilePdfTwoTone,
  FileTextOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Divider, Row, Space, Spin, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { listarCompanias } from '../../../services/facturacion/ParametrosService';
import {insertarFacturaManual } from '../../../services/facturacion/facturacionService';
import InvoiceManualFiltreForm from './invoiceManualFiltreForm/invoiceManualFiltreForm';
import moment from 'moment';

const initialState = {
  form: {
    feEmision:moment(),
    feVencimiento:moment(),
    scCliente:undefined,
    tipoDetalleFactura:'MANUAL',
    concepto:undefined,
    vrFactura:undefined
  },
  loading: false,
  companiaSelect: {
    fetching: false,
    options: [],
  },
};

class InvoiceManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }
  componentDidMount() {
    this.props.menuHandler('4');
  }

  handleOnSearchCompania = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { companiaSelect } = this.state;

      this.setState({
        ...this.state,
        companiaSelect: {
          ...companiaSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarCompanias(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            fetching: false,
          },
        });
      }
    }
  };
  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      loading: false,
      form: {
        feEmision:value.feEmision,
        feVencimiento:value.feVencimiento,
        scCliente:value.scCliente,
        tipoDetalleFactura:this.state.form.tipoDetalleFactura,
        concepto:value.dsConcepto,
        vrFactura:value.vrFactura
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });
    this.getData();
  };
  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await insertarFacturaManual(
        this.props.authToken,
        this.state.form
      );
      notification.success({
        message: 'Factura Manual Creada Correctamente',
        description: response.message,
        duration: 4,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }finally{
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };


  render() {
    const {
      loading,
      companiaSelect,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='mandate-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <InvoiceManualFiltreForm
              handleSubmit={this.handleSubmit}
              companiaSelect={companiaSelect}
              handleOnSearchCompania={this.handleOnSearchCompania}
            />
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(InvoiceManual);
