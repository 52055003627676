import { Button, Col, Form, Modal, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { requiredValueMessage } from '../../../../../../utilities/admin_validation_messages';

const AsociarPagadorModal = ({
  visible,
  onClose,
  pagadoresSelect,
  onPagadorSearch,
  onFinish,
  loading,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  return (
    <Modal
      title='Lista de Compañías'
      visible={visible}
      className='modal-style'
      footer={null}
      onCancel={onClose}
    >
      <Spin tip='Cargando...' spinning={loading}>
        <div className='header-container'>
          <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
            initialValues={{ scCompania: '' }}
          >
            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item
                  name='scCompania'
                  label='COMPAÑÍAS'
                  rules={[{ required: true, message: requiredValueMessage() }]}
                >
                  <Select
                    className='module-selector-select'
                    placeholder='Seleccione'
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => onPagadorSearch(value)}
                    notFoundContent={
                      pagadoresSelect.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {pagadoresSelect.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify='center'>
              <Col span={8}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Grabar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default AsociarPagadorModal;
