import React, { Component } from 'react';
import { Button, Spin, Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { listaMunicipios } from '../../../../../../services/admin/suppliersService';
import {
  buscarUsuario,
  crearUsuario,
  listaTiposContactos,
} from '../../../../../../services/admin/payersService';
import UserForm, { initialFormValue } from '../UserForm/userForm';

const USERS_TITLE = 'Usuarios';

const initialState = {
  visible: false,
  loading: false,
  ciudadesSelect: {
    fetching: false,
    options: [],
  },
  tipoContactos: [],
  data: initialFormValue,
};

class UserNewModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnOpenModal = () => {
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  handleOnClose = () => {
    this.setState({
      ...this.state,
      visible: false,
    });
  };

  getData = async () => {
    const { tipoAsociado, authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const tipoContatoResponse = await listaTiposContactos(
        authToken,
        tipoAsociado
      );

      this.setState({
        ...this.state,
        tipoContactos: tipoContatoResponse.data,
        loading: false,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnCiudadesSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { ciudadesSelect } = this.state;

      this.setState({
        ...this.state,
        ciudadesSelect: {
          ...ciudadesSelect,
          fetching: true,
        },
      });

      try {
        const response = await listaMunicipios(this.props.authToken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: '57',
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesSelect: {
            ...ciudadesSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesSelect: {
            ...ciudadesSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnUserSearch = async (id) => {
    if (!id || id === '') {
      return;
    }

    const { authToken, scPersona, onEditModalChange } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await buscarUsuario(authToken, scPersona, { id });
      const data = response.data;
      if (data.companyUser) {
        Modal.confirm({
          title: USERS_TITLE,
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              El usuario o contacto ya se encuentra asociado con está compañía,
              <br /> ¿desea actualizar la información del contacto?
            </div>
          ),
          onOk: () => {
            this.setState({
              ...this.state,
              visible: false,
              loading: false,
            });
            onEditModalChange(data);
          },
          onCancel: () => {
            this.setState({
              ...this.state,
              loading: false,
            });
          },
        });
      } else {
        Modal.confirm({
          title: USERS_TITLE,
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              Ya existe un contacto con este mismo correo en las siguientes compañias: 
              {data.companiasAsociadas.join(",  ")}       
              <br /> ¿desea
              actualizar al información del usuario y posteriormente asociarlo a
              esta compañía?
            </div>
          ),
          onOk: () => {
            this.setState({
              ...this.state,
              loading: false,
              data,
              ciudadesSelect: {
                options: [
                  { codigo: data.cdMunicipio, descripcion: data.dsMunicipio },
                ],
              },
            });
          },
          onCancel: () => {
            this.setState({
              ...this.state,
              loading: false,
            });
          },
        });
      }
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnFinish = async (formValue) => {
    const { onFinish, scPersona, authToken } = this.props;
    this.setState({ ...this.state, loading: true });
    formValue.tipoAsociado = this.props.tipoAsociado;

    try {
      const response = await crearUsuario(authToken, scPersona, formValue);

      notification.success({
        message: USERS_TITLE,
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        visible: false,
        loading: false,
      });

      onFinish({ ...formValue, scCuenta: response.location });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(props, state) {
    if (state.visible !== this.state.visible) {
      this.setState({
        ...this.state,
        data: { ...initialFormValue },
      });
    }
  }

  render() {
    const { visible, loading, tipoContactos, ciudadesSelect, data } =
      this.state;

    return (
      <>
        <Button
          size='small'
          className='btn-agregar'
          onClick={this.handleOnOpenModal}
        >
          + Agregar nuevo usuario
        </Button>
        {visible && (
          <Modal
            title='Nuevo usuario'
            visible={visible}
            className='modal-style'
            footer={null}
            onCancel={this.handleOnClose}
          >
            <Spin tip='Cargando...' spinning={loading}>
              <UserForm
                data={data}
                reset={visible}
                tipoContactos={tipoContactos}
                ciudadesSelect={ciudadesSelect}
                onCiudadesSearch={this.handleOnCiudadesSearch}
                onFinish={this.handleOnFinish}
                buscarContacto={this.handleOnUserSearch}
              />
            </Spin>
          </Modal>
        )}
      </>
    );
  }
}

export default UserNewModal;
