import { Card, Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadedBills from './loadedBills/loadedBills';
import './reports.scss';
import ScheduledBills from './scheduledBills/scheduledBills';
import VoidedBills from './voidedBills/voidedBills';
import AutomaticDiscountBills from './automaticDiscountBills/automaticDiscountBills';


class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '0',
    };
  }

  componentDidMount() {
    this.props.menuHandler('61');
  }

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  render() {
    const { activeTab } = this.state;
    const { authToken } = this.props;

    return (
      <div className='reports-style-facturas-cargadas'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <Tabs activeKey={activeTab} onChange={this.changeTab}>
              <Tabs.TabPane tab='Facturas cargadas' key={0}>
                <LoadedBills authToken={authToken} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Facturas programadas' key={1}>
                <ScheduledBills authToken={authToken} />,
              </Tabs.TabPane>
              <Tabs.TabPane tab='Facturas Anuladas' key={2}>
                <VoidedBills authToken={authToken} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Facturas DA' key={3}>
                <AutomaticDiscountBills authToken={authToken} />,
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Reports);
