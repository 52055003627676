import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Card,
} from 'antd';
import { connect } from 'react-redux';
import Total from '../../../proveedor/reports/total';
import {
  listarGruposEmpresariales,
  listarPagadores,
  listarFondeadores,
  listarProveedores,
  listaCupos,
  exportarExcelCupos,
} from '../../../../services/admin/reports/quotasService';
import './quotas.scss';
import equals from 'fast-deep-equal';
import { FileExcelOutlined } from '@ant-design/icons';
import { sorterText } from '../../../../utilities/textTools';
import { listarTipoCupo, listarTipoProducto } from '../../../../services/admin/operation';

let intColumnas = 1;
const SELECT_PLACE_HOLDER = 'Seleccionar...';
const { Option } = Select;
const initialState = {
  loading: false,
  currentPage: 1,
  resultado: [],
  total: 0,
  sumatorias: {},
  filtros: {
    feHasta: '',
    scFondeador: '',
    scEntidad: '',
    tipoEntidad: '',
    tipoCupo:'',
    tipoProducto:'',
    offset: 0,
    sort: '',
  },
  entidadSelect: {
    fetching: false,
    options: [],
  },
  fondeadorOptions: [],
  tipoEntidadOptions: [],
  entidadOptions: [],
  tipoProductoOptions: [],
  tipoCupoOptions: [],
};

const entidades = [
  { key: '', value: 'Todos' },
  { key: 'PAG', value: 'Pagador' },
  { key: 'PRO', value: 'Proveedor' },
  { key: 'GRP', value: 'Grupo empresarial' },
];

class Quotas extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.formRef = React.createRef();
  }

  formulario = [
    [
      {
        nombre: 'fechaHasta',
        label: '* FECHA HASTA',
        tipo: 'date',
        required: true,
      },
    ],
    [
      {
        nombre: 'scFondeador',
        label: 'FONDEADOR',
        tipo: 'select',
        selectType: 'fondeadorOptions',
      },
    ],
    [
      {
        nombre: 'tipoEntidad',
        label: 'TIPO ENTIDAD',
        tipo: 'select',
        selectType: 'tipoEntidadOptions',
      },
      {
        nombre: 'scEntidad',
        label: 'ENTIDAD',
        tipo: 'select',
        selectType: 'entidadOptions',
      },
    ],
  ];

  columns = [
    {
      title: 'FONDEADOR',
      dataIndex: 'nombreFondeador',
      sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
      showSorterTooltip: false,
    },
    {
      title: 'TIPO ENTIDAD',
      dataIndex: 'tipoEntidad',
      sorter: (a, b) => sorterText(a.tipoEntidad, b.tipoEntidad),
      showSorterTooltip: false,
    },
    {
      title: 'NOMBRE ENTIDAD',
      dataIndex: 'nombreEntidad',
      sorter: (a, b) => sorterText(a.nombreEntidad, b.nombreEntidad),
      showSorterTooltip: false,
    },
    {
      title: 'VIGENCIA',
      dataIndex: 'feHasta',
      sorter: (a, b) => sorterText(a.vigencia, b.vigencia),
      showSorterTooltip: false,
    },
    {
      title: 'CUPO CONTRAPARTE ASIGNADO',
      dataIndex: 'nmCupoContraparte',
      sorter: (a, b) => a.nmCupoContraparte - b.nmCupoContraparte,
      render: (value) => <span>$ {this.formatNumber(value)} </span>,
      showSorterTooltip: false,
    },
    {
      title: 'CUPO CONTRAPARTE VIGENTE',
      dataIndex: 'nmCupoContraparteVig',
      sorter: (a, b) => a.nmCupoContraparteVig - b.nmCupoContraparteVig,
      render: (value) => <span>$ {this.formatNumber(value)} </span>,
      showSorterTooltip: false,
    },
    {
      title: 'TIPO CUPO',
      dataIndex: 'tipoCupo',
      render: (value) => (
        <span>{(value)}</span>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'TIPO PRODUCTO',
      dataIndex: 'tipoProducto',
      render: (value) => (
        <span>{(value)}</span>
      ),
      showSorterTooltip: false,
    },
  ];

  handleOnChangeTipoEntidad = async (e) => {
    const { entidadSelect } = this.state;

    let response = null;
    if (e === 'PAG') {
      response = await listarPagadores(this.props.authToken, {
        limit: 50,
      });
    } else if (e === 'PRO') {
      response = await listarProveedores(this.props.authToken, {
        limit: 50,
      });
    } else {
      response = await listarGruposEmpresariales(this.props.authToken, {
        limit: 50,
      });
    }
    this.setState({
      ...this.state,
      loading: false,
      scEntidad: '',
      filtros: {
        ...this.state.filtros,
        tipoEntidad: e,
      },
      tipoEntidad: e,
      entidadSelect: {
        ...entidadSelect,
        options: response.data,
        fetching: false,
      },
    });
  };

  handleOnSearchEntidad = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { entidadSelect, tipoEntidad } = this.state;

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          fetching: true,
        },
      });

      try {
        let response = null;
        if (tipoEntidad === 'PAG') {
          response = await listarPagadores(this.props.authToken, {
            nombre: nombre,
          });
        } else if (tipoEntidad === 'PRO') {
          response = await listarProveedores(this.props.authToken, {
            nombre: nombre,
          });
        } else {
          response = await listarGruposEmpresariales(this.props.authToken, {
            nombre: nombre,
          });
        }

        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnChangeEntidad = (e) => {
    this.setState({
      ...this.state,
      filtros: {
        ...this.state.filtros,
        scEntidad: e,
      },
    });
  };

  componentDidMount() {
    this.props.menuHandler('64');
    this.getInitTipoProducto();
    this.getInitTipoCupo();
  }

  componentDidUpdate(props, state) {
    if (!equals(this.state.filtros, state.filtros)) {
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue(this.state.filtros);
      }
    }
  }

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  getData = (value) => {
    this.setState({
      ...this.state,
      filtros: {
        ...this.state.filtros,
        feHasta: value.feHasta,
        scFondeador: value.scFondeador,
        scEntidad: value.scEntidad,
        tipoEntidad: value.tipoEntidad,
        tipoProducto:value.tipoProducto,
        tipoCupo:value.tipoCupo
      },
      resultado: [],
    });
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });
      try {
        listaCupos(this.props.authToken, this.state.filtros).then(
          (response) => {
            this.setResponse(response);
          }
        );
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  setResponse = (response) => {
    this.setState({
      ...this.state,
      resultado: response.data,
      total: response.metadata.count,
      loading: false,
      sumatorias: response.metadata.sumatorias,
    });
  };

  validateForm = () => {
    let filtros = this.state.filtros;
    let validationResult = true;
    let formulario = this.formulario;
    let nombres = [];
    let errores = [];
    Object.keys(filtros).forEach((nombre) => {
      if (
        formulario.find((x) =>
          x.find((y) => y.nombre === nombre && y.required === true)
        )
      ) {
        if (filtros[nombre] === '' || filtros[nombre] === null) {
          nombres.push(nombre);
          validationResult = false;
        }
      }
    });
    if (!validationResult) {
      formulario[this.props.type].forEach((formItem) => {
        formItem.forEach((key) => {
          if (nombres.includes(key.nombre)) {
            errores.push(key.label.replace('* ', ''));
          }
        });
      });
      if (errores.length > 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar los valores de los campos ${errores.reduce(
            (error, acum) => `${acum}, ${error}`
          )} para consultar este reporte`,
          duration: 4,
        });
      }
      if (errores.length === 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar el valor del campo ${errores[0]} para consultar este reporte`,
          duration: 4,
        });
      }
    }
    return validationResult;
  };

  formRender = () => {
    let formulario = '';
    const {tipoProductoOptions, tipoCupoOptions} = this.state;

    return (formulario = (
      <Form
        name='content_form'
        layout='vertical'
        ref={this.formRef}
        className='filters-container'
        initialValues={this.state.filtros}
        onFinish={this.getData}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='feHasta' label='FECHA HASTA' required={true}>
              <DatePicker
                name='feHasta'
                value={this.state.filtros.feHasta}
                onChange={(moment, string) =>
                  this.handleDateChange(moment, 'feHasta')
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={18}>
            <Form.Item name='scFondeador' label='FONDEADOR' required={false}>
              <Select
                showSearch
                allowClear
                value={this.state.filtros.scFondeador}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => this.handleSearchFondeador(value)}
                onChange={(value) =>
                  this.handleSelectChange(value, 'scFondeador')
                }
                notFoundContent={
                  this.state.fetching ? <Spin size='small' /> : null
                }
              >
                {this.state.fondeadorOptions.map((d) => (
                  <Option key={d.key}>{d.nombre}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={5}>
            <Form.Item name='tipoEntidad' label='Tipo Entidad' required={false}>
              <Select onChange={this.handleOnChangeTipoEntidad}>
                {entidades.map((d) => (
                  <Select.Option key={d.key} value={d.key}>
                    {d.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item name='scEntidad' label='Entidad' required={false}>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => this.handleOnSearchEntidad(value)}
                onChange={this.handleOnChangeEntidad}
                notFoundContent={
                  this.state.entidadSelect.fetching ? (
                    <Spin size='small' />
                  ) : null
                }
              >
                {this.state.entidadSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                name='tipoProducto' 
                label='Tipo de Producto'
                >
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
            >
              {tipoProductoOptions.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item 
                name='tipoCupo' 
                label='Tipo de Cupo'
                >
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              value={this.state.filtros.tipoCupo}
              allowClear
            >
              {tipoCupoOptions.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              </Row>
        <Row justify='left'>
          <Col>
            <Form.Item name='submit'>
              <Button
                className='btn btn-green'
                type='primary'
                htmlType='submit'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='submit'>
              <Button
                className='btn btn-blue'
                type='primary'
                onClick={() => {
                  this.limpiarCampos();
                }}
              >
                Limpiar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ));
  };

  limpiarCampos() {
    this.setState({
      ...initialState,
      filtros: {
        feHasta: '',
        scFondeador: '',
        scEntidad: '',
        tipoEntidad: 'PAG',
        offset: 0,
        tipoCupo:'',
        tipoProducto:''
      },
    });
  }

  handleSearchFondeador = (value) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      listarFondeadores(this.props.authToken, { nombre: value }).then(
        (response) => {
          if (Array.isArray(response.data)) {
            this.setState({
              ...this.state,
              fondeadorOptions: response.data.map((item) => {
                return {
                  key: item.codigo,
                  nombre: item.descripcion,
                };
              }),
              fetching: false,
            });
          }
        }
      );
    } else {
      this.setState({
        ...this.state,
        fondeadorOptions: [],
      });
    }
  };

  handleOnChange = (pagination, filters, sorter, extra) => {
    if (this.state.currentPage !== pagination.current) {
      this.setState({
        ...this.state,
        filtros: {
          ...this.state.filtros,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        currentPage: pagination.current,
      });
    }

    if ((this.state.filtros.limit || 10) !== pagination.pageSize) {
      this.setState({
        ...this.state,
        filtros: {
          ...this.state.filtros,
          limit: pagination.pageSize,
          offset: 0,
        },
        currentPage: 1,
      });
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.setState({
        ...this.state,
        filtros: {
          ...this.state.filtros,
          sort: order === 'descend' ? `+${field}` : `-${field}`,
        },
      });
    }
    this.getData();
  };

  handleDateChange = (moment, campo) => {
    this.state.filtros[campo] = moment;
  };

  handleSelectChange = (value, stateName) => {
    this.state.filtros[stateName] = value;
    this.setState({ ...this.state });
  };

  exportExcel = () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });
      exportarExcelCupos(this.props.authToken, this.state.filtros).then(
        (response) => {
          this.setExcel(response);
        }
      );
    }
  };

  setExcel = (response) => {
    const type = this.props.type;
    const url = window.URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Reporte Cupos.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.setState({
      ...this.state,
      loading: false,
    });
  };

  getInitTipoProducto = async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoProductoRes = await listarTipoProducto(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoProductoOptions: tipoProductoRes.data,
    });
  };

  
  getInitTipoCupo= async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoCupoRes = await listarTipoCupo(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoCupoOptions: tipoCupoRes.data,
    });
  };

  render() {
    return (
      <div className='reports'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={this.state.loading}>
                <div className='header-container'>
                  <p className='title'>Búsqueda</p>
                  {this.formRender()}
                </div>
                <div className='table-container'>
                  <Row gutter={30} className='bill-selection' justify='end'>
                    <Col xl={{ span: 24 / intColumnas }} className='export'>
                      <span className='button'>
                        <Button
                          size='medium'
                          className='btn-excel'
                          title='Exportar a excel'
                          onClick={this.exportExcel}
                          icon={<FileExcelOutlined />}
                        ></Button>
                      </span>
                    </Col>
                  </Row>
                  <Divider />
                  <Table
                    pagination={{
                      defaultPageSize: 10,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} de ${total} registros`,
                      current: this.state.filtros.currentPage,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '30'],
                      total: this.state.total,
                    }}
                    onChange={this.handleOnChange}
                    columns={this.columns}
                    footer={() => <Total sumatorias={this.state.sumatorias} />}
                    dataSource={this.state.resultado}
                  ></Table>
                </div>
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Quotas);
