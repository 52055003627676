import { Col, Form, Row, Spin, Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  cuentas,
  documentos,
} from '../../../../services/pagador/supplierServices';
import { isEqualsToObject } from '../../../../utilities/compare';
import Accounts from './accounts/accounts';
import BasicData from './basicData/basicData';
import Documents from './documents/documents';

class SupplierDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeTab: '0',
      proveedorSeleccionado: {
        nombre: '',
        scProveedor: '',
        estadoPersona: '',
        feIngreso: '',
        id: '',
        razonSocial: '',
        tipoId: '',
        tipoPersona: '',
      },
      cuentas: [],
      documentos: [],
    };

    this.componentRef = React.createRef();
  }

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  getData = async () => {
    const { proveedorSeleccionado } = this.props;

    this.setState({
      ...this.state,
      loading: true,
      activeTab: '0',
    });

    let documentosR, cuentasR;

    try {
      const resDoc = await documentos(
        this.props.authToken,
        this.props.company.company,
        proveedorSeleccionado.scPersona
      );
      documentosR = resDoc.data;
    } catch (error) {
      documentosR = [];
    }

    try {
      const resCuenta = await cuentas(
        this.props.authToken,
        this.props.company.company,
        proveedorSeleccionado.scPersona
      );

      cuentasR = resCuenta.data;
    } catch (error) {
      cuentasR = [];
    }

    this.setState({
      ...this.state,
      documentos: documentosR,
      cuentas: cuentasR,
      loading: false,
    });
  };

  componentDidMount() {
    if (this.props.proveedorSeleccionado.scProveedor !== '') {
      this.state.proveedorSeleccionado = {
        ...this.props.proveedorSeleccionado,
      };

      this.componentRef.current.scrollIntoView();
      this.getData();
    }
  }

  componentDidUpdate(props, state) {
    if (
      !isEqualsToObject(
        state.proveedorSeleccionado,
        this.props.proveedorSeleccionado
      )
    ) {
      this.state.proveedorSeleccionado = {
        ...this.props.proveedorSeleccionado,
      };

      this.getData();

      this.componentRef.current.scrollIntoView();
    }
  }

  render() {
    const { activeTab, proveedorSeleccionado, documentos, cuentas, loading } =
      this.state;
    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div ref={this.componentRef} className='header-container'>
          <p className='title'>Detalle</p>
          <Form name='suppliers_form' layout='vertical'>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label='Detalle del siguiente proveedor'>
                  {proveedorSeleccionado.nombre}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className='align-center'>
          <Tabs activeKey={activeTab} onChange={this.changeTab}>
            <Tabs.TabPane key={'0'} tab='Datos básicos'>
              <BasicData data={proveedorSeleccionado} />
            </Tabs.TabPane>
            <Tabs.TabPane key={'1'} tab='Documentos'>
              <Documents
                data={documentos}
                scProveedor={proveedorSeleccionado.scPersona}
                company={this.props.company.company}
                authToken={this.props.authToken}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key={'2'} tab='Cuentas bancarias'>
              <Accounts data={cuentas} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(SupplierDetail);
