import React from "react";
import Login from "../pages/proveedor/login/login";

import '../styles/loginLayout.scss'
import {
  Switch,
  Route,
} from "react-router-dom";
import { Card, Row, Col } from "antd";

// Since routes are regular React components, they
// may be rendered anywhere in the app, including in
// child elements.
//
// This helps when it's time to code-split your app
// into multiple bundles because code-splitting a
// React Router app is the same as code-splitting
// any other React app.

export default class LoginLayout extends React.Component {

  render() {
    return (
      <Row className="login-layout-style" justify="center" align="middle">
        <Card className="login-card radius-3">
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/login/remember`} exact>
              <Login mode={`remember`} />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/login/resetpassword/:token`} exact render={(props) => {
              return <Login mode={`confirm`} token={props.match.params.token} />
            }} />
            <Route path={`${process.env.PUBLIC_URL}/login`} exact>
              <Login mode={`login`} />
            </Route>
          </Switch>
        </Card>
      </Row>
    );
  }
}
