import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import React from 'react';
import {
  digitsMessagge,
  digitsPattern,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../../utilities/textTools';
import { range } from '../../../../../utilities/validate/numberValidate';
import { length } from '../../../../../utilities/validate/textValidate';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const tipoOpMonExt = [
  { key: 'EXPORTAR', description: 'Exportación' },
  { key: 'IMPORTAR', description: 'Importación' },
];

const tipoCuenta = [
  { key: 'A', description: 'Ahorro' },
  { key: 'C', description: 'Corriente' },
];

const initialState = {
  cdSnOpMonExt: false,
};

const initialValues = {
  cdSnOpMonExt: false,
};

const rules = {
  cdTipoOpMonExt: [{ required: true, message: requiredValueMessage() }],
  entidadExt: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 7, 100),
    },
  ],
  numCuentaExt: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  cdTipoCuentaExt: [{ required: true, message: requiredValueMessage() }],
  monedaExt: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 1, 200),
    },
  ],
  montoExt: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => range(value, 0, 99999),
    },
    {
      pattern: digitsPattern(5, 4),
      message: digitsMessagge(5, 4),
    },
  ],
  cdPaisExt: [{ required: true, message: requiredValueMessage() }],
  cdDepExt: [{ required: true, message: requiredValueMessage() }],
  cdMunExt: [{ required: true, message: requiredValueMessage() }],
};

class ForeignCurrencyOperations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.formRef = React.createRef();
  }

  handleOnFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish('-1', {
      ...formValue,
      cdSnOpMonExt: formValue.cdSnOpMonExt ? 'S' : 'N',
    });
  };

  handleOnChangeCdSnOpMonExt = (value) => {
    this.setState({ ...this.state, cdSnOpMonExt: value });
  };

  handleOnChangePais = (value) => {
    const { onChangePais } = this.props;
    this.formRef.current.setFieldsValue({
      cdDepExt: undefined,
      cdMunExt: undefined,
    });
    onChangePais(value);
  };

  handleOnChangeDepartamento = (value) => {
    const { onChangeDepartamento } = this.props;

    const cdPais = this.formRef.current.getFieldValue('cdPaisExt');

    this.formRef.current.setFieldsValue({
      cdMunExt: undefined,
    });

    onChangeDepartamento(value, cdPais);
  };

  componentDidUpdate(props, state) {
    if (JSON.stringify(this.props.person) !== JSON.stringify(props.person)) {
      const { person } = this.props;
      this.formRef.current.setFieldsValue({
        ...initialValues,
        ...person,
        cdSnOpMonExt: person.cdSnOpMonExt === 'S',
      });

      this.setState({
        ...this.state,
        cdSnOpMonExt: person.cdSnOpMonExt === 'S',
      });
    }
  }

  createOperationsForm = () => {
    const { paises, departamentos, municipios } = this.props;

    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdTipoOpMonExt'
              label='Tipo de operaciones'
              rules={rules['cdTipoOpMonExt'] || ''}
            >
              <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                {tipoOpMonExt.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='entidadExt'
              label='Entidad'
              rules={rules['entidadExt'] || ''}
            >
              <Input maxLength={100} minLength={7} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='numCuentaExt'
              label='Cuenta Nro'
              rules={rules['numCuentaExt'] || ''}
            >
              <Input maxLength={20} minLength={7} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='cdTipoCuentaExt'
              label='Tipo de cuenta'
              rules={rules['cdTipoCuentaExt'] || ''}
            >
              <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                {tipoCuenta.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='monedaExt'
              label='Moneda'
              rules={rules['monedaExt'] || ''}
            >
              <Input maxLength={200} minLength={7} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='montoExt'
              label='Monto (Promedio mensual)'
              rules={rules['montoExt'] || ''}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                formatter={(value) => currencyInputFormat(value)}
                parser={(value) => currencyInputParser(value)}
                stringMode={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdPaisExt'
              label='País'
              rules={rules['cdPaisExt'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={this.handleOnChangePais}
              >
                {paises.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='cdDepExt'
              label='Departamento'
              rules={rules['cdDepExt'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  departamentos.fetching ? <Spin size='small' /> : null
                }
                onChange={this.handleOnChangeDepartamento}
              >
                {departamentos.options.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdMunExt'
              label='Ciudad/Municipio'
              rules={rules['cdMunExt'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  municipios.fetching ? <Spin size='small' /> : null
                }
              >
                {municipios.options.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const { cdSnOpMonExt } = this.state;
    return (
      <div className='content-container general-style'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Form
            ref={this.formRef}
            name='currency_operations_data'
            layout='vertical'
            onFinish={this.handleOnFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                    <Col span={12}>
                      <Space>Operación con moneda extranjera</Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdSnOpMonExt'
                        valuePropName='checked'
                        noStyle
                      >
                        <Switch
                          checkedChildren='Si'
                          unCheckedChildren='No'
                          onChange={this.handleOnChangeCdSnOpMonExt}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            {cdSnOpMonExt && this.createOperationsForm()}

            <Row align='middle' justify='center'>
              <Col span={6}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Guardar cambios
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

export default ForeignCurrencyOperations;
