import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd';
import equal from 'fast-deep-equal';
import React, { Component } from 'react';
import {
  digitsMessagge,
  digitsPattern,
  requiredValueMessage,
} from '../utilities/admin_validation_messages';
import { tipoIds, Genero } from '../utilities/constants';
import {
  percentageInputFormat,
  percentageInputParser,
} from '../utilities/textTools';
import * as numberValidate from '../utilities/validate/numberValidate';
import { length } from '../utilities/validate/textValidate';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

export const initialValuesShareholder = {
  cdTipoId: '',
  id: '',
  nombre: '',
  cdGenero: '',
  poParticipacion: '',
  scAccionista: '',
  key: '',
};

const rules = {
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9a-zA-Z-]*$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 3, 20),
    },
  ],
  nombre: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  poParticipacion: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => numberValidate.range(value, 0, 100),
    },
    {
      pattern: digitsPattern(3, 2),
      message: digitsMessagge(3, 2),
    },
  ],
  cdGenero: [{ required: true, message: requiredValueMessage() }],
};
class Shareholder extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleOnFinish = (formValue) => {
    const { onFinish } = this.props;

    const dsTipoId = tipoIds.find(
      (value) => value.key === formValue.cdTipoId
    ).description;

    onFinish({ ...formValue, dsTipoId });
  };

  componentDidUpdate(props, state) {
    if (this.props.visible !== props.visible && this.props.visible) {
      this.formRef.current.setFieldsValue({ ...initialValuesShareholder });
      if (!equal(this.props.data, props.data)) {
        this.formRef.current.setFieldsValue({ ...this.props.data });
      }
    }
  }

  render() {
    const { onClose, visible } = this.props;
    return (
      <Modal
        title='Accionista'
        visible={visible}
        onCancel={onClose}
        width={500}
        footer={false}
      >
        <Form
          ref={this.formRef}
          name='shareholder_data'
          layout='vertical'
          initialValues={initialValuesShareholder}
          onFinish={this.handleOnFinish}
        >
          <Form.Item
            name='cdTipoId'
            label='Tipo de documento'
            rules={rules['cdTipoId'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {tipoIds.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name='scAccionista' hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item name='key' hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item
            name='id'
            label='Número de identificación'
            rules={rules['id'] || ''}
          >
            <Input minLength={3} maxLength={20} />
          </Form.Item>

          <Form.Item name='nombre' label='Nombre' rules={rules['nombre'] || ''}>
            <Input maxLength={100} />
          </Form.Item>

          <Form.Item
            name='cdGenero'
            label='Genero'
            rules={rules['cdGenero'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {Genero.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
          </Form.Item>

          <Form.Item
            name='poParticipacion'
            label='Participación'
            rules={rules['poParticipacion'] || ''}
          >
            <InputNumber
              max={100}
              min={0}
              formatter={percentageInputFormat}
              parser={percentageInputParser}
              style={{ width: '100%' }}
              stringMode={true}
            />
          </Form.Item>

          <Row justify='center' align='middle'>
            <Col span={6}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
export default Shareholder;
