import React, { Component } from 'react';
import { Row, Col } from 'antd';
import './reportCenter.scss';

class total extends Component {
  constructor(props) {
    super(props);
  }

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  render() {
    if (this.props.sumatorias) {
      let sumatorias = Object.keys(this.props.sumatorias);
      return (
        <div>
          <Row>
            <Col span={24} style={{ textAlign: 'left' }}>
              Totales:
            </Col>
            {sumatorias.map((value) => {
              let title = value
                .replace('totalVr', '')
                .replace('TotalVr', '')
                .replace('SumaVr', '')
                .replace('sumaVr', '')
                .replace('SumaNm', '')
                .replace('sumaNm', '')
                .replace('Total', '')
                .replace(/([A-Z])/g, ' $1')
                .trim();
              return (
                <Col span={12} className='right-columns'>
                  <span>
                    {title.charAt(0).toUpperCase() + title.slice(1)}: $
                    {this.formatNumber(this.props.sumatorias[value])}
                  </span>
                </Col>
              );
            })}
          </Row>
        </div>
      );
    } else {
      return false;
    }
  }
}

export default total;
