import React from 'react';
import { Spin, Form, Col, Row, Select, Button } from 'antd';
import { BUSQUEDA_TITLE } from '../../../../../utilities/messages';
import moment from 'moment';

const initialValue = {
  filtros: {
    feRecaudo: moment(),
    vrRecaudo: '',
    observacion: '',
  },
  scEntidad: '',
  tipoEntidad: 'PAG',
};

const SupportFilterForm = ({
  entidadSelect,
  handleOnSearchEntidad,
  handleOnChangeEntidad,
  handleOnChangeTipoEntidad,
  handleSubmit,
}) => {
  const [form] = Form.useForm();

  const entidades = [
    { key: 'PAG', value: 'Pagador' },
    { key: 'PRO', value: 'Proveedor' },
  ];

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={5}>
            <Form.Item
              name='tipoEntidad'
              label='Tipo Entidad'
              rules={[{ required: true, message: 'seleccionar una entidad' }]}>
              <Select onChange={handleOnChangeTipoEntidad}>
                {entidades.map((d) => (
                  <Select.Option key={d.key} value={d.key}>
                    {d.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              name='scEntidad'
              label='Entidad'
              rules={[{ required: true, message: 'seleccionar una entidad' }]}
            >
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchEntidad(value)}
                onChange={handleOnChangeEntidad}
                notFoundContent={
                  entidadSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {entidadSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SupportFilterForm;
