import React from 'react';
import './TYC.scss';
import { Row, Col, Card, Button, Typography } from 'antd';
import { acceptTermsAction } from '../../../store/actions/authActions';
import { connect } from 'react-redux';
import history from '../../../services/history';

const { Title } = Typography;

const tabList = [
  {
    key: 'tyc',
    tab: 'Condiciones de uso',
  },
  {
    key: 'habeasdata',
    tab: 'Tratamiento de datos personales',
  },
];

class TYC extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  state = {
    key: 'tyc',
    buttonActive: false,
  };

  contentList = {
    tyc: () => (
      <div>
        <Title level={4}>“CONDICIONES DE USO DEL SISTEMA AFFIRMATUM”.</Title>
        <div className='wrapper' onScroll={(e) => this.handleScroll(e)}>
          <p>
            La aplicación AFFIRMATUM (en adelante “LA APLICACIÓN”) es un
            servicio establecido por parte de EXPONENCIAL CONFIRMING S.A.S. (en
            adelante “LA COMPAÑÍA”), a través de su página web:
            www.exponencialconfirming.com, mediante el cual busca facilitar a
            sus clientes y visitantes el acceso a sus productos y servicios, así
            como el acceso a información económica y financiera, cuya
            utilización se regirá por las disposiciones legales en la materia, y
            en general, por los siguientes términos y condiciones:
          </p>
          <h3>
            <strong>
              Reglas para el Funcionamiento y Operación del Sistema de
              Negociación Electrónica:
            </strong>
          </h3>
          <p>
            La página web del sistema www.exponencialconfirming.com tiene un
            contenido meramente informativo y un contenido transaccional.
            Respecto al contenido informativo, LA COMPAÑÍA manifiesta que los
            datos e información allí contenidos no deben interpretarse como una
            asesoría, recomendación o sugerencia por parte de la misma para la
            toma de decisiones de inversión o para la realización de cualquier
            tipo de transacciones o negocios, razón por la cual, el uso de la
            información suministrada es de exclusiva responsabilidad del
            usuario. Por su parte, los valores, tasas de interés y demás datos
            que allí se encuentren, son puramente informativos y no constituyen
            una oferta, ni una demanda en firme, para la realización de
            transacciones. Por esto, LA COMPAÑÍA no será responsable por las
            decisiones tomadas por los usuarios o por terceros con base en la
            información y los materiales presentados en dicha página web.
          </p>
          <p>
            LA COMPAÑÍA, en ningún caso, será responsable por los perjuicios o
            consecuencias, directas o indirectas, derivados de la interpretación
            o mal uso de la información contenida en esta página, razón por la
            cual, el uso de la información suministrada es de exclusiva
            responsabilidad del usuario. Así mismo, LA COMPAÑÍA no responderá
            por la oportunidad y suficiencia de la información contenida en la
            página, ni de la veracidad o exactitud de los documentos que
            establezcan enlaces a esta publicación, ni por eventuales problemas
            de carácter técnico, inexactitudes, omisiones o errores de
            trascripción que pudieran afectar el contenido de la página o de los
            enlaces contenidos en la misma. Teniendo en cuenta lo anterior, se
            advierte al usuario sobre la necesidad de validar la información
            antes de hacer uso de la misma.
          </p>
          <p>
            LA COMPAÑÍA se reserva el derecho de modificar, alterar o suprimir
            los contenidos de la página sin necesidad de previo aviso. El
            contenido de carácter informativo de esta página es desarrollado por
            LA COMPAÑÍA o un tercero autorizado, y no compromete el pensamiento
            o la opinión de sus anunciantes o sitios vinculados.
          </p>
          <p>
            Respecto al contenido transaccional se manifiesta que una vez usted
            se haya registrado como afiliado a LA COMPAÑÍA y valide su acceso
            con usuario y clave, podrá realizar directamente sus operaciones
            desde nuestra aplicación transaccional en todos los canales
            ofrecidos, tanto en la aplicación móvil, como en la aplicación web y
            en el canal telefónico, y dichas transacciones se regirán por los
            términos y condiciones que se presentan en el presente reglamento.
          </p>
          <p>
            La consulta de datos e informes técnicos distintos a los necesarios
            para la ejecución de las transacciones y que se presentan a través
            de la Página Web, deberán considerarse únicamente para efectos
            informativos, pues los mismos no llevan implícita ninguna
            recomendación o sugerencia de inversión que impliquen una asesoría
            en materia de inversiones, que generen obligaciones o comprometan a
            LA COMPAÑÍA o sus filiales.
          </p>
          <p>
            EL USUARIO reconoce desde ahora que todo el Software y cualquier
            tipo de código usado en la Página Web es propiedad de LA COMPAÑÍA
            y/o de sus proveedores, y está protegido por leyes nacionales y
            tratados internacionales sobre la propiedad intelectual. Cualquier
            reproducción o redistribución está expresamente prohibida por la ley
            y puede conllevar sanciones civiles y penales
          </p>
          <p>
            En cuanto a lo no expresado en estas condiciones, se estará a lo
            prescrito en los contratos, convenios o reglamentos que regulen cada
            producto o servicio prestado por LA COMPAÑÍA y a la ley colombiana.
          </p>
          <h3>
            <strong>
              Criterios para la Vinculación y Desvinculación de Usuarios:
            </strong>
          </h3>
          <p>
            Podrán utilizar el servicio los afiliados a LA COMPAÑÍA, que se
            encuentren vinculados a través de acuerdos legales debidamente
            suscritos con LA COMPAÑÍA y a quienes se haya suministrado usuario y
            clave, de acuerdo con el “Formato de inscripción de usuarios" que
            haya diligenciado el representante legal de la empresa afiliada (en
            adelante “EL USUARIO”). Para solicitar alguna modificación sobre los
            usuarios inscritos a la empresa afiliada, deberá ponerse en contacto
            con la línea de servicio al cliente 604-448-1248 donde se le
            brindará la información y proceso a seguir.
          </p>
          <p>
            En caso de requerir la desvinculación de un usuario a LA APLICACIÓN,
            en el momento que se requiera, uno de los usuarios autorizados e
            inscritos por el representante legal, deberá realizar la solicitud
            comunicándose a la línea de servicio al cliente 604-448-1248 donde
            se le brindará la información y proceso a seguir.
          </p>
          <p>
            LA APLICACIÓN permitirá a EL USUARIO una comunicación directa vía
            Internet con LA COMPAÑÍA, con el fin de darle a EL USUARIO
            información y/o permitirle la realización de operaciones y
            transacciones determinadas o habilitadas por LA COMPAÑÍA. Dichas
            transacciones se regularán por los convenios particulares celebrados
            entre EL USUARIO y LA COMPAÑÍA, por el presente reglamento y por las
            normas legales que les sean aplicables.
          </p>
          <h3>
            <strong>
              Derechos y Obligaciones de los Usuarios del Sistema de Negociación
              Electrónica:
            </strong>
          </h3>
          <p>
            EL USUARIO, siempre y cuando estuviere habilitado para ello, podrá
            hacer entre otros, los siguientes actos, operaciones o transacciones
            a través de LA APLICACIÓN:
          </p>
          <p>
            • Consulta de facturas pendientes de pago de los diferentes
            pagadores a los cuales se encuentre asociado.
          </p>
          <p>
            • Solicitud de negociación de facturas de acuerdo con la tasa y
            condiciones que se encuentren registradas en la aplicación.
          </p>
          <p>
            • Descarga de soportes e información de las transacciones realizadas
            por medio de la aplicación.
          </p>
          <p>
            • En general EL USUARIO, por medio del usuario y contraseña que le
            ha suministrado o habilitado LA COMPAÑÍA podrá identificarse ante LA
            COMPAÑÍA y ejecutar las distintas operaciones o transacciones que se
            encuentren disponibles en ese momento.
          </p>
          <p>
            EL USUARIO adquirirá y mantendrá a su propio costo y gasto todo el
            equipo y los medios de comunicación necesarios para utilizar LA
            PLATAFORMA y LA COMPAÑÍA no se hará responsable de la disponibilidad
            ni de la confiabilidad de dicho equipo o de los medios de
            comunicaciones. Para poder acceder a LA PLATAFORMA, EL USUARIO
            deberá disponer de los medios físicos que le permitan recibir o
            transmitir vía Internet la información aquí prevista.
          </p>
          <p>
            Además de las obligaciones contenidas en el presente reglamento y en
            los contratos, convenios o reglamentos que regulan cada producto o
            servicio, EL USUARIO se obliga específicamente a:
          </p>
          <p>
            • No permitir que terceras personas accedan a LA PLATAFORMA con la
            clave y usuario del USUARIO.
          </p>
          <p>
            • Custodiar en todo momento y bajo altos estándares de seguridad la
            información correspondiente de su usuario y la clave.
          </p>
          <p>
            • Seguir las recomendaciones en cuanto a forma de operar y
            seguridades de LA PLATAFORMA.
          </p>
          <p>
            • Guardar debida reserva sobre la forma, manejo, funcionalidades y
            facilidades de LA PLATAFORMA
          </p>
          <h3>
            <strong>
              Reglas que Permitan el Acceso y la Identificación del USUARIO:
            </strong>
          </h3>
          <p>
            Para acceder a LA PLATAFORMA, LA COMPAÑÍA habilitará a EL USUARIO un
            usuario y contraseña que se constituirá en la firma electrónica que
            identificará a EL USUARIO en sus relaciones con LA COMPAÑÍA. EL
            USUARIO se obliga a mantener en absoluta reserva dicha contraseña, a
            fin de que nadie más que él tenga acceso a los servicios ofrecidos;
            por lo tanto, EL USUARIO no podrá ceder ni hacerse sustituir por
            terceros en el ejercicio de los derechos y compromisos que se le
            imponen. LA COMPAÑÍA no se hará responsable de los perjuicios que
            pueda sufrir EL USUARIO por el incumplimiento de la obligación que
            aquí asume.
          </p>
          <p>
            EL USUARIO acepta desde ahora como prueba de las operaciones y/o
            transacciones efectuadas, los registros electrónicos que se originen
            bajo la clave, reconociendo como veraces dichos registros que se
            originen en LA COMPAÑÍA bajo su usuario.
          </p>
          <p>
            EL USUARIO deberá registrar en LA COMPAÑÍA su dirección de correo
            electrónico y deberá notificar por escrito todo cambio del mismo.
            Las notificaciones y correspondencia remitidas a esta dirección por
            LA COMPAÑÍA, tendrá plena validez.
          </p>
          <p>
            EL USUARIO se obliga a actualizar anualmente la información y datos
            básicos, así como a suministrar la totalidad de los soportes
            documentales a LA COMPAÑÍA cada vez que éste los solicite, de
            acuerdo con sus políticas y con la regulación vigente. El
            incumplimiento de esta obligación dará derecho a LA COMPAÑÍA para
            cancelar los contratos y suspender los servicios sin que se genere
            indemnización alguna en beneficio de EL USUARIO. LA COMPAÑÍA podrá
            utilizar la información de ubicación y contacto de EL USUARIO con la
            finalidad de ofrecerle sus productos o servicios, e identificar
            posibles transacciones inusuales.
          </p>
          <p>
            La información personal o datos personales que solicite LA COMPAÑÍA
            en ejercicio de sus funciones, serán recolectados, usados y tratados
            conforme a la Política de Tratamiento de Datos Personales de LA
            COMPAÑÍA, la cual se encuentra disponible en la página web
            www.exponencialconfirming.com.
          </p>
          <h3>
            <strong>Derechos, Facultades y Obligaciones de LA COMPAÑÍA:</strong>
          </h3>
          <p>
            LA COMPAÑÍA se reserva el derecho de interrumpir o suspender el
            servicio de LA PLATAFORMA por dificultades técnicas, de seguridad,
            por los problemas que puedan presentarse por cortes en los servicios
            de teléfonos, energía, etc.; así mismo, podrá establecer límites a
            las operaciones a realizar a través de LA PLATAFORMA. LA COMPAÑÍA
            podrá, sin que haya lugar a indemnización alguna, denegar
            utilizaciones o transacciones, bloquear temporal o definitivamente,
            cancelar o revocar total o parcialmente los servicios u operaciones
            en los siguientes eventos:
          </p>
          <p>
            • Mora en el pago de cualquier obligación a favor de LA COMPAÑÍA, la
            cual se entenderá que ha ocurrido en el momento en que exista
            cualquier obligación de pago vencida a cargo del USUARIO y a favor
            de LA COMPAÑÏA.
          </p>
          <p>
            • En el evento de presentarse irregularidades en el uso de
            cualquiera de los servicios.
          </p>
          <p>
            • Como medida de seguridad para LA COMPAÑÍA o para EL USUARIO por
            irregularidades o circunstancias que impliquen tal hecho.
          </p>
          <p>
            Para realizar la consulta de facturas o la solicitud de negociación
            de estas, EL USUARIO tendrá habilitada LA PLATAFORMA en los horarios
            que sean establecidos por LA COMPAÑÍA. Para consultar dichos
            horarios debe ponerse en contacto con la línea de servicio al
            cliente al teléfono 604-448-1248.
          </p>
          <p>
            LA COMPAÑÍA se reserva el derecho de adicionar, suspender y/o
            cancelar alguno de los productos ofrecidos a EL USUARIO a través de
            LA PLATAFORMA, sin que ello genere indemnización alguna.
          </p>
          <p>
            LA COMPAÑÍA no será responsable, entre otros, por los siguientes
            eventos:
          </p>
          <p>
            • Por el uso indebido de LA PLATAFORMA por parte de las personas
            autorizadas o no por EL USUARIO. En consecuencia, EL USUARIO asume
            toda la responsabilidad de las operaciones ordenadas mediante LA
            PLATAFORMA, sin requisito distinto a que la orden se haya impartido
            a través de LA PLATAFORMA y empleando las claves de acceso en los
            términos establecidos en el presente reglamento.
          </p>
          <p>
            • Por los perjuicios de cualquier naturaleza que pueda sufrir EL
            USUARIO a consecuencia de una imposibilidad, demora o deficiente
            transmisión de los datos u operaciones solicitadas en su sistema de
            computación, a causa de fallas en el equipo de EL USUARIO, redes
            telefónicas u otras, ajenas al control de LA COMPAÑÍA, así como por
            problemas técnicos, por actividades de mantenimiento o por
            suspensión de LA PLATAFORMA.
          </p>
          <p>
            • Fuerza mayor, caso fortuito, causa extraña o hecho de un tercero y
            que en un momento puedan ocasionarle perjuicios a EL USUARIO que
            imposibilite, demore, desvíe o altere la realización de operaciones
            y transacciones.
          </p>
          <p>
            • Si las operaciones no pueden realizarse por causas atribuibles a
            EL USUARIO, tales como incorrecta operación del sistema, omisiones o
            insuficiencia de información en los formularios y solicitudes, por
            daños en los sistemas de transmisión de datos, u otros que estén por
            fuera del control de LA COMPAÑÍA.
          </p>
          <p>
            • LA COMPAÑÍA no tendrá responsabilidad o carga alguna frente a
            proveedores, sitios de terceros, y cualquier otra persona respecto a
            cualquier acto, omisión o garantía ofrecidos por dichos terceros,
            pues LA COMPAÑÍA queda desligado de cualquier negocio celebrado
            entre EL USUARIO y dichos terceros.
          </p>
          <p>
            • LA COMPAÑÍA no estará obligada a validar la información de las
            transacciones que realice EL USUARIO a través de LA PLATAFORMA, por
            lo tanto, no será responsable por errores.
          </p>
          <p>
            • Por la información publicada en los sitios con los cuales la
            Página Web posee vínculos.
          </p>
          <p>
            Para la prestación del servicio, LA COMPAÑÍA podrá utilizar su
            propia plataforma tecnológica o la de un tercero designado por éste,
            que cumpla con adecuados estándares de seguridad. En tal caso, EL
            USUARIO autoriza expresamente a dicho tercero para conocer la
            información necesaria para la ejecución del servicio, con fines
            estrictamente transaccionales.
          </p>
          <h3>
            <strong>
              Política General en Materia de Tarifas y Expensas a Cargo de los
              USUARIOS:
            </strong>
          </h3>
          <p>
            LA COMPAÑÍA queda con facultad de establecer y cobrar a EL CLIENTE
            las tarifas y/o comisiones que defina por concepto de la utilización
            de los diferentes servicios, productos o transacciones ofrecidos,
            dentro de la oferta presentada al emisor de las facturas a negociar
            se detalla la tasa de descuento financiera y la comisión a la cual
            haya lugar al realizar la transacción. Con la aceptación de la
            oferta en el sistema, EL USUARIO manifiesta estar de acuerdo con
            estas tarifas las cuales son causadas únicamente por la negociación
            de la factura. No se genera cobro adicional por el uso de la
            plataforma. LA COMPAÑÍA estará autorizada para debitar la tarifa y/o
            comisión de del pago realizado a EL USUARIO por la negociación de
            las facturas y/o el uso del sistema.
          </p>
          <h3>
            <strong>
              Procedimientos Internos para la Aprobación y Modificación del
              Reglamento por parte de LA COMPAÑÍA:
            </strong>
          </h3>
          <p>
            El presente reglamento tendrá una vigencia indefinida. LA COMPAÑÍA
            en cualquier momento, puede cancelar, modificar, limitar o adicionar
            los términos y condiciones de este reglamento, mediante aviso a
            través de su Página Web o por cualquier otro medio, y que se
            entenderán aceptados si EL USUARIO continúa haciendo uso de LA
            APLICACIÓN. La suspensión, limitación o cancelación de servicios por
            parte de LA COMPAÑÍA, no dará lugar a ninguna reclamación de
            responsabilidad o perjuicio en favor de EL USUARIO o terceros. En
            caso de discrepancia entre la versión de sus documentos impresos y
            la versión electrónica de los mismos publicados en las páginas Web
            relacionadas, primarán éstas últimas.
          </p>
          <p>
            LA COMPAÑÍA para la aprobación y modificación del reglamento deberá
            contar con aprobación de la alta dirección.
          </p>
          <h3>
            <strong>Solución de Reclamos, Controversias y Quejas:</strong>
          </h3>
          <p>
            Cualquier reclamo derivado del presente servicio deberá hacerse
            dentro de los treinta (30) días siguientes a la transacción objeto
            de reclamo. Transcurrido este lapso sin reclamación de EL USUARIO se
            considerarán aprobadas las transacciones. Para realizar reclamos,
            quejas o consultas sobre el servicio ofrecido, EL USUARIO debe
            ponerse en contacto con la compañía a través del correo electrónico
            exponencial@affiratum.com y la línea de servicio al cliente
            604-448-1248.
          </p>
          <h3>
            <strong>Reglas De Auditoría de la PLATAFORMA</strong>
          </h3>
          <p>
            La auditoría de la PLATAFORMA estará sujeta a las siguientes
            condiciones:
          </p>
          <p>
            1. La PLATAFORMA solo podrá ser auditada por autoridades competentes
            o entidades con las cuales se haya acordado contractualmente.
          </p>
          <p>
            2. Para realizar una auditoría es necesario conocer el equipo
            auditor, con el fin de garantizar que se cuenta con las capacidades
            necesarias para realizar el proceso.
          </p>
          <p>
            3. Se debe dar aviso al menos con dos semanas de antelación a la
            auditoría y se debe contar con el cronograma de auditoría
            correspondiente con el fin de tener los recursos necesarios
            disponibles.
          </p>
          <p>
            4. El equipo auditor deberá dejar informe por escrito a más tardar
            una semana después de haber ejecutado la auditoría.
          </p>
          <p>
            5. La plataforma cuenta con un Sistema de Gestión de Seguridad de la
            Información (SGSI) basado en la norma ISO 27001, dentro del cual se
            contemplan auditorías de índole interno y externo.
          </p>
        </div>
        <Row align='middle' justify='end'>
          <Col span={6}>
            <Button
              disabled={!this.state.buttonActive}
              className={
                'form-btn btn-radius btn-accept' +
                (!this.state.buttonActive ? ' disabled' : '')
              }
              type='primary'
              onClick={() => {
                this.acceptTerms();
              }}
            >
              Aceptar los Términos
            </Button>
          </Col>
          {this.generarBoton()}
        </Row>
      </div>
    ),

    habeasdata: () => (
      <div>
        <Title level={4}>LEY DE PROTECCIÓN DE DATOS PERSONALES.</Title>
        <div className='wrapper'>
          <p>
            En Exponencial Confirming, nuestro compromiso es el adecuado manejo
            de los datos personales.
          </p>
          <p>
            Desde todos los procesos, velamos por la confidencialidad de los
            datos, por contar con las herramientas tecnológicas idóneas para su
            almacenamiento y el buen uso de estos en función de darle la
            asesoría y el acompañamiento que nuestros clientes necesitan.
          </p>
          <p>
            De acuerdo con lo anterior, y como responsables del uso de la
            información de nuestros clientes, damos cumplimiento a la Ley 1581
            de 2012 de Protección de Datos Personales, por esto ponemos a su
            disposición lo siguiente:
          </p>
          <br />
          <p>
            <a
              href='https://resources-affirmatum.s3.amazonaws.com/Politicas-de-proteccion-y-tratamiento-de-datos-personales-V2.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Políticas de protección y tratamiento de datos personales
            </a>
          </p>
          <p>
            <a
              href='https://resources-affirmatum.s3.amazonaws.com/Aviso-de-privacidad-V2.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Aviso de privacidad
            </a>
          </p>
          <p>
            <a
              href='https://resources-affirmatum.s3.amazonaws.com/AD-FO-01_Autorizaci%C3%B3n_para_la_obtenci%C3%B3n__uso__manejo_yo_tratamiento_de_datos_personales.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Autorización para el tratamiento de datos personales
            </a>
          </p>
        </div>
      </div>
    ),
  };

  generarBoton() {
    let botones = (
      <Col span={6}>
        <Button
          disabled={!this.state.buttonActive}
          className={
            'form-btn btn-radius btn-accept' +
            (!this.state.buttonActive ? ' disabled' : '')
          }
          type='primary'
          onClick={() => {
            this.logout();
          }}
        >
          Salir
        </Button>
      </Col>
    );

    if (this.props.hadAccepted == 'false') {
      return botones;
    }
  }

  logout = () => {
    localStorage.removeItem('auth-token');
    return (window.location.href = `${process.env.PUBLIC_URL}/`);
  };

  componentDidMount() {
    if (this.props.menuHandler) {
      this.props.menuHandler('19');
    }
  }

  componentDidUpdate(props, state) {
    if (
      this.props.snAceptaCondiciones != props.snAceptaCondiciones &&
      this.props.snAceptaCondiciones === 'S'
    ) {
      history.push(`${process.env.PUBLIC_URL}/`);
    }
  }

  handleScroll(event) {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight + 100) {
      this.setState({
        ...this.state,
        buttonActive: true,
      });
    }
  }

  acceptTerms() {
    console.log('ACCEPT');
    this.props.acceptTermsAction(this.props.authToken);
  }

  onTabChange = (key, type) => {
    this.setState({ [type]: key });
  };

  render() {
    return (
      <div className='content-margin tyc'>
        <Row justify={'center'} className='help-top-margin'>
          <Col span={24}>
            <Card
              className='card-radius card-shadow tyc-card-padding'
              tabList={tabList}
              activeTabKey={this.state.key}
              onTabChange={(key) => {
                this.onTabChange(key, 'key');
              }}
            >
              {this.contentList[this.state.key]()}
            </Card>
          </Col>
        </Row>

        <Row justify={'center'} className='tyc-top-margin'>
          <p>
            Exponencial Confirming S.A.S. Todos los derechos reservados ® 2019
          </p>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
    snAceptaCondiciones: state.auth.snAceptaCondiciones,
  };
};

export default connect(mapStateToProps, { acceptTermsAction })(TYC);
