import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Divider,
  Spin,
  notification,
} from 'antd';
import {
  listaParametros,
  editarParametro,
} from '../../../../services/admin/parameterizationService';
import './parameterization.scss';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import ParamFormEdit from './paramFormEdit/paramFormEdit';
import {
  BUSQUEDA_TITLE,
  DATE_FORMAT,
  DATE_POST_FORMAT,
} from '../../../../utilities/messages';
import moment from 'moment';

const initialState = {
  loading: false,
  visibleEditModal: false,
  form: {
    descripcion: '',
  },
  parametroSelect: {},
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class Parameterization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'CÓDIGO',
        dataIndex: 'codigo',
        sorter: (a, b) => a.codigo - b.codigo,
        showSorterTooltip: false,
      },
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'descripcion',
        sorter: (a, b) => a.descripcion - b.descripcion,
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoRegistro',
        sorter: (a, b) => a.dsEstadoRegistro - b.dsEstadoRegistro,
        showSorterTooltip: false,
      },
      {
        title: 'INICIO',
        dataIndex: 'dsSnInicio',
        sorter: (a, b) => a.dsSnInicio - b.dsSnInicio,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR',
        dataIndex: 'valor',
        render: (value) => <p className='value-wrap'> {value} </p>,
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: '',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showModalEditar(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.searchRef = React.createRef();
  }

  handleSearchSubmit = (value) => {
    this.setState({
      ...this.state,
      form: value,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.getData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
      resultado: [],
      total: 0,
    });

    try {
      const response = await listaParametros(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, offset, limit, sort } = this.state;

    return {
      ...form,
      offset,
      limit,
      sort,
    };
  };

  rowClassName = (record, index) => {
    return record.vencido ? 'cuadro-vencidos' : '';
  };

  showModalEditar = (record) => {
    this.setState({
      ...this.state,
      visibleEditModal: true,
      parametroSelect: record,
    });
  };

  handleEditModalClose = () => {
    this.setState({
      ...this.state,
      visibleEditModal: false,
      parametroSelect: {},
    });
  };

  handleEditModalSave = async (value) => {
    const response = await editarParametro(this.props.authToken, {
      ...value,
      feVencimiento:
        value.feVencimiento !== null
          ? moment(value.feVencimiento, DATE_FORMAT).format(DATE_POST_FORMAT)
          : null,
    });

    const resultado = this.state.resultado.map((res) => {
      return res.codigo === value.codigo ? value : res;
    });

    this.setState({
      ...this.state,
      resultado: resultado,
      visibleEditModal: false,
    });

    notification.success({
      message: 'Actualizado',
      description: response.message,
      duration: 6,
    });
  };

  componentDidMount() {
    this.props.menuHandler('2');
    this.getData();
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resultado,
      visibleEditModal,
      parametroSelect,
    } = this.state;
    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='parameterization-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <p className='title'>{BUSQUEDA_TITLE}</p>
              <Form
                layout='vertical'
                className='filters-container'
                ref={this.searchRef}
                onFinish={this.handleSearchSubmit}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='descripcion'
                      label='Buscar por descripción'
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      <Button
                        htmlType='submit'
                        className='form-btn btn-radius'
                        type='primary'
                      >
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <Row className='row-left'>
              <Col>
                <div className='cuadro-vencidos'></div>
              </Col>
              <Col>
                <p className='label-lista'>
                  Los parámetros en color rojo están vencidos
                </p>
              </Col>
            </Row>
            <div>
              <p className='ptable'>Lista de parámetros</p>
            </div>
            <div className='table-container'>
              <Divider />
              <TableReport
                currentPage={currentPage}
                limit={limit}
                total={total}
                columns={this.columnas}
                data={resultado}
                handleChange={this.handleChange}
                rowClassName={this.rowClassName}
              />
            </div>
            <ParamFormEdit
              visible={visibleEditModal}
              data={parametroSelect}
              onClose={this.handleEditModalClose}
              onOk={this.handleEditModalSave}
            />
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Parameterization);
