import React from 'react'
import { Row, Col, Card, Button, Switch, InputNumber, Radio, Select, notification, Popover, Spin, Input } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import AffitokenValidator from '../../../components/affitokenValidator/affitokenValidator'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { listarCuentasProveedor } from '../../../services/proveedor/billServices'
import agrementsServices from '../../../services/proveedor/agrementsServices'
import equals from 'fast-deep-equal'

const { Option } = Select
class ConfigAgreement extends React.Component {

  constructor(props) {
    super(props)
    if (props.isNew) {
      if (props.newAgreements[0]) {

        this.state = this.mapState(this.mapNewAgreement(props.newAgreements[0]))
        this.state.isNew = true

      }
      else {
        this.state = {
          isNew: true
        }
      }
    }
    else {
      this.state = this.mapState(props)
    }
    this.state.isNew = props.isNew
    this.state.selectedNewAgreement = 0
    this.state.userAccounts = [{
      scCuenta: '0',
      dsNombreCuenta: 'Cargando...'
    },]
  }

  inputNumberRef = (index) => (element) => {
    this[`${index}_ref`] = element
  }



  mapNewAgreement({
    scConvenio,
    nombreConvenio,
    snDescAuto,
    nmDiasDescAuto,
    cdDiaDescAuto,
    descAutoPreferencia,
    scCuenta,
    tipoConvenio,
    fondeadorCuentaDto,
  }) {
    return {
      scConvenio: scConvenio,
      name: nombreConvenio,
      checked: snDescAuto == 'S',
      daysAuto: nmDiasDescAuto,
      daysBusiness: cdDiaDescAuto,
      preference: descAutoPreferencia,
      selectedAccount: scCuenta,
      typeAgreement: tipoConvenio,
      accounts: fondeadorCuentaDto
    }
  }

  mapState(props) {
    if (Array.isArray(props.accounts)) {
      return {
        ...this.state,
        loading: false,
        scConvenio: props.scConvenio,
        name: props.name,
        checked: props.checked,
        daysAuto: props.daysAuto,
        daysBusiness: props.daysBusiness || 'A',
        accounts: props.accounts.sort((a, b) => (a.preferencia || 9999) >= (b.preference || 9999) ? 1 : -1),
        preference: props.preference,
        selectedAccount: props.selectedAccount,
        typeAgreement: props.typeAgreement,
        userAccountbyRate: props.userAccountbyRate || {},
      }
    }
    return {}

  }
  componentDidMount() {
    if (this.state.isNew && this.props.newAgreements[0]) {
      this.setState({
        ...this.state,
        ...this.mapState(this.mapNewAgreement(this.props.newAgreements[this.state.selectedNewAgreement]))
      })

    }

    let { authtoken, company } = this.props
    listarCuentasProveedor(authtoken, company).then((response) => {
      this.setState({
        ...this.state,
        userAccounts: [{
          scCuenta: '0',
          dsNombreCuenta: 'Seleccione...'
        }, ...response.data],
      })
    })
  }

  componentDidUpdate(props, state) {
    if (props.scConvenio !== this.props.scConvenio) {
      this.setState(this.mapState(this.props))
    }

    if (!equals(props.newAgreements, this.props.newAgreements)) {
      this.setState(
        {
          ...this.state,
          ...this.mapState(this.mapNewAgreement(this.props.newAgreements[0]))
        }
      )

    }
  }

  changeDaysAuto(value) {
    this.setState({
      ...this.state,
      daysAuto: value
    })
  }

  changeSelectedNewAgreement(index) {
    this.setState(
      {
        ...this.state,
        ...this.mapState(this.mapNewAgreement(this.props.newAgreements[index])),
        selectedNewAgreement: index
      }
    )
  }


  siwtchChange(checked) {
    this.setState({
      ...this.state,
      checked: checked,
    })
  }

  radioChange = e => {
    this.setState({
      ...this.state,
      preference: e.target.value,
    });
  };

  changeDaysBusiness(value) {
    this.setState({
      ...this.state,
      daysBusiness: value
    })
  }

  changeOrder(oldValue, newValue) {
    let reorder = this.state.accounts
    if (newValue && reorder.length >= newValue && newValue > 0) {
      let temp = reorder[oldValue - 1]
      reorder[oldValue - 1] = reorder[newValue - 1]
      reorder[newValue - 1] = temp

      reorder[oldValue - 1].preference = oldValue
      reorder[newValue - 1].preference = newValue
      this.setState({
        ...this.state,
        accounts: reorder
      })
    }
    else {
    }
  }

  changeAccountbyEntity(account, index) {
    this.state.accounts[index].scCuenta = account
  }

  changeAccountbyRate(accountId) {
    this.state.userAccountbyRate = this.state.userAccounts.find(x => x.scCuenta === accountId)
  }

  saveChanges() {

    if (!this.props.affitoken.affitokenEntered || this.props.affitoken.affitokenEntered === '') {
      notification.error({
        message: 'Affitoken',
        description: 'Por favor ingrese el affitoken',
        duration: 5
      });
      return;
    }
    let data = {
      snDescAuto: this.state.checked ? 'S' : 'N',
      cdDiaDescAuto: this.state.daysBusiness,
      descAutoPreferencia: this.state.preference || 'T',
      scConvenio: this.state.scConvenio,
      nmDiasDescAuto: this.state.daysAuto
    }

    if (this.state.typeAgreement !== 'prontoPago') {
      if (this.state.preference === 'T') {
        data.scCuenta = this.state.userAccountbyRate.scCuenta || this.state.selectedAccount
      }

      if (this.state.preference === 'F') {
        data.fondeadorCuentaDto = this.state.accounts.map((item, index) => {
          return {
            preferencia: index,
            scCuenta: item.scCuenta,
            scFondeador: item.scFondeador
          }
        })
      }
    }

    this.setState({
      ...this.state,
      loading: true
    })
    if (this.state.isNew) {


      agrementsServices.insertAgreement(this.props.authtoken, this.props.company, data, this.props.affitoken.affitokenEntered).then(response => {
        if ((response && response.status !== false) || !response) {
          notification.success({
            message: 'Exitoso',
            description: 'La configuración de descuento automática creada exitosamente',
            duration: 5
          });

          setTimeout(() => {
            this.props.handler()
            this.setState({
              ...this.state,
              loading: false
            })
          }, 1000);

        }
        else {
          notification.error({
            message: 'Error',
            description: 'Ha ocurrido un error, intenta nuevamente',
            duration: 5
          });
          this.setState({
            ...this.state,
            loading: false
          })
        }
      }).catch((error) => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
    }
    else {
      agrementsServices.updateAgreement(this.props.authtoken, this.props.company, data, this.props.affitoken.affitokenEntered).then(({ status, message }) => {
        if (status !== false) {
          notification.success({
            message: 'Exitoso',
            description: message,
            duration: 5
          });

          setTimeout(() => {
            this.props.handler()
            this.setState({
              ...this.state,
              loading: false
            })
          }, 1000);

        }
        else {
          notification.error({
            message: 'Error',
            description: 'Ha ocurrido un error, intenta nuevamente',
            duration: 5
          });

          this.setState({
            ...this.state,
            loading: false
          })
        }
      }).catch((error) => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
    }

  }




  renderTypeSection() {
    if (this.state.typeAgreement !== 'prontoPago') {
      let preferenceTitle = (
        <Row className="agree-mod-filters-marginbot">
          <Col span={8}>
            Elige tu preferencia:
          </Col>
          <Col span={16}>
            <Radio.Group onChange={this.radioChange} value={this.state.preference}>
              <Radio value={'T'}>Tasa</Radio>
              <Radio value={'F'}>Entidad financiera</Radio>
            </Radio.Group>
          </Col>
        </Row>)

      return preferenceTitle
    }
  }

  renderAccountsTitle() {
    if (this.state.typeAgreement !== 'prontoPago') {
      if (this.state.preference && this.state.preference !== '') {
        return (
          <Row className="agree-mod-filters-marginbot">
            <Col span={18}>{this.state.preference === 'T' ? 'SE REALIZA LA ACEPTACIÓN CON LA TASA MÁS BAJA DISPONIBLE - TENER EN CUENTA QUE ALGUNAS ENTIDADES FINANCIERAS PUEDEN TENER LA OPERCIÓN CON RECURSO DEL PROVEEDOR' : 'ORDENA SEGÚN TU PREFERENCIA'}</Col>
            <Col span={6}>{this.state.preference === 'F' ? 'CUENTA' : ''}</Col>
          </Row>
        )
      }
    }
  }

  renderAgreementSelector() {
    return (
      <Select size="small" defaultValue={0} value={`${this.state.selectedNewAgreement}`} onChange={(e) => this.changeSelectedNewAgreement(e)}>
        {this.props.newAgreements.map((newAgreement, index) => (
          <Option title={newAgreement.nombreConvenio} key={index}>{newAgreement.nombreConvenio}</Option>
        ))}
      </Select>
    )
  }

  renderPreferenceSection() {
    if (this.state.typeAgreement !== 'prontoPago') {
      if (this.state.preference === 'T') {
        return (<Row className="account-container">
          <Col span={5} >
            <div className="select-label">
              Elegir cuenta bancaria:
          </div>
          </Col>
          <Col span={12}>
            <div className="select-container">
              <Select size="small" className="select-wide" defaultValue={this.state.userAccounts[0].scCuenta} value={this.state.selectedAccount} onChange={(e) => this.changeAccountbyRate(e)}>
                {this.state.userAccounts.map((userAccount) => (
                  userAccount.scCuenta === '0' ?
                    <Option title={userAccount.dsNombreCuenta} key={userAccount.scCuenta}>{userAccount.dsNombreCuenta}</Option>
                    :
                    <Option title={`${userAccount.nombreBanco} - ${userAccount.nroCuenta} - ${userAccount.tipoCuenta}`} key={userAccount.scCuenta}>{`${userAccount.nombreBanco} - ${userAccount.nroCuenta} - ${userAccount.tipoCuenta}`}</Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>)
      }
      if (this.state.preference === 'F') {
        return this.state.accounts.map((item, index) => {
          return (
            <Row className="agree-mod-convenio-center" key={item.scFondeador}>
              <Col span={3}><InputNumber key={`${index}_inputnum_aggrement`} min={0} value={index + 1} step={-1} onChange={(e) => { this.changeOrder(index + 1, e); }} /></Col>
              <Col span={5}>{item.nombre}</Col>
              <Col span={2}>
                {/*VACIO CONCIENTEMENTE*/}
              </Col>
              <Col span={5}>
                <Popover data-html="true" content={
                <div>
                <p>Tasa: {item.descFond.tasaVigente.toFixed(2)}</p>
                <p>Dias de pago: {item.descFond.diasPago.join().replace(',', ', ')}</p>
                <p>Tipo cupo: {item.descFond.tipoCupo}</p>
                </div>} title="Detalles">
                  <InfoCircleFilled className="agree-icon-size agree-color-prim" />
                </Popover>
              </Col>
              <Col span={9}>
                <Select size="small" className="select-wide" defaultValue={this.state.userAccounts[0].scCuenta} value={item.scCuenta} onChange={(e) => this.changeAccountbyEntity(e, index)}>
                  {this.state.userAccounts.map((userAccount) =>
                    (
                      userAccount.scCuenta === '0' ?
                        <Option title={userAccount.dsNombreCuenta} key={userAccount.scCuenta}>{userAccount.dsNombreCuenta}</Option>
                        :
                        <Option title={`${userAccount.nombreBanco} - ${userAccount.nroCuenta} - ${userAccount.tipoCuenta}`} key={userAccount.scCuenta}>{`${userAccount.nombreBanco} - ${userAccount.nroCuenta} - ${userAccount.tipoCuenta}`}</Option>
                    )
                  )}
                </Select>
              </Col>
            </Row>
          )
        })
      }
    }
  }

  render() {
    return (

      <Row className="agree-marginbot">
        <Card className="card-container card-radius card-shadow">
          <Row className="agree-mod-name">
            <Col span={12} className="agree-form-name-col">
              {this.state.name}
            </Col>
            {this.state.isNew ?
              <Col span={12} className="agree-form-name-reset">
                Seleccionar Pagador : {this.renderAgreementSelector()}
              </Col>
              : null}
          </Row>
          <Row className="agree-mod-filters-marginbot">
            <Col span={8}>
              Estado:
              </Col>
            <Col span={16}>
              <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" className="activeSwitch" defaultChecked={this.state.checked} onChange={(checked, event) => { this.siwtchChange(checked) }} />
            </Col>
          </Row>

          <Row className="agree-mod-filters-marginbot">
            <Col span={8}>
              Días para la aceptación automática:
              </Col>
            <Col span={16}>
              <InputNumber min={0} defaultValue={'0'} value={this.state.daysAuto || 0} onChange={(e) => this.changeDaysAuto(e)} />
            </Col>
          </Row>

          <Row className="agree-mod-filters-marginbot">
            <Col span={8}>
              Días de aceptación no hábil:
              </Col>
            <Col span={16}>
              <Select size="small" defaultValue="A" value={this.state.daysBusiness} onChange={(e) => this.changeDaysBusiness(e)}>
                <Option title={'ANTERIOR'} key={'A'}>{'ANTERIOR'}</Option>
                <Option title={'POSTERIOR'} key={'P'}>{'POSTERIOR'}</Option>
              </Select>
            </Col>
          </Row>

          {this.renderTypeSection()}
          {this.renderAccountsTitle()}
          {this.renderPreferenceSection()}


          <Row className="agree-mod-convenio-center">
            <AffitokenValidator disableAutoGenerate={true} />
          </Row>

          <Row className="agree-mod-convenio-center">
            <Spin tip="Loading..." spinning={this.state.loading}>
              <Button className="btn-next agree-mod-guardar btn-card-radius" onClick={() => this.saveChanges()}>Guardar cambios</Button>
            </Spin>
          </Row>

        </Card>
      </Row >)
  }
}


ConfigAgreement.propTypes = {
  authtoken: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  isNew: PropTypes.bool,
  daysAuto: PropTypes.number,
  daysBusiness: PropTypes.string,
  accounts: PropTypes.array
}

const mapStateToProps = (state) => {
  return {
    authtoken: state.auth.auth.access_token,
    company: state.billing.company,
    affitoken: state.affitoken
  }
}


export default connect(mapStateToProps, {})(ConfigAgreement);