import { FormOutlined } from '@ant-design/icons';
import { Col, Divider, notification, Row, Spin } from 'antd';
import React, { Component } from 'react';
import TableReport from '../../../../../components/tableReport/tableReport';
import { lotes } from '../../../../../services/fondeador/operationServices';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';
import '../../operation.scss';
import AchTransacions from '../achTransactions/achTransactions';
import AchHistoryFilterForm from './achHistoryFilterForm/achHistoryFilterForm';

const initialState = {
  loading: false,
  form: {
    scLoteAch: '',
    fechaDesde: '',
    fechaHasta: '',
  },
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  scLoteAchSelect: '',
};

class AchHistory extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'No Lote',
        dataIndex: 'scLoteACH',
        sorter: (a, b) => sorterText(a.scLoteACH, b.scLoteACH),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA INGRESO',
        dataIndex: 'fechaIngreso',
        sorter: (a, b) => sorterDate(a.fechaIngreso, b.fechaIngreso),
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL',
        dataIndex: 'nmTotal',
        sorter: (a, b) => a.nmTotal - b.nmTotal,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: 'DETALLE',
        render: (text, record) => (
          <FormOutlined
            title='Ver transacciones'
            onClick={() => this.getTransaccion(record)}
            className='info'
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  getTransaccion = (record) => {
    this.setState({
      ...this.state,
      scLoteAchSelect: record.scLoteACH,
    });
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scLoteAch: value.scLoteAch,
        fechaDesde: value.fechaRango[0],
        fechaHasta: value.fechaRango[1],
      },
      scLoteAchSelect: initialState.scLoteAchSelect,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await lotes(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha desde y fecha hasta es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      scLoteAchSelect,
    } = this.state;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <AchHistoryFilterForm
            companyId={this.props.company.company}
            handleSubmit={this.handleSubmit}
          />

          <div className='table-container'>
            <Row gutter={30} className='bill-selection'>
              <Col span={12}>
                <p className='title'>Lista de lotes</p>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <TableReport
                  currentPage={currentPage}
                  limit={limit}
                  total={total}
                  resumen={resumen}
                  columns={this.columnas}
                  data={resultado}
                  handleChange={this.handleChange}
                />
              </Col>
            </Row>
          </div>
        </Spin>
        {scLoteAchSelect !== '' && (
          <AchTransacions
            companyId={this.props.company.company}
            authToken={this.props.authToken}
            scLoteAch={scLoteAchSelect}
          />
        )}
      </div>
    );
  }
}

export default AchHistory;
