import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import './dashboard.scss';
import avatar from '../../../assets/img/assisted-man.png';

class Dashboard extends Component {
  componentDidMount() {
    this.props.menuHandler('1');
  }
  render() {
    return (
      <div className='dashboard-style'>
        <Card className='card-shadow card-radius default-border welcome-message '>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <p className='dash-welcome'>
                <span>¡Hola {this.props.name}! </span>Bienvenido a Affirmatum
              </p>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify='center' align='middle'>
            <Col span={9}>
              <img className='dash-avatar' src={avatar} alt='' />
            </Col>
            <Col span={15}>
              <p>
                Te presentamos la nueva versión de Affirmatum ¿Quieres usarlo?
                Todas tus opciones de siempre en un nuevo menú:
                <br />
                <br />
                <ul>
                  <li>
                    Facturas: te permite cargar las facturas que quieres ofrecer
                    a tus proveedores y cargar los pagos realizados a las
                    entidades financieras.
                  </li>
                  <li>
                    Soporte: puedes visualizar las facturas que Exponencial le
                    ha emitido a tu compañía.
                  </li>
                  <li>
                    Proveedores: encuentra la información de los proveedores
                    asociados a tu compañía que están inscritos en Affirmatum.
                  </li>
                  <li>
                    Reportes: encuentra los diferentes reportes a los cuales
                    puedes acceder.
                  </li>
                  <li>
                    Ayuda: en esta sección encuentra tutoriales y nuestros
                    canales de atención.
                  </li>
                </ul>
                ¡Esperamos que disfrutes esta nueva experiencia!
                <br />
              </p>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default Dashboard;
