import React from 'react';
import './helpcenter.scss';
import { Row, Col, Card, Typography, Modal, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import helpImage1 from '../../../assets/img/help_center1.png';
import helpImage2 from '../../../assets/img/help_center2.png';
import helpImage3 from '../../../assets/img/help_center3.png';
import contactImage1 from '../../../assets/img/helpContact/phone.png';
import contactImage2 from '../../../assets/img/helpContact/wapp.png';
import contactImage3 from '../../../assets/img/helpContact/email.png';
import {
  PHONE_NUMBER_INIT,
  WHATSAPP_CORP_INIT,
  EMAIL_INIT,
  PHONE_NUMBER_INIT_NO_FORMAT,
  WHATSAPP_CORP_INIT_NO_FORMAT,
} from '../../../utilities/messages';

import Tutorial from '../../../components/tutorial';
import TutorialIframe from '../../../components/tutorialIframe';
import history from '../../../services/history';
import { Link } from 'react-router-dom';

const { Title } = Typography;

class HelpCenter extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    showModal1: false,
    showModal2: false,
    showContactModal: false,
    tutorial: 1,
  };

  componentDidMount() {
    this.props.menuHandler('20');
  }

  showModal(index, tutorial) {
    switch (index) {
      case 1:
        {
          this.setState({
            ...this.state,
            showModal1: true,
          });
        }
        break;
      case 2: {
        this.setState({
          ...this.state,
          showModal2: true,
          tutorial: tutorial,
        });
        break;
      }
      default: {
        this.setState({
          ...this.state,
          showModal1: true,
        });
      }
    }
  }

  renderTutorials() {
    switch (this.props.module) {
      case 'proveedor': {
        return (
          <>
            <li>
              <Link
                to='#'
                onClick={() => {
                  this.showModal(1);
                }}
              >
                Información general
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial1.pdf`}
                target='_blank'
              >
                Factura de comisiones
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial2.pdf`}
                target='_blank'
              >
                Facturas anticipadas
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial3.pdf`}
                target='_blank'
              >
                Recuperar contraseña
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial4.pdf`}
                target='_blank'
              >
                Ficha cliente
              </Link>
              <br />
            </li>
          </>
        );
      }
      case 'pagador': {
        return (
          <>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial5.pdf`}
                target='_blank'
              >
                Consultar proveedores
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial6.pdf`}
                target='_blank'
              >
                Cargas masivas
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial7.pdf`}
                target='_blank'
              >
                Informes
              </Link>
              <br />
            </li>
          </>
        );
        break;
      }
      case 'fondeador': {
        return (
          <>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial8.pdf`}
                target='_blank'
              >
                Consultar Desembolso
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial9.pdf`}
                target='_blank'
              >
                Consultar y realizar operaciones
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial10.pdf`}
                target='_blank'
              >
                Consultar información de proveedores
              </Link>
              <br />
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/tutorial11.pdf`}
                target='_blank'
              >
                Consultar reportes
              </Link>
              <br />
            </li>
          </>
        );
      }
      default: {
        return '';
      }
    }
  }

  renderVideoTutorials() {
    switch (this.props.module) {
      case 'proveedor': {
        return (
          <>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://vimeo.com/eunocolombia/review/723132044/c8f4e79bf1`}
            >
              1. ¿Eres nuevo? ¿Cómo ingresar por primera vez a Affirmatum?
              <br />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://vimeo.com/eunocolombia/review/730111548/fb8889d883 `}
            >
              2. ¿Cómo configurar mi affitoken?
              <br />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://vimeo.com/eunocolombia/review/723845332/901cfce6e8`}
            >
              3. ¿Cómo negociar mis facturas?
              <br />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://vimeo.com/eunocolombia/review/724072065/cefd2958e8`}
            >
              4. ¿Cómo programar mis facturas?
              <br />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://vimeo.com/eunocolombia/review/723222979/87101b3eff`}
            >
              5. ¿Cómo activar el descuento automático?
              <br />
            </a>
          </>
        );
      }
      case 'fondeador': {
        return (
          <>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://www.youtube.com/watch?v=oVFmcE9fmiA`}
            >
              1. ¿Qué es Affirmatum?
              <br />
            </a>
          </>
        );
      }
      case 'pagador': {
        return (
          <>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://www.youtube.com/watch?v=oVFmcE9fmiA`}
            >
              1. ¿Qué es Affirmatum?
              <br />
            </a>
          </>
        );
      }
      default: {
        return '';
      }
    }
  }

  modalClose = () => {
    this.setState({
      ...this.state,
      showModal1: false,
      showModal2: false,
    });
  };

  render() {
    return (
      <div className='content-margin help-content'>
        <Tutorial showModal={this.state.showModal1} onClose={this.modalClose} />
        <TutorialIframe
          showModal={this.state.showModal2}
          onClose={this.modalClose}
          tutorial={this.state.tutorial}
        />

        <Row justify={'center'}>
          <Col>
            <img src={helpImage1} alt=''></img>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col>
            <Title className='help-title'>
              Queremos ayudarte a tener <br />
              la mejor experiencia.
            </Title>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Card className='card-radius card-shadow'>
              <Row>
                <Col span={8}>
                  <img src={helpImage2} alt=''></img>
                </Col>
                <Col span={16}>
                  <Title level={4} className='help-title'>
                    Aprende a usar mejor Affirmatum
                  </Title>
                  <Row justify={'end'}>
                    <Col span={24}>
                      <ul className='tutorials-list'>
                        {this.renderTutorials()}
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card className='card-radius card-shadow'>
              <Row>
                <Col span={8}>
                  <img src={helpImage3} alt=''></img>
                </Col>
                <Col span={16}>
                  <Title level={4} className='help-title'>
                    Preguntas que son frecuentes
                  </Title>
                  <Row justify={'end'}>
                    <a
                      onClick={() => {
                        history.push(`${process.env.PUBLIC_URL}/faq`);
                      }}
                    >
                      Ver preguntas
                    </a>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={24} justify={'center'} className='help-top-margin'>
          <Col span={12}>
            <Card className='card-radius card-shadow'>
              <Row justify={'center'}>
                <Col span={24}>
                  <Row justify='center' align='middle'>
                    <Title level={4} className='help-title'>
                      Videotutoriales
                    </Title>
                  </Row>
                  <Row
                    justify='center'
                    align='middle'
                    className='contact-container'
                  >
                    <Col span={24}>{this.renderVideoTutorials()}</Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card className='card-radius card-shadow'>
              <Row justify={'center'}>
                <Col span={24}>
                  <Row justify='center' align='middle'>
                    <Title level={4} className='help-title'>
                      Déjanos asesorarte
                    </Title>
                  </Row>
                  <Row
                    justify='center'
                    align='middle'
                    className='contact-container'
                  >
                    <Col span={8}>
                      <a
                        target='_blank'
                        href={`tel:${PHONE_NUMBER_INIT_NO_FORMAT}`}
                      >
                        <div className='contact-item'>
                          <div className='help-contact-image-wrapper'>
                            <img
                              src={contactImage1}
                              className='help-contact-image'
                            />
                          </div>
                          <p className='text'>Teléfono</p>
                          <p className='data'>{PHONE_NUMBER_INIT}</p>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <a
                        target='_blank'
                        href={`https://wa.me/${WHATSAPP_CORP_INIT_NO_FORMAT}`}
                      >
                        <div className='contact-item'>
                          <div className='help-contact-image-wrapper'>
                            <img
                              src={contactImage2}
                              className='help-contact-image'
                            />
                          </div>
                          <p className='text'>Whatsapp Corporativo</p>
                          <p className='data'>{WHATSAPP_CORP_INIT}</p>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <a
                        target='_blank'
                        href={`mailto:${this.props.email || EMAIL_INIT}`}
                      >
                        <div className='contact-item'>
                          <div className='help-contact-image-wrapper'>
                            <img
                              src={contactImage3}
                              className='help-contact-image'
                            />
                          </div>
                          <p className='text'>Correo Electrónico</p>
                          <p className='data'>
                            {this.props.email || EMAIL_INIT}
                          </p>
                        </div>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row justify={'center'} className='help-top-margin'>
          <p>
            Exponencial Confirming S.A.S. Todos los derechos reservados ® 2019
          </p>
        </Row>
        <Row justify={'center'}>
          <a
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/tyc`);
            }}
          >
            Ver políticas de uso
          </a>
        </Row>
      </div>
    );
  }
}

export default HelpCenter;
