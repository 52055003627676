import React, { Component } from 'react';

import {
  Row,
  Col,
  Card,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Checkbox,
} from 'antd';
import {
  listarFacturasEndosadas,
  listarProveedores,
  listarFondeadores,
  listarConvenios,
  grabarFacturasEndosadas,
  grabarFacturasEndosadasAll,
} from '../../../../services/admin/receiptedInvoicesService';
import './receiptedInvoices.scss';
import { connect } from 'react-redux';
import { REPORTS_DATES_REQUIRED_ERROR } from '../../../../utilities/messages';

const { Option } = Select;

let arrIndx = [];

class ReceiptedInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: {
        fechaDesdeCompra: '',
        fechaHastaCompra: '',
        scFondeador: '',
        scProveedor: '',
        scConvenio: '',
        count: 0,
        offset: 0,
        currentPage: 1,
        limit: 10,
      },
      resultado: [],
      loading: false,
      proveedores: [],
      fondeadores: [],
      convenios: [],
      modalVisible: false,
      facturas: [],
    };
    this.formRef = React.createRef();
  }

  sorter = (a, b) =>
    isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b;

  componentDidMount() {
    this.props.menuHandler('45');
    this.loadFondeadores();
    this.loadProveedores();
    this.loadConvenios();
  }

  loadFondeadores() {
    listarFondeadores(this.props.authToken).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          fondeadores: response.data,
        });
      }
    });
  }

  loadProveedores() {
    listarProveedores(this.props.authToken).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          proveedores: response.data,
        });
      }
    });
  }

  loadConvenios() {
    listarConvenios(this.props.authToken).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          convenios: response.data,
        });
      }
    });
  }

  columnas = {
    pageData: [
      {
        title: 'CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => a.nombreConvenio - b.nombreConvenio,
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => a.nombrePagador - b.nombrePagador,
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => a.nombreProveedor - b.nombreProveedor,
        showSorterTooltip: false,
      },
      {
        title: '# FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => a.cdFactura - b.cdFactura,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA EMISIÓN',
        dataIndex: 'feEmision',
        sorter: (a, b) => a.feEmision - b.feEmision,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENTA',
        dataIndex: 'feCompra',
        sorter: (a, b) => a.feCompra - b.feCompra,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR BASE FACTURA',
        dataIndex: 'vrFactura',
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: 'ENDOSO EXPONENCIAL',
        dataIndex: 'snEndosoExpo',
        render: (object, arr, index) => (
          <Checkbox
            checked={
              this.state.resultado[index]['snEndosoExpo'] == 'Si' ? true : false
            }
            onChange={(e) => {
              let endoso = e.target.checked == true ? 'Si' : 'No';
              this.state.resultado[index]['snEndosoExpo'] = endoso;
              this.setState({
                ...this.state,
              });

              if (arrIndx.includes(index) == false) {
                arrIndx.push(index);
                this.setState({
                  ...this.state,
                  facturas: [
                    ...this.state.facturas,
                    this.state.resultado[index],
                  ],
                });
              }
            }}
          ></Checkbox>
        ),
        sorter: (a, b) => a.cdSnDescAuto - b.cdSnDescAuto,
        showSorterTooltip: false,
      },
    ],
  };

  getData = (currentPage) => {
    //if (currentPage == 1) { this.state.pageData.currentPage = 1; this.state.pageData.offset = 0 }
    this.state.resultado = [];
    // if (this.validateForm()) {
    this.setState({
      ...this.state,
      loading: true,
    });
    listarFacturasEndosadas(this.props.authToken, this.state.pageData).then(
      (response) => {
        this.setResponse(response);
      }
    );
    //}
  };

  setResponse = (response) => {
    // this.state.pageData = response.metadata
    this.state.resultado = response.data;
    this.setState({
      ...this.state,
      pageData: {
        ...this.state.pageData,
        ...response.metadata,
      },
      loading: false,
    });
  };

  validateForm = () => {
    let filtros = this.state.pageData;
    let fechas = true;
    Object.keys(filtros).forEach((nombre) => {
      if (nombre !== 'resultado') {
        if (
          (nombre.includes('Desde') || nombre.includes('Hasta')) &&
          (filtros[nombre] === '' || filtros[nombre] === null)
        ) {
          fechas = false;
        }
      }
    });
    if (!fechas) {
      notification.error({
        message: 'Las fechas son obligatorias',
        description: REPORTS_DATES_REQUIRED_ERROR,
        duration: 4,
      });
    }
    return fechas;
  };

  handleOnChange = (pagination, filters, sorter, extra) => {
    if (this.state.pageData.currentPage != pagination.current) {
      this.state.pageData.offset =
        pagination.pageSize * (pagination.current - 1);
      this.state.pageData.currentPage = pagination.current;
    }

    if ((this.state.pageData.limit || 10) != pagination.pageSize) {
      this.state.pageData.limit = pagination.pageSize;
      this.state.pageData.offset = 0;
      this.state.pageData.currentPage = 1;
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.state.pageData.sort = order == 'descend' ? `-${field}` : field;
    }
    this.getData();
  };

  grabarFacturas = () => {
    this.setState({ ...this.state, loading: true });
    if (arrIndx.length > 0) {
      grabarFacturasEndosadas(this.props.authToken, {
        facturas: this.state.facturas,
      }).then((response) => {
        if (response.message) {
          notification.success({
            message: 'Actualizado',
            description: response.message,
            duration: 4,
          });
        }
      });
    } else {
      grabarFacturasEndosadasAll(
        this.props.authToken,
        this.state.pageData
      ).then((response) => {
        if (response.message) {
          notification.success({
            message: 'Actualizado',
            description: response.message,
            duration: 4,
          });
        }
      });
    }
    this.setState({
      ...this.state,
      loading: false,
    });
    this.getData();
  };

  render() {
    const { type } = this.props;
    return (
      <Spin tip='Cargando...' spinning={this.state.loading}>
        <div className='receiptedInvoices-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <p className='title'>Búsqueda</p>
              <Form name='ACHoperation_form' layout='vertical'>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label='FECHA DESDE'>
                      <DatePicker
                        name={this.state.pageData.fechaDesdeCompra}
                        value={this.state.pageData.fechaDesdeCompra}
                        onChange={(moment) =>
                          this.setState({
                            ...this.state,
                            pageData: {
                              ...this.state.pageData,
                              fechaDesdeCompra: moment,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='FECHA HASTA'>
                      <DatePicker
                        name={this.state.pageData.fechaHastaCompra}
                        value={this.state.pageData.fechaHastaCompra}
                        onChange={(moment) =>
                          this.setState({
                            ...this.state,
                            pageData: {
                              ...this.state.pageData,
                              fechaHastaCompra: moment,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name='scFondeador' label='FONDEADOR'>
                      <Select
                        value={this.state.pageData.scFondeador}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            pageData: {
                              ...this.state.pageData,
                              scFondeador: e,
                            },
                          });
                        }}
                      >
                        {Array.isArray(this.state.fondeadores) &&
                          this.state.fondeadores.map((item) => (
                            <Select.Option
                              key={item.codigo}
                              value={item.codigo}
                            >
                              {item.descripcion}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='scProveedor' label='PROVEEDOR'>
                      <Select
                        value={this.state.pageData.scProveedor}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            pageData: {
                              ...this.state.pageData,
                              scProveedor: e,
                            },
                          });
                        }}
                      >
                        {Array.isArray(this.state.fondeadores) &&
                          this.state.fondeadores.map((item) => (
                            <Select.Option
                              key={item.codigo}
                              value={item.codigo}
                            >
                              {item.descripcion}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name='cdFactura' label='NÚMERO DE FACTURA'>
                      <Input
                        value={this.state.pageData.cdFactura}
                        placeholder={this.state.pageData.cdFactura}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            pageData: {
                              ...this.state.pageData,
                              cdFactura: e.target.value,
                            },
                          });
                          this.handleNumeroCalendariosPago(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='scConvenio' label='CONVENIO'>
                      <Select
                        value={this.state.pageData.scConvenio}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            pageData: {
                              ...this.state.pageData,
                              scConvenio: e,
                            },
                          });
                        }}
                      >
                        {Array.isArray(this.state.convenios) &&
                          this.state.convenios.map((item) => (
                            <Select.Option
                              key={item.codigo}
                              value={item.codigo}
                            >
                              {item.descripcion}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        className='form-btn btn-radius'
                        type='primary'
                        htmlType='submit'
                        onClick={() => {
                          this.getData();
                        }}
                      >
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div>
              <p className='ptable'>Listado de convenios</p>
            </div>
            <div className='table-container'>
              <Divider />
              <Table
                pagination={{
                  defaultPageSize: 10,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} de ${total} registros`,
                  current: this.state.pageData.currentPage,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '30'],
                  total: this.state.pageData.count,
                }}
                onChange={this.handleOnChange}
                columns={this.columnas.pageData}
                dataSource={this.state.resultado}
              ></Table>
            </div>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item>
                  <Button
                    className='btn btn-green'
                    type='primary'
                    htmlType='submit'
                    onClick={() => {
                      this.grabarFacturas();
                    }}
                  >
                    Grabar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(ReceiptedInvoices);
