import axios from 'axios'
import { middleWare } from '../middleware'
import queryString from 'query-string'

const baseUrl = process.env.REACT_APP_SERVICES_URL_FONDEADOR

export const listar = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros)
  return middleWare(axios({
    url: `${baseUrl}/api/${scFondeador}/proveedor/listar-proveedores?${queryString.stringify(filtros)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const excel = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros)
  return middleWare(axios({
    url: `${baseUrl}/api/${scFondeador}/facturas/facturas-tramites/exportar/xlsx?${queryString.stringify(filtros)}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarExcel = (authtoken, scFondeador, filtros) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scFondeador}/proveedor/listar-proveedores/exportar/xlsx?${queryString.stringify(filtros)}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}


export const datos = (authtoken, scFondeador, scProveedor) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scFondeador}/proveedor/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const documentos = (authtoken, scFondeador, scProveedor) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scFondeador}/documentos/proveedor/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const descargarDocumento = (authtoken, scFondeador, scProveedor, cdDocumento) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scFondeador}/documentos/proveedor/${scProveedor}/descargar/${cdDocumento}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const cuentas = (authtoken, scFondeador, scProveedor, cdDocumento) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scFondeador}/cuentas/listar-cuentas-proveedor/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach(nombre => {
    if (nombre !== 'resultado') {
      if ((nombre.includes("Desde") || nombre.includes("Hasta")) && (typeof filtros[nombre] === 'object')) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD')
      }
    }
  });
  return filtros
}