import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listaParametros = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/parametros/lista-parametros?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const editarParametro = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/parametros/actualizar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};
