import { Button, Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';
import React, { Component } from 'react';
import { requiredValueMessage } from '../../../../../utilities/admin_validation_messages';
import { isEqualsToObject } from '../../../../../utilities/compare';
import {
  DATE_FORMAT,
  DATE_POST_FORMAT,
} from '../../../../../utilities/messages';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../../utilities/textTools';

export const initialFormValue = {
  scFactura: '',
  fePago: '',
  vrPago: '',
};

const rules = {
  fePago: [{ required: true, message: requiredValueMessage() }],
  vrPago: [{ required: true, message: requiredValueMessage() }],
};

class CheckPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish({
      ...formValue,
      fePago: formValue.fePago.format(DATE_POST_FORMAT),
    });
  };

  componentDidMount() {
    if (this.props.data) {
      this.form.current.setFieldsValue({
        ...this.props.data,
      });
    }
  }

  componentDidUpdate(props, state) {
    if (props.reset !== this.props.reset && this.props.reset) {
      this.form.current.resetFields();
    }

    if (!isEqualsToObject(props.data, this.props.data)) {
      this.form.current.setFieldsValue({
        ...this.props.data,
      });
    }
  }

  render() {
    return (
      <div className='header-container'>
        <Form layout='vertical' ref={this.form} onFinish={this.onFinish}>
          <Form.Item name='scFactura' hidden='true'>
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='fePago'
                label='Fecha Pago'
                rules={rules['fePago'] || ''}
              >
                <DatePicker format={DATE_FORMAT} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='vrPago'
                label='Valor Pago'
                rules={rules['vrPago'] || ''}
              >
                <InputNumber
                  formatter={(value) => currencyInputFormat(value)}
                  parser={(value) => currencyInputParser(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default CheckPaymentForm;
