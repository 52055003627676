import React, { Component } from 'react';

import { Row, Col, Divider, Spin, Space } from 'antd';
import { BorderOutlined, CheckOutlined } from '@ant-design/icons';
import { listaConveniosProveedor } from '../../../../../services/admin/suppliersService';
import TableReport from '../../../../../components/tableReport/tableReport';
import {
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';

const initialState = {
  loading: false,
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  sort: '',
};

class SupplierAgreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => sorterText(a.nombreConvenio, b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoRegistro',
        sorter: (a, b) => sorterText(a.dsEstadoRegistro, b.dsEstadoRegistro),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE INGRESO',
        dataIndex: 'feModifica',
        sorter: (a, b) => sorterDate(a.feModifica, b.feModifica),
        showSorterTooltip: false,
      },
      {
        title: 'D.A.',
        dataIndex: 'cdSnDescAuto',
        render: (record) =>
          record === 'S' ? (
            <CheckOutlined
              style={{ fontSize: '24px', color: '#09d96d' }}
              twoToneColor='#09d96d'
            />
          ) : (
            <BorderOutlined style={{ fontSize: '24px' }} />
          ),
        sorter: (a, b) => sorterText(a.cdSnDescAuto, b.cdSnDescAuto),
        showSorterTooltip: false,
      },

      {
        title: 'DÍAS D.A.',
        dataIndex: 'nmDiasDescAuto',
        sorter: (a, b) => a.nmDiasDescAuto - b.nmDiasDescAuto,
        showSorterTooltip: false,
      },
      {
        title: 'TASA D.',
        dataIndex: 'poDescuentoProveedor',
        sorter: (a, b) => a.cdTipoTasaProveedor - b.cdTipoTasaProveedor,
        render: (text) => <span>{percentageFormat(text)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'TIPO TASA',
        dataIndex: 'dsTipoTasaProveedor',
        sorter: (a, b) => a.dsTipoTasaProveedor - b.dsTipoTasaProveedor,
        showSorterTooltip: false,
      },
      {
        title: '% COMISIÓN',
        dataIndex: 'poParticipacionCom',
        sorter: (a, b) => a.poParticipacionCom - b.poParticipacionCom,
        render: (text) => <span>{percentageFormat(text)}</span>,
        showSorterTooltip: false,
      },
    ];
  }

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    const { authToken, scPersona } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaConveniosProveedor(
        authToken,
        scPersona,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { limit, offset, sort } = this.state;

    return {
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { loading, resultado, currentPage, limit, total } = this.state;
    const { children } = this.props;
    return (
      <Spin tip='Cargando...' spinning={loading}>
        <Row gutter={[16, 32]} align='middle'>
          <Col flex='auto'>
            <Space align='baseline' className='sub-title'>
              Lista de Convenios
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col flex='auto'>
            <div className='table-container'>
              <TableReport
                currentPage={currentPage}
                limit={limit}
                total={total}
                columns={this.columnas}
                data={resultado}
                handleChange={this.handleChange}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>{children}</Row>
      </Spin>
    );
  }
}

export default SupplierAgreements;
