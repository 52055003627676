import React, { Component } from 'react'
import { Card, Tabs } from 'antd';
import './reports.scss'
import Content from './content/report'

class Reports extends Component {

  constructor(props) {
    super(props)

  }


  componentDidMount() {
    this.props.menuHandler('92')
  }

  changeTab = activeKey => {
    this.setState({
      ...this.state,
      activeTab: activeKey
    })
  }

  render() {
    return (
      <div className="reports-style-facturas-descontadas">
        <Card className="card-shadow card-radius default-border welcome-message">
          <div className="align-center">
            <Content type="reports" />
          </div>
        </Card>
      </div>
    )
  }
}

export default Reports