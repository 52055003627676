import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs } from 'antd';
import './reports.scss';
import TransactionInProgress from './transactionInProgress/transactionInProgress';
import Supplier from './supplier/supplier';
import PayerRateAndDay from './payerRateAndDay/payerRateAndDay';
import FundProviders from './fundProviders/fundProviders';
import Accounting from './accounting/accounting';
import F4All from './f4all/f4All';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '0',
    };
  }

  componentDidMount() {
    this.props.menuHandler('60');
  }

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  render() {
    const { activeTab } = this.state;
    const { authToken } = this.props;
    return (
      <div className='reports-style-facturas-descontadas'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <Tabs activeKey={activeTab} onChange={this.changeTab}>
              <Tabs.TabPane key={0} tab='Transacciones en trámite'>
                <TransactionInProgress authToken={authToken} />
              </Tabs.TabPane>

              <Tabs.TabPane key={1} tab='Proveedor'>
                <Supplier authToken={authToken} />
              </Tabs.TabPane>

              <Tabs.TabPane key={2} tab='Pagador tasa y días'>
                <PayerRateAndDay authToken={authToken} />
              </Tabs.TabPane>

              <Tabs.TabPane key={3} tab='Fondeador'>
                <FundProviders authToken={authToken} />
              </Tabs.TabPane>
              <Tabs.TabPane key={4} tab='Contabilidad'>
                <Accounting authToken={authToken} />
              </Tabs.TabPane>
              <Tabs.TabPane key={5} tab='F4All'>
                <F4All authToken={authToken} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Reports);
