import React, { useEffect, useState } from 'react'
import { Card, Tabs, notification, Modal } from 'antd';
import './FormTipoPersona.scss';
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button } from 'antd';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import logo from '../../../assets/img/logo-side-bar.png';
import { FormPreVinculacion } from '../FormPrevinculacion/FormPrevinculacion';
import { guardar } from '../../../services/vinculacion/registroService';
import AffitokenValidator from '../../../components/affitokenValidator/affitokenValidator';
import { Token } from '../ModalToken/Token';
import {DATE_FORMAT, DATE_POST_FORMAT, USER_CREATED} from '../../../utilities/messages';
import history from '../../../services/history';
import { buscarGruposEmp, buscarPagadores } from '../../../services/vinculacion/fichaService';
import moment from 'moment';



const USERS_TITLE = 'Usuarios';

let email = "";
let data = {};

export const FormTipoPersona = () => {
  const [selectValue, setSelectValue] = useState("");
  const [spinState, setSpinState] = useState(false);
  const [modalToken, setmodalToken] = useState(false);
  const [pagadorSelect, setPagadorSelect] = useState([]);

  const redirectlogin = () =>{

    history.push(`${process.env.PUBLIC_URL}/login`);

  }


  const handleTipoPersona = (value) => {
    
    setSelectValue(value);
  }

  const handleOk  = ({token}) => {
    data = {...data,token:token};
    sendData();
    
  }

  const handleCancel = () => {
    setmodalToken(false);
  }

  const sendData = async () =>{
    try {
      if(data.verifInfo === true){
      setSpinState(true);
      const response = await guardar(data);

      notification.success({
        message: '',
        description: "Registro Exitoso.Revisa tu correo para continuar con el proceso de solicitud.",
        duration: 10,
      });

      setSpinState(false);
      setTimeout(redirectlogin, 11000)
    }
    } catch {
      setSpinState(false);
    }
    
  }
  const handleSubmit = (value) => {
    const feConst = value.feConst
    ? moment(value.feConst, DATE_FORMAT)
    : null

    let verifInfo = true

    const checkboxes = ['cdCentralesRiesgo', 'cdDatosPersonales', 'cdTermYcond']
    const estanChekeados = checkboxes.every((checkbox) => value[checkbox]);

    if (!estanChekeados) {
      notification.warning({
        message: 'Alerta',
        description: 'Para continuar es necesario aceptar todos los términos y condiciones.',
        duration: 4,
      });
      return;
    }


    if(parseInt(value.ventasAnuales) === parseInt(value.volumenFactura)){
      notification.warning({
        message: 'Alerta',
        description: <h3>Los valores<b> Ventas en el año actual </b> Y <b>  Facturación mensual</b> no pueden ser iguales</h3>,
        duration: 4,
      });
      verifInfo = false
    }
    if(parseInt(value.ventasAnuales) < parseInt(value.volumenFactura)){
      notification.warning({
        message: 'Alerta',
        description: <h3>El valor<b> Facturación mensual</b> no puede ser mayor a las <b> Ventas en el año actual</b></h3>,
        duration: 4,
      });
      verifInfo = false
    }

    for (let item in value){

      if(value[item] === false){
        value[item] = "N";
      }else if (value[item] === true){
        value[item] = "S";
      }
    }

    if (selectValue === "N"){
      value = {
        ...value,
        tipoProducto: "C"
      }
    }

      value = {
        ...value,
        cdTipoPersona:selectValue,
        verifInfo:verifInfo,
        feConst:feConst
      }
      data = value;
      //email = value.email;
      //setmodalToken(true);
      sendData();
      
  }

  const getPagadores = async () =>{
    try{
    const res = await buscarPagadores();
    setPagadorSelect(res.data);
    }
    catch{
      notification.error({
        message: 'Error',
        description: 'Error Consultando Pagadores',
        duration: 5
      })
    }
  }

  useEffect(() => {
     getPagadores();
  }, [])
  
  
  return (
  <Spin tip='Cargando...' spinning={spinState}>
    <div>
      <div className='reports-style-facturas-cargadas'>
        <Card className='card-shadow card-radius'>
          <div className='align-center'>
            <div className='logo'>
                <img className='brand-logo' src={logo} alt='' />
            </div>
            <h1>Registrate Aquí</h1>
              
                <Row gutter={16} justify="center">
                  <Col span={12}>
                    <Form.Item  label='Tipo Persona'>
                          <Select
                            placeholder="Seleccione Tipo Persona"
                            allowClear
                            defaultActiveFirstOption={false}
                            onChange = {(value) => handleTipoPersona(value)}
                          >
                            <Select.Option value={"N"}>Persona Natural</Select.Option>
                            <Select.Option value={"J"}>Persona Jurídica</Select.Option>
                          </Select>
                      </Form.Item>
                  </Col>
                </Row>
                {
                     selectValue != "" ? 
                      selectValue === "N" ? 
                        <FormPreVinculacion handleSubmit={handleSubmit}
                                            tipoForm={selectValue}
                                            pagadorSelect={pagadorSelect}
                        /> : 
                        <FormPreVinculacion handleSubmit={handleSubmit}
                                            tipoForm={selectValue}
                                            pagadorSelect={pagadorSelect}
                                            />
                      : null
                  
                }
              
          </div>
        </Card>
      </div>
      <Modal
            className='affitoken-modal'
            title='Affitoken'
            visible={modalToken}
            onCancel={handleCancel}
            footer={null}
          >
           <Token handleToken={handleOk}
                  email={email}/>
          </Modal>
    </div>
    </Spin>
  )
}
