import { Tooltip, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

const CufeText = ({ cufe }) => (
  <Tooltip title={cufe ? cufe : ''} color={'#09d96d'}>
    <Text
      style={{
        maxWidth: '90px',
      }}
      ellipsis={{
        suffix: cufe ? '.' : '...',
      }}
    >
      {cufe ? cufe : ''}
    </Text>
  </Tooltip>
);

export default CufeText;
