import React from 'react'
import Chart from 'chart.js'
import './linechart.scss'

Chart.defaults.global.defaultFontFamily = "Roboto, sans-serif";


// LineChart
class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.data.map(d => d.label);
    this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
    this.myChart.update();
  }

  componentDidMount() {
    var title = this.props.title
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'bar',
      options: {
        maintainAspectRatio: true,
        aspectRatio: 2.40,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                maxTicksLimit: 6,
                callback: function (value, index, values) {
                  return '$' + value.toLocaleString();
                }
              }
            }
          ],
          offset: true
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return `${title}: $ ${Number(tooltipItem.value).toLocaleString()}`
            },
          }
        }
      },
      data: {
        labels: this.props.data.map(d => d.label),
        datasets: [{
          label: this.props.title,
          data: this.props.data.map(d => d.value),
          backgroundColor: this.props.color,
          pointRadius: 1,
          fill: 'none'
        }]
      }
    });
  }

  render() {
    return (
      <canvas className="linechart" ref={this.canvasRef} />
    );
  }
}


export default LineChart;