import React, { Component } from 'react';
import { Spin, Modal, notification } from 'antd';
import { listaMunicipios } from '../../../../../../services/admin/suppliersService';
import {
  editarUsuario,
  listaTiposContactos,
} from '../../../../../../services/admin/payersService';
import UserForm, { initialFormValue } from '../UserForm/userForm';
import { isEqualsToObject } from '../../../../../../utilities/compare';

const USERS_TITLE = 'Usuarios';

const initialState = {
  loading: false,
  ciudadesSelect: {
    fetching: false,
    options: [],
  },
  tipoContactos: [],
};

class UserEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  getData = async () => {
    const { tipoAsociado, authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const tipoContatoResponse = await listaTiposContactos(
        authToken,
        tipoAsociado
      );

      this.setState({
        ...this.state,
        tipoContactos: tipoContatoResponse.data,
        loading: false,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnCiudadesSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { ciudadesSelect } = this.state;

      this.setState({
        ...this.state,
        ciudadesSelect: {
          ...ciudadesSelect,
          fetching: true,
        },
      });

      try {
        const response = await listaMunicipios(this.props.authToken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: '57',
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesSelect: {
            ...ciudadesSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesSelect: {
            ...ciudadesSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnFinish = async (formValue) => {
    const { onFinish, scPersona, authToken } = this.props;
    this.setState({ ...this.state, loading: true });
    formValue.tipoAsociado = this.props.tipoAsociado;

    try {
      const response = await editarUsuario(authToken, scPersona, formValue);

      notification.success({
        message: USERS_TITLE,
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        visible: false,
        loading: false,
      });

      onFinish({ ...formValue, scCuenta: response.location });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.getData();
    if (this.props.data.cdMunicipio) {
      this.setState({
        ...this.state,
        ciudadesSelect: {
          fetching: false,
          options: [
            {
              codigo: this.props.data.cdMunicipio,
              descripcion: this.props.data.dsMunicipio,
            },
          ],
        },
      });
    }
  }

  componentDidUpdate(props, state) {
    if (!isEqualsToObject(props.data, this.props.data)) {
      if (this.props.data.cdMunicipio) {
        this.setState({
          ...this.state,
          ciudadesSelect: {
            fetching: false,
            options: [
              {
                codigo: this.props.data.cdMunicipio,
                descripcion: this.props.data.dsMunicipio,
              },
            ],
          },
        });
      }
    }
  }

  render() {
    const { loading, tipoContactos, ciudadesSelect } = this.state;

    const { visible, onClose, data } = this.props;

    return (
      <Modal
        title='Editar usuario'
        visible={visible}
        className='modal-style'
        footer={null}
        onCancel={onClose}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <UserForm
            data={data}
            reset={visible}
            tipoContactos={tipoContactos}
            ciudadesSelect={ciudadesSelect}
            onCiudadesSearch={this.handleOnCiudadesSearch}
            onFinish={this.handleOnFinish}
          />
        </Spin>
      </Modal>
    );
  }
}

export default UserEditModal;
