import axios from 'axios';
import { middleWare } from '../../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarTiposEjecutadas = (authtoken, filters) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-tareas-sistema?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaTareasEjecutadas = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/tareas/lista-tareas-ejecutadas?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const exportarExcelListaTiposEjecutadas = (authtoken, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/tareas/lista-tareas-ejecutadas/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
