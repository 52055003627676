import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

/* #region Listar Opciones */

export const listaNivelesLogs = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/nivel-log`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
export const listaTipoLogs = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-log`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

/* #endregion */

/* #region Obtener Datos */

export const listarReporteLogs = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/logs/log-base-datos?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
export const listarDescargaLogs = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  console.log(filters);
  return middleWare(
    axios({
      url: `${baseUrl}/api/logs/${filters.cdLog}/descargar`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const borrarLogs = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/logs/borrar`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

/* #endregion */

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDDHHmm');
      } else if (
        filtros[nombre] &&
        nombre != 'currentPage' &&
        nombre != 'total'
      ) {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
