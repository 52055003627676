import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Select, Modal, Input, DatePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { maxLength } from '../../../../../utilities/validate/textValidate';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../utilities/messages';
import {
  formatValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';

const estados = [
  {
    key: 'A',
    description: 'Activo',
  },
  {
    key: 'I',
    description: 'Inactivo',
  },
];

const decisions = [
  {
    key: 'S',
    description: 'Si',
  },
  {
    key: 'N',
    description: 'No',
  },
];

const tipoVencimiento = [
  {
    key: 'N',
    description: 'No aplica vencimiento',
    calcular: () => {
      return null;
    },
  },
  {
    key: 'D',
    description: 'Diario',
    calcular: () => {
      return moment().add(1, 'days');
    },
  },
  {
    key: 'S',
    description: 'Semanal',
    calcular: () => {
      return moment().add(1, 'weeks');
    },
  },
  {
    key: 'M',
    description: 'Mensual',
    calcular: () => {
      return moment().add(1, 'months');
    },
  },
  {
    key: 'T',
    description: 'Trimestral',
    calcular: () => {
      return moment().add(1, 'quarters');
    },
  },
  {
    key: 'A',
    description: 'Anual',
    calcular: () => {
      return moment().add(1, 'years');
    },
  },
];

const ParamFormEdit = ({ visible, data, onClose, onOk }) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...data,
        feVencimiento: data.feVencimiento
          ? moment(data.feVencimiento, DATE_FORMAT)
          : null,
      });
    }
  }, [form, visible, data]);

  const handleSubmit = (e) => {
    onOk({
      ...e,
      feVencimiento:
        e.feVencimiento !== null ? e.feVencimiento.format(DATE_FORMAT) : null,
      dsEstadoRegistro:
        estados.find((value) => value.key === e.cdEstadoRegistro).description ||
        '',
      dsSnInicio:
        decisions.find((value) => value.key === e.cdSnInicio).description || '',
      dsTipoVencimiento:
        tipoVencimiento.find((value) => value.key === e.cdTipoVencimiento)
          .description || '',
      vencido: e.feVencimiento
        ? e.feVencimiento.isSameOrBefore(moment())
        : false,
    });
  };

  const handleChangeSelect = (e) => {
    const fecha = tipoVencimiento
      .filter((value) => value.key === e)
      .map((value) => value.calcular())
      .shift();

    form.setFieldsValue({
      feVencimiento: fecha,
    });
  };

  return (
    <Modal
      title='Editar parámetro'
      visible={visible}
      className='modal-style'
      footer={null}
      onCancel={onClose}
    >
      <div className='header-container'>
        <Form
          name='content_form'
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='codigo'
                label='CÓDIGO'
                rules={[{ required: true, message: requiredValueMessage() }]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdEstadoRegistro'
                label='ESTADO'
                rules={[{ required: true, message: requiredValueMessage() }]}
              >
                <Select>
                  {estados.map((estado) => (
                    <Select.Option key={estado.key} value={estado.key}>
                      {estado.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='valor'
                label='VALOR'
                rules={[
                  { required: true, message: requiredValueMessage() },
                  {
                    message: 'El campo no debe tener mas de 500 caracteres',
                    validator: (_, value) => maxLength(value, 500, 'VALOR'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdSnInicio'
                label='INICIO'
                rules={[{ required: true, message: requiredValueMessage() }]}
              >
                <Select>
                  {decisions.map((estado) => (
                    <Select.Option key={estado.key} value={estado.key}>
                      {estado.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoVencimiento'
                label='TIPO DE VENCIMIENTO'
                rules={[{ required: true, message: requiredValueMessage() }]}
              >
                <Select onChange={handleChangeSelect}>
                  {tipoVencimiento.map((tipo) => (
                    <Select.Option key={tipo.key} value={tipo.key}>
                      {tipo.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='feVencimiento' label='FECHA DE VENCIMIENTO'>
                <DatePicker
                  inputReadOnly
                  open={false}
                  format={DATE_FORMAT}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name='descripcion'
                label='DESCRIPCIÓN'
                rules={[{ required: true, message: requiredValueMessage() }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ParamFormEdit;
