import { InboxOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Spin, Typography, Upload } from 'antd';
import React, { Component } from 'react';
import {
  sizeFileValidation,
  typeFileValidation,
} from '../../../utilities/validate/fileValidate';
const initialValues = {
  fileList: [],
  fileMessageErrors: [],
  loading: false,
  message: '',
};

const { Dragger } = Upload;
const { Text } = Typography;

class CufeMasivoModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialValues;

    this.formRef = React.createRef();
  }

  componentDidUpdate(props, state) {
    if (props.visible !== this.props.visible) {
      this.setState({
        ...this.state,
        fileList: [],
        fileMessageErrors: [],
        message: '',
      });
    }
  }

  handleOnChangeUpload = (info) => {
    const fileList = info.fileList.length > 1 ? [info.file] : info.fileList;

    this.isValidFile(fileList);

    this.setState({
      ...this.state,
      fileList,
    });
  };

  isValidFile = async (fileList) => {
    const { edit = false } = this.props;
    let fileMessageErrors = [];

    if (fileList.length === 0 && !edit) {
      fileMessageErrors.push('debe seleccionar un archivo');
    }

    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    try {
      await typeFileValidation(fileList, [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ]);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    this.setState({ ...this.state, fileMessageErrors });
    return fileMessageErrors.length === 0;
  };

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleOnFinish = async (form) => {
    const { onFinish } = this.props;
    const { fileList } = this.state;
    let message = '';
    this.setState({
      ...this.state,
      loading: true,
      message: '',
    });

    try {
      const valid = await this.isValidFile(fileList);
      if (!valid) {
        this.setState({
          ...this.state,
          loading: false,
        });
        return;
      }

      const result = await onFinish(fileList);

      const data = result.data;
      if (
        data !== undefined &&
        data.totalFacturas !== undefined &&
        data.totalFacturasFallidas !== undefined
      ) {
        message = `Total de CUFES cargados ${
          data.totalFacturas - data.totalFacturasFallidas
        } de ${data.totalFacturas}`;
      }
    } finally {
      this.setState({
        ...this.state,
        loading: false,
        message: message,
      });
    }
  };

  render() {
    const { visible } = this.props;
    const { fileList, fileMessageErrors, loading, message } = this.state;

    return (
      <Modal
        title={`Importar Cufes`}
        visible={visible}
        onCancel={this.handleOnClose}
        width={500}
        footer={false}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <Form
            layout='vertical'
            ref={this.formRef}
            onFinish={this.handleOnFinish}
          >
            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item name='file' label='ARCHIVO EXCEL' required>
                  <Form.Item noStyle>
                    <Dragger
                      beforeUpload={() => {
                        return false;
                      }}
                      fileList={fileList}
                      onChange={this.handleOnChangeUpload}
                      maxCount={0}
                      multiple={false}
                      accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    >
                      <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                      </p>
                      <p className='ant-upload-text'>
                        Suelta el archivo aquí o selecciona alguno.
                      </p>
                    </Dragger>
                    {fileMessageErrors.map((value) => (
                      <Text type='danger'>{value}</Text>
                    ))}
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>

            <Row justify='center'>
              <Col span={4}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Grabar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {message !== '' && (
              <Row gutter={12} align='middle' justify='center'>
                <Col>
                  <span>{message}</span>
                </Col>
                <Col>
                  <Button className='form-btn' onClick={this.handleOnClose}>
                    Cerrar
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default CufeMasivoModal;
