export const formDataValidateJ = [
    {
      "name" : "Datos generales",
      "inputs" : ["cdMunElaboracion"]
    },
    {
      "name" : "Dirección",
      "inputs" : ["dirEntidad","cdPaisEntidad","cdDepEntidad","cdMunEntidad","tel1Entidad","emailEntidad"]
    },
    {
      "name" : "Representante Legal",
      "inputs" : ["prNombreLeg","prApellidoLeg","segApellidoLeg","cdTipoIdLeg","idLeg","feExpedicionLeg",
                  "lugarExpedicion","feNacimientoLeg","cdPaisNacLeg","cdMunNacLeg","cargoLeg",
                  "dirResidenciaLeg","cdPaisResidenciaLeg","cdDepResidenciaLeg","cdMunResidenciaLeg",
                  "telResidenciaLeg","emailLeg"]
    },
    {
      "name" : "Tipo de entidad y naturaleza juridica",
      "inputs" : ["cdTipoEmpresa","cdSociedadComercial","cdActividad","cdRegimen","cdTipoRetencion"]
    },
    {
      "name" : "Información tributaria",
      "inputs" : ["cdSituacionTributaria"]
    },
    {
      "name" : "Información financiera",
      "inputs" : ["nmTotalAct","nmTotalPas","nmTotalPatrimonio","nmIngresos","nmEgresos","otrosIngresos","nmOtrosIng"]
    },
    {
      "name" : "Otros",
      "inputs" : ["camaraDeComercio","docConstDeLaSociedad"]
    },
  ]

  export const formDataValidateN = [
    {
      "name" : "Datos personales",
      "inputs" : ["cdMunElaboracion","prNombre","prApellido","segApellido","cdTipoId","id","lugarExpedicion","feExpedicion"]
    },
    {
      "name" : "Datos generales",
      "inputs" : ["cdPaisNac","cdMunNac","dirResidencia","cdPaisResidencia","cdDepResidencia","cdMunResidencia","telResidencia",
                  "cdSexo","cdEstadoCivil","profesion","cargo","dirEmpresa","cdPaisEmpresa","cdDepEmpresa",
                  "cdMunEmpresa","tel1Empresa","email","feNacimiento"]
    },
    {
        "name" : "En caso de tratarse de un menor de edad",
        "inputs" : ["prNombreTutor","prApellidoTutor","segApellidoTutor","cdTipoIdTutor","idTutor",
                    "feExpTutor","lugarExpTutor","feNacimientoTutor","cdPaisNacTutor","cdDepNacTutor",
                    "cdMunNacTutor","profesionTutor","dirResidenciaTutor","cdPaisResidenciaTutor","cdDepResidenciaTutor",
                    "cdMunResidenciaTutor","cdSexoTutor","telefonoTutor","celularTutor","emailTutor"]
    },
    {
      "name" : "Información tributaria",
      "inputs" : ["cdSituacionTributaria"]
    },
    {
      "name" : "Información financiera",
      "inputs" : ["nmTotalActivos","nmTotalPasivos","nmTotalPatrimonio","nmIngresos","nmEgresos","nmOtrosIngresos","otrosIngresos"]
    },
    {
        "name" : "Operaciones en moneda extranjera",
        "inputs" : ["cdTipoOpMonExt","entidadExt","numCuentaExt","cdTipoCuentaExt","monedaExt","cdPaisExt","cdDepExt","cdMunExt"]
    },
    {
      "name" : "Otros",
      "inputs" : ["camaraDeComercio","docConstDeLaSociedad","cdActividad"]
    },
  ]