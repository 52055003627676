import React, { useEffect, useState } from 'react';
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button } from 'antd';

import moment from 'moment';
import { BUSQUEDA_TITLE,DATE_FORMAT } from '../../../../../utilities/messages';

const SELECT_PLACE_HOLDER = 'Seleccione...';

const initialValue = {
  fechaDesde: '',
  scGrupo: undefined,
  scPagador: undefined,
  scFondeador: undefined,
  cdFactura: undefined,
  scConvenio: undefined,
  cdTipoConvenio: undefined,
};

const FundingFilterForm = ({
  grupoSelect,
  handleOnSearchGrupo,
  pagadorSelect,
  handleOnSearchPagador,
  fondeadorSelect,
  handleOnSearchFondeador,
  convenioSelect,
  handleOnSearchConvenio,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
        <Col span={12}>
            <Form.Item name='scFondeador' label='Fondeador'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchFondeador(value)}
                notFoundContent={
                  fondeadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {fondeadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scGrupo' label='Grupo Empresarial'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchGrupo(value)}
                notFoundContent={
                  grupoSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {grupoSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scPagador' label='Pagador'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchPagador(value)}
                notFoundContent={
                  pagadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {pagadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scConvenio' label='Convenio'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchConvenio(value)}
                notFoundContent={
                  convenioSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {convenioSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FundingFilterForm;
