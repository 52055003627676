import { Button, Card, Col, Divider, Row, Space, Spin, } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  listarFondeadores,
  listarPagadores,
  listarConvenios,
  listarGruposEmpresariales
} from '../../../../services/admin/reports/quotasService';
import {
  listarReporteFondeo,
  listarReporteFondeoExcel,
} from '../../../../services/admin/reports/fundingReportService';
import {
  setExcel,
} from '../../../../utilities/downloadTools';
import {
  sorterText,
  currencyFormat
} from '../../../../utilities/textTools';
import FundingFilterForm from './fundingFilterForm/fundingFilterForm';
import './fundingReport.scss';

const initialState = {
  form: {
    scFondeador: '',
    scGrupo: '',
    scPagador: '',
    scConvenio: '',
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
};

class FundingReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE ENTIDAD',
        dataIndex: 'nombreGrupo',
        sorter: (a, b) => sorterText(a.nombreGrupo, b.nombreGrupo),
        showSorterTooltip: false,
      },
      {
        title: 'CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => sorterText(a.nombreConvenio, b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'TASA FOND/SPREAD',
        dataIndex: 'tasaFondSpread',
        sorter: (a, b) => sorterText(a.tasaFondSpread, b.tasaFondSpread),
        showSorterTooltip: false,
      },
      {
        title: 'TASA EXPO/SPREAD',
        dataIndex: 'tasaExpoSpread',
        sorter: (a, b) => sorterText(a.tasaExpoSpread, b.tasaExpoSpread),
        showSorterTooltip: false,
      },
      {
        title: 'CUPO ASIGNADO',
        dataIndex: 'cupo',
        sorter: (a, b) => a.cupo - b.cupo,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'LIQUIDEZ ASIGNADA',
        dataIndex: 'liquidez',
        sorter: (a, b) => a.liquidez - b.liquidez,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
    ];
    this.formSearch = React.createRef();
  }

  componentDidMount() {
    this.props.menuHandler('83');
    this.getData();
  }

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        grupoSelect: {
          ...grupoSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarConvenios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            fetching: false,
          },
        });
      }
    }
  };



  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      loading: false,
      form: {
        scFondeador: value.scFondeador,
        scPagador: value.scPagador,
        scGrupo: value.scGrupo,
        scConvenio: value.scConvenio,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });
    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });
    try {
      const response = await listarReporteFondeo(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        loading: false,
        resultado: response.data,
        total: response.metadata.count,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarReporteFondeoExcel(
        this.props.authToken,
        this.getFiltro()
      );

      setExcel(response, 'Reporte_de_Fondeo');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      fondeadorSelect,
      pagadorSelect,
      grupoSelect,
      convenioSelect,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='mandate-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <FundingFilterForm
              handleSubmit={this.handleSubmit}
              fondeadorSelect={fondeadorSelect}
              pagadorSelect={pagadorSelect}
              grupoSelect={grupoSelect}
              convenioSelect={convenioSelect}
              handleOnSearchFondeador={this.handleOnSearchFondeador}
              handleOnSearchPagador={this.handleOnSearchPagador}
              handleOnSearchGrupo={this.handleOnSearchGrupo}
              handleOnSearchConvenio={this.handleOnSearchConvenio}
            />

            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Reporte de fondeo
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                  </Space>
                </Col>
                {resultado.length > 0 ? (
                  <Col flex='none'>
                    <Space align='baseline'>
                      <Button type='link' onClick={this.exportExcel}>
                        Exportar a excel
                      </Button>
                    </Space>
                  </Col>
                ) : null}
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(FundingReport);
