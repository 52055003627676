import React, { Component } from 'react';
import ProveedorLayout from './proveedorLayout';
import FondeadorLayout from './fondeadorLayout';
import PagadorLayout from './pagadorLayout';
import { connect } from 'react-redux';
import '../styles/mainlayout.scss';
import { Select } from 'antd';
import AdminLayout from './adminLayout';
import VinculacionLayout from './vinculacionLayout';
import FacturacionLayout from './facturacionLayout';
class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModule: '',
      app: [],
    };

    this.APP_LIST = [
      {
        key: 'EXPONENCIALCONFIRMINGWEB',
        name: 'Administrativa',

        module: () => {
          let layout = <AdminLayout>{this.renderSelector()}</AdminLayout>;
          return layout;
        },
      },
      {
        key: 'ECFONDEADORWEB',
        name: 'Fondeadores',
        module: () => {
          let layout = (
            <FondeadorLayout>{this.renderSelector()}</FondeadorLayout>
          );
          return layout;
        },
      },
      {
        key: 'ECPAGADORWEB',
        name: 'Pagadores',
        module: () => {
          let layout = <PagadorLayout>{this.renderSelector()}</PagadorLayout>;
          return layout;
        },
      },
      {
        key: 'ECPROVEEDORWEB',
        name: 'Proveedores',
        module: () => {
          let layout = (
            <ProveedorLayout>{this.renderSelector()}</ProveedorLayout>
          );
          return layout;
        },
      },
      {
        key: 'ECVINCULACIONWEB',
        name: 'Vinculaciones',
        module: () => {
          let layout = (
            <VinculacionLayout>{this.renderSelector()}</VinculacionLayout>
          );
          return layout;
        },
      },
      {
        key: 'ECFACTURACIONWEB',
        name: 'Facturacion',
        module: () => {
          let layout = (
            <FacturacionLayout>{this.renderSelector()}</FacturacionLayout>
          );
          return layout;
        },
      },
    ];
  }

  componentDidMount() {
    this.getSelectedModule();
  }

  componentDidUpdate(props, state) {
    if (
      JSON.stringify(this.props.auth.authorities) !==
      JSON.stringify(props.auth.authorities)
    ) {
      this.getSelectedModule();
    }
  }

  getSelectedModule = () => {
    const { authorities } = this.props.auth;
    const appPermitidas = this.APP_LIST.filter(
      (value) => authorities && authorities.includes(value.key)
    );
    
    this.setState({
      ...this.state,
      app: appPermitidas,
      selectedModule: appPermitidas[0],
    });
  };

  handleChangeSelector = (key) => {
    const { app } = this.state;
    this.setState({
      ...this.state,
      selectedModule: app.find((value) => value.key === key),
    });
  };

  renderSelector() {
    const { app, selectedModule } = this.state;
    if (app.length > 1) {
      return (
        <div className='module-selector-wrapper'>
          <div className='module-selector'>
            <span>Aplicación</span>
            <Select
              size='small'
              className='module-selector-select'
              placeholder='Seleccione'
              value={selectedModule.key}
              onChange={this.handleChangeSelector}
            >
              {app.map((value) => (
                <Select.Option key={value.key} value={value.key}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      );
    }
  }

  renderLayout() {
    const { selectedModule } = this.state;

    let main = '';
    if (selectedModule) {
      main = selectedModule.module();
    }
    return main;
  }

  render() {
    return <>{this.renderLayout()}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(MainLayout);
