import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { Component } from 'react';

const initialState = {
  cdTipoConexion: '',
};

const estado = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
];

const tiposConexiones = [
  {
    key: 'JCO',
    object: 'directa',
    description: 'Directa',
    form: ({ rules }) => <JCOForm rules={rules} />,
  },
  {
    key: 'WS',
    object: 'webService',
    description: 'Web service',
    form: ({ rules }) => <WSForm rules={rules} />,
  },
  {
    key: 'REST',
    object: 'apiRest',
    description: 'API REST',
    form: ({ rules }) => <RestForm rules={rules} />,
  },
  {
    key: 'JDBC',
    object: 'jdbc',
    description: 'Conexión JDBC',
    form: ({ rules }) => <JdbcForm rules={rules} />,
  },
  {
    key: 'FTP',
    object: 'ftp',
    description: 'Conexión FTP',
    form: ({ rules }) => <FtpForm rules={rules} />,
  },
];

class ErpConectionForm extends Component {
  constructor(props) {
    //data, rules, companiaSelect = [], onCompaniaSearch, editarVisible, onfinish
    super(props);
    this.state = { ...initialState };
    this.form = React.createRef();
  }

  handleTipoConexionOnChange = (newCdTipoConexion) => {
    const { cdTipoConexion } = this.state;
    const currentCdTipoConecion = cdTipoConexion;
    if (currentCdTipoConecion) {
      const currentObject = tiposConexiones.find(
        (tipo) => tipo.key === currentCdTipoConecion
      ).object;

      const newObject = tiposConexiones.find(
        (tipo) => tipo.key === newCdTipoConexion
      ).object;

      const currentFormValue = this.form.current.getFieldValue(currentObject);

      this.form.current.setFieldsValue({
        [newObject]: currentFormValue,
      });
    }

    this.setState({ ...this.state, cdTipoConexion: newCdTipoConexion });
  };

  componentDidMount() {
    if (this.props.data) {
      const conexion = this.props.data;
      this.setState({
        ...this.state,
        cdTipoConexion: this.props.data.cdTipoConexionErp,
      });
      this.form.current.setFieldsValue({ ...conexion });
    }
  }

  componentDidUpdate(props, state) {
    if (props.reset !== this.props.reset) {
      this.form.current.resetFields();
      let cdTipoConexion = initialState.cdTipoConexion;

      if (this.props.data && JSON.stringify(this.props.data) !== '{}') {
        cdTipoConexion = this.props.data.cdTipoConexionErp;
        this.form.current.setFieldsValue({
          ...this.props.data,
        });
      }

      this.setState({
        ...this.state,
        cdTipoConexion: cdTipoConexion,
      });
    }
  }

  render() {
    const { rules, companiaSelect, onCompaniaSearch, onFinish, isEdit } =
      this.props;
    const { cdTipoConexion } = this.state;
    return (
      <Form
        name='content_form'
        layout='vertical'
        ref={this.form}
        onFinish={onFinish}
        autoComplete={'off'}
      >
        <Divider orientation='left'>Información General de la Conexión</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdTipoConexionErp'
              label='TIPO DE CONEXIÓN'
              rules={rules['cdTipoConexionErp'] || ''}
            >
              <Select
                placeholder='Seleccione'
                onChange={this.handleTipoConexionOnChange}
              >
                {tiposConexiones.map(({ key, description }) => (
                  <Select.Option key={key} value={key}>
                    {description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {cdTipoConexion !== '' && (
          <>
            <DataGeneralForm
              isEdit={isEdit}
              rules={rules}
              companiaSelect={companiaSelect}
              onCompaniaSearch={onCompaniaSearch}
            />
            <Divider orientation='left'>
              Información Específica de la Conexión
            </Divider>
            {tiposConexiones
              .find(({ key }) => key === cdTipoConexion)
              .form({ rules: rules[cdTipoConexion] })}
          </>
        )}

        <Row justify='center'>
          <Col span={8}>
            <Form.Item>
              <Button
                className='form-btn btn-radius'
                type='primary'
                htmlType='submit'
              >
                Grabar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const DataGeneralForm = ({
  rules,
  companiaSelect,
  onCompaniaSearch,
  isEdit = false,
}) => (
  <>
    <Row gutter={16}>
      <Col span={12}>
        {!isEdit && (
          <Form.Item
            name='scCompania'
            label='NOMBRE/RAZÓN SOCIAL'
            rules={rules['scCompania'] || ''}
          >
            <Select
              className='module-selector-select'
              placeholder='Seleccione'
              showSearch
              allowClear
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => onCompaniaSearch(value)}
              notFoundContent={
                companiaSelect.fetching ? <Spin size='small' /> : null
              }
            >
              {companiaSelect.options.map((compania) => (
                <Select.Option key={compania.codigo} value={compania.codigo}>
                  {compania.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {isEdit && (
          <>
            <Form.Item
              name='nombreCompania'
              label='NOMBRE/RAZÓN SOCIAL'
              rules={rules['scCompania'] || ''}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item name='scCompania' hidden={true}>
              <Input readOnly />
            </Form.Item>
          </>
        )}
      </Col>
      <Col span={12}>
        <Form.Item
          name='nombreComponente'
          label='NOMBRE COMPONENTE'
          rules={rules['nombreComponente'] || ''}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name='patternDate'
          label='FORMATO FECHA'
          rules={rules['patternDate'] || ''}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name='cdMoneda'
          label='CÓDIGO MONEDA'
          rules={rules['cdMoneda'] || ''}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={8}>
        <Form.Item
          name='patternNumber'
          label='FORMATO NUMÉRICO'
          rules={rules['patternNumber'] || ''}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name='separadorMiles'
          label='MILES'
          rules={rules['separadorMiles'] || ''}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name='separadorDecimales'
          label='DECIMALES'
          rules={rules['separadorDecimales'] || ''}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name='cdEstadoRegistro'
          label='ESTADO'
          rules={rules['cdEstadoRegistro'] || ''}
        >
          <Select>
            {estado.map(({ key, description }) => (
              <Select.Option key={key} value={key}>
                {description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </>
);

const JCOForm = ({ rules }) => (
  <>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['directa', 'sapRouter']}
          label='SAP ROUTER'
          rules={rules['sapRouter']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['directa', 'asHost']}
          label='IP SERVIDOR SAP'
          rules={rules['asHost']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['directa', 'poolCapacity']}
          label='NÚMERO MÁXIMO DE CONEXIONES ACTIVAS SIMULTANEAS'
          rules={rules['poolCapacity']}
        >
          <InputNumber />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['directa', 'peakLimit']}
          label='NÚMERO MÁXIMO DE CONEXIONES ACTIVAS'
          rules={rules['peakLimit']}
        >
          <InputNumber />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['directa', 'client']}
          label='CLIENTE SAP'
          rules={rules['client']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['directa', 'sysNr']}
          label='NÚMERO SISTEMA SAP'
          rules={rules['sysNr']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['directa', 'user']}
          label='USUARIO'
          rules={rules['user']}
        >
          <Input autoComplete='off' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['directa', 'passwd']}
          label='CLAVE'
          rules={rules['passwd']}
        >
          <Input.Password autoComplete='off' />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['directa', 'lang']}
          label='LENGUAJE'
          rules={rules['lang']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['directa', 'cdSociedad']}
          label='CÓDIGO DE SOCIEDAD'
          rules={rules['cdSociedad']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const WSForm = ({ rules }) => (
  <>
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name={['webService', 'url']}
          label='URL'
          rules={rules['url']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['webService', 'userPortal']}
          label='USUARIO PORTAL'
          rules={rules['userPortal']}
        >
          <Input autoComplete='off' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['webService', 'passwdPortal']}
          label='CLAVE PORTAL'
          rules={rules['passwdPortal']}
        >
          <Input.Password autoComplete='off' />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['webService', 'asHost']}
          label='IP SERVIDOR SAP'
          rules={rules['asHost']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['webService', 'client']}
          label='CLIENTE SAP'
          rules={rules['client']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['webService', 'user']}
          label='USUARIO'
          rules={rules['user']}
        >
          <Input autoComplete='off' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['webService', 'passwd']}
          label='CLAVE'
          rules={rules['passwd']}
        >
          <Input.Password autoComplete='off' />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['webService', 'sysNr']}
          label='NÚMERO DEL SISTEMA SAP'
          rules={rules['sysNr']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['webService', 'lang']}
          label='LENGUAJE'
          rules={rules['lang']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['webService', 'cdSociedad']}
          label='CÓDIGO DE SOCIEDAD'
          rules={rules['cdSociedad']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const RestForm = ({ rules }) => (
  <>
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item name={['apiRest', 'url']} label='URL' rules={rules['url']}>
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['apiRest', 'userPortal']}
          label='USUARIO PORTAL'
          rules={rules['userPortal']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['apiRest', 'passwdPortal']}
          label='CLAVE PORTAL'
          rules={rules['passwdPortal']}
        >
          <Input.Password />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const JdbcForm = ({ rules }) => (
  <>
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name={['jdbc', 'driver']}
          label='JDBC DRIVER'
          rules={rules['driver']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name={['jdbc', 'urlConexion']}
          label='URL DE CONEXIÓN'
          rules={rules['urlConexion']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['jdbc', 'user']}
          label='USUARIO DE BASE DE DATOS'
          rules={rules['user']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['jdbc', 'passwd']}
          label='CONTRASEÑA'
          rules={rules['passwd']}
        >
          <Input.Password />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const FtpForm = ({ rules }) => (
  <>
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name={['ftp', 'asHost']}
          label=' DIRECCIÓN IP'
          rules={rules['asHost']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={['ftp', 'carpEntrada']}
          label='CARPETA DE ENTRADA'
          rules={rules['carpEntrada']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['ftp', 'carpSalida']}
          label='CARPETA DE SALIDA'
          rules={rules['carpSalida']}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item name={['ftp', 'user']} label='USUARIO' rules={rules['user']}>
          <Input autoComplete='off' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['ftp', 'passwd']}
          label='CLAVE'
          rules={rules['passwd']}
        >
          <Input.Password autoComplete='off' />
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default ErpConectionForm;
