import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Card,
  Checkbox,
} from 'antd';
import {
  FileExcelOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { initialState } from './initialState';
import { columns } from './columns';
import { form } from './form';
import {
  listaTipoLogs,
  listarReporteLogs,
  listaNivelesLogs,
  listarDescargaLogs,
  borrarLogs,
} from '../../../../../services/admin/archivesLogsService';
import '../reports.scss';
import { connect } from 'react-redux';
import { REPORTS_DATES_REQUIRED_ERROR } from '../../../../../utilities/messages';
import Total from '../../../../proveedor/reports/total';
import {
  downloadFile,
  setExport,
} from '../../../../../utilities/downloadTools';

const { Option } = Select;

let intColumnas = 1;

class ArchivesContent extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  columnas = columns;

  formulario = form;
  isBackButtonClicked = false;

  formRender = () => {
    const { type } = this.props;
    let formulario = '';
    return (formulario = (
      <Form name='content_form' layout='vertical' className='filters-container'>
        {this.formulario[type].map((fila, key) => {
          if (fila.length === 1) {
            return (
              <Row key={key} gutter={16}>
                <Col span={24}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={fila[1].nombre} label={fila[1].label}>
                    {this.inputType(fila[1])}
                  </Form.Item>
                </Col>
              </Row>
            );
          }
        })}
        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              {this.props.type == 'reporte_logs' && (
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  onClick={() => this.getData()}
                >
                  Buscar
                </Button>
              )}
              {this.props.type == 'descarga_logs' && (
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  onClick={() => this.getData()}
                >
                  Descargar
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ));
  };

  inputType = (campo) => {
    const { type } = this.props;
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(e) => this.handleTextChange(e, stateName, type)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            showTime
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName, type)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
      case 'select_nosearch':
        input = (
          <Select
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onClick={() => {
              if (this.state[campo.selectType].length == 0)
                this[campo.loadFunction]();
            }}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  };

  listaNivelesLogs = () => {
    listaNivelesLogs(this.props.authToken).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          nivelesLogsOptions: response.data.map((item) => {
            return {
              key: item.codigo,
              nombre: item.descripcion,
            };
          }),
          fetching: false,
        });
      }
    });
  };

  listaTipoLogs = () => {
    listaTipoLogs(this.props.authToken).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          tiposLogsOptions: response.data.map((item) => {
            return {
              key: item.codigo,
              nombre: item.descripcion,
            };
          }),
          fetching: false,
        });
      }
    });
  };

  handleTextChange = (e, campo, type) => {
    this.state[type][campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo, type) => {
    this.state[type][campo] = moment;
  };

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
    }
  };

  handleSelectChange = (value, stateName) => {
    this.state[this.props.type][stateName] = value;
    this.setState({ ...this.state });
  };

  getData = () => {
    setTimeout(() => {
      this.setState({
        ...this.state,
        loading: false,
      });
    }, 3000);
    this.state.resultado = [];
    switch (this.props.type) {
      case 'reporte_logs':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          listarReporteLogs(
            this.props.authToken,
            this.state[this.props.type]
          ).then((response) => {
            this.setResponse(response);
          });
        }
        break;
      case 'descarga_logs':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          listarDescargaLogs(
            this.props.authToken,
            this.state[this.props.type]
          ).then((response) => {
            console.log(response);
            downloadFile(
              response,
              `${this.state[this.props.type].cdLog}.log`,
              'text/plain; charset=UTF-8'
            );
          });
        }
        break;
    }
  };

  borrarLogs = () => {
    borrarLogs(this.props.authToken)
      .then((response) => {
        console.log(response);
        notification.success({
          message: 'Confirmado',
          description: response.message,
          duration: 4,
        });
      })
      .catch(() =>
        notification.error({
          message: 'Error',
          description: 'Los logs no pudieron ser borrados corrrectamente',
          duration: 4,
        })
      );
    this.getData();
  };

  componentDidMount() {}

  componentDidUpdate(props, state) {}

  exportExcel = () => {};

  setExcel = (response) => {
    const type = this.props.type;
    const url = window.URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ` Reporte ${type}.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.setState({
      ...this.state,
      loading: false,
    });
  };

  setResponse = (response) => {
    if (response && response.status !== false) {
      this.setState({
        ...this.state,
        [this.props.type]: {
          ...this.state[this.props.type],
          resultado: response.data,
          total: response.metadata.count,
        },
        loading: false,
        sumatorias: response.metadata.sumatorias,
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  validateForm = () => {
    let filtros = this.state[this.props.type];
    let validationResult = true;
    let formulario = this.formulario;
    let nombres = [];
    let errores = [];
    Object.keys(filtros).forEach((nombre) => {
      if (
        formulario[this.props.type].find((x) =>
          x.find((y) => y.nombre === nombre && y.required === true)
        )
      ) {
        if (filtros[nombre] === '' || filtros[nombre] === null) {
          nombres.push(nombre);
          validationResult = false;
        }
      }
    });
    if (!validationResult) {
      formulario[this.props.type].forEach((formItem) => {
        formItem.forEach((key) => {
          if (nombres.includes(key.nombre)) {
            errores.push(key.label.replace('* ', ''));
          }
        });
      });
      if (errores.length > 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar los valores de los campos ${errores.reduce(
            (error, acum) => `${acum}, ${error}`
          )} para consultar este reporte`,
          duration: 4,
        });
      }
      if (errores.length == 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar el valor del campo ${errores[0]} para consultar este reporte`,
          duration: 4,
        });
      }
    }
    return validationResult;
  };

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  handleOnChange = (pagination, filters, sorter, extra) => {
    if (this.state.currentPage != pagination.current) {
      this.state[this.props.type].offset =
        pagination.pageSize * (pagination.current - 1);
      this.state[this.props.type].currentPage = pagination.current;
    }

    if ((this.state[this.props.type].limit || 10) != pagination.pageSize) {
      this.state[this.props.type].limit = pagination.pageSize;
      this.state[this.props.type].offset = 0;
      this.state[this.props.type].currentPage = 1;
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.state[this.props.type].sort =
        order == 'descend' ? `-${field}` : field;
    }
    this.getData();
  };

  render() {
    const { type } = this.props;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <div className='header-container'>
            <p className='title'>Búsqueda</p>
            {this.formRender()}
          </div>
          <div className='table-container'>
            <Row gutter={30} className='bill-selection' justify='end'>
              <Col xl={{ span: 24 / intColumnas }} className='export'>
                <span className='button'>
                  <Button
                    size='medium'
                    className='btn-excel'
                    title='Exportar a excel'
                    onClick={this.exportExcel}
                    icon={<FileExcelOutlined />}
                  ></Button>
                </span>
              </Col>
            </Row>
            <Divider />
            <Table
              pagination={{
                defaultPageSize: 10,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} registros`,
                current: this.state[this.props.type].currentPage,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30'],
                total: this.state[type].total,
              }}
              onChange={this.handleOnChange}
              columns={this.columnas[type]}
              footer={() => <Total sumatorias={this.state.sumatorias} />}
              dataSource={this.state[type].resultado}
            ></Table>
            <Row justify='right'>
              <Col span={24}>
                {this.props.type == 'reporte_logs' && (
                  <Button
                    type='primary'
                    danger
                    onClick={() => this.borrarLogs()}
                  >
                    Borrar logs
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(ArchivesContent);
