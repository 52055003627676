import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Switch,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  listaFondeadores,
  listarFondeadoresExcel,
} from '../../../../services/admin/fundProvidersService';
import history from '../../../../services/history';
import { setExcel } from '../../../../utilities/downloadTools';
import { BUSQUEDA_TITLE } from '../../../../utilities/messages';
import { sorterText } from '../../../../utilities/textTools';
import './fundProviders.scss';

const IDENTIFICATION_FILTRO = 'Identificación';
const NAME_FILTRO = 'Nombre/Razón social';

const initialState = {
  loading: false,
  form: {
    nombre: '',
    id: '',
  },
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  filtroSeleccionado: NAME_FILTRO,
};

class FundProviders extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'TIPO ID',
        dataIndex: 'dsTipoId',
        sorter: (a, b) => sorterText(a.dsTipoId, b.dsTipoId),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.id, b.id),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoPersona',
        sorter: (a, b) => sorterText(a.dsEstadoPersona, b.dsEstadoPersona),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showEditPage(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.formSearch = React.createRef();
  }

  showEditPage = (record) => {
    history.push(
      `${process.env.PUBLIC_URL}/fundProviders/edit/${btoa(record.scPersona)}`
    );
  };

  handleSwitchChange = (check) => {
    let nombre = NAME_FILTRO;
    if (check) {
      nombre = IDENTIFICATION_FILTRO;
    }

    this.formSearch.current.setFieldsValue({
      tipoSearch: check,
      nombre: '',
      id: '',
    });

    this.setState({
      ...this.state,
      filtroSeleccionado: nombre,
      filtros: {
        ...this.state.filtros,
        nombre: '',
        id: '',
      },
    });
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      resultado: [],
      total: initialState.total,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.getData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaFondeadores(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarFondeadoresExcel(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );

      setExcel(response, 'Listado_de_Fondeadores');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;

    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  componentDidMount() {
    this.props.menuHandler('21');
    this.getData();
  }

  render() {
    const {
      filtroSeleccionado,
      loading,
      currentPage,
      limit,
      total,
      resultado,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='fundProviders-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <Row gutter={[16, 32]}>
                <Col span={24}>
                  <Space align='baseline' className='title'>
                    {BUSQUEDA_TITLE}
                  </Space>
                </Col>
              </Row>
              <Form
                ref={this.formSearch}
                name='erp_search'
                layout='vertical'
                onFinish={this.handleSubmit}
              >
                <Row gutter={[16, 32]}>
                  <Col span={6}>
                    <Form.Item name='tipoSearch' label='Filtro'>
                      <Switch
                        checkedChildren={IDENTIFICATION_FILTRO}
                        unCheckedChildren={NAME_FILTRO}
                        onChange={this.handleSwitchChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    {filtroSeleccionado === NAME_FILTRO && (
                      <Form.Item name='nombre' label={NAME_FILTRO}>
                        <Input />
                      </Form.Item>
                    )}
                    {filtroSeleccionado === IDENTIFICATION_FILTRO && (
                      <Form.Item name='id' label={IDENTIFICATION_FILTRO}>
                        <Input />
                      </Form.Item>
                    )}
                  </Col>

                  <Col span={6}>
                    <Form.Item>
                      <Button
                        className='form-btn btn-radius'
                        type='primary'
                        htmlType='submit'
                      >
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de Fondeadores
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                    <Button
                      size='small'
                      onClick={() =>
                        history.push(
                          `${process.env.PUBLIC_URL}/fundProviders/new`
                        )
                      }
                      className='btn-agregar'
                    >
                      + Agregar nuevo fondeador
                    </Button>
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                    <Button type='link' onClick={this.exportExcel}>
                      Exportar a excel
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Spin>
    );
  }

  /* 

  showModalEditar = (record) => {
    if (typeof record !== 'undefined' && record != null) {
      this.setState({
        ...this.state,
        parametrosModal: {
          ...this.state.parametrosModal,
          ...record,
        },
        isEdit: true,
        isNew: false,
        activeFilter: true,
      });
    } else {
      this.setState({
        ...this.state,
        isNew: true,
        isEdit: false,
        parametrosModal: initialValuesModal,
        activeFilter: true,
      });
    }
  }; */

  /* handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.state.pageData.fetching = true;
      this.setState({
        ...this.state,
      });
      this.state.pageData.options = [];
      this.setState({ ...this.state });
    }
  }; */

  /*  handleSelectChange = (value, stateName) => {
    this.state.pageData[stateName] = value;
    this.setState({ ...this.state });
  }; */

  /* inputType = (campo, type) => {
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state.pageData[stateName]}
            onChange={(e) => this.handleTextChange(e, stateName, type)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state.pageData[stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName, type)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state.pageData[stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={
              this.state.pageData.fetching ? <Spin size='small' /> : null
            }
          >
            {this.state.pageData.options.map((d) => (
              <Option key={d.nombre}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  }; */

  /* handleTextChange = (e, campo, type) => {
    this.state.pageData[campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  }; */

  /*  handleDateChange = (moment, campo, type) => {
    this.state.pageData[campo] = moment;
  }; */

  /* getData = (currentPage) => {
    if (currentPage == 1) {
      this.state.pageData.currentPage = 1;
      this.state.pageData.offset = 0;
    }
    this.state.pageData.resultado = [];
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });
      listaFondeadores(this.props.authToken, this.state.pageData).then(
        (response) => {
          this.setResponse(response);
        }
      );
    }
  }; */

  /*  setResponse = (response) => {
    this.state.pageData.resultado = response.data;
    this.setState({
      ...this.state,
      pageData: {
        ...this.state.pageData,
        ...response.metadata,
      },
      loading: false,
    });
  }; */

  /* 

  editarFondeador = () => {
    if (this.state.isEdit) {
      editarFondeador(this.props.authToken, this.state.parametrosModal).then(
        (response) => {
          this.setState({
            ...this.state,
            editarVisible: false,
          });

          if (response.data) {
            notification.success({
              message: 'Actualizado',
              description: response.message,
              duration: 6,
            });
            this.getData();
          }
        }
      );
    }
    
  }; */

  /* 
  render() {
    const { type } = this.props;
    return (
      <Spin tip='Cargando...' spinning={this.state.loading}>
        <div className='fundProviders-style'>
          

          <Card
            className='card-shadow card-radius default-border welcome-message'
            hidden={!this.state.activeFilter}
          >
            <div>
              <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>
                <Tabs.TabPane key={'1'} tab='Datos básicos'>
                  <BasicData
                    type='fondeadores'
                    data={this.state.parametrosModal}
                    prevURL={'fundProviders'}
                    scTipoPersona={this.state.parametrosModal.cdTipoPersona}
                    scPersona={this.state.parametrosModal.scPersona}
                    isNew={this.state.isNew}
                    clickHandler={this.outputEvent}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane key={'2'} tab='Documentos'>
                  <Documents
                    type='fondeadores'
                    data={this.state.parametrosModal}
                    scPersona={this.state.parametrosModal.scPersona}
                    clickHandler={this.outputEvent}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane key={'3'} tab='Usuarios'>
                  <Usuarios
                    type='fondeadores'
                    data={this.state.parametrosModal}
                    scPersona={this.state.parametrosModal.scPersona}
                    clickHandler={this.outputEvent}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Card>
        </div>
      </Spin>
    );
  } */
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(FundProviders);
