import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listaGruposEmpresariales = (authtoken, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/grupos-empresariales/listar-grupos?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const getGrupoEmpresarial = (authtoken, scGrupo) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/grupos-empresariales/${scGrupo}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const editarGrupoEmpresarial = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/grupos-empresariales/grabar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const crearGrupoEmpresarial = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/grupos-empresariales/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const listaHorasCarga = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/horas-carga`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaHorasDesbloqueo = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/horas-desbloqueo`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const calcularRangos = (authtoken, filtros) => {
  console.log(
    `${baseUrl}/api/grupos-empresariales/calcular-rango?${queryString.stringify(
      filtros
    )}`
  );
  return middleWare(
    axios({
      url: `${baseUrl}/api/grupos-empresariales/calcular-rango?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCompaniasGrupos = (authtoken, scGrupo, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/grupo-empresarial/${scGrupo}/listar-companias?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCompaniasNoAsociados = (authtoken, scGrupo, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/grupo-empresarial/${scGrupo}/companias-no-asociadas?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const asociarPagador = (authtoken, scGrupo, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scCompania}/grupo-empresarial/${scGrupo}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const eliminarPagadorGrupo = (authtoken, scGrupo) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scGrupo}/grupo-empresarial`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD');
      }
    }
  });
  return filtros;
};
