import {
  COMPANY_SELECTED,
  SELECTED_BILLS,
  NEXT_STEP,
  SET_TRACKING,
  SET_NEEDED_AMOUNT,
  CHARGEALL_SELECTED_BILLS,
  CHARGEALL_NEXT_STEP,
  PROGRAM_NEXT_STEP,
  PROGRAM_SELECTED_BILLS,
  UPDATE_PROGRAM_DATE,
  SET_PERSON_TYPE,
  BILLING_RESET_STEPS,
  REALOAD_NOTIFICATIONS
} from '../types'

export const setCompany = (company) => dispatch => {
  dispatch({ type: COMPANY_SELECTED, payload: company })
};

export const reloadNotifications = (state) => dispatch => {
  dispatch({ type: REALOAD_NOTIFICATIONS, payload: state })
}

export const setPersonType = (person) => dispatch => {
  dispatch({ type: SET_PERSON_TYPE, payload: person })
};

export const setNeededAmount = (amount) => dispatch => {
  dispatch({ type: SET_NEEDED_AMOUNT, payload: amount })
};

export const nextStep = (nextStep) => dispatch => {
  dispatch({ type: NEXT_STEP, payload: nextStep })
};
export const selectedBills = (currentState) => dispatch => {
  dispatch({ type: SELECTED_BILLS, payload: currentState })
};

export const chargeallNextStep = (nextStep) => dispatch => {
  dispatch({ type: CHARGEALL_NEXT_STEP, payload: nextStep })
};

export const chargeallSelectedBills = (currentState) => dispatch => {
  dispatch({ type: CHARGEALL_SELECTED_BILLS, payload: currentState })
};

export const programNextStep = (nextStep) => dispatch => {
  dispatch({ type: PROGRAM_NEXT_STEP, payload: nextStep })
};

export const programSelectedBills = (currentState) => dispatch => {
  dispatch({ type: PROGRAM_SELECTED_BILLS, payload: currentState })
};

export const setTracking = (scTracking) => dispatch => {
  dispatch({ type: SET_TRACKING, payload: scTracking })
};


export const updateProgramDate = (date) => dispatch => {
  dispatch({ type: UPDATE_PROGRAM_DATE, payload: date })
};

export const resetBillingSteps = () => dispatch => {
  dispatch({ type: BILLING_RESET_STEPS })
};