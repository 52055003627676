import React from 'react'
import './agreements.scss'
import { Row, Col, Card, Button, Table } from 'antd'
import { SettingOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import agrementsServices from '../../../services/proveedor/agrementsServices';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ConfigAgreement from './configAgreement'
import {
  AGREEMENTS_TITTLE_INIT,
  AGREEMENTS_SUBTITTLE_INIT
} from '../../../utilities/messages'




class Agreements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      agreement: {},
      agreements: [],
      showNew: false,
      showEdit: false,
      activeFilter: false,
      newAgreements: [],
      update: false //reload from child
    }
    this.update = this.update.bind(this)
  }

  columns = [
    {
      title: 'NOMBRE CONVENIO',
      dataIndex: 'agreementName',
      key: 'agreementName',
    },
    {
      title: 'PAGADOR',
      dataIndex: 'payer',
      key: 'payer',
    },
    {
      title: 'ESTADO',
      dataIndex: 'status',
      key: 'status',
      render: (key, item) => (<label className={item.status === 'S' ? 'aggrement-row aggrement-row-active' : 'aggrement-row aggrement-row-inactive'} onClick={() => (this.manageConfig(item))}> {item.status === 'S' ? 'Activo' : 'Inactivo'} </label>),
      align: "center"
    },
    {
      title: 'CONFIGURACIÓN',
      dataIndex: 'configuration',
      key: 'configuration',
      render: (key, item) => (<SettingOutlined className="agree-icon-size" onClick={() => (this.manageConfig(item))} />),
      align: "center"
    },
  ];

  componentDidMount() {
    this.props.menuHandler('4')
    if (this.props.company)
      this.loadAggrements()
  }

  componentDidUpdate(props, state) {
    if (this.state.update) {
      this.setState({
        ...this.state,
        update: false
      })
      this.loadAggrements()
    }

    if (props.company !== this.props.company) {
      this.loadAggrements()
    }
  }

  update() {
    this.setState({
      ...this.state,
      update: true
    })
  }

  activeFilter() {
    this.setState({
      ...this.state,
      activeFilter: !this.state.activeFilter
    })
  }

  manageConfig(e) {
    this.setState({
      ...this.state,
      showEdit: true,
      showNew: false,
      agreement: this.state.agreements.find(x => x.scConvenio == e.key)
    })
    this._scrollToView()
  }

  newConfig() {
    this.setState({
      ...this.state,
      showNew: true,
      showEdit: false
    })
    this._scrollToView()
  }

  _scrollToView() {
    setTimeout(() => {
      var section = document.getElementById('agreements-selection-section')
      if (section) {
        section.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }, 500)
  }

  loadAggrements() {
    agrementsServices.listAgreements(this.props.authtoken, this.props.company).then(response => {
      if (Array.isArray(response.data)) {

        let map = response.data.map((item, index) => ({
          key: item.scConvenio,
          agreementName: item.nombreConvenio,
          payer: item.nombrePagador,
          status: item.snDescAuto,
          configuration: item.scConvenio,
        }))

        let mapActive = map.filter(x => x.status === 'S')

        let countActive = map.reduce((count, current) => (current.status === 'S' ? count + 1 : count + 0), 0)

        this.setState({
          ...this.state,
          showEdit: false, showNew: false,
          dataSource: map,
          activeDataSource: mapActive,
          agreements: response.data,
          agrrementsActiveCount: countActive,
          activeFilter: false
        })
      }
    })

    agrementsServices.listAgreementsNew(this.props.authtoken, this.props.company).then(
      response => this.setState({
        ...this.state,
        newAgreements: response.data
      })
    )
  }

  render() {
    return (
      <div className="content-margin agreements">


        <Card className="card-shadow card-radius default-border welcome-message ">
          < Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="title">
                {AGREEMENTS_TITTLE_INIT}
              </div>
            </Col>
          </Row>
          < Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="subtitle">
                {AGREEMENTS_SUBTITTLE_INIT}
              </div>
            </Col>
          </Row>
        </Card>
        <Title level={4} className="agree-marginbot">Tus convenios de descuento automático</Title>
        <Row gutter={16} className="agree-marginbot">
          <Col>
            <Button className={this.state.activeFilter ? "agree-buttons agree-active active" : "agree-buttons agree-active"} onClick={(e) => this.activeFilter()}>ACTIVOS ({this.state.agrrementsActiveCount})</Button>
          </Col>
          <Col>
            <Button className="agree-buttons agree-new" onClick={() => this.newConfig()}>NUEVO +</Button>

          </Col>
        </Row>

        <Row className="agree-marginbot">
          <Card className="card-container card-radius card-shadow">
            <Table dataSource={this.state.activeFilter ? this.state.activeDataSource : this.state.dataSource} columns={this.columns} size="small" pagination={{ pageSize: 5, showLessItems: true }} />
          </Card>
        </Row>
        <div id="agreements-selection-section"></div>
        <Row>
          {this.state.showEdit ? <Title level={4} className="agree-marginbot">Modificar un convenio de descuento automático</Title> : null}
          {this.state.showNew ? <Title level={4} className="agree-marginbot">Insertar un nuevo convenio de descuento automático</Title> : null}
        </Row>
        {this.state.showEdit ? <ConfigAgreement
          scConvenio={this.state.agreement.scConvenio}
          name={this.state.agreement.nombreConvenio}
          checked={this.state.agreement.snDescAuto == 'S'}
          daysAuto={this.state.agreement.nmDiasDescAuto}
          daysBusiness={this.state.agreement.cdDiaDescAuto}
          preference={this.state.agreement.descAutoPreferencia}
          selectedAccount={this.state.agreement.scCuenta}
          typeAgreement={this.state.agreement.tipoConvenio}
          accounts={this.state.agreement.fondeadorCuentaDto}
          handler={this.update}
        /> : null}
        {this.state.showNew ? <ConfigAgreement isNew={this.state.showNew} newAgreements={this.state.newAgreements} handler={this.update} /> : null}

      </div >
    )
  }
}


Agreements.propTypes = {
  authtoken: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    authtoken: state.auth.auth.access_token,
    company: state.billing.company
  }
}


export default connect(mapStateToProps, {})(Agreements);