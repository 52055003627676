export const initialState = {
  pagadorOptions: [],
  proveedorOptions: [],
  convenioOptions: [],
  tipoConvenioOptions: [],
  grupoEmpresarialOptions: [],
  fondeadorOptions: [],
  tipoClusterOptions: [],
  loading: false,
  reports: {
    fechaDesde: '',
    fechaHasta: '',
    clusNeuroRiesgo: '',
    scCompania: '',
    currentPage: 1,
    total: 0,
    resultado: [],
  },
 
  opcionesDocumentos: [],
  fetching: false,
  sumatorias: {}
}