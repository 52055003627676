export const estadoSolicitudMsj = [
    { key: 'PD', name:"Pendiente Documentación", description: "Tiene pendiente cargar la documentación solicitada, haga clic en el boton 'VER DETALLE' para terminar su solicitud" },
    { key: 'E', name:"En estudio", description: 'Su solicitud esta en estudio,en los siguientes dias tendra respuesta de un asesor' },
    { key: 'A', name:"Aprobado", description: 'Su solicitud ha sido aprobada' },
    { key: 'R', name:"Rechazado", description: 'Su solicitud ha sido rechazada' },
    { key: 'DI', name:"Documentación Incompleta", description: 'Despues de revisar su documentación. se encontro la siguiente novedad:'}
  ];


export const estadoSolicitudMsj2 = [
  { key: 'A', name:"Aprobado", description: 'Su solicitud ha sido aprobada' },
  { key: 'R', name:"Rechazado", description: 'Su solicitud ha sido rechazada' },
  { key: 'DI', name:"Documentación Incompleta", description: 'Despues de revisar su documentación. se encontro la siguiente novedad:'}
];