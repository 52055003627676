import React from 'react';
import { Table } from 'antd';
import './tableReport.scss';

const TableReport = ({
  keyIndex,
  rowSelection,
  currentPage,
  limit,
  total,
  columns,
  data,
  handleChange,
  resumen = [],
  size = 'default',
  rowClassName = null,
}) => {
  data = data.map((d, index) => {
    const i = keyIndex ? d[keyIndex] : index;
    return {
      ...d,
      key: i,
    };
  });

  const handlePagesOnChange = (pagination, filters, sorter, extra) => {
    let offset = 0;
    let sort = '';

    offset = pagination.pageSize * (pagination.current - 1);
    currentPage = pagination.current;

    if ((limit || 10) !== pagination.pageSize) {
      offset = 0;
      currentPage = 1;
      limit = pagination.pageSize;
    }

    if (sorter.field !== undefined && sorter.order !== undefined) {
      let { field, order } = sorter;
      sort = order === 'descend' ? `+${field}` : `-${field}`;
    }

    handleChange({ sort, offset, currentPage, limit });
  };

  return (
    <Table
      size={size}
      rowSelection={rowSelection}
      pagination={{
        defaultPageSize: 10,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} registros`,
        current: currentPage,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
        total: total,
      }}
      onChange={handlePagesOnChange}
      columns={columns}
      dataSource={data}
      rowClassName={rowClassName}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              {resumen &&
                resumen.map((res) => (
                  <Table.Summary.Cell
                    key={res.index}
                    index={res.index}
                    colSpan={res.colSpan}
                  >
                    {res.value}
                  </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
          </>
        );
      }}
    ></Table>
  );
};

export default TableReport;
