import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Card,
  Checkbox,
} from 'antd';
import { connect } from 'react-redux';
import Total from '../../../proveedor/reports/total';
import {
  listarArchivos,
  exportarExcelArchivo,
  eliminarArchivos,
} from '../../../../services/admin/file/fileDownloadsService';
import './fileDownloads.scss';
import equals from 'fast-deep-equal';

const { Option } = Select;
const initialState = {
  loading: false,
  currentPage: 1,
  resultado: [],
  total: 0,
  sumatorias: {},
  filtros: {
    idCompania: '',
    offset: 0,
  },
};

class FileDownloads extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.formRef = React.createRef();
  }

  columns = [
    {
      title: 'FECHA DE DESCARGA',
      dataIndex: 'feModifica',
      sorter: (a, b) => a.feModifica - b.feModifica,
      showSorterTooltip: false,
    },
    {
      title: 'INDENTIFICACIÓN',
      dataIndex: 'idCompania',
      sorter: (a, b) => a.idCompania - b.idCompania,
      showSorterTooltip: false,
    },
    {
      title: 'NOMBRE / RAZÓN SOCIAL',
      dataIndex: 'nombreCompania',
      sorter: (a, b) => a.nombreCompania - b.nombreCompania,
      showSorterTooltip: false,
    },
    {
      title: 'TIPO DE ARCHIVO',
      dataIndex: 'nombreCompania',
      sorter: (a, b) => a.nombreCompania - b.nombreCompania,
      showSorterTooltip: false,
    },
    {
      title: 'EXTENSIÓN',
      dataIndex: 'extension',
      sorter: (a, b) => a.extension - b.extension,
      showSorterTooltip: false,
    },
    {
      title: 'TAMAÑO DEL ARCHIVO',
      dataIndex: 'espacioBytes',
      sorter: (a, b) => a.espacioBytes - b.espacioBytes,
      showSorterTooltip: false,
    },
    {
      title: 'DESCARGAR',
      dataIndex: '',
      render: (record) => (
        <Button
          type='primary'
          title='Editar'
          onClick={() => this.descargarArchivo(record)}
        >
          DESCARGAR
        </Button>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'ELIMINAR',
      dataIndex: '',
      render: (record) => (
        <Button
          type='primary'
          title='Editar'
          onClick={() => this.eliminarArchivo(record)}
        >
          ELIMINAR
        </Button>
      ),
      showSorterTooltip: false,
    },
  ];

  formulario = [
    [
      {
        nombre: 'idCompania',
        label: 'IDENTIFICACIÓN',
        tipo: 'string',
      },
    ],
  ];

  componentDidMount() {
    this.props.menuHandler('81');
  }

  componentDidUpdate(props, state) {
    if (!equals(this.state.filtros, state.filtros)) {
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue(this.state.filtros);
      }
    }
  }

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  descargarArchivo(record) {
    exportarExcelArchivo(this.props.authToken, record.scArchivo).then(
      (response) => {
        if (response.message !== 'Archivo no encontrado') {
          notification.success({
            message: 'Descargado',
            description: response.message,
            duration: 6,
          });
        }
        this.setExcel(response);
        this.getData();
      }
    );
  }

  setExcel = (response) => {
    const type = this.props.type;
    const url = window.URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Archivos de descarga.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.setState({
      ...this.state,
      loading: false,
    });
  };

  eliminarArchivo(record) {
    eliminarArchivos(this.props.authToken, record.scArchivo).then(
      (response) => {
        if (response.message) {
          notification.success({
            message: 'Eliminado',
            description: response.message,
            duration: 6,
          });
        }
        this.getData();
      }
    );
  }

  getData = () => {
    this.state.resultado = [];
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });
      listarArchivos(this.props.authToken, this.state.filtros)
        .then((response) => {
          this.setResponse(response);
        })
        .catch(() =>
          this.setState({
            ...this.state,
            loading: false,
          })
        );
    }
  };

  setResponse = (response) => {
    this.setState({
      ...this.state,
      resultado: response.data,
      total: response.metadata.count,
      loading: false,
      sumatorias: response.metadata.sumatorias,
    });
  };

  validateForm = () => {
    let filtros = this.state.filtros;
    let fechas = true;
    Object.keys(filtros).forEach((nombre) => {
      if (nombre !== 'resultado') {
        if (
          nombre.includes('fecha') &&
          (filtros[nombre] === '' || filtros[nombre] === null)
        ) {
          fechas = false;
        }
      }
    });
    if (!fechas) {
      notification.error({
        message: 'Las fechas son obligatorias',
        duration: 4,
      });
    }
    return fechas;
  };

  formRender = () => {
    let formulario = '';
    return (formulario = (
      <Form
        name='content_form'
        layout='vertical'
        ref={this.formRef}
        className='filters-container'
      >
        {this.formulario.map((fila, key) => {
          if (fila.length === 1) {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>

                {/* <Col span={6}>
                                <Form.Item
                                    name="submit">
                                    <Button className="btn btn-green" type="primary" onClick={() => this.getData()}>
                                        Buscar
                  </Button>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="submit">
                                    <Button className="btn btn-blue" type="primary" onClick={() => { this.limpiarCampos() }}>
                                        Limpiar
                  </Button>
                                </Form.Item>
                            </Col> */}
              </Row>
            );
          }
        })}
        <Row justify='left'>
          <Col>
            <Form.Item name='submit'>
              <Button
                className='btn btn-green'
                type='primary'
                onClick={() => this.getData()}
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='submit'>
              <Button
                className='btn btn-blue'
                type='primary'
                onClick={() => {
                  this.limpiarCampos();
                }}
              >
                Limpiar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ));
  };

  limpiarCampos() {
    this.setState({
      ...initialState,
      filtros: {
        idCompania: '',
        offset: 0,
      },
    });
  }

  inputType = (campo) => {
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state.filtros[stateName]}
            onChange={(e) => this.handleTextChange(e, stateName)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state.filtros[stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state.filtros[stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  };

  handleOnChange = (pagination, sorter) => {
    if (this.state.currentPage != pagination.current) {
      this.state.filtros.offset =
        pagination.pageSize * (pagination.current - 1);
      this.state.currentPage = pagination.current;
    }

    if ((this.state.filtros.limit || 10) != pagination.pageSize) {
      this.state.filtros.limit = pagination.pageSize;
      this.state.filtros.offset = 0;
      this.state.currentPage = 1;
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.state.filtros.sort = order == 'descend' ? `-${field}` : field;
    }
    this.getData();
  };

  handleTextChange = (e, campo) => {
    this.state.filtros[campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo) => {
    this.state.filtros[campo] = moment;
  };

  handleSelectChange = (value, stateName) => {
    this.state.filtros[stateName] = value;
    this.setState({ ...this.state });
  };

  render() {
    return (
      <div className='reports'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={this.state.loading}>
                <div className='header-container'>
                  <p className='title'>Búsqueda</p>
                  {this.formRender()}
                </div>
                <div className='table-container'>
                  <Divider />
                  <Table
                    pagination={{
                      defaultPageSize: 10,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} de ${total} registros`,
                      current: this.state.filtros.currentPage,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '30'],
                      total: this.state.filtros.total,
                    }}
                    onChange={this.handleOnChange}
                    columns={this.columns}
                    footer={() => <Total sumatorias={this.state.sumatorias} />}
                    dataSource={this.state.resultado}
                  ></Table>
                </div>
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(FileDownloads);
