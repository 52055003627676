import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import equal from 'fast-deep-equal';
import React from 'react';
import {
  digitsMessagge,
  digitsPattern,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser,
} from '../../../../../utilities/textTools';
import * as numberValidate from '../../../../../utilities/validate/numberValidate';
import { length } from '../../../../../utilities/validate/textValidate';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const initialState = {
  cdReteIva: false,
  cdReteIca: false,
  cdEntidadVigila: '',
};

const initialValues = {
  cdSnGranContribuyente: false,
  cdReteIva: false,
  cdReteFuente: false,
  cdReteIca: false,
};

const rules = {
  cdSituacionTributaria: [{ required: true, message: requiredValueMessage() }],
  vrBaseReteIva: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  vrBaseReteIca: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: digitsPattern(24, 8),
      message: digitsMessagge(24, 8),
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  poReteIca: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => numberValidate.range(value, 0, 100),
    },
    {
      pattern: digitsPattern(3, 2),
      message: digitsMessagge(3, 2),
    },
  ],
  otraEntidadVigila: [
    {
      validator: (_, value) => length(value, 5, 100),
    },
    { required: true, message: requiredValueMessage() },
  ],
};

const situacionTributaria = [
  { key: 'RE', description: 'Regimen especial' },
  { key: 'NC', description: 'No contribuyente' },
  { key: 'C', description: 'Contribuyente' },
];

const entidadVigila = [
  { key: 'SF', description: 'Superintendencia financiera' },
  { key: 'SIC', description: 'Superintendencia de industria y comercio' },
  { key: 'SS', description: 'Superintendencia de sociedades' },
  { key: 'SES', description: 'Superintendencia de economía solidaria' },
  { key: 'OTRA', description: 'Otra' },
];

class Tributary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.formRef = React.createRef();
  }

  handleOnFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish('6', {
      ...formValue,
      cdSnGranContribuyente: formValue.cdSnGranContribuyente ? 'S' : 'N',
      cdReteIva: formValue.cdReteIva ? 'S' : 'N',
      cdReteFuente: formValue.cdReteFuente ? 'S' : 'N',
      cdReteIca: formValue.cdReteIca ? 'S' : 'N',
    });
  };

  handleOnChangeCdReteIva = (value) => {
    this.setState({ ...this.state, cdReteIva: value });
  };

  handleOnChangeCdReteIca = (value) => {
    this.setState({ ...this.state, cdReteIca: value });
    this.formRef.current.setFieldsValue({ poReteIca: 0.5 });
  };

  handleOnChangeCdEntidadVigilada = (value) => {
    this.setState({ ...this.state, cdEntidadVigila: value });
  };

  componentDidUpdate(props, state) {
    if (!equal(props.person, this.props.person)) {
      const { person } = this.props;
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        ...initialValues,
        ...person,
      });
      this.setState({ ...initialState });
    }
  }

  render() {
    const { cdReteIva, cdReteIca, cdEntidadVigila } = this.state;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Form
            ref={this.formRef}
            name='tributary_data'
            layout='vertical'
            onFinish={this.handleOnFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdSituacionTributaria'
                  label='Situación tributaria'
                  rules={rules['cdSituacionTributaria'] || ''}
                >
                  <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                    {situacionTributaria.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                    <Col span={12}>
                      <Space>Gran contribuyente:</Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdSnGranContribuyente'
                        valuePropName='checked'
                        noStyle
                      >
                        <Switch checkedChildren='Si' unCheckedChildren='No' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                    <Col span={12}>
                      <Space>Retenedor IVA:</Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdReteIva'
                        valuePropName='checked'
                        noStyle
                      >
                        <Switch
                          checkedChildren='Si'
                          unCheckedChildren='No'
                          onChange={this.handleOnChangeCdReteIva}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              {cdReteIva && (
                <Col span={12}>
                  <Form.Item
                    name='vrBaseReteIva'
                    label='Base IVA'
                    rules={rules['vrBaseReteIva'] || ''}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                      stringMode={true}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                    <Col span={12}>
                      <Space>Retenedor Fuente:</Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdReteFuente'
                        valuePropName='checked'
                        noStyle
                      >
                        <Switch checkedChildren='Si' unCheckedChildren='No' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                    <Col span={12}>
                      <Space>Retenedor ICA:</Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdReteIca'
                        valuePropName='checked'
                        noStyle
                      >
                        <Switch
                          checkedChildren='Si'
                          unCheckedChildren='No'
                          onChange={this.handleOnChangeCdReteIca}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              {cdReteIca && (
                <>
                  <Col span={6}>
                    <Form.Item
                      name='vrBaseReteIca'
                      label='Base ICA'
                      rules={rules['vrBaseReteIca'] || ''}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => currencyInputFormat(value)}
                        parser={(value) => currencyInputParser(value)}
                        stringMode={true}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='poReteIca'
                      label='Porcentaje ICA'
                      rules={rules['poReteIca'] || ''}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => percentageInputFormat(value)}
                        parser={(value) => percentageInputParser(value)}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdEntidadVigila'
                  label='Indique si la empresa es vigilada por una de las siguientes entidades'
                  rules={rules['cdEntidadVigila'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    onChange={this.handleOnChangeCdEntidadVigilada}
                    allowClear
                  >
                    {entidadVigila.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {cdEntidadVigila === 'OTRA' && (
                <Col span={12}>
                  <Form.Item
                    name='otraEntidadVigila'
                    label='Otro ¿cuál?'
                    rules={rules['otraEntidadVigila'] || ''}
                  >
                    <Input minLength={5} maxLength={100} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row align='middle' justify='center'>
              <Col span={6}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Siguiente
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

export default Tributary;
