import React, { useEffect, useState } from 'react';
import {
  Form,
  Col,
  Row,
  Input,
  InputNumber,
  Select,
  Button,
  Radio,
  Divider,
  TimePicker,
  Switch,
} from 'antd';
import moment from 'moment';
import { range } from '../../../../../utilities/validate/numberValidate';
import { maxLength } from '../../../../../utilities/validate/textValidate';

const FondeadorEditForm = ({
  formValue,
  categorias,
  baseDias,
  conceptosACH,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...formValue,
      nmHoraMaxOfertas: moment(formValue.nmHoraMaxOfertas, 'HH:mm'),
      snRecalcOfertaCodigo: formValue.snRecalcOfertaCodigo === 'S',
      estadoRegistroCodigo: formValue.estadoRegistroCodigo === 'A',
    });
  }, [formValue, form]);

  const handleFinish = (value) => {
    const form = {
      ...value,
      nmHoraMaxOfertas: value.nmHoraMaxOfertas.format('HH:mm'),
      snRecalcOfertaCodigo: value.snRecalcOfertaCodigo ? 'S' : 'N',
      estadoRegistroCodigo: value.estadoRegistroCodigo ? 'A' : 'I',
    };

    handleSubmit(form);
  };

  return (
    <div className='header-container'>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleFinish}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='nombreFondeador'
              label='FONDEADOR'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input name='nombreFondeador' readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='tipoTasaFondCodigo'
              label='PORCENTAJE NEGOCIACIÓN'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group name='tipoTasaFondCodigo'>
                <Radio value={'DTF'}>DTF</Radio>
                <Radio value={'IPC'}>IPC</Radio>
                <Radio value={'FIJA'}>FIJA EA</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='poBeneficioFondeador'
              label='TASA FONDEADOR (%)'
              rules={[
                {
                  required: true,
                },

                {
                  validator: (_, value) =>
                    range(value, 0, 100, 'tasa fondeador'),
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation='left'>Información</Divider>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='poBeneficioExponencial'
              label='PORCENTAJE NEGOCIACIÓN EXPONENCIAL (%)'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='categoriaCodigo' label='CATEGORÍA'>
              <Select placeholder='Seleccione' name='categoriaCodigo'>
                <Select.Option key={''} value={''}>
                  Vacio
                </Select.Option>
                {categorias.map((categoria) => (
                  <Select.Option
                    key={categoria.codigo}
                    value={categoria.codigo}
                  >
                    {categoria.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation='left' />

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='poCompraFondeador'
              label='PORCENTAJE DE COMPRA ACTIVO'
              rules={[
                {
                  required: true,
                  message: 'por favor ingrese un porcentaje de compra activo',
                },

                {
                  validator: (_, value) =>
                    range(value, 0, 100, 'porcentaje de compra activo'),
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='nmHoraMaxOfertas'
              label='HORA MÁXIMA DE OFERTA'
              rules={[
                {
                  required: true,
                  message: 'por favor ingrese un hora máxima de ofertas',
                },
              ]}
            >
              <TimePicker format={'HH:mm'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='vrMontoMin'
              label='MONTO MÍNIMO DE GIRO'
              rules={[
                {
                  validator: (_, value) =>
                    range(value, 0, 999999999999, 'monto mínimo de giro'),
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='PUNTOS PORCENTUALES BAJO TASA USURA (%)'
              name='poPuntosBajoUsuraFd'
              rules={[
                {
                  required: true,
                  message: 'por favor ingrese los puntos porcentuales',
                },
                {
                  validator: (_, value) => range(value, 0, 99999),
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item style={{ marginTop: '2.3rem' }}>
              <span className='ant-form-text'>¿Oferta con recalculo?</span>
              <Form.Item
                name='snRecalcOfertaCodigo'
                valuePropName='checked'
                noStyle
              >
                <Switch checkedChildren='Si' unCheckedChildren='No' />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='tipoBaseDiasCodigo' label='BASE DÍAS'>
              <Select placeholder='Seleccione'>
                <Select.Option key={''} value={''}>
                  Vacio
                </Select.Option>
                {baseDias.map((b) => (
                  <Select.Option key={b.codigo} value={b.codigo}>
                    {b.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='idSAPFondeador'
              label='NÚMERO DE IDENTIFICACIÓN (ERP)'
              rules={[
                {
                  required: true,
                  message: 'por favor ingrese número de identificación ERP',
                },
                {
                  validator: (_, value) => maxLength(value, 20),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='dsNemotecnicoPagSAFYR'
              label='NEMOTÉNCICO PAGADOR SAFYR'
              rules={[
                {
                  required: true,
                  message: 'por favor ingrese número nemotécnico safyr',
                },
                {
                  validator: (_, value) => maxLength(value, 20),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='formatoACHCodigo'
              label='FORMATO DEL ACH PARA EL PAGO'
              rules={[
                {
                  required: true,
                  message: 'por favor seleccione un formato ACH para el pago',
                },
              ]}
            >
              <Select placeholder='Seleccione'>
                <Select.Option key={''} value={''}>
                  Vacio
                </Select.Option>
                {conceptosACH.map((concepto) => (
                  <Select.Option key={concepto.codigo} value={concepto.codigo}>
                    {concepto.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item style={{ marginTop: '2.3rem' }}>
              <span className='ant-form-text'>Estado</span>
              <Form.Item
                name='estadoRegistroCodigo'
                valuePropName='checked'
                noStyle
                rules={[
                  {
                    required: true,
                    message: 'por favor seleccione un Estado',
                  },
                ]}
              >
                <Switch checkedChildren='Activo' unCheckedChildren='Inactivo' />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>

        <Row justify='center'>
          <Col span={8}>
            <Form.Item>
              <Button
                className='form-btn btn-radius'
                type='primary'
                htmlType='submit'
              >
                Guardar cambios
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FondeadorEditForm;
