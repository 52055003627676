import Axios from "axios";
import { middleWare } from "../middleware";
import QueryString from "qs";


const baseUrl = process.env.REACT_APP_SERVICES_URL_FACTURACION;


export const listarComisiones = (authtoken,filtros) =>{
    let data = formatoFechas(filtros)
    return middleWare(
        Axios({
            url:`${baseUrl}/api/facturacion/listar-comisiones-pendientes?${QueryString.stringify(
            data)}`,
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data:null
        })
    );
};

export const exportaComisiones = (authtoken, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    Axios({
      url:`${baseUrl}/api/facturacion/listar-comisiones-pendientes/exportar/xlsx?${QueryString.stringify(
      data)}`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const generarFacturacion = (authtoken,data,fechaFacturacion) =>{
    let feFacturacion = formatoFechas(fechaFacturacion)
    return middleWare(
        Axios({
            url:`${baseUrl}/api/facturacion/generar-facturacion?${QueryString.stringify(
            feFacturacion)}`,
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data:data
        })
    );
};



export const listarFacturasGeneradas = (authtoken,filtros) =>{
    let data = formatoFechas(filtros)
    return middleWare(
        Axios({
            url:`${baseUrl}/api/facturacion/consultar-facturas-generadas?${QueryString.stringify(
            data)}`,
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data:null
        })
    );
};

export const exportaFacturasGeneradas  = (authtoken, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    Axios({
      url:`${baseUrl}/api/facturacion/consultar-facturas-generadas/exportar/xlsx?${QueryString.stringify(
      data)}`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const detalleFacturaComision = (authtoken,nmFactura) =>{
    return middleWare(
        Axios({
            url:`${baseUrl}/api/facturacion/detalle-comisiones-pendientes?nmFactura=${nmFactura}`,
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data:null
        })
    );
};

export const enviarACadena= (authtoken,data) =>{
    return middleWare(
        Axios({
            url:`${baseUrl}/api/facturacion/enviar-cadena-facturacion`,
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data
        })
    );
};

export const anularFactura = (authtoken,bill) =>{
    return middleWare(
        Axios({
            url:`${baseUrl}/api/facturacion/anular-facturas?nmFactura=${bill}`,
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data:null
        })
    );
};

export const insertarFacturaManual= (authtoken,data) =>{
  let filtro = formatoFechas(data);
    return middleWare(
        Axios({
            url:`${baseUrl}/api/facturacion/generar-factura-manual?${QueryString.stringify(
              filtro)}`,
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`
            },
            data:null
        })
    );
};


const formatoFechas = (filtros) => {
    Object.keys(filtros).forEach((nombre) => {
      if (nombre !== 'resultado') {
        if (
          (nombre.includes('Desde') || nombre.includes('Hasta') 
          || nombre.includes('fe'))  &&
          typeof filtros[nombre] === 'object'
        ) {
          filtros[nombre] = filtros[nombre].format('YYYYMMDD');
        }
      }
    });
    return filtros;
  };