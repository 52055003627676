import React, { useEffect, useState } from 'react';
import { Spin, Form, Col, Row, Select, Button } from 'antd';
import '../quotesagreements.scss';
import { BUSQUEDA_TITLE } from '../../../../../utilities/messages';
import { currencyFormat, dateFormat } from '../../../../../utilities/textTools';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const initialValue = {
  scGrupo: '',
  scEntidad: '',
  tipoEntidad: 'PAG',
};

const QuotasAgreementsFilterFrom = ({
  scEntidad,
  fondeadorSelect,
  entidadSelect,
  handleOnSearchFondeador,
  handleOnSearchEntidad,
  handleOnChangeFondeador,
  handleOnChangeEntidad,
  handleOnChangeTipoEntidad,
  handleOnSearchCupos,
  nmCupoContraparte,
  consCupoOferta,
  liquidez,
  liquidezNegociada,
  liquidezOfertada,
  feHasta,
  tipoCupo,
  tipoProducto
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  const entidades = [
    { key: 'PAG', value: 'Pagador' },
    { key: 'PRO', value: 'Proveedor' },
    { key: 'GRP', value: 'Grupo empresarial' },
  ];

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        initialValues={initialValue}
        onFinish={handleOnSearchCupos}
      >
        <Row gutter={16}>
          <Col span={18}>
            <Form.Item
              name='scFondeador'
              label='Fondeador'
              dependencies={['scFondeador']}
              rules={[{ required: true, message: 'seleccionar un fondeador' }]}
            >
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchFondeador(value)}
                onChange={handleOnChangeFondeador}
                notFoundContent={
                  fondeadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {fondeadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='second-title'>
              LIQUIDEZ TOTAL: {currencyFormat(liquidez)}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='second-title'>
              LIQUIDEZ DISPONIBLE PARA OFERTAR:{' '}
              {currencyFormat(liquidez - liquidezOfertada)}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='second-title'>
              LIQUIDEZ DISPONIBLE PARA NEGOCIAR:{' '}
              {currencyFormat(liquidez - liquidezNegociada)}
            </p>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={5}>
            <Form.Item name='tipoEntidad' label='Tipo Entidad' required='true'>
              <Select onChange={handleOnChangeTipoEntidad}>
                {entidades.map((d) => (
                  <Select.Option key={d.key} value={d.key}>
                    {d.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              name='scEntidad'
              label='Entidad'
              rules={[{ required: true, message: 'seleccionar una entidad' }]}
            >
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchEntidad(value)}
                onChange={handleOnChangeEntidad}
                notFoundContent={
                  entidadSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {entidadSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                name='tipoProducto' 
                label='Tipo de Producto'
                rules={[
                      {
                        required: true,
                        message: 'debe seleccionar el tipo de producto',
                      }
              ]}>
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
            >
              {tipoProducto.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item 
                name='tipoCupo' 
                label='Tipo de Cupo'
                rules={[
                      {
                        required: true,
                        message: 'debe seleccionar el tipo de cupo',
                      }
                    ]}>
                <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
            >
              {tipoCupo.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
                </Form.Item>
              </Col>

              </Row>
            <Row>
              <Col span={3}>
            <Form.Item>
              <Button
              type='primary'
              htmlType='submit'
                // onClick={(value) => handleOnSearchCupos(value)}
              >
                Buscar Cupos
              </Button>
            </Form.Item>
          </Col>
          </Row>

        <Row>
          <Col>
            <p className='second-title'>
              CUPO CONTRAPARTE ASIGNADO ACTUAL:{' '}
              {currencyFormat(nmCupoContraparte)}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className='second-title'>
              CUPO DISPONIBLE PARA OFERTAR:{' '}
              {currencyFormat(nmCupoContraparte - consCupoOferta)}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className='second-title'>
              FECHA DE VIGENCIA: {dateFormat(feHasta)}
            </p>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default QuotasAgreementsFilterFrom;
