import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

var baseUrl = process.env.REACT_APP_SERVICES_URL;

/**
 *
 * @param {*} authToken
 */
const consultarPaises = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-paises?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

/**
 *
 * @param {*} authToken
 * @param {*} idPais
 */
const consultarDepartamentos = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-departamentos?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

/**
 *
 * @param {*} authToken
 * @param {*} idDepartamento
 */
const consultarMunicipios = (authToken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-municipios?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

/**
 *
 * @param {*} authToken
 */
const consultarCompanias = (authToken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/listarCompanias`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export default {
  consultarPaises,
  consultarDepartamentos,
  consultarMunicipios,
  consultarCompanias,
};
