export const form = {
  reporte_logs:
    [
      [
        {
          nombre: "fechaDesde",
          label: "* DESDE",
          tipo: "date",
          required:true
        },
        {
          nombre: "fechaHasta",
          label: "* HASTA",
          tipo: "date",
          required:true
        }
      ],
      [
        {
          nombre: "nivel",
          label: "NIVEL",
          tipo: "select_nosearch",
          selectType: "nivelesLogsOptions",
          loadFunction: "listaNivelesLogs"
        },
        {
          nombre: "hilo",
          label: "HILO DE EJECUCIÓN",
          tipo: "string",
        },
      ],
      [
        {
          nombre: "logger",
          label: "LOGGER",
          tipo: "string",

        },
        {
          nombre: "mensaje",
          label: "MENSAJE",
          tipo: "string"
        },
      ],

    ],
  descarga_logs:
    [
      [
        {
          nombre: "cdLog",
          label: "LOG",
          tipo: "select_nosearch",
          selectType: "tiposLogsOptions",
          loadFunction: "listaTipoLogs"
        },
        {
          nombre: "indice",
          label: "INDICE",
          tipo: "string"
        }
      ],

    ],
}