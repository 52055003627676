import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification, Modal } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import TableReport from '../../../../components/tableReport/tableReport';
import { sorterText } from '../../../../utilities/textTools';
import { setExcel } from '../../../../utilities/downloadTools';
import ErrorsErpForm from './errorsErpForm/errorsErpForm';
import { error, errorExcel } from '../../../../services/pagador/reportServices';

const initialState = {
  resetForm: false,
  form: {
    fechaDesde: '',
    fechaHasta: '',
  },
  modal: {
    details: '',
    isModalVisible: false,
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class ErrorsErp extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
    this.columnas = [
      {
        title: 'COMPAÑIA',
        dataIndex: 'nombreCompania',
        sorter: (a, b) => sorterText(a.nombreCompania - b.nombreCompania),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA',
        dataIndex: 'feError',
        sorter: (a, b) =>
          Date.parse(a.feEstimadaPago) - Date.parse(b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'PROCEDIMIENTO',
        dataIndex: 'cdProcedimiento',
        sorter: (a, b) => sorterText(a.totalPendiente - b.totalPendiente),
        showSorterTooltip: false,
      },
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'dsError',
        sorter: (a, b) => sorterText(a.totalPendiente - b.totalPendiente),
        showSorterTooltip: false,
        className: 'error-column',
      },
      {
        title: 'DETALLE',
        dataIndex: 'dsParametros',
        showSorterTooltip: false,
        render: (text, record) => (
          <Button
            key={text}
            className='form-btn btn-radius btn-details'
            type='primary'
            htmlType='button'
            onClick={() => this.getToDetails(text, record)}
          >
            DETALLE
          </Button>
        ),
      },
    ];
  }

  getToDetails = (text, record) => {
    this.setState({
      ...this.state,
      modal: {
        details: text,
        isModalVisible: true,
      },
    });
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        fechaDesde: value.fechaRango[0],
        fechaHasta: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        loading: true,
      });

      try {
        const response = await error(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await errorExcel(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );
        setExcel(response, `Reporte_facturas_en_tramites`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };
  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesde;
    const fechaHasta = this.state.form.fechaHasta;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleOnReset = () => {
    this.setState({
      ...this.state,
      resetForm: false,
      form: {
        ...initialState.form,
      },
    });
  };

  componentDidUpdate(props, state) {
    if (this.props.company.company != props.company.company) {
      this.setState({
        ...initialState,
        resetForm: true,
      });
    }
  }

  handleModalOk = () => {
    this.setState({
      ...this.state,
      modal: '',
      isModalVisible: false,
    });
  };

  render() {
    const { loading, resetForm, modal } = this.state;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <ErrorsErpForm
            resetForms={resetForm}
            handleSubmit={this.handleSubmit}
            handleOnReset={this.handleOnReset}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={this.state.currentPage}
              limit={this.state.limit}
              total={this.state.total}
              columns={this.columnas}
              data={this.state.resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
        <Modal
          title='DETALLE'
          okText='Aceptar'
          destroyOnClose={true}
          closable={false}
          visible={modal.isModalVisible}
          footer={[
            <Button
              key='back'
              type='primary'
              className='form-btn btn-radius'
              onClick={this.handleModalOk}
            >
              Aceptar
            </Button>,
          ]}
        >
          <pre>{this.state.modal.details}</pre>
        </Modal>
      </div>
    );
  }
}

export default ErrorsErp;
