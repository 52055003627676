import { FormOutlined, WarningTwoTone } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
import QueryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  executeTaskAccionesConsultaEventos,
  executeTaskAccionesEventos,
  listarEventosDE,
  listarEventosFacturas,
  listarEventosFacturasExcel,
} from '../../../../services/admin/radian/billsEventsService';
import { listarTieneEventoDE } from '../../../../services/admin/radian/radianService';
import {
  listarFondeadores,
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../services/admin/reports/quotasService';
import history from '../../../../services/history';
import { setExcel } from '../../../../utilities/downloadTools';
import { DATE_POST_FORMAT } from '../../../../utilities/messages';
import { sorterDate, sorterText } from '../../../../utilities/textTools';
import BillEventsFilterForm from './billEventsFilterForm/billEventsFilterForm';
import './billsEvents.scss';

const { Text } = Typography;

const initialState = {
  loading: true,
  form: {
    fechaDesdeCompra: moment().subtract(1, 'days'),
    fechaHastaCompra: moment(),
    feDesdeVenRadian: moment().subtract(1, 'days'),
    feHastaVenRadian: moment(),
  },
  resultado: [],
  total: 0,
  limit: 10,
  offset: 0,
  sort: '',
  currentPage: 1,
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  GrupoEmpresarialSelect: {
    fetching: false,
    options: [],
  },
  tieneEventoSelect: [],
  tiposEventos: [],
  selectedKeys: [],
  selectAll: false,
  unselectedKeys: [],
  modalOK: false,
  switche: false
};

class BillsEvents extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'NUMERO FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE COMPRA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'EVENTOS REGISTRADO',
        sorter: (a, b) => {
          const eventosA = a.eventos.toString();
          const eventosB = b.eventos.toString();
          return sorterText(eventosA, eventosB);
        },
        render: (record) => {
          return (
            <Space>
              <Popover
                title={<b>{'Listas de eventos'}</b>}
                content={
                  <ul>
                    {record.eventos &&
                      record.eventos.map((el) => (
                        <li>
                          {`${el.dsTipoEventoDE}  - `}
                          <Text italic>{el.dsTipoRegistroEvento}</Text>
                        </li>
                      ))}
                  </ul>
                }
              >
                <Text>
                  {record.eventos &&
                    record.eventos.map((el) => el.cdTipoEventoDE).join(', ')}
                </Text>
              </Popover>
            </Space>
          );
        },
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: 'DETALLE',
        render: (text, record) => (
          <FormOutlined
            title='Ver Eventos'
            onClick={() => this.showEditBillsEvent(record)}
            className='info'
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  componentDidMount() {
    this.props.menuHandler('111');
    this.handleOnSearchEventos();
    this.handleOnSearchTipoEvento();
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.init(nextProps);
    }
  }

  init = async (props) => {
    const filtro = this.parseQueryString(props);

    if (
      !isNaN(filtro.currentPage) &&
      !isNaN(filtro.limit) &&
      !isNaN(filtro.offset)
    ) {
      await this.updateStatePage(filtro);
      await this.getData(filtro);
    } else {
      this.setState({ loading: false });
    }
  };

  updateStatePage = async (filtro) => {
    this.state.limit = filtro.limit || 10;
    this.state.offset = filtro.offset || 0;
    this.state.currentPage = filtro.currentPage || 1;
    this.state.sort = filtro.sort || '';

    let scFondeador;
    if (filtro.fondeador) {
      if (this.state.fondeadorSelect.options.length === 0) {
        await this.handleOnSearchFondeador(filtro.fondeador);
      }

      scFondeador = this.state.fondeadorSelect.options.find(
        (el) => el.descripcion === filtro.fondeador
      ).codigo;
    }

    let scGrupo;
    if (filtro.grupo) {
      if (this.state.GrupoEmpresarialSelect.options.length === 0) {
        await this.handleOnSearchGrupoEmpresarial(filtro.grupo);
      }

      scGrupo = this.state.GrupoEmpresarialSelect.options.find(
        (el) => el.descripcion === filtro.grupo
      ).codigo;
    }

    let scProveedor;
    if (filtro.proveedor) {
      if (this.state.proveedorSelect.options.length === 0) {
        await this.handleOnSearchProveedor(filtro.proveedor);
      }

      scProveedor = this.state.proveedorSelect.options.find(
        (el) => el.descripcion === filtro.proveedor
      ).codigo;
    }

    let scPagador;
    if (filtro.pagador) {
      if (this.state.pagadorSelect.options.length === 0) {
        await this.handleOnSearchPagador(filtro.pagador);
      }

      scPagador = this.state.pagadorSelect.options.find(
        (el) => el.descripcion === filtro.pagador
      ).codigo;
    }

    this.state.form = {
      cdFactura: filtro.cdFactura,
      cufe: filtro.cufe,
      scFondeador,
      scGrupo,
      scPagador,
      scProveedor,
      fechaDesdeCompra: filtro.fechaDesdeCompra,
      fechaHastaCompra: filtro.fechaHastaCompra,
      feDesdeVenRadian: filtro.feDesdeVenRadian,
      feHastaVenRadian: filtro.feHastaVenRadian,
      eventos: filtro.eventos,
      tipoEventosDE: filtro.tipoEventosDE,
    };
  };

  parseQueryString = (props) => {
    const {
      location: { search },
    } = props;

    const filtro = QueryString.parse(search, { ignoreQueryPrefix: true });

    return {
      ...filtro,
      fechaDesdeCompra: filtro.fechaDesdeCompra
        ? moment(filtro.fechaDesdeCompra, DATE_POST_FORMAT)
        : initialState.form.fechaDesdeCompra,
      fechaHastaCompra: filtro.fechaHastaCompra
        ? moment(filtro.fechaHastaCompra, DATE_POST_FORMAT)
        : initialState.form.fechaHastaCompra,
        feDesdeVenRadian: filtro.feDesdeVenRadian
        ? moment(filtro.feDesdeVenRadian, DATE_POST_FORMAT)
        : initialState.form.feDesdeVenRadian,
        feHastaVenRadian: filtro.feHastaVenRadian
        ? moment(filtro.feHastaVenRadian, DATE_POST_FORMAT)
        : initialState.form.feHastaVenRadian,
      limit: parseInt(filtro.limit),
      currentPage: parseInt(filtro.currentPage),
      offset: parseInt(filtro.offset),
    };
  };

  showEditBillsEvent = (record) => {
    history.push(
      `${process.env.PUBLIC_URL}/billEvent/details/${Buffer.from(
        record.scFactura
      ).toString('base64')}`
    );
  };

  handleSubmit = (value) => {
    const { limit, sort } = this.state;
    if (value.eventos === 'TODOS') value.eventos = undefined;
    if(value.fechaRango){
      this.doChangeHistory({
        ...value,
        fechaRango: undefined,
        fechaDesdeCompra: value.fechaRango[0],
        fechaHastaCompra: value.fechaRango[1],
        currentPage: initialState.currentPage,
        offset: initialState.offset,
        limit,
        sort,
      });
    } else {
      this.doChangeHistory({
        ...value,
        fechaRangoVenRadian: undefined,
        feDesdeVenRadian: value.fechaRangoVenRadian[0],
        feHastaVenRadian: value.fechaRangoVenRadian[1],
        currentPage: initialState.currentPage,
        offset: initialState.offset,
        limit,
        sort,
      });
    }
    this.setState({
      ...this.state,
      switche: value.Switch ? true : false,
    });
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarEventosFacturas(
        this.props.authToken,
        this.getFiltro()
      );
      this.setState(() => ({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        loading: false,
      }));
    } catch (error) {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort, switche } = this.state;
    
    let objeto = {
      ...form,
      fechaDesdeCompra: form.fechaDesdeCompra ? form.fechaDesdeCompra.format(DATE_POST_FORMAT) : null,
      fechaHastaCompra: form.fechaHastaCompra ? form.fechaHastaCompra.format(DATE_POST_FORMAT) : null,
      feDesdeVenRadian: form.feDesdeVenRadian ? form.feDesdeVenRadian.format(DATE_POST_FORMAT) : null,
      feHastaVenRadian: form.feHastaVenRadian ? form.feHastaVenRadian.format(DATE_POST_FORMAT) : null,
      limit: limit,
      offset: offset,
      sort: sort, 
    };
    if(switche){
      delete objeto.fechaDesdeCompra;
      delete objeto.fechaHastaCompra;
    } else {
      delete objeto.feDesdeVenRadian;
      delete objeto.feHastaVenRadian;
    }
      return objeto;   
  };

  handleChange = (e) => {
    const { form } = this.state;
    this.doChangeHistory({ ...form, ...e });
  };

  doChangeHistory = (e) => {
    const {
      fondeadorSelect,
      GrupoEmpresarialSelect,
      proveedorSelect,
      pagadorSelect,
    } = this.state;

    const eventos = e.eventos;
    const tipoEventosDE = e.tipoEventosDE;

    const fondeador = e.scFondeador
      ? fondeadorSelect.options.find((el) => el.codigo === e.scFondeador)
          .descripcion
      : undefined;

    const grupo = e.scGrupo
      ? GrupoEmpresarialSelect.options.find((el) => el.codigo === e.scGrupo)
          .descripcion
      : undefined;

    const proveedor = e.scProveedor
      ? proveedorSelect.options.find((el) => el.codigo === e.scProveedor)
          .descripcion
      : undefined;

    const pagador = e.scPagador
      ? pagadorSelect.options.find((el) => el.codigo === e.scPagador)
          .descripcion
      : undefined;

    const search = QueryString.stringify({
      fondeador,
      grupo,
      proveedor,
      pagador,
      cdFactura: e.cdFactura,
      cufe: e.cufe,
      sort: e.sort !== '' ? e.sort : undefined,
      limit: e.limit,
      offset: e.offset,
      currentPage: e.currentPage,
      fechaDesdeCompra: e.fechaDesdeCompra ? e.fechaDesdeCompra.format(DATE_POST_FORMAT) : null,
      fechaHastaCompra: e.fechaHastaCompra ? e.fechaHastaCompra.format(DATE_POST_FORMAT): null,
      feDesdeVenRadian: e.feDesdeVenRadian ? e.feDesdeVenRadian.format(DATE_POST_FORMAT) : null,
      feHastaVenRadian: e.feHastaVenRadian ? e.feHastaVenRadian.format(DATE_POST_FORMAT) : null,
      eventos,
      tipoEventosDE,
    });

    history.push({
      pathname: `${process.env.PUBLIC_URL}/radian/billsEvents`,
      search,
    });
  };

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchEventos = async (nombre) => {
    const { tieneEventoSelect, limit } = this.state;
    this.setState({
      ...this.state,
      limit: 30,
    });

    try {
      const response = await listarTieneEventoDE(this.props.authToken, limit);
      this.setState({
        ...this.state,
        tieneEventoSelect: response.data,
      });
    } catch {
      this.setState({
        ...this.state,
        tieneEventoSelect: [],
      });
    }
  };

  handleOnSearchTipoEvento = async () => {
    const { authToken } = this.props;
    this.setState({
      ...this.state,
    });
    try {
      const respEventos = await listarEventosDE(authToken);

      this.setState({
        ...this.state,
        tiposEventos: respEventos.data,
      });
    } catch {
      this.setState({
        ...this.state,
        tiposEventos: [],
      });
    }
  };

  handleOnSearchGrupoEmpresarial = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { GrupoEmpresarialSelect } = this.state;

      this.setState({
        ...this.state,
        GrupoEmpresarialSelect: {
          ...GrupoEmpresarialSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          GrupoEmpresarialSelect: {
            ...GrupoEmpresarialSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          GrupoEmpresarialSelect: {
            ...GrupoEmpresarialSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarEventosFacturasExcel(
        this.props.authToken,
        this.getFiltro()
      );
      setExcel(response, 'Listado_de_Eventos_Facturas_DE');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      changeRows.forEach((row) => {
        let selected = this.state.selectedKeys.find((key) => row.key === key);
        if (!selected) {
          this.state.selectedKeys = [...this.state.selectedKeys, row.key];
        }
      });
      this.setState({
        ...this.state,
        unselectedKeys: [],
        selectAll: selected,
      });
    } else {
      this.setState({
        ...this.state,
        selectedKeys: [],
        unselectedKeys: [],
        selectAll: selected,
      });
    }
  };

  onSelect = (record, selected) => {
    const { selectAll } = this.state;
    let unselectedKeys = [];
    if (selected) {
      if (this.state.selectAll) {
        unselectedKeys = this.state.unselectedKeys.filter(
          (key) => key !== record.key
        );
      }
      this.setState({
        ...this.state,
        selectedKeys: [...this.state.selectedKeys, record.key],
        unselectedKeys: unselectedKeys,
      });
    } else {
      if (selectAll) {
        unselectedKeys = [...this.state.unselectedKeys, record.key];
      }
      let filterKeys = this.state.selectedKeys.filter(
        (selectedKey) => selectedKey !== record.key
      );
      this.setState({
        ...this.state,
        selectedKeys: filterKeys,
        unselectedKeys: unselectedKeys,
      });
    }
  };

  executeAction = (task) => {
    const { selectedKeys } = this.state;
    const { authToken } = this.props;

    let scPersonaModifica = localStorage.getItem('auth-token');
    let scPersona = JSON.parse(scPersonaModifica).scPersona;

    this.setState({
      ...this.state,
      loading: false,
    });

    this.state.modalOK = true;

    let keys = selectedKeys;

    let executeTask;
    if (task === 'CEF') {
      executeTask = executeTaskAccionesConsultaEventos;
    } else {
      executeTask = executeTaskAccionesEventos;
    }

    executeTask(authToken, keys, task, scPersona)
      .then(() => {
        this.getData();
        notification.success({
          message: 'Acción realizada con éxito',
          description: 'La tarea se ejecutó correctamente',
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: 'Ocurrió un error al ejecutar la tarea',
        });
      });
  };

  showConfirm = (task) => {
    const { selectedKeys } = this.state;
    confirm({
      title: '¿Deseas Realizar Esta Acción?',
      icon: <WarningTwoTone />,
      content: `Las Cantidad de Facturas Seleccionadas Son: ${selectedKeys.length}`,
      onOk: () => this.executeAction(task),
      onCancel: this.setState({ ...this.state, modalOK: false }),
    });
  };

  render() {
    const {
      loading,
      total,
      resumen,
      resultado,
      fondeadorSelect,
      pagadorSelect,
      proveedorSelect,
      GrupoEmpresarialSelect,
      currentPage,
      limit,
      form,
      tieneEventoSelect,
      tiposEventos,
      selectedKeys,
    } = this.state;

    this.rowSelection = {
      type: 'checkbox',
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      selectedRowKeys: selectedKeys,
    };

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='bills-events-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <BillEventsFilterForm
              data={form}
              fondeadorSelect={fondeadorSelect}
              pagadorSelect={pagadorSelect}
              proveedorSelect={proveedorSelect}
              grupoEmpresarialSelect={GrupoEmpresarialSelect}
              onSearchFondeador={this.handleOnSearchFondeador}
              onSearchPagador={this.handleOnSearchPagador}
              onSearchProveedor={this.handleOnSearchProveedor}
              onSearchGrupoEmpresarial={this.handleOnSearchGrupoEmpresarial}
              handleSubmit={this.handleSubmit}
              tieneEventoSelect={tieneEventoSelect}
              tiposEventos={tiposEventos}
            />

            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de Factura
                  </Space>
                </Col>
                {this.state.resultado.length > 0 ? (
                  <Col flex='none'>
                    <Space align='baseline'>
                      <Button type='link' onClick={this.exportExcel}>
                        Exportar a excel
                      </Button>
                    </Space>
                  </Col>
                ) : null}
              </Row>

              <Divider />

              <Row gutter={30} className='bill-selection' justify='end'></Row>

              <Row gutter={30}>
                <Col span={24}>
                  <TableReport
                    keyIndex={'scFactura'}
                    rowSelection={this.rowSelection}
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>

              {/* Botones Radian */}
              {resultado.length > 0 && (
                <Row gutter={[45, 30]} justify='center' align='middle'>
                  <Col span={6}>
                    <Button
                      className='btn btn-blue btn-radius'
                      type='primary'
                      onClick={() => this.showConfirm('CEF')}
                    >
                      Consultar Eventos
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      style={{ minWidth: '135px', fontSize: '0.9rem' }}
                      className='btn btn-blue btn-radius'
                      type='primary'
                      onClick={() => this.showConfirm('AT')}
                    >
                      Aceptación Tácita
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      className='btn form-btn-blue btn-radius'
                      type='primary'
                      onClick={() => this.showConfirm('FETV')}
                    >
                      Inscribir
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      className='btn form-btn-blue btn-radius'
                      type='primary'
                      onClick={() => this.showConfirm('FETVP')}
                    >
                      Inscripción Posterior
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      className='btn btn-blue btn-radius'
                      type='primary'
                      onClick={() => this.showConfirm('EPP')}
                    >
                      Endosar
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps)(BillsEvents));
