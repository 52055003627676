import React, { Component } from 'react';
import { Row, Col, Button, Table, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import equal from 'fast-deep-equal';
import { percentageFormat } from '../../../../../utilities/textTools';
import Shareholder, {
  initialValuesShareholder,
} from '../../../../../components/shareholder';
import { v4 as uuidv4 } from 'uuid';
import { JsonWebTokenError } from 'jsonwebtoken';
/* import { updatePersonState } from '../../../../store/actions/proveedor/personActions'; */

const initialState = {
  accionistas: [],
  visibleModal: false,
  data: initialValuesShareholder,
};

class Shareholders extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'TIPO DE DOCUMENTO',
        dataIndex: 'dsTipoId',
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
      },
      {
        title: 'NOMBRE Y APELLIDOS',
        dataIndex: 'nombre',
      },
      {
        title: 'PARTICIPACIÓN',
        dataIndex: 'poParticipacion',
        render: (value) => <span>{percentageFormat(value)}</span>,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <EditOutlined
            className='info'
            onClick={() => this.handleOnOpenModal(record)}
          />
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <DeleteOutlined
            className='info'
            onClick={() => this.deleteShareholder(record)}
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  deleteShareholder = (record) => {
    const { accionistas } = this.state;

    let accTemp = accionistas.filter((value) => value.key !== record.key);

    this.setState({ ...this.state, accionistas: accTemp });
  };

  handleOnFinish = () => {
    const { onFinish } = this.props;
    const { accionistas } = this.state;

    if (accionistas.length === 0) {
      notification.warn({
        title: 'Alerta',
        message: 'Debe añadir los accionistas',
        duration: 4,
      });
      return;
    }

    onFinish('7', { accionistas: accionistas });
  };

  handleOnOpenModal = (record) => {
    this.setState({ ...this.state, visibleModal: true, data: record });
  };

  handleOnFinishModal = (formValue) => {
    const { accionistas } = this.state;

    let accTemp = [];

    if (formValue.key && formValue.key !== '') {
      accTemp = accionistas.map((value) =>
        value.key === formValue.key ? formValue : value
      );
    } else {
      let uuid = uuidv4();
      accTemp = [{ ...formValue, key: uuid }, ...accionistas];
    }

    this.setState({
      ...this.state,
      accionistas: accTemp,
      visibleModal: false,
      data: initialValuesShareholder,
    });
  };

  handleOnCloseModal = () => {
    this.setState({
      ...this.state,
      visibleModal: false,
    });
  };

  componentDidMount() {
    if (this.props.person) {
      this.init();
    }
  }

  componentDidUpdate(props, state) {
    if (!equal(props.person, this.props.person)) {
      this.init();
    }
  }

  init = () => {
    const {
      person: { accionistas },
    } = this.props;

    this.setState({
      ...this.state,
      accionistas: accionistas
        ? accionistas.map((accionista) => ({
            ...accionista,
            key: accionista.scAccionista,
          }))
        : [],
    });
  };

  render() {
    const { accionistas, visibleModal, data } = this.state;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='end'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6} style={{ textAlign: 'right' }}>
              <Button
                className='btn-radius'
                type='primary'
                onClick={() => this.handleOnOpenModal(initialValuesShareholder)}
              >
                Nuevo Accionista
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
            <Col flex='auto' style={{ overflow: 'auto' }}>
              <Table
                style={{ width: '100%' }}
                columns={this.columnas}
                dataSource={accionistas}
                pagination={false}
              ></Table>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='center'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6}>
              <Button
                className='form-btn btn-radius'
                type='primary'
                htmlType='submit'
                onClick={this.handleOnFinish}
              >
                Siguiente
              </Button>
            </Col>
          </Row>

          <Shareholder
            visible={visibleModal}
            data={data}
            onFinish={this.handleOnFinishModal}
            onClose={this.handleOnCloseModal}
          />
        </div>
      </div>
    );
  }
}

export default Shareholders;
