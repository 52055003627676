import React, { useEffect, useState } from 'react';
import {
  Spin,
  Form,
  Col,
  Row,
  Switch,
  Button,
  Modal,
  notification,
  InputNumber,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { adicionarNuevaLiquidez } from '../../../../../services/fondeador/operationServices';
import { range } from '../../../../../utilities/validate/numberValidate';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../../../utilities/textTools';

const initialValue = {
  liquidez: 0,
  descripcion: '',
  SnOfertaFull: false,
};

const NewLiquidityForm = ({ liquidez, scFondeador, authToken, onFinish }) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...liquidez,
        snOfertaFull: liquidez.SnOfertaFull === 'S',
      });
    }
  }, [form, liquidez, visible]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const formValue = await form.validateFields();
      const data = {
        ...formValue,
        snOfertaFull: formValue.snOfertaFull ? 'S' : 'N',
      };

      const response = await adicionarNuevaLiquidez(
        authToken,
        scFondeador,
        data
      );

      notification.success({
        message: 'Nueva liquidez asignada',
        description: response.message,
        duration: 4,
      });

      setVisible(false);
      onFinish(data);
    } finally {
      setLoading(false);
    }
  };

  const closeWindow = () => {
    setVisible(false);
  };

  const openModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button size='small' onClick={() => openModal()} className='btn-quota'>
        + Asignar nueva liquidez
      </Button>
      <Modal
        title='Asignar nueva liquidez'
        className='modal-style'
        width={720}
        visible={visible}
        closable={false}
        onOk={handleOk}
        onCancel={closeWindow}
        okText='Grabar'
        cancelButtonProps={{ className: 'btn-radius' }}
        okButtonProps={{ type: 'primary', className: 'ok-btn btn-radius' }}
      >
        <div className='header-container'>
          <Spin tip='Cargando...' spinning={loading}>
            <Form
              name='content_form'
              layout='vertical'
              className='filters-container'
              form={form}
              initialValues={initialValue}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name='liquidez'
                    label='LIQUIDEZ'
                    rules={[
                      {
                        required: true,
                        message: 'ingrese un valor de liquidez',
                      },
                      {
                        validator: (_, value) => {
                          return range(value, 0, 999999999999999);
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => currencyInputFormat(value)}
                      parser={(value) => currencyInputParser(value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={18}>
                        <p>¿Desea ofertar todas las facturas?</p>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name='snOfertaFull'
                          valuePropName='checked'
                          noStyle
                        >
                          <Switch checkedChildren='Si' unCheckedChildren='No' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name='descripcion'
                    label='OBSERVACIONES'
                    rules={[
                      {
                        required: true,
                        message: 'debe ingresar una observación',
                      },
                    ]}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default NewLiquidityForm;
