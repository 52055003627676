import {
  PERSON_REQUEST,
  PERSON_J_LOADED,
  PERSON_N_LOADED,
  PERSON_UPDATE_STATE,
  PERSON_SAVE_REQUEST,
  PERSON_SAVE_SUCCESS
}
  from '../../actions/types'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case PERSON_REQUEST:
      return {
        ...state,
        loading: true
      }
    case PERSON_J_LOADED:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case PERSON_N_LOADED:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case PERSON_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case PERSON_SAVE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case PERSON_SAVE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    default:
      return {
        ...state,
      }
      break;
  }
}