import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import store from '../store/index'
import TyC from '../pages/proveedor/content/TYC'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  let { isAuthenticated, snAceptaCondiciones } = store.getState().auth
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          if (snAceptaCondiciones === 'S') {
            return <Component {...props} />
          }
          else {
            return <TyC {...props} hadAccepted="false"/>
          }
        }
        else {
          return <Redirect to={
            {
              pathname: `${process.env.PUBLIC_URL}/login`,
              state: {
                from: props.location
              }
            }
          }
          />
        }

      }}
    />
  )
}

export default ProtectedRoute