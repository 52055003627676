import React from 'react';
import { Row, Card, Col, Steps, Spin } from 'antd';
import './affitoken.scss'
import Configure from './configure/configure'
import Send from './send/send'
import Confirm from './confirm/confirm'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUserAction } from '../../../store/actions/proveedor/affitokenActtions'
import equal from 'fast-deep-equal'

const { Step } = Steps;

class Affitoken extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      affitoken: props.affitoken
    }
  }

  componentDidMount() {
    this.props.menuHandler('16')
    if (this.props.authentication.company)
      this.props.getUserAction(this.props.authentication)
  }

  componentDidUpdate(props, state) {
    if (equal(this.props.affitoken.user, { tipoEnvioOtp: "EMAIL" }) && this.props.authentication.company) {
      this.props.getUserAction(this.props.authentication)
    }
    if (!equal(this.props.affitoken, props.affitoken)) {
      this.setState({
        ...this.state,
        affitoken: this.props.affitoken
      })
    }
  }

  render() {
    return (

      <div className="affitoken-style">
        <Row className="">
          <Col span={24}>
            <Card className="card-shadow default-border radius-3">
              <Spin tip="Cargando..." spinning={(equal(this.props.affitoken.user, {}) && !this.props.authentication.company)}>
                <Row className="standard-steps steps-container">

                  <Steps progressDot current={this.state.affitoken.currentStep} >
                    <Step title="1" />
                    <Step title="2" />
                    {this.state.affitoken.user.estado !== 'Activo' ? < Step title="3" /> : null}
                  </Steps>
                </Row>
                {this.state.affitoken.currentStep === 0 ? <Configure user={this.state.affitoken.user} /> : ''}
                {this.state.affitoken.currentStep === 1 ? <Send user={this.state.affitoken.user} /> : ''}
                {this.state.affitoken.currentStep === 2 ? <Confirm method={this.state.affitoken.selectedSendMethod} /> : ''}
              </Spin>
            </Card>
          </Col>
        </Row>
      </div>
    );

  }
}

Affitoken.propTypes = {
  authentication: PropTypes.object.isRequired,
  affitoken: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    authentication: {
      authtoken: state.auth.auth.access_token,
      company: state.billing.company,
    },
    affitoken: state.affitoken
  }
}
export default connect(mapStateToProps, { getUserAction })(Affitoken);