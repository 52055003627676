import { Button, Modal, notification, Spin } from 'antd';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CalculateVolumeModal from '../../../../../../components/calculateVolumeModal/calculateVolumeModal';
import {
  calcularRangos,
  crearConvenioFondeadorConfirming,
  crearConvenioFondeadorProntoPago,
  listaFormatoACH,
  listaTipoBaseDias,
} from '../../../../../../services/admin/operation';
import { listarFondeadores } from '../../../../../../services/admin/reports/quotasService';
import { isEqualsToObject } from '../../../../../../utilities/compare';
import FoundProviderForm, {
  initialFoundProviderFormValue,
} from '../foundProviderForm/foundProviderForm';

const MESSAGE_TITLE = 'Fondeadores';

const initialState = {
  visible: false,
  loading: false,
  fondeadorSelect: {
    options: [],
    fetching: false,
  },
  rangoVolumen: {},
  rangoMetas: [],
  configModalVisible: false,
  tipoBaseDias: [],
  formtatoACHs: [],
  data: { ...initialFoundProviderFormValue, key: '0' },
};

class FoundProvidersNewModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnOpenModal = () => {
    const data = this.initialData();
    console.log('handleOnOpenModal data', data);
    this.setState({
      ...this.state,
      data,
      visible: true,
    });
  };

  handleOnClose = () => {
    this.setState({
      ...this.state,
      visible: false,
    });
  };

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnConfigVolumen = async (rangoVolumen) => {
    const { authToken, scConvenio } = this.props;

    if (this.validChangeRangoVolumen(rangoVolumen)) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await calcularRangos(
          authToken,
          scConvenio,
          rangoVolumen.cdTipoCalculoVolumen,
          rangoVolumen
        );

        this.setState({
          ...this.state,
          configModalVisible: true,
          loading: false,
          rangoMetas: response.data,
          rangoVolumen: rangoVolumen,
        });
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    } else {
      this.setState({
        ...this.state,
        configModalVisible: true,
      });
    }
  };

  validChangeRangoVolumen = (rangoVolumenPro) => {
    const { rangoVolumen, rangoMetas } = this.state;

    return (
      rangoMetas.length === 0 ||
      !isEqualsToObject(rangoVolumen, rangoVolumenPro)
    );
  };

  handleOnVolumenModalClose = () => {
    this.setState({
      ...this.state,
      configModalVisible: false,
    });
  };

  handleOnFinishVolumenModal = (rangoMetas) => {
    this.setState({
      ...this.state,
      configModalVisible: false,
      rangoMetas: rangoMetas,
    });
  };

  getData = async () => {
    const { authToken } = this.props;
    this.setState({ ...this.state, loading: true });

    try {
      const tipoBaseDiasRes = await listaTipoBaseDias(authToken);
      const formtatoACHsRes = await listaFormatoACH(authToken);
      this.setState({
        ...this.state,
        tipoBaseDias: tipoBaseDiasRes.data,
        formtatoACHs: formtatoACHsRes.data,
        loading: false,
      });
    } catch {
      this.setState({ ...this.state, loading: false, visible: false });
    }
  };

  handleOnFinish = async (formValue) => {
    const {
      cdTipoConvenio,
      authToken,
      scConvenio,
      onFinish,
      poDescuentoMinimoProveedor,
    } = this.props;
    const { rangoMetas } = this.state;
    this.setState({ ...this.state, loading: true });
    formValue.poProveedorDefecto =
      undefined || null
        ? poDescuentoMinimoProveedor
        : formValue.poProveedorDefecto;
    try {
      let response = null;
      if (cdTipoConvenio === 'confirming' || cdTipoConvenio === 'factoring') {
        response = await crearConvenioFondeadorConfirming(
          authToken,
          scConvenio,
          { ...formValue, rangoMetas }
        );
      } else {
        response = await crearConvenioFondeadorProntoPago(
          authToken,
          scConvenio,
          { ...formValue, rangoMetas }
        );
      }

      notification.success({
        message: MESSAGE_TITLE,
        description: response.message,
        duration: 6,
      });
      this.setState({ ...this.state, loading: false, visible: false });

      onFinish({ ...formValue, rangoMetas, scConvenio: response.location });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  };

  componentDidMount() {
    this.getData();
  }

  initialData = () => {
    const { scPagador, nombrePagador, cdTipoConvenio } = this.props;
    console.log('initdata props', this.props);
    let uuids = uuidv4();
    return cdTipoConvenio === 'confirming' || cdTipoConvenio === 'factoring'
      ? { ...initialFoundProviderFormValue, key: uuids }
      : {
          ...initialFoundProviderFormValue,
          scFondeador: scPagador,
          nombreFondeador: nombrePagador,
          key: uuids,
        };
  };

  render() {
    const {
      visible,
      loading,
      fondeadorSelect,
      configModalVisible,
      rangoMetas,
      tipoBaseDias,
      formtatoACHs,
    } = this.state;
    const { cdTipoConvenio, poDescuentoMinimoProveedor } = this.props;

    const { data } = this.state;
    return (
      <>
        <Button
          size='small'
          className='btn-agregar'
          onClick={this.handleOnOpenModal}
        >
          + Agregar un nuevo fondeador
        </Button>
        <Modal
          title='Nueva fondeador'
          visible={visible}
          className='modal-found-providers-style'
          width={800}
          footer={null}
          onCancel={this.handleOnClose}
        >
          <Spin tip='Cargando...' spinning={loading}>
            <FoundProviderForm
              cdTipoConvenio={cdTipoConvenio}
              data={data}
              onFinish={this.handleOnFinish}
              handleOnSearchFondeador={this.handleOnSearchFondeador}
              fondeadorSelect={fondeadorSelect}
              onConfigVolumen={this.handleOnConfigVolumen}
              tipoBaseDias={tipoBaseDias}
              formtatoACHs={formtatoACHs}
              poDescuentoMinimoProveedor={poDescuentoMinimoProveedor}
            />
          </Spin>
        </Modal>
        <CalculateVolumeModal
          visible={configModalVisible}
          data={rangoMetas}
          onFinish={this.handleOnFinishVolumenModal}
          onClose={this.handleOnVolumenModalClose}
        />
      </>
    );
  }
}

export default FoundProvidersNewModal;
