import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import TableReport from '../../../../../components/tableReport/tableReport';
import { conceptos } from '../../../../../services/fondeador/operationServices';
import { currencyFormat, sorterText } from '../../../../../utilities/textTools';

const AchTransactionDetail = ({
  companyId,
  scTransaccionACH,
  scLoteAch,
  authToken,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultado, setResultado] = useState({
    data: [],
    sort: '',
    currentPage: 1,
    offset: 0,
    limit: 10,
    total: 0,
  });

  const getData = async ({ sort, currentPage, offset, limit }) => {
    setLoading(true);

    try {
      const response = await conceptos(authToken, companyId, {
        scTransaccionACH: scTransaccionACH,
        scLoteAch: scLoteAch,
        sort: sort,
        offset: offset,
        limit: limit,
      });
      setResultado({
        ...resultado,
        sort: sort,
        currentPage: currentPage,
        offset: offset,
        limit: limit,
        data: response.data,
        total: response.metadata.count,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    getData({ ...e });
  };

  useEffect(() => {
    if (visible) {
      getData({
        sort: resultado.sort,
        currentPage: 1,
        offset: 0,
        limit: resultado.limit,
      });
    }
  }, [visible, scLoteAch, scTransaccionACH]);

  const columnas = [
    {
      title: 'TIPO',
      dataIndex: 'tipoConceptoACH',
      sorter: (a, b) => sorterText(a.tipoConceptoACH, b.tipoConceptoACH),
      showSorterTooltip: false,
    },
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'dsConcepto',
      sorter: (a, b) => sorterText(a.dsConcepto, b.dsConcepto),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR',
      dataIndex: 'nmValor',
      render: (value) => <span>{currencyFormat(value)}</span>,
      sorter: (a, b) => a.nmValor - b.nmValor,
      showSorterTooltip: false,
    },
  ];
  return (
    <>
      <FormOutlined
        title='Ver transacciones'
        onClick={() => setVisible(true)}
        className='info'
      />

      <Modal
        title={`Detalle transacción ACH ${scTransaccionACH} Lote No ${scLoteAch}`}
        className='modal-ach-style'
        width={750}
        visible={visible}
        closable={false}
        bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
        footer={[
          <Button
            key='back'
            type='primary'
            className='form-btn btn-radius ok-btn '
            onClick={() => setVisible(false)}
          >
            Cerrar
          </Button>,
        ]}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <TableReport
            size='small'
            currentPage={resultado.currentPage}
            limit={resultado.limit}
            total={resultado.total}
            columns={columnas}
            data={resultado.data}
            handleChange={handleChange}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default AchTransactionDetail;
