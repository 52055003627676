import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarLotesFondeador = (authtoken, scFondeador, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/fondeadores/${scFondeador}/ach/lista-lotes-ach?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFondeadores = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-fondeadores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTransaccionesLoteFondeador = (
  authtoken,
  scFondeador,
  scLoteAch,
  filters
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fondeadores/${scFondeador}/ach/lotes-ach/${scLoteAch}/lista-transacciones-ach?
      ${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarConceptosTransaccionesLotes = (
  authtoken,
  scFondeador,
  filters
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fondeadores/${scFondeador}/ach/lista-conceptos-ach?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else if (
        filtros[nombre] &&
        nombre != 'currentPage' &&
        nombre != 'total'
      ) {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
