import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import './dashboard.scss';
import avatar from '../../../assets/img/assisted-man.png';

class Dashboard extends Component {
  componentDidMount() {
    this.props.menuHandler('1');
  }
  render() {
    return (
      <div className='dashboard-style'>
        <Card className='card-shadow card-radius default-border welcome-message '>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <p className='dash-welcome'>
                <span>¡Hola {this.props.name}! </span>Bienvenido a Affirmatum
              </p>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify='center' align='middle'>
            <Col span={9}>
              <img className='dash-avatar' src={avatar} alt='' />
            </Col>
            <Col span={15}>
              <p>
                Te presentamos la nueva versión de Affirmatum ¿Quieres usarlo?
                Todas tus opciones de siempre en un nuevo menú:
                <br />
                <br />
                <ul>
                  <li>
                    <b>Generar Facturacion:</b> Podrás buscar y generar la facturación.
                  </li>
                  <li>
                    <b>Consultar Facturas:</b> Encuentra la información de las facturas. 
                  </li>
                  <li>
                    <b>Factura Manual:</b> Podrás crear una factura manual. 
                  </li>
                  </ul>
                  <br />
                ¡Esperamos que disfrutes esta nueva experiencia!
                </p>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default Dashboard;
