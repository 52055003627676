import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import store from '../store/index'

const LoginRoute = ({ component: Component, ...rest }) => {
  let isAuthenticated = store.getState().auth.isAuthenticated
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated) {
          return <Component {...props} />
        }
        else {
          return <Redirect to={
            {
              pathname: `${process.env.PUBLIC_URL}/`,
              state: {
                from: props.location
              }
            }
          }
          />
        }

      }}
    />
  )
}

export default LoginRoute