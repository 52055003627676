import React, { Component } from 'react';
import { Row, Col, Card, Button, Select, Divider, Spin, Space } from 'antd';
import { listaGruposEmpresariales } from '../../../../services/admin/businessGroupsService';
import './businessGroups.scss';
import { connect } from 'react-redux';
import history from '../../../../services/history';
import TableReport from '../../../../components/tableReport/tableReport';
import { sorterDate, sorterText } from '../../../../utilities/textTools';

const initialState = {
  loading: false,
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  sort: '-dsNombres',
};

class BusinessGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'CÓDIGO',
        dataIndex: 'scGrupo',
        sorter: (a, b) => a.scGrupo - b.scGrupo,
        showSorterTooltip: false,
      },
      {
        title: 'GRUPO',
        dataIndex: 'dsNombres',
        sorter: (a, b) => sorterText(a.dsNombres, b.dsNombres),
        showSorterTooltip: false,
      },
      // {
      //   title: 'CÓDIGO ZOHOO',
      //   dataIndex: 'scGrupoZoho',
      //   sorter: (a, b) => sorterText(a.scGrupoZoho, b.scGrupoZoho),
      //   showSorterTooltip: false,
      // },
      {
        title: 'HORA DE CARGA',
        dataIndex: 'dsHoraCarga',
        sorter: (a, b) => sorterDate(a.dsHoraCarga, b.dsHoraCarga),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO',
        dataIndex: 'dsTipoCliente',
        sorter: (a, b) => sorterText(a.dsTipoCliente, b.dsTipoCliente),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoGrupo',
        sorter: (a, b) => sorterText(a.dsEstadoGrupo, b.dsEstadoGrupo),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showEditPage(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  showEditPage = (record) => {
    history.push(
      `${process.env.PUBLIC_URL}/businessGroups/edit/${btoa(record.scGrupo)}`
    );
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaGruposEmpresariales(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
      console.log(response);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { limit, offset, sort } = this.state;

    return {
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  componentDidMount() {
    this.props.menuHandler('300');
    this.getData();
  }

  render() {
    const { loading, currentPage, limit, total, resultado } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='businessGroups-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='table-container'>
              <Row
                gutter={[16, 32]}
                align='middle'
                style={{ marginTop: '1.2rem' }}
              >
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de grupos empresariales
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                    <Button
                      size='small'
                      onClick={() =>
                        history.push(
                          `${process.env.PUBLIC_URL}/businessGroups/new`
                        )
                      }
                      className='btn-agregar'
                    >
                      + Agregar nuevo grupo empresarial
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(BusinessGroups);
