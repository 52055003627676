import { FileExcelOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import equals from 'fast-deep-equal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  exportarExcelFacturasPagador,
  listaFacturasPagador,
  listarFondeadores,
  listarPagadores,
  listarProveedores,
} from '../../../../services/admin/historics/billsService';
import { sorterText } from '../../../../utilities/textTools';
import Total from '../../../proveedor/reports/total';
import './bills.scss';

let intColumnas = 1;

const { Option } = Select;
const initialState = {
  loading: false,
  currentPage: 1,
  resultado: [],
  total: 0,
  sumatorias: {},
  filtros: {
    fechaDesde: '',
    fechaHasta: '',
    nombrePersonaModifica: '',
    cdFactura: '',
    scFactura: '',
    scFondeador: '',
    scProveedor: '',
    scPagador: '',
    offset: 0,
  },
  proveedorOptions: [],
  pagadorOptions: [],
  fondeadorOptions: [],
};

class Bills extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.formRef = React.createRef();
  }

  columns = [
    {
      title: 'FECHA HISTORICO',
      dataIndex: 'feHistorico',
      sorter: (a, b) => sorterText(a.feHistorico, b.feHistorico),
      showSorterTooltip: false,
    },
    {
      title: 'PERSONA QUE MODIFICA',
      dataIndex: 'nombrePersonaModifica',
      sorter: (a, b) =>
        sorterText(a.nombrePersonaModifica, b.nombrePersonaModifica),
      showSorterTooltip: false,
    },
    {
      title: 'PAGADOR',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
      showSorterTooltip: false,
    },
    {
      title: 'PROVEEDOR',
      dataIndex: 'nombreProveedor',
      sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
      showSorterTooltip: false,
    },
    {
      title: 'FONDEADOR',
      dataIndex: 'nombreFondeador',
      sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
      showSorterTooltip: false,
    },
    {
      title: 'CÓDIGO INTERNO',
      dataIndex: 'scFactura',
      sorter: (a, b) => sorterText(a.scFactura, b.scFactura),
      showSorterTooltip: false,
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
      showSorterTooltip: false,
    },
    {
      title: 'FECHA DE VENCIMIENTO',
      dataIndex: 'feVencimiento',
      sorter: (a, b) => sorterText(a.feVencimiento, b.feVencimiento),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR BASE FACTURA',
      dataIndex: 'vrFacturaBase',
      sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
      showSorterTooltip: false,
    },
  ];

  formulario = [
    [
      {
        nombre: 'fechaDesde',
        label: '* DESDE',
        tipo: 'date',
      },
      {
        nombre: 'fechaHasta',
        label: '* HASTA',
        tipo: 'date',
      },
    ],
    [
      {
        nombre: 'scFondeador',
        label: 'FONDEADOR',
        tipo: 'select',
        selectType: 'fondeadorOptions',
      },
      {
        nombre: 'scPagador',
        label: 'PAGADOR',
        tipo: 'select',
        selectType: 'pagadorOptions',
      },
    ],
    [
      {
        nombre: 'scProveedor',
        label: '* PROVEEDOR',
        tipo: 'select',
        selectType: 'proveedorOptions',
      },
      {
        nombre: 'scFactura',
        label: 'CÓDIGO INTERNO',
        tipo: 'string',
      },
    ],
    [
      {
        nombre: 'cdFactura',
        label: 'NÚMERO DE FACTURA',
        tipo: 'string',
      },
      {
        nombre: 'nombrePersonaModifica',
        label: 'NOMBRE DE PERSONA QUE MODIFICA',
        tipo: 'string',
      },
    ],
  ];

  componentDidMount() {
    this.props.menuHandler('74');
    this.setState({
      ...this.state,
      filtros: {
        fechaDesde: '',
        fechaHasta: '',
        nombrePersonaModifica: '',
        cdFactura: '',
        scFactura: '',
        scFondeador: '',
        scProveedor: '',
        scPagador: '',
        offset: 0,
      },
    });
  }

  componentDidUpdate(props, state) {
    if (!equals(this.state.filtros, state.filtros)) {
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue(this.state.filtros);
      }
    }
  }

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  getData = () => {
    this.state.resultado = [];
    if (this.validateForm()) {
      if (!this.state.filtros.scProveedor) {
        return notification.error({
          message: 'Campo obligatorio',
          description:
            'El Proveedor es obligatorio para el uso de este reporte',
          duration: 4,
        });
      }
      this.setState({
        ...this.state,
        loading: true,
      });
      listaFacturasPagador(this.props.authToken, this.state.filtros).then(
        (response) => {
          this.setResponse(response);
        }
      );
    }
  };

  setResponse = (response) => {
    console.log(response);
    this.setState({
      ...this.state,
      resultado: response.data,
      total: response.metadata.count,
      loading: false,
      sumatorias: response.metadata.sumatorias,
    });
  };

  validateForm = () => {
    let filtros = this.state.filtros;
    let fechas = true;
    Object.keys(filtros).forEach((nombre) => {
      if (nombre !== 'resultado') {
        if (
          nombre.includes('fecha') &&
          (filtros[nombre] === '' || filtros[nombre] === null)
        ) {
          fechas = false;
        }
      }
    });
    if (!fechas) {
      notification.error({
        message: 'Las fechas son obligatorias',
        duration: 4,
      });
    }
    return fechas;
  };

  formRender = () => {
    let formulario = '';
    return (formulario = (
      <Form
        name='content_form'
        layout='vertical'
        ref={this.formRef}
        className='filters-container'
      >
        {this.formulario.map((fila, key) => {
          if (fila.length === 1) {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={fila[1].nombre} label={fila[1].label}>
                    {this.inputType(fila[1])}
                  </Form.Item>
                </Col>
              </Row>
            );
          }
        })}
        <Row justify='left'>
          <Col>
            <Form.Item name='submit'>
              <Button
                className='btn btn-green'
                type='primary'
                onClick={() => this.getData()}
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='submit'>
              <Button
                className='btn btn-blue'
                type='primary'
                onClick={() => {
                  this.limpiarCampos();
                }}
              >
                Limpiar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ));
  };

  limpiarCampos() {
    this.setState({
      ...initialState,
      filtros: {
        fechaDesde: '',
        fechaHasta: '',
        nombrePersonaModifica: '',
        cdFactura: '',
        scFactura: '',
        scFondeador: '',
        scProveedor: '',
        scPagador: '',
        offset: 0,
      },
    });
  }

  inputType = (campo) => {
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state.filtros[stateName]}
            onChange={(e) => this.handleTextChange(e, stateName)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state.filtros[stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state.filtros[stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  };

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      switch (nombre) {
        case 'scProveedor':
          listarProveedores(this.props.authToken, { nombre: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  proveedorOptions: response.data.map((item) => {
                    return {
                      key: item.codigo,
                      nombre: item.descripcion,
                    };
                  }),
                  fetching: false,
                });
              }
            }
          );
        case 'scPagador':
          listarPagadores(this.props.authToken, { nombre: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  pagadorOptions: response.data.map((item) => {
                    return {
                      key: item.codigo,
                      nombre: item.descripcion,
                    };
                  }),
                  fetching: false,
                });
              }
            }
          );
          break;
        case 'scFondeador':
          listarFondeadores(this.props.authToken, { nombre: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  fondeadorOptions: response.data.map((item) => {
                    return {
                      key: item.codigo,
                      nombre: item.descripcion,
                    };
                  }),
                  fetching: false,
                });
              }
            }
          );
          break;
      }
    } else {
      switch (nombre) {
        case 'scProveedor':
          this.setState({
            ...this.state,
            proveedorOptions: [],
          });
          break;
        case 'scPagador':
          this.setState({
            ...this.state,
            pagadorOptions: [],
          });
          break;
        case 'scFondeador':
          this.setState({
            ...this.state,
            fondeadorOptions: [],
          });
          break;
      }
    }
  };

  handleOnChange = (pagination, sorter) => {
    if (this.state.currentPage != pagination.current) {
      this.state.filtros.offset =
        pagination.pageSize * (pagination.current - 1);
      this.state.currentPage = pagination.current;
    }

    if ((this.state.filtros.limit || 10) != pagination.pageSize) {
      this.state.filtros.limit = pagination.pageSize;
      this.state.filtros.offset = 0;
      this.state.currentPage = 1;
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.state.filtros.sort = order == 'descend' ? `-${field}` : field;
    }
    this.getData();
  };

  handleTextChange = (e, campo) => {
    this.state.filtros[campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo) => {
    this.state.filtros[campo] = moment;
  };

  handleSelectChange = (value, stateName) => {
    this.state.filtros[stateName] = value;
    this.setState({ ...this.state });
  };

  exportExcel = () => {
    if (this.validateForm()) {
      if (!this.state.filtros.scProveedor) {
        return notification.error({
          message: 'Campo obligatorio',
          description:
            'El Proveedor es obligatorio para el uso de este reporte',
          duration: 4,
        });
      }
      this.setState({
        ...this.state,
        loading: true,
      });
      exportarExcelFacturasPagador(
        this.props.authToken,
        this.state.filtros
      ).then((response) => {
        this.setExcel(response);
      });
    }
  };

  setExcel = (response) => {
    const type = this.props.type;
    const url = window.URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Histórico facturas.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.setState({
      ...this.state,
      loading: false,
    });
  };

  render() {
    return (
      <div className='reports'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={this.state.loading}>
                <div className='header-container'>
                  <p className='title'>Búsqueda</p>
                  {this.formRender()}
                </div>
                <div className='table-container'>
                  <Row gutter={30} className='bill-selection' justify='end'>
                    <Col xl={{ span: 24 / intColumnas }} className='export'>
                      <span className='button'>
                        <Button
                          size='medium'
                          className='btn-excel'
                          title='Exportar a excel'
                          onClick={this.exportExcel}
                          icon={<FileExcelOutlined />}
                        ></Button>
                      </span>
                    </Col>
                  </Row>
                  <Divider />
                  <Table
                    pagination={{
                      defaultPageSize: 10,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} de ${total} registros`,
                      current: this.state.filtros.currentPage,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '30'],
                      total: this.state.total,
                    }}
                    onChange={this.handleOnChange}
                    columns={this.columns}
                    footer={() => <Total sumatorias={this.state.sumatorias} />}
                    dataSource={this.state.resultado}
                  ></Table>
                </div>
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Bills);
