import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Card,
  Checkbox,
} from 'antd';
import {
  FileExcelOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { initialState } from './initialState';
import { columns } from './columns';
import { form } from './form';
import {
  listarCompañias,
  listarClusteres,
  listarCalificaciones,
} from '../../../../../services/admin/reportsNeuroScoreService';
import '../reports.scss';
import { connect } from 'react-redux';
import { REPORTS_DATES_REQUIRED_ERROR } from '../../../../../utilities/messages';
import Total from '../../../../proveedor/reports/total';

const { Option } = Select;

let intColumnas = 1;

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  columnas = columns;

  formulario = form;
  isBackButtonClicked = false;

  formRender = () => {
    const { type } = this.props;
    let formulario = '';
    return (formulario = (
      <Form name='content_form' layout='vertical' className='filters-container'>
        {this.formulario[type].map((fila, key) => {
          if (fila.length === 1) {
            return (
              <Row key={key} gutter={16}>
                <Col span={24}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={fila[1].nombre} label={fila[1].label}>
                    {this.inputType(fila[1])}
                  </Form.Item>
                </Col>
              </Row>
            );
          }
        })}
        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                className='form-btn btn-radius'
                type='primary'
                onClick={() => this.getData()}
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ));
  };

  inputType = (campo) => {
    const { type } = this.props;
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(e) => this.handleTextChange(e, stateName, type)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName, type)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
      case 'select_nosearch':
        input = (
          <Select
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onClick={() => {
              if (this.state[campo.selectType].length == 0)
                this[campo.loadFunction]();
            }}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  };

  listarClusteres = () => {
    listarClusteres(this.props.authToken).then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          tipoClusterOptions: response.data.map((item) => {
            return {
              key: item.clusNeuroRiesgo,
              nombre: item.clusNeuroRiesgo,
            };
          }),
          fetching: false,
        });
      }
    });
  };

  handleTextChange = (e, campo, type) => {
    this.state[type][campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo, type) => {
    this.state[type][campo] = moment;
  };

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      switch (nombre) {
        case 'scCompania':
          listarCompañias(this.props.authToken, { name: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  grupoEmpresarialOptions: response.data
                    .filter((x) =>
                      x.descripcion.toLowerCase().includes(value.toLowerCase())
                    )
                    .map((item) => {
                      return {
                        key: item.codigo,
                        nombre: item.descripcion,
                      };
                    }),
                  fetching: false,
                });
              }
            }
          );
      }
    } else {
      switch (nombre) {
        case 'scPagador':
          this.setState({
            ...this.state,
            pagadorOptions: [],
          });
          break;
        case 'scConvenio':
          this.setState({
            ...this.state,
            convenioOptions: [],
          });
          break;
        case 'scGrupoEmpresarial':
          this.setState({
            ...this.state,
            grupoEmpresarialOptions: [],
          });
          break;
      }
    }
  };

  handleSelectChange = (value, stateName) => {
    this.state[this.props.type][stateName] = value;
    this.setState({ ...this.state });
  };

  getData = () => {
    this.state.resultado = [];
    switch (this.props.type) {
      case 'reports':
        // if (this.validateForm()) {
        //   this.setState({
        //     ...this.state,
        //     loading: true,
        //   });
        listarCalificaciones(this.props.authToken, this.state[this.props.type])
          .then((response) => {
            this.setResponse(response);
          })
          .catch(() =>
            this.setState({
              ...this.state,
              loading: false,
            })
          );
        // }
        break;
    }
  };

  componentDidMount() {}

  componentDidUpdate(props, state) {}

  setResponse = (response) => {
    if (response && response.status !== false) {
      this.setState({
        ...this.state,
        [this.props.type]: {
          ...this.state[this.props.type],
          resultado: response.data,
          total: response.metadata.count,
        },
        loading: false,
        sumatorias: response.metadata.sumatorias,
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  // validateForm = () => {
  //   let filtros = this.state[this.props.type];
  //   let fechas = true;
  //   Object.keys(filtros).forEach((nombre) => {
  //     if (nombre !== 'resultado') {
  //       if (
  //         nombre.includes('fecha') &&
  //         (filtros[nombre] === '' || filtros[nombre] === null)
  //       ) {
  //         fechas = false;
  //       }
  //     }
  //   });
  //   if (!fechas) {
  //     notification.error({
  //       message: 'Las fechas son obligatorias',
  //       description: REPORTS_DATES_REQUIRED_ERROR,
  //       duration: 4,
  //     });
  //   }
  //   return fechas;
  // };

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  handleOnChange = (pagination, filters, sorter, extra) => {
    if (this.state.currentPage != pagination.current) {
      this.state[this.props.type].offset =
        pagination.pageSize * (pagination.current - 1);
      this.state[this.props.type].currentPage = pagination.current;
    }

    if ((this.state[this.props.type].limit || 10) != pagination.pageSize) {
      this.state[this.props.type].limit = pagination.pageSize;
      this.state[this.props.type].offset = 0;
      this.state[this.props.type].currentPage = 1;
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.state[this.props.type].sort =
        order == 'descend' ? `-${field}` : field;
    }
    this.getData();
  };

  render() {
    const { type } = this.props;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <div className='header-container'>
            <p className='title'>Búsqueda</p>
            {this.formRender()}
          </div>
          <div className='table-container'>
            <Divider />
            <Table
              pagination={{
                defaultPageSize: 10,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} registros`,
                current: this.state[this.props.type].currentPage,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30'],
                total: this.state[type].total,
              }}
              onChange={this.handleOnChange}
              columns={this.columnas[type]}
              footer={() => <Total sumatorias={this.state.sumatorias} />}
              dataSource={this.state[type].resultado}
            ></Table>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Content);
