import React, { Component } from 'react';
import { Tabs } from 'antd';
import './bulkload.scss';
import Bills from './bills/billspayments';

class BulkLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '0',
    };
  }

  pagesToMap = [
    {
      title: 'Factura masiva',
      key: <Bills type='facturas' />,
      type: '',
    },
    {
      title: 'Pagos masivos',
      key: <Bills type='pagos' />,
      type: '',
    },
  ];

  componentDidMount() {
    this.props.menuHandler('2');
  }

  componentDidUpdate(props, state) {
    if (props.company !== this.props.company) {
      this.setState({
        ...this.state,
        activeTab: '0',
      });
    }
  }

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  render() {
    return (
      <div className='content-margin bulkload-style'>
        <div className='card-background-light card-shadow radius'>
          <div className='align-center'>
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>
              {this.pagesToMap.map((page, index) => (
                <Tabs.TabPane tab={page.title} key={`${index}`}>
                  {page.key}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default BulkLoad;
