import { Descriptions, Divider, Modal, Spin } from 'antd';
import React, { Component } from 'react';
import TableReport from '../../../../components/tableReport/tableReport';
import { currencyFormat, sorterDate } from '../../../../utilities/textTools';

const initialState = {
  loading: false,
};

class AccountStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  columns = [
    {
      title: 'FECHA ABONO',
      dataIndex: 'feAbono',
      sorter: (a, b) => sorterDate(a.feAbono, b.feAbono),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR ABONO',
      dataIndex: 'vrAbono',
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'DÍAS DE MORA',
      dataIndex: 'diasMora',
      showSorterTooltip: false,
    },
    {
      title: 'TIPO ABONO',
      dataIndex: 'tipoAbono',
      showSorterTooltip: false,
    },
    {
      title: 'SALDO ADEUDADO',
      dataIndex: 'vrSaldoAdeudado',
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
  ];

  render() {
    const { loading } = this.state;
    const { visible, onClose, abonos } = this.props;
    return (
      <Modal
        title={'Detalle de la Factura #: ' + this.props.scFactura.cdFactura}
        visible={visible}
        className='modal-style'
        footer={null}
        onCancel={onClose}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <Descriptions layout='vertical'>
            <Descriptions.Item
              label='Pagador'
              labelStyle={{ fontWeight: 'bold' }}
            >
              {this.props.scFactura.pagadorNombre}
            </Descriptions.Item>
            <Descriptions.Item
              label='Proveedor'
              labelStyle={{ fontWeight: 'bold' }}
            >
              {this.props.scFactura.proveedorNombre}
            </Descriptions.Item>
            <Descriptions.Item
              label='Valor Factura'
              labelStyle={{ fontWeight: 'bold' }}
            >
              {currencyFormat(this.props.scFactura.vrFactura)}
            </Descriptions.Item>
            <Descriptions.Item
              label='Días Mora'
              labelStyle={{ fontWeight: 'bold' }}
            >
              {this.props.scFactura.diasMora}
            </Descriptions.Item>
            <Descriptions.Item
              label='Fecha esperada de pago'
              labelStyle={{ fontWeight: 'bold' }}
            >
              {this.props.scFactura.feEstimadaPago}
            </Descriptions.Item>
            <Descriptions.Item
              label='Etapa de cobro'
              labelStyle={{ fontWeight: 'bold' }}
            >
              {this.props.scFactura.dsEtapaCobro}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Historial de Abonos
          </div>
          <br></br>
          <TableReport columns={this.columns} data={abonos} />
        </Spin>
      </Modal>
    );
  }
}

export default AccountStatusModal;
