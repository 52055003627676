import axios from 'axios'
import { middleWare } from '../middleware'
import queryString from 'query-string'

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN

export const listarDocumentos = (authtoken, filtros) => {
  let data = formatoFechas(filtros)
  return middleWare(axios({
    url: `${baseUrl}/api/documentos/listar-documentos?${queryString.stringify(data)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const editarDocumento = (authtoken, data) => {
  return middleWare(axios({
    url: `${baseUrl}/api/documentos/grabar`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data
  }))
}

export const crearDocumento = (authtoken, data) => {
  return middleWare(axios({
    url: `${baseUrl}/api/documentos/grabar`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data
  }))
}

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach(nombre => {
    if (nombre !== 'resultado') {
      if ((nombre.includes("Desde") || nombre.includes("Hasta")) && (typeof filtros[nombre] === 'object')) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD')
      }
    }
  });
  return filtros
}