export const form = {
  reports: [
    [
      {
        nombre: 'fechaDesde',
        label: 'Desde',
        tipo: 'date',
      },
      {
        nombre: 'fechaHasta',
        label: 'Hasta',
        tipo: 'date',
      },
    ],
    [
      {
        nombre: 'scCompania',
        label: 'COMPAÑÍA',
        tipo: 'select',
        selectType: 'grupoEmpresarialOptions',
      },
      {
        nombre: 'clusNeuroRiesgo',
        label: 'CLUSTER',
        tipo: 'select_nosearch',
        selectType: 'tipoClusterOptions',
        loadFunction: 'listarClusteres',
      },
    ],
  ],
};
