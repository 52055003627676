import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_LOGIN;

export const listaPerfilesAdmin = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/perfil/administrativa/listar`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const getPerfil = (authtoken, cdUsuario) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/perfil/administrativa/${cdUsuario}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const updateUserProfile = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/perfil/administrativa/${data.cdPerfil}/usuario/${data.userSeguridad}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
