import { Button, Checkbox, Col, Form, Input, Row, Select, Spin } from 'antd';
import React, { Component } from 'react';
import {
  emailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../../utilities/admin_validation_messages';
import { isEqualsToObject } from '../../../../../../utilities/compare';
import { tipoIds } from '../../../../../../utilities/constants';

export const initialFormValue = {
  cdTipoId: '',
  id: '',
  prNombre: '',
  segNombre: '',
  prApellido: '',
  segApellido: '',
  email: '',
  telefono1: '',
  cdTipoContacto: '',
  cdSnRepresentanteLegal: false,
  cdSnFacturacion: false,
  cdMunicipio: '',
  direccion: '',
};

const initialState = {
  cdSnFacturacion: false,
};

const rules = {
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  id: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: /^[0-9a-zA-Z-]*$/,
      message: formatValueMessage(),
    },
  ],
  prNombre: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segNombre: [{ max: 20, message: maxValueMessage(20) }],
  prApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  segApellido: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  email: [
    { required: true, message: requiredValueMessage() },
    { max: 100, message: maxValueMessage(100) },
    { pattern: emailPattern, message: formatValueMessage() },
  ],
  telefono1: [
    { required: true, message: requiredValueMessage() },
    { max: 50, message: maxValueMessage(50) },
  ],
  cdTipoContacto: [{ required: true, message: requiredValueMessage() }],
  cdMunicipio: [{ required: true, message: requiredValueMessage() }],
  direccion: [
    { required: true, message: requiredValueMessage() },
    { max: 100, message: maxValueMessage(100) },
  ],
};

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.form = React.createRef();
  }

  handleOnChange = (checked, name) => {
    this.setState({ ...this.state, [name]: checked });
  };

  onFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish({
      ...formValue,
      cdSnRepresentanteLegal: formValue.cdSnRepresentanteLegal ? 'S' : 'N',
      cdSnFacturacion: formValue.cdSnFacturacion ? 'S' : 'N',
    });
  };

  componentDidMount() {
    if (this.props.data) {
      const user = this.props.data;
      this.form.current.setFieldsValue({
        ...user,
        cdSnFacturacion: user.cdSnFacturacion === 'S',
        cdSnRepresentanteLegal: user.cdSnRepresentanteLegal === 'S',
      });
      this.setState({
        ...this.state,
        cdSnFacturacion: user.cdSnFacturacion === 'S',
      });
    }
  }

  componentDidUpdate(props, state) {
    if (props.reset !== this.props.reset) {
      this.setState({
        ...this.state,
        cdSnFacturacion: initialState.cdSnFacturacion,
      });

      this.form.current.resetFields();
    }

    if (!isEqualsToObject(props.data, this.props.data)) {
      this.setState({
        ...this.state,
        cdSnFacturacion: this.props.data.cdSnFacturacion === 'S',
      });
      this.form.current.setFieldsValue({
        ...this.props.data,
        cdSnFacturacion: this.props.data.cdSnFacturacion === 'S',
        cdSnRepresentanteLegal: this.props.data.cdSnRepresentanteLegal === 'S',
      });
    }
  }

  render() {
    const { ciudadesSelect, onCiudadesSearch, tipoContactos = [] } = this.props;
    const { cdSnFacturacion } = this.state;

    const { buscarContacto } = this.props;
    return (
      <div className='header-container'>
        <Form layout='vertical' ref={this.form} onFinish={this.onFinish}>
          <Form.Item name='scPersona' hidden='true'>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoId'
                label='TIPO DE IDENTIFICACIÓN'
                rules={rules['cdTipoId'] || ''}
              >
                <Select>
                  {tipoIds.map(({ key, description }) => (
                    <Select.Option key={key} value={key}>
                      {description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='id'
                label='IDENTIFICACIÓN'
                rules={rules['id'] || ''}
              >
                <Input onBlur={(e) => buscarContacto(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prNombre'
                label='PRIMER NOMBRE'
                rules={rules['prNombre'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segNombre'
                label='SEGUNDO NOMBRE'
                rules={rules['segNombre'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='prApellido'
                label='PRIMER APELLIDO'
                rules={rules['prApellido'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='segApellido'
                label='SEGUNDO APELLIDO'
                rules={rules['segApellido'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col flex='auto'>
              <Form.Item
                name='email'
                label='CORREO ELECTRÓNICO'
                rules={rules['email'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='telefono1'
                label='TELÉFONO'
                rules={rules['telefono1'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdTipoContacto'
                label='TIPO CONTACTO'
                rules={rules['cdTipoContacto'] || ''}
              >
                <Select>
                  {tipoContactos.map(({ codigo, descripcion }) => (
                    <Select.Option key={codigo} value={codigo}>
                      {descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <div className='check' style={{ paddingTop: '2rem' }}>
                <Form.Item
                  name='cdSnRepresentanteLegal'
                  valuePropName='checked'
                >
                  <Checkbox className='tax-info-check'>
                    Representante legal
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className='check' style={{ paddingTop: '2rem' }}>
                <Form.Item name='cdSnFacturacion' valuePropName='checked'>
                  <Checkbox
                    className='tax-info-check'
                    onChange={(e) =>
                      this.handleOnChange(e.target.checked, 'cdSnFacturacion')
                    }
                  >
                    Contacto facturación
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
          </Row>

          {cdSnFacturacion && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='direccion'
                  label='DIRECCIÓN'
                  rules={rules['direccion'] || ''}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='cdMunicipio'
                  label='CIUDAD'
                  rules={rules['cdMunicipio'] || ''}
                >
                  <Select
                    className='module-selector-select'
                    placeholder='Seleccione'
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => onCiudadesSearch(value)}
                    notFoundContent={
                      ciudadesSelect.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {ciudadesSelect.options.map((ciudad, i) => (
                      <Select.Option key={i} value={ciudad.codigo}>
                        {ciudad.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default UserForm;
