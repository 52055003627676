import axios from 'axios'
import { notification } from 'antd';
import { middleWare } from '../middleware'
import history from '../history'
import store from '../../store'
import { LOGIN_CLOSE } from '../../store/actions/types'
var baseUrl = process.env.REACT_APP_SERVICES_URL


/**
 * 
 * @param {*} authToken 
 * @param {*} scProveedor 
 */
export const getUser = (authToken, scProveedor) => {
  return middleWare(axios({
    url: `${baseUrl}/api/affitoken/${scProveedor}/usuario`,
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: null,
  }))
}

/**
 * 
 * @param {*} authToken 
 * @param {*} scProveedor 
 */
export const requestTempToken = (authToken, scProveedor) => {
  return middleWare(axios({
    url: `${baseUrl}/api/affitoken/solicitarAffitokenTemp/${scProveedor}`,
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: null,
  }))
}

/**
 * 
 * @param {*} authToken 
 * @param {*} scProveedor 
 */
export const requestToken = (authToken, scProveedor) => {
  return axios({
    url: `${baseUrl}/api/affitoken/solicitarAffitoken/${scProveedor}`,
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: null,
  }).then(response => {
    if (response.error && response.status !== 200) {
      return {
        status: false,
        message: 'Se presentó un error, por favor intentalo nuevamente'
      }
    } else {
      if (response.status === 200) {
        return {
          status: true,
          message: 'Se ha generado el token correctamente, por favor verifique el medio configurado para su recepción'
        }
      } else {
        return {
          status: false,
          message: 'Clave incorrecta, intenta nuevamente'
        }
      }
    }
  })
    .catch(error => {
      if (error.response.status === 401) {
        localStorage.removeItem('auth-token');
        store.dispatch({ type: LOGIN_CLOSE })
        notification.error({
          message: 'Sesión Finalizada',
          description: 'La sesión ha finalizado, por favor ingrese nuevamente',
          duration: 6
        });

        history.push(`${process.env.PUBLIC_URL}/login`)
      }
    })
}
/**
 * 
 * @param {*} authToken 
 * @param {*} scProveedor 
 * @param {*} token 
 */
export const validateToken = (authToken, scProveedor, token) => {
  return axios({
    url: `${baseUrl}/api/affitoken/validarAffitoken`,
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: {
      affitoken: token,
      scCompania: scProveedor
    },
  }).then(response => {
    if (response.error && response.status !== 200) {
      return {
        status: false,
        message: 'Se presentó un error, por favor intentalo nuevamente'
      }
    } else {
      if (response.data.message === "VALID") {
        return {
          status: true,
          message: 'Validacion completa'
        }
      } else if (response.data.message === "INVALID") {
        return {
          status: false,
          message: 'El token ingresado es incorrecto, por favor verifique los datos ingresados o solicite uno nuevo'
        }
      } else {
        return {
          status: false,
          message: 'Clave incorrecta, intenta nuevamente'
        }
      }
    }
  }).catch(error => {
    if (Array.isArray(error.response.data.errors)) {
      error.response.data.errors.forEach(element => {
        notification.error({
          message: 'Error',
          description: `${element.message}`,
          duration: 5
        });
      });
    }
    else {
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        duration: 5
      });
    }
    return {
      status: 500,
      message: error.response.data.message
    }
  })
}
/**
 * 
 * @param {*} authToken 
 * @param {*} scProveedor 
 * @param {*} token 
 */
export const validateTempToken = (authToken, scProveedor, token) => {
  return axios({
    url: `${baseUrl}/api/affitoken/validarAffitokenTemp`,
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: {
      affitoken: token,
      scCompania: scProveedor
    },
  }).then(response => {
    if (response.error && response.status !== 200) {
      return {
        status: false,
        message: 'Se presentó un error, por favor intentalo nuevamente'
      }
    } else {
      if (response.data.message === "VALID") {
        return {
          status: true,
          message: 'Validacion completa'
        }
      }
      else {
        return {
          status: false,
          message: 'Clave incorrecta, intenta nuevamente'
        }
      }
    }
  }).catch(error => {
    if (error.response.status === 401) {
      localStorage.removeItem('auth-token');
      store.dispatch({ type: LOGIN_CLOSE })
      notification.error({
        message: 'Sesión Finalizada',
        description: 'La sesión ha finalizado, por favor ingrese nuevamente',
        duration: 6
      });

      history.push(`${process.env.PUBLIC_URL}/login`)
    }
  })
}

/**
 * 
 * @param {*} authToken 
 * @param {*} userConfig 
 * @param {*} userConfig.affitoken
 * @param {*} userConfig.deliveryTypeOTP
 * @param {*} userConfig.scCompania
 * @param {*} userConfig.telefono
 */
export const registerUser = (authToken, company, { tipoEnvioOtp, telefonoEnvioOtp }, token) => {

  return axios({
    url: `${baseUrl}/api/affitoken/registrarUsuario`,
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: {
      affitoken: token,
      deliveryTypeOTP: tipoEnvioOtp,
      scCompania: company,
      telefonoEnvioOtp
    },
  }).then(response => {
    if (response.error && response.status < 200 && response.status > 399) {
      return {
        status: false,
        message: 'Se presentó un error, por favor intentalo nuevamente'
      }
    } else {
      return {
        status: true,
        message: 'Medio de envio registrado correctamente'
      }
    }
  }).catch(error => {
    if (error.response.status === 401) {
      localStorage.removeItem('auth-token');
      store.dispatch({ type: LOGIN_CLOSE })
      notification.error({
        message: 'Sesión Finalizada',
        description: 'La sesión ha finalizado, por favor ingrese nuevamente',
        duration: 6
      });

      history.push(`${process.env.PUBLIC_URL}/login`)
    }

    if (error.response.status === 500 || error.response.status === 400) {
      return {
        status: false,
        message: error.response.data.message
      }
    }
  })
}

/**
 * 
 * @param {*} authToken 
 * @param {*} userConfig 
 * @param {*} userConfig.affitoken
 * @param {*} userConfig.deliveryTypeOTP
 * @param {*} userConfig.scCompania
 * @param {*} userConfig.telefono
 */
export const updateUser = (authToken, scCompania, { tipoEnvioOtp, telefonoEnvioOtp }) => {
  return axios({
    url: `${baseUrl}/api/affitoken/actualizarUsuario`,
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: {
      deliveryTypeOTP: tipoEnvioOtp,
      scCompania,
      telefonoEnvioOtp
    },
  }).then(response => {
    if (response.error && response.status !== 200) {
      return {
        status: false,
        message: 'Se presentó un error, por favor intentalo nuevamente'
      }
    } else {
      return {
        status: true,
        message: 'Medio de envio actualizado correctamente'
      }
    }
  })
}