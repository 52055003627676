export const tipoSociedad = [
    { key: 'LIMITADA', description: 'Limitada' },
    { key: 'COLECTIVA', description: 'Colectiva' },
    { key: 'ANONIMA', description: 'Anónima' },
    { key: 'ACCIONES', description: 'En comandita por acciones' },
    { key: 'SIMPLE', description: 'En comandita simple' },
    { key: 'SUCURSAL', description: 'Sucursal de sociedad extranjera' },
    { key: 'UNIPER', description: 'Empresa unipersonal' },
    { key: 'LUCRO', description: 'Entidad sin ánimo de lucro' },
    { key: 'SAS', description: 'SAS' },
    { key: 'OTRO', description: 'Otro' },
  ];