import React, { useEffect, useState } from 'react';
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button } from 'antd';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';
import moment from 'moment';

const initialValue = {
  feHasta: moment(new Date()),
  tipoEntidad: '',
  scEntidad: '',
};

const AgreementQuotaFilterForm = ({
  companyId,
  handleOnSearchEntidad,
  handleOnChangeEntidad,
  handleOnChangeTipoEntidad,
  handleSubmit,
  handleDateChange,
  feHasta,
  entidadSelect,
  scEntidad,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  const entidades = [
    { key: '', value: 'Todos' },
    { key: 'PAG', value: 'Pagador' },
    { key: 'PRO', value: 'Proveedor' },
    { key: 'GRP', value: 'Grupo empresarial' },
  ];

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='feHasta'
              label='Fecha hasta'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un día',
                },
              ]}
            >
              <DatePicker
                name='feHasta'
                value={feHasta}
                onChange={(moment, string) =>
                  handleDateChange(moment, 'feHasta')
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={5}>
            <Form.Item name='tipoEntidad' label='Tipo Entidad'>
              <Select onChange={handleOnChangeTipoEntidad}>
                {entidades.map((d) => (
                  <Select.Option key={d.key} value={d.key}>
                    {d.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              name='scEntidad'
              label='Entidad'
              rules={[{ message: 'seleccionar una entidad' }]}
            >
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchEntidad(value)}
                onChange={handleOnChangeEntidad}
                notFoundContent={
                  entidadSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {entidadSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AgreementQuotaFilterForm;
