import React, { useEffect, useState } from 'react';
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button } from 'antd';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';
import moment from 'moment';


const SELECT_PLACE_HOLDER = 'Seleccionar...';
const initialValue = {
  scFondeador: '',
  scEntidad: '',
  tipoEntidad: 'PAG',
  tipoCupo:undefined,
  tipoProducto:undefined
};

const TaskQuotaFilterForm = ({
  handleOnSearchEntidad,
  handleOnChangeEntidad,
  handleOnChangeTipoEntidad,
  entidadSelect,
  handleOnSearchFondeador,
  handleOnChangeFondeador,
  fondeadorSelect,
  tipoCupo,
  handleOnChangeTipoCupo,
  tipoProducto,
  handleOnChangeTipoProducto
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  const entidades = [
    { key: 'PAG', value: 'Pagador' },
    { key: 'PRO', value: 'Proveedor' },
    { key: 'GRP', value: 'Grupo empresarial' },
  ];

  return (
    <div className='header-container'>
      <p className='title'>Tareas masivas de cupos</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='scFondeador'
              label='Fondeador'
              dependencies={['scFondeador']}
              rules={[
                {
                  required: false,
                  message: 'seleccionar un fondeador',
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchFondeador(value)}
                onChange={handleOnChangeFondeador}
                notFoundContent={
                  fondeadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {fondeadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={7}>
            <Form.Item name='tipoEntidad' label='Tipo Entidad'>
              <Select onChange={handleOnChangeTipoEntidad}>
                {entidades.map((d) => (
                  <Select.Option key={d.key} value={d.key}>
                    {d.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={17}>
            <Form.Item
              name='scEntidad'
              label='Entidad'
              rules={[{ message: 'seleccionar una entidad' }]}
            >
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchEntidad(value)}
                onChange={handleOnChangeEntidad}
                notFoundContent={
                  entidadSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {entidadSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              name='tipoProducto' 
              label='Tipo de Producto'
              >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                onChange={handleOnChangeTipoProducto}
              >
                {tipoProducto.map((item) => (
                  <Select.Option key={item.codigo} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
              <Form.Item 
              name='tipoCupo' 
              label='Tipo de Cupo'
              >
                <Select
                placeholder={SELECT_PLACE_HOLDER}
                onChange={handleOnChangeTipoCupo}
                >
                  {tipoCupo.map((item) => (
                    <Select.Option key={item.codigo} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TaskQuotaFilterForm;
