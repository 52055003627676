import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  notification,
  Row,
  Spin,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  exportarExcelListaErrores,
  listaErrores,
  listarTiposErrores,
} from '../../../../services/admin/historics/transactionalErrorsService';
import { setExcel } from '../../../../utilities/downloadTools';
import { sorterDate, sorterText } from '../../../../utilities/textTools';
import './transactionalErrors.scss';
import TransactionalErrorsFilterForm from './transactionalErrorsFilterForm/transactionalErrorsFilterForm';

const initialState = {
  form: {
    fechaDesde: moment(),
    fechaHasta: moment(),
    cdTipoError: '',
  },
  tipoErrors: [],
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  select: '',
  visible: '',
};

class TransactionalErrors extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'FECHA ERROR',
        dataIndex: 'feError',
        sorter: (a, b) => sorterDate(a.feError, b.feError),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO ERROR',
        dataIndex: 'dsTipoErrorTrans',
        sorter: (a, b) => sorterText(a.dsTipoErrorTrans, b.dsTipoErrorTrans),
        showSorterTooltip: false,
      },
      {
        title: 'CÓDIGO',
        dataIndex: 'cdCodError',
        sorter: (a, b) => sorterText(a.cdCodError, b.cdCodError),
        showSorterTooltip: false,
      },
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'dsError',
        // sorter: (a, b) => sorterText(a.dsError, b.dsError),
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: 'detalle',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showModalDetalle(record)}
          >
            DETALLE
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  showModalDetalle = (record) => {
    if (typeof record !== 'undefined' && record != null) {
      this.setState({
        ...this.state,
        visible: true,
        select: record,
      });
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        cdTipoError: value.cdTipoError,
        fechaDesde: value.fechaRango[0],
        fechaHasta: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listaErrores(
          this.props.authToken,
          this.getFiltro()
        );
        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await exportarExcelListaErrores(
          this.props.authToken,
          this.getFiltro()
        );

        setExcel(response, `${moment().format('YYYY_MM_DD')}`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  getData = async () => {
    const response = await listarTiposErrores(this.props.authToken);

    this.setState({
      tipoErrors: response.data,
    });
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleCancel = () => {
    this.setState({ ...this.state, visible: false, select: '' });
  };

  componentDidMount() {
    this.props.menuHandler('75');
    this.getData();
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      tipoErrors,
      visible,
      select,
    } = this.state;

    return (
      <div className='reports'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={loading}>
                <TransactionalErrorsFilterForm
                  tipoErrors={tipoErrors}
                  handleSubmit={this.handleSubmit}
                />
                <div className='table-container'>
                  {this.state.resultado.length > 0 && (
                    <Row gutter={30} className='bill-selection' justify='end'>
                      <Col
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                        className='export'
                      >
                        <span className='button'>
                          <Button type='link' onClick={this.exportExcel}>
                            Exportar a excel
                          </Button>
                        </span>
                      </Col>
                    </Row>
                  )}
                  <Divider />
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </div>
              </Spin>
            </div>
          </div>
          <Modal
            title={`Detalle`}
            className='modal-style'
            visible={visible}
            onCancel={this.handleCancel}
            footer={''}
          >
            <div className='header-container'>
              <div dangerouslySetInnerHTML={{ __html: select }} />
            </div>
          </Modal>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(TransactionalErrors);
