import { Button, Col, Row, Spin, Tabs, notification } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import bulkloadServices from '../../../services/proveedor/bulkloadService';
import BulkLoadForm from './bulkloadform/bulkloadform';
import BulkLoadTable from './bulkloadtable/bulkloadtable';
import BulkloadNextForm from './bulkloadnextform/bulkloadnextform';
import BulkLoadAgile from './bulkloadagile/bulkloadagile';

class BulkLoad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        files: [],
      },
      facturasCargadas: [],
      buttonCargaDisable: false,
      buttonConfirmDisable: true,
      data: [],
      loading: false,
      bulkloadEstado: false,
      bulkloadGuardar: false,
      nombreArchivo: '',
      extensionArchivo: '',
      confirmArchivo: false,
    };
    this.refTabla = React.createRef();
  }

  handleSubmit = (values) => {
    this.setState({
      ...this.state,
      form: values,
      buttonCargaDisable: true,
      loading: true,
      data: [],
      buttonConfirmDisable: true,
    });

    const { authtoken, company } = this.props.authentication;

    bulkloadServices
      .upload(
        authtoken,
        company,
        this.state.form.files.map((fileValue) => fileValue.originFileObj)
      )
      .then((response) => {
        this.setState({
          ...this.state,
          data: response.data.map((value, index) => ({ ...value, key: index })),
          buttonCargaDisable: false,
          loading: false,
          nombreArchivo: response.metadata.nombreArchivo,
          extensionArchivo: response.metadata.extensionArchivo,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          data: [],
          buttonCargaDisable: false,
          loading: false,
          nombreArchivo: '',
          buttonConfirmDisable: true,
        });
      });
  };

  componentDidMount() {
    this.props.menuHandler('18');
  }

  componentDidUpdate(props, state) {
    if (
      this.props.authentication.company.company !==
      props.authentication.company.company
    ) {
      this.setState({
        ...state,
        form: {
          files: [],
        },
        buttonCargaDisable: false,
        buttonConfirmDisable: true,
        data: [],
        loading: false,
      });
    }
  }

  confirmUpload = () => {
    this.setState({
      ...this.state,
      buttonCargaDisable: true,
      buttonConfirmDisable: true,
      loading: true,
    });

    const { authtoken, company } = this.props.authentication;
    const { nombreArchivo } = this.state;
    const { extensionArchivo } = this.state;

    bulkloadServices
      .confirm(authtoken, company, nombreArchivo, extensionArchivo)
      .then((response) => {
        this.setState({
          ...this.state,
          data: response.data.map((value, index) => ({ ...value, key: index })),
          buttonCargaDisable: false,
          loading: false,
          buttonConfirmDisable: true,
          bulkloadEstado: extensionArchivo === 'xml' ? true : false,
          bulkloadGuardar: false,
          confirmArchivo: true,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          buttonCargaDisable: false,
          buttonConfirmDisable: false,
          loading: false,
          confirmArchivo: false,
        });
      });
  };

  handleSubmitGuardar = (values) => {
    this.setState({
      ...this.state,
      buttonCargaDisable: true,
      loading: true,
      buttonConfirmDisable: true,
    });

    const { authtoken, company } = this.props.authentication;
    const { nombreArchivo } = this.state;

    bulkloadServices
      .guardar(authtoken, company, values, nombreArchivo)
      .then((response) => {
        this.setState({
          ...this.state,
          data: response.data.map((value, index) => ({ ...value, key: index })),
          buttonCargaDisable: false,
          loading: false,
          buttonConfirmDisable: false,
          bulkloadEstado: false,
        });
      })
      .catch(() => {
        console.log(this.state.data);
        this.setState({
          ...this.state,
          data: [],
          buttonCargaDisable: false,
          loading: false,
          buttonConfirmDisable: true,
          bulkloadGuardar: true,
          bulkloadEstado: false,
        });
      });
    this.refTabla.current.scrollIntoView({ behavior: 'smooth' });
  };

  downloadTemplate = () => {
    const { authtoken, company } = this.props.authentication;
    bulkloadServices.downloadTemplate(authtoken, company).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.ms-excel;charset=ANSI',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Plantilla_Carga_Factoring.xlt`);
      document.body.appendChild(link);
      link.click();
    });
  };

  handleSubmitVolver = () => {
    this.setState({
      ...this.state,
      form: {
        files: [],
      },
      bulkloadEstado: false,
      bulkloadGuardar: false,
      data: [],
      nombreArchivo: '',
      confirmArchivo: false,
    });
  };

  handleUploadFacturas = (facturas) => {
    const { authtoken, company } = this.props.authentication;

    this.setState({loading: true});
    this.setState({ facturasCargadas: facturas });
  
    bulkloadServices
      .guardarAgile(authtoken, company, facturas)
      .then((response) => {
        this.setState({loading: false});
        
        const hasErrors = response.data.some((item) => item.errors && item.errors.length > 0);
        
        if(response.data.length === 0 || hasErrors) {
          notification.error({
            message: 'No se pudieron cargar las facturas',
            duration: 5,
          });
        } else if (hasErrors) {
          notification.warn({
            message: 'Algunas facturas no se cargaron correctamente',
            duration: 5,
          });
        } else {
          notification.success({
            message: 'Facturas cargadas',
            duration: 5,
          });
        }
      })
      .catch(() => {
        this.setState({loading: false});
        notification.error({
          message: 'Error',
          description: 'Hubo un error en la carga, intente de nuevo.',
          duration: 5,
        });
      });
  };
  
  render() {
    return (
      <Tabs>
        <Tabs.TabPane key={0} tab='Carga ágil'>
          <div className='content-margin bulkload-style'>
            <div className='card-background-light card-shadow radius'>
              <div className='align-center'>
                <div className='content-container'>
                  <Spin tip='Cargando...' spinning={this.state.loading}>
                    <BulkLoadAgile
                      onUploadFacturas={this.handleUploadFacturas}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key={1} tab='Carga masiva'>
          <div className='content-margin bulkload-style'>
            <div className='card-background-light card-shadow radius'>
              <div className='align-center'>
                <div ref={this.refTabla} className='content-container'>
                  {!this.state.bulkloadEstado && !this.state.bulkloadGuardar && (
                    <>
                      {' '}
                      <BulkLoadForm
                        formValue={this.state.form}
                        handleSubmit={this.handleSubmit}
                        disabledButton={this.state.buttonCargaDisable}
                        downloadTemplate={this.downloadTemplate}
                      />
                      <Spin tip='Cargando...' spinning={this.state.loading}>
                        {Array.isArray(this.state.data) &&
                          this.state.data.length >= 1 && (
                            <BulkLoadTable
                              data={this.state.data}
                              confirmed={this.state.confirmArchivo}
                            />
                          )}

                        {this.state.data.map((el) => {
                          if (!el.errors) {
                            this.state.buttonConfirmDisable = false;
                          }
                        })}
                        {Array.isArray(this.state.data) &&
                        this.state.data.length > 0 ? (
                          <Row align='middle' justify='center'>
                            <Col>
                              <Button
                                className='btn btn-green'
                                onClick={this.confirmUpload}
                                disabled={this.state.buttonConfirmDisable}
                              >
                                Confirmar archivo
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}
                      </Spin>{' '}
                    </>
                  )}
                  {this.state.bulkloadEstado && (
                    <Spin tip='Cargando...' spinning={this.state.loading}>
                      <BulkloadNextForm
                        handleSubmit={this.handleSubmitGuardar}
                        formValue={this.state.data[0]}
                        handleSubmitVolver={this.handleSubmitVolver}
                      />
                    </Spin>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: {
      authtoken: state.auth.auth.access_token,
      company: state.billing.company,
    },
  };
};
export default connect(mapStateToProps)(BulkLoad);
