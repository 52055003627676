import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Switch,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  listaPagadores,
  listarPagadoresExcel,
} from '../../../../services/admin/payersService';
import history from '../../../../services/history';
import { setExcel } from '../../../../utilities/downloadTools';
import { BUSQUEDA_TITLE } from '../../../../utilities/messages';
import { sorterText } from '../../../../utilities/textTools';
import './payers.scss';

const IDENTIFICATION_FILTRO = 'Identificación';
const NAME_FILTRO = 'Nombre/Razón social';

const initialState = {
  loading: false,
  form: {
    nombre: '',
    id: '',
  },
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  filtroSeleccionado: NAME_FILTRO,
};

class Payers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'TIPO ID',
        dataIndex: 'dsTipoId',
        key: 'dsTipoId',
        sorter: (a, b) => sorterText(a.dsTipoId, b.dsTipoId),
        showSorterTooltip: false,
        width: 50,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.id, b.id),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstado',
        sorter: (a, b) => sorterText(a.dsEstado, b.dsEstado),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showEditPage(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.formSearch = React.createRef();
  }

  showEditPage = (record) => {
    history.push(
      `${process.env.PUBLIC_URL}/payers/edit/${btoa(record.scPersona)}`
    );
  };

  handleSwitchChange = (check) => {
    let nombre = NAME_FILTRO;
    if (check) {
      nombre = IDENTIFICATION_FILTRO;
    }

    this.formSearch.current.setFieldsValue({
      tipoSearch: check,
      nombre: '',
      id: '',
    });

    this.setState({
      ...this.state,
      filtroSeleccionado: nombre,
      filtros: {
        ...this.state.filtros,
        nombre: '',
        id: '',
      },
    });
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      resultado: [],
      total: initialState.total,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.getData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaPagadores(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarPagadoresExcel(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );

      setExcel(response, 'Listado_de_Pagadores');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;

    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  componentDidMount() {
    this.props.menuHandler('22');
    this.getData();
  }

  render() {
    const {
      filtroSeleccionado,
      loading,
      currentPage,
      limit,
      total,
      resultado,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='payers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <Row gutter={[16, 32]}>
                <Col span={24}>
                  <Space align='baseline' className='title'>
                    {BUSQUEDA_TITLE}
                  </Space>
                </Col>
              </Row>
              <Form
                ref={this.formSearch}
                name='erp_search'
                layout='vertical'
                onFinish={this.handleSubmit}
              >
                <Row gutter={[16, 32]}>
                  <Col span={6}>
                    <Form.Item name='tipoSearch' label='Filtro'>
                      <Switch
                        checkedChildren={IDENTIFICATION_FILTRO}
                        unCheckedChildren={NAME_FILTRO}
                        onChange={this.handleSwitchChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    {filtroSeleccionado === NAME_FILTRO && (
                      <Form.Item name='nombre' label={NAME_FILTRO}>
                        <Input />
                      </Form.Item>
                    )}
                    {filtroSeleccionado === IDENTIFICATION_FILTRO && (
                      <Form.Item name='id' label={IDENTIFICATION_FILTRO}>
                        <Input />
                      </Form.Item>
                    )}
                  </Col>

                  <Col span={6}>
                    <Form.Item>
                      <Button
                        className='form-btn btn-radius'
                        type='primary'
                        htmlType='submit'
                      >
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de pagadores
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                    <Button
                      size='small'
                      onClick={() =>
                        history.push(`${process.env.PUBLIC_URL}/payers/new`)
                      }
                      className='btn-agregar'
                    >
                      + Agregar nuevo pagador
                    </Button>
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                    <Button type='link' onClick={this.exportExcel}>
                      Exportar a excel
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Spin>
    );
  }

  /* 

  showModalEditar = (record) => {
    if (typeof record !== 'undefined' && record != null) {
      this.setState({
        ...this.state,
        parametrosModal: {
          //...this.state.parametrosModal,
          diasPagosFond: [],
          ...record,
        },
        isNew: false,
        activeFilter: true,
      });
    } else {
      this.setState({
        ...this.state,
        isNew: true,
        parametrosModal: initialValuesModal,
        activeFilter: true,
      });
    }
  }; */
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Payers);
