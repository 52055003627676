import React, { useEffect, useState } from "react";
import { DatePicker, Input, Button, Row, Col, notification, Form } from "antd";
import { DATE_FORMAT } from "../../../../utilities/messages";
import moment from 'moment';

const initialValue = {
  cufe: undefined,
  facturas: undefined,
};

const BulkLoadAgile = ({ handleSubmit, onUploadFacturas }) => {
  const [feEstimadaPago, setFeEstimadaPago] = useState(null);
  const [cufe, setCufe] = useState("");
  const [facturas, setFacturas] = useState([]);
  const [facturasCargadas, setFacturasCargadas] = useState(false);

  const regexCUFE = /^[0-9A-Fa-f]{96}$/;

  const handleAddFactura = () => {
    if (feEstimadaPago == null || cufe == null) {
      notification.warn({
        message: "Completa todos los campos antes de añadir una factura.",
        duration: 5,
      });
      return;
    }

    if (!regexCUFE.test(cufe)) {
      notification.error({
        message: "El formato del CUFE es inválido.",
        duration: 5,
      });
      return;
    }

    if (facturas.some((factura) => factura.cufe === cufe)) {
      notification.error({
        message: "El CUFE ya ha sido agregado anteriormente.",
        duration: 5,
      });
      return;
    }

    const nuevaFactura = {
      feEstimadaPago,
      cufe,
    };

    const nuevasFacturas = [...facturas, nuevaFactura];
    setFacturas(nuevasFacturas);

    setFeEstimadaPago(null);
    setCufe("");
    form.setFieldsValue({
      feEstimadaPago: null,
      cufe: "",
    });
  };

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  const handleUploadFacturas = () => {
    if (facturas.length === 0) {
      notification.error({
        message: "No hay facturas para cargar.",
        duration: 5,
      });
      return;
    }
    
    const facturasFormateadas = facturas.map((factura) => ({
      ...factura,
      feEstimadaPago: factura.feEstimadaPago
        ? moment(factura.feEstimadaPago).format(DATE_FORMAT)
        : null,
    }));
  
    onUploadFacturas(facturasFormateadas);
    setFacturas([]);
    setFacturasCargadas(true);

    setTimeout(() => {
      setFacturasCargadas(false);
    }, 2000);
  };

  const renderFacturas = () => {
    return facturas.map((factura, index) => (
      <div key={index} style={{ marginTop: "1.5rem" }}>
        <Row gutter={24} style={{ marginTop: '1rem' }}>
          <Col flex="none">
            <DatePicker 
              format={DATE_FORMAT} 
              value={moment(factura.feEstimadaPago, DATE_FORMAT)}
              style={{ width: "14rem", marginTop: "0.0625rem" }} 
            />
          </Col>
          <Col flex="auto">
            <Input.TextArea
              value={factura.cufe}
              style={{
                resize: "none",
                width: "100%",
                height: "2rem",
                paddingTop: "0.25rem",
                overflow: "hidden",
                marginTop: "0.0625rem"
              }}
            />
          </Col>
        </Row>
      </div>
    ));
  };
  
  return (
    <div>
      <Form
        name="content_form"
        layout="vertical"
        className="filters-container"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={24} style={{ marginTop: '1rem' }}>
          <Col flex="none">
            <p 
              style={{ 
                marginBottom: '1.5rem',
                color: '#828282',
                fontFamily: 'Roboto'
              }}
            >
              Fecha estimada de pago
            </p>
            <Form.Item name="feEstimadaPago">
              <DatePicker
                format={DATE_FORMAT}
                value={feEstimadaPago}
                onChange={(date) => setFeEstimadaPago(date)}
                style={{ width: "14rem", marginTop: "0.0625rem" }}
              />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <p 
              style={{ 
                marginBottom: '1.5rem',
                color: '#828282',
                fontFamily: 'Roboto'
              }}
            >
              Cufe
            </p>
            <Form.Item name="cufe">
              <Input.TextArea
                value={cufe}
                onChange={(e) => setCufe(e.target.value)}
                style={{
                  resize: "none",
                  width: "100%",
                  height: "2rem",
                  paddingTop: "0.25rem",
                  overflow: "hidden",
                  marginTop: "0.0625rem"
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {renderFacturas()}
        <Row justify="space-between" style={{ marginTop: '1.5rem' }}>
          <Col>
            <Button
              className="btn btn-green"
              onClick={handleAddFactura}
              disabled={facturas.length >= 5 || facturasCargadas}
            >
              {facturas.length >= 5 ? "Máximo alcanzado" : "Añadir"}
            </Button>
          </Col>
          {facturas.length > 0 && facturas.length <= 5 && !facturasCargadas && (
            <Col>
              <Button
                htmlType="submit"
                className="btn btn-green"
                onClick={handleUploadFacturas}
              >
                Cargar facturas
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );  
};

export default BulkLoadAgile;
