import { Button, Card, Col, Divider, Modal, Popover, Row, Space, Spin, Table, notification,Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import TableReport from '../../../components/tableReport/tableReport';
import { sorterDate, sorterText } from '../../../utilities/textTools';
import { buscarSolicitudes } from '../../../services/proveedor/requestServices';
import { estadoSolicitudMsj } from '../../../utilities/estadoSolicitudMsj';
import moment from 'moment';


import history from '../../../services/history';
import { DATE_FORMAT } from '../../../utilities/messages';
import { FormRequest } from './FormRequest/formRequest';




export const MyRequests = (props) => {
    const [loading, setloading] = useState(false);
    const [showmodal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const token = useSelector(state => state.auth.auth.access_token);
    const scPersona = useSelector(state => state.auth.auth.scPersona);
    const scProveedor = useSelector(state => state.billing.company);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState('');
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [resultado, setResultado] = useState([]);

    const { Text } = Typography;


    const columnas = [
      {
        title: 'FECHA CREACION',
        dataIndex: 'feCreacionSolicitud',
        sorter: (a, b) => sorterDate(a.feCreacionSolicitud, b.feCreacionSolicitud),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO SOLICITUD',
        dataIndex: 'dsTipoSolicitud',
        sorter: (a, b) => sorterText(a.dsTipoSolicitud, b.dsTipoSolicitud),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        render: (record) =>  (
          <Popover
                  title={<b>{'DETALLE ESTADO'}</b>}
                  content={
                    estadoSolicitudMsj.find((estado) => estado.key === record.cdEstado)?.description + " " + (record.cdEstado === 'DI' ? record.dsObservacionDI : '')
                  }
            >
              <Text>
                {record.dsEstado}
              </Text>
            </Popover>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: '',
        render: (record) => (
            <Button type='primary' onClick={() => showModalEditar(record)}>
              VER DETALLE
            </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    const showModalEditar = (record) => {
      if(record.cdEstado === 'PD' || record.cdEstado === 'DI'){
        history.push(`${process.env.PUBLIC_URL}/oldrequest/${Buffer.from(record.cdTipoSolicitud).toString('base64')}/${Buffer.from(record.cdEstado).toString('base64')}/${Buffer.from(record.scSolicitud).toString('base64')}`);
      }else{
        setModalData(record);
        setShowModal(true);
      }
    }

    const handleChange = (e) => {
      setLimit(e.limit);
      setSort(e.sort);
      setOffset(e.offset);
      setCurrentPage(e.currentPage);
    };

    const getFiltro = () => {
      return {
        limit: limit,
        offset: offset,
        sort: sort,
      };
    };

    const handleOnCloseModal = () => {
      setShowModal(false);
    }

    const getSolicitudes = async () =>{
      setloading(true);            
      try {
        const res = await buscarSolicitudes(token,scProveedor,getFiltro());
        setResultado(res.data);
        setTotal(res.metadata.count);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: "Error validando estado " + error,
          duration: 4,
        })
      } finally {
        setloading(false);
      }
    }

    useEffect(() => {
      if(scProveedor){
        getSolicitudes();
      }
    }, [limit,offset,sort,currentPage])

    
    useEffect(() => {
      props.menuHandler('12');
      if(scProveedor){
        getSolicitudes();
      }
    }, [scProveedor])
    
  return (
    <Spin tip='Cargando...' spinning={loading}>
        <div className='users-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Mis solicitudes
                  </Space>
                </Col>
              </Row>
              <Modal
                title='Detalle Solicitud'
                visible={showmodal}
                className='modal-style'
                footer={null}
                onCancel={handleOnCloseModal}

              >
                <FormRequest
                  tipoSolicitud={modalData.cdTipoSolicitud}
                  readMode={true}
                  recordData={modalData}
                />
              </Modal>
              <div className='table-container'>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={columnas}
                    data={resultado}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
            </div>
            </div>
          </Card>
        </div>
      </Spin>
  )
}
