import {
  CaretDownOutlined,
  FileExcelOutlined,
  InfoCircleTwoTone,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  notification,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Table,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import QueryString from 'qs';
import './program.scss';
import moment from 'moment';
import ProgramForm from './programForm/programForm';
import {
  listarDiasHabiles,
  listarOfertaCompraProgramacion,
  ofertasProgramacionExcel,
  resumenCompraProgramacion,
} from '../../../../services/admin/reports/program';
import { setExcel } from '../../../../utilities/downloadTools';
import { listarProveedores } from '../../../../services/admin/reports/quotasService';
import CufeText from '../../../../components/bills/cufeText/cufeText';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';

const { Option } = Select;
class Program extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      investors: [],
      selectedInvestor: '',
      selectedBusinessGroup: '',
      listedBills: [],
      loading: false,
      scProveedor: '',
      nombreProveedor: '',
      selectedKeys: [],
      selectAll: false,
      unselectedKeys: [],
      seleccion: 0,
      suma: 0,
      cantidadFactura: 0,
      proveedorSelect: {
        fetching: false,
        options: [],
      },
      formProgram: {
        fechaDesde: '',
        scProveedor: '',
      },
      formOfertBills: {
        fechaOferta: '',
        scProveedor: '',
        scFondeador: '',
        scGrupo: '',
      },
      fechasActivas: [],
    };

    this.businessGroupSelect = React.createRef();
    this.cards = [];
  }

  billsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
      showSorterTooltip: false,
    },
    {
      title: 'NUEVA',
      dataIndex: 'snNueva',
      sorter: (a, b) => sorterText(a.snNueva, b.snNueva),
      showSorterTooltip: false,
    },
    {
      title: 'SOCIEDAD',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => sorterText(a.nombrePagador - b.nombrePagador),
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmision',
      sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimiento',
      sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFactura',
      render: (value) => currencyFormat(value),
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      showSorterTooltip: false,
    },
    {
      title: 'OFERTA DE COMPRA',
      dataIndex: 'vrOferta',
      render: (text) => (
        <span className='offer-value'>{currencyFormat(text)}</span>
      ),
      sorter: (a, b) => a.vrOferta - b.vrOferta,
      showSorterTooltip: false,
    },
    {
      title: 'NOTAS',
      dataIndex: 'notasCredito',
      render: (object) =>
        object.length > 0 ? <span className='has-credits'>N </span> : '',
      sorter: (a, b) => {
        if (a.notasCredito.length > 0 && b.notasCredito.length <= 0) {
          return -1;
        }
        if (a.notasCredito.length <= 0 && b.notasCredito.length > 0) {
          return 1;
        }

        if (a.notasCredito.length > 0 && b.notasCredito.length > 0) {
          return 0;
        }
      },
      showSorterTooltip: false,
    },
    {
      title: 'TIPO CUPO',
      dataIndex: 'cdTipoCupo',
      sorter: (a, b) => sorterText(a.cdTipoCupo, b.cdTipoCupo),
      showSorterTooltip: false,
    },
    {
      title: 'CUFE',
      dataIndex: '',
      render: (object) => (
        <Row align='middle'>
          <Col flex='auto'>
            <CufeText cufe={object.cufe} />
          </Col>
        </Row>
      ),
    },
    {
      title: '',
      dataIndex: 'inCart',
      render: (text) =>
        text && (
          <span className='current-founder'>
            {text} <ShoppingCartOutlined />
          </span>
        ),
    },
    {
      title: '',
      dataIndex: 'detail',
      render: (text) => (
        <Popover placement='topRight' content={text} title='Detalle'>
          <InfoCircleTwoTone className='info' />
        </Popover>
      ),
    },
  ];

  creditsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFacturaRest',
      sorter: (a, b) => sorterText(a.cdFacturaRest, b.cdFacturaRest),
      showSorterTooltip: false,
    },
    {
      title: 'SOCIEDAD',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmisionRest',
      sorter: (a, b) => sorterDate(a.feEmisionRest, b.feEmisionRest),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimientoRest',
      sorter: (a, b) => sorterDate(a.feVencimientoRest, b.feVencimientoRest),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFacturaRest',
      render: (value) => currencyFormat(value),
      sorter: (a, b) => a.vrFacturaRest - b.vrFacturaRest,
      showSorterTooltip: false,
    },
  ];

  componentDidUpdate(props, state) {
    if (
      state.selectedInvestor.scFondeador !==
      this.state.selectedInvestor.scFondeador
    ) {
      this.businessGroupSelection('all', this.state.selectedInvestor);
    }
  }

  componentDidMount() {
    this.props.menuHandler('67');
    this.validarFechasActivas();
  }
  parseQueryString = (props) => {
    const {
      location: { search },
    } = props;

    const filtro = QueryString.parse(search, { ignoreQueryPrefix: true });

    return filtro;
  };

  info(investor) {
    var investorCode = investor.scFondeador;
    Modal.info({
      title: 'Condiciones de ' + investor.nombreFondeador,
      className: 'modal-condiciones',
      content: (
        <iframe
          id='conditions'
          title='Condiciones'
          width='100%'
          height='100%'
          src={
            'https://www.exponencialconfirming.com/condiciones-de-uso-' +
            investorCode
          }
        ></iframe>
      ),
      footer: null,
    });
  }
  subscribe(investor) {
    var investorCode = investor.scFondeador;
    Modal.info({
      title: 'Inscribirse a ' + investor.nombreFondeador,
      width: 900,
      content: (
        <iframe
          id='conditions'
          title='Inscribirse'
          width='100%'
          height='600px'
          src={`https://www.exponencialconfirming.com/descarga-documentos-${investorCode}`}
        ></iframe>
      ),
    });
  }

  formatCreditDate(date) {
    var newDate = new Date(date);
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    month = month < 9 ? '0' + month.toString() : month;
    day = day < 9 ? '0' + day.toString() : day;
    newDate = (year + '-' + month + '-' + day).toString();
    return newDate;
  }

  formatInvestorDate = (date) => {
    var newDate = {};
    newDate.fullDate = new Date(date);
    newDate.day = newDate.fullDate.getDay();
    newDate.month = newDate.fullDate.getMonth();
    newDate.dayNumber = newDate.fullDate.getDate();
    var currentDate = {};
    currentDate.fullDate = new Date();
    currentDate.day = currentDate.fullDate.getDay();
    currentDate.month = currentDate.fullDate.getMonth();
    currentDate.dayNumber = currentDate.fullDate.getDate();
    if (
      newDate.day === currentDate.day &&
      newDate.month === currentDate.month &&
      newDate.dayNumber === currentDate.dayNumber
    ) {
      return 'Hoy';
    } else {
      const months = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ];
      const days = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ];
      return (
        days[newDate.day] +
        ' ' +
        newDate.dayNumber +
        ' de ' +
        months[newDate.month]
      );
    }
  };

  investorSelection(e) {
    this.setState({
      ...this.state,
      selectedInvestor: e,
      selectedBusinessGroup: {
        scGrupo: 'all',
      },
      selectedRows: [],
      loading: true,
    });

    this.billsSelectionSectioIdTime = setTimeout(() => {
      this.setState({
        ...this.state,
        loading: false,
      });
      var section = document.getElementById('bill-selection-section');
      if (section) {
        section.scrollIntoView();
      }
    }, 1000);
  }

  creditReducer = (accumulator, currentValue) =>
    accumulator + currentValue.vrFacturaRest;

  offerReducer = (accumulator, currentValue) =>
    accumulator + currentValue.vrOferta;

  businessGroupSelection = (value, e) => {
    var selectedInvestor = '';
    selectedInvestor = this.state.selectedInvestor;
    this.state.formOfertBills.scFondeador = selectedInvestor.scFondeador;
    const formBill = this.state.formOfertBills;
    const token = this.props.authToken;
    this.setState({
      ...this.state,
      listedBills: [],
      loading: true,
      selectedBusinessGroup: {
        scGrupo: 'all',
      },
    });
    if (value !== 'all') {
      listarOfertaCompraProgramacion(token, formBill)
        .then((response) => {
          var objBusinessGroup = {};
          objBusinessGroup.totalOffer = 0;
          objBusinessGroup.totalCredit = 0;
          objBusinessGroup.checked = false;
          objBusinessGroup.collapsed = 'collapsed';
          objBusinessGroup.bills = response.data;
          objBusinessGroup.credits = [];
          objBusinessGroup.info = selectedInvestor.grupoEmpresariales
            .map(
              (grupoEmpresarial) =>
                grupoEmpresarial.scGrupo === value && grupoEmpresarial
            )
            .filter(Boolean)[0];
          for (var i = 0; i < response.data.length; i++) {
            objBusinessGroup.bills[i].key =
              objBusinessGroup.bills[i].scFactura +
              '-' +
              value +
              '-' +
              selectedInvestor.scFondeador;
            objBusinessGroup.bills[i].detail = (
              <div
                dangerouslySetInnerHTML={{
                  __html: objBusinessGroup.bills[i].calculo,
                }}
              ></div>
            );
            objBusinessGroup.totalOffer += objBusinessGroup.bills[i].vrOferta;
            if (objBusinessGroup.bills[i].notasCredito.length > 0) {
              objBusinessGroup.bills[i].notasCredito.forEach((credit) => {
                objBusinessGroup.totalCredit += credit.vrFacturaRest;
                credit.key =
                  credit.scFactura +
                  '-' +
                  value +
                  '-' +
                  selectedInvestor.scFondeador;
                objBusinessGroup.credits.push(credit);
              });
              objBusinessGroup.totalCredit += objBusinessGroup.bills[
                i
              ].notasCredito.reduce(this.creditReducer, 0);
            }
          }
          this.setState({
            ...this.state,
            selectedBusinessGroup: objBusinessGroup.info,
            listedBills: [objBusinessGroup],
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            ...this.state,
            loading: false,
          });
        });
    } else {
      const objInvestor = selectedInvestor ? selectedInvestor : e;
      var selectedBills = [];
      if (objInvestor) {
        objInvestor.grupoEmpresariales.forEach((grupoEmpresarial) => {
          formBill.scGrupo = grupoEmpresarial.scGrupo;
          listarOfertaCompraProgramacion(token, formBill)
            .then((response) => {
              selectedBills = [];
              selectedBills.info = grupoEmpresarial;
              selectedBills.checked = false;
              selectedBills.totalOffer = response.data.reduce(
                this.offerReducer,
                0
              );
              selectedBills.totalCredit = 0;
              selectedBills.collapsed = 'collapsed';
              selectedBills.credits = [];
              response.data.forEach((bill) => {
                bill.key =
                  bill.scFactura +
                  '-' +
                  grupoEmpresarial.scGrupo +
                  '-' +
                  objInvestor.scFondeador;
                bill.detail = (
                  <div dangerouslySetInnerHTML={{ __html: bill.calculo }}></div>
                );
                if (bill.notasCredito.length > 0) {
                  bill.notasCredito.forEach((credit) => {
                    selectedBills.totalCredit += credit.vrFacturaRest;
                    credit.key =
                      credit.scFactura +
                      '-' +
                      grupoEmpresarial.scGrupo +
                      '-' +
                      objInvestor.scFondeador;
                    selectedBills.credits.push(credit);
                  });
                }
              });
              selectedBills.bills = response.data;
              this.setState({
                ...this.state,
                listedBills: [...this.state.listedBills, selectedBills],
                loading: false,
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                loading: false,
              });
            });
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  changeTable = (businessGroup) => {
    var module = '';
    module = 'listedBills';
    var index = this.state[module].findIndex(
      (listedBill) => businessGroup.info.scGrupo === listedBill.info.scGrupo
    );
    this.state[module][index].checked = !this.state[module][index].checked;
    this.setState({
      ...this.state,
    });
  };

  openTable = (businessGroup) => {
    var module = '';
    if (this.props.module === 'program') {
      module = 'programListedBills';
    } else {
      module = 'listedBills';
    }
    var index = this.state[module].findIndex(
      (listedBill) => businessGroup.info.scGrupo === listedBill.info.scGrupo
    );
    this.state[module][index].collapsed =
      this.state[module][index].collapsed === '' ? 'collapsed' : '';
    this.setState({
      ...this.state,
    });
  };

  filtrarRegitrosByGrupo = (businessGroup) => {
    const scGrupo = businessGroup.info.scGrupo;
    if (businessGroup.checked) {
      return businessGroup.credits.filter(
        (credits) => credits.scGrupo === scGrupo
      );
    } else {
      return businessGroup.bills.filter((bill) => bill.scGrupo === scGrupo);
    }
  };

  cantidadDeFacturasByGrupo = (businessGroup) => {
    const scGrupo = businessGroup.info.scGrupo;
    let bill = businessGroup.bills.filter((bill) => bill.scGrupo === scGrupo);
    return bill.length;
  };
  calcTotalFacturasByGrupo = (businessGroup) => {
    const scGrupo = businessGroup.info.scGrupo;
    let bills = businessGroup.bills.filter((bill) => bill.scGrupo === scGrupo);
    let value = 0;
    bills.map((bill) => {
      value += bill.vrOferta;
      return value;
    });
    businessGroup.totalOffer = value;
    return value;
  };

  calcTotalNotasByGrupo = (businessGroup) => {
    const scGrupo = businessGroup.info.scGrupo;
    let credits = businessGroup.credits.filter(
      (credit) => credit.scGrupo === scGrupo
    );
    let value = 0;
    credits.map((credit) => {
      value += credit.vrFacturaRest;
      return value;
    });
    businessGroup.totalCredit = value;
    return value;
  };

  exportExcel = async () => {
    const { selectedInvestor, formOfertBills } = this.state;
    const { authToken } = this.props;
    formOfertBills.scFondeador = selectedInvestor.scFondeador;
    this.getFiltro();
    try {
      const response = await ofertasProgramacionExcel(
        authToken,
        this.getFiltro()
      );
      setExcel(response, `Ofertas`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { formOfertBills, limit, offset, sort } = this.state;
    return {
      ...formOfertBills,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  componentWillUnmount() {
    clearTimeout(this.billsSelectionSectioIdTime);
  }

  onSelect = (record, selected) => {
    const { selectAll } = this.state;
    let unselectedKeys = [];
    if (selected) {
      if (this.state.selectAll) {
        unselectedKeys = this.state.unselectedKeys.filter(
          (key) => key !== record.key
        );
      }
      this.setState({
        ...this.state,
        selectedKeys: [...this.state.selectedKeys, record.key],
        unselectedKeys: unselectedKeys,
        suma: this.state.suma + record.vrOferta,
      });
    } else {
      if (selectAll) {
        unselectedKeys = [...this.state.unselectedKeys, record.key];
      }
      let filterKeys = this.state.selectedKeys.filter(
        (selectedKey) => selectedKey !== record.key
      );
      this.setState({
        ...this.state,
        selectedKeys: filterKeys,
        unselectedKeys: unselectedKeys,
        suma: this.state.suma - record.vrOferta,
      });
    }
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      changeRows.forEach((row) => {
        let selected = this.state.selectedKeys.find((key) => row.key === key);
        if (!selected) {
          this.state.selectedKeys = [...this.state.selectedKeys, row.key];
        }
      });
      this.setState({
        ...this.state,
        unselectedKeys: [],
        selectAll: selected,
        suma: this.state.listedBills[0].totalOffer,
      });
    } else {
      this.setState({
        ...this.state,
        selectedKeys: [],
        unselectedKeys: [],
        selectAll: selected,
        suma: 0.0,
      });
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  validarFechasActivas = () => {
    const { authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    listarDiasHabiles(authToken).then((response) => {
      let fechas = response.data;
      if (Array.isArray(fechas)) {
        this.setState({
          ...this.state,
          fechasActivas: fechas.map((fecha) => moment(fecha)),
          loading: false,
        });
      }
    });
  };

  disabledDate = (date) => {
    const { fechasActivas } = this.state;
    if (Array.isArray(fechasActivas) && fechasActivas.length === 6) {
      return (
        date < moment().endOf('day') ||
        (date.format('YYYY-MM-DD') !== fechasActivas[0].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[1].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[2].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[3].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[4].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[5].format('YYYY-MM-DD'))
      );
    } else {
      return date < moment().endOf('day');
    }
  };

  handleSearchProgram = (values) => {
    const filtro = this.parseQueryString(this.props);
    const selectedProvider = values.scProveedor;
    this.setState({
      ...this.state,
      formProgram: {
        ...values,
      },
    });
    if (selectedProvider !== '') {
      this.setState({
        ...this.state,
        nombreProveedor: filtro.nombreProveedor,
        loading: true,
        scProveedor: selectedProvider,
      });
      const token = this.props.authToken;
      resumenCompraProgramacion(token, this.state.formProgram)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            var selectedInvestor = '';
            this.setState({
              ...this.state,
              investors: response.data,
              selectedInvestor: selectedInvestor || '',
              loading: false,
              formOfertBills: {
                fechaOferta: values.fechaDesde,
                scProveedor: values.scProveedor,
                scFondeador: response.data[0].scFondeador,
                scGrupo: response.data[0].grupoEmpresariales[0].scGrupo,
              },
            });
          } else {
            this.setState({
              ...this.state,
              loading: false,
            });
            notification.info({
              message: 'Sin ofertas',
              description: 'este proveedor no posee ofertas pendientes',
              duration: 4,
            });
          }
        })
        .catch(() => {
          this.setState({
            ...this.state,
            loading: false,
          });
        });
    }
  };

  render() {
    const { selectedKeys, formProgram, proveedorSelect } = this.state;
    var listedBills = [];
    listedBills = this.state.listedBills;
    const { investors } = this.state;
    var selectedInvestor = '';
    selectedInvestor = this.state.selectedInvestor;
    var selectedBusinessGroup = '';
    selectedBusinessGroup = this.state.selectedBusinessGroup;
    return (
      <div className='billing-style'>
        <div className='bills-style'>
          <Spin tip='Cargando...' spinning={this.state.loading}>
            <Card className='card-shadow card-radius default-border welcome-message '>
              <ProgramForm
                data={formProgram}
                handleOnSearchProveedor={this.handleOnSearchProveedor}
                disabledDate={this.disabledDate}
                proveedorSelect={proveedorSelect}
                handleSubmit={this.handleSearchProgram}
              />
            </Card>
            {investors.length > 0 && (
              <div>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className='title-info'>Elige el fondeador final</div>
                  </Col>
                </Row>
              </div>
            )}
            <Row gutter={[16, 16]} className='investors-container'>
              {investors.map((investor, key) => {
                return (
                  <Col
                    xl={{ span: 6 }}
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    key={investor.scFondeador + '-' + key}
                  >
                    <Card
                      ref={(cards) => (this.cards[key] = cards)}
                      className={
                        'investor-card card-radius default-border card-shadow ' +
                        (selectedInvestor.scFondeador === investor.scFondeador
                          ? ' active selected'
                          : '')
                      }
                      id={investor.scFondeador}
                      onClick={() => this.investorSelection(investor)}
                    >
                      <div className='title'>
                        {investor.nombreFondeador.toUpperCase()}
                      </div>
                      <div className={'logo-container'}>
                        <img
                          src={
                            investor.dsLogo
                              ? investor.dsLogo
                              : 'https://resources-affirmatum.s3.amazonaws.com/Pronto_Pago_MF.png'
                          }
                          alt=''
                          className='logo'
                        />
                      </div>
                      <div className='value'>
                        {currencyFormat(investor.vrOfertaTotal)}
                      </div>
                      <div className='payers'>
                        {investor.grupoEmpresariales.map((grupoEmpresarial) => (
                          <p key={`${grupoEmpresarial.scGrupo}_key`}>
                            {grupoEmpresarial.dsNombres}
                          </p>
                        ))}
                      </div>
                      <div className='other-data'>
                        Desembolso: {investor.fechaDesembolso}
                      </div>
                      <div className='other-data'>
                        Nro. Facturas: {investor.nroFacturas}
                      </div>
                      <div className='conditions'>
                        <a href='#' onClick={() => this.info(investor)}>
                          VER CONDICIONES
                        </a>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            {selectedInvestor !== '' && (
              <div id='bill-selection-section'>
                <Card className='card-shadow card-radius default-border welcome-message '>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div
                        className='subtitle'
                        dangerouslySetInnerHTML={{
                          __html: `<strong> <em> ${selectedInvestor.mensajeFondeador}  </em> </strong>`,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </Card>
                <Row gutter={30} className='bill-selection' align='middle'>
                  <Col>
                    <span className='title'>Elige facturas</span>
                  </Col>
                  <Col>
                    <div className='select-label'>
                      Filtrar por grupo empresarial:
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className='select-container'>
                      <Select
                        size='small'
                        defaultValue={'all'}
                        value={selectedBusinessGroup.scGrupo}
                        onChange={this.businessGroupSelection}
                      >
                        <Option key={'all'} value={'all'}>
                          TODOS
                        </Option>
                        {selectedInvestor.grupoEmpresariales.map(
                          (grupoEmpresarial) => (
                            <Option
                              key={grupoEmpresarial.scGrupo}
                              value={grupoEmpresarial.scGrupo}
                            >
                              {grupoEmpresarial.dsNombres.toUpperCase()}
                            </Option>
                          )
                        )}
                      </Select>
                    </div>
                  </Col>
                  <Col span={2}>
                    <span className='button'>
                      <Button
                        size='small'
                        className='btn-excel'
                        onClick={this.exportExcel}
                        title='Exportar a excel'
                        icon={<FileExcelOutlined />}
                      ></Button>
                    </span>
                  </Col>
                </Row>
                {listedBills.map((businessGroup, key) => (
                  <Row
                    gutter={[16, 16]}
                    className='bill-table-container'
                    key={
                      key +
                      '-' +
                      selectedInvestor.scFondeador +
                      '-' +
                      businessGroup.info.scGrupo
                    }
                  >
                    <Col span={24}>
                      <Card className='card-shadow card-radius default-border card'>
                        <Row>
                          <Col
                            xl={{ span: 6 }}
                            md={{ span: 6 }}
                            className='client'
                          >
                            {businessGroup.info.dsNombres.toUpperCase()}
                          </Col>
                          <Col
                            xl={{ span: 5 }}
                            md={{ span: 6 }}
                            className='select-label'
                          >
                            Facturas/Notas crédito
                          </Col>
                          <Col
                            xl={{ span: 5 }}
                            md={{ span: 8 }}
                            className='switch-container'
                          >
                            <Switch
                              checkedChildren='Notas crédito'
                              checked={businessGroup.checked}
                              unCheckedChildren='Facturas'
                              onChange={() => this.changeTable(businessGroup)}
                            />
                          </Col>
                          <Col
                            xl={{ span: 8 }}
                            md={{ span: 4 }}
                            className='show-icon-container'
                          >
                            <CaretDownOutlined
                              className={
                                businessGroup.collapsed ? '' : 'caret-open'
                              }
                              onClick={() => this.openTable(businessGroup)}
                            />
                          </Col>
                        </Row>
                        <Divider />
                        <Row
                          gutter={[16, 16]}
                          className={
                            'table-collapse ' + businessGroup.collapsed
                          }
                        >
                          <Col span={24}>
                            {businessGroup.credits.length > 0 ||
                              businessGroup.bills.length > 0 ? (
                              <Table
                                className={
                                  businessGroup.checked ? 'no-check' : ''
                                }
                                rowSelection={{
                                  type: 'checkbox',
                                  onSelectAll: this.onSelectAll,
                                  onSelect: this.onSelect,
                                  selectedRowKeys: selectedKeys,
                                }}
                                pagination={{
                                  defaultPageSize: 10,
                                  showSizeChanger: true,
                                  pageSizeOptions: ['10', '20', '30'],
                                }}
                                columns={
                                  businessGroup.checked
                                    ? this.creditsColumns
                                    : this.billsColumns
                                }
                                dataSource={this.filtrarRegitrosByGrupo(
                                  businessGroup
                                )}
                              ></Table>
                            ) : (
                              <p className='empty-bills'>
                                Las facturas en oferta no cubren el valor de
                                notas crédito
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Row className='footer'>
                          <Col className='text'>Hoy te ofrecemos:</Col>
                          <Col className='value value-offer'>
                            {currencyFormat(
                              this.calcTotalFacturasByGrupo(businessGroup)
                            )}
                          </Col>
                          <Col className='text'>Notas crédito:</Col>
                          <Col className='value value-credit'>
                            {currencyFormat(
                              this.calcTotalNotasByGrupo(businessGroup)
                            )}
                          </Col>
                          <Col className='text text-total'>Total:</Col>
                          <Col className='value value-credit'>
                            {currencyFormat(
                              businessGroup.totalOffer -
                              businessGroup.totalCredit
                            )}
                          </Col>
                          <Col className='text text-total'>Seleccion:</Col>
                          <Col className='value value-credit'>
                            {currencyFormat(this.state.suma)}
                          </Col>
                          <Col className='text text-total'>
                            Cantidad de Facturas:
                          </Col>
                          <Col className='value value-credit'>
                            {this.cantidadDeFacturasByGrupo(businessGroup)}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                ))}
              </div>
            )}
          </Spin>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  company: state.company.company,
  authToken: state.auth.auth.access_token,
});

export default withRouter(connect(mapStateToProps, {})(Program));
