import { Button, Col, DatePicker, Form, Input, Row, Select, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';

import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';
import {requiredValueMessage} from '../../../../../utilities/admin_validation_messages';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  tipoEventosDE:[
    { required: true, message: requiredValueMessage() }
  ],
}

const BillEventsFilterForm = ({
  handleSubmit,
  fondeadorSelect,
  pagadorSelect,
  proveedorSelect,
  grupoEmpresarialSelect,
  onSearchFondeador,
  onSearchPagador,
  onSearchProveedor,
  onSearchGrupoEmpresarial,
  data,
  tieneEventoSelect,
  tiposEventos
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [tieneE, setTieneE] = useState('');
  const [filtroSeleccionado, setFiltroSeleccionado] = useState('Fecha de compra');

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    console.log(data)
    form.setFieldsValue({
      ...data,
      fechaRango: [data.fechaDesdeCompra, data.fechaHastaCompra],
      fechaRangoVenRadian: [data.feDesdeVenRadian, data.feHastaVenRadian],
    });
    forceUpdate({});
  }, [form, data]);

  const onSelectValue = (value) => {
    setTieneE(value)
  };

  const handleSwitchChange = (checked) => {
    setFiltroSeleccionado(checked ? 'Vencimiento Radian' : 'Fecha de compra');
  };

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
      >
        {/* Switch entre "Rango de fechas de compra" y "Vencimiento Radian" */}
        <Row justify='end' gutter={[16, 16]}>
          <Col>
            <Form.Item
              name="Switch" 
            >
              <Switch
                onChange={handleSwitchChange} 
              />
            </Form.Item>
          </Col>
          <Col>
            <p>{filtroSeleccionado}</p>
          </Col>
        </Row>

        {/* Filtro */}
        {filtroSeleccionado === 'Fecha de compra' ? (
          <Row gutter={16}>
            <Col flex='auto'>
              <Form.Item
                name='fechaRango'
                label='Rango de fechas de compra'
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione un rango de fecha',
                  },
                ]}
              >
                <DatePicker.RangePicker format={DATE_FORMAT} />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row gutter={16}>
            <Col flex='auto'>
              { <Form.Item
                name='fechaRangoVenRadian'
                label=' Rango de vencimiento Radian'
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione un rango de fecha',
                  },
                ]}
              >
                <DatePicker.RangePicker format={DATE_FORMAT} />
              </Form.Item> }
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col flex='auto'>
            <Form.Item name='cufe' label='CUFE'>
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scFondeador' label='Fondeador'>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => onSearchFondeador(value)}
                notFoundContent={
                  fondeadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {fondeadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='scGrupo' label='Grupo Empresarial'>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => onSearchGrupoEmpresarial(value)}
                notFoundContent={
                  grupoEmpresarialSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {grupoEmpresarialSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scProveedor' label='Proveedor'>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => onSearchProveedor(value)}
                notFoundContent={
                  proveedorSelect.fetching ? <Spin size='small' /> : null
                }
                required
              >
                {proveedorSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='scPagador' label='Pagador'>
              <Select
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => onSearchPagador(value)}
                notFoundContent={
                  pagadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {pagadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo} value={d.codigo}>
                    {d.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
         
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='cdFactura' label='Nro Factura'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='eventos' label='Eventos'>
            <Select allowClear  onSelect={(value) => onSelectValue(value)}>
                {tieneEventoSelect &&
                  tieneEventoSelect.map((d) => (
                    <Select.Option key={d.codigo} value={d.codigo}>
                      {d.descripcion}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {tieneE === 'ST' || tieneE === 'NT' ? (
          <Row gutter={16}>
          <Col flex={'auto'}>
             <Form.Item
               name='tipoEventosDE' label='EVENTOS' 
               rules={rules['tipoEventosDE']}
             >
               <Select
                 placeholder={SELECT_PLACE_HOLDER}
                 mode='multiple'
                 allowClear
                 style={{ width: '100%' }}
                 filterOption={(input, option) => {
                   return (
                     option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                     0
                   );
                 }}
               >
                 {tiposEventos &&
                   tiposEventos.map((grupo) => (
                     <Select.Option key={grupo.codigo} value={grupo.codigo}>
                       {grupo.descripcion}
                     </Select.Option>
                   ))}
               </Select>
             </Form.Item>
           </Col>
           </Row>  
        ) : ''}

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BillEventsFilterForm;
