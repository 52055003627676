export const range = (value, min, max, name = '') => {
  if (value >= min && value <= max) {
    return Promise.resolve();
  } else {
    return Promise.reject(
      new Error(
        `el valor ${
          name !== '' ? `del ${name}` : ''
        } debe estar entre ${min} y ${max.toLocaleString()}`
      )
    );
  }
};

export const min = (value, min, name = '') => {
  console.log('min', value, min);
  if (value >= min) {
    return Promise.resolve();
  } else {
    return Promise.reject(
      new Error(
        `el valor ${name !== '' ? `del ${name}` : ''} debe ser mayor a ${min}`
      )
    );
  }
};

export const max = (value, max, name = '') => {
  const formattedMax = max.toLocaleString();
  if (value <= max) {
    return Promise.resolve();
  } else {
    return Promise.reject(
      new Error(
        `el valor ${name !== '' ? `del ${name}` : ''} debe ser menor a ${formattedMax}`
      )
    );
  }
};
