import React, { useEffect, useRef, useState } from 'react';
import { InboxOutlined,CheckCircleOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Typography, 
  Upload,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import {useDispatch, useSelector} from "react-redux";
import { sizeFileValidation, typeFileValidation } from '../../../../utilities/validate/fileValidate';
import { actualizarEstadoSolicitud, grabarDocumentosSolicitud, listarDocumentosAnexos } from '../../../../services/proveedor/requestServices';
import history from '../../../../services/history';

const { Title } = Typography;
const mensajecargado = " ya has cargado este archivo anteriormente.";
const ayudaMsj = "Por favor cargue los documentos y guardelos, luego haz clic en el boton enviar solicitud";

export const Documentos = (props) => {

  const [ListDocument, setListDocument] = useState([]);
  const [fileMessageErrors, setFileMessageErrors] = useState([]);
  const [fileCamaraComercio, setFileCamaraComercio] = useState([]);
  const [fileRut, setFileRut] = useState([]);
  const [fileDocumentosRepLeg, setFileDocumentosRepLeg] = useState([]);
  const [fileCuentaBancaria, setFileCuentaBancaria] = useState([]);
  const [fileDeclaracionRenta, setFileDeclaracionRenta] = useState([]);
  const [fileEstadosFinancieros, setFileEstadosFinancieros] = useState([]);
  const [fileComposicionAccionaria, setFileComposicionAccionaria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tipoSolicitud, setTipoSolicitud] = useState('');
  const [scSolicitud, setScSolicitud] = useState('');
  const formRef = useRef(null);



  const token = useSelector(state => state.auth.auth.access_token);
  const scProveedor = useSelector(state => state.billing.company);

  const validateSizeExtfile =  (files,name) => {
    let totalSize = 0;
    const validFileFormats = ['zip','rar','xlsx','xls','pdf','jpeg','png','7zip','doc','docx','ppt','pptx'];
    files && files.forEach(file =>{
      totalSize += file.size;
      if(!validFileFormats.includes(file.name.split('.').pop())){
        throw new Error("El documento: " + name + " no tiene el formato valido('zip','rar','xlsx','xls','pdf','jpeg','png','7zip','doc','docx','ppt','pptx')");
      }
    })
    if(totalSize > 10000000){
      throw new Error("Maximo de tamaño excedido en " + name + "(10MB)");
    }
    
  }

  const validateFormDataSize = (files) => {
    let totalSize = 0;
    Array.from(files.entries(), (file) => {
      file.forEach(input =>{
        if(input.size){totalSize += input.size} 
      })
    });
    if(totalSize > 20000000){
      throw new Error("El peso máximo permitido para todos los archivos es 20MB");
    } 
  }

  const handleOnChangeUploadAccionario = (info) => {
    if(info.file.status === 'removed'){
      setFileComposicionAccionaria([]);
    }else{
      const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
      setFileComposicionAccionaria(fileList);
    }
  };

  const handleOnChangeUploadDecRent = (info) => {
    if(info.file.status === 'removed'){
      setFileDeclaracionRenta([]);
    }else{
      const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
      setFileDeclaracionRenta(fileList);
    }
  };
  
  const handleOnChangeUploadEstadosFin = (info) => {
    if(info.file.status === 'removed'){
      setFileEstadosFinancieros([]);
    }else{
      const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
      setFileEstadosFinancieros(fileList);
    }
  };

  const handleOnChangeUploadBancario = (info) => {
    if(info.file.status === 'removed'){
      setFileCuentaBancaria([]);
    }else{
      const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
      setFileCuentaBancaria(fileList);
    }
  };

  const handleOnChangeUploadCamaraComercio = (info) => {
    if(info.file.status === 'removed'){
      setFileCamaraComercio([]);
    }else{
      const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
      setFileCamaraComercio(fileList);
    }
  };

  const handleOnChangeUploadRut = (info) => {
    if(info.file.status === 'removed'){
      setFileRut([]);
    }else{
      const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
      setFileRut(fileList);
    }
  };

  const handleOnChangeUploadRepLg = (info) => {
    if(info.file.status === 'removed'){
      setFileDocumentosRepLeg([]);
    }else{
      const fileList = info.fileList.length > 1 ? info.fileList : [info.file];
      setFileDocumentosRepLeg(fileList);
    }
  };

  const redirectTomyrequests = () =>{

    history.push(`${process.env.PUBLIC_URL}/myrequests`);

  }

  const handleOnFinish = async () => {

    setLoading(true);

    if(fileCamaraComercio.length > 0 || fileComposicionAccionaria.length > 0 || fileCuentaBancaria.length > 0 
      || fileEstadosFinancieros.length > 0 || fileDeclaracionRenta.length > 0 || fileRut.length > 0 
      || fileDocumentosRepLeg.length > 0){

      try {

        const formData = new FormData();
        validateSizeExtfile(fileComposicionAccionaria,"Composición Accionaria");
        if(fileComposicionAccionaria.length > 1){
          fileComposicionAccionaria.forEach(file=>{
            formData.append('CA', file.originFileObj);
          });
        } else if(fileComposicionAccionaria.length === 1){
          formData.append('CA', fileComposicionAccionaria[0]);
        }

        validateSizeExtfile(fileCuentaBancaria,"Cuenta Bancaria");
        if(fileCuentaBancaria.length > 1){
          fileCuentaBancaria.forEach(file=>{
            formData.append('CCB', file.originFileObj);
          });
        } else if(fileCuentaBancaria.length === 1){
          formData.append('CCB', fileCuentaBancaria[0]);
        }

        validateSizeExtfile(fileCamaraComercio,"Cámara Comercio");
        if(fileCamaraComercio.length > 1){
          fileCamaraComercio.forEach(file=>{
            formData.append('CC', file.originFileObj);
          });
        } else if(fileCamaraComercio.length === 1){
          formData.append('CC', fileCamaraComercio[0]);
        }

        validateSizeExtfile(fileEstadosFinancieros,"Estados Financieros");
        if(fileEstadosFinancieros.length > 1){
          fileEstadosFinancieros.forEach(file=>{
            formData.append('EF', file.originFileObj);
          });
        } else if(fileEstadosFinancieros.length === 1){
          formData.append('EF', fileEstadosFinancieros[0]);
        }

        validateSizeExtfile(fileDeclaracionRenta,"Declaración de Renta");
        if(fileDeclaracionRenta.length > 1){
          fileDeclaracionRenta.forEach(file=>{
            formData.append('DR', file.originFileObj);
          });
        } else if(fileDeclaracionRenta.length === 1){
          formData.append('DR', fileDeclaracionRenta[0]);
        }

        validateSizeExtfile(fileRut,"RUT");
        if(fileRut.length > 1){
          fileRut.forEach(file=>{
            formData.append('RUT', file.originFileObj);
          });
        } else if(fileRut.length === 1){
          formData.append('RUT', fileRut[0]);
        }

        validateSizeExtfile(fileDocumentosRepLeg,"Cedula Rep Legal");
        if(fileDocumentosRepLeg.length > 1){
          fileDocumentosRepLeg.forEach(file=>{
            formData.append('CCRL', file.originFileObj);
          });
        } else if(fileDocumentosRepLeg.length === 1){
          formData.append('CCRL', fileDocumentosRepLeg[0]);
        }

        
        validateFormDataSize(formData);   
        const response = await grabarDocumentosSolicitud(token, formData, scSolicitud);
        notification.success({
          message: 'Guardado',
          description: 'Documentos Cargados con Éxito',
          duration: 5,
        });

        
        setFileComposicionAccionaria([]);
        setFileCuentaBancaria([]);
        setFileCamaraComercio([]);
        setFileRut([]);
        setFileDocumentosRepLeg([]);
        setFileDeclaracionRenta([]);
        setFileEstadosFinancieros([]);

        getListDocuments(scSolicitud);
        

      }
      catch(error){
          notification.error({
            message: 'Error',
            description: error.message,
            duration: 5,
          })
      }
      finally {
          setLoading(false);
      }
            
  }else{
    setLoading(false);
    notification.warn({
      message: 'Acción no permitida',
      description: "No hay archivos precargados para guardar",
      duration: 4,
    })
  }
  };

  const handleEnviarSolicitud = async () =>{

    let data = formRef.current.getFieldsValue();
    let formCant = Object.keys(data).length;
    
    if(!(formCant === ListDocument.length)){
      notification.error({
        message: 'Error',
        description: "Por favor cargue todos los documentos solicitados y guarde",
        duration: 4,
      })

      return;
    }

    try {
      setLoading(true);
      let data = {
        cdEstado : 'E'
      }
      await actualizarEstadoSolicitud(token,scSolicitud,data)
      notification.success({
        message: 'Enviado',
        description: 'Solicitud Enviada con Éxito',
        duration: 5,
      });
      redirectTomyrequests();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: "Error enviando solicitud: " + error,
        duration: 4,
      })
    }finally{
      setLoading(false);
    }

  }

  const getListDocuments = async (scSolicitud) => {
    try {
      setLoading(true);
      const res = await listarDocumentosAnexos(token,scSolicitud);
      setListDocument(res.data);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: "Error Listando documentos" + error,
        duration: 4,
      })
    }finally{
      setLoading(false);
    }
  }

  useEffect(() => {

    setTipoSolicitud(props.tipoSolicitud);
    if(scProveedor){
      if(props.scSolicitud){
        setScSolicitud(props.scSolicitud);
        getListDocuments(props.scSolicitud);
      }
    }
  }, [props.tipoSolicitud,scProveedor])
  
  return (
  <Spin tip='Cargando...' spinning={loading}>
  <Form
      name='content_form'
      layout='vertical'
      ref={formRef}

  >
    <h3><b>{ayudaMsj}</b></h3>
    <br/>
      {/* Camara de comercio*/}
      <Row gutter={[16, 32]}>
          <Col flex='auto'>
              <Title level={4}>CÁMARA DE COMERCIO</Title>
              <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}}>Este no debe ser mayor a 30 días</b></h5></h3>
              {ListDocument.map((value) => (
                value.tipodoc === 'CC'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
              ))}
              <Form.Item 
                noStyle
                name='CC'>
                <br/>
              <Upload
                  beforeUpload={() => {
                  return false;
                  }}
                  fileList={fileCamaraComercio}
                  onChange={handleOnChangeUploadCamaraComercio}
                  maxCount={5}
                  multiple
              >
                <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
              </Upload>
              {fileMessageErrors.map((value) => (
                  <Text type='danger'>{value}</Text>
              ))}
              </Form.Item>
          </Col>
          {/* RUT*/}
          <Col span={12}>
              <Title level={4}>RUT</Title>
              <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}} >RUT Actualizado</b></h5></h3>
              {ListDocument.map((value) => (
                value.tipodoc === 'RUT'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
              ))}
              <Form.Item 
                noStyle
                name='RUT'>
                  <br/>
              <Upload
                  beforeUpload={() => {
                  return false;
                  }}
                  fileList={fileRut}
                  onChange={handleOnChangeUploadRut}
                  maxCount={5}
                  multiple
              >
                <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
              </Upload>
              {fileMessageErrors.map((value) => (
                  <Text type='danger'>{value}</Text>
              ))}
              </Form.Item>
          </Col>
      </Row>
      <Divider />

      {/*COPIA DEL DOCUMENTO DE INDENTIDAD DEL REPRESENTANTE LEGAL*/}
      <Row gutter={[16, 32]}>
          <Col flex='auto'>
              <Title level={4}>COPIA DE LA CÉDULA</Title>
              <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}} >Copia de la cédula del representante legal</b></h5></h3>
              {ListDocument.map((value) => (
                value.tipodoc === 'CCRL'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
              ))}
              <Form.Item 
                noStyle
                name='CCRL'>
                  <br/>
              <Upload
                  beforeUpload={() => {
                  return false;
                  }}
                  fileList={fileDocumentosRepLeg}
                  onChange={handleOnChangeUploadRepLg}
                  maxCount={5}
                  multiple
              >
                <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
              </Upload>
              {fileMessageErrors.map((value) => (
                  <Text type='danger'>{value}</Text>
              ))}
              </Form.Item>
          </Col>
      {/* Certificado Cuenta Bancaria*/}
            <Col span={12}>
              <Title level={4}>CERTIFICADO CUENTA BANCARIA</Title>
              <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}}>Este no debe ser mayor a 30 días</b></h5></h3>
              {ListDocument.map((value) => (
                value.tipodoc === 'CCB'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
              ))}
              <Form.Item 
                noStyle
                name='CCB'>
                <br/>
              <Upload
                  beforeUpload={() => {
                  return false;
                  }}
                  fileList={fileCuentaBancaria}
                  onChange={handleOnChangeUploadBancario}
                  maxCount={5}
                  multiple
              >
                <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
              </Upload>
              {fileMessageErrors.map((value) => (
                  <Text type='danger'>{value}</Text>
              ))}
              </Form.Item>
          </Col>
      </Row>
      { tipoSolicitud === 'FA' || tipoSolicitud === 'CCR' || tipoSolicitud === 'RC' ?
      <Row gutter={[16, 32]}>
        <Divider />
        <Col flex='auto'>
            <Title level={4}>DECLARACIÓN DE RENTA</Title>
            <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}} >Último cierre fiscal disponible</b></h5></h3>
            {ListDocument.map((value) => (
              value.tipodoc === 'DR'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
            ))}
            <Form.Item 
              noStyle
              name='DR'>
                <br/>
            <Upload
                beforeUpload={() => {
                return false;
                }}
                fileList={fileDeclaracionRenta}
                onChange={handleOnChangeUploadDecRent}
                maxCount={5}
                multiple
            >
              <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
            </Upload>
            {fileMessageErrors.map((value) => (
                <Text type='danger'>{value}</Text>
            ))}
            </Form.Item>
        </Col>                      
        <Col span={12}>
            <Title level={4}>ESTADOS FINANCIEROS</Title>
            <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}} >Estados financieros del cierre del año anterior y último corte de año en curso</b></h5></h3>
            {ListDocument.map((value) => (
              value.tipodoc === 'EF'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
            ))}
            <Form.Item 
              noStyle
              name='EF'>
                <br/>
            <Upload
                beforeUpload={() => {
                return false;
                }}
                fileList={fileEstadosFinancieros}
                onChange={handleOnChangeUploadEstadosFin}
                maxCount={5}
                multiple
            >
              <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
            </Upload>
            {fileMessageErrors.map((value) => (
                <Text type='danger'>{value}</Text>
            ))}
            </Form.Item>
        </Col>            
        {/* Composicion Accionaria */}
        {
        /*<Col flex='auto'>
              <Title level={4}>COMPOSICIÓN ACCIONARIA</Title>
              <h3>Requisitos: <h5 style={{fontWeight:'bold'}}><b style={{color:'green'}}>La sumatoria de los accionistas debe ser del 100%</b></h5></h3>
              {ListDocument.map((value) => (
                value.tipodoc === 'CA'  ? <Text type='secondary'><CheckCircleOutlined color='secondary'/><b style={{color:'gray'}} >{mensajecargado}</b></Text> : ''
              ))}
              <Form.Item 
                noStyle
                name='CA'>
                <br/>
                <Upload
                    beforeUpload={() => {
                    return false;
                    }}
                    fileList={fileComposicionAccionaria}
                    onChange={handleOnChangeUploadAccionario}
                    maxCount={5}
                    multiple
                >
                    <Button icon={<UploadOutlined />}>Clic Para Subir Archivo</Button>
                </Upload>
                {fileMessageErrors.map((value) => (
                    <Text type='danger'>{value}</Text>
                ))}
              </Form.Item>
                </Col> : null*/ }
    </Row> : null }
    <Divider />
      {/* Boton Guardar */}
      <Row align='middle' justify='center' gutter={16}>
          <Col span={6}>
              <Button
                  style={{ width: '90%' }}
                  className='btn btn-green'
                  type='primary'
                  onClick={handleOnFinish}
              >
                  GUARDAR DOCUMENTOS
              </Button>
          </Col>
          <Col span={6}>
              <Button
                  style={{ width: '100%' }}
                  className='btn btn-blue'
                  type='primary'
                  onClick={handleEnviarSolicitud}
              >
                  ENVIAR SOLICITUD
              </Button>
          </Col>
      </Row>    
  </Form>   
  </Spin>
  )
}


