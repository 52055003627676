import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Card,
  Checkbox,
} from 'antd';
import {
  FileExcelOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileOutlined,
} from '@ant-design/icons';
import {
  listarFondeadores,
  listarConvenios,
  operacionProveedores,
  operacionProveedoresExcel,
  operacionConvenios,
  operacionConveniosExcel,
} from '../../../../../services/admin/reports/operations';
import '../reports.scss';
import { connect } from 'react-redux';
import { REPORTS_DATES_REQUIRED_ERROR } from '../../../../../utilities/messages';
import Total from '../../../../proveedor/reports/total';

const { Option } = Select;

let intColumnas = 1;

const initialState = {
  scFondeadorOptions: [],
  scConvenioOptions: [],
  loading: false,
  convenios: {
    currentPage: 1,
    total: 0,
    limit: 0,
    offset: 0,
    resultado: [],
    fechaHasta: '',
    scFondeador: '',
    scConvenio: '',
  },
  proveedor: {
    currentPage: 1,
    total: 0,
    limit: 0,
    offset: 0,
    resultado: [],
    scFondeador: '',
    scConvenio: '',
    fechaDesde: '',
    fechaHasta: '',
  },
  fetching: false,
  sumatorias: {},
};
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.formRef = React.createRef();
  }

  sorter = (a, b) =>
    isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b;

  columnas = {
    proveedor: [
      {
        title: 'TIPO DE CONVENIO',
        dataIndex: 'dsTipoConvenio',
        sorter: (a, b) => this.sorter(a.cdTipoConvenio, b.cdTipoConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE DE CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => this.sorter(a.nombreConvenio, b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => this.sorter(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'TASA DESC. AL PROVEEDOR',
        dataIndex: 'poDescuentoProveedor',
        sorter: (a, b) => a.poDescuentoProveedor - b.poDescuentoProveedor,
        showSorterTooltip: false,
      },
      {
        title: 'VAL. FACT. DESEMBOLSO',
        dataIndex: 'vrFacturaDesembolso',
        sorter: (a, b) => a.vrFacturaDesembolso - b.vrFacturaDesembolso,
        render: (value) => <span>$ {this.formatNumber(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'DESEMBOLSO',
        dataIndex: 'vrDesembolso',
        sorter: (a, b) => a.vrDesembolso - b.vrDesembolso,
        render: (value) => <span>$ {this.formatNumber(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'BENEFICIO PAGADOR',
        dataIndex: 'vrBenefPagador',
        sorter: (a, b) => a.vrBenefPagador - b.vrBenefPagador,
        render: (value) => <span>$ {this.formatNumber(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'BENEFICIO EXPONENCIAL',
        dataIndex: 'vrBenefExp',
        sorter: (a, b) => a.vrBenefExp - b.vrBenefExp,
        render: (value) => <span>$ {this.formatNumber(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'AVG TASA BEN. PAGADOR',
        dataIndex: 'avgPOBeneficioPagReal',
        sorter: (a, b) => a.avgPOBeneficioPagReal - b.avgPOBeneficioPagReal,
        showSorterTooltip: false,
      },
      {
        title: 'AVG TASA BEN. PROVEEDOR',
        dataIndex: 'avgPODescuentoProveedor',
        sorter: (a, b) => a.avgPODescuentoProveedor - b.avgPODescuentoProveedor,
        showSorterTooltip: false,
      },
    ],
    convenios: [
      {
        title: 'TIPO DE CONVENIO',
        dataIndex: 'dsTipoConvenio',
        sorter: (a, b) => this.sorter(a.dsTipoConvenio, b.dsTipoConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => this.sorter(a.nombreConvenio, b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO CONVENIO',
        dataIndex: 'dsEstadoRegistroConvenio',
        sorter: (a, b) =>
          this.sorter(a.dsEstadoRegistroConvenio, b.dsEstadoRegistroConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => this.sorter(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO PROVEEDOR',
        dataIndex: 'dsEstadoRegistroConvProv',
        sorter: (a, b) =>
          this.sorter(a.dsEstadoRegistroConvProv, b.dsEstadoRegistroConvProv),
        showSorterTooltip: false,
      },
      {
        title: 'CATEGORÍA',
        dataIndex: 'dsCategoria',
        sorter: (a, b) => this.sorter(a.dsCategoria, b.dsCategoria),
        showSorterTooltip: false,
      },
    ],
  };

  formulario = {
    proveedor: [
      [
        {
          nombre: 'fechaDesde',
          label: '* DESDE',
          tipo: 'date',
          required: true,
        },
        {
          nombre: 'fechaHasta',
          label: '* HASTA',
          tipo: 'date',
          required: true,
        },
      ],
      [
        {
          nombre: 'scFondeador',
          label: 'FONDEADOR',
          tipo: 'select',
          selectType: 'scFondeadorOptions',
        },
        {
          nombre: 'scConvenio',
          label: 'NOMBRE DE CONVENIO',
          tipo: 'select',
          selectType: 'scConvenioOptions',
        },
      ],
    ],
    convenios: [
      [
        {
          nombre: 'fechaHasta',
          label: '* FECHA HASTA',
          tipo: 'date',
          required: true,
        },
        {},
      ],
      [
        {
          nombre: 'scFondeador',
          label: 'FONDEADOR',
          tipo: 'select',
          selectType: 'scFondeadorOptions',
        },
        {
          nombre: 'scConvenio',
          label: 'NOMBRE DE CONVENIO',
          tipo: 'select',
          selectType: 'scConvenioOptions',
        },
      ],
    ],
  };

  isBackButtonClicked = false;

  formRender = () => {
    const { type } = this.props;
    let formulario = '';
    return (formulario = (
      <Form
        name='content_form'
        ref={this.formRef}
        layout='vertical'
        className='filters-container'
      >
        {this.formulario[type].map((fila, key) => {
          if (fila.length === 1) {
            return (
              <Row key={key} gutter={16}>
                <Col span={24}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={fila[1].nombre} label={fila[1].label}>
                    {this.inputType(fila[1])}
                  </Form.Item>
                </Col>
              </Row>
            );
          }
        })}
        <Row justify='left' gutter={16}>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                className='form-btn btn-radius'
                type='primary'
                onClick={() => this.getData()}
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                className='form-btn secundary btn-radius'
                type='primary'
                onClick={() => this.clearFields()}
              >
                Limpiar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ));
  };

  clearFields = () => {
    const { type } = this.props;
    this.setState({
      ...this.state,
      [this.props.type]: {
        ...initialState[this.props.type],
      },
    });
    if (type === 'proveedor') {
      this.formRef.current.setFieldsValue({
        fechaDesde: '',
        fechaHasta: '',
        scFondeador: '',
        scConvenio: '',
      });
    } else {
      this.formRef.current.setFieldsValue({
        fechaHasta: '',
        scFondeador: '',
        scConvenio: '',
      });
    }
  };

  inputType = (campo) => {
    const { type } = this.props;
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(e) => this.handleTextChange(e, stateName, type)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName, type)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key} value={d.codigo}>
                {d.nombre ? d.nombre : d.descripcion}
              </Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  };

  handleOnChange = (pagination, filters, sorter, extra) => {
    if (this.state.currentPage != pagination.current) {
      this.state[this.props.type].offset =
        pagination.pageSize * (pagination.current - 1);
      this.state[this.props.type].currentPage = pagination.current;
    }

    if ((this.state[this.props.type].limit || 10) != pagination.pageSize) {
      this.state[this.props.type].limit = pagination.pageSize;
      this.state[this.props.type].offset = 0;
      this.state[this.props.type].currentPage = '1';
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.state[this.props.type].sort =
        order == 'descend' ? `-${field}` : field;
    }
    this.getData();
  };

  handleTextChange = (e, campo, type) => {
    this.state[type][campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo, type) => {
    this.state[type][campo] = moment;
  };

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      switch (nombre) {
        case 'scConvenio':
          let filters;
          if (this.state[this.props.type].scFondeador) {
            filters = {
              nombre: value,
              scFondeador: this.state[this.props.type].scFondeador,
            };
          }
          listarConvenios(this.props.authToken, filters).then((response) => {
            console.log(response.data);
            if (Array.isArray(response.data)) {
              this.setState({
                ...this.state,
                scConvenioOptions: response.data.map((item) => {
                  return {
                    key: item.codigo,
                    nombre: item.descripcion,
                  };
                }),
                fetching: false,
              });
            }
          });
          break;
        case 'scFondeador':
          listarFondeadores(this.props.authToken, { nombre: value }).then(
            (response) => {
              if (Array.isArray(response.data)) {
                this.setState({
                  ...this.state,
                  scFondeadorOptions: response.data.map((item) => {
                    return {
                      key: item.codigo,
                      nombre: item.descripcion,
                    };
                  }),
                  fetching: false,
                });
              }
            }
          );
          break;
      }
    } else {
      switch (nombre) {
        case 'scConvenio':
          this.setState({
            ...this.state,
            scConvenioOptions: [],
          });
          break;
        case 'scFondeador':
          this.setState({
            ...this.state,
            scFondeadorOptions: [],
          });
          break;
      }
    }
  };

  handleSelectChange = (value, stateName) => {
    this.setState({
      ...this.state,
      [this.props.type]: {
        ...this.state[this.props.type],
        [stateName]: value,
      },
    });
  };

  getData = () => {
    switch (this.props.type) {
      case 'proveedor':
        if (this.validateForm()) {
          if (!this.state.proveedor.scFondeador) {
            return notification.error({
              message: 'Campo obligatorio',
              description:
                'El fondeador es obligatorio para el uso de este reporte',
              duration: 4,
            });
          }
          this.setState({
            ...this.state,
            loading: true,
          });
          operacionProveedores(
            this.props.authToken,
            this.state.proveedor.scFondeador,
            this.state[this.props.type]
          ).then((response) => {
            this.setResponse(response);
          });
        }
        break;
      case 'convenios':
        if (this.validateForm()) {
          if (!this.state.convenios.scFondeador) {
            return notification.error({
              message: 'Campo obligatorio',
              description:
                'El fondeador es obligatorio para el uso de este reporte',
              duration: 4,
            });
          }
          this.setState({
            ...this.state,
            loading: true,
          });
          operacionConvenios(
            this.props.authToken,
            this.state.convenios.scFondeador,
            this.state[this.props.type]
          ).then((response) => {
            this.setResponse(response);
          });
        }
        break;
    }
  };

  exportExcel = () => {
    const { type } = this.props;
    switch (type) {
      case 'proveedor':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          operacionProveedoresExcel(
            this.props.authToken,
            this.state[type].scFondeador,
            this.state[type]
          ).then((response) => {
            this.setExcel(response);
          });
        }
        break;
      case 'convenios':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          operacionConveniosExcel(
            this.props.authToken,
            this.state[type].scFondeador,
            this.state[type]
          ).then((response) => {
            this.setExcel(response);
          });
        }
        break;
    }
  };

  setExcel = (response) => {
    const type = this.props.type;
    const url = window.URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ` Reporte ${type}.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.setState({
      ...this.state,
      loading: false,
    });
  };

  setResponse = (response) => {
    this.setState({
      ...this.state,
      [this.props.type]: {
        ...this.state[this.props.type],
        resultado: response.data,
        total: response.metadata.count,
      },
      loading: false,
      sumatorias: response.metadata.sumatorias,
    });
  };

  validateForm = () => {
    let filtros = this.state[this.props.type];
    let validationResult = true;
    let formulario = this.formulario;
    let nombres = [];
    let errores = [];
    Object.keys(filtros).forEach((nombre) => {
      if (
        formulario[this.props.type].find((x) =>
          x.find((y) => y.nombre === nombre && y.required === true)
        )
      ) {
        if (filtros[nombre] === '' || filtros[nombre] === null) {
          nombres.push(nombre);
          validationResult = false;
        }
      }
    });
    if (!validationResult) {
      formulario[this.props.type].forEach((formItem) => {
        formItem.forEach((key) => {
          if (nombres.includes(key.nombre)) {
            errores.push(key.label.replace('* ', ''));
          }
        });
      });
      if (errores.length > 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar los valores de los campos ${errores.reduce(
            (error, acum) => `${acum}, ${error}`
          )} para consultar este reporte`,
          duration: 4,
        });
      }
      if (errores.length == 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar el valor del campo ${errores[0]} para consultar este reporte`,
          duration: 4,
        });
      }
    }
    return validationResult;
  };

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  render() {
    const { type } = this.props;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <div className='header-container'>
            <p className='title'>Búsqueda</p>
            {this.formRender()}
          </div>
          <div className='table-container'>
            <Row gutter={30} className='bill-selection' justify='end'>
              <Col xl={{ span: 24 / intColumnas }} className='export'>
                <span className='button'>
                  <Button
                    size='medium'
                    className='btn-excel'
                    title='Exportar a excel'
                    onClick={this.exportExcel}
                    icon={<FileExcelOutlined />}
                  ></Button>
                </span>
              </Col>
            </Row>
            <Divider />
            <Table
              pagination={{
                defaultPageSize: 10,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} registros`,
                current: this.state[this.props.type].currentPage,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30'],
                total: this.state[type].total,
              }}
              onChange={this.handleOnChange}
              columns={this.columnas[type]}
              footer={() => <Total sumatorias={this.state.sumatorias} />}
              dataSource={this.state[type].resultado}
            ></Table>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Content);
