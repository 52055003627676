import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { currencyFormat, sorterText } from '../../../../utilities/textTools';
import TableReport from '../../../../components/tableReport/tableReport';
import OffersForm from './offersForm/offersForm';
import {
  listarProveedores,
  oferta,
  ofertaExcel,
} from '../../../../services/pagador/reportServices';
import history from '../../../../services/history';
import { setExcel } from '../../../../utilities/downloadTools';

const initialState = {
  resetForm: false,
  form: {
    scProveedor: '',
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 3,
      type: 'text',
    },
    {
      id: 'totalVrCantidadOfertas',
      value: 0,
      index: 'cantidadOfertas',
      colSpan: 1,
      type: 'number',
    },
    {
      id: 'totalVrOfertas',
      value: currencyFormat(0),
      index: 'totalOfertas',
      colSpan: 1,
      type: 'current',
    },
    {
      id: 'totalVrDescontado',
      value: currencyFormat(0),
      index: 'totalDescontado',
      colSpan: 1,
      type: 'current',
    },
    {
      id: 'totalVrPendiente',
      value: currencyFormat(0),
      index: 'totalPendiente',
      colSpan: 2,
      type: 'current',
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NIT',
        dataIndex: 'idProveedor',
        sorter: (a, b) => sorterText(a.idProveedor, b.idProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'razonSocialProveedor',
        sorter: (a, b) =>
          sorterText(a.razonSocialProveedor, b.razonSocialProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'TÉLEFONO',
        dataIndex: 'telefono',
        sorter: (a, b) => sorterText(a.telefonoProveedor, b.telefonoProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'OFERTAS',
        dataIndex: 'cantidadOfertas',
        sorter: (a, b) => a.cantidadOfertas - b.cantidadOfertas,
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL OFERTAS',
        dataIndex: 'totalOfertas',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.totalOfertas - b.totalOfertas,
        showSorterTooltip: false,
      },

      {
        title: 'TOTAL DESCONTADO',
        dataIndex: 'totalDescontado',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.totalDescontado - b.totalDescontado,
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL PENDIENTE',
        dataIndex: 'totalPendiente',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.totalPendiente - b.totalPendiente,
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: 'scProveedor',
        render: (text, record) => (
          <Button
            key={text}
            className='form-btn btn-radius btn-details'
            type='primary'
            htmlType='button'
            onClick={() =>
              this.goToDetails(record.scProveedor, this.props.company.company)
            }
          >
            DETALLE
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  goToDetails(scProveedor, scPagador) {
    history.push(
      `${process.env.PUBLIC_URL}/reports/bills/${btoa(scPagador)}/${btoa(
        scProveedor
      )}`
    );
  }

  handleOnSearchProveedor = (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      listarProveedores(this.props.authToken, this.props.company.company, {
        nombre: nombre,
      }).then((response) => {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      });
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scProveedor: value.scProveedor,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      const response = await oferta(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );

      const resumen = this.state.resumen.map((res) => {
        if (response.metadata.sumatorias[res.id] !== undefined) {
          const valor = response.metadata.sumatorias[res.id];
          let text = '';
          switch (res.type) {
            case 'current':
              text = (
                <span className='format-number'>{currencyFormat(valor)}</span>
              );
              break;
            default:
              text = (
                <Row justify='center'>
                  <Col>
                    <span className='format-number'>{valor}</span>;
                  </Col>
                </Row>
              );
          }
          res.value = text;
        }
        return res;
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        resumen: resumen,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await ofertaExcel(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );
      setExcel(response, `ofertas`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleOnReset = () => {
    this.setState({
      ...this.state,
      resetForm: false,
      form: {
        ...initialState.form,
      },
    });
  };

  componentDidMount() {
    if (typeof this.props.company.company !== 'object') {
      this.searchData();
    }
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company != props.company.company) {
      this.setState({
        ...initialState,
        resetForm: true,
        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'tipoConvenio',
            colSpan: 3,
            type: 'text',
          },
          {
            id: 'totalVrCantidadOfertas',
            value: 0,
            index: 'cantidadOfertas',
            colSpan: 1,
            type: 'number',
          },
          {
            id: 'totalVrOfertas',
            value: currencyFormat(0),
            index: 'totalOfertas',
            colSpan: 1,
            type: 'current',
          },
          {
            id: 'totalVrDescontado',
            value: currencyFormat(0),
            index: 'totalDescontado',
            colSpan: 1,
            type: 'current',
          },
          {
            id: 'totalVrPendiente',
            value: currencyFormat(0),
            index: 'totalPendiente',
            colSpan: 2,
            type: 'current',
          },
        ],
      });

      this.searchData();
    }
  }

  render() {
    const { proveedorSelect, loading, resetForm } = this.state;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <OffersForm
            resetForms={resetForm}
            proveedorSelect={proveedorSelect}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleSubmit={this.handleSubmit}
            handleOnReset={this.handleOnReset}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={this.state.currentPage}
              limit={this.state.limit}
              total={this.state.total}
              resumen={this.state.resumen}
              columns={this.columnas}
              data={this.state.resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default Offers;
