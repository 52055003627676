import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import {
  DownloadOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileOutlined,
} from '@ant-design/icons';
import {
  currencyFormat,
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import {
  documentoTextoOpcionesPagadas,
  listarConvenios,
  listarFacturasPagadas,
  listarFacturasPagadasExcel,
  listarOpcionesDocumentosPagadas,
  listarPagadores,
  listarProveedores,
} from '../../../../services/fondeador/reportServices';
import TableReport from '../../../../components/tableReport/tableReport';
import { setExcel, setExport } from '../../../../utilities/downloadTools';
import PaidBillsFiltersForm from './PaidBillsFiltersForm/PaidBillsFiltersForm';

const initialState = {
  form: {
    cdFactura: '',
    scConvenio: '',
    scPagador: '',
    scProveedor: '',
    fechaPago: '',
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 4,
    },
    {
      id: 'sumaVrFactura',
      value: currencyFormat(0),
      index: 'vrFactura',
      colSpan: 1,
    },
    {
      id: 'sumaVrFacturaBase',
      value: currencyFormat(0),
      index: 'vrFacturaBase',
      colSpan: 1,
    },
    {
      id: 'sumaVrOferta',
      value: currencyFormat(0),
      index: 'vrFacturaBase',
      colSpan: 7,
    },
    {
      id: 'sumaVrCostoTransaccion',
      value: currencyFormat(0),
      index: 'vrCostoTransaccion',
      colSpan: 1,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  buttonOptions: [],
  intColumn: 1,
};

class PaidBills extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'REF. DOC. CONTABLE',
        dataIndex: 'cdDocumentoSAP',
        sorter: (a, b) => sorterText(a.cdDocumentoSAP, b.cdDocumentoSAP),
        showSorterTooltip: false,
      },
      {
        title: 'CÓDIGO FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'PLAZO PARA PAGO',
        dataIndex: 'numPlazoPago',
        sorter: (a, b) => a.numPlazoPago - b.numPlazoPago,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REPORTADO',
        dataIndex: 'vrFactura',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR BASE FACTURA',
        dataIndex: 'vrFacturaBase',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR OFERTA',
        dataIndex: 'vrOferta',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrOferta - b.vrOferta,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REDUCIDO',
        dataIndex: 'vrFacturaChReducido',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFacturaChReducido - b.vrFacturaChReducido,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR NOTAS CRÉDITO',
        dataIndex: 'vrTotalNotas',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrTotalNotas - b.vrTotalNotas,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA EMISIÓN',
        dataIndex: 'feEmision',
        sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ESPERADA PAGO',
        dataIndex: 'feEstimadaPago',
        sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'TASA MV',
        dataIndex: 'poDescuentoProveedorMV',
        render: (value) => <span>{percentageFormat(value)}</span>,
        sorter: (a, b) => a.poDescuentoProveedorMV - b.poDescuentoProveedorMV,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR TRANSACCIÓN',
        dataIndex: 'vrCostoTransaccion',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrCostoTransaccion - b.vrCostoTransaccion,
        showSorterTooltip: false,
      },
    ];
  }

  getDocumentOption = async () => {
    if (this.props.company.company !== 0) {
      const response = await listarOpcionesDocumentosPagadas(
        this.props.authToken,
        this.props.company.company
      );

      this.setState({
        ...this.state,
        buttonOptions: response.data,
        intColumn: 1 + response.data.length * 4,
      });
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });
      try {
        const response = await listarConvenios(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  searchData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      const response = await listarFacturasPagadas(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );

      const resumen = this.state.resumen.map((res) => {
        if (response.metadata.sumatorias[res.id] !== undefined) {
          res.value = (
            <span className='format-number'>
              {currencyFormat(response.metadata.sumatorias[res.id])}
            </span>
          );
        }

        return res;
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        resumen: resumen,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarFacturasPagadasExcel(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );
      setExcel(response, `Facturas_pagadas`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportText = async (codigoOpcion, descripcionOpcion) => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const filtro = this.getFiltro();
        const response = await documentoTextoOpcionesPagadas(
          this.props.authToken,
          this.props.company.company,
          { ...filtro, codigoOpcion: codigoOpcion }
        );

        if (response.status === false) {
          return;
        }

        setExport(response, descripcionOpcion);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  documentOptionsRender = () => {
    let opciones = [];
    const { buttonOptions, intColumn } = this.state;

    buttonOptions.forEach((options) => {
      switch (options.type) {
        case 'TXT':
          opciones = [
            opciones,
            <>
              <Col
                lg={{ span: 24 / intColumn }}
                xl={{ span: 24 / intColumn }}
                className='export'
              >
                <span className='button'>
                  <Button
                    size='medium'
                    className='btn-text'
                    type='primary'
                    title={options.descripcion}
                    onClick={() =>
                      this.exportText(options.codigo, options.descripcion)
                    }
                    icon={<FileTextOutlined />}
                  ></Button>
                </span>
              </Col>
            </>,
          ];
          break;
        case 'PDF':
          opciones = [
            opciones,
            <>
              <Col
                lg={{ span: 24 / intColumn }}
                xl={{ span: 24 / intColumn }}
                className='export'
              >
                <span className='button'>
                  <Button
                    size='medium'
                    className='btn-pdf'
                    type='primary'
                    title={options.descripcion}
                    onClick={() =>
                      this.exportText(options.codigo, options.descripcion)
                    }
                    icon={<FilePdfOutlined />}
                  ></Button>
                </span>
              </Col>
            </>,
          ];
          break;
        default:
          opciones = [
            opciones,
            <>
              <Col
                lg={{ span: 24 / intColumn }}
                xl={{ span: 24 / intColumn }}
                className='export'
              >
                <span className='button'>
                  <Button
                    size='medium'
                    className='btn-default'
                    type='primary'
                    title={options.descripcion}
                    onClick={() =>
                      this.exportText(
                        options.codigo,
                        options.descripcion,
                        options.type
                      )
                    }
                    icon={<FileOutlined />}
                  ></Button>
                </span>
              </Col>
            </>,
          ];
          break;
      }
    });

    return opciones;
  };

  componentDidMount() {
    this.getDocumentOption();
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company != props.company.company) {
      this.setState({
        ...initialState,
        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'tipoConvenio',
            colSpan: 4,
          },
          {
            id: 'sumaVrFactura',
            value: currencyFormat(0),
            index: 'vrFactura',
            colSpan: 1,
          },
          {
            id: 'sumaVrFacturaBase',
            value: currencyFormat(0),
            index: 'vrFacturaBase',
            colSpan: 1,
          },
          {
            id: 'sumaVrOferta',
            value: currencyFormat(0),
            index: 'vrFacturaBase',
            colSpan: 7,
          },
          {
            id: 'sumaVrCostoTransaccion',
            value: currencyFormat(0),
            index: 'vrCostoTransaccion',
            colSpan: 1,
          },
        ],
      });

      this.getDocumentOption();
    }
  }

  render() {
    const {
      pagadorSelect,
      proveedorSelect,
      convenioSelect,
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      intColumn,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <PaidBillsFiltersForm
            companyId={this.props.company.company}
            proveedorSelect={proveedorSelect}
            pagadorSelect={pagadorSelect}
            convenioSelect={convenioSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                {this.documentOptionsRender()}
                <Col
                  lg={{ span: 24 / intColumn }}
                  xl={{ span: 24 / intColumn }}
                  className='export'
                >
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default PaidBills;
