import { FileExcelOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Spin,
  Tooltip,
  notification,
} from 'antd';
import equals from 'fast-deep-equal';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  listarPagadores,
  listarProveedores,
} from '../../../../services/fondeador/reportServices';
import {
  exportarExcelFacturasCartera,
  listarCartera,
  listarEstadoObligacion,
  listarHistoricoAbonos,
  listarTiposConvenios,
} from '../../../../services/fondeador/walletService';
import { DATE_FORMAT } from '../../../../utilities/messages';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import './listWallet.scss';
import ListWalletModal from './listWalletModal/listWalletModal';

let intColumnas = 1;
const SELECT_PLACE_HOLDER = 'Seleccionar...';
const { Option } = Select;
const initialState = {
  visibleCheckPaymentModal: false,
  factura: {},
  abonos: [],
  loading: false,
  currentPage: 1,
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'cdFactura',
      colSpan: 3,
    },
    {
      id: 'totalVrPendiente',
      value: currencyFormat(0),
      index: 'vrFactura',
      colSpan: 3,
    },
    {
      id: 'totalVrSaldo',
      value: currencyFormat(0),
      index: 'saldo',
      colSpan: 2,
    },
  ],
  resultado: [],
  total: 0,
  sumatorias: {},
  filtros: {
    feliquidacion: moment(),
    scPagador: null,
    scProveedor: null,
    tipoConvenio: 'factoring',
    estado: null,
    limit: 10,
    offset: 0,
    sort: '',
    fechaRango: [moment().startOf('month'), moment(new Date())],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  tipoConvenioOptions: [],
  estadoOptions: [],
  carteraTotal: 0,
  carteraVencida: 0,
};

class listWallet extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.formRef = React.createRef();
  }

  formulario = [
    [
      {
        nombre: 'scPagador',
        label: 'Pagador',
        tipo: 'select',
        selectType: 'pagadorSelect',
      },
    ],
    [
      {
        nombre: 'scProveedor',
        label: 'Proveedor',
        tipo: 'select',
        selectType: 'proveedorSelect',
      },
      {
        nombre: 'tipoConvenio',
        label: 'Tipo de Producto',
        tipo: 'select',
        selectType: 'tipoConvenioOptions',
      },
    ],
    [
      {
        nombre: 'feliquidacion',
        label: 'Fecha de liquidación',
        tipo: 'date',
        required: false,
      },
    ],
  ];

  columns = [
    {
      title: '#FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
      showSorterTooltip: false,
    },
    {
      title: 'PAGADOR',
      dataIndex: 'pagadorNombre',
      sorter: (a, b) => sorterText(a.pagadorNombre, b.pagadorNombre),
      showSorterTooltip: false,
    },
    {
      title: 'PROVEEDOR',
      dataIndex: 'proveedorNombre',
      sorter: (a, b) => sorterText(a.proveedorNombre, b.proveedorNombre),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'FECHA ESPERADA DE PAGO',
      dataIndex: 'feEstimadaPago',
      sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
      showSorterTooltip: false,
    },
    {
      title: 'ESTADO',
      dataIndex: 'dsEstado',
      showSorterTooltip: false,
    },
    {
      title: 'SALDO',
      dataIndex: 'saldo',
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: '',
      render: (record) => (
        <Tooltip title='Historial de abonos'>
          <Button
            type='primary'
            shape='circle'
            icon={<InfoCircleOutlined />}
            onClick={() => this.showModalAbonosFactura(record)}
          />
        </Tooltip>
      ),
    },
  ];

  handleSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  componentDidMount() {
    this.props.menuHandler('71');
    this.getInitTipoConvenio();
    this.getInitEstado();
  }

  componentDidUpdate(props, state) {
    if (!equals(this.state.filtros, state.filtros)) {
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue(this.state.filtros);
      }
    }
  }

  getData = async () => {
    this.setState({
      ...this.state,
      resumen: initialState.resumen,
      carteraTotal: 0,
      carteraVencida: 0,
      loading: true,
    });
    try {
     const response = await listarCartera(
        this.props.authToken,
        this.props.company.company,
        this.state.filtros
      )
      this.setResponse(response);
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  setResponse = (response) => {
    const resumen = this.state.resumen.map((res) => {
      if (response.metadata.sumatorias[res.id] !== undefined) {
        res.value = (
          <span className='format-number'>
            {currencyFormat(response.metadata.sumatorias[res.id])}
          </span>
        );
      }

      return res;
    });

    this.setState({
      ...this.state,
      resumen: resumen,
      resultado: response.data,
      total: response.metadata.count,
      loading: false,
      sumatorias: response.metadata.sumatorias,
      carteraTotal: response.metadata.sumatorias.totalVrCartera,
      carteraVencida: response.metadata.sumatorias.totalVrVencida,
    });
  };

  validateForm = () => {
    let filtros = this.state.filtros;
    let validationResult = true;
    let formulario = this.formulario;
    let nombres = [];
    let errores = [];
    Object.keys(filtros).forEach((nombre) => {
      if (
        formulario.find((x) =>
          x.find((y) => y.nombre === nombre && y.required === true)
        )
      ) {
        if (filtros[nombre] === '' || filtros[nombre] === null) {
          nombres.push(nombre);
          validationResult = false;
        }
      }
    });
    if (!validationResult) {
      formulario[this.props.type].forEach((formItem) => {
        formItem.forEach((key) => {
          if (nombres.includes(key.nombre)) {
            errores.push(key.label.replace('* ', ''));
          }
        });
      });
      if (errores.length > 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar los valores de los campos ${errores.reduce(
            (error, acum) => `${acum}, ${error}`
          )} para consultar este reporte`,
          duration: 4,
        });
      }
      if (errores.length === 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar el valor del campo ${errores[0]} para consultar este reporte`,
          duration: 4,
        });
      }
    }
    return validationResult;
  };

  showModalAbonosFactura = async (factura) => {
    let filtros = { feLiquidacion: this.state.filtros.feliquidacion };
    try {
      this.setState({
        ...this.state,
        loading: true,
      });
     let response= await listarHistoricoAbonos(
        this.props.authToken,
        this.props.company.company,
        factura.scFactura,
        filtros
      )
      this.setState({
        ...this.state,
        factura: factura,
        abonos: response.data,
        visibleCheckPaymentModal: true,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  }

  handleModalOnClose = () => {
    this.setState({
      ...this.state,
      factura: {},
      abonos: [],
      visibleCheckPaymentModal: false,
      loading: false,
    });
  };

  onFinish = (value) => {
    this.setState({
      ...this.state,
      filtros: {
        ...this.state.filtros,
        fechaRango: value.fechaRango,
        feDesdeEstimadaPago: value.fechaRango[0],
        feHastaEstimadaPago: value.fechaRango[1],
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        tipoConvenio: value.tipoConvenio,
        estado: value.estado,
        feLiquidacion: value.feliquidacion,
        offset: 0,
      },
      resultado: [],
    });
    this.getData();
  };

  formRender = () => {
    const { tipoConvenioOptions, estadoOptions } = this.state;

    return (
      <Form
        name='content_form'
        layout='vertical'
        ref={this.formRef}
        className='filters-container'
        initialValues={this.state.filtros}
        onFinish={this.onFinish}
      >
        <Row gutter={18}>
          <Col span={24}>
            <Form.Item
              name='fechaRango'
              label='Fecha esperada de pago'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un rango de fechas',
                },
              ]}
            >
              <DatePicker.RangePicker name='fechaRango' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item name='scPagador' label='Pagador' required={false}>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                value={this.state.filtros.scPagador}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => this.handleSearchPagador(value)}
                onChange={(value) =>
                  this.handleSelectChange(value, 'scPagador')
                }
                notFoundContent={
                  this.state.fetching ? <Spin size='small' /> : null
                }
              >
                {this.state.pagadorSelect.options.map((d) => (
                  <Option key={d.scPersona}>{d.nombre}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scProveedor' label='Proveedor' required={false}>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                value={this.state.filtros.scProveedor}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => this.handleSearchProveedor(value)}
                onChange={(value) =>
                  this.handleSelectChange(value, 'scProveedor')
                }
                notFoundContent={
                  this.state.fetching ? <Spin size='small' /> : null
                }
              >
                {this.state.proveedorSelect.options.map((d) => (
                  <Option key={d.scPersona}>{d.nombre}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item name='tipoConvenio' label='Tipo de Producto'>
              <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                {tipoConvenioOptions.map((item) => (
                  <Select.Option key={item.codigo} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='estado' label='Estado'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                value={this.state.filtros.estado}
                allowClear
              >
                {estadoOptions.map((item) => (
                  <Select.Option key={item.codigo} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name='feliquidacion'
              label='Fecha de liquidación'
              required={true}
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione una fecha de liquidación',
                },
              ]}
            >
              <DatePicker
                name='feliquidacion'
                value={this.state.filtros.feliquidacion}
                onChange={(moment, string) =>
                  this.handleDateChange(moment, 'feliquidacion')
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='left'>
          <Col>
            <Form.Item name='submit'>
              <Button
                className='btn btn-green'
                type='primary'
                htmlType='submit'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  handleOnChange = (e) => {
    this.state.filtros.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.filtros.offset = e.offset;
    this.state.filtros.limit = e.limit;

    this.getData();
  };

  handleDateChange = (moment, campo) => {
    this.state.filtros[campo] = moment;
  };

  handleSelectChange = (value, stateName) => {
    this.state.filtros[stateName] = value;
    this.setState({ ...this.state });
  };

  exportExcel = () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });
      exportarExcelFacturasCartera(
          this.props.authToken,
          this.props.company.company,
          this.state.filtros
        ).then((response) => {
          this.setExcel(response);
        }).catch(_ => {
          this.setState({
            ...this.state,
            loading: false,
          });
        });         
    }
  };

  setExcel = (response) => {
    const url = window.URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Reporte Listar Cartera.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.setState({
      ...this.state,
      loading: false,
    });
  };

  getInitTipoConvenio = async () => {
    const { authToken } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const tipoConvenioRes = await listarTiposConvenios(authToken);
      this.setState({
        ...this.state,
        loading: false,
        tipoConvenioOptions: tipoConvenioRes.data,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        tipoConvenioOptions: [],
      });
    }
  };

  getInitEstado = async () => {
    const { authToken } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const estadoRes = await listarEstadoObligacion(authToken);
      this.setState({
        ...this.state,
        loading: false,
        estadoOptions: estadoRes.data,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        estadoOptions: [],
      });
    }
  };

  render() {
    return (
      <div className='reports'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={this.state.loading}>
                <Row gutter={30} className='bill-selection-core'>
                  <Col xl={{ span: 12 }} className='export'>
                    <p className='first-title'>
                      CARTERA TOTAL: {currencyFormat(this.state.carteraTotal)}
                    </p>
                  </Col>
                  <Col xl={{ span: 12 }} className='export'>
                    <p className='first-title'>
                      CARTERA VENCIDA:{' '}
                      {currencyFormat(this.state.carteraVencida)}
                    </p>
                  </Col>
                </Row>
                <div className='header-container'>{this.formRender()}</div>
                <div className='table-container'>
                  <Row
                    gutter={30}
                    className='bill-selection-core'
                    justify='end'
                  >
                    <Col xl={{ span: 24 / intColumnas }} className='export'>
                      <span className='button'>
                        <Button
                          size='medium'
                          className='btn-excel'
                          title='Exportar a excel'
                          onClick={this.exportExcel}
                          icon={<FileExcelOutlined />}
                        ></Button>
                      </span>
                    </Col>
                  </Row>
                  <Divider />
                  <TableReport
                    currentPage={this.state.currentPage}
                    limit={this.state.filtros.limit}
                    total={this.state.total}
                    resumen={this.state.resumen}
                    columns={this.columns}
                    data={this.state.resultado}
                    handleChange={this.handleOnChange}
                  />
                </div>
                <ListWalletModal
                  authToken={this.props.authToken}
                  visible={this.state.visibleCheckPaymentModal}
                  scFactura={this.state.factura}
                  abonos={this.state.abonos}
                  onClose={this.handleModalOnClose}
                />
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(listWallet);
