import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { currencyFormat, sorterText } from '../../../../utilities/textTools';
import {
  listaCupos,
  listarCuposConveniosExcel,
} from '../../../../services/fondeador/reportServices';
import {
  listarPagadoresCupos,
  listarProveedoresCupos,
  listarGruposEmpresarialesCupos,
} from '../../../../services/fondeador/operationServices';
import TableReport from '../../../../components/tableReport/tableReport';
import { setExcel } from '../../../../utilities/downloadTools';
import AgreementQuotaFilterForm from './agreementQuotaFilterForm/agreementQuotaFilterForm';

const initialState = {
  scFondeador: '',
  form: {
    feHasta: '',
    tipoEntidad: '',
    scEntidad: '',
  },
  entidadSelect: {
    fetching: false,
    options: [],
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 3,
    },
    {
      id: 'sumaNmCupoContraparte',
      value: currencyFormat(0),
      index: 'nmCupoContraparte',
      colSpan: 1,
    },
    {
      id: 'sumaNmCupoContraparteVig',
      value: currencyFormat(0),
      index: 'nmCupoContraparteVig',
      colSpan: 2,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class AgreementQuota extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO ENTIDAD',
        dataIndex: 'tipoEntidad',
        sorter: (a, b) => sorterText(a.tipoEntidad, b.tipoEntidad),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE ENTIDAD',
        dataIndex: 'nombreEntidad',
        sorter: (a, b) => sorterText(a.nombreEntidad, b.nombreEntidad),
        showSorterTooltip: false,
      },
      {
        title: 'VIGENCIA',
        dataIndex: 'feHasta',
        sorter: (a, b) => sorterText(a.vigencia, b.vigencia),
        showSorterTooltip: false,
      },
      {
        title: 'CUPO CONTRAPARTE ASIGNADO',
        dataIndex: 'nmCupoContraparte',
        sorter: (a, b) => a.nmCupoContraparte - b.nmCupoContraparte,
        render: (value) => <span>$ {this.formatNumber(value)} </span>,
        showSorterTooltip: false,
      },
      {
        title: 'CUPO CONTRAPARTE VIGENTE',
        dataIndex: 'nmCupoContraparteVig',
        sorter: (a, b) => a.nmCupoContraparteVig - b.nmCupoContraparteVig,
        render: (value) => <span>$ {this.formatNumber(value)} </span>,
        showSorterTooltip: false,
      },
    ];
  }

  handleFondeador = async (e) => {
    this.state.scFondeador = e;
    this.state.resultado = initialState.resultado;
  };

  handleDateChange = (moment, campo) => {
    this.state.form[campo] = moment;
  };

  handleOnChangeTipoEntidad = async (e) => {
    const { entidadSelect } = this.state;

    let response = null;
    if (e === 'PAG') {
      response = await listarPagadoresCupos(this.props.authToken, {
        limit: 50,
      });
    } else if (e === 'PRO') {
      response = await listarProveedoresCupos(this.props.authToken, {
        limit: 50,
      });
    } else {
      response = await listarGruposEmpresarialesCupos(this.props.authToken, {
        limit: 50,
      });
    }
    this.setState({
      ...this.state,
      loading: false,
      form: {
        ...this.state.form,
        scEntidad: '',
        tipoEntidad: e,
      },
      entidadSelect: {
        ...entidadSelect,
        options: response.data,
        fetching: false,
      },
    });
  };

  handleOnSearchEntidad = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { entidadSelect } = this.state;

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          fetching: true,
        },
      });

      try {
        let response = null;
        if (this.state.form.tipoEntidad === 'PAG') {
          response = await listarPagadoresCupos(this.props.authToken, {
            nombre: nombre,
          });
        } else if (this.state.form.tipoEntidad === 'PRO') {
          response = await listarProveedoresCupos(this.props.authToken, {
            nombre: nombre,
          });
        } else {
          response = await listarGruposEmpresarialesCupos(
            this.props.authToken,
            {
              nombre: nombre,
            }
          );
        }

        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnChangeEntidad = async (e) => {
    const { entidadSelect } = this.state;

    this.setState({
      ...this.state,
      entidadSelect: {
        ...entidadSelect,
        fetching: true,
      },
      form: {
        ...this.state.form,
        scEntidad: e,
      },
      resultado: initialState.resultado,
    });

    try {
      let response = null;
      if (this.state.form.tipoEntidad === 'PAG') {
        response = await listarPagadoresCupos(this.props.authToken, {
          limit: 50,
        });
      } else if (this.state.form.tipoEntidad === 'PRO') {
        response = await listarProveedoresCupos(this.props.authToken, {
          limit: 50,
        });
      } else {
        response = await listarGruposEmpresarialesCupos(this.props.authToken, {
          limit: 50,
        });
      }

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      const response = await listaCupos(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );

      const resumen = this.state.resumen.map((res) => {
        if (response.metadata.sumatorias[res.id] !== undefined) {
          res.value = (
            <span className='format-number'>
              {currencyFormat(response.metadata.sumatorias[res.id])}
            </span>
          );
        }

        return res;
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        resumen: resumen,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarCuposConveniosExcel(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );
      setExcel(response, `reporte_cupo_pagador`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      scFondeador: this.props.company.company,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.setState({
      sort: e.sort,
      currentPage: e.currentPage,
      offset: e.offset,
      limit: e.limit,
    });
    this.searchData();
  };

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  componentDidMount() {
    this.handleFondeador(this.props.company.company);
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...initialState,
        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'tipoConvenio',
            colSpan: 3,
          },
          {
            id: 'sumaNmCupoContraparte',
            value: currencyFormat(0),
            index: 'nmCupoContraparte',
            colSpan: 1,
          },
          {
            id: 'sumaNmCupoContraparteVig',
            value: currencyFormat(0),
            index: 'nmCupoContraparteVig',
            colSpan: 2,
          },
        ],
      });
      this.handleFondeador(this.props.company.company);
    }
  }

  render() {
    const {
      entidadSelect,
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
    } = this.state;

    const { feHasta, scEntidad } = this.state.form;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <AgreementQuotaFilterForm
            companyId={this.props.company.company}
            handleOnSearchEntidad={this.handleOnSearchEntidad}
            handleOnChangeEntidad={this.handleOnChangeEntidad}
            handleOnChangeTipoEntidad={this.handleOnChangeTipoEntidad}
            handleSubmit={this.handleSubmit}
            handleDateChange={this.handleDateChange}
            feHasta={feHasta}
            entidadSelect={entidadSelect}
            scEntidad={scEntidad}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default AgreementQuota;
