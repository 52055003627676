import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Modal,
  notification,
  Row,
  Space,
  Spin,
} from 'antd';
import React, { Component } from 'react';
import TableReport from '../../../../../components/tableReport/tableReport';
import {
  asociarPagador,
  eliminarPagadorGrupo,
  listarCompaniasGrupos,
  listarCompaniasNoAsociados,
} from '../../../../../services/admin/businessGroupsService';
import { sorterText } from '../../../../../utilities/textTools';
import AsociarPagadorModal from './asociarPagadorModal/asociarPagadorModal';

const GROUP_TITLE = 'Grupo empresarial';

const initialState = {
  loading: false,
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  sort: '',
  loadingModal: false,
  visibleModal: false,
  pagadoresSelect: {
    fetching: false,
    options: [],
  },
};

class CompaniesGrups extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'TIPO IDENTIFICACIÓN',
        dataIndex: 'dsTipoId',
        sorter: (a, b) => sorterText(a.dsTipoId, b.dsTipoId),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.id, b.id),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        showSorterTooltip: false,
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showEliminarGrupo(record)}
          >
            ELIMINAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  showEliminarGrupo = (grupo) => {
    Modal.confirm({
      title: GROUP_TITLE,
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro de eliminar esta compañía?',
      onOk: () => this.eliminarCompania(grupo.scCompania),
    });
  };

  eliminarCompania = async (scCompania) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await eliminarPagadorGrupo(
        this.props.authToken,
        scCompania
      );

      notification.success({
        message: GROUP_TITLE,
        description: response.message,
        duration: 6,
      });

      this.getData();
    } catch {
      this.setState({
        ...this.state,
        loading: true,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    const { authToken, scGrupo, cdTipoCliente } = this.props;
    const { pagadoresSelect } = this.state;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarCompaniasGrupos(
        authToken,
        scGrupo,
        this.getFiltro()
      );

      const asoResponse = await listarCompaniasNoAsociados(authToken, scGrupo, {
        cdTipoCliente,
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        pagadoresSelect: {
          ...pagadoresSelect,
          options: asoResponse.data,
          fetching: false,
        },
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { limit, offset, sort } = this.state;

    return {
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleOnOpenModal = () => {
    this.setState({
      ...this.state,
      visibleModal: true,
    });
  };

  handleOnClose = () => {
    this.setState({
      ...this.state,
      visibleModal: false,
    });
  };

  handleOnFinish = async (formValue) => {
    const { authToken, scGrupo } = this.props;
    this.setState({
      ...this.state,
      loadingModal: true,
    });

    try {
      const response = await asociarPagador(
        authToken,
        scGrupo,
        formValue.scCompania
      );

      notification.success({
        message: GROUP_TITLE,
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        loadingModal: false,
        visibleModal: false,
      });

      this.getData();
    } catch {
      this.setState({
        ...this.state,
        loadingModal: false,
      });
    }
  };

  handleOnPagadorSearch = async (nombre) => {
    const { pagadoresSelect } = this.state;
    const { authToken, scGrupo, cdTipoCliente } = this.props;

    this.setState({
      ...this.state,
      pagadoresSelect: {
        ...pagadoresSelect,
        fetching: true,
      },
    });

    try {
      const response = await listarCompaniasNoAsociados(authToken, scGrupo, {
        nombre: nombre,
        cdTipoCliente: cdTipoCliente,
      });
      this.setState({
        ...this.state,
        pagadoresSelect: {
          ...pagadoresSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        pagadoresSelect: {
          ...pagadoresSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const {
      loading,
      loadingModal,
      visibleModal,
      pagadoresSelect,
      resultado,
      currentPage,
      limit,
      total,
    } = this.state;
    const { children } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <Row gutter={[16, 32]} align='middle'>
          <Col flex='auto'>
            <Space align='baseline' className='sub-title'>
              Lista de compañías
            </Space>
          </Col>
          <Col flex='none'>
            <span className='button'>
              <Button
                size='small'
                className='btn-agregar'
                onClick={this.handleOnOpenModal}
              >
                Asociar Compañía
              </Button>
            </span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col flex='auto'>
            <div className='table-container'>
              <TableReport
                currentPage={currentPage}
                limit={limit}
                total={total}
                columns={this.columnas}
                data={resultado}
                handleChange={this.handleChange}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>{children}</Row>
        <AsociarPagadorModal
          loading={loadingModal}
          visible={visibleModal}
          pagadoresSelect={pagadoresSelect}
          onPagadorSearch={this.handleOnPagadorSearch}
          onClose={this.handleOnClose}
          onFinish={this.handleOnFinish}
        />
      </Spin>
    );
  }
}

export default CompaniesGrups;
