import { FileExcelOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Spin,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import TableReport from '../../../../../components/tableReport/tableReport';
import {
  listarConvenios,
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../../services/admin/reports/quotasService';
import {
  executeTask,
  listarFacturasCargadas,
  listarFacturasCargadasExcel,
} from '../../../../../services/admin/reportsLoadedBills';
import { setExcel } from '../../../../../utilities/downloadTools';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';
import LoadedBillsFilterForm from './loadedBillsFilterForm/loadedBillsFilterForm';
import { listarTiposConvenios } from '../../../../../services/admin/operation';

const initialState = {
  form: {
    fechaDesde: moment(),
    scGrupo: '',
    scPagador: '',
    scProveedor: '',
    cdFactura: '',
    scConvenio: '',
    cdTipoConvenio: '',
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 9,
    },
    {
      id: 'totalVrFactura',
      value: currencyFormat(0),
      index: 'vrFactura',
      type: 'current',
      colSpan: 1,
    },
    {
      id: 'totalNmOfertas',
      value: 0,
      index: 'nmOfertas',
      type: 'number',
      colSpan: 1,
    },
  ],
  selectedKeys: [],
  selectAll: false,
  unselectedKeys: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  buttonOptions: [],
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  tipoConvenioSelect: [],
  visible: false,
  visibleVerif: false,
};

class LoadedBills extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NOMBRE DEL CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => sorterText(a.nombreConvenio, b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: '# FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE VENTA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO DE DOCUMENTO',
        dataIndex: 'dsDebeHaber',
        render: (value) => (
          <span>{value === undefined ? 'Factura' : value}</span>
        ),
        sorter: (a, b) => sorterText(a.dsDebeHaber, b.dsDebeHaber),
        showSorterTooltip: false,
      },
      {
        title: 'BLOQUEO',
        dataIndex: 'dsSnBloqueo',
        sorter: (a, b) => sorterText(a.cdSnBloqueo, b.cdSnBloqueo),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoFactura',
        sorter: (a, b) => sorterText(a.cdEstadoFactura, b.cdEstadoFactura),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR FACTURA',
        dataIndex: 'vrFactura',
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'NÚMERO OFERTAS',
        dataIndex: 'nmOfertas',
        sorter: (a, b) => b.nmOfertas - a.nmOfertas,
        render: (value) => <span>{value}</span>,
        showSorterTooltip: false,
      },
    ];

    this.form = React.createRef();
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarConvenios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
      selectedKeys: initialState.selectedKeys,
      selectAll: initialState.selectAll,
      unselectedKeys: initialState.unselectedKeys,
      sort: initialState.sort,
    });

    this.searchData();
  };

  searchData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      let response = await listarFacturasCargadas(
        this.props.authToken,
        this.getFiltro()
      );

      const resumen = this.state.resumen.map((res) => {
        if (response.metadata.sumatorias[res.id] !== undefined) {
          const valor = response.metadata.sumatorias[res.id];

          let text = '';
          switch (res.type) {
            case 'current':
              text = (
                <span className='format-number'>{currencyFormat(valor)}</span>
              );
              break;
            default:
              text = (
                <Row justify='center'>
                  <Col>
                    <span className='format-number'>{valor}</span>
                  </Col>
                </Row>
              );
              break;
          }
          res.value = text;
        }

        return res;
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        resumen: resumen,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    // if (this.validateForm()) {
    const {
      form: { fechaDesde },
    } = this.state;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarFacturasCargadasExcel(
        this.props.authToken,
        this.getFiltro()
      );

      setExcel(response, `${fechaDesde.format('YYYY_MM_DD')}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
    // }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  onSelect = (record, selected) => {
    const { selectAll } = this.state;
    let unselectedKeys = [];
    if (selected) {
      if (this.state.selectAll) {
        unselectedKeys = this.state.unselectedKeys.filter(
          (key) => key !== record.key
        );
      }
      this.setState({
        ...this.state,
        selectedKeys: [...this.state.selectedKeys, record.key],
        unselectedKeys: unselectedKeys,
      });
    } else {
      if (selectAll) {
        unselectedKeys = [...this.state.unselectedKeys, record.key];
      }
      let filterKeys = this.state.selectedKeys.filter(
        (selectedKey) => selectedKey !== record.key
      );
      this.setState({
        ...this.state,
        selectedKeys: filterKeys,
        unselectedKeys: unselectedKeys,
      });
    }
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      changeRows.forEach((row) => {
        let selected = this.state.selectedKeys.find((key) => row.key === key);
        if (!selected) {
          this.state.selectedKeys = [...this.state.selectedKeys, row.key];
        }
      });
      this.setState({
        ...this.state,
        unselectedKeys: [],
        selectAll: selected,
      });
    } else {
      this.setState({
        ...this.state,
        selectedKeys: [],
        unselectedKeys: [],
        selectAll: selected,
      });
    }
  };

  handleOnFinish = (formValue) => {
    this.executeAction('anular_operacion', formValue.justificacion);
    this.setState({
      visible: false,
    });
  };
  //Agregando funcion para cerrar modal anular operacion
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  //Agregando funcion para cerrar modal facturas en verfic
  handleOnFinishVerif = (formValue) => {
    this.executeAction('eliminar_verificacion', formValue.justificacionVerif);
    this.setState({
      visibleVerif: false,
    });
  };
  handleCancelVerif = (e) => {
    this.setState({
      visibleVerif: false,
    });
  };

  executeAction = async (task, justificacion) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      let keys = [];
      if (this.state.selectAll) {
        keys = this.state.unselectedKeys;
      } else {
        keys = this.state.selectedKeys;
      }


      const response = await executeTask(
        this.props.authToken,
        task,
        keys,
        this.state.selectAll,
        justificacion,
        { ...this.getFiltro() },
        this.state.form.fechaDesde
      );

      notification.success({
        message: 'Facturas Cargadas',
        description: response ? response.message : 'Error inesperado',
        duration: 5,
      });

      this.setState({
        ...this.state,
        currentPage: 1,
        offset: 0,
        total: 0,
        resultado: [],
        selectedKeys: [],
        selectAll: false,
        unselectedKeys: [],
        visible: false,
      });

      this.searchData();
    } catch (error) {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnOpenModal = () => {
    if (this.form.current) {
      this.form.current.resetFields();
    }
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  handleOnOpenModalVerif = () => {
    if (this.form.current) {
      this.form.current.resetFields();
    }
    this.setState({
      ...this.state,
      visibleVerif: true,
    });
  };
  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleOnSearchTipoConvenio = async () => {
    const { tipoConvenioSelect } = this.state;
    try {
      const response = await listarTiposConvenios(this.props.authToken)
      this.setState({
        ...this.state,
        tipoConvenioSelect: response.data
      });
      console.log(response.data);
    } catch {
      this.setState({
        ...this.state,
        tipoConvenioSelect: []
      });
    }
  };

  componentDidMount() {
    this.handleOnSearchTipoConvenio();
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      grupoSelect,
      pagadorSelect,
      proveedorSelect,
      convenioSelect,
      selectedKeys,
      visible,
      visibleVerif,
      tipoConvenioSelect
    } = this.state;

    this.rowSelection = {
      type: 'checkbox',
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      selectedRowKeys: selectedKeys,
    };

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <LoadedBillsFilterForm
            grupoSelect={grupoSelect}
            pagadorSelect={pagadorSelect}
            proveedorSelect={proveedorSelect}
            convenioSelect={convenioSelect}
            tipoConvenioSelect={tipoConvenioSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnSearchGrupo={this.handleOnSearchGrupo}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              keyIndex={'scFactura'}
              rowSelection={this.rowSelection}
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
          {resultado.length > 0 && (
            <Row gutter={[45, 30]} justify='center' align='middle'>
              <Col span={6}>
                <Button
                  className='btn btn-blue btn-radius'
                  type='primary'
                  onClick={() => this.executeAction('desbloquear_factura')}
                >
                  Desbloquear factura
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  style={{ minWidth: '135px', fontSize: '0.9rem' }}
                  className='btn btn-blue btn-radius'
                  type='primary'
                  onClick={() => this.executeAction('eliminar_oferta')}
                >
                  Eliminar oferta
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  className='btn form-btn-blue btn-radius'
                  type='primary'
                  onClick={() => this.executeAction('eliminar_factura')}
                >
                  Eliminar factura
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  className='btn btn-blue btn-radius'
                  type='primary'
                  onClick={this.handleOnOpenModal}
                >
                  Anular operación
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  className='btn btn-blue btn-radius'
                  type='primary'
                  onClick={this.handleOnOpenModalVerif}
                >
                  Cambiar estado
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  className='btn form-btn-blue btn-radius'
                  type='primary'
                  onClick={() => this.executeAction('traer_facturas')}
                >
                  Traer
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  className='btn form-btn-blue btn-radius'
                  type='primary'
                  onClick={() => this.executeAction('consultar_eventos_facturas')}
                >
                  Consultar Eventos DIAN
                </Button>
              </Col>
            </Row>
          )}
          {/* Modal para anular operacion*/}
          <Modal
            title='Justificar operación manual'
            className='modal-style'
            visible={visible}
            onCancel={this.handleCancel}
            footer={''}
          >
            <div className='header-container'>
              <Form ref={this.form} onFinish={this.handleOnFinish}>
                <Row justify='center'>
                  <Col flex='auto'>
                    <Form.Item
                      name='justificacion'
                      rules={[
                        {
                          required: true,
                          message: 'la justificación es requerida',
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify='center'>
                  <Col span={8}>
                    <Form.Item>
                      <Button
                        className='form-btn btn-radius'
                        type='primary'
                        htmlType='submit'
                      >
                        Grabar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>

          {/* Modal para eliminar estado de verif */}
          <Modal
            title='Justificar operación manual'
            className='modal-style'
            visible={visibleVerif}
            onCancel={this.handleCancelVerif}
            footer={''}
          >
            <div className='header-container'>
              <Form ref={this.form} onFinish={this.handleOnFinishVerif}>
                <Row justify='center'>
                  <Col flex='auto'>
                    <Form.Item
                      name='justificacionVerif'
                      rules={[
                        {
                          required: true,
                          message: 'la justificación es requerida',
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify='center'>
                  <Col span={8}>
                    <Form.Item>
                      <Button
                        className='form-btn btn-radius'
                        type='primary'
                        htmlType='submit'
                      >
                        Grabar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
        </Spin>
      </div>
    );
  }
}

export default LoadedBills;
