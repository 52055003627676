import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import { listaUsuarios } from '../../../../services/admin/usersService';
import { BUSQUEDA_TITLE } from '../../../../utilities/messages';
import { sorterText } from '../../../../utilities/textTools';
import UserEditModal from './userEditModal/userEditModal';
import './users.scss';
import UsersNewModal from './usersNewModal/usersNewModal';

const NAME_FILTRO = 'Nombre';

const initialState = {
  loading: false,
  form: {
    nombre: '',
  },
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  userEdit: {},
  visibleEditModal: false,
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.id, b.id),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        showSorterTooltip: false,
      },
      {
        title: 'EMAIL',
        dataIndex: 'email',
        sorter: (a, b) => sorterText(a.email, b.email),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <Button type='primary' onClick={() => this.showModalEditar(record)}>
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.formSearch = React.createRef();
  }

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      resultado: [],
      total: initialState.total,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.getData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaUsuarios(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;

    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  showModalEditar = (user) => {
    this.setState({
      ...this.state,
      userEdit: user,
      visibleEditModal: true,
    });
  };

  handleEditModalOnClose = () => {
    this.setState({
      ...this.state,
      cuentaEdit: {},
      visibleEditModal: false,
    });
  };

  handleModalOnFinish = (user) => {
    this.setState({
      ...this.state,
      userEdit: {},
      visibleEditModal: false,
    });
    this.getData();
  };

  componentDidMount() {
    this.props.menuHandler('8');
    this.getData();
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resultado,
      visibleEditModal,
      userEdit,
    } = this.state;

    const { authToken } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='users-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <Row gutter={[16, 32]}>
                <Col span={24}>
                  <Space align='baseline' className='title'>
                    {BUSQUEDA_TITLE}
                  </Space>
                </Col>
              </Row>
              <Form
                ref={this.formSearch}
                name='erp_search'
                layout='vertical'
                onFinish={this.handleSubmit}
              >
                <Row gutter={[16, 32]}>
                  <Col span={9}>
                    <Form.Item name='nombre' label={NAME_FILTRO}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item>
                      <Button
                        className='form-btn btn-radius'
                        type='primary'
                        htmlType='submit'
                      >
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de usuarios
                  </Space>
                </Col>
                <Col flex='none'>
                  <span className='button'>
                    <UsersNewModal
                      authToken={authToken}
                      onEditModalChange={this.showModalEditar}
                      onFinish={this.handleModalOnFinish}
                    />
                  </span>
                </Col>
              </Row>

              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        {visibleEditModal && (
          <UserEditModal
            authToken={authToken}
            data={userEdit}
            visible={visibleEditModal}
            onClose={this.handleEditModalOnClose}
            onFinish={this.handleModalOnFinish}
          />
        )}
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Users);
