import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs, Spin, Col, Button, notification, Modal } from 'antd';
import '../../common/client.scss';
import history from '../../../../../services/history';
import {
  buscarPagador,
  crearPagador,
} from '../../../../../services/admin/payersService';
import BasicDataForm, {
  initialPayerValuesForm,
} from '../basicDataForm/basicDataForm';

const PAYERS_TITLE = 'Pagadores';

const MESSAGE_ENTIDAD_PROVEEDOR_NO_EXIST =
  'Esta persona o entidad no es un pagador';
const MESSAGE_ENTIDAD_PROVEEDOR_EXIST = 'Esta persona o entidad es un pagador';

const initialState = {
  loading: false,
  propietarioSelect: {
    options: [],
    fetching: false,
  },
  data: { ...initialPayerValuesForm },
};

class PayersNewForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  redirectToPayers = () => {
    history.push(`${process.env.PUBLIC_URL}/payers`);
  };

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await crearPagador(this.props.authToken, formValue);

      notification.success({
        message: PAYERS_TITLE,
        description: response.message,
        duration: 6,
      });
      this.redirectToEditPayers(response.location);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnPayerSearch = async (filtros) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await buscarPagador(this.props.authToken, filtros);

      if (response.data) {
        if (response.data.snPagador) {
          Modal.success({
            title: PAYERS_TITLE,
            content: MESSAGE_ENTIDAD_PROVEEDOR_EXIST,
          });
          this.redirectToEditPayers(response.data.scPersona);
        } else {
          Modal.success({
            title: PAYERS_TITLE,
            content: MESSAGE_ENTIDAD_PROVEEDOR_NO_EXIST,
          });

          this.setState({
            ...this.state,
            data: response.data,
          });
        }
      }
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  redirectToEditPayers = (scPersona) => {
    history.push(`${process.env.PUBLIC_URL}/payers/edit/${btoa(scPersona)}`);
  };

  componentDidMount() {
    this.props.menuHandler('22');
  }

  render() {
    const { loading, data } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Tabs activeKey={'1'}>
              <Tabs.TabPane key={'1'} tab='Datos básicos'>
                <BasicDataForm
                  data={data}
                  onPayerSearch={this.handleOnPayerSearch}
                  onFinish={this.handleOnFinish}
                  visibleCalendar={false}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToPayers}
                      >
                        Lista de Pagadores
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(PayersNewForm);
