import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

const upload = (authtoken, company, loadtype = 'facturas', files) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${company}/${loadtype}/cargar-archivo`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: files,
    })
  );
};
const confirm = (authtoken, company, loadtype = 'facturas', filemane) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${company}/${loadtype}/cargar-archivo/aceptar/${filemane}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
const downloadTemplate = (authtoken, company, loadtype) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${company}/${loadtype}/plantilla`,
      responseType: 'blob',
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const uploadRates = (authtoken, company, loadtype = 'tasas', files) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fondeadores/${company}/${loadtype}/cargar-tasas`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: files,
    })
  );
};
const downloadTemplateRates = (authtoken, company, loadtype = 'tasas') => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fondeadores/${company}/${loadtype}/plantilla`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export default {
  upload,
  confirm,
  downloadTemplate,
  uploadRates,
  downloadTemplateRates,
};