import React, { Component } from 'react';
import { Row, Col, Divider, Spin } from 'antd';
import TableReport from '../../../../../components/tableReport/tableReport';
import { currencyFormat, sorterText } from '../../../../../utilities/textTools';

import { listarTransaccionesLoteFondeador } from '../../../../../services/admin/ACHoperationService';
import AchTransactionDetail from '../achTransactionDetail/achTransactionDetail';

const initialState = {
  loading: false,
  resultado: [],
  sort: 'beneficiario',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class AchTransacions extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.componentRef = React.createRef();
  }

  searchData = async () => {
    const { scLoteAch, authToken, scFondeador } = this.props;
    this.setState({
      ...this.state,
      resultado: [],
      loading: true,
    });
    const filtros = this.getFiltro();
    try {
      const response = await listarTransaccionesLoteFondeador(
        authToken,
        scFondeador,
        scLoteAch,
        filtros
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };
  getFiltro = () => {
    const { limit, offset, sort } = this.state;
    return {
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };
  

  getSortOrder = (sort, column) => {
    let order = '+';
    let col = sort;
    if (sort.includes('+') || sort.includes('-')) {
      col = sort.substr(1, sort.length);
      order = sort.charAt(0);
    }

    if (col === column) {
      return order === '-' ? 'descend' : 'ascend';
    }

    return false;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  componentDidMount() {
    this.searchData();
    this.componentRef.current.scrollIntoView();
  }

  componentDidUpdate(props, state) {
    if (props.scLoteAch !== this.props.scLoteAch) {
      this.searchData();
      this.componentRef.current.scrollIntoView();
    }
  }

  render() {
    const { loading, currentPage, limit, total, resumen, resultado, sort } =
      this.state;

    const { scLoteAch, scFondeador, authToken } = this.props;

    this.columnas = [
      {
        title: 'No TRANSACCIÓN ACH',
        dataIndex: 'scTransaccionACH',
        sorter: (a, b) => sorterText(a.scTransaccionACH, b.scTransaccionACH),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'scTransaccionACH'),
      },
      {
        title: 'BENEFICIARIO',
        dataIndex: 'beneficiario',
        sorter: (a, b) => sorterText(a.beneficiario, b.beneficiario),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'beneficiario'),
      },
      {
        title: 'TIPO ID',
        dataIndex: 'tipoId',
        sorter: (a, b) => sorterText(a.tipoId, b.tipoId),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'tipoId'),
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.id, b.id),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'id'),
      },
      {
        title: 'BANCO',
        dataIndex: 'banco',
        sorter: (a, b) => sorterText(a.banco, b.banco),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'banco'),
      },
      {
        title: 'CÓDIGO ACH',
        dataIndex: 'cdACH',
        sorter: (a, b) => sorterText(a.cdACH, b.cdACH),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'cdACH'),
      },
      {
        title: 'TIPO DE CUENTA',
        dataIndex: 'tipoCuenta',
        sorter: (a, b) => sorterText(a.tipoCuenta, b.tipoCuenta),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'tipoCuenta'),
      },
      {
        title: 'NÚMERO DE CUENTA',
        dataIndex: 'numeroCuenta',
        sorter: (a, b) => sorterText(a.numeroCuenta, b.numeroCuenta),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'numeroCuenta'),
      },
      {
        title: 'VALOR A PAGAR',
        dataIndex: 'valorAPagar',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.valorAPagar - b.valorAPagar,
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'valorAPagar'),
      },
      {
        title: 'DETALLE',
        dataIndex: 'DETALLE',
        render: (text, record) => (
          <AchTransactionDetail
            scFondeador={scFondeador}
            authToken={authToken}
            scLoteAch={scLoteAch}
            scTransaccionACH={record.scTransaccionACH}
          />
        ),
        showSorterTooltip: false,
      },
    ];

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div ref={this.componentRef} className='table-container'>
          <Row gutter={30} className='bill-selection'>
            <Col span={12}>
              <p className='ptable'>
                Transacciones ACH asociadas al Lote No {scLoteAch}
              </p>
            </Col>
          </Row>
          <Divider />

          <TableReport
            currentPage={currentPage}
            limit={limit}
            total={total}
            resumen={resumen}
            columns={this.columnas}
            data={resultado}
            handleChange={this.handleChange}
          />
        </div>
      </Spin>
    );
  }
}

export default AchTransacions;
