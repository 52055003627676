import { Form, Row, Col, Select, DatePicker, Input, Button } from 'antd';
import React,{useEffect} from 'react';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';
import { tipoSolicitud } from '../../../../../utilities/tipoSolicitud';

export const FormFilterReport = ({handleSubmit}) => {

 const [form] = Form.useForm();

 useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.size > 0){
      let urldata = {
        scSolicitud: queryParameters.get("scSolicitud"),
        tipoSolicitud: queryParameters.get("tipoSolicitud"),
        id_nit: queryParameters.get("id_nit"),
      }    
      form.setFieldsValue(urldata);
    }
 }, [])
 
  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
      >
        <Row gutter={12}>
            <Col flex="auto">
              <Form.Item
                name='id_nit'
                label='Nit/Identificacion'
              >
                <Input type='number'/>
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item
                name='scSolicitud'
                label='Consecutivo Solicitud'
              >
                <Input type='number'/>
              </Form.Item>
            </Col>
        </Row>
        <Row gutter={12}>
            <Col flex="auto">
              <Form.Item
                name='tipoSolicitud'
                label='Tipo Solicitud'
              >
                <Select allowClear>
                  {tipoSolicitud.map(({ key, description }) => (
                    <Select.Option key={key} value={key}>
                      {description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
        </Row>
        <Row justify='center'>
          <Col span={12}>
            <Form.Item>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
