import {
  ADMIN_PERSON_TEMPORAL_DATA,
  ADMIN_PERSON_REQUEST,
  ADMIN_PERSON_J_LOADED,
  ADMIN_PERSON_N_LOADED,
  ADMIN_PERSON_UPDATE_STATE,
  ADMIN_PERSON_SAVE_REQUEST,
  ADMIN_PERSON_SAVE_SUCCESS
}
  from '../../actions/types'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADMIN_PERSON_TEMPORAL_DATA:
      return {
        ...initialState,
        ...action.payload,
        loading: false
      }
    case ADMIN_PERSON_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ADMIN_PERSON_J_LOADED:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case ADMIN_PERSON_N_LOADED:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case ADMIN_PERSON_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case ADMIN_PERSON_SAVE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ADMIN_PERSON_SAVE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    default:
      return {
        ...state,
      }
      break;
  }
}