import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import React from 'react';
import { requiredValueMessage } from '../../../../../../utilities/admin_validation_messages';
import { tipoIds } from '../../../../../../utilities/constants';
import { DATE_FORMAT } from '../../../../../../utilities/messages';
import { length } from '../../../../../../utilities/validate/textValidate';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const sexo = [
  { key: 'M', description: 'Masculino' },
  { key: 'F', description: 'Femenino' },
];

const rules = {
  prNombreTutor: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 20),
    },
  ],
  segNombreTutor: [
    {
      validator: (_, value) => length(value, 2, 20),
    },
  ],
  prApellidoTutor: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 20),
    },
  ],
  segApellidoTutor: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 20),
    },
  ],
  cdTipoIdTutor: [{ required: true, message: requiredValueMessage() }],
  idTutor: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  feExpTutor: [{ required: true, message: requiredValueMessage() }],
  lugarExpTutor: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 20),
    },
  ],
  feNacimientoTutor: [{ required: true, message: requiredValueMessage() }],
  cdPaisNacTutor: [{ required: true, message: requiredValueMessage() }],
  cdDepNacTutor: [{ required: true, message: requiredValueMessage() }],
  cdMunNacTutor: [{ required: true, message: requiredValueMessage() }],
  profesionTutor: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 100),
    },
  ],
  dirResidenciaTutor: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisResidenciaTutor: [{ required: true, message: requiredValueMessage() }],
  cdDepResidenciaTutor: [{ required: true, message: requiredValueMessage() }],
  cdMunResidenciaTutor: [{ required: true, message: requiredValueMessage() }],
  cdSexoTutor: [{ required: true, message: requiredValueMessage() }],
  telefonoTutor: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  celularTutor: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  emailTutor: [
    { required: true, message: requiredValueMessage() },
    {
      pattern:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 200),
    },
  ],
};

class Tutor extends React.Component {
  /* handleOnChangeDepartamentoResidencia = (value) => {
    const { onChangeDepartamentoResidencia } = this.props;

   

    onChangeDepartamentoResidencia(value, cdPais);
  }; */

  createTutorForm = () => {
    const {
      paises,
      departamentosNac,
      municipiosNac,
      departamentosRes,
      municipiosRes,
      onChangePaisNacimiento,
      onChangeDepartamentoNac,
      onChangePaisResidencia,
      onChangeDepartamentoResidencia,
    } = this.props;
    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='prNombreTutor'
              label='Primer nombre'
              rules={rules['prNombreTutor'] || ''}
            >
              <Input maxLength={20} minLength={2} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='segNombreTutor'
              label='Segundo nombre'
              rules={rules['segNombreTutor'] || ''}
            >
              <Input maxLength={20} minLength={2} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='prApellidoTutor'
              label='Primer apellido'
              rules={rules['prApellidoTutor'] || ''}
            >
              <Input maxLength={20} minLength={2} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='segApellidoTutor'
              label='Segundo apellido'
              rules={rules['segApellidoTutor'] || ''}
            >
              <Input maxLength={20} minLength={2} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdTipoIdTutor'
              label='Tipo de documento'
              rules={rules['cdTipoIdTutor'] || ''}
            >
              <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                {tipoIds.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='idTutor'
              label='Número de identificación'
              rules={rules['idTutor'] || ''}
            >
              <Input maxLength={20} minLength={7} type='number' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='feExpTutor'
              label='Fecha de expedición'
              rules={rules['feExpTutor'] || ''}
            >
              <DatePicker
                className='radius'
                allowClear={false}
                style={{ width: '100%' }}
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='lugarExpTutor'
              label='Lugar de expedición'
              rules={rules['lugarExpTutor'] || ''}
            >
              <Input maxLength={100} minLength={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='feNacimientoTutor'
              label='Fecha de nacimiento'
              rules={rules['feNacimientoTutor'] || ''}
            >
              <DatePicker
                className='radius'
                allowClear={false}
                style={{ width: '100%' }}
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='cdPaisNacTutor'
              label='País de nacimiento'
              rules={rules['cdPaisNacTutor'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={onChangePaisNacimiento}
              >
                {paises.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdDepNacTutor'
              label='Departamento de nacimiento'
              rules={rules['cdDepNacTutor'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  departamentosNac.fetching ? <Spin size='small' /> : null
                }
                onChange={onChangeDepartamentoNac}
              >
                {departamentosNac.options.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='cdMunNacTutor'
              label='Ciudad de nacimiento'
              rules={rules['cdMunNacTutor'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  municipiosNac.fetching ? <Spin size='small' /> : null
                }
              >
                {municipiosNac.options.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='profesionTutor'
              label='Cargo u oficio'
              rules={rules['profesionTutor'] || ''}
            >
              <Input maxLength={100} minLength={5} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='dirResidenciaTutor'
              label='Dirección de residencia'
              rules={rules['dirResidenciaTutor'] || ''}
            >
              <Input maxLength={300} minLength={5} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdPaisResidenciaTutor'
              label='País de residencia'
              rules={rules['cdPaisResidenciaTutor'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={onChangePaisResidencia}
              >
                {paises.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='cdDepResidenciaTutor'
              label='Departamento de residencia'
              rules={rules['cdDepResidenciaTutor'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  departamentosRes.fetching ? <Spin size='small' /> : null
                }
                onChange={onChangeDepartamentoResidencia}
              >
                {departamentosRes.options.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdMunResidenciaTutor'
              label='Ciudad/Municipio de Residencia'
              rules={rules['cdMunResidenciaTutor'] || ''}
            >
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  municipiosRes.fetching ? <Spin size='small' /> : null
                }
              >
                {municipiosRes.options.map((item, i) => (
                  <Select.Option key={i} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='cdSexoTutor'
              label='Sexo'
              rules={rules['cdSexoTutor'] || ''}
            >
              <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                {sexo.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='telefonoTutor'
              label='Teléfono'
              rules={rules['telefonoTutor'] || ''}
            >
              <Input minLength={7} maxLength={20} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='celularTutor'
              label='Número celular'
              rules={rules['celularTutor'] || ''}
            >
              <Input minLength={7} maxLength={20} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='emailTutor'
              label='Correo electrónico'
              rules={rules['emailTutor'] || ''}
            >
              <Input minLength={7} maxLength={200} />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const { snMenorEdad, onChangeMenorEdad } = this.props;
    return (
      <div className='content-container general-style'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                  <Col span={12}>
                    <Space>¿Es Menor de edad?</Space>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='snMenorEdad'
                      valuePropName='checked'
                      noStyle
                    >
                      <Switch
                        checkedChildren='Si'
                        unCheckedChildren='No'
                        onChange={onChangeMenorEdad}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          {snMenorEdad && (
            <>
              <Divider orientation='left'>
                Especifique los datos del tutor o representante legal
              </Divider>
              {this.createTutorForm()}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Tutor;
