import axios from 'axios'
import { middleWare } from '../../middleware'
import queryString from 'query-string'

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN

export const listarProveedores = (authtoken) => {
  return middleWare(axios({
    url: `${baseUrl}/api/listar-proveedores`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarFichaVigente = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  if (filters.resultado) {
    delete filters.resultado
  }
  return middleWare(axios({
    url: `${baseUrl}/api/documentos/ficha-vigente?${queryString.stringify(filters)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarFichaProximaVencer = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  if (filters.resultado) {
    delete filters.resultado
  }
  return middleWare(axios({
    url: `${baseUrl}/api/documentos/ficha-proximo-vencer?${queryString.stringify(filters)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const fichaVigenteExcel = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  if (filters.resultado) {
    delete filters.resultado
  }
  return middleWare(axios({
    url: `${baseUrl}/api/documentos/ficha-vigente/exportar/xlsx?${queryString.stringify(filters)}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const fichaProximaVencerExcel = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  if (filters.resultado) {
    delete filters.resultado
  }
  return middleWare(axios({
    url: `${baseUrl}/api/documentos/ficha-proximo-vencer/exportar/xlsx?${queryString.stringify(filters)}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

const formatoFechas = (filtros) => {
  let empty = {}
  Object.keys(filtros).forEach(nombre => {
    if (nombre !== 'resultado') {
      if ((nombre.includes("Desde") || nombre.includes("Hasta")) && (typeof filtros[nombre] === 'object')) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD')
      } else {
        if (filtros[nombre]) {
          empty[nombre] = filtros[nombre]
        }
      }
    }
  });
  return empty
}