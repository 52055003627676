import {
  Button,
  Card,
  DatePicker,
  Form,
  InputNumber,
  Spin,
  Table,
  notification,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import {
  listarPagadoresCupos,
  listarProveedoresCupos,
} from '../../../../../services/fondeador/operationServices';
import {
  listarPagadoresDelProveedor,
  listarProveedoresDelPagador,
} from '../../../../../services/fondeador/walletService';
import { COMPANY_SELECT_MESSAGE } from '../../../../../utilities/messages';
import {
  currencyFormat,
  dateFormat,
  sorterText,
} from '../../../../../utilities/textTools';
import PaymentFilterForm from '../paymentsFilterForm/paymentFilterForm';

const initialState = {
  filtros: {
    feRecaudo: moment(),
    vrRecaudo: '',
    observacion: '',
  },
  loading: false,
  scEntidad: '',
  tipoEntidad: 'PAG',
  nombreEntidad: '',
  entidadSelect: {
    fetching: false,
    options: [],
  },
  resultado: [],
  companias: [],
};

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {}
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        {dataIndex === 'feRecaudo' ? (
          <DatePicker
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            allowClear={false}
          />
        ) : dataIndex === 'observacion' ? (
          <TextArea
            rows={4}
            placeholder='Observaciones...'
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            allowClear={false}
          />
        ) : (
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            min={0}
            max={10000000000}
            style={{ width: '100%' }}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{
          paDDingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

class PaymentWallet extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.columnas = [
      {
        title: 'NIT',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.nit, b.nit),
        showSorterTooltip: false,
      },
      {
        title: 'RAZÓN SOCIAL',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.razonSocial, b.razonSocial),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR RECAUDO',
        dataIndex: 'vrRecaudo',
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
        editable: true,
      },
      {
        title: 'FECHA DE RECAUDO',
        dataIndex: 'feRecaudo',
        render: (value) =>
          moment.isMoment(value) ? (
            <span>{value.format('YYYY-MM-DD')}</span>
          ) : (
            <span>{dateFormat(value)}</span>
          ),
        showSorterTooltip: false,
        editable: true,
      },
      {
        title: 'OBSERVACIÓN',
        dataIndex: 'observacion',
        render: (value) => <span>{value}</span>,
        showSorterTooltip: false,
        editable: true,
      },
    ];
  }

  handleOnSearchEntidad = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { entidadSelect, tipoEntidad } = this.state;

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          fetching: true,
        },
      });

      try {
        let response = null;
        if (tipoEntidad === 'PAG') {
          response = await listarPagadoresCupos(this.props.authToken, {
            nombre: nombre,
          });
        } else if (tipoEntidad === 'PRO') {
          response = await listarProveedoresCupos(this.props.authToken, {
            nombre: nombre,
          });
        }
        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnChangeEntidad = async (e) => {
    this.state.scEntidad = e;
    this.state.resultado = initialState.resultado;

    const { entidadSelect, tipoEntidad } = this.state;

    this.setState({
      ...this.state,
      entidadSelect: {
        ...entidadSelect,
        fetching: true,
      },
    });

    try {
      let response = null;
      if (tipoEntidad === 'PAG') {
        response = await listarPagadoresCupos(this.props.authToken, {
          limit: 50,
        });
      } else if (tipoEntidad === 'PRO') {
        response = await listarProveedoresCupos(this.props.authToken, {
          limit: 50,
        });
      }
      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleOnChangeTipoEntidad = async (e) => {
    const { entidadSelect } = this.state;

    let response = null;
    if (e === 'PAG') {
      response = await listarPagadoresCupos(this.props.authToken, {
        limit: 50,
      });
    } else if (e === 'PRO') {
      response = await listarProveedoresCupos(this.props.authToken, {
        limit: 50,
      });
    }
    this.setState({
      ...this.state,
      loading: false,
      tipoEntidad: e,
      entidadSelect: {
        ...entidadSelect,
        options: response.data,
        fetching: false,
      },
    });
  };

  handleSubmit = async () => {
    this.getData();
  };

  getData = async () => {
    const { scEntidad, tipoEntidad } = this.state;
    if (this.props.company.company === '0') {
      notification.warn({
        message: 'Cuidado',
        description: COMPANY_SELECT_MESSAGE,
      });
      return;
    }

    if (!scEntidad) {
      this.setState({
        ...this.state,
        loading: false,
      });
      return;
    }
    try {
      this.setState({
        ...this.state,
        loading: true,
      });
      let response;
      if (tipoEntidad === 'PAG') {
        response = await listarProveedoresDelPagador(
          this.props.authToken,
          scEntidad
        );
      } else if (tipoEntidad === 'PRO') {
        response = await listarPagadoresDelProveedor(
          this.props.authToken,
          scEntidad
        );
      }
      let companias = response.data.map((compania) => {
        let nuevaCompania = { ...compania };
        nuevaCompania.scCompaniaObjetivo = nuevaCompania.scPersona;
        delete nuevaCompania.scPersona;
        return {
          ...nuevaCompania,
          vrRecaudo: '',
          feRecaudo: '',
          observacion: '',
        };
      });
      this.setState({
        ...this.state,
        companias: companias,
        loading: false,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidUpdate(props) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...this.state,
        resultado: initialState.resultado,
      });
    }
  }

  handleSave = (row) => {
    const newData = [...this.state.companias];
    const index = newData.findIndex(
      (item) => row.scCompaniaObjetivo === item.scCompaniaObjetivo
    );
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({
      ...this.state,
      companias: newData,
    });
  };

  render() {
    const { loading, entidadSelect, tipoEntidad, scEntidad } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columns = this.columnas.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <div className='support-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={loading}>
                <PaymentFilterForm
                  companyId={this.props.company.company}
                  handleSubmit={this.handleSubmit}
                  scEntidad={scEntidad}
                  tipoEntidad={tipoEntidad}
                  entidadSelect={entidadSelect}
                  handleOnSearchEntidad={this.handleOnSearchEntidad}
                  handleOnChangeEntidad={this.handleOnChangeEntidad}
                  handleOnChangeTipoEntidad={this.handleOnChangeTipoEntidad}
                />
                <div className='table-container'>
                  <Table
                    rowKey={'scCompaniaObjetivo'}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={this.state.companias}
                    columns={columns}
                  />
                </div>
                <br />
                <Button
                  type='primary'
                  style={{
                    marginBottom: 16,
                  }}
                  onClick={() =>
                    this.props.onCalcular(
                      this.state.companias,
                      this.state.scEntidad,
                      this.state.tipoEntidad
                    )
                  }
                >
                  Calcular
                </Button>
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(PaymentWallet);
