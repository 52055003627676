import React, { Component } from 'react';
import { Spin, Modal, notification } from 'antd';
import {
  editarCuentaProveedor,
  listaBancos,
  listaMunicipios,
} from '../../../../../../services/admin/suppliersService';
import BankAccountForm from '../bankAccountForm/bankAccountForm';
import { isEqualsToObject } from '../../../../../../utilities/compare';

const MESSAGE_TITLE = 'Cuentas bancarias';

const initialState = {
  loading: false,
  bancosSelect: {
    fetching: false,
    options: [],
  },
  ciudadesSelect: {
    fetching: false,
    options: [],
  },
};

class BankAccountEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnBancosSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { bancosSelect } = this.state;

      this.setState({
        ...this.state,
        bancosSelect: {
          ...bancosSelect,
          fetching: true,
        },
      });

      try {
        const response = await listaBancos(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          bancosSelect: {
            ...bancosSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          bancosSelect: {
            ...bancosSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnCiudadesSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { ciudadesSelect } = this.state;

      this.setState({
        ...this.state,
        ciudadesSelect: {
          ...ciudadesSelect,
          fetching: true,
        },
      });

      try {
        const response = await listaMunicipios(this.props.authToken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: '57',
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesSelect: {
            ...ciudadesSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesSelect: {
            ...ciudadesSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnFinish = async (formValue) => {
    const { onFinish, scPersona, authToken, data } = this.props;
    this.setState({ ...this.state, loading: true });
    try {
      const response = await editarCuentaProveedor(
        authToken,
        scPersona,
        data.scCuenta,
        formValue
      );

      notification.success({
        message: MESSAGE_TITLE,
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        visible: false,
        loading: false,
      });

      onFinish({ ...response.data });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(props, state) {
    if (!isEqualsToObject(props.data, this.props.data)) {
      this.load();
    }
  }

  load = async () => {
    const { data, onClose } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    this.setState({
      ...this.state,
      ciudadesSelect: {
        options: [
          { codigo: data.cdMunicipio, descripcion: data.nombreMunicipio },
        ],
        fetching: false,
      },

      bancosSelect: {
        options: [{ codigo: data.cdBanco, descripcion: data.nombreBanco }],
        fetching: false,
      },
      loading: false,
    });
  };

  render() {
    const { loading, bancosSelect, ciudadesSelect } = this.state;
    const { visible, onClose, data } = this.props;

    return (
      <Modal
        title='Editar cuenta'
        visible={visible}
        className='modal-style'
        footer={null}
        onCancel={onClose}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <BankAccountForm
            edit={true}
            fileRequired={false}
            data={data}
            reset={visible}
            bancosSelect={bancosSelect}
            ciudadesSelect={ciudadesSelect}
            onBancosSearch={this.handleOnBancosSearch}
            onCiudadesSearch={this.handleOnCiudadesSearch}
            onFinish={this.handleOnFinish}
          />
        </Spin>
      </Modal>
    );
  }
}

export default BankAccountEditModal;
