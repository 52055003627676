import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Card,
  Checkbox,
} from 'antd';
import {
  FileExcelOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileOutlined,
} from '@ant-design/icons';
import {
  listarProveedores,
  listarPagadores,
  listarFondeadores,
  listarConveniosPagador,
  conveniosPagadorExcel,
  listarConveniosFondeador,
  conveniosFondeadorExcel,
  listarConveniosProveedor,
  conveniosProveedorExcel,
} from '../../../../../services/admin/history/agreements';
import '../reports.scss';
import { connect } from 'react-redux';
import { REPORTS_DATES_REQUIRED_ERROR } from '../../../../../utilities/messages';
import Total from '../../../../proveedor/reports/total';

const { Option } = Select;

let intColumnas = 1;

const initialState = {
  scProveedorOptions: [],
  scFondeadorOptions: [],
  scPagadorOptions: [],
  loading: false,
  pagadores: {
    currentPage: 1,
    total: 0,
    limit: 0,
    offset: 10,
    resultado: [],
    fechaDesde: '',
    fechaHasta: '',
    scPagador: '',
    nombrePersonaModifica: '',
  },
  fondeadores: {
    currentPage: 1,
    total: 0,
    limit: 0,
    offset: 10,
    resultado: [],
    scPagador: '',
    scFondeador: '',
    fechaDesde: '',
    fechaHasta: '',
    nombrePersonaModifica: '',
  },
  proveedores: {
    currentPage: 1,
    total: 0,
    limit: 0,
    offset: 10,
    resultado: [],
    scProveedor: '',
    scPagador: '',
    fechaDesde: '',
    fechaHasta: '',
    nombrePersonaModifica: '',
  },
  fetching: false,
  sumatorias: {},
};

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.formRef = React.createRef();
  }

  sorter = (a, b) =>
    isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b;

  columnas = {
    pagadores: [
      {
        title: 'FECHA HISTORICO',
        dataIndex: 'feHistorico',
        sorter: (a, b) => a.feHistorico - b.feHistorico,
        showSorterTooltip: false,
      },
      {
        title: 'PERSONA QUE MODIFICA',
        dataIndex: 'nombrePersonaModifica',
        sorter: (a, b) =>
          this.sorter(a.nombrePersonaModifica, b.nombrePersonaModifica),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO DE CONVENIO',
        dataIndex: 'dsTipoConvenio',
        sorter: (a, b) => this.sorter(a.dsTipoConvenio, b.dsTipoConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO ID',
        dataIndex: 'dsPagadorTipoId',
        sorter: (a, b) => this.sorter(a.dsPagadorTipoId, b.dsPagadorTipoId),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO ID',
        dataIndex: 'dsPagadorTipoId',
        sorter: (a, b) => this.sorter(a.dsPagadorTipoId, b.dsPagadorTipoId),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACION',
        dataIndex: 'pagadorId',
        sorter: (a, b) => this.sorter(a.pagadorId, b.pagadorId),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => this.sorter(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO DE BENEFICIO',
        dataIndex: 'dsTipoBeneficioPg',
        sorter: (a, b) => this.sorter(a.dsTipoBeneficioPg, b.dsTipoBeneficioPg),
        showSorterTooltip: false,
      },
      {
        title: 'TASA PAGADOR',
        dataIndex: 'poBeneficioPagador',
        sorter: (a, b) =>
          this.sorter(a.poBeneficioPagador, b.poBeneficioPagador),
        showSorterTooltip: false,
      },
    ],
    fondeadores: [
      {
        title: 'FECHA HISTORICO',
        dataIndex: 'feHistorico',
        sorter: (a, b) => a.feHistorico - b.feHistorico,
        showSorterTooltip: false,
      },
      {
        title: 'PERSONA QUE MODIFICA',
        dataIndex: 'nombrePersonaModifica',
        sorter: (a, b) =>
          this.sorter(a.nombrePersonaModifica, b.nombrePersonaModifica),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO ID',
        dataIndex: 'dsFondeadorTipoId',
        sorter: (a, b) => this.sorter(a.dsFondeadorTipoId, b.dsFondeadorTipoId),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'fondeadorId',
        sorter: (a, b) => this.sorter(a.fondeadorId, b.fondeadorId),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => this.sorter(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoRegistro',
        sorter: (a, b) => this.sorter(a.dsEstadoRegistro, b.dsEstadoRegistro),
        showSorterTooltip: false,
      },
    ],
    proveedores: [
      {
        title: 'FECHA HISTORICO',
        dataIndex: 'feHistorico',
        sorter: (a, b) => a.feHistorico - b.feHistorico,
        showSorterTooltip: false,
      },
      {
        title: 'PERSONA QUE MODIFICA',
        dataIndex: 'nombrePersonaModifica',
        sorter: (a, b) =>
          this.sorter(a.nombrePersonaModifica, b.nombrePersonaModifica),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO ID',
        dataIndex: 'dsProveedorTipoId',
        sorter: (a, b) => this.sorter(a.dsProveedorTipoId, b.dsProveedorTipoId),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'proveedorId',
        sorter: (a, b) => this.sorter(a.proveedorId, b.proveedorId),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'proveedorNombre',
        sorter: (a, b) => this.sorter(a.proveedorNombre, b.proveedorNombre),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoRegistro',
        sorter: (a, b) => this.sorter(a.dsEstadoRegistro, b.dsEstadoRegistro),
        showSorterTooltip: false,
      },
    ],
  };

  formulario = {
    pagadores: [
      [
        {
          nombre: 'fechaDesde',
          label: '* DESDE',
          tipo: 'date',
          required: true,
        },
        {
          nombre: 'fechaHasta',
          label: '* HASTA',
          tipo: 'date',
          required: true,
        },
      ],
      [
        {
          nombre: 'scPagador',
          label: '* PAGADOR',
          required: true,
          tipo: 'select',
          selectType: 'scPagadorOptions',
        },
        {
          nombre: 'nombrePersonaModifica',
          label: 'NOMBRE DE PERSONA QUE MODIFICA',
          tipo: 'string',
        },
      ],
    ],
    fondeadores: [
      [
        {
          nombre: 'fechaDesde',
          label: '* DESDE',
          tipo: 'date',
          required: true,
        },
        {
          nombre: 'fechaHasta',
          label: '* HASTA',
          tipo: 'date',
          required: true,
        },
      ],
      [
        {
          nombre: 'scFondeador',
          label: '* FONDEADOR',
          tipo: 'select',
          selectType: 'scFondeadorOptions',
          required: true,
        },
        {
          nombre: 'scPagador',
          label: 'PAGADOR',
          tipo: 'select',
          selectType: 'scPagadorOptions',
        },
      ],
      [
        {
          nombre: 'nombrePersonaModifica',
          label: 'NOMBRE DE PERSONA QUE MODIFICA',
          tipo: 'string',
        },
      ],
    ],
    proveedores: [
      [
        {
          nombre: 'fechaDesde',
          label: '* DESDE',
          tipo: 'date',
          required: true,
        },
        {
          nombre: 'fechaHasta',
          label: '* HASTA',
          tipo: 'date',
          required: true,
        },
      ],
      [
        {
          nombre: 'scProveedor',
          label: '* PROVEEDOR',
          tipo: 'select',
          selectType: 'scProveedorOptions',
          required: true,
        },
        {
          nombre: 'scPagador',
          label: 'PAGADOR',
          tipo: 'select',
          selectType: 'scPagadorOptions',
        },
      ],
      [
        {
          nombre: 'nombrePersonaModifica',
          label: 'NOMBRE DE PERSONA QUE MODIFICA',
          tipo: 'string',
        },
      ],
    ],
  };

  isBackButtonClicked = false;

  componentDidMount() {
    this.clearFields();
  }

  formRender = () => {
    const { type } = this.props;
    let formulario = '';
    return (formulario = (
      <Form
        name='content_form'
        ref={this.formRef}
        layout='vertical'
        className='filters-container'
      >
        {this.formulario[type].map((fila, key) => {
          if (fila.length === 1) {
            return (
              <Row key={key} gutter={16}>
                <Col span={24}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row key={key} gutter={16}>
                <Col span={12}>
                  <Form.Item name={fila[0].nombre} label={fila[0].label}>
                    {this.inputType(fila[0])}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={fila[1].nombre} label={fila[1].label}>
                    {this.inputType(fila[1])}
                  </Form.Item>
                </Col>
              </Row>
            );
          }
        })}
        <Row justify='left' gutter={16}>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                className='form-btn btn-radius'
                type='primary'
                onClick={() => this.getData(true)}
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                className='form-btn secundary btn-radius'
                type='primary'
                onClick={() => this.clearFields()}
              >
                Limpiar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ));
  };

  clearFields = () => {
    const { type } = this.props;
    this.setState({
      ...this.state,
      pagadores: {
        currentPage: 1,
        total: 0,
        limit: 0,
        offset: 10,
        resultado: [],
        fechaDesde: '',
        fechaHasta: '',
        scPagador: '',
        nombrePersonaModifica: '',
      },
      fondeadores: {
        currentPage: 1,
        total: 0,
        limit: 0,
        offset: 10,
        resultado: [],
        scPagador: '',
        scFondeador: '',
        fechaDesde: '',
        fechaHasta: '',
        nombrePersonaModifica: '',
      },
      proveedores: {
        currentPage: 1,
        total: 0,
        limit: 0,
        offset: 10,
        resultado: [],
        scProveedor: '',
        scPagador: '',
        fechaDesde: '',
        fechaHasta: '',
        nombrePersonaModifica: '',
      },
    });
    switch (type) {
      case 'pagadores':
        this.formRef.current.setFieldsValue({
          fechaDesde: '',
          fechaHasta: '',
          scPagador: '',
          nombrePersonaModifica: '',
        });
        break;
      case 'fondeadores':
        this.formRef.current.setFieldsValue({
          fechaDesde: '',
          fechaHasta: '',
          scPagador: '',
          scFondeador: '',
          nombrePersonaModifica: '',
        });
        break;
      case 'proveedores':
        this.formRef.current.setFieldsValue({
          fechaDesde: '',
          fechaHasta: '',
          scPagador: '',
          scProveedor: '',
          nombrePersonaModifica: '',
        });
        break;
    }
  };

  inputType = (campo) => {
    const { type } = this.props;
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(e) => this.handleTextChange(e, stateName, type)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state[type][stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName, type)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state[type][stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={this.state.fetching ? <Spin size='small' /> : null}
          >
            {this.state[campo.selectType].map((d) => (
              <Option key={d.key} value={d.codigo}>
                {d.nombre ? d.nombre : d.descripcion}
              </Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  };

  handleOnChange = (pagination, filters, sorter, extra) => {
    if (this.state.currentPage != pagination.current) {
      this.state[this.props.type].offset =
        pagination.pageSize * (pagination.current - 1);
      this.state[this.props.type].currentPage = pagination.current;
    }

    if ((this.state[this.props.type].limit || 10) != pagination.pageSize) {
      this.state[this.props.type].limit = pagination.pageSize;
      this.state[this.props.type].offset = 0;
      this.state[this.props.type].currentPage = '1';
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.state[this.props.type].sort =
        order == 'descend' ? `-${field}` : field;
    }
    this.getData();
  };

  handleTextChange = (e, campo, type) => {
    this.state[type][campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo, type) => {
    this.state[type][campo] = moment;
  };

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      let filters = {};
      switch (nombre) {
        case 'scPagador':
          filters = { nombre: value };
          listarPagadores(this.props.authToken, filters).then((response) => {
            if (Array.isArray(response.data)) {
              this.setState({
                ...this.state,
                scPagadorOptions: response.data.map((item) => {
                  return {
                    key: item.codigo,
                    nombre: item.descripcion,
                  };
                }),
                fetching: false,
              });
            }
          });
          break;
        case 'scProveedor':
          filters = { nombre: value };
          listarProveedores(this.props.authToken, filters).then((response) => {
            if (Array.isArray(response.data)) {
              this.setState({
                ...this.state,
                scProveedorOptions: response.data.map((item) => {
                  return {
                    key: item.codigo,
                    nombre: item.descripcion,
                  };
                }),
                fetching: false,
              });
            }
          });
          break;
        case 'scFondeador':
          filters = { nombre: value };
          listarFondeadores(this.props.authToken, filters).then((response) => {
            if (Array.isArray(response.data)) {
              this.setState({
                ...this.state,
                scFondeadorOptions: response.data.map((item) => {
                  return {
                    key: item.codigo,
                    nombre: item.descripcion,
                  };
                }),
                fetching: false,
              });
            }
          });
          break;
      }
    } else {
      this.setState({
        ...this.state,
        scProveedorOptions: [],
      });
    }
  };

  handleSelectChange = (value, stateName) => {
    this.setState({
      ...this.state,
      [this.props.type]: {
        ...this.state[this.props.type],
        [stateName]: value,
      },
    });
  };

  getData = (resetPagination) => {
    if (resetPagination) {
      this.state[this.props.type].offset = 0;
      this.state[this.props.type].currentPage = 1;
    }
    switch (this.props.type) {
      case 'pagadores':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          listarConveniosPagador(
            this.props.authToken,
            this.state[this.props.type]
          ).then((response) => {
            this.setResponse(response);
          });
        }
        break;
      case 'fondeadores':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          listarConveniosFondeador(
            this.props.authToken,
            this.state[this.props.type]
          ).then((response) => {
            this.setResponse(response);
          });
        }
        break;
      case 'proveedores':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          listarConveniosProveedor(
            this.props.authToken,
            this.state[this.props.type]
          ).then((response) => {
            this.setResponse(response);
          });
        }
        break;
    }
  };

  exportExcel = () => {
    const { type } = this.props;
    switch (type) {
      case 'pagadores':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          conveniosPagadorExcel(this.props.authToken, this.state[type]).then(
            (response) => {
              this.setExcel(response);
            }
          );
        }
        break;
      case 'fondeadores':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          conveniosFondeadorExcel(this.props.authToken, this.state[type]).then(
            (response) => {
              this.setExcel(response);
            }
          );
        }
        break;
      case 'proveedores':
        if (this.validateForm()) {
          this.setState({
            ...this.state,
            loading: true,
          });
          conveniosProveedorExcel(this.props.authToken, this.state[type]).then(
            (response) => {
              this.setExcel(response);
            }
          );
        }
        break;
    }
  };

  setExcel = (response) => {
    const type = this.props.type;
    const url = window.URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ` Reporte ${type}.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.setState({
      ...this.state,
      loading: false,
    });
  };

  setResponse = (response) => {
    console.log(response.data);
    this.setState({
      ...this.state,
      [this.props.type]: {
        ...this.state[this.props.type],
        resultado: response.data,
        total: response.metadata.count,
      },
      loading: false,
      sumatorias: response.metadata.sumatorias,
    });
  };

  validateForm = () => {
    let filtros = this.state[this.props.type];
    let validationResult = true;
    let formulario = this.formulario;
    let nombres = [];
    let errores = [];
    Object.keys(filtros).forEach((nombre) => {
      if (
        formulario[this.props.type].find((x) =>
          x.find((y) => y.nombre === nombre && y.required === true)
        )
      ) {
        if (filtros[nombre] === '' || filtros[nombre] === null) {
          nombres.push(nombre);
          validationResult = false;
        }
      }
    });
    if (!validationResult) {
      formulario[this.props.type].forEach((formItem) => {
        formItem.forEach((key) => {
          if (nombres.includes(key.nombre)) {
            errores.push(key.label.replace('* ', ''));
          }
        });
      });
      if (errores.length > 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar los valores de los campos ${errores.reduce(
            (error, acum) => `${acum}, ${error}`
          )} para consultar este reporte`,
          duration: 4,
        });
      }
      if (errores.length == 1) {
        notification.error({
          message: 'Algunos campos son obligatorios',
          description: `Error: Debes ingresar el valor del campo ${errores[0]} para consultar este reporte`,
          duration: 4,
        });
      }
    }
    return validationResult;
  };

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  render() {
    const { type } = this.props;
    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <div className='header-container'>
            <p className='title'>Búsqueda</p>
            {this.formRender()}
          </div>
          <div className='table-container'>
            <Row gutter={30} className='bill-selection' justify='end'>
              <Col xl={{ span: 24 / intColumnas }} className='export'>
                <span className='button'>
                  <Button
                    size='medium'
                    className='btn-excel'
                    title='Exportar a excel'
                    onClick={this.exportExcel}
                    icon={<FileExcelOutlined />}
                  ></Button>
                </span>
              </Col>
            </Row>
            <Divider />
            <Table
              pagination={{
                defaultPageSize: 10,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} registros`,
                current: this.state[this.props.type].currentPage,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30'],
                total: this.state[type].total,
              }}
              onChange={this.handleOnChange}
              columns={this.columnas[type]}
              footer={() => <Total sumatorias={this.state.sumatorias} />}
              dataSource={this.state[type].resultado}
            ></Table>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Content);
