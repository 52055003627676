import { Button, Col, DatePicker, Divider, Form, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { BUSQUEDA_TITLE, DATE_FORMAT, FACTURACION_GENERAR } from '../../../../utilities/messages';
import moment from 'moment';

const initialValue = {
  feFacturacion:moment(),
};

const SendToCadenaFiltreForm = ({
  sendToCadena
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  return (
    <div className='header-container'>
    <Divider/>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={sendToCadena}
        initialValues={initialValue}
      >
   <Row justify='center' gutter={[16,16]}>
          <Col span={8}>
            <Form.Item>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Facturar Electrónicamente
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SendToCadenaFiltreForm;
