import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';


const baseUrl = process.env.REACT_APP_SERVICES_URL_VINCULACION;

export const buscarTipoFicha = (authToken,scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/previnculacion/buscarTipoFicha/${scCompania}`,
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json'
        "Authorization": `Bearer ${authToken}`
      },
    })
  );
};

export const buscarGruposEmp = () => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/previnculacion/grupos-empresariales`,
      method: 'GET',
    })
  );
};

export const buscarPagadores = () => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/previnculacion/listar-pagadores`,
      method: 'GET',
    })
  );
};

export const buscarTiposActividades = (filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/previnculacion/listar-actividades-economicas?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
    })
  );
};

export const buscarOperacionesLey = (authToken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/medios/buscarOperacionesLey`,
      method:'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      data:null
    })
  )
};

export const grabarDatosOperacionesLey = (authToken,dataMedio) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/medios/actualizarOperacionesLey`,
      method:'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}` 
    },
    data:dataMedio
    })
  )
}








