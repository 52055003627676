import React, { Component } from 'react';
import { Button, Spin, Modal, notification } from 'antd';
import {
  crearCuentaProveedor,
  listaBancos,
  listaMunicipios,
} from '../../../../../../services/admin/suppliersService';
import BankAccountForm, {
  initialFormValue,
} from '../bankAccountForm/bankAccountForm';

const MESSAGE_TITLE = 'Cuentas bancarias';

const initialState = {
  visible: false,
  loading: false,
  bancosSelect: {
    fetching: false,
    options: [],
  },
  ciudadesSelect: {
    fetching: false,
    options: [],
  },
};

class BankAccountNewModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnOpenModal = () => {
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  handleOnClose = () => {
    this.setState({
      ...this.state,
      visible: false,
    });
  };

  handleOnBancosSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { bancosSelect } = this.state;

      this.setState({
        ...this.state,
        bancosSelect: {
          ...bancosSelect,
          fetching: true,
        },
      });

      try {
        const response = await listaBancos(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          bancosSelect: {
            ...bancosSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          bancosSelect: {
            ...bancosSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnCiudadesSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { ciudadesSelect } = this.state;

      this.setState({
        ...this.state,
        ciudadesSelect: {
          ...ciudadesSelect,
          fetching: true,
        },
      });

      try {
        const response = await listaMunicipios(this.props.authToken, {
          nombre: nombre,
          conDepartamento: true,
          cdPais: '57',
          limit: 30,
        });
        this.setState({
          ...this.state,
          ciudadesSelect: {
            ...ciudadesSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          ciudadesSelect: {
            ...ciudadesSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnFinish = async (formValue) => {
    const { onFinish, scPersona, authToken } = this.props;
    this.setState({ ...this.state, loading: true });

    try {
      const response = await crearCuentaProveedor(
        authToken,
        scPersona,
        formValue
      );

      notification.success({
        message: MESSAGE_TITLE,
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        visible: false,
        loading: false,
      });

      onFinish({ ...formValue, scCuenta: response.location });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  render() {
    const { visible, loading, bancosSelect, ciudadesSelect } = this.state;

    return (
      <>
        <Button
          size='small'
          className='btn-agregar'
          onClick={this.handleOnOpenModal}
        >
          + Agregar nueva cuenta
        </Button>
        {this.state.visible && (
          <Modal
            title='Nueva cuenta'
            visible={visible}
            className='modal-style'
            footer={null}
            onCancel={this.handleOnClose}
          >
            <Spin tip='Cargando...' spinning={loading}>
              <BankAccountForm
                fileRequired={true}
                data={initialFormValue}
                reset={visible}
                bancosSelect={bancosSelect}
                ciudadesSelect={ciudadesSelect}
                onBancosSearch={this.handleOnBancosSearch}
                onCiudadesSearch={this.handleOnCiudadesSearch}
                onFinish={this.handleOnFinish}
              />
            </Spin>
          </Modal>
        )}
      </>
    );
  }
}

export default BankAccountNewModal;
