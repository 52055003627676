import axios from 'axios';
import { middleWare } from '../../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarOferta = (authtoken, scProveedor, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/ofertas/proveedores/${scProveedor}/listar-Oferta-Compra?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFondeadores = (authtoken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/ofertas/proveedores/${scProveedor}/listar-pre-oferta`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaOfertasPagador = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  filters.sort = filters.sort === '' ? '+totalOfertas' : filters.sort;
  return middleWare(
    axios({
      url: `${baseUrl}/api/ofertas/ofertas-dia-proveedores?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const exportarExcelOfertasPagador = (authtoken, filtros) => {
  filtros = formatoFechas(filtros);
  filtros.sort = filtros.sort === '' ? '+totalOfertas' : filtros.sort;
  return middleWare(
    axios({
      url: `${baseUrl}/api/ofertas/ofertas-dia-proveedores/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const preofertasExcel = (
  authToken,
  scProveedor,
  scFondeador,
  scGrupo
) => {
  var filter = '';
  if (scGrupo !== 'all') {
    filter = `?scGrupo=${scGrupo}`;
  }
  return axios({
    url: `${baseUrl}/api/ofertas/listarOfertaCompraPorProveedor/exportar/xlsx/${scProveedor}/${scFondeador}${filter}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

export const importCufeMasivo = (authToken, scProveedor, file) => {
  const formData = new FormData();
  formData.append('file', file[0].originFileObj);

  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cufe/cargar-archivo`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: formData,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
