import React, { Component } from 'react';
import { Row, Col, Select, Button, Spin, notification, Form } from 'antd';
import { listaPagadores } from '../../../../services/admin/payersService';
import { listaConvenios } from '../../../../services/admin/agreementService';
import { ejecutarTareaCupos } from '../../../../services/admin/taskService';
import { connect } from 'react-redux';
import '../task.scss';
import {
  listarPagadores,
  listarFondeadores,
  listarGruposEmpresariales,
  listarProveedores,
} from '../../../../services/admin/reports/quotasService';
import TaskQuotaFilterForm from './taskQuotaFilterForm/taskQuotaFilterForm';
import { listarTipoCupo, listarTipoProducto } from '../../../../services/admin/operation';

const { Option } = Select;

const initialState = {
  scFondeador: '',
  scEntidad: '',
  tipoEntidad: 'PAG',
  tipoCupo:undefined,
  tipoProducto:undefined,
  loading: false,
  entidadSelect: {
    fetching: false,
    options: [],
  },
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
  tipoProductoData: [],
  tipoCupoData: [],
};

class BulkLoadTaskQuota extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.props.menuHandler('52');
    this.handleOnChangeTipoEntidad(initialState.tipoEntidad);
    this.getInitFondeadorData();
    this.getInitTipoCupo();
    this.getInitTipoProducto();
  }

  ejecutarTarea = (tarea) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    let data = {
      tipoEntidad: this.state.tipoEntidad,
      scEntidad: this.state.scEntidad,
      scFondeador: this.state.scFondeador,
      tipoCupo:this.state.tipoCupo,
      tipoProducto:this.state.tipoProducto,
      tarea: tarea,
    };

    try {
    ejecutarTareaCupos(this.props.authToken, data)
      .then((res) => {
        notification.info({
          message: 'Tarea Ejecutada',
          description: 'Se Ha Iniciado La Tarea.'
        });
      })
      .finally(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
    } catch (error) {
      this.setState({
        ...this.state,
        loading: false,
      })
    }
    setTimeout(() => {
      this.setState({
        ...this.state,
        loading: false,
      })
    }, 5000);
  };

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  getInitFondeadorData = async () => {
    const { fondeadorSelect, scEntidad } = this.state;

    this.setState({
      ...this.state,
      fondeadorSelect: {
        ...fondeadorSelect,
        fetching: true,
      },
    });

    try {
      const response = await listarFondeadores(this.props.authToken, {
        scEntidad: scEntidad,
        limit: 100,
      });
      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleOnChangeFondeador = async (e) => {
    this.state.scFondeador = e;
  };

  handleOnChangeEntidad = async (e) => {
    this.state.scEntidad = e;
  };

  handleOnChangeTipoCupo = async (e) => {
    this.state.tipoCupo = e
  };

  handleOnChangeTipoProducto = async (e) => {
    this.state.tipoProducto = e;
  };

  handleOnChangeTipoEntidad = async (e) => {
    const { entidadSelect } = this.state;

    let response = null;
    if (e === 'PAG') {
      response = await listarPagadores(this.props.authToken, {
        limit: 50,
      });
    } else if (e === 'PRO') {
      response = await listarProveedores(this.props.authToken, {
        limit: 50,
      });
    } else {
      response = await listarGruposEmpresariales(this.props.authToken, {
        limit: 50,
      });
    }
    this.setState({
      ...this.state,
      loading: false,
      tipoEntidad: e,
      entidadSelect: {
        ...entidadSelect,
        options: response.data,
        fetching: false,
      },
    });
  };

  handleOnSearchEntidad = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { entidadSelect, tipoEntidad } = this.state;

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          fetching: true,
        },
      });

      try {
        let response = null;
        if (tipoEntidad === 'PAG') {
          response = await listarPagadores(this.props.authToken, {
            nombre: nombre,
          });
        } else if (tipoEntidad === 'PRO') {
          response = await listarProveedores(this.props.authToken, {
            nombre: nombre,
          });
        } else {
          response = await listarGruposEmpresariales(this.props.authToken, {
            nombre: nombre,
          });
        }

        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  getInitTipoCupo= async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoCupoRes = await listarTipoCupo(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoCupoData: tipoCupoRes.data,
    });
  };

  getInitTipoProducto = async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoProductoRes = await listarTipoProducto(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoProductoData: tipoProductoRes.data,
    });
  };

  render() {
    return (
      <div className='content-margin task-style'>
        <div className='card-background-light card-shadow radius'>
          <div className='align-center'>
            <Spin tip='Cargando...' spinning={this.state.loading}>
              <div className='content-container'>
                <TaskQuotaFilterForm
                  handleOnSearchEntidad={this.handleOnSearchEntidad}
                  handleOnChangeEntidad={this.handleOnChangeEntidad}
                  handleOnChangeTipoEntidad={this.handleOnChangeTipoEntidad}
                  entidadSelect={this.state.entidadSelect}
                  handleOnSearchFondeador={this.handleOnSearchFondeador}
                  handleOnChangeFondeador={this.handleOnChangeFondeador}
                  fondeadorSelect={this.state.fondeadorSelect}
                  tipoCupo={this.state.tipoCupoData}
                  handleOnChangeTipoCupo={this.handleOnChangeTipoCupo}
                  tipoProducto={this.state.tipoProductoData}
                  handleOnChangeTipoProducto={this.handleOnChangeTipoProducto}
                />

                <div className='detail-container'>
                  <div className='task-list'>
                    <div className='task'>
                      <div className='task-label'>
                        <p className='task-label-text'>
                          ¿Desea que se ejecute la tarea, liberar cupo?
                        </p>
                      </div>
                      <div className='task-button'>
                        <Button
                          className='task-button-component'
                          onClick={() => {
                            this.ejecutarTarea('liberarCupo');
                          }}
                        >
                          Liberar cupo
                        </Button>
                      </div>
                    </div>
                    <div className='task'>
                      <div className='task-label'>
                        <p className='task-label-text'>
                          ¿Desea que se ejecute la tarea, actualizar liquidez?
                        </p>
                      </div>
                      <div className='task-button'>
                        <Button
                          className='task-button-component'
                          onClick={() => {
                            this.ejecutarTarea('actualizarLiquidez');
                          }}
                        >
                          Actualizar liquidez
                        </Button>
                      </div>
                    </div>
                    <div className='task'>
                      <div className='task-label'>
                        <p className='task-label-text'>
                          ¿Desea que se ejecute la tarea, actualizar cupo TAC?
                        </p>
                      </div>
                      <div className='task-button'>
                        <Button
                          className='task-button-component'
                          onClick={() => {
                            this.ejecutarTarea('actualizarCupoTAC');
                          }}
                        >
                          Actualizar cupo TAC
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};
export default connect(mapStateToProps)(BulkLoadTaskQuota);
