export const columns = {
  reports: [
    {
      title: 'NIT ',
      dataIndex: 'idCompania',
      sorter: (a, b) => a.idCompania - b.idCompania,
      showSorterTooltip: false
    },
    {
      title: 'NOMBRE',
      dataIndex: 'nombreCompania',
      sorter: (a, b) => a.nombreCompania - b.nombreCompania,
      showSorterTooltip: false
    },
    {
      title: 'INDICE GLOBAL DE RIESGO',
      dataIndex: 'indiceGlobalRiesgo',
      sorter: (a, b) => a.indiceGlobalRiesgo - b.indiceGlobalRiesgo,
      showSorterTooltip: false
    },
    {
      title: 'CLUSTER',
      dataIndex: 'clusNeuroRiesgo',
      sorter: (a, b) => a.clusNeuroRiesgo - b.clusNeuroRiesgo,
      showSorterTooltip: false
    },
    {
      title: 'CUPO FACTURACIÓN ESPERADA',
      dataIndex: 'cupoFacturacionEsperada',
      sorter: (a, b) => a.cupoFacturacionEsperada - b.cupoFacturacionEsperada,
      showSorterTooltip: false
    },
    {
      title: 'FECHA DE CORRIDA MODELO',
      dataIndex: 'feCalificacion',
      sorter: (a, b) => a.feCalificacion - b.feCalificacion,
      showSorterTooltip: false
    },
   
  ],
  
}