import { Spin, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  calcularAbonos,
  traerEntidad,
} from '../../../../services/fondeador/walletService';
import ApplyPayment from '../../adminWallet/paymentsWallet/applyPayment/applyPayment';
import './paymentWallet.scss';
import PaymentWallet from './payments/paymentWallet';

const initialState = {
  companias: [],
  currentStep: 0,
  scEntidad: '',
  tipoEntidad: 'PAG',
  dstipoEntidad: '',
  fechaRecaudo: moment(),
  entidad: [],
  abonos: [],
  loading: false,
};

class PaymentsWallet extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.props.menuHandler('72');
  }

  handlerOnCalcular = async (
    companias,
    scEntidad,
    tipoEntidad,
    fechaRecaudo,
    dstipoEntidad
  ) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    let sumaVrRecaudo = 0;
    await this.handleSearchEntidad(scEntidad);
    const companiasFiltradas = companias.reduce((resultado, compania) => {
      if (
        compania.vrRecaudo > 0 &&
        compania.feRecaudo !== '' &&
        compania.observacion !== ''
      ) {
        sumaVrRecaudo += compania.vrRecaudo;
        delete compania.id;
        delete compania.nombre;
        resultado.push(compania);
        fechaRecaudo = compania.feRecaudo;
      }

      return resultado;
    }, []);

    if (!companiasFiltradas.length) {
      notification.warning({
        message: 'Abonos no calculados',
        description:
          'No se llenaron correctamente los campos de valor recaudo, fecha recaudo y observación.',
        duration: 6,
      });
      this.setState({
        ...this.state,
        currentStep: 0,
      });
    } else {
      try {
        await this.handleCalcularAbonos(
          tipoEntidad,
          scEntidad,
          companiasFiltradas
        );

        if (tipoEntidad === 'PAG') {
          dstipoEntidad = 'Pagador';
        } else if (tipoEntidad === 'PRO') {
          dstipoEntidad = 'Proveedor';
        }

        this.setState({
          ...this.state,
          companias: companiasFiltradas,
          sumaVrRecaudo: sumaVrRecaudo,
          scEntidad: scEntidad,
          tipoEntidad: tipoEntidad,
          dstipoEntidad: dstipoEntidad,
          fechaRecaudo: fechaRecaudo,
          currentStep: 1,
        });

        this.setState({
          ...this.state,
          loading: false,
        });
      } catch (error) {
        this.setState({
          ...this.state,
          currentStep: 0,
        });
      }
    }
  };

  handleSearchEntidad = async (scEntidad) => {
    const { authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const entidadRes = await traerEntidad(authToken, scEntidad);
      this.setState({
        ...this.state,
        loading: false,
        entidad: entidadRes.data,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        entidad: [],
      });
    }
  };

  handleCalcularAbonos = async (tipoEntidad, scEntidad, companias) => {
    const { authToken } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const abonosRes = await calcularAbonos(
        authToken,
        this.props.company.company,
        tipoEntidad,
        scEntidad,
        companias
      );
      this.setState({
        ...this.state,
        loading: false,
        abonos: abonosRes.data,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        abonos: [],
      });
    }
  };

  changeStep = async (value) => {
    this.setState({
      ...this.state,
      currentStep: value,
    });
  };

  render() {
    const { currentStep, loading } = this.state;

    if (currentStep === 0) {
      return (
        <Spin tip='Cargando...' spinning={loading}>
          <PaymentWallet onCalcular={this.handlerOnCalcular} />;
        </Spin>
      );
    } else {
      return (
        <ApplyPayment
          companias={this.state.companias}
          sumaVrRecaudo={this.state.sumaVrRecaudo}
          scEntidad={this.state.scEntidad}
          tipoEntidad={this.state.tipoEntidad}
          dstipoEntidad={this.state.dstipoEntidad}
          fechaRecaudo={this.state.fechaRecaudo}
          entidad={this.state.entidad}
          abonos={this.state.abonos}
          onChangeStep={this.changeStep}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(PaymentsWallet);
