import React, { useEffect, useState } from 'react';
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button } from 'antd';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../utilities/messages';
import moment from 'moment';

const initialValue = {
  fechaRango: [moment(new Date()), moment(new Date())],
};

const SupportFilterForm = ({ companyId, handleSubmit }) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [companyId, form]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='fechaRango'
              label='Rango de fechas de factura'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un rango de fechas',
                },
              ]}
            >
              <DatePicker.RangePicker name='fechaRango' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SupportFilterForm;
