import { Col, Form, Input, Row } from 'antd';
import React from 'react';

const BasicData = ({ data }) => {
  return (
    <div className='detail-container'>
      <Form name='detail_form' layout='vertical'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label='Tipo de persona'>
              <Input
                name={'personType'}
                value={data.dsTipoId}
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Identificación'>
              <Input name={'id'} value={data.id} readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={data.cdTipoPersona === 'N' ? 'Nombres' : 'Razón social'}
            >
              <Input
                name={'razonSocial'}
                value={
                  data.cdTipoPersona === 'N' ? data.nombre : data.razonSocial
                }
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Estado'>
              <Input
                name={'state'}
                value={data.dsEstadoPersona}
                readOnly={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BasicData;
