import { Button, Card, Descriptions, Divider, Spin, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../../components/tableReport/tableReport';
import { aplicarPagos } from '../../../../../services/fondeador/walletService';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';
import '../paymentWallet.scss';

const initialState = {
  loading: false,
  companias: [],
  selectedKeys: [],
  selectAll: false,
  unselectedKeys: [],
  selectedAbonos: [],
  segundoRecaudo: false,
};

class ApplyPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      sumaVrRecaudo: props.sumaVrRecaudo,
      tipoEntidad: props.tipoEntidad,
      dstipoEntidad: props.dstipoEntidad,
      scEntidad: props.scEntidad,
      fechaRecaudo: props.fechaRecaudo,
      entidad: props.entidad,
      abonos: props.abonos,
      companias: props.companias,
    };
  }

  componentDidMount() {
    const selectedKeys = this.props.abonos
      .filter((abono) => abono.selected)
      .map((abono) => abono.scFactura);
    this.setState({
      selectedKeys: selectedKeys,
    });
  }

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;
  };

  columns = [
    {
      title: 'NIT',
      dataIndex: this.props.tipoEntidad === 'PAG' ? 'proveedorId' : 'pagadorId',
      sorter: (a, b) =>
        this.props.tipoEntidad === 'PAG'
          ? sorterText(a.proveedorId, b.proveedorId)
          : sorterText(a.pagadorId, b.pagadorId),
      showSorterTooltip: false,
    },
    {
      title: 'RAZÓN SOCIAL',
      dataIndex:
        this.props.tipoEntidad === 'PAG' ? 'proveedorNombre' : 'pagadorNombre',
      sorter: (a, b) => sorterText(a.rSocial, b.rSocial),
      showSorterTooltip: false,
    },
    {
      title: '#FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
      showSorterTooltip: false,
    },
    {
      title: 'FECHA ESPERADA DE PAGO',
      dataIndex: 'feEstimadaPago',
      sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
      showSorterTooltip: false,
    },
    {
      title: 'DÍAS MORA',
      dataIndex: 'diasMora',
      showSorterTooltip: false,
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR REAL',
      dataIndex: 'vrActual',
      sorter: (a, b) => a.vrActual - b.vrActual,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR INTERESES MORA',
      dataIndex: 'vrIntereses',
      sorter: (a, b) => a.vrIntereses - b.vrIntereses,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'SALDO ACTUAL',
      dataIndex: 'saldo',
      sorter: (a, b) => a.saldo - b.saldo,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR DE REINTEGRO',
      dataIndex: 'vrReintegro',
      sorter: (a, b) => a.vrReintegro - b.vrReintegro,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
  ];

  columnsSegRecaudo = [
    {
      title: 'NIT',
      dataIndex: 'pagadorId',
      sorter: (a, b) => sorterText(a.pagadorId, b.pagadorId),
      showSorterTooltip: false,
    },
    {
      title: 'RAZÓN SOCIAL',
      dataIndex: 'pagadorNombre',
      sorter: (a, b) => sorterText(a.rSocial, b.rSocial),
      showSorterTooltip: false,
    },
    {
      title: '#FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
      showSorterTooltip: false,
    },
    {
      title: 'FECHA ESPERADA DE PAGO',
      dataIndex: 'feEstimadaPago',
      sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
      showSorterTooltip: false,
    },
    {
      title: 'DÍAS MORA',
      dataIndex: 'diasMora',
      showSorterTooltip: false,
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR REAL',
      dataIndex: 'vrActual',
      sorter: (a, b) => a.vrActual - b.vrActual,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR INTERESES MORA',
      dataIndex: 'vrIntereses',
      sorter: (a, b) => a.vrIntereses - b.vrIntereses,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'SALDO ACTUAL',
      dataIndex: 'saldo',
      sorter: (a, b) => a.saldo - b.saldo,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR DE REINTEGRO',
      dataIndex: 'vrReintegro',
      sorter: (a, b) => a.vrReintegro - b.vrReintegro,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
  ];

  aplicarPagos = async () => {
    const { authToken } = this.props;
    const { companias } = this.state;
    this.setState({
      ...this.state,
      loading: true,
    });
    const abonosSelect = this.state.abonos.filter((abono) =>
      this.state.selectedKeys.includes(abono.scFactura)
    );
    try {
      const companiasFacturas = companias.map((compania) => {
        const abonosCompania = abonosSelect.filter(
          (abono) =>
            abono.scProveedor === compania.scCompaniaObjetivo ||
            abono.scPagador === compania.scCompaniaObjetivo
        );
        return {
          ...compania,
          facturas: abonosCompania,
        };
      });

      const response = await aplicarPagos(
        authToken,
        this.props.company.company,
        this.state.tipoEntidad,
        this.state.scEntidad,
        companiasFacturas
      );

      if (Array.isArray(response.data?.data) && response.data.data.length === 0) {

        notification.success({
          message: 'Aplicación de pago exitosa',
          description:
            response.data.message || 'La operación se completó con éxito',
          duration: 5,
        });

        this.setState({
          ...this.state,
          loading: false,
        });

        this.props.onChangeStep(0);
      } else {
        notification.success({
          message: 'Aplicación de pago exitosa',
          description:
            response.data.message ||
            'En pantalla se muestra la segunda aplicación de pagos realizada automáticamente',
          duration: 5,
        });

        const abonoReintegro = this.state.abonos.filter((abono) => 
          abono.vrReintegro > 0)

          this.setState({
            ...this.state,
            loading: false,
            abonos: response.data.data,
            selectedKeys: response.data.data,
            segundoRecaudo: true,
            sumaVrRecaudo: abonoReintegro[0].vrReintegro,
            dstipoEntidad: 'Proveedor', 
            entidad: {
              ...this.state.entidad,
              nombre: abonoReintegro[0].proveedorNombre
            } 
          });
        }
      }
      catch (error) {
      this.setState({
        ...this.state,
        loading: false,
      });
      notification.error({
        message: 'Error',
        description: 'Se ha producido un error durante la solicitud.',
        duration: 5,
      });
    }
  };

  salir = () => {
    this.props.onChangeStep(0);
  };

  render() {
    const loading = false;
    const { fechaRecaudo, selectedKeys } = this.state;
    let formattedDate = '';
    if (moment.isMoment(fechaRecaudo)) {
      formattedDate = fechaRecaudo.format('DD/MM/YYYY');
    }
    this.rowSelection = {
      type: 'checkbox',
      selectedRowKeys: selectedKeys,
      hideSelectAll: true,
      getCheckboxProps: (record) => ({
        disabled: true,
      }),
    };

    return (
      <div className='support-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={loading}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Descriptions layout='vertical' column={4}>
                    <Descriptions.Item
                      label='Fuente de pago'
                      labelStyle={{ fontWeight: 'bold' }}
                    >
                      {this.state.dstipoEntidad}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='Compañia'
                      labelStyle={{ fontWeight: 'bold' }}
                    >
                      {this.state.entidad.nombre}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='Monto recaudo'
                      labelStyle={{ fontWeight: 'bold' }}
                    >
                      {currencyFormat(this.state.sumaVrRecaudo)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='Fecha Recaudo'
                      labelStyle={{ fontWeight: 'bold' }}
                    >
                      {formattedDate}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <Divider />
                <TableReport
                  keyIndex={'scFactura'}
                  rowSelection={this.rowSelection}
                  columns={
                    this.state.segundoRecaudo
                      ? this.columnsSegRecaudo
                      : this.columns
                  }
                  data={this.state.abonos}
                  handleChange={this.handleChange}
                />
                <br />
                {!this.state.segundoRecaudo ? (
                  <Button
                    type='primary'
                    style={{
                      marginBottom: 16,
                    }}
                    onClick={this.aplicarPagos}
                  >
                    Aplicar Pago
                  </Button>
                ) : (
                  <Button
                    type='primary'
                    style={{
                      marginBottom: 16,
                    }}
                    onClick={this.salir}
                  >
                    Salir
                  </Button>
                )}
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(ApplyPayment);
