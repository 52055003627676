import axios from 'axios';
import { middleWare } from '../../middleware';
import queryString from 'query-string';
import { DATE_POST_FORMAT } from '../../../utilities/messages';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const buscarMandato = (authtoken, scMandato) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/mandato/${scMandato}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarMandatos = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/mandato/listar-mandatos?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarMandatosExcel = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/mandato/listar-mandatos/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const exportMandatoPDF = (authtoken, scMandato) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/mandato/${scMandato}/descargar`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const exportMandatoXML = (authtoken, scMandato) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/mandato/${scMandato}/document-response/descargar`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacultadesMandatos = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/facultad-mandato-de?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const grabarMandato = (authtoken, data) => {
  const formData = new FormData();
  formData.append('scCompania', data.scCompania);
  formData.append('feInicio', data.feInicio.format(DATE_POST_FORMAT));
  formData.append('feEscritura', data.feEscritura.format(DATE_POST_FORMAT));
  formData.append('dsNombresRL', data.dsNombresRL);
  formData.append('dsApellidosRL', data.dsApellidosRL);
  formData.append('cdTipoIdRL', data.cdTipoIdRL);
  formData.append('idRL', data.idRL);
  formData.append('dsCargoRL', data.dsCargoRL);
  formData.append('dsAreaRL', data.dsAreaRL);
  formData.append('dsNombresNotario', data.dsNombresNotario);
  formData.append('dsApellidosNotario', data.dsApellidosNotario);
  formData.append('dsIdNotario', data.dsIdNotario);
  formData.append('nmNotaria', data.nmNotaria);
  formData.append('cdFacultadMandatoDE', data.cdFacultadMandatoDE);
  formData.append('cdTipoMandanteDE', 'MFE');

  if (data.feVigencia && data.feVigencia !== '') {
    formData.append('feVigencia', data.feVigencia.format(DATE_POST_FORMAT));
  }

  if (data.file.length > 0) {
    formData.append('file', data.file[0].originFileObj);
  }

  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/mandato/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export const actualizarMandato = (authtoken, data, scMandato) => {
  const formData = new FormData();
  formData.append('scCompania', data.scCompania);
  formData.append('feInicio', data.feInicio.format(DATE_POST_FORMAT));
  formData.append('feEscritura', data.feEscritura.format(DATE_POST_FORMAT));
  formData.append('dsNombresRL', data.dsNombresRL);
  formData.append('dsApellidosRL', data.dsApellidosRL);
  formData.append('cdTipoIdRL', data.cdTipoIdRL);
  formData.append('idRL', data.idRL);
  formData.append('dsCargoRL', data.dsCargoRL);
  formData.append('dsAreaRL', data.dsAreaRL);
  formData.append('dsNombresNotario', data.dsNombresNotario);
  formData.append('dsApellidosNotario', data.dsApellidosNotario);
  formData.append('dsIdNotario', data.dsIdNotario);
  formData.append('nmNotaria', data.nmNotaria);
  formData.append('cdFacultadMandatoDE', data.cdFacultadMandatoDE);
  formData.append('cdTipoMandanteDE', 'MFE');

  if (data.feVigencia && data.feVigencia !== '') {
    formData.append('feVigencia', data.feVigencia.format(DATE_POST_FORMAT));
  }

  if (data.file.length > 0) {
    formData.append('file', data.file[0].originFileObj);
  }

  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/mandato/grabar/${scMandato}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export const enviarMandato = (authToken, scMandato) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/radian/mandato/${scMandato}/enviar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const listarTieneEventoDE = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tiene-eventos?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else if (
        filtros[nombre] &&
        nombre != 'currentPage' &&
        nombre != 'total'
      ) {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
