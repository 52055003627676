import React, { Component } from 'react';
import { Row, Col, Card, Divider, Spin, notification, Space } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import './ACHoperation.scss';
import { connect } from 'react-redux';
import {
  listarFondeadores,
  listarLotesFondeador,
} from '../../../../services/admin/ACHoperationService';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import TableReport from '../../../../components/tableReport/tableReport';
import AchHistoryFilterForm from './achHistoryFilterForm/achHistoryFilterForm';
import AchTransacions from './achTransactions/achTransactions';

const initialState = {
  loading: false,
  form: {
    scLoteAch: '',
    fechaDesde: '',
    fechaHasta: '',
  },
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  scLoteAchSelect: {
    scLoteACH: '',
    scFondeador: '',
  },
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
};

class ACHoperation extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'No Lote',
        dataIndex: 'scLoteACH',
        sorter: (a, b) => sorterText(a.scLoteACH, b.scLoteACH),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE CREACIÓN',
        dataIndex: 'fechaIngreso',
        sorter: (a, b) => sorterDate(a.fechaIngreso, b.fechaIngreso),
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL',
        dataIndex: 'nmTotal',
        sorter: (a, b) => a.nmTotal - b.nmTotal,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: 'DETALLE',
        render: (text, record) => (
          <FormOutlined
            title='Ver transacciones'
            onClick={() => this.getTransaccion(record)}
            className='info'
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  componentDidMount() {
    this.props.menuHandler('44');
    // this.loadFondeadores()
  }

  getTransaccion = (record) => {
    this.setState({
      ...this.state,
      scLoteAchSelect: {
        ...this.state.scLoteAchSelect,
        scLoteACH: record.scLoteACH,
      },
    });
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scFondeador: value.scFondeador,
        scLoteAch: value.scLoteAch,
        fechaDesde: value.fechaRango[0],
        fechaHasta: value.fechaRango[1],
      },
      scLoteAchSelect: {
        ...this.state.scLoteAchSelect,
        scFondeador: value.scFondeador,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });
      const filtros = this.getFiltro();
      try {
        const response = await listarLotesFondeador(
          this.props.authToken,
          filtros.scFondeador,
          filtros
        );

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha desde y fecha hasta es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleOnSearchFondeador = async (nombre) => {
    console.log('handleOnSearchFondeador', nombre);
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      scLoteAchSelect,
      fondeadorSelect,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='ACHoperation-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <AchHistoryFilterForm
              fondeadorSelect={fondeadorSelect}
              onSearchFondeador={this.handleOnSearchFondeador}
              handleSubmit={this.handleSubmit}
            />

            <div className='table-container'>
              <Row gutter={30}>
                <Col span={12}>
                  <Space className='ptable'>Lista de lotes Fondeador</Space>
                </Col>
              </Row>
              <Divider />
              <Row gutter={30}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
            {scLoteAchSelect.scLoteACH !== '' && (
              <AchTransacions
                scFondeador={scLoteAchSelect.scFondeador}
                authToken={this.props.authToken}
                scLoteAch={scLoteAchSelect.scLoteACH}
              />
            )}
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(ACHoperation);
