import { Button, Card, Col, Form, Input, notification, Row, Spin } from 'antd';
import React from 'react';
import userServices from '../../../services/userService';
import store from '../../../store/index';
import {
  compareContraseñaMessage,
  passwordMessage,
  passwordPattern,
  requiredValueMessage,
} from '../../../utilities/admin_validation_messages';
import {
  CHANGEPASS_SUBTITLE_INIT,
  CHANGEPASS_TITLE_INIT,
} from '../../../utilities/messages';
import './changepass.scss';

const initialFormValue = {
  passwordActual: '',
  nuevoPassword: '',
  nuevoPasswordConfirm: '',
};

const inutialState = {
  loading: false,
};

const rules = {
  passwordActual: [{ required: true, message: requiredValueMessage() }],
  nuevoPassword: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: passwordPattern,
      message: passwordMessage(),
    },
  ],
  nuevoPasswordConfirm: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: passwordPattern,
      message: passwordMessage(),
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value !== getFieldValue('nuevoPassword')) {
          return Promise.reject(
            new Error(compareContraseñaMessage('Nueva contraseña'))
          );
        }

        return Promise.resolve();
      },
    }),
  ],
};

class changePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...inutialState,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.menuHandler('17');
  }

  onFinish = async (form) => {
    this.setState({ loading: true });

    try {
      const response = await userServices.changePassword(
        store.getState().auth.auth.access_token,
        form
      );

      if (response.message) {
        notification.success({
          message: 'Exitoso',
          description: response.message,
          duration: 4,
        });

        this.form.current.setFieldsValue({
          ...initialFormValue,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        duration: 4,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <div className='content-margin changepass-style'>
        <Spin tip='Cargando...' spinning={loading}>
          <Card className='card-shadow card-radius default-border welcome-message '>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className='title'>{CHANGEPASS_TITLE_INIT}</div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className='subtitle'>{CHANGEPASS_SUBTITLE_INIT}</div>
              </Col>
            </Row>
          </Card>
          <Card className='card-shadow card-radius default-border welcome-message '>
            <div className='content-container'>
              <div className='form-container card-background-gray card-shadow card-radius'>
                <Form
                  layout='vertical'
                  ref={this.form}
                  initialValues={initialFormValue}
                  onFinish={this.onFinish}
                >
                  <Row gutter={16} justify='center'>
                    <Col span={12}>
                      <Form.Item
                        name='passwordActual'
                        label='Contraseña anterior'
                        rules={rules.passwordActual}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} justify='center'>
                    <Col span={12}>
                      <Form.Item
                        name='nuevoPassword'
                        label='Nueva contraseña'
                        rules={rules.nuevoPassword}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} justify='center'>
                    <Col span={12}>
                      <Form.Item
                        name='nuevoPasswordConfirm'
                        label='Confirmar nueva contraseña'
                        dependencies={['nuevoPassword']}
                        rules={rules.nuevoPasswordConfirm}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify='center'>
                    <Col span={6}>
                      <Form.Item name='submit'>
                        <Button
                          className='form-btn btn-radius'
                          type='primary'
                          htmlType='submit'
                        >
                          Cambiar contraseña
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Card>
        </Spin>
      </div>
    );
  }
}

export default changePassword;
