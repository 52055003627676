export const initialState = {
  tiposLogsOptions: [],
  nivelesLogsOptions: [],
  loading: false,
  reporte_logs: {
    fechaDesde: '',
    fechaHasta: '',
    nivel:'',
    hilo: '',
    logger: '',
    mensaje: '',
    currentPage: 1,
    total: 0,
    resultado: [],
  },
  descarga_logs: {
    cdLog:'',
    indice:'',
    currentPage: 1,
    total: 0,
    resultado: [],
  },
  fetching: false,
  sumatorias: {}
}