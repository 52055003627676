import React from 'react';
import { Row, Col, Divider, Form, Button } from 'antd';
import './configure.scss';
import '../affitoken.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setStepAction } from '../../../../store/actions/proveedor/affitokenActtions';
import equal from 'fast-deep-equal';
import { PHONE_NUMBER_INIT } from '../../../../utilities/messages';

class Configure extends React.Component {
  constructor(props) {
    super(props);

    let { nombre, email, telefono1 } = props.user;
    this.state = {
      name: nombre,
      email: email,
      phone: telefono1,
    };
  }

  componentDidUpdate(props, state) {
    if (!equal(this.props.user, props.user)) {
      let { nombre, email, telefono1 } = this.props.user;
      this.setState({
        name: nombre,
        email: email,
        phone: telefono1,
      });
    }
  }
  nextStep() {
    this.props.setStepAction(1);
  }

  render() {
    return (
      <div className='configure-style'>
        <Row className='title-container'>
          <Col span={24} className='title'>
            Configurar Affitoken
          </Col>
        </Row>
        <Divider />
        <Row className='description-container'>
          <Col span={24} className='description'>
            {`Estas iniciando el proceso de registro en el sistema de seguridad
            Affitoken. Verifica que los datos a continuación son correctos y da
            clic en enviar. En caso de no ser correctos por favor comunicarse al ${PHONE_NUMBER_INIT}`}
          </Col>
        </Row>
        <Row className='configure-form default-border card-shadow card-radius'>
          <Col span={24}>
            <Form name='configure_affitoken' layout='vertical'>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label='Nombre' rules={[{ required: true }]}>
                    <span className='data-field'>{this.state.name}</span>
                    <Divider className='form-divider' />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='Correo electrónico'
                    rules={[{ required: true }]}
                  >
                    <span className='data-field'>{this.state.email}</span>
                    <Divider className='form-divider' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label='Teléfono' rules={[{ required: true }]}>
                    <span className='data-field'>{this.state.phone}</span>
                    <Divider className='form-divider' />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className='submit-container'>
          <Col span={24}>
            <Button
              className='form-btn btn-radius'
              htmlType='submit'
              onClick={() => {
                this.nextStep();
              }}
            >
              Enviar
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
Configure.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { setStepAction })(Configure);
