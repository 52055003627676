import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import PendingPaymentsFilterForm from './pendingPaymentsFilterForm/pendingPaymentsFilterForm';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  listarPagadores,
  pendientesPago,
  pendientesPagosExcel,
} from '../../../../services/fondeador/reportServices';
import { setExcel } from '../../../../utilities/downloadTools';

const initialState = {
  form: {
    scPagador: '',
    fechaDesdeEstimadaPago: '',
    fechaHastaEstimadaPago: '',
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 7,
    },
    {
      id: 'sumaVrFactura',
      value: <span className='format-number'>{currencyFormat(0)}</span>,
      index: 'vrFactura',
      colSpan: 3,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class PendingPayments extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'CÓDIGO DE FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'REF. DOC. CONTABLE',
        dataIndex: 'cdDocumentoSAP',
        sorter: (a, b) => sorterText(a.cdDocumentoSAP, b.cdDocumentoSAP),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE PAGO',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ESPERADA DE PAGO',
        dataIndex: 'feEstimadaPago',
        sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REPORTADO',
        dataIndex: 'vrFactura',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REDUCIDO',
        dataIndex: 'vrFacturaChReducido',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFacturaChReducido - b.vrFacturaChReducido,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR NOTAS CRÉDITO',
        dataIndex: 'vrTotalNotas',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrTotalNotas - b.vrTotalNotas,
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scConvenio: value.scConvenio,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        cdFactura: value.cdFactura,
        fechaDesdeEstimadaPago: value.fechaRango[0],
        fechaHastaEstimadaPago: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await pendientesPago(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = (
              <span className='format-number'>
                {currencyFormat(response.metadata.sumatorias[res.id])}
              </span>
            );
          }

          return res;
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await pendientesPagosExcel(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );
        setExcel(response, `reporte_pagos_pendientes`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  componentDidUpdate(props, state) {
    if (this.props.company.company != props.company.company) {
      this.setState({
        ...initialState,
        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'tipoConvenio',
            colSpan: 7,
          },
          {
            id: 'sumaVrFactura',
            value: <span className='format-number'>{currencyFormat(0)}</span>,
            index: 'vrFactura',
            colSpan: 3,
          },
        ],
      });
    }
  }

  render() {
    const {
      pagadorSelect,
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <PendingPaymentsFilterForm
            companyId={this.props.company.company}
            pagadorSelect={pagadorSelect}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default PendingPayments;
