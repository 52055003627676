
import React from 'react';
import { Row, Col, Divider, Form, Button, Input, Spin, Alert } from 'antd';
import './confirm.scss'
import '../affitoken.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setStepAction, validateTempTokenAction, registerUserAction } from '../../../../store/actions/proveedor/affitokenActtions'
import equal from 'fast-deep-equal'
import history from '../../../../services/history'

class Confirm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      affitoken: this.props.affitoken,
      loading: false,
      affitokenValue: ''
    }
    this.noPasteInputRef = React.createRef();
  }
  componentDidMount() {
    this.noPasteInputRef.current.input.onpaste = (e) => {
      e.preventDefault()
      return false;
    }
  }


  componentDidUpdate(props, state) {
    if (!equal(props.affitoken, this.props.affitoken)) {
      this.setState({
        ...this.state,
        affitoken: this.props.affitoken,
        loading: false
      })

    }
  }
  componentWillUnmount() {

    this.props.setStepAction(0)
  }

  changeInput(value) {
    this.setState({
      ...this.state,
      affitokenValue: value
    })
  }

  sendAffitoken(e) {
    this.setState({
      ...this.state,
      loading: true
    })
    this.props.registerUserAction(this.props.authentication, this.props.affitoken.user, this.state.affitokenValue)
  }

  back() {
    this.props.setStepAction(0)
    history.push(`${process.env.PUBLIC_URL}/dashboard`)
  }

  render() {
    return (
      <div className="configure-style">
        <Row className="title-container">
          <Col span={24} className="title">Confirmar Affitoken</Col>
        </Row>
        <Divider />
        <Row className="description-container">
          <Col span={24} className="description">
            Ingresa la clave temporal recibida por {this.props.affitoken.user.tipoEnvioOtp}
            <br />
            <br />

          </Col>
        </Row>
        <Row className="configure-form default-border card-shadow radius-2">
          {this.state.affitoken.registerStatus ?
            <p>Clave temporal enviada</p>
            :

            (
              <Col span={24}>
                <Form
                  name="configure_affitoken"
                  layout="vertical"
                >
                  <Form.Item
                    name="affitoken"
                    label="Confirmar clave temporal"
                    rules={[{ required: true, message: 'Por favor ingrese la clave temporal recibida' }]}
                  >
                    <Input ref={this.noPasteInputRef} onChange={(e) => this.changeInput(e.target.value)} />
                  </Form.Item>
                </Form>
              </Col>
            )}

        </Row>
        <Spin tip="Loading..." spinning={this.state.loading}>
          <Row className="submit-container">

            {this.state.affitoken.registerStatus ?
              (
                <Col span={24}>

                  <Alert className="affitoken-alert"
                    message={`Registro ${this.state.affitoken.registerStatus.status ? 'Exitoso' : 'Fallido'}`}
                    description={this.state.affitoken.registerStatus.message}
                    type={this.state.affitoken.registerStatus.status ? 'info' : 'error'}
                  />
                  <Button className="form-btn btn-radius" htmlType="submit" onClick={() => this.back()}>Volver</Button>

                </Col>)
              :
              <Col span={24}>
                <Button className="form-btn btn-radius" htmlType="submit" onClick={(e) => this.sendAffitoken(e)}>Enviar</Button>
              </Col>}
          </Row>
        </Spin>
      </div >
    )
  }
}

Confirm.propTypes = {
  affitoken: PropTypes.object.isRequired,
  authentication: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    authentication: {
      authtoken: state.auth.auth.access_token,
      company: state.billing.company
    },
    affitoken: state.affitoken
  }
}


export default connect(mapStateToProps, { setStepAction, validateTempTokenAction, registerUserAction })(Confirm);