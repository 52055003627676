import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Spin, Divider, Row, Col, Button, notification } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../utilities/textTools';
import SupportForm from './supportForm/supportForm';
import TableReport from '../../../components/tableReport/tableReport';
import {
  soporte,
  soporteComision,
  soporteExcel,
  soporteFactura,
} from '../../../services/pagador/reportServices';
import './support.scss';
import { setExcel, setExportPDF } from '../../../utilities/downloadTools';
import TaxPopover from '../../../components/taxPopover/taxPopover';

const initialState = {
  form: {
    fechaDesdeEmision: '',
    fechaHastaEmision: '',
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class Support extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
    this.columnas = [
      {
        title: 'NÚMERO FACTURA',
        dataIndex: 'nmFactura',
        sorter: (a, b) => sorterText(a.scFacturaExp, b.scFacturaExp),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA EMISIÓN',
        dataIndex: 'feEmision',
        sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR',
        dataIndex: 'vrFacturaBase',
        render: (value) => <span>{currencyFormat(value)} </span>,
        sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
        showSorterTooltip: false,
      },
      {
        title: 'IMPUESTO',
        dataIndex: 'vrTotalRetenciones',
        render: (value, record) => (
          <TaxPopover record={record}>
            <span>{currencyFormat(value)}</span>
          </TaxPopover>
        ),
        sorter: (a, b) => a.vrTotalRetenciones - b.vrTotalRetenciones,
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL',
        dataIndex: 'vrFactura',
        render: (value) => <span>{currencyFormat(value)} </span>,

        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'estado',
        sorter: (a, b) => sorterText(a.estado, b.estado),
        showSorterTooltip: false,
      },
      {
        title: 'FACTURA',
        dataIndex: 'descargarFactura',
        render: (text, record) => (
          <DownloadOutlined
            title='Descargar Factura'
            className='info'
            onClick={() => this.supportBillPDF(record.nmFactura)}
          />
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ANEXO',
        dataIndex: 'descargarAnexo',
        render: (text, record) => (
          <DownloadOutlined
            title='Descargar Anexo'
            onClick={() => this.supportComisionPDF(record.nmFactura)}
            className='info'
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        fechaDesdeEmision: value.fechaRango[0],
        fechaHastaEmision: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        loading: true,
      });

      try {
        const response = await soporte(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await soporteExcel(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );
        setExcel(response, `soporte.xlsx`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  supportBillPDF = async (nmFacturas) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await soporteFactura(
        this.props.authToken,
        this.props.company.company,
        {
          nmFacturas,
        }
      );

      setExportPDF(response, `Soporte comision factura ${nmFacturas}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  supportComisionPDF = async (nmFacturas) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await soporteComision(
        this.props.authToken,
        this.props.company.company,
        {
          nmFacturas,
        }
      );
      setExportPDF(response, `Soporte comision ${nmFacturas}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.props.menuHandler('3');
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...initialState,
      });
    }
  }

  render() {
    const { loading, currentPage, limit, total, resultado } = this.state;

    return (
      <div className='support-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='align-center'>
            <div className='reports-container'>
              <Spin tip='Cargando...' spinning={loading}>
                <SupportForm
                  companyId={this.props.company.company}
                  handleSubmit={this.handleSubmit}
                />
                <div className='table-container'>
                  {this.state.resultado.length > 0 && (
                    <Row gutter={30} className='bill-selection'>
                      <Col
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                        className='export'
                      >
                        <span className='button'>
                          <Button
                            size='medium'
                            className='btn-excel'
                            title='Exportar a excel'
                            onClick={this.exportExcel}
                            icon={<FileExcelOutlined />}
                          ></Button>
                        </span>
                      </Col>
                    </Row>
                  )}
                  <Divider />
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </div>
              </Spin>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Support);
