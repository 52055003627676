import { Button, Col, Form, Input,InputNumber, Modal, Row, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { requiredValueMessage } from '../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser
} from '../../utilities/textTools';

export const initialTasaDescuentoForm = {
  nombreFondeador: '',
  snPuntosBasicos: '',
  vrPoDescuento:'',
  scFondeador: '',
};

const rules = {
  snPuntosBasicos: [{ required: true, message: requiredValueMessage() }],
  poDescuentoFondeador: [{ required: true, message: requiredValueMessage() }],
};

const TasaDescuentoModalEdit = ({
  visible,
  onClose,
  onFinish,
  dataFondeador
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});


  useEffect(() => {
    if (visible) {
      if (Array.isArray(dataFondeador)) {
        const formData = dataFondeador.reduce((obj, item, index, arr) => {
          return index === arr.length 
            ? { ...obj}
            : { ...obj, [index]: { ...item } };
        }, {});

        form.setFieldsValue({
          ...formData,
        });
      }
    }
  }, [form, dataFondeador, visible]);

  useEffect(() => {
    forceUpdate({});
  }, []);

  const handleSubmit = (formValue) => {
    let rangosPoDescuentoProveedor = Object.entries(formValue)
    .map((value) => value[1])
    .map((value) => ({
      ...value,
      nombreFondeador: value.nombreFondeador || 0,
      snPuntosBasicos: value.snPuntosBasicos || 0,
      vrPoDescuento: value.vrPoDescuento || 0,
      scFondeador: value.scFondeador || 0,
    }));
    onFinish(rangosPoDescuentoProveedor)
  };

  const handleChangeFinField = (name, e) => {
    form.setFieldsValue({
      [name + 1]: {
        inicio: Number(e) + 1,
      },
    });
  };
  
  const handleOnCancel = () => {
    form.resetFields();
    onClose();
  };
  const snPuntosBasicosProv = [
    { key: 'S', description: 'PBS' },
    { key: 'N', description: 'Porcentaje' },
  ];

  
  const tasasFondeadores = ({ nmForm , obj}) => (
  

    <Row gutter={16} key={nmForm}>
  
        <Col span={0}>
        <Form.Item
          name={[nmForm, 'scFondeador']}
          label={'FONDEADOR'}
          hidden={true}
        >
          <Input readOnly />
        </Form.Item>
        </Col>
  
        <Col span={8}>
        <Form.Item
          name={[nmForm, 'nombreFondeador']}
          label={'FONDEADOR'}
        >
          <Input readOnly />
        </Form.Item>
        </Col>
  
        <Col span={8}>
                <Form.Item
                  name={[nmForm, 'snPuntosBasicos']}
                  label='TIPO DE TASA'
                  rules={rules['snPuntosBasicos'] || ''}
                >
                  <Radio.Group buttonStyle='solid'>
                  {snPuntosBasicosProv.map((item) => (
                      <Radio.Button
                        key={item.key}
                        value={item.key}
                        className='radio-btn'
                      >
                        {item.description}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
        </Col>
  
        <Col span={8}>
                <Form.Item
                  name={[nmForm,'vrPoDescuento']}
                  label='TASA FONDEADOR'
                  rules={rules['poDescuentoFondeador'] || ''}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    formatter={percentageInputFormat}
                    parser={percentageInputParser}
                  />
                </Form.Item>
        </Col>
    </Row>
  );
  return (
    <Modal
      title='Tipo Tasa Fondeador'
      visible={visible}
      className='modal-style-volumen'
      footer={null}
      width={800}
      onCancel={handleOnCancel}
    >
    <div className='header-container'>
        <Form
          name='content_form'
          layout='vertical'
          form={form}
          onFinish={(value) => handleSubmit(value)}
        >

          {Array.from({ length: dataFondeador.length }, (v, i) => i).map((_, index) =>
          tasasFondeadores({
              nmForm: index,
              obj:_
              //hidden: index === nmRangosCom - 1,
              //onChangeFin: handleChangeFinField,
            })
          
          )}

        <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='btn btn-green'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
  </Form>
  </div>
    </Modal>
  );
};

export default TasaDescuentoModalEdit;
