import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarUltimosCupos = (
  authtoken,
  scFondeador,
  scEntidad,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/cupos/${scEntidad}/${scFondeador}/listar-ultimos-cupos`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

export const cupoActual = (authtoken, scFondeador, scEntidad, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/cupos/${scEntidad}/${scFondeador}/listar-cupos`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

// export const getProgramarLiquidez = (authtoken, scFondeador, scConvenio) => {
//   return middleWare(
//     axios({
//       url: `${baseUrl}/api/convenios/${scConvenio}/cupo-convenio/${scFondeador}/programar-liquidez`,
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${authtoken}`,
//       },
//       data: null,
//     })
//   );
// };

export const getLiquidez = (authtoken, scFondeador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/liquidez/${scFondeador}/listar-liquidez`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const adicionarCupoContraparte = (
  authtoken,
  scFondeador,
  scEntidad,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/cupos/${scEntidad}/${scFondeador}/cupo-contraparte/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

export const adicionarNuevaLiquidez = (authtoken, scFondeador, data) => {
  data = formatoFechas(data);
  return middleWare(
    axios({
      url: `${baseUrl}/api/liquidez/${scFondeador}/actualizar-liquidez/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') ||
          nombre.includes('Hasta') ||
          nombre.includes('Corte') ||
          nombre.includes('fechaCorte')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        if (filtros[nombre]) {
          empty[nombre] = filtros[nombre];
        }
      }
    }
  });
  return empty;
};
