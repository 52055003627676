import React from 'react'
import './program.scss'
import '../../../styles/standard.scss'
import { Row, Col, Card, Steps } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import Bill from '../billing/bill/bill';
import Summary from '../billing/summary/summary';
import Finish from '../billing/finish/finish';
import store from "../../../store/index";

const { Step } = Steps;

class Program extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
    }
  }

  componentDidMount = () => {
    this.props.menuHandler('5')
    this.updateStep()
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  updateStep = () => {
    const { programCurrentStep } = store.getState().billing;
    this.setState({ currentStep: programCurrentStep });
  }

  render() {
    const step = this.state.currentStep;
    const { programTotalSelectedValue, programSelectedBills } = store.getState().billing
    var investors = []
    programSelectedBills.map((bill, key) => {
      investors[key] = bill.scNombreFondeador
    })
    let unique = [...new Set(investors)];
    return (<div className="assisted-style billing-style">
      <Card className="card-shadow radius-3 default-border">
        <Row gutter={16}>
          <Col span={14}>
            <div className="standard-steps steps-container">
              <Steps progressDot current={this.state.currentStep} onChange={this.changeStep}>
                <Step title="1" />
                {/* <Step title="1 Asistido" /> */}
                <Step title="2" />
                <Step title="3" />
              </Steps>
            </div>
          </Col>
          <Col span={6} className="shop-info-container">
            <div className="shop-card radius-3">
              <p className="icon">
                <ShoppingCartOutlined className="primary-green-font" />
              </p>
              <p className="money-text primary-green-font">
                $ {this.formatNumber(programTotalSelectedValue)}
              </p>
            </div>
          </Col>
          <Col span={4} className="summary primary-green-font">
            <div><strong>Facturas:</strong> {programSelectedBills.length}</div>
            <div><strong>Fondeadores:</strong></div>
            <div>{unique.map((investor) => (
              investor + "\n"
            ))}</div>
          </Col>
        </Row>
      </Card>
      {step === 0 ? <Bill module={'program'} stepHandler={this.updateStep} /> : ''}
      {step === 1 ? <Summary module={'program'} stepHandler={this.updateStep} /> : ''}
      {step === 2 ? <Finish module={'program'} stepHandler={this.updateStep} /> : ''}
    </div>)
  }
}

export default Program;