import {
  COMPANY_SELECTED_PAGADOR
} from '../../actions/types'

const initialState = {
  company: {}
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_SELECTED_PAGADOR: {
      return {
        ...state,
        company: action.payload
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default companyReducer