import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs, Spin, Col, Button, notification, Modal } from 'antd';
import BasicDataForm, {
  initialSupplierValuesForm,
} from '../BasicDataForm/BasicDataForm';
import '../../common/client.scss';
import {
  buscarProveedor,
  crearProveedor,
  listaGruposEmpresarialesProveedores,
  listaPropietarios,
  validarSarlaftProveedor,
} from '../../../../../services/admin/suppliersService';
import history from '../../../../../services/history';

const MESSAGE_ENTIDAD_PROVEEDOR_NO_EXIST =
  'Esta Persona o Entidad no es un Proveedor';
const MESSAGE_ENTIDAD_PROVEEDOR_EXIST =
  'Esta Persona o Entidad es un Proveedor';
const SUPPLIER_TITLE = 'Proveedor';

const initialState = {
  loading: false,
  propietarioSelect: {
    options: [],
    fetching: false,
  },
  grupoSelect: [],
  data: { ...initialSupplierValuesForm },
};

class SuppliersNewForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnPropietarioSearch = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { propietarioSelect } = this.state;

      this.setState({
        ...this.state,
        propietarioSelect: {
          ...propietarioSelect,
          fetching: true,
        },
      });

      try {
        const response = await listaPropietarios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          propietarioSelect: {
            ...propietarioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          propietarioSelect: {
            ...propietarioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  getGrupoAsociados = async () => {
    try {
      const response = await listaGruposEmpresarialesProveedores(
        this.props.authToken
      );
      this.setState({
        ...this.state,
        grupoSelect: response.data,
      });
    } catch {
      this.setState({
        ...this.state,
        grupoSelect: [],
      });
    }
  };

  redirectToSuppliers = () => {
    history.push(`${process.env.PUBLIC_URL}/suppliers`);
  };

  validarSarlaft = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await validarSarlaftProveedor(this.props.authToken);
      notification.success({
        message: SUPPLIER_TITLE,
        description: response.message,
        duration: 6,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await crearProveedor(this.props.authToken, formValue);

      notification.success({
        message: SUPPLIER_TITLE,
        description: response.message,
        duration: 6,
      });
      this.redirectToEditSuppliers(response.location);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnSupplierSearch = async (filtros) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await buscarProveedor(this.props.authToken, filtros);

      if (response.data) {
        if (response.data.snProveedor) {
          Modal.success({
            title: SUPPLIER_TITLE,
            content: MESSAGE_ENTIDAD_PROVEEDOR_EXIST,
          });
          this.redirectToEditSuppliers(response.data.scPersona);
        } else {
          Modal.success({
            title: SUPPLIER_TITLE,
            content: MESSAGE_ENTIDAD_PROVEEDOR_NO_EXIST,
          });

          this.setState({
            ...this.state,
            data: response.data,
          });
        }
      }
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  redirectToEditSuppliers = (scPersona) => {
    history.push(`${process.env.PUBLIC_URL}/suppliers/edit/${btoa(scPersona)}`);
  };

  componentDidMount() {
    this.props.menuHandler('20');
    this.getGrupoAsociados();
  }

  render() {
    const { loading, propietarioSelect, grupoSelect, data } = this.state;
    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Tabs activeKey={'1'}>
              <Tabs.TabPane key={'1'} tab='Datos básicos'>
                <BasicDataForm
                  data={data}
                  grupoSelect={grupoSelect}
                  propietarioSelect={propietarioSelect}
                  onPropietarioSearch={this.handleOnPropietarioSearch}
                  onSupplierSearch={this.handleOnSupplierSearch}
                  onFinish={this.handleOnFinish}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToSuppliers}
                      >
                        Lista de proveedores
                      </Button>
                    </Col>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.validarSarlaft}
                      >
                        Validar sarlaft
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(SuppliersNewForm);
