import { FileExcelOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../services/admin/reports/quotasService';
import { setExcel } from '../../../../utilities/downloadTools';
import { sorterDate, sorterText } from '../../../../utilities/textTools';
import BillEventsFilterForm from './billEventsFilterForm/billEventsFilterForm';
import { listarFacturaRadian, listarFacturaRadianExcel } from '../../../../services/fondeador/reportServices';

const { Text } = Typography;

const initialState = {
  loading: false,
  form: {
    fechaDesdeCompra: moment().subtract(1, 'days'),
    fechaHastaCompra: moment(), 
  },
  resultado: [],
  total: 0,
  limit: 10,
  offset: 0,
  sort: '',
  currentPage: 1,
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  GrupoEmpresarialSelect: {
    fetching: false,
    options: [],
  },
};

class BillsRadian extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'NUMERO FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE COMPRA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'EVENTOS REGISTRADO',
        sorter: (a, b) => {
          const eventosA = a.eventos.toString()
          const eventosB = b.eventos.toString()
          return sorterText(eventosA, eventosB)
        },
        render: (record) => {
          return (
            <Space>
              <Popover
                title={<b>{'Listas de eventos'}</b>}
                content={
                  <ul>
                    {record.eventos &&
                      record.eventos.map((el) => (
                        <li>
                          {`${el.dsTipoEventoDE}  - `}
                          <Text italic>{el.dsTipoRegistroEvento}</Text>
                        </li>
                      ))}
                  </ul>
                }
              >
                <Text>
                  {record.eventos &&
                    record.eventos.map((el) => el.cdTipoEventoDE).join(', ')}
                </Text>
              </Popover>
            </Space>
          );
        },
        showSorterTooltip: false,
      },
    ];
  }
  componentDidMount() {
    this.getData();
  }
  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        cdFactura: value.cdFactura,
        cufe: value.cufe,
        scGrupo: value.scGrupo,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        fechaDesdeCompra: value.fechaRango[0],
        fechaHastaCompra: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.getData();
  };
  getData = async () => {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listarFacturaRadian(
          this.props.authToken,
          this.props.company,
          this.getFiltro()
        );
      this.setState(() => ({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        loading: false,
      }));
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
  };
  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupoEmpresarial = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { GrupoEmpresarialSelect } = this.state;

      this.setState({
        ...this.state,
        GrupoEmpresarialSelect: {
          ...GrupoEmpresarialSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          GrupoEmpresarialSelect: {
            ...GrupoEmpresarialSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          GrupoEmpresarialSelect: {
            ...GrupoEmpresarialSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarFacturaRadianExcel(
        this.props.authToken,
        this.props.company,
        this.getFiltro()
      );
      setExcel(response, 'Listado_de_Eventos_Facturas_DE');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  render() {
    const {
      loading,
      total,
      resumen,
      resultado,
      pagadorSelect,
      proveedorSelect,
      GrupoEmpresarialSelect,
      currentPage,
      limit,
    } = this.state;

    return (
      <div className='reports-container'>
      <Spin tip='Cargando...' spinning={loading}>     
            <BillEventsFilterForm
              pagadorSelect={pagadorSelect}
              proveedorSelect={proveedorSelect}
              grupoEmpresarialSelect={GrupoEmpresarialSelect}
              onSearchPagador={this.handleOnSearchPagador}
              onSearchProveedor={this.handleOnSearchProveedor}
              onSearchGrupoEmpresarial={this.handleOnSearchGrupoEmpresarial}
              handleSubmit={this.handleSubmit}
            />

            <div className='table-container'>
              <Row gutter={[16, 32]} justify='end'>
                {this.state.resultado.length > 0 ? (
                  <Col flex='none'>
                  <Button
                          size='medium'
                          className='btn-excel'
                          title='Exportar a excel'
                          onClick={this.exportExcel}
                          icon={<FileExcelOutlined />}
                        ></Button>
                  </Col>
                ) : null}
              </Row>

              <Divider />

              <Row gutter={30} className='bill-selection' justify='end'></Row>

              <Row gutter={30}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps)(BillsRadian));
