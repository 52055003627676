import {
  AFFITOKEN_STEP_STATUS,
  AFFITOKEN_USER_STATUS,
  AFFITOKEN_SEND_MEDIA,
  AFFITOKEN_TOKEN_TEMP_GENERATED,
  AFFITOKEN_TOKEN_TEMP_VALIDATED,
  AFFITOKEN_TOKEN_GENERATED,
  AFFITOKEN_TOKEN_VALIDATED,
  AFFITOKEN_REGISTER_USER,
  AFFITOKEN_UPDATE_USER,
  AFFITOKEN_TOKEN_VALIDATED_RESTART,
  AFFITOKEN_TOKEN_SET,
  AFFITOKEN_RESET_STEPS
} from '../../actions/types'

const initialState = {
  currentStep: 0,
  user: {
    tipoEnvioOtp: 'EMAIL'
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AFFITOKEN_STEP_STATUS:
      if (action.payload === 0) {
        delete state.AffitokenValidation
        delete state.tempAffitokenValidation
        delete state.registerStatus
      }
      return {
        ...state,
        currentStep: action.payload
      }
    case AFFITOKEN_USER_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case AFFITOKEN_SEND_MEDIA:
      let { tipoEnvioOtp, telefonoEnvioOtp } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          tipoEnvioOtp,
          telefonoEnvioOtp
        }
      }
    case AFFITOKEN_TOKEN_TEMP_GENERATED:
      return {
        ...state,
        tempAffitokenGenerated: action.payload
      }
    case AFFITOKEN_TOKEN_TEMP_VALIDATED:
      return {
        ...state,
        tempAffitokenValidation: action.payload
      }
    case AFFITOKEN_TOKEN_GENERATED:
      return {
        ...state,
        AffitokenGenerated: action.payload
      }
    case AFFITOKEN_TOKEN_VALIDATED:
      return {
        ...state,
        AffitokenValidation: action.payload
      }
    case AFFITOKEN_TOKEN_SET:
      return {
        ...state,
        affitokenEntered: action.payload
      }
    case AFFITOKEN_TOKEN_VALIDATED_RESTART:
      delete state.AffitokenValidation
      return state

    case AFFITOKEN_REGISTER_USER:
      return {
        ...state,
        registerStatus: action.payload
      }
    case AFFITOKEN_UPDATE_USER:
      return {
        ...state,
        updateStatus: action.payload
      }
    case AFFITOKEN_RESET_STEPS:
      return {
        ...initialState,
      }
    default:
      return {
        ...state
      }
  }

}