import axios from 'axios'
import { middleWare } from '../middleware'
import queryString from 'query-string'

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN

export const listarFacturasEndosadas = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  return middleWare(axios({
    url: `${baseUrl}/api/facturas/facturas-endosadas?${queryString.stringify(filters)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarProveedores = (authtoken) => {
  return middleWare(axios({
    url: `${baseUrl}/api/listar-proveedores`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarFondeadores = (authtoken) => {
  return middleWare(axios({
    url: `${baseUrl}/api/listar-fondeadores`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarConvenios = (authtoken) => {
  return middleWare(axios({
    url: `${baseUrl}/api/listar-convenios`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const grabarFacturasEndosadas = (authtoken, data) => {
  let datos = formatoFechas(data)
  return middleWare(axios({
    url: `${baseUrl}/api/facturas/facturas-endosadas/grabar`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: datos
  }))
}

export const grabarFacturasEndosadasAll = (authtoken, filtros) => {
  let filters = formatoFechas(filtros)
  return middleWare(axios({
    url: `${baseUrl}/api/facturas/facturas-endosadas/grabar-all?${queryString.stringify(filters)}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach(nombre => {
    if (nombre !== 'resultado') {
      if ((nombre.includes("Desde") || nombre.includes("Hasta")) && (typeof filtros[nombre] === 'object')) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD')
      }
    }
  });
  return filtros
}