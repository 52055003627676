import moment from 'moment'

export const initialState = {
  loading: false,
  config: {
    currentPage: 1,
    total: 0,
    resultado: [],
  },
  parametrosModal: {
    clusNeuroRiesgo: '',
    percentilCorrespondiente: '',
    limiteInferior: '',
    limiteSuperior: '',
  },
  modalVisible: false,
  opcionesDocumentos: [],
  fetching: false,
  sumatorias: {}
}