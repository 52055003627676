export const form = {
  archive: [
    [
      {
        nombre: 'fechaDesde',
        label: '* Desde',
        tipo: 'date',
        required: true,
      },
      {
        nombre: 'fechaHasta',
        label: '* Hasta',
        tipo: 'date',
        required: true,
      },
    ],
    [
      {
        nombre: 'cdTipoModelo',
        label: '* TIPO MODELO',
        tipo: 'select_nosearch',
        selectType: 'tipoModeloOptions',
        required: true,
      },
      {
        nombre: 'scGrupo',
        label: 'GRUPO EMPRESARIAL',
        tipo: 'select',
        selectType: 'grupoEmpresarialOptions',
      },
    ],
  ],
};
