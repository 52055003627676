export const maxLength = (value, max, name = '') => {
  if (value.length <= max) {
    return Promise.resolve();
  } else {
    return Promise.reject(
      new Error(
        `el valor ${
          name !== '' ? `del ${name}` : ''
        } debe tener un tamaño maximo de ${max} caracteres`
      )
    );
  }
};

export const length = (value, min, max, name = '') => {
  if (!value || value === '' || (value.length >= min && value.length <= max)) {
    return Promise.resolve();
  } else {
    return Promise.reject(
      new Error(
        `el valor ${
          name !== '' ? `del ${name}` : ''
        } debe tener un tamaño minimo de ${min} y maximo de ${max} caracteres`
      )
    );
  }
};

export const totalLength = (value, dataLength, name = '') => {
  if (!value || value === '' || (value.length === dataLength)) {
    return Promise.resolve();
  } else {
    return Promise.reject(
      new Error(
        `el valor ${
          name !== '' ? `del ${name}` : ''
        } debe tener un tamaño de ${dataLength} caracteres`
      )
    );
  }
};
