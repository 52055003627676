import { getType } from '../downloadTools';
export const typeFileValidation = (value, types) => {
  if (Array.isArray(value)) {
    if (value.length <= 0) {
      return Promise.resolve();
    }
    const data = value[0];

    const isError = types.filter((type) => type === data.type);

    if (isError.length === 0) {
      return Promise.reject(
        new Error(
          `El tipo de archivo es el incorrecto, debe ser de tipos: ${getTypes(
            types
          )}`
        )
      );
    }
  }

  return Promise.resolve();
};

const getTypes = (types) => {
  return types.map((type) => getType(type));
};

export const sizeFileValidation = (value, size = 10) => {
  if (Array.isArray(value)) {
    if (value.length <= 0) {
      return Promise.resolve();
    }
    const data = value[0];
    return data.size / 1048576 <= size
      ? Promise.resolve()
      : Promise.reject(
          new Error(`El archivo debe tener un tamaño maximo de ${size}MB`)
        );
  } else {
    return Promise.resolve();
  }
};
