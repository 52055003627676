import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';
import { DATE_POST_FORMAT } from '../../utilities/messages';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listaPagadores = (authtoken, filtros) => {
  let data;
  if (filtros) data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/listar-pagadores?${
        filtros ? queryString.stringify(data) : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const editarPagador = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/grabar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const crearPagador = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const listarPagadoresExcel = (authtoken, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/listar-pagadores/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaTipoDiaPago = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-dia-pago?${queryString.stringify(filtros)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const getPagador = (authtoken, scPagador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${scPagador}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const buscarPagador = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/buscar-pagador?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaTiposDocumentos = (authtoken, tipoAsociado) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${tipoAsociado}/tipo-documento`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaDocumentosCargados = (
  authtoken,
  tipoAsociado,
  scCompania
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/documentos/${tipoAsociado}/${scCompania}/listar-documentos-cargados`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaDocumentosVencidos = (
  authtoken,
  tipoAsociado,
  scCompania
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/documentos/${tipoAsociado}/${scCompania}/listar-documentos-pendientes`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const cargarArchivo = (
  authtoken,
  tipoAsociado,
  scProveedor,
  filtros
) => {
  const formData = new FormData();
  formData.append('feCargar', filtros.feCarga.format(DATE_POST_FORMAT));
  formData.append('codigo', filtros.codigo);
  formData.append('file', filtros.file[0].originFileObj);

  return middleWare(
    axios({
      url: `${baseUrl}/api/documentos/${tipoAsociado}/${scProveedor}/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export const descargarDocumetosCargados = (
  authtoken,
  tipoAsociado,
  scProveedor,
  codigo
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/documentos/${tipoAsociado}/${scProveedor}/descargar-documento/${codigo}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const eliminarDocumentosCargados = (
  authtoken,
  tipoAsociado,
  scCompania,
  codigo
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/documentos/${tipoAsociado}/${scCompania}/eliminar-documento/${codigo}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaTiposContactos = (authtoken, cdTipoAsociado) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${cdTipoAsociado}/tipo-contacto`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaUsuarios = (authtoken, scCompania) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/usuarios/${scCompania}/listar-usuarios`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const buscarUsuario = (authtoken, scCompania, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/usuarios/${scCompania}/validar-usuario?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const editarUsuario = (authtoken, scCompania, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/usuarios/${scCompania}/grabar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const crearUsuario = (authtoken, scCompania, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/usuarios/${scCompania}/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const eliminarUsuario = (authtoken, scCompania, scPersona) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/usuarios/${scCompania}/eliminar/${scPersona}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const crearCalendarioPagos = (authtoken, scPagador, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${scPagador}/calendario-pagos/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const editarCalendarioPagos = (authtoken, scPagador, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${scPagador}/calendario-pagos/grabar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const exportarDiaCalendario = (authtoken, scPagador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${scPagador}/calendario-pagos/dias-calendario/exportar/xlsx`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const crearCalendarioPagosDiasCalendario = (
  authtoken,
  scPagador,
  data,
  reemplazar,
) => {
  const formData = new FormData();
  formData.append('nombrePago', data.nombrePago);
  formData.append('file', data.file[0].originFileObj);
  formData.append('reemplazar', reemplazar);
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${scPagador}/calendario-pagos/grabar/dias-calendario`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data:formData,
    })
  );
};

export const editarCalendarioPagosDiasCalendario = (
  authtoken,
  scPagador,
  scPago,
  data,
  reemplazar,
) => {
  const formData = new FormData();
  formData.append('nombrePago', data.nombrePago);
  formData.append('file', data.file[0].originFileObj);
  formData.append('reemplazar', reemplazar);

  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${scPagador}/calendario-pagos/grabar/dias-calendario/${scPago}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data:formData,
    })
  );
};

export const listarCalendariosPagos = (authtoken, scPagador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/pagadores/${scPagador}/calendario-pagos/listar-pagos`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') ||
          nombre.includes('Hasta') ||
          nombre.includes('feCarga')) &&
        typeof filtros[nombre] === 'object'
      ) {
        filtros[nombre] = filtros[nombre].format(DATE_POST_FORMAT);
      }
    }
  });
  return filtros;
};
