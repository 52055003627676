import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_FONDEADOR;

export const listarEstadoObligacion = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/estado-obligacion`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCartera = (authtoken, scFondeador, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/listar-cartera/${scFondeador}?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarHistoricoAbonos = (
  authtoken,
  scFondeador,
  scFactura,
  filtros
) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/listar-historico-abonos/${scFactura}/${scFondeador}?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarReintegros = (authtoken, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/listar-reintegros?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const exportarExcelFacturasCartera = (
  authtoken,
  scFondeador,
  filtros
) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/reporte-listar-cartera/exportar/xlsx/${scFondeador}?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTiposConvenios = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-convenios`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarPagadoresDelProveedor = (
  authToken,
  scProveedor
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/listarPagadoresDelProveedor/${scProveedor}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const listarProveedoresDelPagador = (
  authtoken,
  scPagador
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/listarProveedoresDelPagador/${scPagador}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const traerEntidad = (authtoken, scPersona) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/personas?scPersona=${scPersona}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const calcularAbonos = (
  authtoken,
  scFondeador,
  tipoEntidad,
  scEntidad,
  recaudos
) => {
  recaudos.forEach((recaudo) => {
    formatoFechas(recaudo);
  });
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/calcular-abonos/${tipoEntidad}/${scEntidad}/${scFondeador}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: recaudos,
    })
  );
};

export const aplicarPagos = (
  authtoken,
  scFondeador,
  tipoEntidad,
  scEntidad,
  recaudos
) => {
  recaudos.forEach((recaudo) => {
    formatoFechas(recaudo);
  });
  return axios({
    url: `${baseUrl}/api/cartera/aplicar-pagos/${tipoEntidad}/${scEntidad}/${scFondeador}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
    data: recaudos,
  });
};

export const actualizarEstadoReintegro = (
  authtoken,
  scRecaudo,
  estadoReintegro,
  cdFactura
) => {
  return axios({
    url: `${baseUrl}/api/cartera/actualizar-estado/${scRecaudo}/${estadoReintegro}/${cdFactura}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authtoken}`,
    },
  });
};

export const upload = (authtoken, scFondeador, company, files) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/${company}/cargar-archivo/${scFondeador}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: files,
    })
  );
};

export const confirm = (
  authtoken,
  scFondeador,
  company,
  filemane
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/${company}/cargar-archivo/aceptar/${filemane}/${scFondeador}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const downloadTemplate = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/cartera/plantilla`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') ||
          nombre.includes('Hasta') ||
          nombre.includes('feLiquidacion') ||
          nombre.includes('feRecaudo')) &&
        typeof filtros[nombre] === 'object'
      ) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD');
      }
    }
  });
  return filtros;
};
