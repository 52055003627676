import React, { useEffect, useState } from 'react';
import { Spin, Form, Col, Row, Input, Select, DatePicker, Button } from 'antd';

import moment from 'moment';
import {
  BUSQUEDA_TITLE,
  DATE_FORMAT,
} from '../../../../../../utilities/messages';

const SELECT_PLACE_HOLDER = 'Seleccione...';

const initialValue = {
  fechaRango: [moment().subtract(1, 'days'), moment()],
  scGrupo: undefined,
  scPagador: undefined,
  scProveedor: undefined,
  cdFactura: undefined,
  scConvenio: undefined,
  cdTipoConvenio: undefined,
};

const TransactionInProgressFilterForm = ({
  grupoSelect,
  handleOnSearchGrupo,
  pagadorSelect,
  handleOnSearchPagador,
  proveedorSelect,
  handleOnSearchProveedor,
  convenioSelect,
  handleOnSearchConvenio,
  tipoConvenioSelect,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='fechaRango'
              label='Rango De Fechas'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un rango de fechas',
                },
              ]}
            >
              <DatePicker.RangePicker name='fechaRango' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scGrupo' label='Grupo Empresarial'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchGrupo(value)}
                notFoundContent={
                  grupoSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {grupoSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scPagador' label='Pagador'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchPagador(value)}
                notFoundContent={
                  pagadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {pagadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scProveedor' label='Proveedor'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchProveedor(value)}
                notFoundContent={
                  proveedorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {proveedorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='cdFactura' label='Código De Factura'>
              <Input name='cdFactura' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='cdTipoConvenio' label='Tipo de Convenio'>
            <Select
                placeholder={SELECT_PLACE_HOLDER}
                allowClear
              >
                {tipoConvenioSelect.map((item) => (
                  <Select.Option key={item.codigo} value={item.codigo}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='scConvenio' label='Convenios'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchConvenio(value)}
                notFoundContent={
                  convenioSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {convenioSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TransactionInProgressFilterForm;
