import { Button, Col, Divider, Row, Space, Spin } from 'antd';
import React, { Component } from 'react';
import TableReport from '../../../../../components/tableReport/tableReport';
import { listarConveniosFondeador } from '../../../../../services/admin/operation';
import { sorterText } from '../../../../../utilities/textTools';
import FoundProvidersEditModal from './foundProvidersEditModal/foundProvidersEditModal';
import FoundProvidersNewModal from './foundProvidersNewModal/foundProvidersNewModal';

const initialState = {
  loading: false,
  visibleEditModal: false,
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  sort: '',
  fondeador: { rangoMetas: [] },
};

class FoundProviders extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
    this.columnas = [
      {
        title: 'TIPO DE DOCUMENTO',
        dataIndex: 'dsFondeadorTipoId',
        sorter: (a, b) => sorterText(a.dsFondeadorTipoId, b.dsFondeadorTipoId),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'fondeadorId',
        sorter: (a, b) => sorterText(a.fondeadorId, b.fondeadorId),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE / RAZÓN SOCIAL',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoRegistro',
        sorter: (a, b) => sorterText(a.dsEstadoRegistro, b.dsEstadoRegistro),
        showSorterTooltip: false,
      },
      {
        title: '',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showModal(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  showModal = (fondeador) => {
    this.setState({ ...this.state, fondeador, visibleEditModal: true });
  };

  getData = async () => {
    const { scConvenio, authToken } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarConveniosFondeador(authToken, scConvenio);

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  handleModalOnFinish = (e) => {
    this.setState({
      ...this.state,
      visibleEditModal: false,
      fondeador: { rangoMetas: [] },
    });
    this.getData();
  };

  handleEditModalOnClose = () => {
    this.setState({
      ...this.state,
      fondeador: { rangoMetas: [] },
      visibleEditModal: false,
    });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resultado,
      visibleEditModal,
      fondeador,
    } = this.state;
    const {
      authToken,
      scConvenio,
      cdTipoConvenio,
      children,
      scPagador,
      nombrePagador,
      poDescuentoMinimoProveedor
    } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <Row gutter={[16, 32]} align='middle'>
          <Col flex='auto'>
            <Space align='baseline' className='sub-title'>
              Lista de Fondeadores
            </Space>
          </Col>
          <Col flex='none'>
            <span className='button'>
              <FoundProvidersNewModal
                authToken={authToken}
                scConvenio={scConvenio}
                cdTipoConvenio={cdTipoConvenio}
                scPagador={scPagador}
                nombrePagador={nombrePagador}
                onFinish={this.handleModalOnFinish}
                poDescuentoMinimoProveedor={poDescuentoMinimoProveedor}
              />
            </span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col flex='auto'>
            <div className='table-container'>
              <TableReport
                currentPage={currentPage}
                limit={limit}
                total={total}
                columns={this.columnas}
                data={resultado}
                handleChange={this.handleChange}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>{children}</Row>
        <FoundProvidersEditModal
          data={fondeador}
          visible={visibleEditModal}
          authToken={authToken}
          scConvenio={scConvenio}
          cdTipoConvenio={cdTipoConvenio}
          onFinish={this.handleModalOnFinish}
          onClose={this.handleEditModalOnClose}
          poDescuentoMinimoProveedor={poDescuentoMinimoProveedor}
        />
      </Spin>
    );
  }
}

export default FoundProviders;
