import axios from 'axios'
import { middleWare } from '../middleware'

var baseUrl = process.env.REACT_APP_SERVICES_URL_PAGADOR

/**
 * 
 * @param {*} authToken
 */
const consultarCompanias = (authToken) => {
  return middleWare(axios({
    url: `${baseUrl}/api/companias/listar-companias`,
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    },
    data: null,
  }))
};

export default {
  consultarCompanias
}