import { Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import React from 'react';
import { requiredValueMessage } from '../../../../../../utilities/admin_validation_messages';
import { DATE_FORMAT } from '../../../../../../utilities/messages';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  cdMunElaboracion: [{ required: true, message: requiredValueMessage() }],
  feElaboracion: [{ required: true, message: requiredValueMessage() }],
};

class General extends React.Component {
  render() {
    const { ciudadesSelect, onCiudadesSearch } = this.props;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdMunElaboracion'
                label='Ciudad de elaboración'
                rules={rules['cdMunElaboracion'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  className='module-selector-select'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={(value) => onCiudadesSearch(value)}
                  notFoundContent={
                    ciudadesSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {ciudadesSelect.options.map((ciudad, i) => (
                    <Select.Option key={i} value={ciudad.codigo}>
                      {ciudad.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='feElaboracion'
                label='Fecha de elaboración'
                rules={rules['feElaboracion'] || ''}
              >
                <DatePicker
                  className='radius'
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='razonSocial'
                label='Razón o denominación social'
                rules={rules['razonSocial'] || ''}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='nit' label='Nit'>
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default General;
