import {
  AFFITOKEN_STEP_STATUS,
  AFFITOKEN_USER_STATUS,
  AFFITOKEN_SEND_MEDIA,
  AFFITOKEN_TOKEN_TEMP_GENERATED,
  AFFITOKEN_TOKEN_TEMP_VALIDATED,
  AFFITOKEN_TOKEN_GENERATED,
  AFFITOKEN_TOKEN_VALIDATED,
  AFFITOKEN_REGISTER_USER,
  AFFITOKEN_UPDATE_USER,
  AFFITOKEN_TOKEN_VALIDATED_RESTART,
  AFFITOKEN_TOKEN_SET,
  AFFITOKEN_RESET_STEPS
} from '../types'
import { getUser, requestTempToken, validateTempToken, requestToken, validateToken, updateUser, registerUser } from '../../../services/proveedor/affitokenServices'

export const setStepAction = (step) => dispatch => {
  dispatch({ type: AFFITOKEN_STEP_STATUS, payload: step })
};

export const getUserAction = ({ authtoken, company }) => dispatch => {
  getUser(authtoken, company)
    .then(response => {
      dispatch({ type: AFFITOKEN_USER_STATUS, payload: response.data })
    })
};

export const requestTempTokenAction = ({ authtoken, company }) => dispatch => {
  requestTempToken(authtoken, company)
    .then(response => {
      dispatch({ type: AFFITOKEN_TOKEN_TEMP_GENERATED, payload: response })
    })
};

export const validateTempTokenAction = ({ authtoken, company }, token) => dispatch => {
  validateTempToken(authtoken, company, token)
    .then(response => {
      dispatch({ type: AFFITOKEN_TOKEN_TEMP_VALIDATED, payload: response })
    })
};

export const requestTokenAction = ({ authtoken, company }) => dispatch => {
  requestToken(authtoken, company)
    .then(response => {
      dispatch({ type: AFFITOKEN_TOKEN_GENERATED, payload: response })
    })
};

export const validateTokenAction = (token) => dispatch => {
  dispatch({ type: AFFITOKEN_TOKEN_VALIDATED_RESTART, payload: {} })
  dispatch({ type: AFFITOKEN_TOKEN_VALIDATED, payload: token })
};

export const setToken = (token) => dispatch => {
  dispatch({ type: AFFITOKEN_TOKEN_SET, payload: token })
}

export const selectSendMethodAction = (tipoEnvioOtp, telefonoEnvioOtp = null) => dispatch => {
  dispatch({ type: AFFITOKEN_SEND_MEDIA, payload: { tipoEnvioOtp, telefonoEnvioOtp } })
};

export const updateUserAction = ({ authtoken, company }, user) => dispatch => {

  updateUser(authtoken, company, user).then(response => {
    dispatch({ type: AFFITOKEN_UPDATE_USER, payload: response })
  })
};

export const registerUserAction = ({ authtoken, company }, user, token) => dispatch => {
  registerUser(authtoken, company, user, token).then(response => {
    dispatch({ type: AFFITOKEN_REGISTER_USER, payload: response })
  })
};
export const resetAffitokengSteps = () => dispatch => {
  dispatch({ type: AFFITOKEN_RESET_STEPS })
};
