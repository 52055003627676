import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  Spin,
  Select,
  Button,
  Checkbox,
  InputNumber,
  notification,
  Space,
  Divider,
} from 'antd';
import {
  informacionTributaria,
  actualizarInformacionTributaria,
} from '../../../../../services/admin/suppliersService';
import {
  requiredValueMessage,
  rangeValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import { range } from '../../../../../utilities/validate/numberValidate';

const { Option } = Select;

const regimenTributario = [
  { key: 'S', description: 'Simplificado' },
  { key: 'C', description: 'Común' },
  { key: 'G', description: 'Especial' },
];

const estado = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
];

const decision = [
  { key: 'S', description: 'Sí' },
  { key: 'N', description: 'No' },
];

const initialState = {
  loading: false,
  cdReteIva: false,
  cdReteIca: false,
};

const rules = {
  cdAutoRetenedor: [{ required: true, message: requiredValueMessage() }],
  cdRegimenTrib: [{ required: true, message: requiredValueMessage() }],
  vrBaseReteIva: [
    { required: true, message: requiredValueMessage() },
    {
      validator: async (_, value) => range(value, 0, 9999999999999999),
      message: rangeValueMessage(0, 9999999999999999),
    },
  ],
  vrBaseReteIca: [
    { required: true, message: requiredValueMessage() },
    {
      validator: async (_, value) => range(value, 0, 9999999999999999),
      message: rangeValueMessage(0, 9999999999999999),
    },
  ],
  poReteIca: [
    { required: true, message: requiredValueMessage() },
    {
      validator: async (_, value) => range(value, 0, 100),
      message: rangeValueMessage(0, 100),
    },
  ],
  cdEstadoInfoTrib: [{ required: true, message: requiredValueMessage() }],
};

const initialValues = {
  cdAutoRetenedor: '',
  cdRegimenTrib: '',
  cdReteIva: false,
  vrBaseReteIva: '',
  cdReteIca: false,
  cdReteFuente: false,
  vrBaseReteIca: '',
  cdEstadoInfoTrib: '',
};

class TaxInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.formRef = React.createRef();
  }

  getTaxInformation = async () => {
    const { authToken, scPersona } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await informacionTributaria(authToken, scPersona);
      this.formRef.current.setFieldsValue({
        ...response.data,
        cdReteIva: response.data.cdReteIva === 'S',
        cdReteIca: response.data.cdReteIca === 'S',
        cdReteFuente: response.data.cdReteFuente === 'S',
      });
      this.setState({
        ...this.state,
        loading: false,
        cdReteIva: response.data.cdReteIva === 'S',
        cdReteIca: response.data.cdReteIca === 'S',
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.getTaxInformation();
  }

  handleOnFinish = async (formValue) => {
    const { authToken, scPersona } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await actualizarInformacionTributaria(
        authToken,
        scPersona,
        {
          ...formValue,
          cdReteIva: formValue.cdReteIva ? 'S' : 'N',
          cdReteIca: formValue.cdReteIca ? 'S' : 'N',
          cdReteFuente: formValue.cdReteFuente ? 'S' : 'N',
        }
      );

      notification.success({
        message: 'Información tributaria',
        description: response.message,
        duration: 6,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnChange = (checked, name) => {
    this.setState({ ...this.state, [name]: checked });
  };

  render() {
    const { loading, cdReteIva, cdReteIca } = this.state;
    const { children } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <Form
          name='content_form'
          layout='vertical'
          ref={this.formRef}
          onFinish={this.handleOnFinish}
          initialValues={initialValues}
        >
          <Row gutter={16}>
            <Col>
              <Space align='baseline' className='sub-title'>
                Información tributaria
              </Space>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdAutoRetenedor'
                label='AUTORETENEDOR'
                rules={rules['cdAutoRetenedor']}
              >
                <Select>
                  {decision.map((data) => (
                    <Option key={data.key} value={data.key}>
                      {data.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdRegimenTrib'
                label='RÉGIMEN TRIBUTARIO'
                rules={rules['cdRegimenTrib']}
              >
                <Select>
                  {regimenTributario.map((data) => (
                    <Option key={data.key} value={data.key}>
                      {data.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <div className='check' style={{ paddingTop: '2rem' }}>
                <Form.Item name='cdReteIva' valuePropName='checked'>
                  <Checkbox
                    className='tax-info-check'
                    onChange={(e) =>
                      this.handleOnChange(e.target.checked, 'cdReteIva')
                    }
                  >
                    Rete IVA
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
            {cdReteIva && (
              <Col span={12}>
                <Form.Item
                  name='vrBaseReteIva'
                  label='BASE'
                  rules={rules['vrBaseReteIva']}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <div className='check' style={{ paddingTop: '2rem' }}>
                <Form.Item name='cdReteFuente' valuePropName='checked'>
                  <Checkbox
                    className='tax-info-check'
                    onChange={(e) =>
                      this.handleOnChange(e.target.checked, 'cdReteFuente')
                    }
                  >
                    Rete Fuente
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <div className='check' style={{ paddingTop: '2rem' }}>
                <Form.Item name='cdReteIca' valuePropName='checked'>
                  <Checkbox
                    className='tax-info-check'
                    onChange={(e) =>
                      this.handleOnChange(e.target.checked, 'cdReteIca')
                    }
                  >
                    Rete ICA
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
            {cdReteIca && (
              <>
                <Col span={6}>
                  <Form.Item
                    name='vrBaseReteIca'
                    label='BASE'
                    rules={rules['vrBaseReteIca']}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='poReteIca'
                    label='%'
                    rules={rules['poReteIca']}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdEstadoInfoTrib'
                label='Estado'
                rules={rules['cdEstadoInfoTrib']}
              >
                <Select>
                  {estado.map((data) => (
                    <Option key={data.key} value={data.key}>
                      {data.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {children}
            <Col span={6}>
              <Button
                style={{ width: '90%' }}
                className='btn btn-green'
                type='primary'
                htmlType='submit'
              >
                Grabar
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
    /*  return (
     
           
                
                
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='cdEstadoInfoTrib'
                      label='ESTADO'
                      rules={this.formRules['cdEstadoInfoTrib']}
                    >
                      <Select
                        value={this.state.parametersPage.cdEstadoInfoTrib}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            parametersPage: {
                              ...this.state.parametersPage,
                              cdEstadoInfoTrib: e,
                            },
                          })
                        }
                      >
                        {Array.isArray(this.state.estado) &&
                          this.state.estado.map((item) => (
                            <Select.Option
                              key={item.codigo}
                              value={item.codigo}
                            >
                              {item.descripcion}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={32} className='button-list'>
                  <Col span={6} className='center-btn'>
                    <Button
                      style={{ width: '90%' }}
                      className='btn btn-blue'
                      type='primary'
                      onClick={this.props.clickHandler}
                    >
                      Lista {this.props.type}
                    </Button>
                  </Col>
                  <Col span={6} className='center-btn'>
                    <Button
                      className='btn btn-green'
                      type='primary'
                      onClick={() => {
                        this.formRef.current.submit();
                      }}
                    >
                      Grabar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Spin>  );*/
  }
}

export default TaxInformation;
