import React, { Component, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Modal,
  Upload,
  Space,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  listaDiasNoHabiles,
  listarDiasNoHabilesExcel,
  cargarArchivo,
} from '../../../../services/admin/nonBusinessDaysService';
import './nonBusinessDays.scss';
import { connect } from 'react-redux';
import { sorterDate } from '../../../../utilities/textTools';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../utilities/messages';
import moment from 'moment';
import TableReport from '../../../../components/tableReport/tableReport';
import { setExcel } from '../../../../utilities/downloadTools';
import NonBusinessDaysModal from './nonBusinessDaysModal/nonBusinessDaysModal';

const initialStates = {
  loading: false,
  visibleModal: false,
  form: {
    nombre: '',
    id: '',
  },
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  sort: '',
};

const initialValue = {
  fechaRango: [moment(new Date()), moment(new Date())],
};

class NonBusinessDays extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialStates };
    this.formRef = React.createRef();
    this.columnas = [
      {
        title: 'FECHA',
        dataIndex: 'fecha',
        sorter: (a, b) => sorterDate(a.fecha, b.fecha),
        showSorterTooltip: false,
      },
    ];
  }

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        fechaDesde: value.fechaRango[0],
        fechaHasta: value.fechaRango[1],
      },
      resultado: [],
      total: initialStates.total,
      currentPage: initialStates.currentPage,
      offset: initialStates.offset,
    });

    this.getData();
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaDiasNoHabiles(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarDiasNoHabilesExcel(
        this.props.authToken,
        this.getFiltro()
      );

      setExcel(response, 'Dias no hábiles');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;

    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleOnFinish = () => {
    this.setState({ ...this.state, visibleModal: false });
    this.getData();
  };

  handleOnClose = () => {
    this.setState({ ...this.state, visibleModal: false });
  };

  handleOnOpenModal = () => {
    this.setState({ ...this.state, visibleModal: true });
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  componentDidMount() {
    this.props.menuHandler('7');
  }

  render() {
    const { loading, currentPage, limit, total, resultado, visibleModal } =
      this.state;
    const { authToken } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='nonBusinessDays-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <p className='title'>{BUSQUEDA_TITLE}</p>
              <Form
                name='content_form'
                layout='vertical'
                className='filters-container'
                ref={this.formRef}
                onFinish={this.handleSubmit}
                initialValues={initialValue}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name='fechaRango'
                      label='Rango de fechas'
                      rules={[
                        {
                          required: true,
                          message: 'Por favor seleccione un rango de fechas',
                        },
                      ]}
                    >
                      <DatePicker.RangePicker
                        name='fechaRango'
                        format={DATE_FORMAT}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='left'>
                  <Col span={8}>
                    <Form.Item name='submit'>
                      <Button
                        htmlType='submit'
                        className='form-btn btn-radius'
                        type='primary'
                      >
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Listado de días no habiles
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                    <Button
                      size='small'
                      className='btn-agregar'
                      onClick={this.handleOnOpenModal}
                    >
                      + Cargar dias no habiles
                    </Button>
                  </Space>
                </Col>
                <Col flex='none'>
                  <Space align='baseline'>
                    <Button type='link' onClick={this.exportExcel}>
                      Exportar a excel
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
            <NonBusinessDaysModal
              authToken={authToken}
              visible={visibleModal}
              onFinish={this.handleOnFinish}
              onClose={this.handleOnClose}
            />
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(NonBusinessDays);
