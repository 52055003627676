import React, { Component } from 'react'
import { Card, Tabs } from 'antd';
import './reports.scss'
import Content from './content/content'

class Reports extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeTab: '0'
    }
  }

  tabsToMap = [
    {
      title: 'Pagador - Exponencial',
      key: <Content type="pagadores" />,
    },
    {
      title: 'Fondeadores',
      key: <Content type="fondeadores" />,
    },
    {
      title: 'Proveedores',
      key: <Content type="proveedores" />,
    },
  ]

  componentDidMount() {
    this.props.menuHandler('70')
  }

  changeTab = activeKey => {
    this.setState({
      ...this.state,
      activeTab: activeKey
    })
  }

  render() {
    return (
      <div className="reports-style-f">
        <Card className="card-shadow card-radius default-border welcome-message">
          <div className="align-center">
            <Tabs activeKey={this.state.activeTab} onChange={this.changeTab}>
              {this.tabsToMap.map((page, index) => (
                <Tabs.TabPane tab={page.title} key={`${index}`} >
                  {page.key}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </Card>
      </div>
    )
  }
}

export default Reports