import { Button, Card, Col, Divider, Row, Space, Spin } from 'antd';
import QueryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TableReport from '../../../../components/tableReport/tableReport';
import { listarConvenios } from '../../../../services/admin/operation';
import {
  listarFondeadores,
  listarPagadores,
  listarProveedores,
} from '../../../../services/admin/reports/quotasService';
import history from '../../../../services/history';
import { sorterText } from '../../../../utilities/textTools';
import AgreementFilterFrom from './agreementFilterFrom/agreementFilterFrom';
import './agreementOperation.scss';
import DuplicateAgreementModal from './duplicateAgreementModal/duplicateAgreementModal';

const initialState = {
  loading: false,
  form: {
    nombre: '',
    scPagador: '',
    scProveedor: '',
    scFondeador: '',
    cdEstadoRegistro: '',
  },
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  sort: '-nombrePagador',
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
  convenioDuplicar: undefined,
  duplicarVisibleModal: false,
};

class AgreementOperation extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE CONVENIO',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO CONVENIO',
        dataIndex: 'dsTipoConvenio',
        sorter: (a, b) => sorterText(a.dsTipoConvenio, b.dsTipoConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'dsConvenio',
        sorter: (a, b) => sorterText(a.dsConvenio, b.dsConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstado',
        sorter: (a, b) => sorterText(a.dsEstado, b.dsEstado),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showEditPage(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'DUPLICAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Duplicar'
            onClick={() => this.showDuplicarModal(record)}
          >
            DUPLICAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.formSearch = React.createRef();
  }

  showEditPage = (record) => {
    history.push(
      `${process.env.PUBLIC_URL}/operation/edit/${btoa(record.scConvenio)}`
    );
  };

  showDuplicarModal = (record) => {
    this.setState({
      ...this.state,
      duplicarVisibleModal: true,
      convenioDuplicar: record,
    });
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    const { limit, sort } = this.state;

    this.doChangeHistory({
      ...value,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
      limit,
      sort,
    });
  };

  handleChange = (e) => {
    const { form } = this.state;
    this.doChangeHistory({ ...form, ...e });
  };

  getData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarConvenios(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;

    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  componentDidMount() {
    this.props.menuHandler('41');
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.init(nextProps);
    }
  }

  init = async (props) => {
    const filtro = this.parseQueryString(props);

    await this.updateStatePage(filtro);

    await this.getData();
  };

  parseQueryString = (props) => {
    const {
      location: { search },
    } = props;

    const filtro = QueryString.parse(search, { ignoreQueryPrefix: true });

    return {
      ...filtro,
      limit: parseInt(filtro.limit),
      currentPage: parseInt(filtro.currentPage),
      offset: parseInt(filtro.offset),
    };
  };

  updateStatePage = async (filtro) => {
    this.state.limit = filtro.limit || 10;
    this.state.offset = filtro.offset || 0;
    this.state.currentPage = filtro.currentPage || 1;
    this.state.sort = filtro.sort || '';
    try {
      let scFondeador;
      if (filtro.fondeador) {
        if (this.state.fondeadorSelect.options.length === 0) {
          await this.handleOnSearchFondeador(filtro.fondeador);
        }

        scFondeador = this.state.fondeadorSelect.options.find(
          (el) => el.descripcion === filtro.fondeador
        ).codigo;
      }

      let scProveedor;
      if (filtro.proveedor) {
        if (this.state.proveedorSelect.options.length === 0) {
          await this.handleOnSearchProveedor(filtro.proveedor);
        }

        scProveedor = this.state.proveedorSelect.options.find(
          (el) => el.descripcion === filtro.proveedor
        ).codigo;
      }

      let scPagador;
      if (filtro.pagador) {
        if (this.state.pagadorSelect.options.length === 0) {
          await this.handleOnSearchPagador(filtro.pagador);
        }

        scPagador = this.state.pagadorSelect.options.find(
          (el) => el.descripcion === filtro.pagador
        ).codigo;
      }

      this.state.form = {
        scFondeador,
        scPagador,
        scProveedor,
        cdEstadoRegistro: filtro.cdEstadoRegistro,
        nombre: filtro.nombre,
      };
    } catch {
      history.push({
        pathname: `${process.env.PUBLIC_URL}/operation`,
      });
    }
  };

  handleDuplicateAgreementOnClose = () => {
    this.setState({
      ...this.state,
      duplicarVisibleModal: false,
      convenioDuplicar: undefined,
    });
  };

  doChangeHistory = (e) => {
    const { fondeadorSelect, proveedorSelect, pagadorSelect } = this.state;

    const fondeador = e.scFondeador
      ? fondeadorSelect.options.find((el) => el.codigo === e.scFondeador)
          .descripcion
      : undefined;

    const proveedor = e.scProveedor
      ? proveedorSelect.options.find((el) => el.codigo === e.scProveedor)
          .descripcion
      : undefined;

    const pagador = e.scPagador
      ? pagadorSelect.options.find((el) => el.codigo === e.scPagador)
          .descripcion
      : undefined;

    const search = QueryString.stringify({
      fondeador,
      proveedor,
      pagador,
      nombre: e.nombre,
      cdEstadoRegistro: e.cdEstadoRegistro,

      sort: e.sort !== '' ? e.sort : undefined,
      limit: e.limit,
      offset: e.offset,
      currentPage: e.currentPage,
    });

    history.push({
      pathname: `${process.env.PUBLIC_URL}/operation`,
      search,
    });
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resultado,
      fondeadorSelect,
      pagadorSelect,
      proveedorSelect,
      duplicarVisibleModal,
      convenioDuplicar,
      form,
    } = this.state;

    const { authToken } = this.props;

    return (
      <>
        <Spin tip='Cargando...' spinning={loading}>
          <div className='suppliers-style'>
            <Card className='card-shadow card-radius default-border welcome-message'>
              <AgreementFilterFrom
                data={form}
                fondeadorSelect={fondeadorSelect}
                pagadorSelect={pagadorSelect}
                proveedorSelect={proveedorSelect}
                handleOnSearchFondeador={this.handleOnSearchFondeador}
                handleOnSearchProveedor={this.handleOnSearchProveedor}
                handleOnSearchPagador={this.handleOnSearchPagador}
                handleSubmit={this.handleSubmit}
              />
              <div className='table-container'>
                <Row
                  gutter={[16, 32]}
                  align='middle'
                  style={{ marginTop: '1.3rem' }}
                >
                  <Col flex='auto'>
                    <Space align='baseline' className='sub-title'>
                      Lista de Convenios
                    </Space>
                  </Col>
                  <Col flex='none'>
                    <Space align='baseline'>
                      <Button
                        size='small'
                        onClick={() =>
                          history.push(
                            `${process.env.PUBLIC_URL}/operation/new`
                          )
                        }
                        className='btn-agregar'
                      >
                        + Agregar nuevo convenio
                      </Button>
                    </Space>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                  <Col span={24}>
                    <TableReport
                      currentPage={currentPage}
                      limit={limit}
                      total={total}
                      columns={this.columnas}
                      data={resultado}
                      handleChange={this.handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </Spin>
        <DuplicateAgreementModal
          convenio={convenioDuplicar}
          visible={duplicarVisibleModal}
          authToken={authToken}
          onClose={this.handleDuplicateAgreementOnClose}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(AgreementOperation));
