import React from 'react';
import { Table, Popover } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const BulkLoadTable = ({ data, confirmed }) => {
  const formatNumber = (num) => {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    } else {
      return 0;
    }
  };

  const columns = [
    {
      title: 'ESTADO',
      key: 'estado',
      align: 'center',
      render: (item) =>
        Array.isArray(item.errors) && item.errors.length > 0 ? (
          <Popover
            data-html='true'
            placement='topRight'
            content={
              Array.isArray(item.errors) && (
                <div>
                  {item.errors.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              )
            }
            title='Errores'
          >
            {<InfoCircleOutlined className='icon-size color-error' />}
          </Popover>
        ) : confirmed ? (
          <CheckCircleOutlined className='icon-size color-success' />
        ) : (
          <InfoCircleOutlined className='icon-size color-success' />
        ),
    },
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'pagadorId',
      key: 'pagadorId',
    },
    {
      title: 'NOMBRE RECEPTOR',
      dataIndex: 'pagadorNombre',
      key: 'pagadorNombre',
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      key: 'cdFactura',
      expandable: true,
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      render: (value) => <span>$ {formatNumber(value)}</span>,
      key: 'vrFactura',
    },
    {
      title: 'VALOR BASE',
      dataIndex: 'vrFacturaBase',
      render: (value) => <span>$ {formatNumber(value)}</span>,
      key: 'vrFacturaBase',
    },
    {
      title: 'FECHA EMISIÓN',
      dataIndex: 'feEmision',
      key: 'feEmision',
    },
    {
      title: 'FECHA VENCIMIENTO',
      dataIndex: 'feVencimiento',
      key: 'feVencimiento',
    },
  ];

  return (
    <div className='table-wrapper'>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30'],
        }}
      />
    </div>
  );
};

export default BulkLoadTable;
