import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Divider } from 'antd';
import { BUSQUEDA_TITLE } from '../../../../../utilities/messages';
import TableReport from '../../../../../components/tableReport/tableReport';
import { sorterDate, sorterText } from '../../../../../utilities/textTools';

const columnas = [
  {
    title: 'TIPO IDENTIFICACIÓN',
    dataIndex: 'dsProveedorTipoId',
    sorter: (a, b) => sorterText(a.dsProveedorTipoId, b.dsProveedorTipoId),
    showSorterTooltip: false,
  },
  {
    title: 'IDENTIFICACION',
    dataIndex: 'proveedorId',
    sorter: (a, b) => sorterText(a.proveedorId, b.proveedorId),
    showSorterTooltip: false,
  },
  {
    title: 'PROVEEDOR',
    dataIndex: 'proveedorNombre',
    sorter: (a, b) => sorterText(a.proveedorNombre, b.proveedorNombre),
    showSorterTooltip: false,
  },
  {
    title: 'ESTADO',
    dataIndex: 'dsEstadoRegistro',
    sorter: (a, b) => sorterText(a.dsEstadoRegistro, b.dsEstadoRegistro),
    showSorterTooltip: false,
  },
  {
    title: 'FECHA INGRESO',
    dataIndex: 'feModifica',
    sorter: (a, b) => sorterDate(a.feIngreso, b.feIngreso),
    showSorterTooltip: false,
  },
];

const SupplierList = ({
  handleSearch,
  scConvenio,
  resultado,
  total,
  currentPage,
  limit,
  handleChange,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({ nombre: '' });

  console.log('SupplierList', resultado);
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [scConvenio, form]);

  const handleSudmit = (form) => {
    handleSearch({ ...form });
  };

  return (
    <>
      <div className='header-container'>
        <p className='title'>{BUSQUEDA_TITLE}</p>

        <Form
          form={form}
          name='supplierFilterForm'
          layout='vertical'
          onFinish={handleSudmit}
        >
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item name='nombre' label='Nombre'>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className='table-container'>
        <Divider />
        <TableReport
          currentPage={currentPage}
          limit={limit}
          total={total}
          columns={columnas}
          data={resultado}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default SupplierList;
