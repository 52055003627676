import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Spin, Button, Divider, Table, Space } from 'antd';
import {
  listaCuentasBancarias,
  descargarCuentaProveedor,
} from '../../../../../services/admin/suppliersService';

import { sorterText } from '../../../../../utilities/textTools';
import { setExport } from '../../../../../utilities/downloadTools';
import BankAccountNewModal from './bankAccountNewModal/bankAccountNewModal';
import BankAccountEditModal from './bankAccountEditModal/bankAccountEditModal';

const initialState = {
  loading: false,
  resultado: [],
  cuentaEdit: {},
  visibleEditModal: false,
};

class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'BANCO',
        dataIndex: 'nombreBanco',
        sorter: (a, b) => sorterText(a.nombreBanco, b.nombreBanco),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO DE CUENTA',
        dataIndex: 'tipoCuenta',
        sorter: (a, b) => sorterText(a.tipoCuenta, b.tipoCuenta),
        showSorterTooltip: false,
      },
      {
        title: 'NÚMERO DE CUENTA',
        dataIndex: 'nroCuenta',
        sorter: (a, b) => sorterText(a.nroCuenta, b.nroCuenta),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstadoRegistro',
        sorter: (a, b) => sorterText(a.dsEstadoRegistro, b.dsEstadoRegistro),
        showSorterTooltip: false,
      },
      {
        title: 'CIUDAD DE LA CUENTA',
        dataIndex: 'nombreMunicipio',
        sorter: (a, b) => sorterText(a.nombreMunicipio, b.nombreMunicipio),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <Button type='primary' onClick={() => this.showModalEditar(record)}>
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'DESCARGAR',
        dataIndex: '',
        render: (record) => (
          <Button type='primary' onClick={() => this.descargarCuenta(record)}>
            DESCARGAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  showModalEditar = (cuenta) => {
    this.setState({
      ...this.state,
      cuentaEdit: cuenta,
      visibleEditModal: true,
    });
  };

  descargarCuenta = async (record) => {
    const { authToken, scPersona } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await descargarCuentaProveedor(
        authToken,
        scPersona,
        record.scCuenta
      );

      setExport(response, record.scCuenta);
    } finally {
      this.setState({ ...this.state, loading: false });
    }
  };

  getData = async () => {
    const { authToken, scPersona } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listaCuentasBancarias(authToken, scPersona);

      this.setState({
        ...this.state,
        resultado: response.data,
        loading: false,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleModalOnFinish = (e) => {
    this.setState({
      ...this.state,
      cuentaEdit: {},
      visibleEditModal: false,
    });
    this.getData();
  };

  handleEditModalOnClose = () => {
    this.setState({
      ...this.state,
      cuentaEdit: {},
      visibleEditModal: false,
    });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { loading, resultado, cuentaEdit, visibleEditModal } = this.state;
    const { authToken, scPersona, children } = this.props;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <Row gutter={[16, 32]} align='middle'>
          <Col flex='auto'>
            <Space align='baseline' className='sub-title'>
              Lista de cuentas
            </Space>
          </Col>
          <Col flex='none'>
            <span className='button'>
              <BankAccountNewModal
                authToken={authToken}
                scPersona={scPersona}
                onFinish={this.handleModalOnFinish}
              />
            </span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col flex='auto'>
            <div className='table-container'>
              <Table
                rowKey={(record) => record.scCuenta}
                columns={this.columnas}
                dataSource={resultado}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>{children}</Row>
        {visibleEditModal && (
          <BankAccountEditModal
            scPersona={scPersona}
            authToken={authToken}
            data={cuentaEdit}
            visible={visibleEditModal}
            onClose={this.handleEditModalOnClose}
            onFinish={this.handleModalOnFinish}
          />
        )}
      </Spin>
    );
  }
}

export default BankAccount;
