import React from 'react'
import './faq.scss'
import { Row, Col, Card, Typography } from 'antd'


const { Title } = Typography;


class FAQ extends React.Component {

  questions = [{
    title: '1. ¿Cómo son los horarios de negociación y desembolso de facturas?',
    answer: `La plataforma Affirmatum está habilitada todos los días hábiles del año:<br><br>
  <strong>Para pago en menos de 12 horas</strong>: el horario de negociación de facturas es
  únicamente en la mañana y depende del Fondeador con el que quieras
  negociar, todos tienen horarios diferentes que puedes consultar en las
  condiciones de cada Fondeador. Los desembolsos de las facturas
  negociadas diariamente en las horas de la mañana se hacen efectivas ese
  mismo día en horas de la tarde.<br><br>
  <strong>Para pago al día siguiente o fechas futuras:</strong> si no pudiste ingresar en la
  mañana para negociar tus facturas, puedes hacerlo también en horas de la
  tarde para pago el día siguiente, o incluso puede programar para fechas
  futuras, de acuerdo con tus necesidades.`
  }, {
    title: '2. ¿Cuántas cuentas bancarias puedo tener registradas? ¿Es posible que la cuenta registrada sea una cuenta personal?',
    answer: `Puedes registrar hasta 3 cuentas bancarias, todas las cuentas deben
  pertenecer a la empresa emisora de las facturas. No se permiten cuentas
  bancarias personales o cuentas de otras empresas.`
  }, {
    title: '3. ¿Cuánto tiempo se demoran las facturas para cargar en Affirmatum después de ser radicadas con mi cliente?',
    answer: `Cada cliente tiene sus procesos de radicado y contabilización de facturas,
  es importante que tengas en cuenta que solo cargan facturas que estén
  disponibles para pago en el ERP del cliente y que se hayan dado las
  liberaciones internas que el cliente exige.
  Las facturas, por lo general, cargan los primeros 5 días después de
  radicadas.`
  }, {
    title: '4. ¿Cómo hago para habilitar mis facturas en la plataforma y poderlas negociar?',
    answer: `Las facturas se habilitan de forma automática, gracias a la conexión que
  tenemos entre los sistemas de información Affirmatum y el ERP de tu
  cliente.
  Por lo tanto, no tienes que hacer ningún proceso o trámite para que
  carguen tus facturas en Affirmatum, a menos de que haya novedades en la
  
  radicación y/o contabilización de dichas facturas con tu cliente. Estas
  novedades debes revisarlas directamente con el deudor.`
  }, {
    title: '5. ¿Quién es el comprador de mis facturas?',
    answer: `En Affirmatum tenemos muchos compradores, en algunos casos son incluso
  operaciones de Pronto Pago, donde tu cliente es quien desembolsa los
  recursos de las facturas que negociaste al descuento, en otros casos son
  entidades financieras calificadas que compran facturas a través de nuestra
  plataforma transaccional Affirmatum, estando disponibles los recursos
  todos los días del año, y dando cumplimiento a los pago en menos de 12
  horas.`
  }, {
    title: '6. ¿Qué pasa si mi Cliente no le paga la factura al banco cuando llega a vencimiento?',
    answer: `Las operaciones de Confirming son, en su mayoría, descuentos de facturas
  sin responsabilidad o recurso del emisor, es por ello que no tienes porque
  preocuparte por el pago de tu cliente a la entidad financiera, nosotros nos
  encargamos y mitigamos ese riesgo para ti y tu empresa.`
  }, {
    title: '7. ¿Cómo cambiar el correo al que llega la información de Affirmatum?',
    answer: `El correo que tienes registrado en Affirmatum está asociado a tu usuario,
  para cambiar la información de los usuarios tenemos un formato estándar
  que puedes solicitar por cualquier de nuestros canales de atención y
  diligenciarlo nuevamente con la información actualizada.`
  }, {
    title: '8. ¿Cómo puedes registrar un usuario nuevo?',
    answer: `Para registrar usuarios nuevos en Affirmatum tenemos un formato
  estándar que puedes solicitar por cualquier de nuestros canales de
  atención y diligenciarlo con los datos del nuevo usuario.`

  }, {
    title: '9. ¿Dónde puedo verificar las retenciones aplicadas a las facturas?',
    answer: `Las retenciones se pueden visualizar en el detalle de tus facturas, si no
  sale toda la información que necesitas, debes consultarlo con tu cliente.`
  }, {
    title: '10. ¿Cómo se calcula el descuento de una factura?',
    answer: `El descuento de las facturas se calcula con una tasa. La tasa puede variar
  entre los diferentes clientes y fondeadores que tenemos en Affirmatum, si
  quieres conocer el detalle del cálculo matemático, puedes solicitarlo con
  uno de nuestros comerciales.`

  }, {
    title: '11. ¿Quién me entrega el certificado de retenciones?',
    answer: `Los certificados de retenciones se deben solicitar directamente con tu
  cliente.`
  }, {
    title: '12. ¿Cómo habilitar el descuento automático?',
    answer: `El descuento automático es una opción fácil de usar, que se configura una
  única vez, su finalidad es que no tengas que ingresar a la plataforma
  Affirmatum a negociar facturas cada vez que lo necesites, sino que, por el
  contrario, con algunas reglas que tú mismo programas y asignas, las
  facturas se anticipan automáticamente. ¡Así de fácil!`
  }, {
    title: '13. ¿Cómo puedo programar una o mas facturas para fechas futuras?',
    answer: `Ingresando en la opción programación podrás seleccionar cuantas facturas
  necesites para fechas futuras que desees programar. Si quieres asesoría
  puedes contactarte con un comercial o a través de cualquier de nuestros
  canales de atención.`}]

  render() {
    return (
      <div className="content-margin faq">
        <Title level={2} className="faq-title">Preguntas que son frecuentes</Title>
        {/* <p>Bacon ipsum dolor amet andouille drumstick doner jowl capicola shank frankfurter sausage hamburger cow tail chicken brisket rump swine mi vitae nibh condimentum elementum. Suspendisse non eleifend urna. Nulla facilisi. Aliquam suscipit auctor elementum. Donec tempus nisl nec enim pretium, nec consequat tortor iaculis. Suspendisse a molestie enim. Nunc laoreet dui in diam sodales, non varius arcu mattis</p> */}

        <Row justify={"center"} className="help-top-margin">
          <Col span={24}>
            <Card className="card-radius card-shadow">

              {this.questions.map(question => {
                return <Row>
                  <Col span={24}>
                    <Title level={3} className="faq-title">{question.title}</Title>
                  </Col>
                  <Col span={24}>
                    <p dangerouslySetInnerHTML={{ __html: question.answer }}></p>
                  </Col>
                </Row>
              })}
            </Card>
          </Col>
        </Row>



        <Row justify={"center"} className="faq-top-margin">
          <p>Exponencial Confirming S.A.S. Todos los derechos reservados ® 2019</p>
        </Row>
        <Row justify={"center"}>
          <a href={`${process.env.PUBLIC_URL}/tyc`}>Ver políticas de uso</a>
        </Row>
      </div >
    )
  }
}

export default FAQ