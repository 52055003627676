import { Button, Card, Input, Spin, notification,Form } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './consultation.scss';
import { exportarArchivoTransunion } from '../../../../services/admin/creditsService';
import { setExcel } from '../../../../utilities/downloadTools';
import {requiredValueMessage} from '../../../../utilities/admin_validation_messages';
import { totalLength } from '../../../../utilities/validate/textValidate';

const SELECT_PLACE_HOLDER = 'Ingrese un nit...';

const rules = {
  nit: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => totalLength(value, 9),
    },
  ]
};

class Consultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        files: [],
      },
      buttonCargaDisable: false,
      buttonConfirmDisable: true,
      data: [],
      loading: false,
      bulkloadEstado: false,
      bulkloadGuardar: false,
      nombreArchivo: '',
      extensionArchivo: '',
      confirmArchivo: false,
      proveedor: '',
      proveedores: [],
      scProveedor: '',
      proveedorSelect: {
        fetching: false,
        options: [],
      },

      idProveedor: '',
    };

    this.formSearch = React.createRef();
  }

  componentDidMount() {
    this.props.menuHandler('120');
    this.getData();
  }

  handleSubmit = (value) => {
    if (this.state.scProveedor === '') {
      notification.error({
        message: 'El proveedor es requerido',
        duration: 4,
      });
      return;
    }
    this.getData();
  };

  getData = async () => {};

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
  };

  handleOnChangeProveedor = (e) => {
    this.setState({
      ...this.state,
      idProveedor: e.currentTarget.value,
    });
  };

  ejecutarTarea = async () => {

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const res = await exportarArchivoTransunion(this.props.authToken, this.state.idProveedor);  
      setExcel(res, 'reporte_transunion');
    } catch (error) {
      this.setState({
        ...this.state,
        loading: false,
      });
      notification.error({
        message: 'Error generando informe TU',
        description: error.message,
        duration: 5,
      });
    }finally{
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  render() {
    const { loading } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='consultation-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Form onFinish={this.ejecutarTarea}>
              <div className='select-wrapper' style={{ width: '100%' }}>
              <Form.Item
                name='nit'
                label='NIT'
                rules={rules['nit'] || ''}
              >
                <Input
                  placeholder={SELECT_PLACE_HOLDER}
                  allowClear
                  onChange={(e) => this.handleOnChangeProveedor(e)}

                ></Input>
              </Form.Item>

              </div>

              <div className='task-list'>
                <div className='task'>
                  <div className='task-label'>
                    <p className='task-label-text'>
                      ¿Desea que se ejecute la tarea, consultar en Transunion?
                    </p>
                  </div>
                  <div className='task-button'>
                    <Button
                      className='task-button-component'
                      htmlType='submit'
                    >
                      Consulta Transunion
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Card>
        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Consultation);
