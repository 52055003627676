import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { BUSQUEDA_TITLE } from '../../../../../utilities/messages';

const SELECT_PLACE_HOLDER = 'Seleccione...';

const estados = [
  { key: 'A', descripcion: 'Activo' },
  { key: 'I', descripcion: 'Inactivo' },
];

const initialValue = {
  nombre: '',
  scPagador: undefined,
  scProveedor: undefined,
  scFondeador: undefined,
  cdTipoConvenio: undefined,
};

const AgreementFilterFrom = ({
  pagadorSelect,
  handleOnSearchPagador,
  proveedorSelect,
  handleOnSearchProveedor,
  fondeadorSelect,
  handleOnSearchFondeador,
  handleSubmit,
  data,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      fechaRango: [data.fechaDesdeCompra, data.fechaHastaCompra],
    });
    forceUpdate({});
  }, [form, data]);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scFondeador' label='Fondeador'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchFondeador(value)}
                notFoundContent={
                  fondeadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {fondeadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='scPagador' label='Pagador'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchPagador(value)}
                notFoundContent={
                  pagadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {pagadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='scProveedor' label='Proveedor'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchProveedor(value)}
                notFoundContent={
                  proveedorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {proveedorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='cdEstadoRegistro' label='Estado'>
              <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                {estados.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='nombre' label='Nombre Convenio'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AgreementFilterFrom;
