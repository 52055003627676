import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../utilities/messages';

const initialValue = {
  fechaRango: [moment(new Date()), moment(new Date())],
  estadoReintegro: '',
};

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const RefundsFilterForm = ({ companyId, handleSubmit }) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...initialValue });
  }, [companyId, form]);

  const estadoReintegro = [
    { key: 'PEND', value: 'Pendiente' },
    { key: 'REEM', value: 'Reembolsado' },
    { key: 'AOOB', value: 'Aplicado a otra obligación' },
  ];

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='fechaRango'
              label='Rango de fechas de recaudo'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un rango de fechas',
                },
              ]}
            >
              <DatePicker.RangePicker name='fechaRango' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item name='estadoReintegro' label='Estado Reintegro'>
              <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                {estadoReintegro.map((d) => (
                  <Select.Option key={d.key} value={d.key}>
                    {d.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RefundsFilterForm;
