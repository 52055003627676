import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin } from 'antd';
import React, { Component } from 'react';
import TableReport from '../../../../../components/tableReport/tableReport';
import {
  listarBancos,
  transacciones,
  transaccionesExcel,
  transaccionesPlano,
} from '../../../../../services/fondeador/operationServices';
import { downloadFile, setExcel } from '../../../../../utilities/downloadTools';
import { currencyFormat, sorterText } from '../../../../../utilities/textTools';
import AchPaymentsModal from '../achPaymentsModal/achPaymentsModal';
import AchTransactionDetail from '../achTransactionDetail/achTransactionDetail';

const initialState = {
  loading: false,
  resultado: [],
  bancoSelect: {
    options: [],
    fetching: false,
  },
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class AchTransacions extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.componentRef = React.createRef();
  }

  searchData = async () => {
    const { scLoteAch, authToken, companyId } = this.props;
    const { sort, offset, limit } = this.state;
    this.setState({
      ...this.state,
      resultado: [],
      loading: true,
    });

    try {
      const response = await transacciones(authToken, companyId, scLoteAch, {
        sort,
        offset,
        limit,
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleOnSearch = async (value) => {
    const { authToken, companyId } = this.props;

    this.setState({
      ...this.state,
      bancoSelect: {
        options: [],
        fetching: true,
      },
    });
    try {
      const response = await listarBancos(authToken, companyId, value);

      this.setState({
        ...this.state,
        bancoSelect: {
          options: response.data,
          fetching: true,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        bancoSelect: {
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleModalOk = async (value) => {
    const { authToken, companyId, scLoteAch } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await transaccionesPlano(
        authToken,
        companyId,
        scLoteAch,
        value
      );

      downloadFile(response, `Lote_${scLoteAch}`, response.type);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    const { authToken, companyId, scLoteAch } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await transaccionesExcel(
        authToken,
        companyId,
        scLoteAch
      );

      setExcel(response, `Lote_${scLoteAch}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.searchData();
    this.componentRef.current.scrollIntoView();
  }

  componentDidUpdate(props, state) {
    if (props.scLoteAch !== this.props.scLoteAch) {
      this.searchData();
      this.componentRef.current.scrollIntoView();
    }
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      bancoSelect,
    } = this.state;

    const { scLoteAch, companyId, authToken } = this.props;

    this.columnas = [
      {
        title: 'No TRANSACCIÓN ACH',
        dataIndex: 'scTransaccionACH',
        sorter: (a, b) => sorterText(a.scTransaccionACH, b.scTransaccionACH),
        showSorterTooltip: false,
      },
      {
        title: 'BENEFICIARIO',
        dataIndex: 'beneficiario',
        sorter: (a, b) => sorterText(a.beneficiario, b.beneficiario),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO ID',
        dataIndex: 'tipoId',
        sorter: (a, b) => sorterText(a.tipoId, b.tipoId),
        showSorterTooltip: false,
      },
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.id, b.id),
        showSorterTooltip: false,
      },
      {
        title: 'BANCO',
        dataIndex: 'banco',
        sorter: (a, b) => sorterText(a.banco, b.banco),
        showSorterTooltip: false,
      },
      {
        title: 'CÓDIGO ACH',
        dataIndex: 'cdACH',
        sorter: (a, b) => sorterText(a.cdACH, b.cdACH),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO CUENTA',
        dataIndex: 'tipoCuenta',
        sorter: (a, b) => sorterText(a.tipoCuenta, b.tipoCuenta),
        showSorterTooltip: false,
      },
      {
        title: 'NÚMERO CUENTA',
        dataIndex: 'numeroCuenta',
        sorter: (a, b) => sorterText(a.numeroCuenta, b.numeroCuenta),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR A PAGAR',
        dataIndex: 'valorAPagar',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.valorAPagar - b.valorAPagar,
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: 'DETALLE',
        render: (text, record) => (
          <AchTransactionDetail
            companyId={companyId}
            authToken={authToken}
            scLoteAch={scLoteAch}
            scTransaccionACH={record.scTransaccionACH}
          />
        ),
        showSorterTooltip: false,
      },
    ];

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div ref={this.componentRef} className='table-container'>
          <Row gutter={30} className='bill-selection'>
            <Col span={12}>
              <p className='title'>
                Transacciones ACH asociadas al Lote No {scLoteAch}
              </p>
            </Col>
            <Col span={10} className='export'>
              <span className='button'>
                <AchPaymentsModal
                  loteSeleccionado={scLoteAch}
                  bancoSelect={bancoSelect}
                  handleModalOk={this.handleModalOk}
                  handleOnSearch={this.handleOnSearch}
                />
              </span>
            </Col>
            <Col span={1} className='export'>
              <span className='button'>
                <Button
                  size='small'
                  className='btn-excel'
                  title='Exportar a excel'
                  onClick={this.exportExcel}
                  icon={<FileExcelOutlined />}
                ></Button>
              </span>
            </Col>
          </Row>
          <Divider />

          <TableReport
            currentPage={currentPage}
            limit={limit}
            total={total}
            resumen={resumen}
            columns={this.columnas}
            data={resultado}
            handleChange={this.handleChange}
          />
        </div>
      </Spin>
    );
  }
}

export default AchTransacions;
