import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import equal from 'fast-deep-equal';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CommercialReferenceModal, {
  initialValuesCommercialRef,
} from '../../../../../components/commercialReferenceModal/commercialReferenceModal';

const initialState = {
  referenciasComerciales: [],
  visibleModal: false,
  data: initialValuesCommercialRef,
};

class CommercialReferences extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'ENTIDAD',
        dataIndex: 'entidad',
      },
      {
        title: 'NOMBRE DEL CONTACTO',
        dataIndex: 'nombre',
      },
      {
        title: 'DIRECCIÓN',
        dataIndex: 'direccion',
      },
      {
        title: 'TELÉFONO',
        dataIndex: 'telefono',
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <EditOutlined
            className='info'
            onClick={() => this.handleOnOpenModal(record)}
          />
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <DeleteOutlined
            className='info'
            onClick={() => this.deleteShareholder(record)}
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  deleteShareholder = (record) => {
    const { referenciasComerciales } = this.state;

    let temps = referenciasComerciales.filter(
      (value) => value.key !== record.key
    );

    this.setState({ ...this.state, referenciasComerciales: temps });
  };

  handleOnFinish = () => {
    const { onFinish } = this.props;
    const { referenciasComerciales } = this.state;

    if (referenciasComerciales.length === 0) {
      referenciasComerciales.warn({
        title: 'Alerta',
        message: 'Debe añadir las referencias comerciales',
        duration: 4,
      });
      return;
    }

    onFinish('5', { referenciasComerciales: referenciasComerciales });
  };

  handleOnOpenModal = (record) => {
    this.setState({ ...this.state, visibleModal: true, data: record });
  };

  handleOnFinishModal = (formValue) => {
    const { referenciasComerciales } = this.state;

    let temps = [];

    if (formValue.key && formValue.key !== '') {
      temps = referenciasComerciales.map((value) =>
        value.key === formValue.key ? formValue : value
      );
    } else {
      let uuid = uuidv4();
      temps = [{ ...formValue, key: uuid }, ...referenciasComerciales];
    }

    this.setState({
      ...this.state,
      referenciasComerciales: temps,
      visibleModal: false,
      data: initialValuesCommercialRef,
    });
  };

  handleOnCloseModal = () => {
    this.setState({
      ...this.state,
      visibleModal: false,
    });
  };

  componentDidMount() {
    if (this.props.person) {
      this.init();
    }
  }

  componentDidUpdate(props, state) {
    if (!equal(props.person, this.props.person)) {
      this.init();
    }
  }

  init = () => {
    const {
      person: { referenciasComerciales },
    } = this.props;

    this.setState({
      ...this.state,
      referenciasComerciales: referenciasComerciales
        ? referenciasComerciales.map((ref) => ({
            ...ref,
            key: ref.scReferencia,
          }))
        : [],
    });
  };

  render() {
    const { referenciasComerciales, visibleModal, data } = this.state;
    return (
      <div className='content-container'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='end'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6} style={{ textAlign: 'right' }}>
              <Button
                className='btn-radius'
                type='primary'
                onClick={() =>
                  this.handleOnOpenModal(initialValuesCommercialRef)
                }
              >
                Agregar referencia comercial
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
            <Col flex='auto' style={{ overflow: 'auto' }}>
              <Table
                style={{ width: '100%' }}
                columns={this.columnas}
                dataSource={referenciasComerciales}
                pagination={false}
              ></Table>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            align='middle'
            justify='center'
            style={{ marginBottom: '16px' }}
          >
            <Col span={6}>
              <Button
                className='form-btn btn-radius'
                type='primary'
                htmlType='submit'
                onClick={this.handleOnFinish}
              >
                Siguiente
              </Button>
            </Col>
          </Row>

          <CommercialReferenceModal
            visible={visibleModal}
            data={data}
            onFinish={this.handleOnFinishModal}
            onClose={this.handleOnCloseModal}
          />
        </div>
      </div>
    );
  }
}

export default CommercialReferences;
