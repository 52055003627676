import { Button, Col, Form,  Modal, Row, Select } from 'antd'
import React from 'react'
import { requiredValueMessage } from '../../../../../utilities/admin_validation_messages'

const initialFormValue = {
  cdtipoProducto: ''
}

const rules = {
  cdtipoProducto: [
    { required: true, message: requiredValueMessage() },
  ]
}

const ModalTipoProducto = ({
  handleOnFinish,
  tipoProductoData
}) => {
  return (
    <Form  initialValues={initialFormValue} onFinish={handleOnFinish}>
      <Row  gutter={[24, 24]} >
            <Col span={22}>
                <Form.Item 
                    name='cdtipoProducto'
                    label="Seleccione el tipo Producto"
                    rules={rules['cdtipoProducto'] || ''}
                >
                    <Select
                        placeholder="Seleccione"
                        allowClear
                        defaultActiveFirstOption={false}
                    >
                    {tipoProductoData.map((item) => (
                    <Select.Option key={item.codigo} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                    </Select>
                </Form.Item>
            </Col>
      </Row>
      <Row justify="center" >
            <Col span={6}>
                <Button
                    htmlType='submit'
                    className='form-btn btn-radius'
                    type='primary'
                >
                    Guardar
                </Button>
            </Col>
      </Row>
    </Form>
  )
}

export default ModalTipoProducto