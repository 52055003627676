import React, { useEffect, useState } from 'react';
import { Upload, Button, Form, Row, Col } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  sizeFileValidation,
  typeFileValidation,
} from '../../../../utilities/validate/fileValidate';

const { Dragger } = Upload;

const BulkLoadForm = ({
  disabledButton,
  handleSubmit,
  formValue,
  downloadTemplate,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({}); // To disable submit button at the beginning.

  form.setFieldsValue(formValue);

  useEffect(() => {
    forceUpdate({});
  }, []);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  return (
    <div className='file-upload'>
      <p className='file-upload-message'>
        Archivo para cargar facturas de forma masiva
      </p>
      <Form form={form} onFinish={handleSubmit} initialValues={formValue}>
        <Form.Item>
          <Form.Item
            name='files'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            rules={[
              {
                type: 'array',
                required: true,
                message: 'Debe seleccionar un archivo',
              },
              {
                validator: (_, value) =>
                  typeFileValidation(value, [
                    'application/xml',
                    'text/xml',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  ]),
                message: 'El archivo debe ser de tipo xml, xls o xlsx',
              },
              {
                validator: (_, value) => sizeFileValidation(value, 10),
                message: 'El tamaño máximo del archivo es de 10MB',
              },
            ]}
            noStyle
          >
            <Dragger
              name='files'
              action='/upload.do'
              maxCount={0}
              multiple={false}
              accept='application/xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>
                Suelta el archivo aquí o selecciona alguno.
              </p>
              <p className='ant-upload-hint'>
                Los formatos permitidos son xml, xls y xlsx
              </p>
            </Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item className='template-download'>
          <Row align='middle' justify='center'>
            <Col>
              <Button
                className='btn btn-blue'
                htmlType='submit'
                disabled={disabledButton}
              >
                Cargar archivo
              </Button>
              <a href='#' onClick={downloadTemplate}>
                Descargar plantilla
              </a>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BulkLoadForm;
