import { InfoCircleFilled } from '@ant-design/icons';
import { Card, Col, Input, InputNumber, Popover, Row, Select } from 'antd';
import equals from 'fast-deep-equal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { listarCuentasProveedor } from '../../../../services/proveedor/billServices';

const { Option } = Select;
class DiscountsDetail extends React.Component {
  constructor(props) {
    super(props);
    if (props.isNew) {
      if (props.newAgreements[0]) {
        this.state = this.mapState(
          this.mapNewAgreement(props.newAgreements[0])
        );
        this.state.isNew = true;
      } else {
        this.state = {
          isNew: true,
        };
      }
    } else {
      this.state = this.mapState(props);
    }
  }

  inputNumberRef = (index) => (element) => {
    this[`${index}_ref`] = element;
  };

  mapNewAgreement({
    scConvenio,
    nombreConvenio,
    snDescAuto,
    nmDiasDescAuto,
    cdDiaDescAuto,
    descAutoPreferencia,
    scCuenta,
    tipoConvenio,
    fondeadorCuentaDto,
    company,
  }) {
    return {
      scConvenio: scConvenio,
      name: nombreConvenio,
      checked: snDescAuto == 'S',
      daysAuto: nmDiasDescAuto,
      daysBusiness: cdDiaDescAuto,
      preference: descAutoPreferencia,
      selectedAccount: scCuenta,
      typeAgreement: tipoConvenio,
      accounts: fondeadorCuentaDto,
      company: company,
    };
  }

  mapState(props) {
    if (Array.isArray(props.accounts)) {
      return {
        ...this.state,
        loading: false,
        scConvenio: props.scConvenio,
        name: props.name,
        checked: props.checked,
        daysAuto: props.daysAuto,
        daysBusiness: props.daysBusiness || 'A',
        accounts: props.accounts.sort((a, b) =>
          (a.preferencia || 9999) >= (b.preference || 9999) ? 1 : -1
        ),
        preference: props.preference,
        selectedAccount: props.selectedAccount,
        typeAgreement: props.typeAgreement,
        userAccountbyRate: props.userAccountbyRate || {},
        company: props.company,
      };
    }
    return {};
  }

  componentDidMount() {
    if (this.state.isNew && this.props.newAgreements[0]) {
      this.setState({
        ...this.state,
        ...this.mapState(
          this.mapNewAgreement(
            this.props.newAgreements[this.state.selectedNewAgreement]
          )
        ),
      });
    }

    let { authtoken, company } = this.props;

    listarCuentasProveedor(authtoken, company).then((response) => {
      this.setState({
        ...this.state,
        userAccounts: [...response.data],
      });
    });
  }

  componentDidUpdate(props, state) {
    if (props.scConvenio !== this.props.scConvenio) {
      this.setState(this.mapState(this.props));
    }

    if (!equals(props.newAgreements, this.props.newAgreements)) {
      this.setState({
        ...this.state,
        ...this.mapState(this.mapNewAgreement(this.props.newAgreements[0])),
      });
    }
  }

  changeSelectedNewAgreement(index) {
    this.setState({
      ...this.state,
      ...this.mapState(this.mapNewAgreement(this.props.newAgreements[index])),
      selectedNewAgreement: index,
    });
  }

  siwtchChange(checked) {
    this.setState({
      ...this.state,
      checked: checked,
    });
  }

  radioChange = (e) => {
    this.setState({
      ...this.state,
      preference: e.target.value,
    });
  };

  changeAccountbyEntity(account, index) {
    this.state.accounts[index].scCuenta = account;
  }

  changeAccountbyRate(accountId) {
    this.state.userAccountbyRate = this.state.userAccounts.find(
      (x) => x.scCuenta === accountId
    );
  }

  renderAccountsTitle() {
    if (this.state.typeAgreement !== 'prontoPago') {
      if (
        this.state.typeAgreement &&
        this.state.typeAgreement === 'confirming'
      ) {
        return (
          <Row className='agree-mod-filters-marginbot'>
            <Col span={18}>FONDEADOR</Col>
            <Col span={6}>CUENTA</Col>
          </Row>
        );
      } else {
        return (
          <Row className='agree-mod-filters-marginbot'>
            <Col span={18}>ESTE CONVENIO ES PRONTO PAGO</Col>
          </Row>
        );
      }
    }
  }

  renderAgreementSelector() {
    return (
      <Select
        size='small'
        defaultValue={0}
        value={`${this.state.selectedNewAgreement}`}
        onChange={(e) => this.changeSelectedNewAgreement(e)}
      >
        {this.props.newAgreements.map((newAgreement, index) => (
          <Option title={newAgreement.nombreConvenio} key={index}>
            {newAgreement.nombreConvenio}
          </Option>
        ))}
      </Select>
    );
  }

  renderPreferenceSection() {
    if (this.state.typeAgreement !== 'prontoPago') {
      return this.state.accounts.map((item, index) => {
        console.log(item);
        return (
          <Row className='agree-mod-convenio-center' key={item.scFondeador}>
            <Col span={3}>{index + 1}</Col>
            <Col span={5}>{item.nombre}</Col>
            <Col span={2}>{/*VACIO CONCIENTEMENTE*/}</Col>
            <Col span={5}>
              <Popover
                data-html='true'
                content={
                  <div>
                    <p>Tasa: {item.descFond.tasaVigente.toFixed(2)}</p>
                    <p>
                      Dias de pago:{' '}
                      {item.descFond.diasPago.join().replace(',', ', ')}
                    </p>
                  </div>
                }
                title='Detalles'
              >
                <InfoCircleFilled className='agree-icon-size agree-color-prim' />
              </Popover>
            </Col>
            <Col span={9}>
              {item.dsCuenta
                ? `${item.dsBanco} - ${item.dsCuenta} - ${item.dsTipoCuenta}`
                : ''}
            </Col>
          </Row>
        );
      });
    }
    // }
  }

  render() {
    return (
      <Row className='agree-marginbot'>
        <Card className='card-container card-radius card-shadow'>
          <Row className='agree-mod-name'>
            <Col span={12} className='agree-form-name-col'>
              {this.state.name}
            </Col>
            {this.state.isNew ? (
              <Col span={12} className='agree-form-name-reset'>
                Seleccionar Pagador : {this.renderAgreementSelector()}
              </Col>
            ) : null}
          </Row>
          <Row className='agree-mod-filters-marginbot'>
            <Col span={8}>Estado:</Col>
            <Col span={16}>
              <Input
                readOnly
                value={this.state.checked ? 'Activo' : 'Inactivo'}
              />
            </Col>
          </Row>

          <Row className='agree-mod-filters-marginbot'>
            <Col span={8}>Días para la aceptación automática:</Col>
            <Col span={16}>
              <InputNumber
                readOnly
                min={0}
                defaultValue={'0'}
                value={this.state.daysAuto || 0}
                onChange={(e) => this.changeDaysAuto(e)}
              />
            </Col>
          </Row>

          <Row className='agree-mod-filters-marginbot'>
            <Col span={8}>Días de aceptación no hábil:</Col>
            <Col span={16}>
              <Input
                readOnly
                size='small'
                value={
                  this.state.daysBusiness === 'P' ? 'POSTERIOR' : 'ANTERIOR'
                }
              />
            </Col>
          </Row>

          <Row className='agree-mod-filters-marginbot'>
            <Col span={8}>Tipo de preferencia:</Col>
            <Col span={16}>
              <Input
                readOnly
                value={
                  this.state.preference === 'F' ? 'ENTIDAD FINANCIERA' : 'TASA'
                }
              />
            </Col>
          </Row>
          {this.renderAccountsTitle()}
          {this.renderPreferenceSection()}
        </Card>
      </Row>
    );
  }
}

DiscountsDetail.propTypes = {
  authtoken: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  isNew: PropTypes.bool,
  daysAuto: PropTypes.number,
  daysBusiness: PropTypes.string,
  accounts: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    authtoken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps, {})(DiscountsDetail);
