import { Button, Card, Col, notification, Spin, Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CalculateVolumeModal from '../../../../../components/calculateVolumeModal/calculateVolumeModal';
import RangosComisionModal from '../../../../../components/rangosComisionModal/rangosComisionModal';
import {
  calcularRangos,
  editarGrupoEmpresarial,
  getGrupoEmpresarial,
  listaHorasCarga,
  listaHorasDesbloqueo,
} from '../../../../../services/admin/businessGroupsService';
import history from '../../../../../services/history';
import { isEqualsToObject } from '../../../../../utilities/compare';
import BasicDataForm, {
  initialGroupValuesForm,
} from '../basicDataForm/basicDataForm';
import '../businessGroups.scss';
import CompaniesGrups from '../companiesGrups/companiesGrups';
const BUSIESS_GROUP_TITLE = 'Grupo empresarial';

const initialState = {
  activeTab: '1',
  loading: false,
  horasCarga: [],
  horasDesbloqueo: [],
  configModalVisible: false,
  rangoMetas: [],
  rangoVolumen: {},
  data: { ...initialGroupValuesForm },
  cdTipoCliente: undefined,
  rangoComisionVisible: false,
  nmRangosCom: '',
  rangosCom: [],
};

class BusinessGroupsEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  redirectToGroup = () => {
    history.push(`${process.env.PUBLIC_URL}/businessGroups`);
  };

  handleOnFinish = async (formValue) => {
    const { rangoMetas, rangosCom } = this.state;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      if (rangosCom !== undefined && formValue.nmRangosCom+1 == rangosCom.length) {
        const response = await editarGrupoEmpresarial(this.props.authToken, {
          ...formValue,
          rangoMetas,
          rangosCom,
        });
  
        notification.success({
          message: BUSIESS_GROUP_TITLE,
          description: response.message,
          duration: 6,
        });
  
        this.setState({
          ...this.state,
          cdTipoCliente: formValue.cdTipoCliente,
        });
      } else {
        notification.warning({
          message: 'Falta configurar los rangos de comisión',
        });
      }
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getData = async () => {
    const { authToken, scGrupo } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await getGrupoEmpresarial(authToken, scGrupo);

      const cargaResponse = await listaHorasCarga(authToken);

      const desbloqueoResponse = await listaHorasDesbloqueo(authToken);

      this.setState({
        ...this.state,
        horasCarga: cargaResponse.data,
        horasDesbloqueo: desbloqueoResponse.data,
        data: response.data,
        rangoMetas: response.data.rangoMetas
          ? response.data.rangoMetas
          : initialState.rangoMetas,
        rangosCom: response.data.rangosCom,
        nmRangosCom: response.data.nmRangosCom,
        rangoVolumen: {
          nmMetaPiso: response.data.nmMetaPiso,
          nmMetaTecho: response.data.nmMetaTecho,
          nmRangosMeta: response.data.nmRangosMeta,
        },
        cdTipoCliente: response.data.cdTipoCliente,
        loading: false,
      });
    } catch {
      this.redirectToGroup();
    }
  };

  handleOnConfigVolumen = async (rangoVolumen) => {
    const { authToken } = this.props;

    if (this.validChangeRangoVolumen(rangoVolumen)) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await calcularRangos(authToken, rangoVolumen);

        this.setState({
          ...this.state,
          configModalVisible: true,
          loading: false,
          rangoMetas: response.data,
          rangoVolumen: rangoVolumen,
        });
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    } else {
      this.setState({
        ...this.state,
        configModalVisible: true,
      });
    }
  };

  validChangeRangoVolumen = (rangoVolumenPro) => {
    const { rangoVolumen, rangoMetas } = this.state;

    return (
      rangoMetas.length === 0 ||
      !isEqualsToObject(rangoVolumen, rangoVolumenPro)
    );
  };

  handleOnFinishModal = (rangoMetas) => {
    this.setState({
      ...this.state,
      configModalVisible: false,
      rangoMetas: rangoMetas,
    });
  };

  handleOnClose = () => {
    this.setState({
      ...this.state,
      configModalVisible: false,
    });
  };

  handleTabOnChange = (e) => {
    this.setState({
      ...this.state,
      activeTab: e,
    });
  };

  handleOnFinishRangoModal = (rangosCom) => {
    this.setState({
      ...this.state,
      rangosCom,
      rangoComisionVisible: false,
    });
  };

  handleOnRangoModalClose = () => {
    this.setState({
      ...this.state,
      rangoComisionVisible: false,
    });
  };

  handleOnConfigRangoComision = (nmRangosComNew) => {
    const { nmRangosCom, rangosCom } = this.state;
    this.setState({
      ...this.state,
      rangoComisionVisible: true,
      nmRangosCom: nmRangosComNew,
      rangosCom: nmRangosCom !== nmRangosComNew ? [] : rangosCom,
    });
  };

  componentDidMount() {
    this.props.menuHandler('300');
    this.getData();
  }

  render() {
    const {
      activeTab,
      loading,
      horasCarga,
      horasDesbloqueo,
      configModalVisible,
      rangoMetas,
      data,
      rangoComisionVisible,
      nmRangosCom,
      rangosCom,
      cdTipoCliente,
    } = this.state;

    const { authToken, scGrupo } = this.props;

    return (
      <div className='businessGroups-style'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <Tabs activeKey={activeTab} onChange={this.handleTabOnChange}>
            <Tabs.TabPane key={'1'} tab='Datos básicos'>
              <Spin tip='Cargando...' spinning={loading}>
                <BasicDataForm
                  data={data}
                  horasDesbloqueo={horasDesbloqueo}
                  horasCarga={horasCarga}
                  onConfigVolumen={this.handleOnConfigVolumen}
                  onFinish={this.handleOnFinish}
                  onConfigRangoComision={this.handleOnConfigRangoComision}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToGroup}
                      >
                        Lista de Grupos empresariales
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane key={'2'} tab='Compañías'>
              <CompaniesGrups
                authToken={authToken}
                scGrupo={scGrupo}
                cdTipoCliente={cdTipoCliente}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>

        <CalculateVolumeModal
          visible={configModalVisible}
          data={rangoMetas}
          onFinish={this.handleOnFinishModal}
          onClose={this.handleOnClose}
        />

        <RangosComisionModal
          visible={rangoComisionVisible}
          nmRangosCom={nmRangosCom}
          data={rangosCom}
          onFinish={this.handleOnFinishRangoModal}
          onClose={this.handleOnRangoModalClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scGrupo = atob(props.match.params.scGrupo);
  return {
    scGrupo: scGrupo,
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps, {})(BusinessGroupsEditForm));
