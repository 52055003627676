import React from 'react';
import { Button, Col, Form, Row, DatePicker } from 'antd';
import { requiredValueMessage } from '../../../../utilities/admin_validation_messages';
import { DATE_FORMAT } from '../../../../utilities/messages';

const rules = {
  diasPago: [{ required: true, message: requiredValueMessage() }],
};

const BulkloadNextForm = ({ handleSubmit, formValue, handleSubmitVolver }) => {
  const [form] = Form.useForm();

  return (
    <div className='bulkloadNextForm'>
      <h4 align='middle' justify='center'>
        Factura {formValue.cdFactura}
      </h4>
      <Form
        name='content_form'
        layout='vertical'
        className='bulkload-datos-pagador'
        form={form}
        hideRequiredMark
        onFinish={handleSubmit}
        initialValues={formValue}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label='Fecha estimada de pago:'
              name='calendarioPagoC'
              rules={rules['diasPago']}
            >
              <DatePicker
                className='calendario'
                format={DATE_FORMAT}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align='middle' justify='center'>
          <Col span={4}>
            <Button className='btn btn-green' onClick={handleSubmitVolver}>
              Volver
            </Button>
          </Col>
          <Col span={4}>
            <Button className='btn btn-green' type='primary' htmlType='submit'>
              Finalizar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BulkloadNextForm;
