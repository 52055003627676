import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Button,
  Select,
  Spin,
  notification,
  TimePicker,
} from 'antd';
import '../../operation.scss';
import AchTransacions from '../achTransactions/achTransactions';
import { lotePago } from '../../../../../services/fondeador/operationServices';
import { DATE_FORMAT } from '../../../../../utilities/messages';

const { Option } = Select;

const initialState = {
  loading: false,
  scLoteAch: '',
};

class AchPayments extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.formRef = React.createRef();
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.formRef.current.resetFields();
      this.setState({
        ...this.state,
        scLoteAch: '',
      });
    }
  }

  hanlegenerateACHSubmit = async (value) => {
    if (this.props.company.company === '0') {
      notification.warning({
        message: 'Todas las compañías seleccionadas',
        description:
          'Por favor seleccione una compañía antes de generar el lote',
      });
      return;
    }

    this.setState({
      ...this.state,
      loading: false,
    });

    try {
      const response = await lotePago(
        this.props.authToken,
        this.props.company.company,
        {
          fechaCorte: `${value.date.format('YYYYMMDD')}${value.time.format(
            'HHmm'
          )}`,
          tipoDetallePago: value.detailType,
        }
      );

      this.setState({
        ...this.state,
        scLoteAch: response.data[0],
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        scLoteAch: '',
      });
    }
  };

  render() {
    const { scLoteAch, loading } = this.state;
    return (
      <>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <div className='header-container'>
            <p className='title'>Generar lote de pago ACH</p>
            <Form
              ref={this.formRef}
              name='content_form'
              layout='vertical'
              className='filters-container'
              onFinish={this.hanlegenerateACHSubmit}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name='detailType'
                    label='Tipo detalle'
                    rules={[
                      {
                        required: true,
                        message: 'por favor seleccione un tipo detalle',
                      },
                    ]}
                  >
                    <Select
                      defaultActiveFirstOption={true}
                      name='detailType'
                      value={this.state.detailType}
                      onChange={(e) =>
                        this.setState({ ...this.state, detailType: e })
                      }
                    >
                      <Option key={'TODOS'} value={'TODOS'}>
                        Todos
                      </Option>
                      <Option key={'PROV'} value={'PROV'}>
                        Proveedores
                      </Option>
                      <Option key={'EXPO'} value={'EXPO'}>
                        Exponencial Confirming
                      </Option>
                      <Option key={'PROVRET'} value={'PROVRET'}>
                        Retenido Proveedor
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='date'
                    label='Fecha Corte'
                    rules={[
                      {
                        required: true,
                        message: 'por favor seleccione una fecha de corte',
                      },
                    ]}
                  >
                    <DatePicker
                      name='date'
                      value={this.state.date}
                      onChange={(moment, string) =>
                        this.setState({ ...this.state, date: moment })
                      }
                      format={DATE_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name='time'
                    label='Hora'
                    rules={[
                      {
                        required: true,
                        message: 'por favor seleccione una hora',
                      },
                    ]}
                  >
                    <TimePicker
                      name='time'
                      value={this.state.time}
                      onChange={(moment, string) =>
                        this.setState({ ...this.state, time: moment })
                      }
                      format='HH:mm'
                      minuteStep={10}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify='left'>
                <Col span={8}>
                  <Form.Item>
                    <Button
                      className='form-btn btn-radius'
                      type='primary'
                      htmlType='submit'
                      loading={loading}
                    >
                      Generar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          {scLoteAch !== '' && (
            <AchTransacions
              companyId={this.props.company.company}
              authToken={this.props.authToken}
              scLoteAch={scLoteAch}
            />
          )}
        </Spin>
      </>
    );
  }
}

export default AchPayments;
