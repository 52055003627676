import {
  FilePdfTwoTone,
  FileTextOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Divider, Row, Space, Spin, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../components/tableReport/tableReport';
import {setExcel } from '../../../utilities/downloadTools';
import GenerateFiltreForm from './generateFiltreForm/generateFiltreForm';
import { currencyFormat, sorterDate, sorterText } from '../../../utilities/textTools';
import GenerateBillingFiltreForm from './generateFiltreForm/generateBillingFiltreForm';
import { listarCompanias, listarTipoFacturacion } from '../../../services/facturacion/ParametrosService';
import { exportaComisiones, generarFacturacion, listarComisiones } from '../../../services/facturacion/facturacionService';

const initialState = {
  form: {
    feDesde: '',
    feHasta: '',
    scCliente: undefined,
    tipoDetalleFactura:''
  },
  loading: false,
  resultado: [],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  companiaSelect: {
    fetching: false,
    options: [],
  },
  tipoFacturacionSelect: {
    fetching: false,
    options: [],
  },
  selectedKeys:[],
  unselectedKeys: [],
  selectedData:[]
};

class GenerateBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };
    this.columnas = [
      {
        title: 'CLIENTE',
        dataIndex: 'nombreCliente',
        sorter: (a, b) => sorterText(a.nombreCliente, b.nombreCliente),
        showSorterTooltip: false,
      },
      {
        title: 'CONTACTO',
        dataIndex: 'nombreContacto',
        sorter: (a, b) => sorterText(a.nombreContacto, b.nombreContacto),
        showSorterTooltip: false,
      },
      {
        title: 'TELEFONO',
        dataIndex: 'telefonoContacto',
        sorter: (a, b) => sorterText(a.telefonoContacto, b.telefonoContacto),
        showSorterTooltip: false,
      },
      {
        title: 'CORREO',
        dataIndex: 'telefonoContacto',
        sorter: (a, b) => sorterText(a.emailContacto, b.emailContacto),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PENDIENTE',
        dataIndex: 'valorPendiente',
        sorter: (a, b) => sorterText(a.valorPendiente, b.valorPendiente),
        render: (valorPendiente) => <span>{currencyFormat(valorPendiente)}</span>,
        showSorterTooltip: false,
      }
    ];

    this.formSearch = React.createRef();
  }

  componentDidMount() {
    this.props.menuHandler('2');
    this.getInitTipoFacturacion();
  }

  handleOnSearchCompania = async (nombre) => {
    if (nombre && nombre.length >= 0) {
      const { companiaSelect } = this.state;

      this.setState({
        ...this.state,
        companiaSelect: {
          ...companiaSelect,
          options: [],
          fetching: true,
        },
      });

      try {
        const response = await listarCompanias(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            fetching: false,
          },
        });
      }
    }
  };

  getInitTipoFacturacion= async () => {
    const { tipoFacturacionSelect } = this.state;

    this.setState({
      ...this.state,
      tipoFacturacionSelect: {
        ...tipoFacturacionSelect,
        fetching: true,
      },
    });

    try {
      const response = await listarTipoFacturacion(this.props.authToken);
      this.setState({
        ...this.state,
        tipoFacturacionSelect: {
          ...tipoFacturacionSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        tipoFacturacionSelect: {
          ...tipoFacturacionSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      loading: false,
      form: {
        scCliente: value.scCliente !== undefined 
        ? value.scCliente : undefined,
        feDesde: value.fechaRango[0],
        feHasta: value.fechaRango[1],
        tipoDetalleFactura:value.tipoDetalleFactura
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });
    this.getData();
  };

  handleFacturarSubmit = async (value) => {
    const {selectedData} = this.state;
    const fechaFacturacion = value

    if(selectedData.length > 0){
      this.setState({
        ...this.state,
        loading: true,
      });
    try {
      const response = await generarFacturacion(
        this.props.authToken,
        selectedData,
        fechaFacturacion
      );
      notification.success({
        message: 'Generación de facturación completada',
        description: response.message,
        duration: 4,
      });
      this.getData();
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
      notification.error({
        message: 'Error',
        description: "Error al generar la facturación",
        duration: 4,
      });
    } finally{
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  }else{
    notification.warn({
      message: 'Por favor selecciona un registro',
      description: 'Selecciona el registro a facturar',
      duration: 4,
    });
  }
};

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  getData = async () => {
    this.setState({
      ...this.state,
      resultado:[],
      resumen: initialState.resumen,
      loading: true,
    });
    try {
      const response = await listarComisiones(
        this.props.authToken,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        loading: false,
        resultado: response.data,
        total: response.metadata.count,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await exportaComisiones(
        this.props.authToken,
        this.getFiltro()
      );

      setExcel(response, 'Listado_Facturacion_Pendiente');
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      const updatedSelectedKeys = [
        ...this.state.selectedKeys,
        ...changeRows.map((row) => row.key),
      ];
  
      this.setState({
        selectedKeys: updatedSelectedKeys,
        unselectedKeys: [],
        selectAll: selected,
        selectedData: [...this.state.selectedData, ...changeRows],
      });
    } else {
      this.setState({
        selectedKeys: [],
        unselectedKeys: [],
        selectAll: selected,
        selectedData: [],
      });
    }
  };

  onSelect = (record, selected) => {
    const { selectAll, selectedKeys, unselectedKeys, selectedData } = this.state;
  
    if (selected) {
      const updatedSelectedKeys = [...selectedKeys, record.key];
      const updatedSelectedData = [...selectedData, record];
  
      this.setState({
        selectedKeys: updatedSelectedKeys,
        selectedData: updatedSelectedData,
        unselectedKeys: unselectedKeys.filter((key) => key !== record.key),
        selectAll: selectAll && updatedSelectedKeys.length === 0,
      });
    } else {
      const updatedUnselectedKeys = [...unselectedKeys, record.key];
      const updatedSelectedData = selectedData.filter((data) => data.key !== record.key);
  
      this.setState({
        selectedKeys: selectedKeys.filter((key) => key !== record.key),
        unselectedKeys: updatedUnselectedKeys,
        selectedData: updatedSelectedData,
        selectAll: false,
      });
    }
  };
  

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      companiaSelect,
      selectedKeys,
      tipoFacturacionSelect
    } = this.state;

    this.rowSelection = {
      type: 'checkbox',
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      selectedRowKeys: selectedKeys,
    };

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='mandate-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <GenerateFiltreForm
              handleSubmit={this.handleSubmit}
              companiaSelect={companiaSelect}
              handleOnSearchCompania={this.handleOnSearchCompania}
              tipoFacturacionSelect={tipoFacturacionSelect}
            />

            <div className='table-container'>
              <Row gutter={[16, 32]} align='middle'>
                <Col flex='auto'>
                  <Space align='baseline' className='sub-title'>
                    Lista de facturación pendiente
                  </Space>
                </Col>
                {resultado.length > 0 ? (
                  <Col flex='none'>
                    <Space align='baseline'>
                      <Button className='form-btn' type='primary' onClick={this.exportExcel}>
                        Exportar a excel
                      </Button>
                    </Space>
                  </Col>
                ) : null}
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    keyIndex={'nombreCliente'}
                    rowSelection={this.rowSelection}
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    resumen={resumen}
                    columns={this.columnas}
                    data={resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>

              {resultado.length > 0 ? (
                <GenerateBillingFiltreForm
                handleFacturarSubmit={this.handleFacturarSubmit}
                />
                ) : null}
            </div>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(GenerateBilling);
