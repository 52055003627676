import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const initialState = {}

const middleware = [thunk]

let composer = [applyMiddleware(...middleware)]
if (process.env.NODE_ENV === 'development') {
  composer.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
}


const store = createStore(rootReducer, initialState, compose(
  ...composer
))

export default store