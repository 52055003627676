import { Button, Col, Row, Spin, Upload, notification, Select, Tabs} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import bulkloadServicesFall from '../../../../services/admin/bulkloadServiceFall';
import BulkLoadFormFall from './bulkloadformFall/bulkloadformFall';
import BulkLoadTableFall from './bulkloadtableFall/bulkloadtableFall';
import BulkloadNextFormFall from './bulkloadnextformFall/bulkloadnextformFall';
import BulkLoadAgileFall from './bulkloadAgileFall/bulkloadAgileFall';
import { listaProveedores } from '../../../../services/admin/suppliersService';
import { listarProveedores } from '../../../../services/admin/reports/quotasService';
import './bulkloadFall.scss';
import Form from 'antd/lib/form/Form';
import { BUSQUEDA_TITLE, EMPTY_SELECT } from '../../../../utilities/messages';

const SELECT_PLACE_HOLDER = 'Seleccione...';
const { Dragger } = Upload;
const { Option } = Select;

class BulkLoadF4ll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        files: [],
      },
      buttonCargaDisable: false,
      buttonConfirmDisable: true,
      data: [],
      facturasCargadas: [],
      loading: false,
      bulkloadEstado: false,
      bulkloadGuardar: false,
      nombreArchivo: '',
      extensionArchivo: '',
      confirmArchivo: false,
      proveedor: '',
      proveedores: [],
      scProveedor: '',
      proveedorSelect: {
        fetching: false,
        options: [],
      }
    };
    this.refTabla = React.createRef();
  }

  listarProveedores = () => {
    listaProveedores(this.props.authToken, {}).then((response) => {
      if (response.data && Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          proveedores: response.data,
        });
      }
    });
  };

  componentDidMount() {
    this.props.menuHandler('53');
  }

  handleSubmit = (values) => {
    if (this.state.scProveedor == '') {
      notification.error({
        message: 'El proveedor es requerido',
        duration: 4,
      });
      return;
    }
    this.setState({
      ...this.state,
      form: values,
      buttonCargaDisable: true,
      loading: true,
      data: [],
      buttonConfirmDisable: true,
    });

    const { authtoken, company } = this.props.authentication;
    const { scProveedor } = this.state;

    bulkloadServicesFall
      .upload(
        authtoken,
        scProveedor,
        this.state.form.files.map((fileValue) => fileValue.originFileObj)
      )
      .then((response) => {
        this.setState({
          ...this.state,
          data: response.data.map((value, index) => ({ ...value, key: index })),
          buttonCargaDisable: false,
          loading: false,
          nombreArchivo: response.metadata.nombreArchivo,
          extensionArchivo: response.metadata.extensionArchivo,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          data: [],
          buttonCargaDisable: false,
          loading: false,
          nombreArchivo: '',
          buttonConfirmDisable: true,
        });
      });
  };

  confirmUpload = () => {
    this.setState({
      ...this.state,
      buttonCargaDisable: true,
      buttonConfirmDisable: true,
      loading: true,
    });

    const { authtoken } = this.props.authentication;
    const { extensionArchivo, nombreArchivo, scProveedor } = this.state;

    let scPersonaModifica = localStorage.getItem('auth-token');
    let scPersona = JSON.parse(scPersonaModifica).scPersona;

    bulkloadServicesFall
      .confirm(authtoken, scProveedor, nombreArchivo, extensionArchivo, scPersona)
      .then((response) => {
        this.setState({
          ...this.state,
          data: response.data.map((value, index) => ({ ...value, key: index })),
          buttonCargaDisable: false,
          loading: false,
          buttonConfirmDisable: true,
          bulkloadEstado: extensionArchivo === 'xml' ? true : false,
          bulkloadGuardar: false,
          confirmArchivo: true,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          buttonCargaDisable: false,
          buttonConfirmDisable: false,
          loading: false,
          confirmArchivo: false,
        });
      });
  };

  handleSubmitGuardar = (values) => {
    this.setState({
      ...this.state,
      buttonCargaDisable: true,
      loading: true,
      buttonConfirmDisable: true,
    });

    const { authtoken } = this.props.authentication;
    const { nombreArchivo, scProveedor } = this.state;

    let scPersonaModifica = localStorage.getItem('auth-token');
    let scPersona = JSON.parse(scPersonaModifica).scPersona;

    bulkloadServicesFall
      .guardar(authtoken, scProveedor, values, nombreArchivo, scPersona)
      .then((response) => {
        this.setState({
          ...this.state,
          data: response.data.map((value, index) => ({ ...value, key: index })),
          buttonCargaDisable: false,
          loading: false,
          buttonConfirmDisable: false,
          bulkloadEstado: false,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          data: [],
          buttonCargaDisable: false,
          loading: false,
          buttonConfirmDisable: true,
          bulkloadGuardar: true,
          bulkloadEstado: false,
        });
      });
    this.refTabla.current.scrollIntoView({ behavior: 'smooth' });
  };

  componentDidUpdate(props, state) {
    if (
      this.props.authentication.company.company !==
      props.authentication.company.company
    ) {
      this.setState({
        ...state,
        form: {
          files: [],
        },
        buttonCargaDisable: false,
        buttonConfirmDisable: true,
        data: [],
        loading: false,
      });
    }
  }

  downloadTemplate = () => {
    if (this.state.scProveedor == '') {
      notification.error({
        message: 'El proveedor es requerido',
        duration: 4,
      });
      return;
    }
    const { authtoken } = this.props.authentication;
    const { scProveedor } = this.state;
    bulkloadServicesFall.downloadTemplate(authtoken, scProveedor).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.ms-excel;charset=ANSI',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Plantilla_Carga_Factoring.xlt`);
      document.body.appendChild(link);
      link.click();
    });
  };

  handleSubmitVolver = () => {
    this.setState({
      ...this.state,
      form: {
        files: [],
      },
      bulkloadEstado: false,
      bulkloadGuardar: false,
      data: [],
      nombreArchivo: '',
      confirmArchivo: false,
    });
  };


  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;
      const { authtoken, company } = this.props.authentication;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(authtoken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSelectProveedor = (value) => {
    const { scProveedor } = this.state
    this.setState({
      ...this.state,
      scProveedor: value
    })
  }

  handleUploadFacturas = (facturas) => {
    const { authtoken } = this.props.authentication;
    const { scProveedor } = this.state;

    this.setState({loading: true});
    this.setState({ facturasCargadas: facturas });
  
    bulkloadServicesFall
      .guardarAgile(authtoken, scProveedor, facturas)
      .then((response) => {
        this.setState({loading: false});
        
        const hasErrors = response.data.some((item) => item.errors && item.errors.length > 0);
        
        if(response.data.length === 0 || hasErrors) {
          notification.error({
            message: 'No se pudieron cargar las facturas',
            duration: 5,
          });
        } else if (hasErrors) {
          notification.warn({
            message: 'Algunas facturas no se cargaron correctamente',
            duration: 5,
          });
        } else {
          notification.success({
            message: 'Facturas cargadas',
            duration: 5,
          });
        }
      })
      .catch(() => {
        this.setState({loading: false});
      });
  };
  
  render() {
    return (
      <Tabs>
        <Tabs.TabPane key={0} tab='Carga ágil'>
          <div className='content-margin bulkload-style'>
            <div class='card-background-light card-shadow radius'>
              <div className='align-center'>
                <div ref={this.refTabla} className='content-container'>
                  <div className='file-upload'>
                    <div className='header-container'>
                      <p className='title'>{BUSQUEDA_TITLE}</p>
                    </div>
                    <div className='label'>
                      <p className='label-text file-upload-message'>Proveedor</p>
                    </div>
                    <Spin tip='Cargando...' spinning={this.state.loading}>
                      <div className='select-wrapper' style={{width:'200%'}}>
                        <Select
                          placeholder={ EMPTY_SELECT }
                          showSearch
                          allowClear
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={(value) => this.handleOnSearchProveedor(value)}
                          onSelect={(value) => this.handleOnSelectProveedor(value)}
                          notFoundContent={this.state.proveedorSelect.fetching ? <Spin size='small' /> : null}

                        >
                          {this.state.proveedorSelect.options.map((d) => (
                            <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                          ))}  
                        </Select>
                      </div>
                      <div>
                        <BulkLoadAgileFall
                          onUploadFacturas={this.handleUploadFacturas}
                        />
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>   
        </Tabs.TabPane>
        <Tabs.TabPane key={1} tab='Carga masiva'>
          <div className='content-margin bulkload-style'>
            <div className='card-background-light card-shadow radius'>
              <div className='align-center'>
                <div ref={this.refTabla} className='content-container'>
                  <div className='file-upload'>
                    <div className='header-container'>
                      <p className='title'>Búsqueda</p>
                    </div>
                    <div className='label'>
                      <p className='label-text file-upload-message'>Proveedor</p>
                    </div>
                    <div className='select-wrapper' style={{ width: '200%' }}>
                      <Select
                        placeholder={SELECT_PLACE_HOLDER}
                        showSearch
                        allowClear
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={(value) => this.handleOnSearchProveedor(value)}
                        onSelect={(value) => this.handleOnSelectProveedor(value)}
                        notFoundContent={this.state.proveedorSelect.fetching ? <Spin size='small' /> : null}

                      >
                        {this.state.proveedorSelect.options.map((d) => (
                          <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                        ))}
                      </Select>
                    </div>
                    {!this.state.bulkloadEstado && !this.state.bulkloadGuardar && (
                      <>
                        {' '}
                        <BulkLoadFormFall
                          formValue={this.state.form}
                          handleSubmit={this.handleSubmit}
                          disabledButton={this.state.buttonCargaDisable}
                          downloadTemplate={this.downloadTemplate}
                        />
                        <Spin tip='Cargando...' spinning={this.state.loading}>
                          {Array.isArray(this.state.data) &&
                            this.state.data.length >= 1 && (
                              <BulkLoadTableFall
                                data={this.state.data}
                                confirmed={this.state.confirmArchivo}
                              />
                            )}

                          {this.state.data.map((el) => {
                            if (!el.errors) {
                              this.state.buttonConfirmDisable = false;
                            }
                          })}
                          {Array.isArray(this.state.data) &&
                            this.state.data.length > 0 ? (
                            <Row align='middle' justify='center'>
                              <Col>
                                <Button
                                  className='btn btn-green'
                                  onClick={this.confirmUpload}
                                  disabled={this.state.buttonConfirmDisable}
                                >
                                  Confirmar archivo
                                </Button>
                              </Col>
                            </Row>
                          ) : (
                            ''
                          )}
                        </Spin>{' '}
                      </>
                    )}
                  </div>
                  {this.state.bulkloadEstado && (
                    <Spin tip='Cargando...' spinning={this.state.loading}>
                      <BulkloadNextFormFall
                        handleSubmit={this.handleSubmitGuardar}
                        formValue={this.state.data[0]}
                        handleSubmitVolver={this.handleSubmitVolver}
                      />
                    </Spin>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: {
      authtoken: state.auth.auth.access_token,
      company: state.billing.company,
    },
  };
};
export default connect(mapStateToProps)(BulkLoadF4ll);
