import {
  Button,
  Card,
  Col,
  Divider,
  notification,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  editarMarcarPagoAll,
  listarPagosPendientes,
} from '../../../../services/admin/paymentReportsService';
import {
  listarConvenios,
  listarFondeadores,
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../services/admin/reports/quotasService';
import { DATE_POST_FORMAT } from '../../../../utilities/messages';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import CheckPaymentModal from './checkPaymentModal/checkPaymentModal';
import PaymentReportFilterForm from './paymentReportFilterForm/paymentReportFilterForm';
import './paymentReports.scss';

const { Option } = Select;

const initialState = {
  form: {
    fechaDesdeEstimadaPago: '',
    fechaHastaEstimadaPago: '',
    scFondeador: '',
    scProveedor: '',
    scGrupoEmpresarial: '',
    scPagador: '',
    scConvenio: '',
  },
  factura: {},
  visibleCheckPaymentModal: false,
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  resultado: [],
  loading: false,
  selectedKeys: [],
  selectAll: false,
  unselectedKeys: [],
  resumen: [
    {
      id: '',
      value: '',
      index: '0',
      colSpan: 1,
    },
    {
      id: 'total',
      value: 'Total:',
      index: '1',
      colSpan: 5,
    },
    {
      id: 'totalVrPendiente',
      value: currencyFormat(0),
      index: '2',
      colSpan: 1,
    },
  ],
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
};

class PaymentReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: '# FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'PROOVEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE PAGO',
        dataIndex: 'feEstimadaPago',
        sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PENDIENTE',
        dataIndex: 'vrFactura',
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'MARCAR PAGO',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='MARCAR PAGO'
            onClick={() => this.showModalMarcarPago(record)}
          >
            MARCAR PAGO
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  showModalMarcarPago = (factura) => {
    this.setState({
      ...this.state,
      factura: factura,
      visibleCheckPaymentModal: true,
    });
  };

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        grupoSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarConvenios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        fechaDesdeEstimadaPago: value.fechaRango[0].format(DATE_POST_FORMAT),
        fechaHastaEstimadaPago: value.fechaRango[1].format(DATE_POST_FORMAT),
        scFondeador: value.scFondeador,
        scProveedor: value.scProveedor,
        scGrupoEmpresarial: value.scGrupoEmpresarial,
        scPagador: value.scPagador,
        scConvenio: value.scConvenio,
      },
      sort: '',
      currentPage: initialState.currentPage,
      offset: initialState.offset,
      selectedKeys: [],
      selectAll: false,
      unselectedKeys: [],
    });

    this.searchData();
  };

  searchData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      const response = await listarPagosPendientes(
        this.props.authToken,
        this.getFiltro()
      );

      const resumen = this.state.resumen.map((res) => {
        if (response.metadata.sumatorias[res.id] !== undefined) {
          res.value = (
            <span className='format-number'>
              {currencyFormat(response.metadata.sumatorias[res.id])}
            </span>
          );
        }

        return res;
      });

      response.data.forEach((element) => {
        element.key = element.scFactura;
        if (this.state.selectAll) {
          let unselected = this.state.unselectedKeys.find(
            (key) => key === element.key
          );
          if (!unselected) {
            if (!this.state.selectedKeys.find((key) => element.key === key)) {
              this.state.selectedKeys = [
                ...this.state.selectedKeys,
                element.key,
              ];
            }
          }
        }
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        resumen: resumen,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      changeRows.forEach((row) => {
        let selected = this.state.selectedKeys.find((key) => row.key === key);
        if (!selected) {
          this.state.selectedKeys = [...this.state.selectedKeys, row.key];
        }
      });
      this.setState({
        ...this.state,
        unselectedKeys: [],
        selectAll: selected,
      });
    } else {
      this.setState({
        ...this.state,
        selectedKeys: [],
        unselectedKeys: [],
        selectAll: selected,
      });
    }
  };

  onSelect = (record, selected) => {
    const { selectAll } = this.state;
    let unselectedKeys = [];
    if (selected) {
      if (this.state.selectAll) {
        unselectedKeys = this.state.unselectedKeys.filter(
          (key) => key !== record.key
        );
      }
      this.setState({
        ...this.state,
        selectedKeys: [...this.state.selectedKeys, record.key],
        unselectedKeys: unselectedKeys,
      });
    } else {
      if (selectAll) {
        unselectedKeys = [...this.state.unselectedKeys, record.key];
      }
      let filterKeys = this.state.selectedKeys.filter(
        (selectedKey) => selectedKey !== record.key
      );
      this.setState({
        ...this.state,
        selectedKeys: filterKeys,
        unselectedKeys: unselectedKeys,
      });
    }
  };

  marcarPagos = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      let keys = [];
      if (this.state.selectAll) {
        keys = this.state.unselectedKeys;
      } else {
        keys = this.state.selectedKeys;
      }

      const response = await editarMarcarPagoAll(
        this.props.authToken,
        keys,
        this.getFiltro(),
        this.state.selectAll
      );

      notification.success({
        message: 'Marcar Pagos',
        description: response.message,
        duration: 5,
      });

      this.setState({
        ...this.state,
        currentPage: 1,
        offset: 0,
        total: 0,
        resultado: [],
        selectedKeys: [],
        selectAll: false,
        unselectedKeys: [],
      });

      this.searchData();
    } catch (error) {
      notification.error({
        message: 'Marcar Pagos',
        description: 'Se presento un error inesperado',
        duration: 5,
      });
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleModalOnClose = () => {
    this.setState({
      ...this.state,
      factura: {},
      visibleCheckPaymentModal: false,
    });
  };

  handleModalOnFinish = () => {
    this.setState({
      ...this.state,
      currentPage: 1,
      offset: 0,
      total: 0,
      resultado: [],
      selectedKeys: [],
      selectAll: false,
      unselectedKeys: [],
      factura: {},
      visibleCheckPaymentModal: false,
    });

    this.searchData();
  };

  componentDidMount() {
    this.props.menuHandler('43');
  }

  componentDidUpdate(props, state) {}

  render() {
    const { authToken } = this.props;
    const {
      selectedKeys,
      loading,
      fondeadorSelect,
      proveedorSelect,
      grupoSelect,
      pagadorSelect,
      convenioSelect,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      visibleCheckPaymentModal,
      factura,
    } = this.state;

    this.rowSelection = {
      type: 'checkbox',
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      selectedRowKeys: selectedKeys,
    };

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='paymentReports-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <PaymentReportFilterForm
              fondeadorSelect={fondeadorSelect}
              proveedorSelect={proveedorSelect}
              grupoSelect={grupoSelect}
              pagadorSelect={pagadorSelect}
              convenioSelect={convenioSelect}
              handleOnSearchFondeador={this.handleOnSearchFondeador}
              handleOnSearchProveedor={this.handleOnSearchProveedor}
              handleOnSearchGrupo={this.handleOnSearchGrupo}
              handleOnSearchPagador={this.handleOnSearchPagador}
              handleOnSearchConvenio={this.handleOnSearchConvenio}
              handleSubmit={this.handleSubmit}
            />
            <div className='table-container'>
              <Row gutter={30} className='bill-selection'>
                <Col span={12}>
                  <p className='ptable'>Lista de facturas pendientes</p>
                </Col>
              </Row>
              <Divider />
              <TableReport
                keyIndex={'scFactura'}
                rowSelection={this.rowSelection}
                currentPage={currentPage}
                limit={limit}
                total={total}
                resumen={resumen}
                columns={this.columnas}
                data={resultado}
                handleChange={this.handleChange}
              />
              <Row className='notify-container'>
                <Col span={24}>
                  <Button
                    className='form-btn btn-notify'
                    type='primary'
                    htmlType='submit'
                    onClick={this.marcarPagos}
                  >
                    Marcar pagos
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        <CheckPaymentModal
          authToken={authToken}
          visible={visibleCheckPaymentModal}
          scFactura={factura.scFactura}
          onClose={this.handleModalOnClose}
          onFinish={this.handleModalOnFinish}
        />
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(PaymentReports);
