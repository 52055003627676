import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarDescuentosAutomaticos = (
  authtoken,
  scProveedor,
  filtros
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios-proveedor/proveedores/${scProveedor}/listar-descuento-automatico?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarProveedores = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-proveedores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const adicionarCupoContraparte = (
  authtoken,
  scFondeador,
  scConvenio,
  data
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios/${scConvenio}/cupo-convenio/${scFondeador}/cupo-contraparte/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

export const adicionarNuevaLiquidez = (
  authtoken,
  scFondeador,
  scConvenio,
  data
) => {
  data = formatoFechas(data);
  return middleWare(
    axios({
      url: `${baseUrl}/api/convenios/${scConvenio}/cupo-convenio/${scFondeador}/nueva-liquidez/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') ||
          nombre.includes('Hasta') ||
          nombre.includes('Corte') ||
          nombre.includes('fechaCorte')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        if (filtros[nombre]) {
          empty[nombre] = filtros[nombre];
        }
      }
    }
  });
  return empty;
};
