import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { Component } from 'react';
import { duplicarConvenio } from '../../../../../services/admin/operation';
import { listarPagadores } from '../../../../../services/admin/reports/quotasService';
import history from '../../../../../services/history';
import {
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const AGREEMENTS_TITLE = 'Duplicar Convenio';

const initialState = {
  visible: false,
  loading: false,
  pagadorSelect: {
    options: [],
    fetching: false,
  },
};

const initialFormValue = {
  scPagador: '',
  nombre: '',
  dsConvenio: '',
};

const rules = {
  nombre: [
    { required: true, message: requiredValueMessage() },
    { max: 150, message: maxValueMessage(150) },
  ],
  dsConvenio: [
    { required: true, message: requiredValueMessage() },
    { max: 400, message: maxValueMessage(400) },
  ],
};

class DuplicateAgreementModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.form = React.createRef();
  }

  componentDidUpdate(props, state) {
    if (this.props.visible !== props.visible) {
      if (this.props.visible) {
        this.form.current.resetFields();
        this.form.current.setFieldsValue({
          ...initialFormValue,
          scConvenio: this.props.convenio.scConvenio,
          dsConvenio: this.props.convenio.dsConvenio,
        });
      }
    }
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;
      const { authToken } = this.props;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  redirectToEditAgreements = (scConvenio) => {
    history.push(
      `${process.env.PUBLIC_URL}/operation/edit/${btoa(scConvenio)}`
    );
  };

  handleOnFinish = async (formValue) => {
    const { convenio, authToken, onClose } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await duplicarConvenio(authToken, {
        ...formValue,
        scConvenio: convenio.scConvenio,
      });

      notification.success({
        message: AGREEMENTS_TITLE,
        description: response.message,
        duration: 6,
      });

      this.redirectToEditAgreements(response.location);

      this.onClose();
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  render() {
    const { loading, pagadorSelect } = this.state;

    const { visible, convenio, onClose } = this.props;

    const title = `Duplicar Convenio: ${convenio ? convenio.nombre : ''}`;
    return (
      <Modal
        title={title}
        visible={visible}
        className='modal-style'
        footer={null}
        onCancel={onClose}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <Form
            name='content_form'
            layout='vertical'
            ref={this.form}
            onFinish={this.handleOnFinish}
            initialValues={initialFormValue}
            autoComplete={'off'}
          >
            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item
                  label='NOMBRE DEL CONVENIO'
                  name='nombre'
                  rules={rules['nombre'] || ''}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item name='scPagador' label='PAGADOR'>
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => this.handleOnSearchPagador(value)}
                    notFoundContent={
                      pagadorSelect.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {pagadorSelect.options.map((d) => (
                      <Select.Option key={d.codigo}>
                        {d.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label='DESCRIPCIÓN DEL CONVENIO'
                  name='dsConvenio'
                  rules={rules['dsConvenio'] || ''}
                >
                  <Input.TextArea autoSize={{ minRows: 4 }} maxLength={400} />
                </Form.Item>
              </Col>
            </Row>

            <Row justify='center'>
              <Col span={8}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Grabar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default DuplicateAgreementModal;
