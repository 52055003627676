import { ExperimentTwoTone } from '@ant-design/icons';
import {
  Card,
  Divider,
} from 'antd';
import React, { Component } from 'react';

class CondicionesInfo extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }


  render() {
    const { dataFondedor } = this.props
    return (
        <Card className="card-shadow card-radius default-border welcome-message">
        {/* Banco de Bogota */}
        {dataFondedor.scFondeador === '10282' ? (
        <div className="align-left">
        <h3><strong>Horarios de los desembolsos:</strong></h3>
        <p>
        Banco de Bogotá realizará el pago de sus operaciones de factoring durante el mismo día cuando las aceptaciones a las ofertas presentadas se realicen antes de las 11:00 a.m.
        </p>
        <strong>Todos los días hábiles. Se excluyen los días de cierre Bancario.</strong>
        <Divider/>
        <h3><strong>Matricula de Cuentas:</strong></h3>
        <p>
        Recuerde realizar la actualización de su cuenta para pago, mediante la entrega de una Certificación Bancaria. La cuenta debe ser del mismo titular de las facturas a descontar.
        </p>
        </div>
        ): 
        
        /* Bancolombia */
        dataFondedor.scFondeador === '10842' ? (
        <div className="align-left">
        <h3><strong>Cesión de documentos crediticios:</strong></h3>
        <p>
        La selección y transferencia de los documentos crediticios (facturas)
        hecha por usted en AFFIRMATUM constituye la cesión de dichos documentos a favor de Bancolombia S.A. y
        se entenderá como aprobada por Bancolombia S.A. a partir del desembolso del valor de las facturas o, por el contrario, como rechazada, en caso de no realizarse dicho desembolso.
        </p>  
        <p>
        Bancolombia S.A. tomará como referencia para la aplicación de las condiciones financieras la fecha de expedición y de vencimiento indicadas en los sistemas de Exponencial Confirming S.A.S. (AFFIRMATUM) al momento de la cesión.
        </p>
        <Divider/>
        <h3><strong>Matricula de Cuentas:</strong></h3>
        <p>
        Señor proveedor, recuerde que usted podrá matricular en Bancolombia S.A. hasta 3 cuentas para todos los productos ofrecidos por Factoring Bancolombia una marca Bancolombia. Recuerde que la cuenta a la cual se realizará el desembolso debe estar matriculada en Bancolombia S.A.
        </p>
        <h3><strong>Horarios de los desembolsos:</strong></h3>
        <p>
        Los horarios establecidos por Bancolombia S.A. para el desembolso de sus operaciones de factoring son:
        <ol start="tipo">
            <li>
            Si el desembolso es a cuentas de otro banco, tenga en cuenta que deberá realizar la negociación antes de las 10:30 am en días hábiles.
            </li>
            <li>
            Si el desembolso es a cuentas Bancolombia, usted podrá realizar la negociación dentro de los horarios habilitados para realizar operaciones en AFFIRMATUM, hasta las 4:30 pm en días hábiles.
            </li>
        </ol>
        </p>
        <h3><strong>Cesión automática:</strong></h3>
        <p>Bancolombia S.A. le ofrece la posibilidad de hacer la cesión de todos los documentos crediticios a cargo de sus pagadores, con lo cual, una vez AFFIRMATUM notifique a Bancolombia S.A. la aceptación de las facturas a su favor, menos las notas a su cargo, y cumplidos los requisitos de la operación de factoring, Bancolombia S.A. procederá con el desembolso, de acuerdo con las condiciones financieras previamente notificadas a usted.</p>
        </div>
        ):

        /* BTG PACTUAL */
        dataFondedor.scFondeador === '9737' ? (
        <div className="align-left">
        <h3><strong>Horarios de los desembolsos:</strong></h3>
        <p>
        BTG Pactual realizará el pago de sus operaciones de factoring durante el mismo día cuando las aceptaciones a las ofertas presentadas se realicen antes de las 11:30 a.m.
        </p>
        <strong>Todos los días hábiles. Se excluyen los días de cierre Bancario.</strong>
        <Divider/>
        <h3><strong>Matricula de Cuentas:</strong></h3>
        <p>
        Recuerde realizar la actualización de su cuenta para pago, mediante la entrega de una Certificación Bancaria. La cuenta debe ser del mismo titular de las facturas a descontar.
        </p>
        </div>
        ): 

        /* CREDICORP */
        dataFondedor.scFondeador === '11050' ? (
        <div className="align-left">
        <h3><strong>Horarios de los desembolsos:</strong></h3>
        <p>
        Credicorp Capital realizará el pago de sus operaciones de factoring durante el mismo día cuando las aceptaciones a las ofertas presentadas se realicen antes de las 11:00 a.m.
        </p>
        <strong>Todos los días hábiles. Se excluyen los días de cierre Bancario.</strong>
        <Divider/>
        <h3><strong>Matricula de Cuentas:</strong></h3>
        <p>
        Recuerde realizar la actualización de su cuenta para pago, mediante la entrega de una Certificación Bancaria. La cuenta debe ser del mismo titular de las facturas a descontar.
        </p>
        </div>
        ):

        /* COLTEFINANCIERA */
        dataFondedor.scFondeador === '21063' ? (
        <div className="align-left">
        <h3><strong>Horarios de los desembolsos:</strong></h3>
        <p>
        Coltefinanciera realizará el pago de sus operaciones de factoring durante el mismo día cuando las aceptaciones a las ofertas presentadas se realicen antes de las 10:00 a.m.
        </p>
        <strong>Todos los días hábiles. Se excluyen los días de cierre Bancario.</strong>
        <Divider/>
        <h3><strong>Matricula de Cuentas:</strong></h3>
        <p>
        Recuerda realizar la actualización de la cuenta para pago, mediante la entrega de una Certificación Bancaria. La cuenta debe ser del mismo titular de las facturas a descontar.
        </p>
        </div>
        ):

        /* PA EXPONENCIAL */
        dataFondedor.scFondeador === '23232' || dataFondedor.scFondeador === '22040' ? (
        <div className="align-left">
        <h3><strong>Horarios de los desembolsos:</strong></h3>
        <p>
        Exponencial Confirming realizará el pago de sus operaciones de factoring durante el mismo día cuando las aceptaciones a las ofertas presentadas se realicen antes de las 11:00 a.m.
        </p>
        <strong>Todos los días hábiles. Se excluyen los días de cierre Bancario.</strong>
        <Divider/>
        <h3><strong>Matricula de Cuentas:</strong></h3>
        <p>
        Recuerde realizar la actualización de su cuenta para pago, mediante la entrega de una Certificación Bancaria. La cuenta debe ser del mismo titular de las facturas a descontar.
        </p>
        </div>
        ):

        /* SEMPLI */
        dataFondedor.scFondeador === '22850' ? (
        <div className="align-left">
        <h3><strong>Horarios de los desembolsos:</strong></h3>
        <p>
        Sempli realizará el pago de sus operaciones de factoring durante el mismo día cuando las aceptaciones a las ofertas presentadas se realicen antes de las 11:00 a.m.
        </p>
        <strong>Todos los días hábiles. Se excluyen los días de cierre Bancario.</strong>
        <Divider/>
        <h3><strong>Matricula de Cuentas:</strong></h3>
        <p>
        Recuerde realizar la actualización de su cuenta para pago, mediante la entrega de una Certificación Bancaria. La cuenta debe ser del mismo titular de las facturas a descontar. ..
        </p>
        </div>
        ):

        /* SIN CONDICIONES */
        <div className="align-center">
        <h2>
        <strong>CONDICIONES PACTADAS CON TU CLIENTE</strong>
        </h2>
        </div>
        }
        </Card>
    );
  }
}

export default CondicionesInfo;
