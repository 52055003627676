import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import equal from 'fast-deep-equal';
import React, { Component } from 'react';
import {
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser,
} from '../../../../../../utilities/textTools';
import * as numberValidate from '../../../../../../utilities/validate/numberValidate';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

export const initialFoundProviderFormValue = {
  scFondeador: undefined,
  cdTipoTasaFond: 'DTF',
  poBeneficioFondeador: '',
  cdSnPuntosBasicos: 'N',
  cdSnPBProveedorDefecto: 'N',
  poBeneficioExponencial: '',
  cdCategoria: undefined,
  cdTipoCalculoVolumen: undefined,
  nmMetaPiso: '',
  nmMetaTecho: '',
  nmRangosMeta: '',
  poCompraFondeador: '',
  nmHoraMaxOfertas: '',
  vrMontoMin: '',
  poPuntosBajoUsuraFd: '',
  cdSnRecalcOferta: undefined,
  idSAPFondeador: '',
  dsNemotecnicoPagSAFYR: '',
  cdEstadoRegistro: undefined,
  cdSnTipoOperacion: undefined,
  cdSnRadian: undefined,
  cdFormatoACH: undefined,
  poProveedorDefecto: '',
  cdTipoCupo: undefined,
};

const initialState = {
  cdTipoCalculoVolumen: undefined,
};

const tipoTasa = [
  { key: 'DTF', description: 'DTF' },
  { key: 'IPC', description: 'IPC' },
  { key: 'FIJA', description: 'Fija EA' },
  { key: 'IBR30', description: 'IBR 30' },
  { key: 'IBR90', description: 'IBR 90' },
  { key: 'IBR180', description: 'IBR 180' },
];

const snPuntosBasicos = [
  { key: 'S', description: 'PBS' },
  { key: 'N', description: 'Porcentaje' },
];

const cdSnPBProveedorDefecto = [
  { key: 'S', description: 'PBS' },
  { key: 'N', description: 'Porcentaje' },
];

const decision = [
  { key: 'S', description: 'Si' },
  { key: 'N', description: 'No' },
];

const cdSnTipoOperacion = [
  { key: 'CD', description: 'Cesión de derechos' },
  { key: 'EPP', description: 'Endoso en propiedad' },
  { key: 'EG', description: 'Endoso en garantía' },
  { key: 'DEPP', description: 'Doble endoso en propiedad' },
];

const categorias = [
  { key: 'corretaje', description: 'Corretaje' },
  { key: 'corretaje2', description: 'Corretaje 2' },
  { key: 'confirming2', description: 'Confirming 2' },
  { key: 'corretaje3', description: 'Corretaje 3' },
  { key: 'isimple', description: 'Interes Simple' }
];

const tipoCalculoVolumen = [
  { key: 'PAGADOR', description: 'Pagador' },
  { key: 'GRUPO_EMP', description: 'Grupo Empresarial' },
];

const estadoRegistro = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
  { key: 'P', description: 'Pendiente' },
  { key: 'R', description: 'Rechazado' },
  { key: 'E', description: null },
];

const tipoCupos = [
  { key: 'SR', description: 'Sin recurso' },
  { key: 'CR', description: 'Con recurso' },
];

const rules = {
  scFondeador: [{ required: true, message: requiredValueMessage() }],
  cdTipoTasaFond: [{ required: true, message: requiredValueMessage() }],
  poBeneficioFondeador: [{ required: true, message: requiredValueMessage() }],
  cdSnPuntosBasicos: [{ required: true, message: requiredValueMessage() }],
  poBeneficioExponencial: [{ required: true, message: requiredValueMessage() }],
  cdTipoCalculoVolumen: [{ required: true, message: requiredValueMessage() }],
  cdTipoCupo: [{ required: true, message: requiredValueMessage() }],
  nmMetaTecho: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('nmMetaPiso') <= value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('el valor debe ser mayor al valor de Meta Piso Volumen')
        );
      },
    }),
    {
      validator: (_, value) => numberValidate.range(value, 0, 999999999999),
    },
  ],
  nmMetaPiso: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('nmMetaTecho') >= value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('el valor debe ser menor al valor de Meta Techo Volumen')
        );
      },
    }),
    {
      validator: (_, value) => numberValidate.range(value, 0, 999999999999),
    },
  ],
  poCompraFondeador: [{ required: true, message: requiredValueMessage() }],
  nmHoraMaxOfertas: [{ required: true, message: requiredValueMessage() }],
  poPuntosBajoUsuraFd: [{ required: true, message: requiredValueMessage() }],
  idSAPFondeador: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  dsNemotecnicoPagSAFYR: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  cdEstadoRegistro: [{ required: true, message: requiredValueMessage() }],
  poProveedorDefecto: [{ required: true, message: requiredValueMessage() }],
  cdSnPBProveedorDefecto: [{ required: true, message: requiredValueMessage() }],
};

class FoundProviderForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.form = React.createRef();
  }

  getFieldsConfigVolumen = () => {
    const nmMetaPiso = this.form.current.getFieldValue('nmMetaPiso');
    const nmMetaTecho = this.form.current.getFieldValue('nmMetaTecho');
    const nmRangosMeta = this.form.current.getFieldValue('nmRangosMeta');

    if (!nmMetaPiso || nmMetaPiso === '') {
      return {};
    }

    if (!nmMetaTecho || nmMetaTecho === '') {
      return {};
    }

    if (!nmRangosMeta || nmRangosMeta === '' || nmRangosMeta <= 0) {
      return {};
    }

    if (this.form.current.getFieldError('nmMetaPiso').length > 0) {
      return {};
    }

    if (this.form.current.getFieldError('nmMetaTecho').length > 0) {
      return {};
    }

    return { nmMetaPiso, nmMetaTecho, nmRangosMeta };
  };

  handleOnConfigVolumen = () => {
    const { onConfigVolumen, cdTipoConvenio } = this.props;
    const { cdTipoCalculoVolumen } = this.state;
    const rangoMeta = this.getFieldsConfigVolumen();

    let poBeneficioExponencial = 100;

    if (cdTipoConvenio === 'confirming' || cdTipoConvenio === 'factoring') {
      poBeneficioExponencial = this.form.current.getFieldValue(
        'poBeneficioExponencial'
      );
    } else {
      const poBeneficioFondeador = this.form.current.getFieldValue(
        'poBeneficioFondeador'
      );

      poBeneficioExponencial = 100 - poBeneficioFondeador;
    }
    if (JSON.stringify(rangoMeta) !== '{}') {
      onConfigVolumen({
        ...rangoMeta,
        cdTipoCalculoVolumen,
        poBeneficioExponencial,
      });
    } else {
      Modal.warning({
        title: 'CALCULAR VOLUMEN',
        content:
          'para calcular debe seleccionar rango de volumen valido  [Meta Piso Volumen, Meta Tacho Volumen] y un Número de rangos para Volumen mayor a 0',
      });
    }
  };

  handleTipoCalculoVolumenOnChange = (value) => {
    this.setState({ ...this.state, cdTipoCalculoVolumen: value });
  };

  componentDidMount() {
    if (this.props.data) {
      this.initData();
    }
  }

  componentDidUpdate(props, state) {
    if (!equal(props.data, this.props.data)) {
      this.initData();
    }
  }

  initData = () => {
    const data = this.props.data;
    this.form.current.setFieldsValue({
      ...initialFoundProviderFormValue,
      ...data,
    });
    this.setState({
      ...this.state,
      cdTipoCalculoVolumen: data.cdTipoCalculoVolumen,
    });
  };

  render() {
    const {
      cdTipoConvenio,
      onFinish,
      edit = false,
      fondeadorSelect,
      handleOnSearchFondeador,
      tipoBaseDias,
      formtatoACHs,
      poDescuentoMinimoProveedor,
    } = this.props;

    const { cdTipoCalculoVolumen } = this.state;

    const fondeadorState = edit === false && cdTipoConvenio !== 'prontoPago';

    return (
      <div className='header-container'>
        <Form layout='vertical' ref={this.form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col flex='auto'>
              {fondeadorState && (
                <Form.Item name='scFondeador' label='FONDEADOR'>
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => handleOnSearchFondeador(value)}
                    notFoundContent={
                      fondeadorSelect.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {fondeadorSelect.options.map((d) => (
                      <Select.Option key={d.codigo}>
                        {d.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {!fondeadorState && (
                <>
                  <Form.Item name='nombreFondeador' label='FONDEADOR'>
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item name='scFondeador' hidden='true'>
                    <Input readOnly />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>

          <Row gutter={16}>
            {(cdTipoConvenio === 'confirming' ||
              cdTipoConvenio === 'factoring') && (
                <Col span={12}>
                  <Form.Item
                    name='cdTipoTasaFond'
                    label='TASA FONDEADOR'
                    rules={rules['cdTipoTasaFond'] || ''}
                  >
                    <Radio.Group buttonStyle='solid'>
                      {tipoTasa.map((item) => (
                        <Radio.Button
                          key={item.key}
                          value={item.key}
                          className='radio-btn'
                        >
                          {item.description}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}
            <Col span={12}>
              <Form.Item
                label='PORCENTAJE NEGOCIACIÓN'
                name='poBeneficioFondeador'
                rules={rules['poBeneficioFondeador'] || ''}
              >
                <InputNumber
                  min={0}
                  formatter={percentageInputFormat}
                  parser={percentageInputParser}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdSnPBProveedorDefecto'
                label='TASA POR DEFECTO'
                rules={rules['cdSnPBProveedorDefecto'] || ''}
              >
                <Radio.Group buttonStyle='solid'>
                  {cdSnPBProveedorDefecto.map((item) => (
                    <Radio.Button
                      key={item.key}
                      value={item.key}
                      className='radio-btn'
                    >
                      {item.description}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={`TASA DE DESC. AL PROVEEDOR ${poDescuentoMinimoProveedor !== 0
                  ? poDescuentoMinimoProveedor
                  : ''
                  }`}
                name='poProveedorDefecto'
                rules={rules['poProveedorDefecto'] || ''}
              >
                <InputNumber
                  min={0}
                  max={100}
                  precision={2}
                  style={{ width: '100%' }}
                  formatter={percentageInputFormat}
                  parser={percentageInputParser}
                  defaultValue={poDescuentoMinimoProveedor}
                />
              </Form.Item>
            </Col>
          </Row>

          {(cdTipoConvenio === 'confirming' ||
            cdTipoConvenio === 'factoring') && (
              <>
                <Row gutter={16}>
                  <Col>
                    <Space align='baseline' className='sub-title'>
                      Información de EXPONENCIAL CONFIRMING S.A.S
                    </Space>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                  <Col flex='auto'>
                    <Form.Item
                      name='cdSnPuntosBasicos'
                      label='ELIJA EL TIPO DE VALOR QUE DESEA USAR'
                      rules={rules['cdSnPuntosBasicos'] || ''}
                    >
                      <Radio.Group buttonStyle='solid'>
                        {snPuntosBasicos.map((item) => (
                          <Radio.Button
                            key={item.key}
                            value={item.key}
                            className='radio-btn'
                          >
                            {item.description}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label='PORCENTAJE NEGOCIACIÓN EXPONENCIAL'
                      name='poBeneficioExponencial'
                      rules={rules['poBeneficioExponencial'] || ''}
                    >
                      <InputNumber
                        min={0}
                        style={{ width: '100%' }}
                        formatter={percentageInputFormat}
                        parser={percentageInputParser}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdCategoria'
                      label='CATEGORÍA'
                      rules={rules['cdCategoria'] || ''}
                    >
                      <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                        {categorias.map((item) => (
                          <Select.Option key={item.key} value={item.key}>
                            {item.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

          <Row gutter={16}>
            <Col>
              <Space align='baseline' className='sub-title'>
                Información para calcular volumen
              </Space>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoCalculoVolumen'
                label='TIPO CÁLCULO VOLUMEN'
                rules={rules['cdTipoCalculoVolumen'] || ''}
              >
                <Select
                  placeholder={SELECT_PLACE_HOLDER}
                  allowClear
                  onChange={this.handleTipoCalculoVolumenOnChange}
                >
                  {tipoCalculoVolumen.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {cdTipoCalculoVolumen === 'PAGADOR' && (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name='nmMetaPiso'
                    dependencies={['nmMetaTecho']}
                    label='META PISO VOLUMEN'
                    rules={rules['nmMetaPiso'] || ''}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      formatter={currencyInputFormat}
                      parser={currencyInputParser}
                      stringMode={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='nmMetaTecho'
                    dependencies={['nmMetaPiso']}
                    label='META TECHO VOLUMEN'
                    rules={rules['nmMetaTecho'] || ''}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      formatter={currencyInputFormat}
                      parser={currencyInputParser}
                      stringMode={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} justify='space-around' align='middle'>
                <Col span={12}>
                  <Form.Item
                    name='nmRangosMeta'
                    label='NÚMERO DE RANGOS PARA VOLUMEN'
                    rules={rules['nmRangosMeta'] || ''}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      precision={0}
                      max={99}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>
                  <Button
                    className='btn btn-blue'
                    type='primary'
                    onClick={this.handleOnConfigVolumen}
                  >
                    Configurar
                  </Button>
                </Col>
              </Row>
            </>
          )}
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label='PORCENTAJE DE COMPRA DEL ACTIVO'
                name='poCompraFondeador'
                rules={rules['poCompraFondeador'] || ''}
              >
                <InputNumber
                  min={0}
                  max={100}
                  precision={5}
                  style={{ width: '100%' }}
                  formatter={percentageInputFormat}
                  parser={percentageInputParser}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='HORA MÁXIMA OFERTAS (HH:MM)'
                name='nmHoraMaxOfertas'
                rules={rules['nmHoraMaxOfertas'] || ''}
              >
                <Input maxLength={5} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label='MONTO MÍNIMO GIRO'
                name='vrMontoMin'
                rules={rules['vrMontoMin'] || ''}
              >
                <InputNumber
                  min={0}
                  max={999999999999}
                  precision={0}
                  style={{ width: '100%' }}
                  formatter={currencyInputFormat}
                  parser={currencyInputParser}
                />
              </Form.Item>
            </Col>

            {(cdTipoConvenio === 'confirming' ||
              cdTipoConvenio === 'factoring') && (
                <Col span={12}>
                  <Form.Item
                    label='PUNTOS PORCENTUALES BAJO TASA USURA'
                    name='poPuntosBajoUsuraFd'
                    rules={rules['poPuntosBajoUsuraFd'] || ''}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      precision={2}
                      style={{ width: '100%' }}
                      formatter={percentageInputFormat}
                      parser={percentageInputParser}
                    />
                  </Form.Item>
                </Col>
              )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdSnRecalcOferta'
                label='OFERTA CON RECALCULO'
                rules={rules['cdSnRecalcOferta'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {decision.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='cdTipoBaseDias'
                label='BASE DÍAS'
                rules={rules['cdTipoBaseDias'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {tipoBaseDias.map((item) => (
                    <Select.Option key={item.codigo} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {(cdTipoConvenio === 'confirming' ||
            cdTipoConvenio === 'factoring') && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label='NÚMERO DE IDENTIFICACIÓN (ERP)'
                    name='idSAPFondeador'
                    rules={rules['idSAPFondeador'] || ''}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='NEMOTÉCNICO PAGADOR SAFYR'
                    name='dsNemotecnicoPagSAFYR'
                    rules={rules['dsNemotecnicoPagSAFYR'] || ''}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdFormatoACH'
                label='FORMATO DEL ACH PARA EL PAGO'
                rules={rules['cdFormatoACH'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {formtatoACHs.map((item) => (
                    <Select.Option key={item.codigo} value={item.codigo}>
                      {item.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdTipoCupo'
                label='TIPO DE CUPO'
                rules={rules['cdTipoCupo'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER}>
                  {tipoCupos.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdEstadoRegistro'
                label='ESTADO'
                rules={rules['cdEstadoRegistro'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {estadoRegistro.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdSnTipoOperacion'
                label='TIPO OPERACIÓN'
                rules={rules['cdSnTipoOperacion'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {cdSnTipoOperacion.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdSnRadian'
                label='RADIAN OBLIGATORIO'
                rules={rules['cdSnRadian'] || ''}
              >
                <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                  {decision.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default FoundProviderForm;
