import { Button, Card, Col, notification, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../../../..//services/history';
import { listarCompanias } from '../../../../../services/admin/operation';
import {
  buscarFichaJuridica,
  buscarFichaNatural,
} from '../../../../../services/admin/personServices';
import {
  grabarMandato,
  listarFacultadesMandatos,
} from '../../../../../services/admin/radian/radianService';
import '../mandateForm.scss';
import MandateForm, { initialValuesForm } from '../mandateForm/mandateForm';

const initialState = {
  loading: false,
  companiaSelect: {
    fetching: false,
    options: [],
  },
  facultadads: [],
  data: { ...initialValuesForm },
};

class MandateNewForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await grabarMandato(this.props.authToken, formValue);

      notification.success({
        message: 'Exito',
        description: response.message,
        duration: 6,
      });

      this.redirectToEditMandate(response.data);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  redirectToEditMandate = (mandato) => {
    history.push(
      `${process.env.PUBLIC_URL}/radian/mandate/edit/${Buffer.from(
        mandato.scMandato
      ).toString('base64')}}`
    );
  };

  redirectToMandate = () => {
    history.push(`${process.env.PUBLIC_URL}/radian/mandate`);
  };

  componentDidMount() {
    this.props.menuHandler('22');
    this.init();
  }

  init = async () => {
    this.setState({ ...this.state, loading: true });
    try {
      const response = await listarFacultadesMandatos(this.props.authToken, {
        limit: 100,
      });

      this.setState({
        ...this.state,
        facultadads: response.data,
        loading: false,
      });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  };

  handleOnSearchCompania = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { companiaSelect } = this.state;
      this.setState({
        ...this.state,
        companiaSelect: {
          ...companiaSelect,
          options: [],
          fetching: true,
        },
      });
      try {
        const response = await listarCompanias(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          companiaSelect: {
            ...companiaSelect,
            fetching: false,
          },
        });
      }
    }
  };

  handleSearchReprLeg = (data) => {
    if (data && data.type === 'J') {
      this.handleSearchFichaJuridica(data.codigo);
    } else if (data && data.type === 'N') {
      this.handleSearchFichaNatural(data.codigo);
    } else {
      this.setState({ ...this.state, data: { ...initialValuesForm } });
    }
  };

  handleSearchFichaJuridica = async (value) => {
    const { authToken } = this.props;

    this.setState({ ...this.state, loading: true });

    try {
      const response = await buscarFichaJuridica(authToken, value);

      this.setState({
        ...this.state,
        data: {
          ...response,
          scCompania: value,
          dsNombresRL:
            response.prNombreLeg === undefined &&
            response.segNombreLeg === undefined
              ? ''
              : response.prNombreLeg === undefined && response.segNombreLeg
              ? response.segNombreLeg
              : response.segNombreLeg === undefined && response.prNombreLeg
              ? response.prNombreLeg
              : response.prNombreLeg && response.segNombreLeg
              ? `${response.prNombreLeg} ${response.segNombreLeg}`
              : '',

          dsApellidosRL:
            response.prApellidoLeg === undefined &&
            response.segApellidoLeg === undefined
              ? ''
              : response.prApellidoLeg === undefined && response.segApellidoLeg
              ? response.segApellidoLeg
              : response.segApellidoLeg === undefined && response.prApellidoLeg
              ? response.prApellidoLeg
              : response.prApellidoLeg && response.segApellidoLeg
              ? `${response.prApellidoLeg} ${response.segApellidoLeg}`
              : '',
          idRL: response.idLeg,
          cdTipoIdRL: response.cdTipoIdLeg,
          dsCargoRL: response.cargoLeg,
          dsAreaRL: response.dsDepResidenciaLeg,
        },
        loading: false,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        data: { ...initialValuesForm },
      });
    }
  };

  handleSearchFichaNatural = async (value) => {
    const { authToken } = this.props;

    this.setState({ ...this.state, loading: true });

    try {
      const response = await buscarFichaNatural(authToken, value);

      this.setState({
        ...this.state,
        data: {
          ...response,
          dsNombresRL: `${response.prNombre} ${response.segNombre}`,
          dsApellidosRL: `${response.prApellido} ${response.segApellido}`,
          idRL: response.id,
          cdTipoIdRL: response.cdTipoId,
          dsCargoRL: response.cargo,
          dsAreaRL: response.dsMunElaboracion,
        },
        loading: false,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        data: { ...initialValuesForm },
      });
    }
  };

  render() {
    const { loading, data, companiaSelect, facultadads } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <MandateForm
              data={data}
              onFinish={this.handleOnFinish}
              handleOnSearchCompania={this.handleOnSearchCompania}
              handleSearchReprLeg={this.handleSearchReprLeg}
              companiaSelect={companiaSelect}
              facultadads={facultadads}
              estadoHidden={false}
            >
              <Col span={6}>
                <Button
                  style={{ width: '90%' }}
                  className='btn btn-blue'
                  type='primary'
                  onClick={this.redirectToMandate}
                >
                  Lista de Mandatos
                </Button>
              </Col>
            </MandateForm>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(MandateNewForm);
