import React, { Component } from 'react';
import { Row, Col, Divider, Table, Spin, notification } from 'antd';
import '../operation.scss';
import { currencyFormat } from '../../../../utilities/textTools';
import { COMPANY_SELECT_MESSAGE } from '../../../../utilities/messages';
import {
  cupoActual,
  listarUltimosCupos,
  getLiquidez,
  listarPagadoresCupos,
  listarProveedoresCupos,
  listarGruposEmpresarialesCupos,
  listarTipoProducto,
  listarTipoCupo,
  listarEstadoCupo,
} from '../../../../services/fondeador/operationServices';
import QuotasAgreementsFilterForm from './quotasAgreementsFilterForm/quotasAgreementsFilterForm';
import NewLiquidityForm from './newLiquidityForm/newLiquidityForm';
import CounterpartQuotaForm from './counterpartQuotaForm/counterpartQuotaForm';

const initialState = {
  visibleLiquidez: true,
  loading: false,
  scFondeador: '',
  scEntidad: '',
  tipoEntidad: '',
  tipoCupo:'',
  tipoProducto:'',
  estadoCupo:'',
  snOfertaFull: 'N',
  fondeadorSelect: {
    fetching: false,
    option: [],
  },
  entidadSelect: {
    fetching: false,
    options: [],
  },
  cupoActual: {
    scEntidad: '',
    scFondeador: '',
    feDesde: '',
    feHasta: '',
    nmCupoContraparte: 0,
    nmCupoContraparteVig: 0,
    liquidez: 0,
    liquidezReal: 0,
    consLiquidez: 0,
    descripcion: '',
    tipoCupo:'',
    tipoProducto:'',
    estadoCupo:'',
  },
  programarLiquidez: {},
  liquidezActual: {
    consLiquidez: 0,
    fechaIngreso: '',
    fechaModifica: '',
    liquidez: 0,
    scFondeador: '',
    scPersonaIngreso: '',
  },
  resultado: [],
  tipoProductoData: [],
  tipoCupoData: [],
  estadoCupoData:[],
};

class QuotasAgreements extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'FECHA CUPO',
        dataIndex: 'feHasta',
        showSorterTooltip: false,
      },
      {
        title: 'CUPO CONTRAPARTE ASIGNADO',
        dataIndex: 'nmCupoContraparte',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'SALDO DE CONTRAPARTE VIGENTE',
        dataIndex: 'nmCupoContraparteVig',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        showSorterTooltip: false,
      },
      //Este va?
      {
        title: 'TOTAL OFERTAS ACEPTADAS',
        dataIndex: 'consLiquidez',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL PAGOS RECIBIDOS',
        dataIndex: 'nmPagRecibidos',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO CUPO',
        dataIndex: 'tipoCupo',
        render: (value) => (
          <span>{(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'TIPO PRODUCTO',
        dataIndex: 'tipoProducto',
        render: (value) => (
          <span>{(value)}</span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ESTADO CUPO',
        dataIndex: 'estadoCupo',
        render: (value) => (
          <span>{value === 'A' ? 'Activo' : 'Inactivo'}</span>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  componentDidMount() {
    this.handleFondeador(this.props.company.company);
    this.getInitTipoProducto();
    this.getInitTipoCupo();
    this.getInitEstadoCupo();
  }

  handleOnSearchEntidad = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { entidadSelect, tipoEntidad } = this.state;

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          fetching: true,
        },
      });

      try {
        let response = null;
        if (tipoEntidad === 'PAG') {
          response = await listarPagadoresCupos(this.props.authToken, {
            nombre: nombre,
          });
        } else if (tipoEntidad === 'PRO') {
          response = await listarProveedoresCupos(this.props.authToken, {
            nombre: nombre,
          });
        } else {
          response = await listarGruposEmpresarialesCupos(
            this.props.authToken,
            {
              nombre: nombre,
            }
          );
        }
        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          entidadSelect: {
            ...entidadSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnChangeEntidad = async (e) => {
    this.state.scEntidad = e;
    this.state.cupoActual = initialState.cupoActual;
    this.state.resultado = initialState.resultado;

    const { entidadSelect, tipoEntidad } = this.state;

    this.setState({
      ...this.state,
      entidadSelect: {
        ...entidadSelect,
        fetching: true,
      },
    });

    try {
      let response = null;
      if (tipoEntidad === 'PAG') {
        response = await listarPagadoresCupos(this.props.authToken, {
          limit: 50,
        });
      } else if (tipoEntidad === 'PRO') {
        response = await listarProveedoresCupos(this.props.authToken, {
          limit: 50,
        });
      } else {
        response = await listarGruposEmpresarialesCupos(this.props.authToken, {
          limit: 50,
        });
      }

      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.setState({
        ...this.state,
        entidadSelect: {
          ...entidadSelect,
          options: [],
          fetching: false,
        },
      });
    }
  };

  handleOnChangeTipoEntidad = async (e) => {
    const { entidadSelect } = this.state;

    let response = null;
    if (e === 'PAG') {
      response = await listarPagadoresCupos(this.props.authToken, {
        limit: 50,
      });
    } else if (e === 'PRO') {
      response = await listarProveedoresCupos(this.props.authToken, {
        limit: 50,
      });
    } else {
      response = await listarGruposEmpresarialesCupos(this.props.authToken, {
        limit: 50,
      });
    }
    this.setState({
      ...this.state,
      loading: false,
      tipoEntidad: e,
      entidadSelect: {
        ...entidadSelect,
        options: response.data,
        fetching: false,
      },
    });
  };

  handleOnSearchCupos = async (e) => {
    this.getData(e);
  };

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...this.state,
        cupoActual: initialState.cupoActual,
        resultado: initialState.resultado,
      });
      this.handleOnSearchEntidad();
      this.handleFondeador(this.props.company.company);
    }
  }

  handleFondeador = async (e) => {
    this.state.scFondeador = e;
    this.state.cupoActual = initialState.cupoActual;
    this.state.resultado = initialState.resultado;

    const { scFondeador } = this.state;

    try {
      const liquidez = await getLiquidez(this.props.authToken, scFondeador);

      this.setState({
        ...this.state,
        liquidezActual: liquidez,
        loading: false,
        visibleLiquidez: true,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        visibleLiquidez: false,
        liquidezActual: initialState.liquidezActual,
      });
    }
  };

  getInitTipoProducto = async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoProductoRes = await listarTipoProducto(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoProductoData: tipoProductoRes.data,
    });
  };

  
  getInitTipoCupo= async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const tipoCupoRes = await listarTipoCupo(authToken);
    this.setState({
      ...this.state,
      loading: false,
      tipoCupoData: tipoCupoRes.data,
    });
  };

  getInitEstadoCupo= async () => {
    const {authToken} = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const estadoCupoRes = await listarEstadoCupo(authToken);
    this.setState({
      ...this.state,
      loading: false,
      estadoCupoData: estadoCupoRes.data,
    });
  };

  getData = async (values) => {
    const { scEntidad, scFondeador, tipoEntidad } = this.state;

    if (this.props.company.company === '0') {
      notification.warn({
        message: 'Cupo',
        description: COMPANY_SELECT_MESSAGE,
      });
      return;
    }

    const data = {
      tipoEntidad,
      tipoCupo: values.tipoCupo,
      tipoProducto: values.tipoProducto,
      estadoCupo: values.estadoCupo,
    }

    this.setState({
      ...this.state,
      loading: true,
    });

    if (!scEntidad) {
      this.setState({
        ...this.state,
        loading: false,
      });
      return;
    }

    let cupoActualData = {
      data: initialState.cupoActual,
    };

    let cuposResponse = {
      data: initialState.resultado,
    };

    try {
      if (
        scFondeador !== '' &&
        scEntidad !== '' &&
        scFondeador !== undefined &&
        scEntidad !== undefined
      ) {
        cupoActualData = await cupoActual(
          this.props.authToken,
          scFondeador,
          scEntidad,
          data
        );

        cuposResponse = await listarUltimosCupos(
          this.props.authToken,
          scFondeador,
          scEntidad,
          data
        );
      }

      this.setState({
        ...this.state,
        loading: false,
        visibleLiquidez: false,
        cupoActual: cupoActualData.data,
        resultado: cuposResponse.data,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        visibleLiquidez: false,
        cupoActual: initialState.cupoActual,
      });
    }
  };

  render() {
    const {
      loading,
      entidadSelect,
      scFondeador,
      tipoEntidad,
      scEntidad,
      cupoActual,
      resultado,
      liquidezActual,
      tipoProductoData,
      tipoCupoData,
      estadoCupoData
    } = this.state;

    const { nmCupoContraparte, feHasta } = cupoActual;

    const { liquidez } = liquidezActual;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <QuotasAgreementsFilterForm
          entidadSelect={entidadSelect}
          handleOnSearchEntidad={this.handleOnSearchEntidad}
          handleOnChangeEntidad={this.handleOnChangeEntidad}
          handleOnChangeTipoEntidad={this.handleOnChangeTipoEntidad}
          handleOnSearchCupos={this.handleOnSearchCupos}
          nmCupoContraparte={nmCupoContraparte}
          liquidez={liquidez}
          feHasta={feHasta}
          tipoProducto={tipoProductoData}
          tipoCupo={tipoCupoData}
        />

        <div className='table-container'>
          <Row gutter={30} className='bill-selection'>
            <Col span={12}>
              <p className='title'>Lista de cupos</p>
            </Col>
            <Col span={12} className='export'>
              <Row gutter={16}>
                <Col span={12}>
                  <span className='button'>
                    <CounterpartQuotaForm
                      tipoEntidad={tipoEntidad}
                      scEntidad={scEntidad}
                      cupoActual={cupoActual}
                      scFondeador={scFondeador}
                      authToken={this.props.authToken}
                      onFinish={() => this.getData()}
                      handleOnSearchCupos={this.handleOnSearchCupos}
                      liquidezActual={liquidezActual}
                      tipoProducto={tipoProductoData}
                      tipoCupo={tipoCupoData}
                      estadoCupo={estadoCupoData}
                    />
                  </span>
                </Col>
                <Col span={12}>
                  <span className='button'>
                    <NewLiquidityForm
                      liquidez={liquidezActual}
                      scFondeador={scFondeador}
                      authToken={this.props.authToken}
                      onFinish={() => this.getData()}
                    />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Table
            columns={this.columnas}
            dataSource={resultado}
            pagination={false}
          ></Table>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default QuotasAgreements;
