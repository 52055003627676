import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Select,
  Input,
  Spin,
  Upload,
  Typography,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../../utilities/admin_validation_messages';
import { sizeFileValidation } from '../../../../../../utilities/validate/fileValidate';

const { Dragger } = Upload;
const { Text } = Typography;

export const initialFormValue = {
  cdBanco: '',
  nroCuenta: '',
  cdTipoCuenta: '',
  cdEstadoRegistro: 'A',
  cdMunicipio: '',
};

const initialState = {
  fileList: [],
  fileMessageErrors: [],
};

const tipoCuenta = [
  {
    key: 'A',
    description: 'Ahorro',
  },
  {
    key: 'C',
    description: 'Corriente',
  },
];

const estado = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
];

const rules = {
  cdBanco: [{ required: true, message: requiredValueMessage() }],
  nroCuenta: [
    { required: true, message: requiredValueMessage() },
    { max: 30, message: maxValueMessage(30) },
  ],
  cdTipoCuenta: [{ required: true, message: requiredValueMessage() }],
  cdEstadoRegistro: [{ required: true, message: requiredValueMessage() }],
  cdMunicipio: [{ required: true, message: requiredValueMessage() }],
};

class BankAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.form = React.createRef();
  }

  handleOnChangeUpload = (info) => {
    const fileList =
      info.fileList.length > 1 ? info.fileList.shift() : info.fileList;

    this.isValidFile(fileList);

    this.setState({
      ...this.state,
      fileList,
    });
  };

  handleOnFinish = async (formValue) => {
    const { onFinish } = this.props;
    const { fileList } = this.state;

    if (await this.isValidFile(fileList)) {
      onFinish({ ...formValue, file: fileList });
    }
  };

  isValidFile = async (fileList) => {
    const { edit = false } = this.props;
    let fileMessageErrors = [];
    if (fileList.length === 0 && !edit) {
      fileMessageErrors.push('debe seleccionar un archivo');
    }

    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    this.setState({ ...this.state, fileMessageErrors });
    return fileMessageErrors.length === 0;
  };

  componentDidMount() {
    if (this.props.data) {
      const bankAccount = this.props.data;
      this.form.current.setFieldsValue({ ...bankAccount });
      this.setState({ ...this.state, fileList: [] });
    }
  }

  componentDidUpdate(props, state) {
    if (props.reset !== this.props.reset) {
      this.form.current.resetFields();
      this.setState({ ...this.state, fileList: [] });

      if (this.props.data && JSON.stringify(this.props.data) !== '{}') {
        this.form.current.setFieldsValue({
          ...this.props.data,
        });
      }
    }
  }

  render() {
    const { bancosSelect, onBancosSearch, ciudadesSelect, onCiudadesSearch } =
      this.props;

    const { fileList, fileMessageErrors } = this.state;

    return (
      <div className='header-container'>
        <Form layout='vertical' ref={this.form} onFinish={this.handleOnFinish}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdBanco'
                label='BANCOS'
                rules={rules['cdBanco'] || ''}
              >
                <Select
                  className='module-selector-select'
                  placeholder='Seleccione'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={(value) => onBancosSearch(value)}
                  notFoundContent={
                    bancosSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {bancosSelect.options.map((banco, i) => (
                    <Select.Option key={i} value={banco.codigo}>
                      {banco.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='nroCuenta'
                label='NÚMERO DE CUENTA'
                rules={rules['nroCuenta'] || ''}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='cdTipoCuenta'
                label='TIPO DE CUENTA'
                rules={rules['cdTipoCuenta'] || ''}
              >
                <Select>
                  {tipoCuenta.map(({ key, description }) => (
                    <Select.Option key={key} value={key}>
                      {description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='cdEstadoRegistro'
                label='ESTADO'
                rules={rules['cdEstadoRegistro'] || ''}
              >
                <Select>
                  {estado.map(({ key, description }) => (
                    <Select.Option key={key} value={key}>
                      {description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex='auto'>
              <Form.Item
                name='cdMunicipio'
                label='CIUDAD'
                rules={rules['cdMunicipio'] || ''}
              >
                <Select
                  className='module-selector-select'
                  placeholder='Seleccione'
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={(value) => onCiudadesSearch(value)}
                  notFoundContent={
                    ciudadesSelect.fetching ? <Spin size='small' /> : null
                  }
                >
                  {ciudadesSelect.options.map((ciudad, i) => (
                    <Select.Option key={i} value={ciudad.codigo}>
                      {ciudad.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex='auto'>
              <Form.Item
                name='file'
                label='ARCHIVO SOPORTE'
                required={this.props.fileRequired}
              >
                <Form.Item noStyle>
                  <Dragger
                    beforeUpload={() => {
                      return false;
                    }}
                    fileList={fileList}
                    onChange={this.handleOnChangeUpload}
                    maxCount={0}
                    multiple={false}
                  >
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Suelta el archivo aquí o selecciona alguno.
                    </p>
                  </Dragger>
                  {fileMessageErrors.map((value) => (
                    <Text type='danger'>{value}</Text>
                  ))}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default BankAccountForm;
