import { Modal, notification, Spin } from 'antd';
import React, { Component } from 'react';
import RangosComisionModal from '../../../../../../components/rangosComisionModal/rangosComisionModal';
import TasaDescuentoModal from '../../../../../../components/tasaDescuentoModal/tasaDescuentoModal';
import TasaDescuentoModalEdit from '../../../../../../components/tasaDescuentoModal/tasaDescuentoModalEdit';
import { editarConvenioProveedor, listarConveniosFondeador } from '../../../../../../services/admin/operation';
import { isEqualsToObject } from '../../../../../../utilities/compare';
import { PROV_SIN_TASAS } from '../../../../../../utilities/messages';
import SupplierForm from '../supplierForm/supplierForm';

const MESSAGE_TITLE = 'Proveedores';

const initialState = {
  loading: false,
  configModalVisible: false,
  rangoComisionVisible: false,
  TasaDescuentoVisible: false,
  nmRangosCom: '',
  rangosCom: [],
  rangosPoDescuentoProveedor:[],
  dataFondeador:[],
  proveedorPorDefecto:false
};

class SupplierEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnFinishRangoModal = (rangosCom) => {
    this.setState({
      ...this.state,
      rangosCom,
      rangoComisionVisible: false,
    });
  };

  handleOnFinishTasaDescuentoModal = (rangosPoDescuentoProveedor) => {
    this.setState({
      ...this.state,
      rangosPoDescuentoProveedor,
      TasaDescuentoVisible: false,
      dataFondeador:[...rangosPoDescuentoProveedor],
    });
  };

  handleOnRangoModalClose = () => {
    this.setState({
      ...this.state,
      rangoComisionVisible: false,
    });
  };
  handleTasaDescuentoModalClose = () => {
    this.setState({
      ...this.state,
      TasaDescuentoVisible: false,
    });
  };
  handleOnConfigRangoComision = (nmRangosComNew) => {
    const { nmRangosCom, rangosCom } = this.state;
    this.setState({
      ...this.state,
      rangoComisionVisible: true,
      nmRangosCom: nmRangosComNew,
      rangosCom: nmRangosCom !== nmRangosComNew ? [] : rangosCom,
    });
  };
  handleOnTasaDescuentoModal = () => {
    this.setState({
      ...this.state,
      TasaDescuentoVisible: true,
    });
  };

  handleOnFinish = async (formValue) => {
    const { authToken, onFinish, scConvenio } = this.props;
    const { rangosCom, rangosPoDescuentoProveedor } = this.state;

    formValue.poDescuentoProveedor = 0
    formValue.cdSnPuntosBasicos = 'N'
    if(this.validateForm()){
    if (JSON.stringify(rangosCom).length === 0) {
      notification.success({
        message: MESSAGE_TITLE,
        description: 'Debe configura los rangos de comision antes de grabar',
        duration: 6,
      });
      return;
    }

    this.setState({ ...this.state, loading: true });

    try {
      const response = await editarConvenioProveedor(
        authToken,
        scConvenio,
        formValue.scProveedor,
        {
          ...formValue,
          rangosCom,
          rangosPoDescuentoProveedor
        }
      );
      notification.success({
        message: MESSAGE_TITLE,
        description: response.message,
        duration: 6,
      });
      this.setState({ ...this.state, loading: false });

      onFinish({ ...formValue, rangosCom, scProveedor: response.location });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  }
  };
  handleBuscarConvenioFondeador = async () => {
    const { scConvenio, authToken } = this.props;


    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarConveniosFondeador(authToken, scConvenio);

      this.setState({
        ...this.state,
        dataFondeador: response.data,
        proveedorPorDefecto:true,
        loading: false,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };
  validateForm = () => {
    let data = this.state.rangosPoDescuentoProveedor;
    let rangosProv = true;
    if(data.length === 0) {
      rangosProv = false
    }
    if (!rangosProv) {
      notification.warn({
        message: 'Por favor validar',
        description: PROV_SIN_TASAS,
        duration: 4,
      });
    }
    return rangosProv;
  };
  
  SetDataFondeador = () => {
    const {data} = this.props;
    if(data.rangosPoDescuentoProveedor){
      this.setState({
        ...this.state,
        dataFondeador: [...data.rangosPoDescuentoProveedor],
        rangosPoDescuentoProveedor:[...data.rangosPoDescuentoProveedor],
        proveedorPorDefecto: false,
        nmRangosCom: data.nmRangosCom,
        rangosCom: data.rangosCom,
      });
    } 

    if(!data.rangosPoDescuentoProveedor){
      this.handleBuscarConvenioFondeador()
      this.setDataRangoComision()
    }
  }

  setDataRangoComision = () => {
    const { data } = this.props;
    this.setState({
      ...this.state,
      nmRangosCom: data.nmRangosCom,
      rangosCom: data.rangosCom,
    });
  }

  componentDidMount(){
    this.SetDataFondeador();
  }

  componentDidUpdate(props, state) {
    if (!isEqualsToObject(props.data, this.props.data)) {
      if (this.props.visible) {
        const { data } = this.props;
        this.setState({
          ...this.state,
          nmRangosCom: data.nmRangosCom,
          rangosCom: data.rangosCom,
        });
      } else {
        this.setState({
          ...this.state,
          nmRangosCom: '',
          rangosCom: [],
        });
      }
    }
  }
  render() {
    const {
      loading,
      rangoComisionVisible,
      TasaDescuentoVisible,
      nmRangosCom,
      rangosCom,
      dataFondeador,
      proveedorPorDefecto
    } = this.state;

    const {
      visible,
      onClose,
      data,
      tipoTasaProveedor,
      categorias,
      tipoPagos,
      fondeadores,
    } = this.props;
    return (
      <>
        <Modal
          title='Editar Proveedor'
          visible={visible}
          className='modal-found-providers-style'
          width={800}
          footer={null}
          onCancel={onClose}
        >
          <Spin tip='Cargando...' spinning={loading}>
            <SupplierForm
              edit={true}
              data={data}
              onFinish={this.handleOnFinish}
              tipoTasaProveedor={tipoTasaProveedor}
              onConfigRangoComision={this.handleOnConfigRangoComision}
              OnTasaDescuentoModal={this.handleOnTasaDescuentoModal}
              categorias={categorias}
              tipoPagos={tipoPagos}
              fondeadores={fondeadores}
            />
          </Spin>
        </Modal>
        <RangosComisionModal
          visible={rangoComisionVisible}
          nmRangosCom={nmRangosCom}
          data={rangosCom}
          onFinish={this.handleOnFinishRangoModal}
          onClose={this.handleOnRangoModalClose}
        />
       {proveedorPorDefecto === true &&( 
        <TasaDescuentoModal
          visible={TasaDescuentoVisible}
          dataFondeador={dataFondeador}
          onFinish={this.handleOnFinishTasaDescuentoModal}
          onClose={this.handleTasaDescuentoModalClose}
        /> )}
        {proveedorPorDefecto === false &&( 
          <TasaDescuentoModalEdit
          visible={TasaDescuentoVisible}
          dataFondeador={dataFondeador}
          onFinish={this.handleOnFinishTasaDescuentoModal}
          onClose={this.handleTasaDescuentoModalClose}
        /> )}
      </>
    );
  }
}

export default SupplierEditModal;
