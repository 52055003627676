import { FileExcelOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Switch,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../components/tableReport/tableReport';
import {
  listar,
  listarExcel,
} from '../../../services/fondeador/supplierServices';
import { setExcel } from '../../../utilities/downloadTools';
import { sorterText } from '../../../utilities/textTools';
import SupplierDetail from './supplierdetail/supplierdetail';
import './suppliers.scss';

const initialState = {
  form: {
    nombreProveedor: '',
    proveedorId: '',
  },
  resultado: [],
  total: 0,
  currentPage: 1,
  offset: 0,
  limit: 10,
  loading: false,
  proveedorSeleccionado: {},
  filtroSeleccionado: 'Nombre',
};

class Suppliers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.formSearch = React.createRef();
    this.columnas = [
      {
        title: 'TIPO DE IDENTIFICACIÓN',
        dataIndex: 'dsTipoId',
        sorter: (a, b) => sorterText(a.dsTipoId, b.dsTipoId),
        showSorterTooltip: false,
      },
      {
        title: 'IDENTIFICACIÓN',
        dataIndex: 'id',
        sorter: (a, b) => sorterText(a.id, b.id),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: '',
        render: (text, record) => (
          <Button
            className='form-btn btn-radius btn-details'
            type='primary'
            htmlType='submit'
            onClick={() => this.getDetails(record)}
          >
            VER
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  getDetails = (record) => {
    this.setState({
      ...this.state,
      proveedorSeleccionado: record,
    });
  };

  getData = () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    listar(this.props.authToken, this.props.company.company, this.getFiltro())
      .then((response) => {
        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;

    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleSwitchChange = (check) => {
    let nombre = 'Nombre';
    if (check) {
      nombre = 'Identificación';
    }
    this.formSearch.current.setFieldsValue({
      tipoSearch: check,
      nombreProveedor: '',
      proveedorId: '',
    });
    this.setState({
      ...this.state,
      filtroSeleccionado: nombre,
      filtros: {
        ...this.state.filtros,
        nombreProveedor: '',
        proveedorId: '',
      },
    });
  };

  exportExcel = () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    listarExcel(
      this.props.authToken,
      this.props.company.company,
      this.getFiltro()
    )
      .then((response) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        setExcel(response, 'Listado_de_Proveedores');
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        ...value,
      },
      resultado: [],
      proveedorSeleccionado: {},
      total: 0,
      currentPage: 1,
      offset: 0,
    });

    this.getData();
  };

  componentDidMount() {
    this.props.menuHandler('2');
    if (typeof this.props.company.company !== 'object') {
      this.getData();
    }
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.state.activeTab = '1';
      this.state.form = {
        nombreProveedor: '',
        proveedorId: '',
      };
      this.state.resultado = [];
      this.state.total = 0;
      this.state.currentPage = 1;
      this.state.offset = 0;
      this.state.proveedorSeleccionado = {};
      this.state.filtroSeleccionado = 'Nombre';

      this.formSearch.current.setFieldsValue({
        nombreProveedor: '',
        proveedorId: '',
      });

      this.getData();
    }
  }

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.getData();
  };

  render() {
    const {
      filtroSeleccionado,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      proveedorSeleccionado,
    } = this.state;
    return (
      <div className='suppliers-style-fondeador'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <div className='header-container'>
            <p className='title'>Proveedores</p>
            <Form
              ref={this.formSearch}
              name='suppliers_form'
              layout='vertical'
              onFinish={this.handleSubmit}
            >
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item name='tipoSearch' label='Filtro'>
                    <Switch
                      checkedChildren='Identificación'
                      unCheckedChildren='Nombre'
                      onChange={this.handleSwitchChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  {filtroSeleccionado === 'Nombre' && (
                    <Form.Item name='nombreProveedor' label='Nombre'>
                      <Input name='nombreProveedor' />
                    </Form.Item>
                  )}
                  {filtroSeleccionado === 'Identificación' && (
                    <Form.Item name='proveedorId' label='Identificación'>
                      <Input name='proveedorId' />
                    </Form.Item>
                  )}
                </Col>

                <Col span={6}>
                  <Form.Item>
                    <Button
                      className='form-btn btn-radius'
                      type='primary'
                      htmlType='submit'
                    >
                      Buscar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div className='table-container'>
            <Spin tip='Cargando...' spinning={this.state.loading}>
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
              <Divider />

              <TableReport
                currentPage={currentPage}
                limit={limit}
                total={total}
                resumen={resumen}
                columns={this.columnas}
                data={resultado}
                handleChange={this.handleChange}
              />
            </Spin>
          </div>

          {Object.keys(proveedorSeleccionado).length !== 0 && (
            <SupplierDetail proveedorSeleccionado={proveedorSeleccionado} />
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Suppliers);
