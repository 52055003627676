import { Modal, notification, Spin } from 'antd';
import React, { Component } from 'react';
import { editarMarcarPago } from '../../../../../services/admin/paymentReportsService';
import CheckPaymentForm, {
  initialFormValue,
} from '../checkPaymentForm/checkPaymentForm';

const CHECK_PAYMENT_TITLE = 'MARCAR PAGOS';

const initialState = {
  loading: false,
  perfiles: [],
};

class CheckPaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleOnFinish = async (formValue) => {
    const { onFinish, authToken } = this.props;
    this.setState({ ...this.state, loading: true });

    try {
      const response = await editarMarcarPago(authToken, formValue);

      notification.success({
        message: CHECK_PAYMENT_TITLE,
        description: response.message,
        duration: 6,
      });

      this.setState({
        ...this.state,
        visible: false,
        loading: false,
      });

      onFinish({ ...formValue, scFactura: response.data.scFactura });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  render() {
    const { loading } = this.state;

    const { visible, onClose, scFactura } = this.props;

    return (
      <Modal
        title='Marcar Pago'
        visible={visible}
        className='modal-style'
        footer={null}
        onCancel={onClose}
      >
        <Spin tip='Cargando...' spinning={loading}>
          <CheckPaymentForm
            data={{ ...initialFormValue, scFactura: scFactura }}
            onFinish={this.handleOnFinish}
          />
        </Spin>
      </Modal>
    );
  }
}

export default CheckPaymentModal;
