import React, { Component } from 'react';

import {
  Row,
  Col,
  Card,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Spin,
  notification,
  Modal,
  InputNumber,
} from 'antd';
import { FileExcelOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  listarDocumentos,
  editarDocumento,
  crearDocumento,
} from '../../../../services/admin/documentos';
import './documentos.scss';
import { connect } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import { REPORTS_DATES_REQUIRED_ERROR } from '../../../../utilities/messages';
import equals from 'fast-deep-equal';
import {
  requiredValueMessage,
  formatValueMessage,
  maxValueMessage,
  minValueMessage,
} from '../../../../utilities/admin_validation_messages';

const { Option } = Select;

class Documentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editarVisible: false,
      parametrosModal: {
        cdSnObligatorio: '',
        cdTipoAsocDoc: '',
        codigo: '',
        descripcion: '',
        dsSnObligatorio: '',
        dsTipoAsocDoc: '',
        nmVigencia: 0,
        nombre: '',
      },
      pageData: {
        descripcion: '',
        resultado: [],
        count: 0,
        offset: 0,
        currentPage: 1,
        limit: 10,
      },
      loading: false,
      isEdit: false,
      isNew: false,
    };
    this.formRef = React.createRef();
  }

  requiredMessage = 'Éste campo es requerido';
  requiredMessageRegexp = 'El valor no tiene el formato correcto';

  sorter = (a, b) =>
    isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b;

  componentDidMount() {
    this.props.menuHandler('4');
    this.getData();
  }
  componentDidUpdate(props, state) {
    if (!equals(this.state.parametrosModal, state.parametrosModal)) {
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue(this.state.parametrosModal);
      }
    }
  }

  columnas = {
    pageData: [
      {
        title: 'CÓDIGO',
        dataIndex: 'codigo',
        sorter: (a, b) => a.codigo - b.codigo,
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'nombre',
        sorter: (a, b) => a.nombre - b.nombre,
        showSorterTooltip: false,
      },
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'descripcion',
        sorter: (a, b) => a.descripcion - b.descripcion,
        showSorterTooltip: false,
      },
      {
        title: 'ASOCIACIÓN',
        dataIndex: 'dsTipoAsocDoc',
        sorter: (a, b) => a.dsTipoAsocDoc - b.dsTipoAsocDoc,
        showSorterTooltip: false,
      },
      {
        title: 'OBLIGATORIO',
        dataIndex: 'dsSnObligatorio',
        sorter: (a, b) => a.dsSnObligatorio - b.dsSnObligatorio,
        showSorterTooltip: false,
      },
      {
        title: 'VIGENCIA',
        dataIndex: 'nmVigencia',
        sorter: (a, b) => a.nmVigencia - b.nmVigencia,
        showSorterTooltip: false,
      },
      {
        title: '',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='Editar'
            onClick={() => this.showModalEditar(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ],
  };

  formulario = {
    pageData: [
      [
        {
          nombre: 'descripcion',
          label: 'Descripción',
          tipo: 'string',
        },
      ],
    ],
  };

  formRules = {
    codigo: [
      { required: true, message: requiredValueMessage() },
      { max: 20, message: maxValueMessage(20) },
    ],
    nombre: [
      { required: true, message: requiredValueMessage() },
      { max: 30, message: maxValueMessage(30) },
    ],
    cdTipoAsocDoc: [{ required: true, message: requiredValueMessage() }],
    cdSnObligatorio: [{ required: true, message: requiredValueMessage() }],
    nmVigencia: [{ required: true, message: requiredValueMessage() }],
    descripcion: [{ max: 100, message: maxValueMessage(100) }],
  };

  formRender = () => {
    const { type } = this.props;
    let formulario = '';
    return (formulario = (
      <Form name='content_form' layout='vertical' className='filters-container'>
        {this.formulario.pageData.map((fila, key) => {
          return (
            <Row key={key} gutter={16}>
              <Col span={12}>
                <Form.Item name={fila[0].nombre} label={fila[0].label}>
                  {this.inputType(fila[0], type)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='submit'>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    onClick={() => this.getData(1)}
                  >
                    Buscar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          );
        })}

        {/* <Col span={8}>
                <Form.Item
                name="submit">
                    <Button className="form-btn btn-radius" type="primary" onClick={this.showModalEditar}>
                        Buscar
                    </Button>
                </Form.Item>
              </Col> */}
      </Form>
    ));
  };

  showModalEditar = (record) => {
    if (typeof record !== 'undefined' && record != null) {
      let parametrosModal = {};

      for (var [key, value] of Object.entries(record)) {
        parametrosModal[key] = value;
      }

      this.setState({
        ...this.state,
        editarVisible: true,
        parametrosModal: {
          ...parametrosModal,
        },
        isEdit: true,
        isNew: false,
      });
    } else {
      this.setState({
        ...this.state,
        editarVisible: true,
        isNew: true,
        isEdit: false,
        parametrosModal: {
          cdSnObligatorio: '',
          cdTipoAsocDoc: '',
          codigo: '',
          descripcion: '',
          dsSnObligatorio: '',
          dsTipoAsocDoc: '',
          nmVigencia: 0,
          nombre: '',
        },
      });
    }
  };

  handleOnSearch = (value, nombre) => {
    if (value && value.length >= 3) {
      this.state.pageData.fetching = true;
      this.setState({
        ...this.state,
      });
      this.state.pageData.options = [];
      this.setState({ ...this.state });
    }
  };

  handleSelectChange = (value, stateName) => {
    this.state.pageData[stateName] = value;
    this.setState({ ...this.state });
  };

  inputType = (campo, type) => {
    let input;
    let stateName = campo.nombre;
    switch (campo.tipo) {
      case 'string':
        input = (
          <Input
            name={campo.nombre}
            value={this.state.pageData[stateName]}
            onChange={(e) => this.handleTextChange(e, stateName, type)}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            name={campo.nombre}
            value={this.state.pageData[stateName]}
            onChange={(moment, string) =>
              this.handleDateChange(moment, stateName, type)
            }
          />
        );
        break;
      case 'select':
        input = (
          <Select
            showSearch
            allowClear
            value={this.state.pageData[stateName]}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => this.handleOnSearch(value, stateName)}
            onChange={(value) => this.handleSelectChange(value, stateName)}
            notFoundContent={
              this.state.pageData.fetching ? <Spin size='small' /> : null
            }
          >
            {this.state.pageData.options.map((d) => (
              <Option key={d.nombre}>{d.nombre}</Option>
            ))}
          </Select>
        );
        break;
    }
    return input;
  };

  handleTextChange = (e, campo, type) => {
    this.state.pageData[campo] = e.target.value;
    this.setState({
      ...this.state,
    });
  };

  handleDateChange = (moment, campo, type) => {
    this.state.pageData[campo] = moment;
  };

  getData = (currentPage) => {
    if (currentPage == 1) {
      this.state.pageData.currentPage = 1;
      this.state.pageData.offset = 0;
    }
    this.state.pageData.resultado = [];
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });
      listarDocumentos(this.props.authToken, this.state.pageData).then(
        (response) => {
          this.setResponse(response);
        }
      );
    }
  };

  setResponse = (response) => {
    // this.state.pageData = response.metadata
    this.state.pageData.resultado = response.data;
    this.setState({
      ...this.state,
      pageData: {
        ...this.state.pageData,
        ...response.metadata,
      },
      loading: false,
    });
  };

  validateForm = () => {
    let filtros = this.state.pageData;
    let fechas = true;
    Object.keys(filtros).forEach((nombre) => {
      if (nombre !== 'resultado') {
        if (
          (nombre.includes('Desde') || nombre.includes('Hasta')) &&
          (filtros[nombre] === '' || filtros[nombre] === null)
        ) {
          fechas = false;
        }
      }
    });
    if (!fechas) {
      notification.error({
        message: 'Las fechas son obligatorias',
        description: REPORTS_DATES_REQUIRED_ERROR,
        duration: 4,
      });
    }
    return fechas;
  };

  handlePageChange = (page, pagesize) => {
    this.state.pageData.offset = pagesize * (page - 1);
    this.state.pageData.currentPage = page;
    this.setState({
      ...this.state,
    });
    this.getData();
  };

  handlePerPageChange = (size) => {
    this.state.pageData.limit = size;
    this.state.pageData.offset = 0;
    this.state.pageData.currentPage = 1;
    this.setState({
      ...this.state,
    });
    this.getData();
  };

  formatNumber(num) {
    if (!isNaN(num)) {
      num = parseFloat(num).toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  handleOnChange = (pagination, filters, sorter, extra) => {
    if (this.state.pageData.currentPage != pagination.current) {
      this.state.pageData.offset =
        pagination.pageSize * (pagination.current - 1);
      this.state.pageData.currentPage = pagination.current;
    }

    if ((this.state.pageData.limit || 10) != pagination.pageSize) {
      this.state.pageData.limit = pagination.pageSize;
      this.state.pageData.offset = 0;
      this.state.pageData.currentPage = 1;
    }

    if (sorter.field) {
      let { field, order } = sorter;
      this.state.pageData.sort = order == 'descend' ? `-${field}` : field;
    }
    this.getData(this.props.type);
  };

  handleCancel = (e) => {
    this.setState({
      editarVisible: false,
    });
  };

  editarDocumento = () => {
    let errors = this.formRef.current.getFieldsError();
    if (Array.isArray(errors)) {
      if (!errors.find((x) => x.errors.length > 0)) {
        if (this.state.isEdit) {
          editarDocumento(
            this.props.authToken,
            this.state.parametrosModal
          ).then((response) => {
            this.setState({
              ...this.state,
              editarVisible: false,
            });

            if (response.data) {
              notification.success({
                message: 'Actualizado',
                description: response.message,
                duration: 6,
              });
              this.getData();
            }
          });
        }
        if (this.state.isNew) {
          crearDocumento(this.props.authToken, this.state.parametrosModal).then(
            (response) => {
              this.setState({
                ...this.state,
                editarVisible: false,
                isNew: false,
              });

              if (response.location) {
                notification.success({
                  message: 'Actualizado',
                  description: response.message,
                  duration: 6,
                });
                this.getData();
              }
            }
          );
        }
      }
    }
  };

  render() {
    const { type } = this.props;
    return (
      <Spin tip='Cargando...' spinning={this.state.loading}>
        <div className='documentos-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <div className='header-container'>
              <p className='title'>Búsqueda</p>
              {this.formRender()}
            </div>
            <Row className='row-agregar'>
              <Col>
                <span className='button'>
                  <Button
                    size='small'
                    onClick={() => this.showModalEditar(null)}
                    className='btn-agregar'
                  >
                    + Agregar nuevo Documento
                  </Button>
                </span>
              </Col>
            </Row>
            <div>
              <p className='ptable'>Lista de documentos</p>
            </div>
            <div className='table-container'>
              <Divider />
              <Table
                pagination={{
                  defaultPageSize: 10,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} de ${total} registros`,
                  current: this.state.pageData.currentPage,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '30'],
                  total: this.state.pageData.count,
                }}
                onChange={this.handleOnChange}
                columns={this.columnas.pageData}
                dataSource={this.state.pageData.resultado}
              ></Table>
            </div>

            <Modal
              title={`${this.state.isNew ? 'Nuevo' : 'Editar'} Documento`}
              className='modal-style'
              visible={this.state.editarVisible}
              footer={null}
              onCancel={this.handleCancel}
            >
              <div className='header-container'>
                <Form
                  name='localization_form'
                  layout='vertical'
                  ref={this.formRef}
                  initialValues={{
                    ...this.state.parametrosModal,
                  }}
                  onFinish={() => this.editarDocumento()}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name='codigo'
                        label='CÓDIGO'
                        rules={this.formRules['codigo']}
                      >
                        <Input
                          disabled={!this.state.isNew}
                          value={this.state.parametrosModal.codigo}
                          placeholder={this.state.parametrosModal.codigo}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              parametrosModal: {
                                ...this.state.parametrosModal,
                                codigo: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='nombre'
                        label='NOMBRE'
                        rules={this.formRules['nombre']}
                      >
                        <Input
                          value={this.state.parametrosModal.nombre}
                          placeholder={this.state.parametrosModal.nombre}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              parametrosModal: {
                                ...this.state.parametrosModal,
                                nombre: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name='cdTipoAsocDoc'
                        label='ASOCIACIÓN'
                        rules={this.formRules['cdTipoAsocDoc']}
                      >
                        <Select
                          value={this.state.parametrosModal.cdTipoAsocDoc}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              parametrosModal: {
                                ...this.state.parametrosModal,
                                cdTipoAsocDoc: e,
                              },
                            })
                          }
                        >
                          <Select.Option key={'T'} value={'T'}>
                            Todos
                          </Select.Option>
                          <Select.Option key={'P'} value={'P'}>
                            Pagador
                          </Select.Option>
                          <Select.Option key={'F'} value={'F'}>
                            Fondeador
                          </Select.Option>
                          <Select.Option key={'R'} value={'R'}>
                            Proveedor
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdSnObligatorio'
                        label='OBLIGATORIO'
                        rules={this.formRules['cdSnObligatorio']}
                      >
                        <Select
                          value={this.state.parametrosModal.cdSnObligatorio}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              parametrosModal: {
                                ...this.state.parametrosModal,
                                cdSnObligatorio: e,
                              },
                            })
                          }
                        >
                          <Select.Option key={'S'} value={'S'}>
                            Sí
                          </Select.Option>
                          <Select.Option key={'N'} value={'N'}>
                            No
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name='nmVigencia'
                        label='VIGENCIA DE DOCUMENTO EN DÍAS'
                        rules={this.formRules['nmVigencia']}
                      >
                        <InputNumber
                          value={this.state.parametrosModal.nmVigencia}
                          placeholder={this.state.parametrosModal.nmVigencia}
                          min={1}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              parametrosModal: {
                                ...this.state.parametrosModal,
                                nmVigencia: e,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name='descripcion'
                        label='DESCRIPCIÓN'
                        rules={this.formRules['descripcion']}
                      >
                        <TextArea
                          required
                          value={this.state.parametrosModal.descripcion}
                          placeholder={this.state.parametrosModal.descripcion}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              parametrosModal: {
                                ...this.state.parametrosModal,
                                descripcion: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify='center'>
                    <Col span={8}>
                      <Form.Item name='submit'>
                        <Button
                          className='form-btn btn-radius'
                          type='primary'
                          onClick={() => this.formRef.current.submit()}
                        >
                          Grabar
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Documentos);
