import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import {
  Layout,
  Menu,
  Row,
  Col,
  Select,
  Modal,
  notification,
} from 'antd';
import {
  LogoutOutlined,
  CalculatorOutlined,
  SolutionOutlined,
  AuditOutlined
} from '@ant-design/icons';
import '../styles/proveedorLayout.scss';
import '../styles/standard.scss';

import logo from '../assets/img/logo-side-bar.png';
import userLogo from '../assets/img/user-icon.png';

import masterServices from '../services/fondeador/masterServices';
import { setCompany } from '../store/actions/pagador/companyActions';
import {
  setExpirationTimeout,
  refreshToken,
  sessionClose,
} from '../store/actions/authActions';
import history from '../services/history';


import { connect } from 'react-redux';
import { InscripcionUsuariosForm } from '../pages/vinculacion/InscripcionUsuarios/inscripcionUsuariosForm';

import FichaCliente from '../pages/vinculacion/FichaCliente/fichaCliente';
import TYC from '../pages/proveedor/content/TYC';
import DocumentosAnexos from '../pages/vinculacion/DocumentosAnexos/documentosAnexos';
import { buscarTipoFicha } from '../services/vinculacion/fichaService';
import { buscarFichaJuridicaPU } from '../services/vinculacion/fichaJuridicaPUservice';
import { buscarFichaNaturalPU } from '../services/vinculacion/fichaNaturalPUservice';


/**Pages */

const { Option } = Select;

const { Header, Sider, Content } = Layout;

class VinculacionLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSelectedKey: '1',
      selected_company: '',
      notificationDrawer: false,
      notifications: [],
      unreadedNotifications: 0,
      showAuthExpModal: false,
      setTimeoutCloseSession: null,
      menusTotal: []
    };
  }

  fecha = moment().format('dddd, LL');

  menus = [
    {
        title: 'Ficha Cliente',
        icon: CalculatorOutlined,
        selected: true,
        disabled: false,
        protected: false,
        href: `${process.env.PUBLIC_URL}/vinculacion/fichacliente`,
        key: 1,
        paso: 1
    },
    {
        title: 'Inscripcion Usuarios',
        icon: SolutionOutlined,
        selected: true,
        disabled: false,
        protected: false,
        href: `${process.env.PUBLIC_URL}/vinculacion/inscripcionusuarios`,
        key: 2,
        paso: 2
    },
    {
        title: 'Documentos Anexos',
        icon: AuditOutlined,
        selected: true,
        disabled: false,
        protected: false,
        href: `${process.env.PUBLIC_URL}/vinculacion/DocumentosAnexos`,
        key: 3,
        paso: 3
    }
    ];

  getTipoFicha =  async () => { 
    const { authtoken,company } = this.props;  
    try {
      const tipoFicha = await buscarTipoFicha(authtoken,company);
      if ((tipoFicha.cdTipoPersona != undefined) && (tipoFicha.id != undefined)){

        this.getFicha(tipoFicha.cdTipoPersona,tipoFicha.id);

      }else{
        notification.error({
          message: 'Error',
          description: "Error Buscando tipo ficha",
          duration: 5,
        });
      }
    } catch (error) {
      
      notification.error({
        message: 'Error',
        description: "Error Buscando ficha",
        duration: 5,
      });

  }
}

  getFicha =  async (tipoPersona,id) =>{
    const { authtoken } = this.props;  
    let estadoVinculacion,tipoProducto,estadoFicha;
    let personResponse = {};
    try{
      if(tipoPersona === "J"){
        personResponse = await buscarFichaJuridicaPU(authtoken, id);
        estadoVinculacion = personResponse.cdEstadoVinGeneral;
        estadoFicha = personResponse.cdEstado;
        tipoProducto = personResponse.cdTipoProducto;
      }else if((tipoPersona === "N")){
        personResponse = await buscarFichaNaturalPU(authtoken, id);
        estadoVinculacion = personResponse.cdEstadoVinGeneral;
        estadoFicha = personResponse.cdEstado;
        tipoProducto = personResponse.cdTipoProducto;
      }

      let persondata = {
        scCompania : personResponse.scCompania,
        idUser : id,
        estadoVinculacion,
        estadoFicha,
        tipoProducto,
        tipoPersona
      }
      localStorage.setItem("personData",JSON.stringify(persondata));
    }catch (error) {

      notification.error({
        message: 'Error',
        description: "Error trayendo información de Ficha",
        duration: 5,
      });
      
    }

    if(tipoPersona === "J" && ( estadoVinculacion === "P" || estadoVinculacion === "E" || estadoVinculacion === "DI" ) && tipoProducto === "F" )
    {
      this.menus = this.menus.filter(item => item.key !== 2);
      this.menus[1].paso = 2;
      
    }else if(tipoPersona === "J" && estadoVinculacion === "RE" && tipoProducto === "F" ){
      this.menus = [];
    }
    
    this.setState({
      ...this.state,
      menusTotal: this.menus
    })

  }

  async componentDidMount() {
    if (window.configuredInterval) {
      clearInterval(window.configuredInterval);
    }

    window.configuredInterval = setInterval(() => {
      this.props.setExpirationTimeout(this.props.authExpiration);
    }, 30000);

    await this.getTipoFicha();
    
  }

  componentDidUpdate(props, state) {
    if (this.props.reachExpiration !== props.reachExpiration) {
      if (this.props.reachExpiration) {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
          setTimeoutCloseSession: setTimeout(this.logout, 110000),
        });
      } else {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
        });
        clearTimeout(this.state.setTimeoutCloseSession);
      }
    }
  }

  logout = () => {
    this.props.sessionClose(this.props.authtoken);
  };

  selectedKeyHandler = (key) => {
    this.setState({
      ...this.state,
      menuSelectedKey: key,
    });
  };

  render() {
    const {menusTotal} = this.state;
    return (
      <Layout>
        <Sider className='realasside'>
          <div className='user-container'>
            <Link to={`${process.env.PUBLIC_URL}/vinculacion/fichacliente`}>
              <img className='brand-logo' src={logo} alt='' />
            </Link>
            <img className='user-avatar' src={userLogo} alt='' />
            <p className='user-name'>{this.props.username}</p>
            <br />
            <br />
          </div>
          <Menu
            className='slider-list'
            mode='inline'
            selectedKeys={[this.state.menuSelectedKey]}
          >
            {
              menusTotal.map((item) => (
                        <Menu.Item key={item.key}>
                            <Link to={item.href}>
                              {item.icon ? React.createElement(item.icon) : ''}
                              {" "+item.title}
                              {React.createElement(
                                'b',
                                    { style: { color: '#00ff6a' } },
                                ` PASO ${item.paso}`
                            )}
                            </Link>
                          </Menu.Item>
              ))
            }  
          </Menu>
        </Sider>
        <Layout className='content'>
          <Header className='header'>
            <Row className='menu'>
              <Col span={23}>
                {this.fecha.charAt(0).toUpperCase() + this.fecha.slice(1)}
              </Col>

              <Col span={1} onClick={this.logout} className='logout'>
                {' '}
                <LogoutOutlined className='notification-icon' />
              </Col>
            </Row>
          </Header>
          <Content>
            <Switch>
              
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/vinculacion/fichacliente`}
              >
                <FichaCliente menuHandler={this.selectedKeyHandler}/>
              </Route>

              <Route exact path={`${process.env.PUBLIC_URL}/vinculacion/inscripcionusuarios`}>
                <InscripcionUsuariosForm menuHandler={this.selectedKeyHandler}/>
              </Route>

              
              <Route exact path={`${process.env.PUBLIC_URL}/vinculacion/DocumentosAnexos`}>
                <DocumentosAnexos menuHandler={this.selectedKeyHandler}/>
              </Route>

              <Route path={`${process.env.PUBLIC_URL}/tyc`}>
                <TYC menuHandler={this.selectedKeyHandler} hadAccepted='true' />
              </Route>
              
              <Route path={`${process.env.PUBLIC_URL}/`}>
                <Redirect to={`${process.env.PUBLIC_URL}/vinculacion/fichacliente`} />
              </Route> 
                 
            </Switch>
          </Content>
        </Layout>
        <Modal
          title='Sessión a punto de finalizar'
          visible={this.state.showAuthExpModal}
          onOk={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
            clearTimeout(this.state.setTimeoutCloseSession);
            this.props.refreshToken(this.props.authRefreshToken);
          }}
          onCancel={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
          }}
        >
          <p>
            Tu sesión esta a punto de finalizar, si deseas continuar operando en
            la plataforma presiona "Aceptar"
          </p>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  authtoken: state.auth.auth.access_token,
  company: state.auth.auth.scPersona,
  username: state.auth.auth.name,
  authExpiration: state.auth.expiressTimestamp,
  authRefreshToken: state.auth.auth.refresh_token,
  reachExpiration: state.auth.reachExpiration,
});

export default connect(mapStateToProps, {
  setCompany,
  setExpirationTimeout,
  refreshToken,
  sessionClose,
})(VinculacionLayout);
