import { Button, Col, Form, InputNumber, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { requiredValueMessage } from '../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser,
} from '../../utilities/textTools';

export const initialRangoComisionForm = {
  inicio: '',
  fin: '',
  vrPoComision: '',
};

const rules = {
  inicio: [
    { required: true, message: requiredValueMessage() },
    ({ getFieldValue }) => ({
      validator(name, value) {
        const group = name.field.split('.')[0];
        const fin = getFieldValue([group, 'fin']);
        if (!value || !fin || fin > value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('el valor debe ser menor al valor de Techo')
        );
      },
    }),
  ],
  fin: [
    { required: true, message: requiredValueMessage() },
    ({ getFieldValue }) => ({
      validator(name, value) {
        const group = name.field.split('.')[0];
        const inicio = getFieldValue([group, 'inicio']);
        if (!value || !inicio || inicio < value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('el valor debe ser menor al valor de piso')
        );
      },
    }),
  ],
  vrPoComision: [{ required: true, message: requiredValueMessage() }],
};

const RangosComisionModal = ({
  visible,
  data = [],
  nmRangosCom,
  onClose,
  onFinish,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    if (visible) {
      if (Array.isArray(data)) {
        const formData = data.reduce((obj, item, index, arr) => {
          return index === arr.length - 1
            ? { ...obj, vrPoComision: item.vrPoComision }
            : { ...obj, [index]: { ...item } };
        }, {});

        form.setFieldsValue({
          ...formData,
        });
      }
    }
  }, [form, data, visible]);

  useEffect(() => {
    forceUpdate({});
  }, []);

  const handleSubmit = (formValue) => {
    let maxValorComision = formValue.vrPoComision;
    let rangosMeta = Object.entries(formValue)
      .map((value) => value[1])
      .map((value) => ({
        ...value,
        inicio: value.inicio || 0,
        fin: value.fin || 0,
        vrPoComision: value.vrPoComision || 0,
      }));
    rangosMeta[rangosMeta.length - 1]["vrPoComision"] = maxValorComision;
    onFinish(rangosMeta);
  };

  const handleChangeFinField = (name, e) => {
    form.setFieldsValue({
      [name + 1]: {
        inicio: Number(e) + 1,
      },
    });
  };

  const handleOnCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title='Rangos para comision'
      visible={visible}
      className='modal-style-volumen'
      footer={null}
      width={800}
      onCancel={handleOnCancel}
    >
      <div className='header-container'>
        <Form
          name='content_form'
          layout='vertical'
          form={form}
          onFinish={(value) => handleSubmit(value)}
        >
          {Array.from({ length: nmRangosCom }, (v, i) => i).map((_, index) =>
            rangoVolumenForm({
              nmForm: index,
              hidden: index === nmRangosCom - 1,
              onChangeFin: handleChangeFinField,
            })
          )}

          <Row gutter={16} justify='end'>
            <Col span={8}>
              <Form.Item
                name={['vrPoComision']}
                label='VALOR MAX. COMISIÓN'
                rules={rules['vrPoComision'] || ''}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={currencyInputFormat}
                  parser={currencyInputParser}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='btn btn-green'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

const rangoVolumenForm = ({ nmForm, hidden, onChangeFin }) => (
  <Row gutter={16} key={nmForm}>
    <Col span={8}>
      <Form.Item
        name={[nmForm, 'inicio']}
        dependencies={[[nmForm, 'fin']]}
        label='PISO'
        rules={rules['inicio'] || ''}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          formatter={currencyInputFormat}
          parser={currencyInputParser}
        />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        name={[nmForm, 'fin']}
        dependencies={[[nmForm, 'inicio']]}
        label='TECHO'
        rules={hidden ? '' : rules['fin'] || ''}
        hidden={hidden}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          formatter={currencyInputFormat}
          parser={currencyInputParser}
          onBlur={(e) =>
            onChangeFin(nmForm, currencyInputParser(e.target.value))
          }
        />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item
        name={[nmForm, 'vrPoComision']}
        label={hidden ? 'PORCENTAJE COMISIÓN' : 'VALOR COMISIÓN'}
        rules={rules['vrPoComision'] || ''}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          formatter={hidden ? percentageInputFormat : currencyInputFormat}
          parser={hidden ? percentageInputParser : currencyInputParser}
        />
      </Form.Item>
    </Col>
  </Row>
);

export default RangosComisionModal;
