import React, { Component } from 'react';
import { Spin, Row, Col, Divider, Button, notification } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import {
  currencyFormat,
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import BillsFilterForm from './billsFilterForm/billsFilterForm';
import {
  documentoFactura,
  listarConvenios,
  listarFacturas,
  listarFacturasExcel,
  listarPagadores,
  listarProveedores,
} from '../../../../services/fondeador/reportServices';
import TableReport from '../../../../components/tableReport/tableReport';
import { setExcel, setExportPDF } from '../../../../utilities/downloadTools';

const initialState = {
  form: {
    cdFactura: '',
    scConvenio: '',
    scPagador: '',
    scProveedor: '',
    fechaDesdeCompra: '',
    fechaHastaCompra: '',
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 15,
    },
    {
      id: 'totalVrPago',
      value: currencyFormat(0),
      index: 'vrPago',
      colSpan: 1,
    },
    {
      id: 'totalVrDesembolso',
      value: currencyFormat(0),
      index: 'vrDesembolso',
      colSpan: 1,
    },
    {
      id: 'totalVrBeneficioExponencial',
      value: currencyFormat(0),
      index: 'vrBeneficioExponencialTotal',
      colSpan: 1,
    },
    {
      id: 'totalVrBeneficioFondeador',
      value: currencyFormat(0),
      index: 'vrBeneficioFondeador',
      colSpan: 1,
    },
    {
      id: 'totalVrCostoTransaccion',
      value: currencyFormat(0),
      index: 'vrCostoTransaccionFd',
      colSpan: 1,
    },
    {
      id: 'totalVrCostoTransaccionPv',
      value: currencyFormat(0),
      index: 'vrCostoTransaccionPv',
      colSpan: 2,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};
class Bills extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'TIPO CONVENIO',
        dataIndex: 'tipoConvenio',
        sorter: (a, b) => sorterText(a.tipoConvenio, b.tipoConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'CONVENIO',
        dataIndex: 'nombreConvenio',
        sorter: (a, b) => sorterText(a.nombreConvenio, b.nombreConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'CÓDIGO DE FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REPORTADO',
        dataIndex: 'vrFactura',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR BASE FACTURA',
        dataIndex: 'vrFacturaBase',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REDUCIDO',
        dataIndex: 'vrFacturaChReducido',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFacturaChReducido - b.vrFacturaChReducido,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR NOTAS CRÉDITO',
        dataIndex: 'vrTotalNotas',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrTotalNotas - b.vrTotalNotas,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA EMISIÓN',
        dataIndex: 'feEmision',
        sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
        showSorterTooltip: false,
      },

      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ESPERADA DE PAGO',
        dataIndex: 'feEstimadaPago',
        sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE LA COMPRA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'TASA DE FONDEO EA',
        dataIndex: 'poBeneficioFondeadorReal',
        sorter: (a, b) =>
          a.poBeneficioFondeadorReal - b.poBeneficioFondeadorReal,
        render: (value) => <span>{percentageFormat(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'FECHA DE PAGO',
        dataIndex: 'fePago',
        sorter: (a, b) => sorterDate(a.fePago, b.fePago),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PAGADO',
        dataIndex: 'vrPago',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrPago - b.vrPago,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR DESEMBOLSO',
        dataIndex: 'vrDesembolso',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrDesembolso - b.vrDesembolso,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PAGADO A EXPONENCIAL',
        dataIndex: 'vrBeneficioExponencialTotal',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) =>
          a.vrBeneficioExponencialTotal - b.vrBeneficioExponencialTotal,
        showSorterTooltip: false,
      },
      {
        title: 'BENEFICIO FONDEADOR',
        dataIndex: 'vrBeneficioFondeador',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) =>
          a.vrBeneficioFondeador - b.vrBeneficioFondeador,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR POR TRANSACCION',
        dataIndex: 'vrCostoTransaccionFd',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.vrCostoTransaccionFd - b.vrCostoTransaccionFd,
        showSorterTooltip: false,
      },
      {
        title: 'COMISIÓN PROVEEDOR',
        dataIndex: 'vrCostoTransaccionPv',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)} </span>
        ),
        sorter: (a, b) => a.vrCostoTransaccionPv - b.vrCostoTransaccionPv,
        showSorterTooltip: false,
      },
      {
        title: 'DOCUMENTO',
        dataIndex: '',
        render: (text, record) => (
          <DownloadOutlined
            style={{ fontSize: '1.8rem' }}
            title='Descargar documento'
            onClick={() => this.exportPDF(record.scFactura)}
            className='info'
          />
        ),
        showSorterTooltip: false,
      },
    ];
  }

  exportPDF = async (scFactura) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await documentoFactura(
        this.props.authToken,
        this.props.company.company,
        scFactura
      );

      setExportPDF(response, `Documento factura ${scFactura}`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });
      try {
        const response = await listarConvenios(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scConvenio: value.scConvenio,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        cdFactura: value.cdFactura,
        fechaDesdeCompra: value.fechaRango[0],
        fechaHastaCompra: value.fechaRango[1],
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listarFacturas(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = (
              <span className='format-number'>
                {currencyFormat(response.metadata.sumatorias[res.id])}
              </span>
            );
          }

          return res;
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };
  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await listarFacturasExcel(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );
        setExcel(response, `reporte_facturas`);
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...initialState,

        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'tipoConvenio',
            colSpan: 15,
          },
          {
            id: 'totalVrPago',
            value: currencyFormat(0),
            index: 'vrPago',
            colSpan: 1,
          },
          {
            id: 'totalVrDesembolso',
            value: currencyFormat(0),
            index: 'vrDesembolso',
            colSpan: 1,
          },
          {
            id: 'totalVrBeneficioExponencial',
            value: currencyFormat(0),
            index: 'vrBeneficioExponencialTotal',
            colSpan: 1,
          },
          {
            id: 'totalVrBeneficioFondeador',
            value: currencyFormat(0),
            index: 'vrBeneficioFondeador',
            colSpan: 1,
          },
          {
            id: 'totalVrCostoTransaccion',
            value: currencyFormat(0),
            index: 'vrCostoTransaccionFd',
            colSpan: 1,
          },
          {
            id: 'totalVrCostoTransaccionPv',
            value: currencyFormat(0),
            index: 'vrCostoTransaccionPv',
            colSpan: 2,
          },
        ],
      });
    }
  }

  render() {
    const {
      pagadorSelect,
      proveedorSelect,
      convenioSelect,
      loading,

      currentPage,
      limit,
      total,
      resumen,
      resultado,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <BillsFilterForm
            companyId={this.props.company.company}
            proveedorSelect={proveedorSelect}
            pagadorSelect={pagadorSelect}
            convenioSelect={convenioSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection'>
                <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default Bills;
