import axios from 'axios';
import { notification } from 'antd';
import { middleWare } from '../middleware';
import queryString from 'query-string';
import history from '../../services/history';
import store from '../../store';

import { LOGIN_CLOSE } from '../../store/actions/types';

const baseUrl = process.env.REACT_APP_SERVICES_URL_FONDEADOR;

export const listarFondeadores = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/${scFondeador}/listar-fondeadores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarProveedores = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/listar-proveedores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const soporte = (authtoken, scFondeador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas-exp/pagos-comisiones?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const pendientesPago = (authtoken, scFondeador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/reporte-pagos-pendientes?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturas = (authtoken, scFondeador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/listar-facturas?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasEnTramite = (authtoken, scFondeador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/tramites?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasPagadas = (authtoken, scFondeador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/pagadas?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCuposPagadores = (authtoken, scFondeador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupo-convenio/cupos-por-pagador?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCuposConvenios = (authtoken, scFondeador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupo-convenio/cupos-por-convenio?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listaCupos = (authtoken, scFondeador, filtros) => {
  console.log();
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupos/lista-cupos?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const soporteExcel = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas-exp/pagos-comisiones/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const pendientesPagosExcel = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/reporte-pagos-pendientes/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasExcel = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/listar-facturas/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasEnTramiteExcel = (
  authtoken,
  scFondeador,
  filtros
) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/tramites/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasPagadasExcel = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/pagadas/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCuposPagadoresExcel = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupo-convenio/cupos-por-pagador/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCuposConveniosExcel = (authtoken, scFondeador, filtros) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/cupo-convenio/cupos-por-convenio/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasCustodiadasExcel = (
  authtoken,
  scFondeador,
  filtros
) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/facturas-custodiadas/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const soporteFactura = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas-exp/pagos-comisiones/descargar-factura?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
export const soporteComision = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas-exp/pagos-comisiones/descargar-comision?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const documentoFactura = (authtoken, scFondeador, scFactura) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/documentos/descarga/documento-cesion-derecho/${scFactura}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const documentoTextoOpcionesTramites = async (
  authtoken,
  scFondeador,
  filtros
) => {
  let data = formatoFechas(filtros);
  try {
    const response = await axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/tramites/opciones/${
        data.codigoOpcion
      }?${queryString.stringify(data)}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    });

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('auth-token');
      store.dispatch({ type: LOGIN_CLOSE });
      notification.error({
        message: 'Sesión Finalizada',
        description: 'La sesión ha finalizado, por favor ingrese nuevamente',
        duration: 6,
      });

      window.location.href = `${process.env.PUBLIC_URL}/`;
    }
    if (error.response.status === 500) {
      if (error.response.data.type == 'application/json') {
        let reader = new FileReader();
        reader.onload = function () {
          let message = JSON.parse(this.result).message;
          notification.error({
            message: 'Error',
            description:
              'se presento un error inesperado. Si vuelve a pasar favor contactar con el administrador',
            duration: 5,
          });
        };
        reader.readAsText(error.response.data);
      } else {
        notification.error({
          message: 'Error',
          description:
            'se presento un error inesperado. Si vuelve a pasar favor contactar con el administrador',
          duration: 5,
        });
      }
    } else if (error.response.status === 400) {
      let reader = new FileReader();
      reader.onload = function () {
        let message = JSON.parse(this.result).message;
        notification.warn({
          message: 'Alerta',
          description: message,
          duration: 5,
        });
      };
      reader.readAsText(error.response.data);
    }

    return Promise.reject(error);
  }
};

export const documentoTextoOpcionesPagadas = (
  authtoken,
  scFondeador,
  filtros
) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/pagadas/opciones/${
        data.codigoOpcion
      }?${queryString.stringify(data)}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarOpcionesDocumentosTramites = (authtoken, scFondeador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/tramites/opciones`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarOpcionesDocumentosPagadas = (authtoken, scFondeador) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/facturas/pagadas/opciones`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarPagadores = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/listar-pagadores?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarConvenios = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/listar-convenios?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarGruposEmpresariales = (authtoken, scFondeador, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador}/listar-grupos-empresariales?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturaRadian = (authtoken,scFondeador,filtros) => {
  let data = formatoFechas(filtros)
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador.company}/facturas/eventos/listar-facturas?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturaRadianExcel = (authtoken, scFondeador, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scFondeador.company}/facturas/eventos/listar-facturas/exportar/xlsx?${queryString.stringify(
        data
      )}`,
      responseType: 'blob',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') ||
          nombre.includes('Hasta') ||
          nombre.includes('fechaPago')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        if (filtros[nombre] && nombre !== 'currentPage') {
          empty[nombre] = filtros[nombre];
        }
      }
    }
  });
  return empty;
};
