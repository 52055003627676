import React from 'react'
import './tutorial.scss'
import { Modal, Carousel } from 'antd'
import {
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons'

import tutorial1 from '../assets/img/tutorial/tutorial_affirmatum_1.jpg'
import tutorial2 from '../assets/img/tutorial/tutorial_affirmatum_2.jpg'
import tutorial3 from '../assets/img/tutorial/tutorial_affirmatum_3.jpg'
import tutorial4 from '../assets/img/tutorial/tutorial_affirmatum_4.jpg'
import tutorial5 from '../assets/img/tutorial/tutorial_affirmatum_5.jpg'
import tutorial6 from '../assets/img/tutorial/tutorial_affirmatum_6.jpg'
import tutorial7 from '../assets/img/tutorial/tutorial_affirmatum_7.jpg'
import tutorial8 from '../assets/img/tutorial/tutorial_affirmatum_8.jpg'
import tutorial9 from '../assets/img/tutorial/tutorial_affirmatum_9.jpg'
import tutorial10 from '../assets/img/tutorial/tutorial_affirmatum_10.jpg'

function Tutorial(props) {
  return (
    <Modal
      className="content-modal"
      visible={props.showModal}
      title="Tutoriales"
      footer={<div></div>}
      closable
      maskClosable
      onCancel={() => props.onClose()}
      onClose
    >
      <LeftOutlined style={{
        fontSize: '2.5rem',
        position: 'absolute',
        top: '50%',
        left: '0',
      }} />
      <RightOutlined style={{
        fontSize: '2.5rem',
        position: 'absolute',
        top: '50%',
        right: '0',
      }} />
      <Carousel
        accessibility={true}
        autoplay={true}
        arrows>

        <img src={tutorial1} alt="" />
        <img src={tutorial2} alt="" />
        <img src={tutorial3} alt="" />
        <img src={tutorial4} alt="" />
        <img src={tutorial5} alt="" />
        <img src={tutorial6} alt="" />
        <img src={tutorial7} alt="" />
        <img src={tutorial8} alt="" />
        <img src={tutorial9} alt="" />
        <img src={tutorial10} alt="" />

      </Carousel>

    </Modal>
  )
}

export default Tutorial