import { Button, Col, Form, InputNumber, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { requiredValueMessage } from '../../utilities/admin_validation_messages';
import {
  currencyInputFormat,
  currencyInputParser,
  percentageInputFormat,
  percentageInputParser,
} from '../../utilities/textTools';
import * as numberValidate from '../../utilities/validate/numberValidate';
import './calculateVolumenModal.scss';

export const initialCalculateVolumenFomr = {
  inicio: '',
  fin: '',
  poCeder: '',
};

const rules = {
  inicio: [
    { required: true, message: requiredValueMessage() },
    ({ getFieldValue }) => ({
      validator(name, value) {
        const group = name.field.split('.')[0];
        if (!value || getFieldValue([group, 'fin']) > value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('el valor debe ser menor al valor de Techo')
        );
      },
    }),
    {
      validator: (_, value) => numberValidate.range(value, 0, 999999999999),
    },
  ],
  fin: [
    { required: true, message: requiredValueMessage() },
    ({ getFieldValue }) => ({
      validator(name, value) {
        const group = name.field.split('.')[0];
        if (!value || getFieldValue([group, 'inicio']) < value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('el valor debe ser menor al valor de piso')
        );
      },
    }),
    {
      validator: (_, value) => numberValidate.range(value, 0, 999999999999),
    },
  ],
  poCeder: [{ required: true, message: requiredValueMessage() }],
};

const CalculateVolumeModal = ({ visible, data = [], onClose, onFinish }) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (visible) {
      if (Array.isArray(data)) {
        const formData = data.reduce((obj, item, index, arr) => {
          return index === arr.length - 1
            ? { ...obj, [index]: { ...item, inicio: 0, fin: 0 } }
            : { ...obj, [index]: { ...item } };
        }, {});

        form.setFieldsValue({
          ...formData,
        });
      }
    }
  }, [form, data, visible]);

  const handleSubmit = (formValue) => {
    let rangosMeta = Object.entries(formValue).map((value) => value[1]);
    onFinish(rangosMeta);
  };

  return (
    <Modal
      title='Rangos para volumen'
      visible={visible}
      className='modal-style-volumen'
      footer={null}
      width={800}
      onCancel={onClose}
    >
      <div className='header-container'>
        <Form
          name='content_form'
          layout='vertical'
          form={form}
          onFinish={(value) => handleSubmit(value)}
        >
          {data.map((_, index) =>
            rangoVolumenForm({
              nmForm: index,
              hidden: index === data.length - 1,
            })
          )}
          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='btn btn-green'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

const rangoVolumenForm = ({ nmForm, hidden }) => (
  <Row gutter={16} key={nmForm}>
    <Col span={8}>
      <Form.Item
        name={[nmForm, 'inicio']}
        dependencies={[[nmForm, 'fin']]}
        label='PISO'
        rules={rules['inicio'] || ''}
        hidden={hidden}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          formatter={currencyInputFormat}
          parser={currencyInputParser}
        />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        name={[nmForm, 'fin']}
        dependencies={[[nmForm, 'inicio']]}
        label='TECHO'
        rules={rules['fin'] || ''}
        hidden={hidden}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          formatter={currencyInputFormat}
          parser={currencyInputParser}
        />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item
        name={[nmForm, 'poCeder']}
        label='PORCENTAJE EXPONENCIAL'
        rules={rules['poCeder'] || ''}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          max={100}
          formatter={percentageInputFormat}
          parser={percentageInputParser}
        />
      </Form.Item>
    </Col>
  </Row>
);

export default CalculateVolumeModal;
