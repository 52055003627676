import axios from 'axios'
import { middleWare } from '../middleware'
import queryString from 'query-string'

const baseUrl = process.env.REACT_APP_SERVICES_URL_PAGADOR

export const listar = (authtoken, scPagador, filtros) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/proveedores/listar-proveedores?${queryString.stringify(filtros)}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const listarExcel = (authtoken, scPagador, filtros) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/proveedores/listar-proveedores/exportar/xlsx?${queryString.stringify(filtros)}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const datos = (authtoken, scPagador, scProveedor) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/proveedores/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const documentos = (authtoken, scPagador, scProveedor) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/documentos/proveedor/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const descargarDocumento = (authtoken, scPagador, scProveedor, cdDocumento) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/documentos/proveedor/${scProveedor}/descargar/${cdDocumento}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}

export const cuentas = (authtoken, scPagador, scProveedor, cdDocumento) => {
  return middleWare(axios({
    url: `${baseUrl}/api/${scPagador}/cuentas/listar-cuentas-proveedor/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authtoken}`
    },
    data: null
  }))
}
