import React, { Component } from "react";
import {
  Upload,
  Table,
  Button,
  Spin,
  Select,
  notification,
} from "antd";
import {
  InboxOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import bulkloadServices from "../../../../../services/admin/bulkloadServices";
import { listarFondeadores } from "../../../../../services/admin/reports/quotasService";
import { listaFondeadores } from "../../../../../services/admin/fundProvidersService";

const { Dragger } = Upload;
const { Option } = Select;

class Rates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      tableData: [],
      filename: "",
      fondeador: "",
      funders: [],
      loading: false,
    };
  }

  columnsTasas = [
    {
      title: "SCCONVENIO",
      dataIndex: "scConvenio",
      key: "scConvenio",
    },
    {
      title: "NOMBRE CONVENIO",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "PO BENEFICIO FON",
      dataIndex: "poBeneficioFon",
      key: "poBeneficioFon",
    },
    {
      title: "PO BENEFICIO EXPO",
      dataIndex: "poBeneficioExpo",
      key: "poBeneficioExpo",
    },
  ];

  listarFondeadores = () => {
    listaFondeadores(this.props.authToken, {}).then((response) => {
      if (response.data && Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          funders: response.data,
        });
      }
    });
  };

  handleOnSearch = (value) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      listarFondeadores(this.props.authToken, { nombre: value }).then(
        (response) => {
          if (Array.isArray(response.data)) {
            this.setState({
              ...this.state,
              funders: response.data.map((item) => {
                return {
                  scPersona: item.codigo,
                  nombre: item.nombre || item.descripcion,
                };
              }),
              fetching: false,
            });
          }
        }
      );
    } else {
      this.setState({
        ...this.state,
        funders: [],
      });
    }
  };

  handleUpload = () => {
    const { fileList } = this.state;
    console.log(fileList);
    const formData = new FormData();
    let mustReturn = false;
    if (!this.state.fondeador) {
      notification.error({
        message: "El fondeador es requerido",
        duration: 4,
      });
      return;
    }

    if (fileList.length <= 0) {
      notification.error({
        message: "El archivo es requerido",
        duration: 4,
      });
      return;
    }

    if (fileList.length > 1) {
      notification.error({
        message: "Solo se permite un archivo",
        duration: 4,
      });
      return;
    }

    fileList.forEach((file) => {
      if (file.name.slice(-3) === "xls" || file.name.slice(-4) === "xlsx") {
        formData.append("file", file);
      } else {
        mustReturn = true;
        notification.error({
          message: "El archivo debe tener formato de excel (xls, xlsx)",
          duration: 4,
        });
      }
    });

    if (mustReturn) {
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
    });

    bulkloadServices
      .uploadRates(
        this.props.authToken,
        this.state.fondeador.key,
        this.props.type,
        formData
      )
      .then((response) => {
        this.setState({
          ...this.state,
          tableData: response.data,
          filename: response.metadata ? response.metadata.nombreArchivo : "",
          confirmed: false,
          loading: false,
        });

        if(Array.isArray(response.data) && response.data.length === 0){
          notification.success({
            message:'Tasas actualizadas',
            duration: 5,
          });
        } else {
          notification.warn({
            message:'Algunas tasas no se actualizaron correctamente',
            duration: 5,
          });
        }
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
      notification.info({
        message: 'Inicio la actualizacion de tasas, por favor validar el resultado',
        duration: 5,
      });
  };

  downloadTemplate = () => {
    if (!this.state.fondeador) {
      notification.error({
        message: 'El fondeador es requerido',
        duration: 4,
      });
      return;
    }
    bulkloadServices
      .downloadTemplateRates(
        this.props.authToken,
        this.state.fondeador.key,
        this.props.type
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.ms-excel;charset=ANSI',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Plantilla_${this.props.type}.xlt`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  render() {
    return (
      <div className="content-container">
        <div className="file-upload">
          <div className="header-container">
            <p className="title">Búsqueda</p>
          </div>
          <div className="label">
            <p className="label-text file-upload-message">FONDEADOR</p>
          </div>
          <div className="select-wrapper" style={{ width: "200%" }}>
            <Select
              className="module-selector-select"
              placeholder="Seleccione"
              showSearch
              allowClear
              value={this.state.fondeadorName}
              onChange={(e, value) =>
                this.setState({
                  ...this.state,
                  fondeadorName: e,
                  fondeador: value,
                })
              }
              onSearch={(value) => this.handleOnSearch(value)}
              notFoundContent={
                this.state.fetching ? <Spin size="small" /> : null
              }
            >
              {Array.isArray(this.state.funders) &&
                this.state.funders.map((funder) => (
                  <Option
                    key={funder.scPersona}
                    value={funder.razonSocial || funder.nombre}
                  >
                    {funder.razonSocial || funder.nombre}
                  </Option>
                ))}
            </Select>
          </div>
          <p className="file-upload-message">
            Archivo para actualizar {this.props.type} de forma masiva
          </p>
          <Dragger
            customRequest={() => {}}
            multiple={false}
            onChange={this.handleChange}
            onRemove={(file) => {
              const index = this.state.fileList.indexOf(file);
              const newFileList = this.state.fileList.slice();
              newFileList.splice(index, 1);
              this.setState({
                ...this.state,
                fileList: newFileList,
              });
            }}
            beforeUpload={(file) => {
              this.setState({
                ...this.state,
                fileList: [...this.state.fileList, file],
              });
            }}
            fileList={this.state.fileList}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Suelta el archivo aquí o selecciona alguno
            </p>
          </Dragger>
          <div className="template-download">
            <Button
              style={{ margin: 0 }}
              className="btn btn-blue"
              onClick={this.handleUpload}
            >
              Actualizar tasas
            </Button>
            <Button
              style={{ margin: 10 }}
              onClick={this.downloadTemplate}
            >
              Descargar plantilla
            </Button>
          </div>
        </div>
        <Spin tip="Cargando..." spinning={this.state.loading}>
          {Array.isArray(this.state.tableData) &&
            this.state.tableData.length >= 1 && (
              <div className="table-wrapper">
                <p className="title">CONVENIOS NO ACTUALIZADOS</p>
                <Table
                  columns={this.columnsTasas}
                  dataSource={this.state.tableData}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </div>
            )}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};
export default connect(mapStateToProps)(Rates);
