import React, { Component } from 'react';
import { Row, Col, Tabs, Spin, notification } from 'antd';
import {
  convenioFondeador,
  categorias,
  baseDias,
  tipoConceptoACH,
  actualizarConvenio,
  listarProveedores,
  listarProveedoresConvenio,
} from '../../../../../services/fondeador/operationServices';
import FondeadorEditForm from '../fondeadorEditForm/fondeadorEditForm';
import SupplierList from '../supplierList/supplierList';

const initialState = {
  form: { nombre: '' },
  loading: false,
  activeTab: '0',
  convenioData: {},
  categoriasData: [],
  baseDiasData: [],
  conceptosACHData: [],
  proveedores: [],
  total: 0,
  currentPage: 1,
  limit: 10,
  sort: '',
};
class AgreementsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.spinRef = React.createRef();
  }

  getData = async (scConvenio) => {
    this.setState({
      ...this.state,
      form: { nombre: '' },
      loading: true,
      convenioData: {},
      activeTab: '0',
      total: 0,
      proveedores: [],
      currentPage: 1,
      offset: 0,
    });
    try {
      const convenioRes = await convenioFondeador(
        this.props.authToken,
        this.props.company.company,
        scConvenio
      );

      let categoriasData = this.state.categoriasData;
      if (categoriasData.length === 0) {
        const categoriasRes = await categorias(
          this.props.authToken,
          this.props.company.company
        );
        categoriasData = categoriasRes.data;
      }

      let baseDiasData = this.state.baseDiasData;
      if (baseDiasData.length === 0) {
        const baseDiasRes = await baseDias(
          this.props.authToken,
          this.props.company.company
        );
        baseDiasData = baseDiasRes.data;
      }

      let conceptosACHData = this.state.conceptosACHData;
      if (conceptosACHData.length === 0) {
        const conceptosACHRes = await tipoConceptoACH(
          this.props.authToken,
          this.props.company.company
        );
        conceptosACHData = conceptosACHRes.data;
      }

      const response = await listarProveedoresConvenio(
        this.props.authToken,
        this.props.company.company,
        this.props.scConvenio,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        convenioData: {
          ...convenioRes,
        },
        categoriasData: categoriasData,
        baseDiasData: baseDiasData,
        conceptosACHData: conceptosACHData,
        proveedores: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleSubmit = async (form) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await actualizarConvenio(
        this.props.authToken,
        this.props.company.company,
        this.props.scConvenio,
        form
      );

      notification.success({
        message: 'Información actualizada',
        description: response.message,
        duration: 4,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  handleSearch = (form) => {
    this.setState({
      ...this.state,
      loading: true,
      offset: 0,
      currentPage: 1,
      form: { ...form },
    });

    this.searchData();
  };

  searchData = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      const response = await listarProveedoresConvenio(
        this.props.authToken,
        this.props.company.company,
        this.props.scConvenio,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        proveedores: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  changeTab = (activeKey) => {
    this.setState({
      ...this.state,
      activeTab: activeKey,
    });
  };

  componentDidMount() {
    if (typeof this.props.company.company !== 'object') {
      this.getData(this.props.scConvenio);
      this.spinRef.current.scrollIntoView();
    }
  }

  componentDidUpdate(props, state) {
    if (props.scConvenio !== this.props.scConvenio) {
      this.getData(this.props.scConvenio);
      this.spinRef.current.scrollIntoView();
    }
  }

  render() {
    const {
      activeTab,
      convenioData,
      categoriasData,
      baseDiasData,
      conceptosACHData,
      loading,
      proveedores,
      total,
      currentPage,
      limit,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div ref={this.spinRef} className='header-detail-container'>
          <p className='title'>
            Modificar convenio: {convenioData.nombreConvenio}
          </p>
        </div>
        <div className='align-center'>
          <Tabs activeKey={activeTab} onChange={this.changeTab}>
            <Tabs.TabPane key={'0'} tab='Fondeadores'>
              <FondeadorEditForm
                formValue={convenioData}
                categorias={categoriasData}
                baseDias={baseDiasData}
                conceptosACH={conceptosACHData}
                handleSubmit={this.handleSubmit}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key={'1'} tab='Proveedor'>
              <SupplierList
                resultado={proveedores}
                handleSearch={this.handleSearch}
                handleChange={this.handleChange}
                scConvenio={this.props.scConvenio}
                total={total}
                currentPage={currentPage}
                limit={limit}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Spin>
    );
  }
}

export default AgreementsDetail;
