export const LOGIN_SERVICE_ERROR =
  'Ingresaste un usuario o una contraseña incorrecta';
export const LOGIN_USERNAME_ERROR = 'Por favor ingresa tu usuario';
export const LOGIN_PASSWORD_ERROR = 'Por favor ingresa tu contraseña';

export const RESETPASS_EMAIL_ERROR = 'Por favor ingresa tu correo electrónico';
export const RESETPASS_MESSAGE_INIT =
  'Para recuperar tu contraseña debes ingresar tu correo electronico registrado en Affirmatum:';
export const RESETPASS_CONFIRM_MESSAGE_INIT =
  'Ahora puedes registrar una nueva contraseña, debe contener mínimo 8 caracteres, ser alfanumérica e incluir una mayúscula y un carácter especial:';
export const RESETPASS_NEW_PASS_ERROR =
  'Por favor ingresa una nueva contraseña';
export const RESETPASS_OLD_PASS_ERROR =
  'Por favor ingresa tu contraseña actual';
export const RESETPASS_CONFIRM_PASS_ERROR =
  'Por favor confirma tu nueva contraseña';
export const RESETPASS_RECAPTCHA_SITE = process.env.REACT_APP_RECAPTCHA_SITE;

export const CHANGEPASS_TITLE_INIT = 'Cambiar contraseña';
export const CHANGEPASS_SUBTITLE_INIT =
  'Recuerda que debe contener entre 3 y 12 caracteres, ser alfanumérica e incluir al menos una mayúscula';

export const DASHBOARD_MODAL_INIT =
  'Al dar clic en cobrar todo, estarás a un paso de completar la transacción. Solo necesitarás escoger el fondeador de tu preferencia para recibir el dinero hoy.';
export const DASHBOARD_MODAL_INIT_WITH_VALUE =
  'Affirmatum seleccionará de forma inteligente, la mejor combinación de facturas para cubrir tu necesidad de liquidez. Siempre teniendo en cuenta que sea la opción mas económica para ti.';

export const CHARGEALL_VALUE_BIGGER_ERROR =
  'Como el valor que necesitas es superior al disponible, te sugerimos seleccionar tu mismo las facturas.';
export const CHARGEALL_WITH_VALUE_TITLE_INIT =
  'Esta es la mejor combinación de facturas para cubrir tu necesidad de liquidez. Ahora solo queda seleccionar el fondeador de tu preferencia en cada grupo económico.';
export const CHARGEALL_TITLE_INIT =
  'Ahora solo queda seleccionar el fondeador de tu preferencia en cada grupo económico para poder cobrar todas tus facturas';

export const BILLING_TITLE_INIT = '¡Negociemos facturas!';
export const BILLING_SUBTITLE_INIT =
  'Selecciona el o los fondeadores de tu preferencia, luego las facturas que necesitas anticipar y recibe hoy mismo tu dinero.';
export const BILLING_TIME_ERROR =
  'En este momento no puedes negociar tus facturas con este fondeador';

export const PERSON_TITLE_INIT = 'Datos de tu compañía';
export const PERSON_SUBTITLE_INIT =
  'Esta información estará vigente por un año, recuerda que debes renovarla.';

export const AFFITOKEN_SEND_SUCCESS =
  'Al medio de envío que tienes seleccionado, se ha enviado el Affitoken. Ten en cuenta el tiempo disponible para ingresarlo.';

export const DASHBOARD_GRAPH_INIT =
  'Este es el flujo de caja que recibirás, según las fechas de pago de las facturas disponibles en Affirmatum.';

export const REPORTS_DATES_REQUIRED_ERROR =
  'Error: Debes seleccionar las fechas de inicio y fin para consultar este reporte';
export const REPORTS_DISBURSED_INIT =
  'En este informe podrás consultar el histórico de tu facturas negociadas a través de Affirmatum';
export const REPORTS_ON_TRAMIT_INIT =
  'En este informe podrás consultar las facturas negociadas hoy o aquellas que aún no han sido desembolsadas.';
export const REPORTS_COMISION_INIT =
  'Desde este reporte podrás consultar y descargar la factura generada, ya pagada, por tus comisiones.';
export const REPORTS_FINISHED_INIT =
  'En este informe podrás consultar el histórico de tus transacciones realizadas a través de Affirmatum.';
export const REPORTS_TITLE_INIT = 'Centro de informes y soportes';
export const REPORTS_SUBTITLE_INIT =
  'Esta opción te permite consultar y descargar todos los informes históricos de tus transacciones, también podrás consultar la factura por comisiones y algunas estadisticas informativas para tu empresa.';

export const PROGRAM_TITLE_INIT = 'Programación de Facturas';
export const PROGRAM_SUBTITLE_INIT =
  'Esta opción te permite seleccionar fechas futuras para dejar programadas tus transacciones.';

export const AGREEMENTS_TITTLE_INIT = 'Descuento automático';
export const AGREEMENTS_SUBTITTLE_INIT =
  'En esta opción puedes configurar los descuentos automáticos con cada uno de tus clientes ¡Sigue los pasos!';

export const PHONE_NUMBER_INIT_NO_FORMAT = '6044481248';
export const PHONE_NUMBER_INIT = '(604) - 448 12 48.';
export const WHATSAPP_CORP_INIT_NO_FORMAT = '573135534973';
export const WHATSAPP_CORP_INIT = '(57) 313 553 49 73';
export const EMAIL_INIT = 'comercial@affirmatum.com';
export const EMAIL_INIT_PAGADOR = 'operaciones@affirmatum.com';
export const EMAIL_INIT_FONDEADOR = 'operaciones@affirmatum.com';

export const COMPANY_SELECT_MESSAGE =
  'Debe seleccionar una compañía antes de realizar la consulta.';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_POST_FORMAT = 'YYYYMMDD';

export const BUSQUEDA_TITLE = 'Búsqueda';
export const EMPTY_SELECT = 'Seleccione...';
export const USER_CREATED =
  ' Revise la bandeja del correo ingresado y siga las instrucciones';

export const PROV_SIN_TASAS =
  'Asegúrate de grabar las tasas antes de GUARDAR la información.';

export const VDIGITAL_VALIDACION_FICHA =
  'El proveedor no ha terminado el proceso de vinculación o ya está activado';
