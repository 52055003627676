import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Spin } from 'antd';
import QueryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  exportarExcelOfertasPagador,
  listaOfertasPagador,
} from '../../../../services/admin/reports/offersService';
import {
  listarGruposEmpresariales,
  listarProveedores,
} from '../../../../services/admin/reports/quotasService';
import history from '../../../../services/history';
import { setExcel } from '../../../../utilities/downloadTools';
import { currencyFormat, sorterText } from '../../../../utilities/textTools';
import './offers.scss';
import OffersForm from './offersForm/offersForm';

const initialState = {
  resetForm: false,
  form: {
    scProveedor: '',
    scGrupo: '',
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  grupoSelect: {
    fetching: false,
    options: [],
  },
  loading: true,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 4,
      type: 'text',
    },
    {
      id: 'totalCantidadOfertas',
      value: 0,
      index: 'cantidadOfertas',
      colSpan: 1,
      type: 'number',
    },
    {
      id: 'totalTotalOfertas',
      value: currencyFormat(0),
      index: 'totalOfertas',
      colSpan: 1,
      type: 'current',
    },
    {
      id: 'totalTotalDescontado',
      value: currencyFormat(0),
      index: 'totalDescontado',
      colSpan: 1,
      type: 'current',
    },
    {
      id: 'totalTotalPendiente',
      value: currencyFormat(0),
      index: 'totalPendiente',
      colSpan: 2,
      type: 'current',
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  nuevaConsulta: false,
};

class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'NIT',
        dataIndex: 'idProveedor',
        sorter: (a, b) => sorterText(a.idProveedor, b.idProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'NOMBRE',
        dataIndex: 'razonSocialProveedor',
        sorter: (a, b) =>
          sorterText(a.razonSocialProveedor, b.razonSocialProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'DESC. AUTO.',
        dataIndex: 'snDescAuto',
        sorter: (a, b) => sorterText(a.snDescAuto, b.snDescAuto),
        render: (value) => <span>{value === 'S' ? 'Si' : 'No'}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'NUEVO',
        dataIndex: 'nuevo',
        sorter: (a, b) => a.nuevo - b.nuevo,
        showSorterTooltip: false,
      },
      {
        title: 'OFERTAS',
        dataIndex: 'cantidadOfertas',
        render: (value) => <span className='format-number'>{value}</span>,
        sorter: (a, b) => a.cantidadOfertas - b.cantidadOfertas,
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL OFERTAS',
        dataIndex: 'totalOfertas',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.totalOfertas - b.totalOfertas,
        showSorterTooltip: false,
      },

      {
        title: 'TOTAL DESCONTADO',
        dataIndex: 'totalDescontado',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.totalDescontado - b.totalDescontado,
        showSorterTooltip: false,
      },
      {
        title: 'TOTAL PENDIENTE',
        dataIndex: 'totalPendiente',
        render: (value) => (
          <span className='format-number'>{currencyFormat(value)}</span>
        ),
        sorter: (a, b) => a.totalPendiente - b.totalPendiente,
        showSorterTooltip: false,
      },
      {
        title: 'DETALLE',
        dataIndex: 'scProveedor',
        render: (text, record) => (
          <Button
            key={text}
            className='btn btn-radius btn-details'
            type='primary'
            htmlType='button'
            onClick={() =>
              this.goToDetails(record.scProveedor, record.razonSocialProveedor)
            }
          >
            DETALLE
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  goToDetails(scProveedor, razonSocialProveedor) {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/reports/offers/bills/${btoa(
        scProveedor
      )}`,
      search: QueryString.stringify({ nombreProveedor: razonSocialProveedor }),
    });
  }

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });
      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });

        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
          cdTipoCliente: 'PAG',
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    const { limit, sort } = this.state;

    this.state.nuevaConsulta = true;

    this.doChangeHistory({
      ...value,
      currentPage: initialState.currentPage,
      offset: initialState.offset,
      limit,
      sort,
    });
  };

  getData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      const response = await listaOfertasPagador(
        this.props.authToken,
        this.getFiltro()
      );

      const resumen = this.state.resumen.map((res) => {
        if (response.metadata.sumatorias[res.id] !== undefined) {
          const valor = response.metadata.sumatorias[res.id];
          let text = '';
          switch (res.type) {
            case 'current':
              text = (
                <span className='format-number'>{currencyFormat(valor)}</span>
              );
              break;
            default:
              text = (
                <Row justify='center'>
                  <Col>
                    <span className='format-number'>{valor}</span>
                  </Col>
                </Row>
              );
              break;
          }
          res.value = text;
        }
        return res;
      });

      this.setState({
        ...this.state,
        nuevaConsulta: false,
        resultado: response.data,
        total: response.metadata.count,
        resumen: resumen,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await exportarExcelOfertasPagador(
        this.props.authToken,
        this.getFiltro()
      );
      setExcel(response, `ofertas`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort, nuevaConsulta } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
      nuevaConsulta: nuevaConsulta,
    };
  };

  handleChange = (e) => {
    const { form } = this.state;
    this.state.nuevaConsulta = false;
    this.doChangeHistory({ ...form, ...e });
  };

  handleOnReset = () => {
    this.setState({
      ...this.state,
      resetForm: false,
      form: {
        ...initialState.form,
      },
    });
  };

  componentDidMount() {
    this.props.menuHandler('65');
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.init(nextProps);
    } else {
      if (this.state.nuevaConsulta) {
        this.init(nextProps);
      }
    }
  }

  init = async (props) => {
    const filtro = this.parseQueryString(props);

    if (
      !isNaN(filtro.currentPage) &&
      !isNaN(filtro.limit) &&
      !isNaN(filtro.offset)
    ) {
      await this.updateStatePage(filtro);
      await this.getData();
    } else {
      this.setState({ loading: false });
    }
  };

  parseQueryString = (props) => {
    const {
      location: { search },
    } = props;

    const filtro = QueryString.parse(search, { ignoreQueryPrefix: true });

    return {
      ...filtro,
      limit: parseInt(filtro.limit),
      currentPage: parseInt(filtro.currentPage),
      offset: parseInt(filtro.offset),
    };
  };

  updateStatePage = async (filtro) => {
    this.state.limit = filtro.limit || 10;
    this.state.offset = filtro.offset || 0;
    this.state.currentPage = filtro.currentPage || 1;
    this.state.sort = filtro.sort || '';
    try {
      let scGrupo;
      if (filtro.grupo) {
        const grupo = filtro.grupo.split(';');
        if (this.state.grupoSelect.options.length === 0) {
          await this.handleOnSearchGrupo(grupo[1]);
        }

        scGrupo = this.state.grupoSelect.options.find(
          (el) => el.codigo === grupo[0]
        ).codigo;
      }

      let scProveedor;
      if (filtro.proveedor) {
        const proveedor = filtro.proveedor.split(';');
        if (this.state.proveedorSelect.options.length === 0) {
          await this.handleOnSearchProveedor(proveedor[1]);
        }

        scProveedor = this.state.proveedorSelect.options.find(
          (el) => el.codigo === proveedor[0]
        ).codigo;
      }

      this.state.form = {
        scGrupo,
        scProveedor,
      };
    } catch {
      history.push({
        pathname: `${process.env.PUBLIC_URL}/reports/offers`,
      });
    }
  };

  doChangeHistory = (e) => {
    const { grupoSelect, proveedorSelect } = this.state;

    const grupo = e.scGrupo
      ? grupoSelect.options
          .filter((el) => el.codigo === e.scGrupo)
          .map((el) => `${el.codigo};${el.descripcion}`)
          .join('')
      : undefined;

    const proveedor = e.scProveedor
      ? proveedorSelect.options
          .filter((el) => el.codigo === e.scProveedor)
          .map((el) => `${el.codigo};${el.descripcion}`)
          .join('')
      : undefined;

    const search = QueryString.stringify({
      grupo,
      proveedor,
      sort: e.sort !== '' ? e.sort : undefined,
      limit: e.limit,
      offset: e.offset,
      currentPage: e.currentPage,
    });

    history.push({
      pathname: `${process.env.PUBLIC_URL}/reports/offers`,
      search,
    });
  };

  render() {
    const { proveedorSelect, loading, resetForm, grupoSelect, form } =
      this.state;
    return (
      <div className='reports'>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <Spin tip='Cargando...' spinning={loading}>
            <OffersForm
              data={form}
              resetForms={resetForm}
              proveedorSelect={proveedorSelect}
              handleOnSearchProveedor={this.handleOnSearchProveedor}
              grupoSelect={grupoSelect}
              handleOnSearchGrupo={this.handleOnSearchGrupo}
              handleSubmit={this.handleSubmit}
              handleOnReset={this.handleOnReset}
            />
            <div className='table-container'>
              {this.state.resultado.length > 0 && (
                <Row gutter={30} className='bill-selection'>
                  <Col lg={{ span: 24 }} xl={{ span: 24 }} className='export'>
                    <span className='button'>
                      <Button
                        size='medium'
                        className='btn-excel'
                        title='Exportar a excel'
                        onClick={this.exportExcel}
                        icon={<FileExcelOutlined />}
                      ></Button>
                    </span>
                  </Col>
                </Row>
              )}
              <Divider />
              <Row>
                <Col flex='auto'>
                  <TableReport
                    currentPage={this.state.currentPage}
                    limit={this.state.limit}
                    total={this.state.total}
                    resumen={this.state.resumen}
                    columns={this.columnas}
                    data={this.state.resultado}
                    handleChange={this.handleChange}
                  />
                </Col>
              </Row>
            </div>
          </Spin>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default withRouter(connect(mapStateToProps)(Offers));
