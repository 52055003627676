import {
  CheckOutlined,
  CloseOutlined,
  InboxOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Popover,
  Select,
  Spin,
  Table,
  Upload,
  notification,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { listaPagadores } from '../../../../services/admin/payersService';
import { listarPagadores } from '../../../../services/fondeador/reportServices';
import {
  confirm,
  downloadTemplate,
  upload,
} from '../../../../services/fondeador/walletService';
import './paymentsManaged.scss';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import TableReport from '../../../../components/tableReport/tableReport';

const { Dragger } = Upload;
const { Option } = Select;

class PaymentsManaged extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      tableData: [],
      filename: '',
      confirmed: false,
      loading: false,
      pagador: '',
      payers: [],
      reintegros: null,
    };
  }

  columns = [
    {
      title: 'ESTADO',
      key: 'estado',
      align: 'center',
      render: (item) =>
        Array.isArray(item.errors) && item.errors.length > 0 ? (
          <Popover
            data-html='true'
            placement='topRight'
            content={
              Array.isArray(item.errors) && (
                <div>
                  {item.errors.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              )
            }
            title='Errores'
          >
            {this.state.confirmed ? (
              <CloseOutlined className='icon-size color-alert' />
            ) : (
              <InfoCircleOutlined className='icon-size color-error' />
            )}
          </Popover>
        ) : this.state.confirmed ? (
          <CheckOutlined className='icon-size color-success' />
        ) : null,
    },
    {
      title: 'IDENTIFICACIÓN',
      dataIndex: 'proveedorId',
      key: 'proveedorId',
    },
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      key: 'cdFactura',
    },
    {
      title: "VALOR RECAUDO",
      dataIndex: "vrRecaudo",
      render: (value) => <span>$ {this.formatNumber(value)}</span>,
      key: "vrRecaudo",
    },
    {
      title: "FECHA RECAUDO",
      dataIndex: "feRecaudo",
      key: "feRecaudo",
    },
    {
      title: "OBSERVACIÖN",
      dataIndex: "observacion",
      key: "observacion",
    },
  ];

  columnsSegRecaudo = [
    {
      title: 'NIT',
      dataIndex: 'pagadorId',
      sorter: (a, b) => sorterText(a.pagadorId, b.pagadorId),
      showSorterTooltip: false,
    },
    {
      title: 'RAZÓN SOCIAL',
      dataIndex: 'pagadorNombre',
      sorter: (a, b) => sorterText(a.rSocial, b.rSocial),
      showSorterTooltip: false,
    },
    {
      title: '#FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
      showSorterTooltip: false,
    },
    {
      title: 'FECHA ESPERADA DE PAGO',
      dataIndex: 'feEstimadaPago',
      sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
      showSorterTooltip: false,
    },
    {
      title: 'DÍAS MORA',
      dataIndex: 'diasMora',
      showSorterTooltip: false,
    },
    {
      title: 'VALOR FACTURA',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR REAL',
      dataIndex: 'vrActual',
      sorter: (a, b) => a.vrActual - b.vrActual,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR INTERESES MORA',
      dataIndex: 'vrIntereses',
      sorter: (a, b) => a.vrIntereses - b.vrIntereses,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'SALDO ACTUAL',
      dataIndex: 'saldo',
      sorter: (a, b) => a.saldo - b.saldo,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'VALOR DE REINTEGRO',
      dataIndex: 'vrReintegro',
      sorter: (a, b) => a.vrReintegro - b.vrReintegro,
      render: (value) => <span>{currencyFormat(value)}</span>,
      showSorterTooltip: false,
    },
  ];

  componentDidMount() {
    this.props.menuHandler('73');
  }

  listarPagadores = () => {
    listaPagadores(this.props.authToken, {}).then((response) => {
      if (response.data && Array.isArray(response.data)) {
        this.setState({
          ...this.state,
          payers: response.data,
        });
      }
    });
  };

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    let mustReturn = false;
    if (!this.state.pagador) {
      notification.error({
        message: 'El pagador es requerido',
        duration: 4,
      });
      return;
    }

    if (fileList.length <= 0) {
      notification.error({
        message: 'El archivo es requerido',
        duration: 4,
      });
      return;
    }

    fileList.forEach((file) => {
      if (file.name.slice(-3) === 'xls' || file.name.slice(-4) === 'xlsx') {
        formData.append('file', file);
      } else {
        mustReturn = true;
        notification.error({
          message: 'El archivo debe tener formato de excel (xls, xlsx)',
          duration: 4,
        });
      }
    });

    if (mustReturn) {
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
    });

      upload(
        this.props.authToken,
        this.props.company.company,
        this.state.pagador.key,
        formData
      )
      .then((response) => {
        this.setState({
          ...this.state,
          tableData: response.data,
          filename: response.metadata ? response.metadata.nombreArchivo : '',
          reintegros: null,
          confirmed: false,
          loading: false,
        });
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  confirmUpload = () => {
    this.setState({
      ...this.state,
      loading: true,
    });

      confirm(
        this.props.authToken,
        this.props.company.company,
        this.state.pagador.key,
        this.state.filename
      )
      .then((response) => {
        if (response.metadata) {
          this.setState({
            ...this.state,
            confirmed: true,
            loading: false,
            fileList: [],
            reintegros: response.data
          });
          notification.success({
            message: 'Confirmado',
            description:
              'Archivo cargado correctamente, por favor revisar los resultados',
          });
        }
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  downloadTemplate = () => {
    if (!this.state.pagador) {
      notification.error({
        message: 'El pagador es requerido',
        duration: 4,
      });
      return;
    }

    downloadTemplate(
      this.props.authToken
    )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.ms-excel;charset=ANSI',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `plantilla_pagos_dirigidos.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  formatNumber(num) {
    if (num) {
      num = num.toFixed(2);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else if (num === 0) {
      return 0;
    }
  }

  handleChange = (status) => {
    if (this.state.fileList.length > 1) {
      this.state.fileList.shift();
      this.setState({
        ...this.state,
      });
    }
  };

  handleOnSearch = (value) => {
    if (value && value.length >= 3) {
      this.setState({
        ...this.state,
        fetching: true,
      });
      listarPagadores(this.props.authToken, this.props.company.company, { nombre: value }).then(
        (response) => {
          if (Array.isArray(response.data)) {
            this.setState({
              ...this.state,
              payers: response.data.map((item) => {
                return {
                  scPersona: item.scPersona,
                  nombre: item.nombre || item.descripcion,
                  id: item.id,
                };
              }),
              fetching: false,
            });
          }
        }
      );
    } else {
      this.setState({
        ...this.state,
        payers: [],
      });
    }
  };

  render() {
    return (
      <div className='content-margin bulkload-style'>
        <div className='card-background-light card-shadow radius'>
          <div className='align-center'>
            <div className='content-container'>
              <div className='file-upload'>
                <div className='header-container'>
                  <p className='title'>Búsqueda</p>
                </div>
                <div className='label'>
                  <p className='label-text file-upload-message'>PAGADOR</p>
                </div>
                <div className='select-wrapper' style={{ width: '200%' }}>
                  <Select
                    className='module-selector-select'
                    placeholder='Seleccione'
                    showSearch
                    allowClear
                    value={this.state.pagadorName}
                    onChange={(e, value) =>
                      this.setState({
                        ...this.state,
                        pagadorName: e,
                        pagador: value,
                      })
                    }
                    onSearch={(value) => this.handleOnSearch(value)}
                    notFoundContent={
                      this.state.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {Array.isArray(this.state.payers) &&
                      this.state.payers.map((payer) => (
                        <Option
                          key={payer.scPersona}
                          value={payer.razonSocial || payer.nombre}
                        >
                          {payer.id} - {payer.razonSocial || payer.nombre}
                        </Option>
                      ))}
                  </Select>
                </div>
                <p className='file-upload-message'>
                  Archivo para aplicar pagos de forma masiva
                </p>
                <Dragger
                  customRequest={() => {}}
                  multiple={false}
                  onChange={this.handleChange}
                  onRemove={(file) => {
                    const index = this.state.fileList.indexOf(file);
                    const newFileList = this.state.fileList.slice();
                    newFileList.splice(index, 1);
                    this.setState({
                      ...this.state,
                      fileList: newFileList,
                    });
                  }}
                  beforeUpload={(file) => {
                    this.setState({
                      ...this.state,
                      fileList: [...this.state.fileList, file],
                    });
                  }}
                  fileList={this.state.fileList}
                >
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    Suelta el archivo aquí o selecciona alguno
                  </p>
                </Dragger>
                <div className='template-download'>
                  <Button
                    style={{ margin: 0 }}
                    className='btn btn-blue'
                    onClick={this.handleUpload}
                  >
                    Cargar archivo
                  </Button>
                  <a href='#' onClick={this.downloadTemplate}>
                    Descargar plantilla
                  </a>
                </div>
              </div>
              <Spin tip='Cargando...' spinning={this.state.loading}>

                {Array.isArray(this.state.reintegros) &&
                    this.state.reintegros.length >= 1 && (
                <div>
                  <p className='title'><b>REINTEGROS HECHOS</b></p>
                  <div className='table-wrapper'>
                  <TableReport
                      keyIndex={'scFactura'}
                      columns={this.columnsSegRecaudo}
                      data={this.state.reintegros}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <br />
                </div>
                )}

                {Array.isArray(this.state.reintegros) &&
                    this.state.reintegros.length === 0 && (
                <div>
                  <p className='title'><b>SIN REINTEGROS</b></p>
                  <br />
                </div>
                )}

                {Array.isArray(this.state.tableData) &&
                  this.state.tableData.length >= 1 && (
                    <div className='table-wrapper'>
                      <Table
                        columns={this.columns}
                        dataSource={this.state.tableData}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: ['10', '20', '30'],
                        }}
                      />
                    </div>
                )}

                {Array.isArray(this.state.tableData) &&
                this.state.tableData.length > 0 ? (
                  <Button
                    className='btn btn-green'
                    onClick={this.confirmUpload}
                  >
                    Confirmar archivo
                  </Button>
                ) : (
                  ''
                )}
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};
export default connect(mapStateToProps)(PaymentsManaged);
