import React from 'react';
import {
  Row,
  Col,
  Form,
  notification,
  Spin,
  Select,
  Button,
  DatePicker,
  Divider,
  Table,
  Upload,
  Space,
  Modal,
  Typography,
} from 'antd';
import { ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { sorterDate, sorterText } from '../../../../../utilities/textTools';
import {
  cargarArchivo,
  descargarDocumetosCargados,
  eliminarDocumentosCargados,
  listaDocumentosCargados,
  listaDocumentosVencidos,
  listaTiposDocumentos,
} from '../../../../../services/admin/payersService';
import {
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import { sizeFileValidation } from '../../../../../utilities/validate/fileValidate';
import { DATE_FORMAT } from '../../../../../utilities/messages';
import moment from 'moment';
import { setExport } from '../../../../../utilities/downloadTools';

const { Dragger } = Upload;
const { Text } = Typography;

const SUPPLIER_TITLE = 'Proveedor';

const initialState = {
  loading: false,
  docsCargados: [],
  docsVencidos: [],
  tiposDocumentos: [],
  fileList: [],
  fileMessageErrors: [],
};

const initialValue = {
  codigo: '',
  feCarga: moment(),
  file: '',
};

const rules = {
  codigo: [
    { required: true, message: requiredValueMessage() },
    { max: 20, message: maxValueMessage(20) },
  ],
  feCarga: [{ required: true, message: requiredValueMessage() }],
};
class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.formRef = React.createRef();

    this.docCargadosColumnas = [
      {
        title: 'DOCUMENTO',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        defaultSortOrder: 'descend',
        showSorterTooltip: false,
      },
      {
        title: 'DECRIPCIÓN',
        dataIndex: 'descripcion',
        sorter: (a, b) => sorterText(a.descripcion, b.descripcion),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ENTREGA',
        dataIndex: 'feCarga',
        sorter: (a, b) => sorterDate(a.feCarga, b.feCarga),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='EDITAR'
            onClick={() => this.setEditFields(record)}
          >
            EDITAR
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'DESCARGAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='DESCARGAR'
            onClick={() => this.descargarDocumento(record)}
          >
            DESCARGAR
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'ELIMINAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='ELIMINAR'
            onClick={() => this.showDeleteConfirm(record)}
          >
            ELIMINAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];

    this.docVencidosColumnas = [
      {
        title: 'DOCUMENTO',
        dataIndex: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        defaultSortOrder: 'descend',
        render: (text, record) => (
          <Button
            type='link'
            onClick={() => this.selectCdDocumento(record.cdDocumento)}
          >
            {text}
          </Button>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'DECRIPCIÓN',
        dataIndex: 'descripcion',
        sorter: (a, b) => sorterText(a.descripcion, b.descripcion),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ENTREGA',
        dataIndex: 'feCarga',
        sorter: (a, b) => sorterDate(a.feCarga, b.feCarga),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'DESCARGAR',
        dataIndex: '',
        render: (record) => (
          <Button
            type='primary'
            title='DESCARGAR'
            onClick={() => this.descargarDocumento(record)}
          >
            DESCARGAR
          </Button>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  handleOnChangeUpload = (info) => {
    const fileList =
      info.fileList.length > 1 ? info.fileList.shift() : info.fileList;

    this.isValidFile(fileList);

    this.setState({
      ...this.state,
      fileList,
    });
  };

  isValidFile = async (fileList) => {
    const { edit = false } = this.props;
    let fileMessageErrors = [];

    if (fileList.length === 0 && !edit) {
      fileMessageErrors.push('debe seleccionar un archivo');
    }

    try {
      await sizeFileValidation(fileList);
    } catch (error) {
      fileMessageErrors.push(error.message);
    }

    this.setState({ ...this.state, fileMessageErrors });
    return fileMessageErrors.length === 0;
  };

  selectCdDocumento = (cdDocumento) => {
    this.formRef.current.setFieldsValue({
      codigo: cdDocumento,
    });
  };

  setEditFields = (record) => {
    this.formRef.current.setFieldsValue({
      codigo: record.cdDocumento,
      feCarga: moment(record.feCarga, DATE_FORMAT),
    });
  };

  descargarDocumento = async (record) => {
    const { authToken, tipoAsociado, scPersona } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await descargarDocumetosCargados(
        authToken,
        tipoAsociado,
        scPersona,
        record.cdDocumento
      );

      setExport(response, record.cdDocumento);
    } finally {
      this.setState({ ...this.state, loading: false });
    }
  };

  showDeleteConfirm = (record) => {
    Modal.confirm({
      title: 'Documentos',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro de eliminar este documento?',
      onOk: () => this.eliminarDocumento(record),
    });
  };

  eliminarDocumento = async (record) => {
    const { authToken, tipoAsociado, scPersona } = this.props;
    let { docsCargados } = this.state;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await eliminarDocumentosCargados(
        authToken,
        tipoAsociado,
        scPersona,
        record.cdDocumento
      );

      this.setState({
        ...this.state,
        docsCargados: docsCargados,
        loading: false,
      });

      notification.success({
        message: SUPPLIER_TITLE,
        description: response.message,
        duration: 6,
      });

      this.getDocumentos();
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getDocumentos = async () => {
    const { authToken, tipoAsociado, scPersona } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const docsCargadosResponse = await listaDocumentosCargados(
        authToken,
        tipoAsociado,
        scPersona
      );

      const docsVencidosResponse = await listaDocumentosVencidos(
        authToken,
        tipoAsociado,
        scPersona
      );

      const tipoDocsResponse = await listaTiposDocumentos(
        authToken,
        tipoAsociado
      );

      this.setState({
        ...this.state,
        docsCargados: docsCargadosResponse.data,
        docsVencidos: docsVencidosResponse.data,
        tiposDocumentos: tipoDocsResponse.data,
        loading: false,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  handleOnFinish = async (formValue) => {
    const { authToken, tipoAsociado, scPersona } = this.props;
    const { fileList } = this.state;
    if (await this.isValidFile(fileList)) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await cargarArchivo(
          authToken,
          tipoAsociado,
          scPersona,
          {
            ...formValue,
            file: fileList,
          }
        );

        notification.success({
          message: SUPPLIER_TITLE,
          description: response.message,
          duration: 6,
        });
        this.handleOnReset();
        this.getDocumentos();
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  handleOnReset = () => {
    this.formRef.current.setFieldsValue({ ...initialValue });
    this.setState({ ...this.state, fileList: [], fileMessageErrors: [] });
  };

  componentDidMount() {
    this.getDocumentos();
  }

  render() {
    const {
      loading,
      docsCargados,
      docsVencidos,
      tiposDocumentos,
      fileList,
      fileMessageErrors,
    } = this.state;
    const { children } = this.props;
    return (
      <Spin tip='Cargando...' spinning={loading}>
        <Form
          name='detail_form'
          layout='vertical'
          ref={this.formRef}
          onReset={this.handleOnReset}
          onFinish={this.handleOnFinish}
          initialValues={initialValue}
        >
          <Row gutter={16}>
            <Col>
              <Space align='baseline' className='sub-title'>
                Cargar documento
              </Space>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='codigo'
                label='TIPO DE DOCUMENTO'
                rules={rules['codigo']}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder='Seleccionar'
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {tiposDocumentos.map((tipoDoc) => (
                    <Select.Option key={tipoDoc.codigo} value={tipoDoc.codigo}>
                      {tipoDoc.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='feCarga'
                label='FECHA ENTREGA'
                rules={rules['feCarga']}
              >
                <DatePicker format={DATE_FORMAT} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col flex='auto'>
              <Form.Item label='DOCUMENTO 10 MB' required>
                <Form.Item noStyle>
                  <Dragger
                    beforeUpload={() => {
                      return false;
                    }}
                    fileList={fileList}
                    onChange={this.handleOnChangeUpload}
                    maxCount={0}
                    multiple={false}
                  >
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Suelta el archivo aquí o selecciona alguno.
                    </p>
                  </Dragger>
                  {fileMessageErrors.map((value) => (
                    <Text type='danger'>{value}</Text>
                  ))}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} align='middle' justify='center'>
            <Col>
              <Form.Item>
                <Button className='btn btn-blue' htmlType='reset'>
                  Limpiar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button className='btn btn-green' htmlType='submit'>
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Row gutter={16}>
          <Col>
            <Space align='baseline' className='sub-title'>
              Lista de documentos cargados
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col flex='auto'>
            <div className='table-container'>
              <Table
                rowKey={(record) => record.cdDocumento}
                columns={this.docCargadosColumnas}
                dataSource={docsCargados}
                pagination={false}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col>
            <Space
              align='baseline'
              className='sub-title'
              style={{ marginTop: '2.0rem' }}
            >
              Lista de documentos vencidos o no entregados
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col flex='auto'>
            <div className='table-container'>
              <Table
                rowKey={(record) => record.cdDocumento}
                columns={this.docVencidosColumnas}
                dataSource={docsVencidos}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>{children}</Row>
      </Spin>
    );
  }
}

export default Documents;
